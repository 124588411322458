import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import {
  ILLiveProfileDetailsCard,
  ILLiveProfileHeader,
  ILScrollToTop,
} from "../../components";
import { useLiveProfileLayoutHook } from "../../hooks/dashboard/myShop/liveProfileLayout.hook";
import styles from "./LiveProfileLayout.module.css";

export const LiveProfileLayout = () => {
  const { isLoading, influencerDetails } = useLiveProfileLayoutHook();

  return (
    <>
      <Layout className={styles.ILLiveProfileLayout}>
        <ILScrollToTop />
        <ILLiveProfileHeader />
        <ILLiveProfileDetailsCard
          influencerDetails={influencerDetails}
          isLoading={isLoading}
        />
        <Outlet />
      </Layout>
    </>
  );
};
