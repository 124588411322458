import React from "react";
import { clsx } from "clsx";
import styles from "./ILDataOverviewCard.module.css";
import { ILSkeleton } from "../ILSkeleton";

export const ILDataOverviewCard = ({
  isLoading,
  dataCardsList,
  className,
  stylingCard,
}) => {
  return (
    <>
      {isLoading ? (
        <div
          className={clsx(
            styles.dataCard,
            className,
            "radius-md",
            stylingCard ? "border" : ""
          )}
        >
          <ILSkeleton isDataCard widthOne="50px" />
        </div>
      ) : (
        dataCardsList &&
        dataCardsList.map((item, index) => {
          return (
            <div
              key={index}
              className={clsx(
                styles.dataCard,
                className,
                "radius-md",
                stylingCard ? "border" : ""
              )}
            >
              <div>
                <h5 className="big fw-semibold mb-3 word-break-word">
                  {item.data}
                </h5>
                <p className="paragraph-sm-text fw-medium mb-0">{item.title}</p>
              </div>
              <img
                className={clsx(styles.dataCardIcon, "flex-0-auto")}
                width={"100%"}
                height={"100%"}
                src={item.image}
                alt={item.title}
              />
            </div>
          );
        })
      )}
    </>
  );
};
