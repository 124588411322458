export const appConfig = {
  APP_MODE: import.meta.env.VITE_APP_MODE,
  APP_URL: import.meta.env.VITE_APP_URL,
  API_URL: import.meta.env.VITE_API_URL,
  APP_DOMAIN: import.meta.env.VITE_APP_DOMAIN,
  SOCKET_URL: import.meta.env.VITE_APP_SOCKET_URL,
  ENCRYPTION_MESSAGE: import.meta.env.VITE_APP_ENCRYPTION_MESSAGE,
  ENCRYPTION_SECRET_KEY: import.meta.env.VITE_APP_ENCRYPTION_SECRET_KEY,
  DEVICE_TYPE: import.meta.env.VITE_APP_DEVICE_TYPE,
  CRYPTO_KEY: import.meta.env.VITE_APP_CRYPTO_KEY,
  CRYPTO_IV: import.meta.env.VITE_APP_CRYPTO_IV,
};
