import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { notificationTabData } from "../../constants/data";
import {
  doFetchAccessRequest,
  doFetchAddCollection,
  doFetchAddProduct,
  doFetchAddService,
  doFetchMarkAllAsRead,
  doFetchRequestAction,
  doFetchUserDetails,
} from "../../actions";
import { getDecryptionString } from "../../services";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { storeAuthUserDetails } from "../../reducers/authenticate.reducer";
import { setAuthHeader } from "../../axios";
import { socket } from "../../services/socket";

export const useDashboardHeaderHook = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState({
    userDetails: false,
    accessRequestList: false,
    sendAccessRequest: false,
    markAllRead: false,
    markSingleProduct: false,
  });
  const [isAddToShopLoading, setIsAddToShopLoading] = useState(false);
  const [userProfileDetails, setUserProfileDetails] = useState({});
  const [isLogOutModalOpen, setIsLogOutModalOpen] = useState(false);
  const [isTabActive, setIsTabActive] = useState(notificationTabData[0].key);
  const [accessRequestTab, setAccessRequestTab] = useState({
    type: "",
  });
  const [selectedRequest, setSelectedRequest] = useState({});
  const [accessRequestList, setAccessRequestList] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("_token") ? true : false
  );
  const { userDetails } = useSelector(
    (state) => state.storeAuthenticateReducer
  );

  useEffect(() => {
    if (isLoggedIn) {
      setAuthHeader(`Bearer ${localStorage.getItem("_token")}`);
      fetchUserDetails();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchAccessRequest(accessRequestTab);
    }
  }, [accessRequestTab, isLoggedIn]);

  useEffect(() => {
    try {
      socket.emit("check-connection");
      socket.on("done-connection", (result) => {
        console.log("on connected", result);
      });
      socket.emit("connect-user", {
        socketId: socket?.id,
        userId: userDetails?.userData?.id,
      });
    } catch (error) {
      console.error("Error emitting event:", error);
    }
  }, [socket, userDetails?.userData?.id]);

  useEffect(() => {
    try {
      socket.on("receive-message", (result) => {
        const updatedAccessRequestList = {
          ...accessRequestList,
          rows: [result, ...(accessRequestList?.rows || [])],
          totalItems: (accessRequestList?.totalItems || 0) + 1,
          count: (accessRequestList?.count || 0) + 1,
          unReadNotification: (accessRequestList?.unReadNotification || 0) + 1,
        };

        setAccessRequestList(updatedAccessRequestList);
      });
    } catch (error) {
      console.error("Error emitting event:", error);
    }
    return () => {
      socket.off("receive-message");
    };
  }, [accessRequestList]);

  useEffect(() => {
    if (isLoggedIn) {
      setAuthHeader(`Bearer ${localStorage.getItem("_token")}`);
      fetchUserDetails();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchAccessRequest(accessRequestTab);
    }
  }, [accessRequestTab, isLoggedIn]);

  const showLogOutModal = () => {
    setIsLogOutModalOpen(true);
  };

  const handleLogOutModalOk = () => {
    setIsLogOutModalOpen(false);
  };

  const handleLogOutModalCancel = () => {
    setIsLogOutModalOpen(false);
  };

  const handleTabClick = (key) => {
    setIsTabActive(key);
  };

  const logoutHandler = () => {
    localStorage.clear();
    navigate("/login");
    dispatch(storeAuthUserDetails({}));
  };

  const handleSelectChange = (value, name) => {
    setAccessRequestTab((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleViewDetails = async (data) => {
    if (data?.product_request?.product_fk || data?.product_fk) {
      if (
        data?.product_request?.product_details?.is_added_to_shop === 1 ||
        data?.product_details?.is_added_to_shop === 1
      ) {
        navigate(
          `/dashboard/my-shop/product-details/${
            data?.product_fk || data?.product_request?.product_fk
          }`
        );
      } else {
        localStorage.setItem(
          "data",
          JSON.stringify({
            id: data?.room_fk,
            type: "PRODUCT",
          })
        );
        navigate("/dashboard/messaging");
      }
    }

    if (data?.product_request?.service_fk || data?.service_fk) {
      if (
        data?.product_request?.service_details?.is_added_to_shop === 1 ||
        data?.service_details?.is_added_to_shop === 1
      ) {
        navigate(
          `/dashboard/my-shop/service-details/${
            data?.service_fk || data?.product_request?.service_fk
          }`
        );
      } else {
        localStorage.setItem(
          "data",
          JSON.stringify({
            id: data?.room_fk,
            type: "SERVICE",
          })
        );
        navigate("/dashboard/messaging");
      }
    }

    if (data?.product_request?.collection_fk || data?.collection_fk) {
      if (
        data?.product_request?.collection_details?.is_added_to_shop === 1 ||
        data?.collection_details?.is_added_to_shop === 1
      ) {
        navigate(
          `/dashboard/my-shop/view-all-collections/view-all-products-collections/${
            data?.collection_fk || data?.product_request?.collection_f
          }`
        );
      } else {
        localStorage.setItem(
          "data",
          JSON.stringify({
            id: data?.room_fk,
            type: "PRODUCT",
          })
        );
        navigate("/dashboard/messaging");
      }
    }
    await fetchMarkSingleProduct(data);
  };

  const handleNavigateAccessRequest = async (data) => {
    navigate("/dashboard/my-profile/my-access-request");
    await fetchMarkSingleProduct(data);
  };

  const fetchUserDetails = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, userDetails: true }));

      if (!userDetails || Object?.keys(userDetails)?.length === 0) {
        const userDetailsResponse = await doFetchUserDetails();

        if (userDetailsResponse?.status === 200) {
          const decryptedUserDetails = await JSON.parse(
            getDecryptionString(userDetailsResponse?.data?.data)
          );
          setUserProfileDetails(decryptedUserDetails);
          dispatch(storeAuthUserDetails(decryptedUserDetails));
        } else {
          toast.error(userDetailsResponse?.data?.message);
        }
      } else {
        setUserProfileDetails(userDetails);
      }
      setIsLoading((prev) => ({ ...prev, userDetails: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prev) => ({ ...prev, userDetails: false }));
    }
  };

  const fetchAccessRequest = async (data) => {
    try {
      setIsLoading((prev) => ({ ...prev, accessRequestList: true }));

      const accessRequestResponse = await doFetchAccessRequest(
        "",
        data?.type,
        true
      );

      if (accessRequestResponse?.status === 200) {
        const decryptedAccessRequest = await JSON.parse(
          getDecryptionString(accessRequestResponse?.data?.data)
        );

        setAccessRequestList(decryptedAccessRequest);
      } else {
        toast.error(accessRequestResponse?.data?.message);
      }
      setIsLoading((prev) => ({ ...prev, accessRequestList: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prev) => ({ ...prev, accessRequestList: false }));
    }
  };

  const handleAccessRequest = async (data, type) => {
    try {
      setIsLoading((prev) => ({ ...prev, sendAccessRequest: true }));

      const requestBody = {
        requestId: data?.id,
        status: type,
      };
      setSelectedRequest(data);

      const accessRequestResponse = await doFetchRequestAction(requestBody);

      if (accessRequestResponse?.status === 200) {
        toast.success(accessRequestResponse?.data?.message);
        fetchAccessRequest(accessRequestTab);
      } else {
        toast.error(accessRequestResponse?.data?.message);
      }
      setIsLoading((prev) => ({ ...prev, sendAccessRequest: false }));
      setSelectedRequest({});
    } catch (error) {
      console.log({ error });
      setIsLoading((prev) => ({ ...prev, sendAccessRequest: false }));
    }
  };

  const fetchMarkAllAsRead = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, markAllRead: true }));

      const markAllReadResponse = await doFetchMarkAllAsRead({
        IsMarkAll: true,
      });

      if (markAllReadResponse?.status === 200) {
        fetchAccessRequest(accessRequestTab);
      } else {
        toast.error(markAllReadResponse?.data?.message);
      }
      setIsLoading((prev) => ({ ...prev, markAllRead: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prev) => ({ ...prev, markAllRead: false }));
    }
  };

  const fetchMarkSingleProduct = async (data) => {
    try {
      setIsLoading((prev) => ({ ...prev, markSingleProduct: true }));

      const markAllReadResponse = await doFetchMarkAllAsRead({
        notificationId: data?.Notification?.id || data?.id,
      });

      if (markAllReadResponse?.status === 200) {
        fetchAccessRequest(accessRequestTab);
      } else {
        toast.error(markAllReadResponse?.data?.message);
      }
      setIsLoading((prev) => ({ ...prev, markSingleProduct: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prev) => ({ ...prev, markSingleProduct: false }));
    }
  };

  const handleAddToShop = async (data) => {
    try {
      setIsAddToShopLoading(true);
      let addProductServiceResponse;

      const requestBody = {
        ...(data?.service_fk !== null
          ? {
              serviceId: data?.service_fk,
            }
          : data?.product_fk !== null
          ? { productId: data?.product_fk }
          : { brandCollectionId: data?.collection_fk }),
      };

      if (data?.service_fk !== null) {
        addProductServiceResponse = await doFetchAddService(requestBody);
      } else if (data?.product_fk !== null) {
        addProductServiceResponse = await doFetchAddProduct(requestBody);
      } else {
        addProductServiceResponse = await doFetchAddCollection(requestBody);
      }

      if (addProductServiceResponse?.status === 200) {
        toast.success(
          `${
            data?.service_fk !== null
              ? "Tjänste"
              : data?.product_fk !== null
              ? "Produkt"
              : "kollection"
          } har lagts till i din butik framgångsrikt`
        );
        fetchAccessRequest(accessRequestTab);
      } else {
        toast.error(addProductServiceResponse?.message);
      }

      setIsAddToShopLoading(false);
    } catch (error) {
      console.log({ error });
      setIsAddToShopLoading(false);
    }
  };

  return {
    isLoading,
    isTabActive,
    selectedRequest,
    accessRequestTab,
    accessRequestList,
    isLogOutModalOpen,
    isAddToShopLoading,
    userProfileDetails,
    navigate,
    logoutHandler,
    handleTabClick,
    handleAddToShop,
    showLogOutModal,
    handleViewDetails,
    handleSelectChange,
    fetchMarkAllAsRead,
    handleLogOutModalOk,
    handleAccessRequest,
    handleLogOutModalCancel,
    handleNavigateAccessRequest,
  };
};
