import { Col, Row } from "antd";
import React from "react";
import { SwiperSlide } from "swiper/react";
import { clsx } from "clsx";
import { featuredProductBreakPoints } from "../../constants/data";
import {
  ILProductCard,
  ILSwiper,
  ILCopyLinkModalFlow,
  ILHead,
  ILButton,
  ILNoDataCard,
  ILReadMoreLess,
  ILSendAccessReqModal,
  ILPagination,
  ILModal,
  ILSkeleton,
} from "../../components";
import { Link } from "react-router-dom";
import { ArrowLeft02Icon } from "hugeicons-react";
import styles from "./ILProductDetails.module.css";
import { useProductDetailsHook } from "../../hooks/website/productDetails.hook";
import { capitalize } from "lodash";
import { defaultUser, gradientCircleRound } from "../../constants/imageData";
import { isEmpty } from "../../services";

const ILProductDetails = () => {
  const {
    isLoading,
    isProduct,
    isLoggedIn,
    productData,
    productList,
    paginationData,
    relatedProductList,
    modalPaginationData,
    isSuccessfullyReqModalOpen,
    navigate,
    loginHandler,
    setIsAddedToShop,
    handleTableChange,
    sendAccessRequest,
    handleSRModalClose,
    handleSetProductId,
    setModalPaginationData,
  } = useProductDetailsHook();

  return (
    <>
      <ILHead title="Product details" />
      <section className="default-inner-hero bg-dark-purple position-relative">
        <div className="container">
          <div className="d-flex align-items-center gap-3">
            <Link to="/brands">
              <ArrowLeft02Icon
                size={34}
                color={"#FFFFFF"}
                variant={"stroke"}
                strokeWidth={2}
              />
            </Link>
            <h4 className="p big text-white fw-semibold lh-22 mb-0">Företag</h4>
          </div>
        </div>
        <div className="position-absolute start-0 w-100 z-0 bg-dark-purple default-hero-before-layer hero-gradient-border"></div>
      </section>
      {/* PRODUCT DETAILS SECTION START */}
      <section className="">
        <div className="container">
          <Row
            className="align-items-center"
            gutter={[
              { xs: 15, sm: 20, md: 25, lg: 30 },
              { xs: 15, sm: 20, md: 25, lg: 30 },
            ]}
          >
            <Col xs={22} sm={18} md={10} className="mx-md-0 mx-auto">
              <div
                className={clsx(
                  styles.productDetailsSwiperImage,
                  "text-center w-100 overflow-hidden h-100 d-flex align-itms-center justify-content-center"
                )}
              >
                <img
                  src={isLoading?.detail ? defaultUser : productData?.image}
                  alt={productData?.productName}
                  className={
                    "object-fit-contain object-position-center w-100 h-100 aspect-ratio-1-1"
                  }
                />
              </div>
            </Col>
            <Col xs={24} md={14}>
              {isLoading?.detail ? (
                <ILSkeleton isImgTitleLabelCard2 />
              ) : (
                <div className={clsx(styles.productDetailsCard, "bg-white")}>
                  <div className="d-flex align-items-center flex-wrap gap-4">
                    <div className="d-flex align-items-center gap-4">
                      <div
                        className={clsx(
                          styles.brandImageWrap,
                          "w-100 d-flex align-items-center justify-content-center ratio ratio-1x1 ratio-w-100"
                        )}
                      >
                        <img
                          width={"100%"}
                          height={"100%"}
                          src={productData?.brand?.profile_image}
                          alt="advertising agency | ilonsi"
                          className="object-fit-contain"
                        />
                      </div>
                      <h6 className="mb-0">
                        {capitalize(productData?.brand?.name)}
                      </h6>
                    </div>
                    <div
                      className={clsx(
                        styles.ILProductDetailsCardLabel,
                        "ms-auto"
                      )}
                    >
                      <p className="sm mb-0 fw-semibold gradient-text">
                        CPC : {productData?.ppc_price} KR
                      </p>
                      {!isEmpty(productData?.sell_commission) && (
                        <p className="sm mb-0 fw-semibold gradient-text">
                          SELL : {productData?.sell_commission + " %"}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="my-5 d-flex flex-column gap-4 gap-md-3">
                    <span className="md-secondary-title xs-secondary-title mb-0 text-ellipsis line-clamp-2 word-break-word">
                      {capitalize(productData?.name)}
                    </span>

                    <ILReadMoreLess
                      text={
                        productData?.description === "null" ||
                        productData?.description === null
                          ? ""
                          : productData?.description
                      }
                      sliceLength={400}
                      sliceClassName="custom-text-class"
                      className="big text-gray custom-text-class word-break-word"
                      onClick={(e) => e.stopPropagation()}
                    />
                  </div>
                  <div>
                    <span className="p big d-block fw-500 text-gray mb-3">
                      Pris
                    </span>
                    <h5 className="p big fw-semibold mb-0">
                      {productData?.price} KR
                    </h5>
                  </div>
                  <div className="mt-5 pt-4 d-flex gap-5 flex-wrap flex-sm-nowrap">
                    {isLoggedIn ? (
                      productData?.has_access === "ACCESS_NEEDED" ||
                      productData?.has_access === "ACCESS_DENIED" ? (
                        <ILSendAccessReqModal
                          sendAccessRequest={sendAccessRequest}
                          data={productData}
                          isRequestSentLoading={isLoading?.isRequestSent}
                        />
                      ) : productData?.has_access === "NO_REQUEST_NEEDED" ||
                        productData?.has_access === "ACCESS_GRANTED" ? (
                        <ILCopyLinkModalFlow
                          data={productData}
                          productListData={relatedProductList}
                          isService={isProduct ? false : true}
                          isModalLoading={isLoading?.relatedProducts}
                          setIsAddedToShop={setIsAddedToShop}
                          paginationData={modalPaginationData}
                          setPaginationData={setModalPaginationData}
                          handleTableChange={handleTableChange}
                        />
                      ) : productData?.has_access === "PENDING_REQUEST" ? (
                        <ILButton
                          disabled={true}
                          isSmallSizeBtn
                          isArrow
                          ILBtnClass={"w-100 text-clr-pink"}
                          variant={"gradient-bordered"}
                        >
                          Förfrågan Skickad
                        </ILButton>
                      ) : (
                        <ILButton
                          disabled
                          isSmallSizeBtn
                          isArrow
                          ILBtnClass={"w-100 text-clr-pink"}
                          variant={"gradient-bordered"}
                        >
                          Requested Access Denied
                        </ILButton>
                      )
                    ) : (
                      <ILButton
                        isSmallSizeBtn
                        ILBtnClass={"w-100 text-clr-pink text-uppercase p sm"}
                        variant={"gradient-bordered"}
                        handleClick={loginHandler}
                      >
                        Logga in för att kopiera länken
                      </ILButton>
                    )}
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </section>
      {/* PRODUCT DETAILS SECTION END */}

      {/* RELATED PRODUCTS SECTION START */}
      <section className="section-md-py">
        <div className="container">
          <div className="d-flex align-items-center flex-wrap gap-4">
            <span className="md-secondary-title d-block">
              Relaterad {productData?.brand?.name}{" "}
              {isProduct ? "Produkter" : "Tjänster"}
            </span>
          </div>

          <div className="position-relative">
            {isLoading?.productList ? (
              <div className="row mt-2">
                <div className="col-md-3 skeleton-card border">
                  <ILSkeleton isImgTitleLabelCard2 />
                </div>
              </div>
            ) : (
              productList?.rows?.length > 0 && (
                <ILSwiper
                  leftArrowClassName="left-related-products-arrow"
                  rightArrowClassName="right-related-products-arrow"
                  swiperButtonClassName="d-none d-md-block"
                  spaceBetween={20}
                  slidesPerView={4}
                  autoplay={false}
                  loop={false}
                  breakpoints={featuredProductBreakPoints}
                  grabCursor={true}
                >
                  {productList?.rows?.map((FPItem, FPIndex) => {
                    return (
                      <SwiperSlide key={FPIndex}>
                        <ILProductCard
                          isProductLabel
                          isService={!isProduct}
                          productDataShow={FPItem}
                          isLoggedIn={isLoggedIn}
                          loginHandler={loginHandler}
                          setIsAddedToShop={setIsAddedToShop}
                          productList={relatedProductList}
                          isModalLoading={isLoading?.relatedProducts}
                          handleProductDetail={handleSetProductId}
                          sendAccessRequest={sendAccessRequest}
                          paginationData={modalPaginationData}
                          setPaginationData={setModalPaginationData}
                          handleTableChange={handleTableChange}
                          isRequestSentLoading={isLoading?.isRequestSent}
                        />
                      </SwiperSlide>
                    );
                  })}
                </ILSwiper>
              )
            )}

            {!isLoading?.productList &&
              (productList?.hasPrevious || productList?.hasNext) && (
                <ILPagination
                  defaultCurrent={paginationData?.page}
                  total={productList?.totalItems}
                  onChange={(event) =>
                    handleTableChange(event, "relatedProducts")
                  }
                  defaultPageSize={paginationData?.pageSize}
                  className="flex-0-auto ms-md-auto mt-5"
                />
              )}
          </div>

          {!isLoading?.productList && productList?.rows?.length === 0 && (
            <ILNoDataCard
              isIcon
              className="position-relative z-3 mt-5"
              title={"Inga relaterade produkter hittades"}
            />
          )}
        </div>
      </section>

      <ILModal
        open={isSuccessfullyReqModalOpen}
        onOk={handleSRModalClose}
        onCancel={handleSRModalClose}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <span className="lh-0">
                <img
                  src={gradientCircleRound}
                  fluid
                  alt="advertising agency | ilonsi"
                />
              </span>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Förfrågan har skickats!
              </h4>
              <ILButton
                ILBtnClass="w-100"
                variant={"primary-gradient"}
                handleClick={() =>
                  navigate("/dashboard/my-profile/my-access-request")
                }
              >
                Hantera förfrågningars
              </ILButton>
            </div>
          </>
        }
        isFooterRemove
      />
    </>
  );
};

export default ILProductDetails;
