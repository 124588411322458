import { RootLayout } from "../layout/RootLayout";
import { createBrowserRouter } from "react-router-dom";
import { AuthenticationLayout } from "../layout/AuthenticationLayout";
import ILStyleguide from "../pages/ILStyleguide";
import ILLogin from "../pages/AuthenticationPages/ILLogin";
import ILForgotPassword from "../pages/AuthenticationPages/ILForgotPassword";
import ILResetPassword from "../pages/AuthenticationPages/ILResetPassword";
import ILSignUp from "../pages/AuthenticationPages/ILSignUp";
import ILAboutUs from "../pages/ILAboutUs";
import ILBrandsList from "../pages/ILBrandsList";
import ILInfluencers from "../pages/ILInfluencers";
import ILBrands from "../pages/ILBrands";
import ILProductDetails from "../pages/ILProductDetails";
import ILFaqDetails from "../pages/ILFaqDetails";
import ILBrandProducts from "../pages/ILBrandProducts";
import ILSearchResults from "../pages/ILSearchResults";
import ILProductSearchFilter from "../pages/ILProductSearchFilter";
import ILHome from "../pages/ILHome";
import ILBrandCollections from "../pages/ILBrandCollections";
import { DashboardLayout } from "../layout/DashboardLayout";
import ILDashboard from "../pages/DashboardPages/ILDashboard";
import ILMyShop from "../pages/DashboardPages/ILMyShop";
import ILMessaging from "../pages/DashboardPages/ILMessaging";
import { ILEmailVerification } from "../pages/AuthenticationPages";
import { LiveProfileLayout } from "../layout/LiveProfileLayout";
import ILViewCollection from "../pages/LiveProfilePages/ILViewCollection";
import ILLiveProfile from "../pages/LiveProfilePages/ILLiveProfile";
import { ILMyShopProductDetails } from "../pages/DashboardPages/ILMyShop/ILMyShopProductDetails";
import { ILMyShopViewAllCollections } from "../pages/DashboardPages/ILMyShop/ILMyShopViewAllCollections";
import ILEditProfile from "../pages/DashboardPages/ILEditProfile";
import ILMyAccessRequest from "../pages/DashboardPages/ILMyAccessRequest";
import ILSupportAndHelp from "../pages/DashboardPages/ILSupportAndHelp";
import ILMyPayment from "../pages/DashboardPages/ILMyPayment";
import ILSettings from "../pages/DashboardPages/ILSettings";
import MyShopViewAllProducts from "../pages/DashboardPages/ILMyShop/MyShopViewAllProducts";
import MyShopViewAllServices from "../pages/DashboardPages/ILMyShop/MyShopViewAllServices";
import ILMyProductsViewAllProductsCollection from "../pages/DashboardPages/ILMyShop/ILMyShopViewAllProductCollections";
import { ProtectedRoute } from "./ProtectedRoute";
import NotFoundPage from "../pages/NotFoundPage";
import ComingSoonPage from "../pages/ComingSoonPage";
import ILEmptyPage from "../pages/EmptyPage";
import { ILPrivacyPolicy } from "../pages/ILPrivacyPolicy";
import { ILTermsCondition } from "../pages/ILTermsCondition";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute isAuth={false} role="influencer">
        <AuthenticationLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/login",
        element: <ILLogin />,
      },
      {
        path: "/forgot-password",
        element: <ILForgotPassword />,
      },
      {
        path: "/reset-password",
        element: <ILResetPassword />,
      },
      {
        path: "/sign-up",
        element: <ILSignUp />,
      },
      {
        path: "/email-verification",
        element: <ILEmailVerification />,
      },
    ],
  },
  {
    element: <RootLayout />,
    path: "/",
    children: [
      {
        index: true,
        element: <ILHome />,
      },
      {
        path: "/styleguide",
        element: <ILStyleguide />,
      },
      {
        path: "/about-us",
        element: <ILAboutUs />,
      },
      {
        path: "/brands-list",
        element: <ILBrandsList />,
      },
      {
        path: "/brands-list/:id",
        element: <ILBrandsList />,
      },
      {
        path: "/influencers",
        element: <ILInfluencers />,
      },
      {
        path: "/faq",
        element: <ILFaqDetails />,
      },
      {
        path: "/brands",
        children: [
          {
            index: true,
            element: <ILBrands />,
          },
          {
            path: "product-details",
            element: <ILProductDetails />,
          },
          {
            path: "product-details/:id",
            element: <ILProductDetails />,
          },
          {
            path: "service-details/:id",
            element: <ILProductDetails />,
          },
        ],
      },
      {
        path: "/brand-info",
        element: <ILBrandProducts />,
      },
      {
        path: "/brand-info/:id",
        element: <ILBrandProducts />,
      },
      {
        path: "/search-results",
        element: <ILSearchResults />,
      },
      {
        path: "/search-results/:id",
        element: <ILSearchResults />,
      },
      {
        path: "/product-search-filter",
        element: <ILProductSearchFilter />,
      },
      {
        path: "/brand-collections/:id",
        element: <ILBrandCollections />,
      },
      {
        path: "/privacy-policy",
        element: <ILPrivacyPolicy />,
      },
      {
        path: "terms-of-use",
        element: <ILTermsCondition />,
      },
    ],
  },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute isAuth={true} role="influencer">
        <DashboardLayout />
      </ProtectedRoute>
    ),

    children: [
      {
        index: true,
        element: <ILDashboard />,
      },
      {
        path: "my-shop",
        children: [
          {
            index: true,
            element: <ILMyShop />,
          },
          {
            path: "product-details",
            element: <ILMyShopProductDetails />,
          },
          {
            path: "product-details/:id",
            element: <ILMyShopProductDetails />,
          },
          {
            path: "service-details/:id",
            element: <ILMyShopProductDetails />,
          },
          {
            path: "view-all-collections",
            element: <ILMyShopViewAllCollections />,
          },
          {
            path: "view-all-products",
            element: <MyShopViewAllProducts />,
          },
          {
            path: "view-all-services",
            element: <MyShopViewAllServices />,
          },
          {
            path: "view-all-collections/view-all-products-collections/:id",
            element: <ILMyProductsViewAllProductsCollection />,
          },
        ],
      },
      {
        path: "messaging",
        element: <ILMessaging />,
      },
      {
        path: "my-profile",
        children: [
          {
            path: "edit-profile",
            element: <ILEditProfile />,
          },
          {
            path: "my-access-request",
            element: <ILMyAccessRequest />,
          },
          {
            path: "support-and-help",
            element: <ILSupportAndHelp />,
          },
          {
            path: "my-payment",
            element: <ILMyPayment />,
          },
          {
            path: "settings",
            element: <ILSettings />,
          },
        ],
      },
    ],
  },
  {
    path: "/collection/:shortCode",
    element: <ILViewCollection />,
  },
  {
    element: <LiveProfileLayout />,
    path: "",
    children: [
      {
        path: "/shop/:userName",
        element: <ILLiveProfile />,
      },
      {
        path: "/live-profile",
        element: <ILLiveProfile />,
      },
    ],
  },

  {
    path: "*",
    element: <NotFoundPage />,
  },
  {
    path: "/coming-soon",
    element: <ComingSoonPage />,
  },
  {
    path: "/:shortCode",
    element: <ILEmptyPage />,
  },
]);
