import IntlTelInput from "intl-tel-input/reactWithUtils";
import "intl-tel-input/styles";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import clsx from "clsx";
import styles from "./ILCountryCodeInput.module.css";

export const ILCountryCodeInput = ({
  value,
  defaultCountry,
  countryCallingCodeEditable = false,
  placeholder,
  classname,
  label,
  handleChange,
  isRequired,
  errorMsg,
  name,
  // new
  id,
  isShowNew,
  initialCountry,
}) => {
  return (
    <>
      {isShowNew ? (
        <>
          {/* new country number select dropdown start */}
          <div
            className={clsx(
              styles.countryCodeWrap,
              styles.newCountryCodeWrap,
              "w-100"
            )}
          >
            {label && (
              <label
                className={clsx(
                  styles.countryCodeLabel,
                  "fw-medium text-dark-purple d-block"
                )}
              >
                {label}
                {isRequired && <span className="text-tomato">*</span>}
              </label>
            )}
            <IntlTelInput
              // onChangeValidity={setIsValid}
              id={id}
              name={name}
              value={value}
              onChangeNumber={handleChange}
              initOptions={{
                initialCountry: initialCountry,
                separateDialCode: true,
                containerClass: clsx(
                  styles.newCountryCodeSelect,
                  classname,
                  "w-100"
                ),
              }}
            />
            {errorMsg && (
              <span className="p sm mt-3 lh-1 text-tomato fw-medium d-block mb-3">
                {errorMsg}
              </span>
            )}
          </div>
          {/* new country number select dropdown end */}
        </>
      ) : (
        <>
          <div className={clsx(styles.countryCodeWrap, "w-100")}>
            {label && (
              <label
                className={clsx(
                  styles.countryCodeLabel,
                  "fw-medium text-dark-purple d-flex gap-2 gap-xxl-1"
                )}
              >
                {label}
                {isRequired && <span className="text-tomato">*</span>}
              </label>
            )}
            <PhoneInput
              name={name}
              placeholder={placeholder}
              international
              countryCallingCodeEditable={countryCallingCodeEditable}
              value={value}
              onChange={handleChange}
              className={clsx(styles.countryCodeSelect, classname)}
              defaultCountry={defaultCountry}
            />
            {errorMsg && (
              <span className="p sm mt-3 lh-1 text-tomato fw-medium d-block mb-3">
                {errorMsg}
              </span>
            )}
          </div>
        </>
      )}
    </>
  );
};
