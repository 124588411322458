import React from "react";
import { Form } from "antd";
import { authBannerOne, ilonsiLogo } from "../../../constants/imageData";
import { ILTextInput, ILButton, ILHead, ILLoader } from "../../../components";
import {
  Mail01Icon,
  SquareLockPasswordIcon,
  ViewIcon,
  ViewOffIcon,
} from "hugeicons-react";
import { Link } from "react-router-dom";
import { useLoginHook } from "../../../hooks/auth/login.hook";

const ILLogin = () => {
  const {
    loginData,
    isLoading,
    isShowIcon,
    validateMessages,
    handleIconChange,
    handleInputChange,
    handleLoginSubmit,
    handleRedirectHome,
  } = useLoginHook();

  return (
    <>
      <ILHead title="Login" />
      <section className="authentication-section">
        <div className="authentication-flex d-flex flex-wrap">
          <div className="authentication-col d-flex overflow-y-auto order-2 order-lg-1 py-5 py-xl-4">
            <div className="m-auto authentication-sm-box">
              <img
                src={ilonsiLogo}
                alt="advertising agency | ilonsi"
                className="auth-logo cursor-pointer"
                onClick={handleRedirectHome}
              />
              <h1 className="secondary-title md-secondary-title">
                Välkommen tillbaka till influencer inloggningen
              </h1>
              <p className="paragraph-sm-text paragraph-md-text">
                För att fortsätta till ILONSI, vänligen ange följande uppgifter.
              </p>
              <Form>
                <ILTextInput
                  isRequiredInput
                  prefix={
                    <Mail01Icon
                      size={24}
                      color={"#1d1128"}
                      variant={"stroke"}
                      strokeWidth={1.5}
                    />
                  }
                  id="EPostadress"
                  datatestid="EPostadress"
                  type="email"
                  label="E-postadress"
                  placeholder={"Ange din e-postadress"}
                  name="email"
                  value={loginData?.email}
                  handleChange={handleInputChange}
                  errorMsg={validateMessages?.email}
                />
                <ILTextInput
                  isRequiredInput
                  prefix={
                    <SquareLockPasswordIcon
                      size={24}
                      color={"#1d1128"}
                      variant={"stroke"}
                      strokeWidth={1.5}
                    />
                  }
                  suffix={
                    isShowIcon ? (
                      <span
                        onClick={handleIconChange}
                        className="cursor-pointer"
                      >
                        <ViewOffIcon
                          size={24}
                          color={"#1d1128"}
                          variant={"stroke"}
                          strokeWidth={1.5}
                        />
                      </span>
                    ) : (
                      <span
                        onClick={handleIconChange}
                        className="cursor-pointer"
                      >
                        <ViewIcon
                          size={24}
                          color={"#1d1128"}
                          variant={"stroke"}
                          strokeWidth={1.5}
                        />
                      </span>
                    )
                  }
                  isSuffixIconPresent
                  id="loginPassword"
                  datatestid="loginPassword"
                  type={isShowIcon ? "text" : "password"}
                  label="Lösenord"
                  placeholder={"Ange ditt lösenord"}
                  name="password"
                  value={loginData?.password}
                  handleChange={handleInputChange}
                  errorMsg={validateMessages?.password}
                />
                <div className="text-end">
                  <Link
                    to="/forgot-password"
                    className="p big gradient-text fw-medium d-inline-flex"
                  >
                    Glömt lösenord
                  </Link>
                </div>
                <ILButton
                  disabled={isLoading}
                  handleClick={handleLoginSubmit}
                  ILBtnClass="w-100"
                  variant={"primary-gradient"}
                >
                  {isLoading ? <ILLoader isBtn /> : "Logga in"}
                </ILButton>
                <p className="p fw-medium text-shark-fin text-center mb-0">
                  Har du inget Influencer konto?&nbsp;&nbsp;
                  <Link to="/sign-up">Bli Medlem</Link>
                </p>
              </Form>
            </div>
          </div>
          <div className="authentication-col bg-dark authentication-right-col order-1 order-lg-2 mb-5 mb-lg-0">
            <img
              src={authBannerOne}
              alt="advertising agency | ilonsi"
              className="w-100 h-100 object-fit-cover"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default ILLogin;
