import clsx from "clsx";
import {
  ILProductCard,
  ILTab,
  ILHead,
  ILDashboardGalleryCard,
  ILLoader,
  ILTextInput,
  ILButton,
  ILAccordion,
  ILSwiper,
  ILSkeleton,
} from "../../../components";
import { useLiveProfileHook } from "../../../hooks/dashboard/myShop/liveProfile.hook";
import { FilterIcon, Search01Icon } from "hugeicons-react";
import { Link } from "react-router-dom";
import { Icons } from "../../../constants/icons";
import { ILCSFilterCheckboxList } from "../../ILCSFilterCheckboxList/ILCSFilterCheckboxList";
import { SwiperSlide } from "swiper/react";
import { productBrandCollectionBreakPoints } from "../../../constants/data";
import { InView } from "react-intersection-observer";
import styles from "./ILLiveProfile.module.css";

const ILLiveProfile = () => {
  const {
    category,
    brandList,
    isLoading,
    citiesList,
    countryList,
    serviceList,
    productList,
    categoryList,
    setFilterData,
    collectionList,
    servicePaginationData,
    productPaginationData,
    productServiceFilterData,
    ref,
    navigate,
    observerScroll,
    showFilterDataWrap,
    hideFilterDataWrap,
    handleInputChange,
    handleSelectChange,
    handleProductDetail,
    handleClearAllFilters,
    handleFilterInputChange,
    handleApplyFilterModalCancel,
    handleViewCollectionProducts,
  } = useLiveProfileHook();

  const liveProfileData = [
    {
      key: "product",
      label: "Produkter",
      children: (
        <div className={clsx(styles.liveProfileProductTabWrap, "w-100")}>
          <div
            className={clsx(
              styles.liveProfileSearchFilterHead,
              "py-4 d-flex align-items-center position-sticky top-0 bg-clr-walkie-chalkie z-3 mb-5 mb-md-0 gap-3 filterDataHead"
            )}
          >
            <ILTextInput
              ILInputLabelClass={"search-input search-input-full-sm ms-auto"}
              placeholder={"Sök Produkt eller Kollektioner"}
              type={"search"}
              name={"search"}
              value={productPaginationData?.search}
              handleChange={(event) => handleInputChange(event, "product")}
              prefix={
                <Search01Icon
                  size={20}
                  color={"var(--clr-dark-purple)"}
                  variant={"stroke"}
                />
              }
              allowClear
            />
            <ILButton
              variant={"gradient-bordered"}
              handleClick={showFilterDataWrap}
              ILBtnClass="btn-small min min-w-auto"
              ILBtnInnerSpanClass="d-flex align-items-center"
            >
              <b className="lh-0 me-2 text-clr-purple">
                <FilterIcon size={20} variant={"stroke"} />
              </b>
              Filtrera
            </ILButton>
          </div>
          <div className="w-100">
            {collectionList?.totalItems > 0 && (
              <>
                <div className="mb-md-5 pb-md-5">
                  <h4 className="fw-semibold d-block">Kollektioner</h4>
                  <div className={clsx(styles.productBrandCollectionWrap)}>
                    {isLoading?.collectionList ? (
                      <div className="skeleton-card">
                        <ILSkeleton isImgTitleLabelCard2 isNotNeed />
                      </div>
                    ) : (
                      <ILSwiper
                        className={clsx(
                          styles.productBrandCollectionRow,
                          "md-mx-0"
                        )}
                        spaceBetween={20}
                        slidesPerView={6}
                        autoplay={false}
                        loop={false}
                        breakpoints={productBrandCollectionBreakPoints}
                        leftArrowClassName={
                          "product-brand-collections-left-nav"
                        }
                        rightArrowClassName={
                          "product-brand-collections-right-nav"
                        }
                        grabCursor={true}
                        isNotDefaultSwiperSlider
                      >
                        {collectionList?.totalItems > 0 && (
                          <>
                            {collectionList?.rows?.map((item, index) => {
                              return (
                                <SwiperSlide>
                                  <div className="w-100" key={index}>
                                    <ILDashboardGalleryCard
                                      isLiveProfile
                                      ILProductGalleryCardItem={item}
                                      customGalleryClassname="product-collection-card h-100 w-100"
                                      customGallerySliderClassname="product-collection-slider"
                                      customMainGalleryImgClassname="product-collection-img"
                                      navigate={navigate}
                                      handleViewCollectionProducts={
                                        handleViewCollectionProducts
                                      }
                                      // navigation
                                      autoplay={true}
                                      pagination={{ dynamicBullets: true }}
                                    />
                                  </div>
                                </SwiperSlide>
                              );
                            })}
                            {collectionList?.hasNext && (
                              <SwiperSlide>
                                <InView
                                  as="div"
                                  onChange={(inView, entry) =>
                                    inView &&
                                    observerScroll(entry, "collection")
                                  }
                                >
                                  <div
                                    ref={ref}
                                    className={clsx(
                                      styles.swiperSlideLoader,
                                      "w-100 h-100 text-center position-relative py-5 d-flex align-items-center justify-content-center"
                                    )}
                                  >
                                    <ILLoader
                                      classname={
                                        "loader-wrap-absolute bg-transparent"
                                      }
                                      isLoaderBgOpacitySm
                                      isChatLoader
                                    />
                                  </div>
                                </InView>
                              </SwiperSlide>
                            )}
                          </>
                        )}
                      </ILSwiper>
                    )}
                  </div>
                </div>
              </>
            )}

            {isLoading?.productList ? (
              <div className="skeleton-card">
                <ILSkeleton isImgTitleLabelCard2 />
              </div>
            ) : (
              productList?.totalItems > 0 && (
                <div className={clsx(styles.myProductsWrapper, "mt-md-5")}>
                  <div className={clsx(styles.myProductCardWrap, "mt-0")}>
                    {productList?.rows?.map((MPItem, MPIndex) => {
                      return (
                        <ILProductCard
                          isLiveProfile
                          productDataShow={MPItem}
                          key={MPIndex}
                          productCardClassname={clsx(
                            "live-profile-product-card bg-white"
                          )}
                          handleProductDetail={handleProductDetail}
                        />
                      );
                    })}
                  </div>

                  {productList?.hasNext && (
                    <InView
                      as="div"
                      onChange={(inView, entry) =>
                        inView && observerScroll(entry, "product")
                      }
                    >
                      <div
                        ref={ref}
                        className={clsx(
                          styles.swiperSlideLoader,
                          "w-100 h-100 text-center position-relative py-5 d-flex align-items-center justify-content-center"
                        )}
                      >
                        <ILLoader
                          classname={"loader-wrap-absolute bg-transparent"}
                          isLoaderBgOpacitySm
                          isChatLoader
                        />
                      </div>
                    </InView>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      ),
    },
    ,
    {
      key: "service",
      label: "Tjänster",
      children: (
        <>
          <div
            className={clsx(
              styles.liveProfileSearchFilterHead,
              "py-4 d-flex align-items-center position-sticky top-0 bg-clr-walkie-chalkie z-3 mb-3 mb-md-0 gap-3 filterDataHead"
            )}
          >
            <ILTextInput
              ILInputLabelClass={"search-input search-input-full-sm ms-auto"}
              placeholder={"Sök Tjänster"}
              type={"search"}
              name={"search"}
              value={servicePaginationData?.search}
              handleChange={(event) => handleInputChange(event, "service")}
              prefix={
                <Search01Icon
                  size={20}
                  color={"var(--clr-dark-purple)"}
                  variant={"stroke"}
                />
              }
              allowClear
            />
            <ILButton
              variant={"gradient-bordered"}
              handleClick={showFilterDataWrap}
              ILBtnClass="btn-small min min-w-auto"
              ILBtnInnerSpanClass="d-flex align-items-center"
            >
              <b className="lh-0 me-2 text-clr-purple">
                <FilterIcon size={20} variant={"stroke"} />
              </b>
              Filtera
            </ILButton>
          </div>
          {isLoading?.serviceList ? (
            <div className="skeleton-card">
              <ILSkeleton isImgTitleLabelCard2 />
            </div>
          ) : (
            serviceList?.totalItems > 0 && (
              <>
                <div
                  className={clsx(styles.myServicesCardWrap, "d-grid bg-white")}
                >
                  {serviceList?.rows?.map((LPSItem, LPSIndex) => {
                    return (
                      <ILProductCard
                        isService
                        isLiveProfile
                        productDataShow={LPSItem}
                        key={LPSIndex}
                        isRatingAndAddressPresent
                        isPriceChanged
                        handleProductDetail={handleProductDetail}
                      />
                    );
                  })}
                </div>

                {serviceList?.hasNext && (
                  <InView
                    as="div"
                    onChange={(inView, entry) =>
                      inView && observerScroll(entry, "service")
                    }
                  >
                    <div
                      ref={ref}
                      className={clsx(
                        styles.swiperSlideLoader,
                        "w-100 h-100 text-center position-relative py-5 d-flex align-items-center justify-content-center"
                      )}
                    >
                      <ILLoader
                        classname={"loader-wrap-absolute bg-transparent"}
                        isLoaderBgOpacitySm
                        isChatLoader
                      />
                    </div>
                  </InView>
                )}
              </>
            )
          )}
        </>
      ),
    },
  ];

  const applyFilterCreatorSearchData = [
    {
      key: "price",
      label: "Pris",
      children: (
        <ILCSFilterCheckboxList
          CSPriceFilterCheckboxCon
          name="priceRange"
          creatorsFilterData={productServiceFilterData}
          handleChange={handleFilterInputChange}
        />
      ),
    },
    ...(category?.categoryType === "service"
      ? [
          {
            key: "rating",
            label: "Omdöme",
            children: (
              <ILCSFilterCheckboxList
                name="rating"
                CSRatingFilterCheckboxCon
                creatorsFilterData={productServiceFilterData}
                handleSelectChange={handleSelectChange}
              />
            ),
          },
          {
            key: "country",
            label: "Land",
            children: (
              <ILCSFilterCheckboxList
                name="country"
                CSCountryFilterCheckboxCon
                countryList={countryList}
                creatorsFilterData={productServiceFilterData}
                handleChange={handleFilterInputChange}
              />
            ),
          },
          {
            key: "cities",
            label: "Stad",
            children: (
              <ILCSFilterCheckboxList
                name="cities"
                CSCitiesFilterCheckboxCon
                citiesList={citiesList}
                creatorsFilterData={productServiceFilterData}
                handleChange={handleFilterInputChange}
              />
            ),
          },
        ]
      : []),
    {
      key: "brands",
      label: "Företag",
      children: (
        <ILCSFilterCheckboxList
          name="brandId"
          brandList={brandList}
          CSBrandFilterCheckboxCon
          creatorsFilterData={productServiceFilterData}
          handleChange={handleFilterInputChange}
        />
      ),
    },

    {
      key: "categories",
      label: "Kategorier",
      children: (
        <ILCSFilterCheckboxList
          name="categoryId"
          categoryList={categoryList}
          CSCategoryFilterCheckboxCon
          creatorsFilterData={productServiceFilterData}
          handleChange={handleFilterInputChange}
        />
      ),
    },
    // {
    //   key: "PayPerClicks",
    //   label: "PayPerClicks",
    //   children: (
    //     <ILCSFilterCheckboxList
    //       name="ppcRange"
    //       CSPayPerClicksFilterCheckboxCon
    //       creatorsFilterData={productServiceFilterData}
    //       handleChange={handleFilterInputChange}
    //     />
    //   ),
    // },
  ];

  return (
    <>
      <ILHead title="Live Profile | My shop" />
      <section
        className={clsx(
          setFilterData ? "showBgLayerFilterData" : "",
          styles.liveProfileTabSection,
          "section-sm-pb pt-5"
        )}
      >
        <div className="container">
          <div>
            <div>
              <ILTab
                defaultActiveKey="1"
                tabItem={liveProfileData}
                tabClassName="live-profile-tab"
                isSecondaryTabStyles
                onChange={(event) => handleSelectChange(event, "categoryType")}
              />
            </div>
          </div>
        </div>
      </section>
      {/* filter data sidebar start */}
      <div
        className={clsx(
          setFilterData
            ? "showFilterDataShowWrap noHeaderFilterDataShowWrap"
            : "",
          "w-100 bg-white position-fixed end-0 z-99 top-0 transition-smooth filterDataShowWrap"
        )}
      >
        <div
          className={clsx(
            "d-flex align-items-center position-relative searchFilterClearAllBox"
          )}
        >
          <span
            className={clsx(
              "d-flex align-items-center justify-content-center cursor-pointer rounded-circle closeIcon order-3 order-md-1"
            )}
            onClick={hideFilterDataWrap}
          >
            {Icons.closeIcon}
          </span>
          <h6 className="fw-semibold text-dark-purple mb-0 order-1">
            Tillämpa filter
          </h6>
          <Link
            className="text-gray fw-medium mb-0 ms-auto p big order-2 me-5 me-md-0"
            onClick={(e) => {
              e.preventDefault();
              handleClearAllFilters();
            }}
          >
            Rensa allt
          </Link>
        </div>
        {/* <span
          className={clsx(
            styles.closeIcon,
            "d-flex align-items-center cursor-pointer hover-text"
          )}
          onClick={hideFilterDataWrap}
        >
          {Icons.closeIcon}
        </span> */}

        <div className="filterDataContentWrap overflow-y-auto midium-track">
          <ILAccordion
            items={applyFilterCreatorSearchData}
            defaultActiveKey="1"
            className={clsx(styles.filterAccordion, "filter-accordion my-0")}
            expandIconPosition="end"
            iconSize="20"
          />
        </div>
        <div className="filterDataApplyBtnWrap d-flex align-items-center justify-content-center px-5 gap-4">
          <ILButton
            variant={"primary-gradient"}
            handleClick={handleApplyFilterModalCancel}
            ILBtnClass="w-50 btn-small min min-w-auto mt-0"
          >
            Tillämpa
          </ILButton>
          <ILButton
            variant={"gradient-bordered"}
            handleClick={hideFilterDataWrap}
            ILBtnClass="w-50 btn-small min min-w-auto mt-0"
          >
            Avbryt
          </ILButton>
        </div>
      </div>
      {/* filter data sidebar end */}
    </>
  );
};

export default ILLiveProfile;
