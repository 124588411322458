import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Thumbs } from "swiper/modules";
import clsx from "clsx";
import { capitalize } from "lodash";
import styles from "./../../components/ILProductGalleryCard/ILProductGalleryCard.module.css";

export const ILMSGProductCard = ({ ILProductGalleryCardData, classname }) => {
  // thumb swiper click start
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  // thumb swiper click end
  return (
    <div
      className={clsx(
        styles.ILProductGalleryCard,
        styles.msgProductGalleryCard,
        classname,
        "h-100 d-flex flex-column justify-content-between bg-white"
      )}
    >
      <div
      // onClick={() => handleCollectionDetail(ILProductGalleryCardData)}
      >
        <div className={styles.ILProductGallerySliderContainer}>
          <Swiper
            loop={true}
            thumbs={{
              // swiper: `.${ILProductGalleryCardData.thumbsClassName}`,
              swiper: thumbsSwiper,
            }}
            modules={[FreeMode, Thumbs]}
            className={styles.mainGallerytSlider}
          >
            {ILProductGalleryCardData?.product_request?.collection_details?.brandCollectionProducts?.map(
              (imageItem, index) => {
                return (
                  <SwiperSlide
                    key={index}
                    className="radius-xs d-flex align-items-center justify-content-center cursor-pointer"
                  >
                    <div className={clsx(styles.mainGalleryImg)}>
                      <img
                        width={"100%"}
                        height={"100%"}
                        className={"object-fit-cover"}
                        src={imageItem?.product?.image}
                        alt="advertising agency | ilonsi"
                      />
                    </div>
                  </SwiperSlide>
                );
              }
            )}
          </Swiper>
          <Swiper
            onSwiper={setThumbsSwiper}
            loop={true}
            spaceBetween={8.5}
            slidesPerView={3}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Thumbs]}
            direction="verticle"
            className={clsx(
              styles.thumbGallerySlider,
              "thumb-gallery-slider",
              true
              // ILProductGalleryCardData.thumbsClassName
            )}
          >
            {ILProductGalleryCardData?.product_request?.collection_details?.brandCollectionProducts?.map(
              (imageItem, index) => {
                return (
                  <SwiperSlide
                    key={index}
                    className="bg-clr-black-gray radius-extra-xs d-flex align-items-center justify-content-center cursor-pointer"
                  >
                    <div
                      className={clsx(
                        styles.thumbImg,
                        "w-100 h-100 cursor-pointer"
                      )}
                    >
                      <img
                        className={"object-fit-cover w-100 h-100"}
                        src={imageItem?.product?.image}
                        alt="advertising agency | ilonsi"
                      />
                    </div>
                  </SwiperSlide>
                );
              }
            )}
          </Swiper>
        </div>
        <div className="mt-5 mt-xxl-4 ">
          <p className="p big fw-semibold text-dark-purple mb-2 text-ellipsis line-clamp-2 word-break-word">
            {capitalize(
              ILProductGalleryCardData?.product_request?.collection_details
                ?.name
            )}
          </p>
          <p className="sm text-gray fw-medium mb-0">
            {
              ILProductGalleryCardData?.product_request?.collection_details
                ?.brandCollectionProducts?.length
            }{" "}
            Items
          </p>
        </div>
      </div>
    </div>
  );
};
