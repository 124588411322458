import { Breadcrumb } from "antd";
import { ArrowRight01Icon } from "hugeicons-react";
import React from "react";
import styles from "./ILBreadCrumb.module.css";

export const ILBreadCrumb = ({ items }) => {
  return (
    <Breadcrumb
      className={styles.ILBreadCrumb}
      separator={
        <ArrowRight01Icon
          size={16}
          color={"var(--clr-gray)"}
          variant={"stroke"}
        />
      }
      items={items}
    />
  );
};
