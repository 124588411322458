import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { doFetchResetPassword } from "../../actions";
import { toast } from "react-toastify";
import {
  validateResetPassword,
  validateSubmitResetPassword,
} from "../../validations";
import { useEffect } from "react";

export const useResetPasswordHook = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [validateMessages, setValidateMessages] = useState({});
  const [resetPasswordData, setResetPasswordData] = useState({});
  const [influencerEmail, setInfluencerEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [passwordShowIcon, setPasswordShowIcon] = useState({
    newPasswordShowIcon: false,
    confirmPasswordShowIcon: false,
  });

  useEffect(() => {
    setInfluencerEmail(localStorage.getItem("email"));
  }, []);

  const handleModalOk = () => {
    navigate("/login");
    setIsModalOpen(false);
  };
  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const handlePasswordShowIcon = (name = "") => {
    if (name === "newPassword") {
      setPasswordShowIcon((prevState) => ({
        ...prevState,
        newPasswordShowIcon: !prevState?.newPasswordShowIcon,
      }));
    } else {
      setPasswordShowIcon((prevState) => ({
        ...prevState,
        confirmPasswordShowIcon: !prevState?.confirmPasswordShowIcon,
      }));
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event?.target;
    const { errors } = validateResetPassword(
      name,
      value,
      validateMessages,
      resetPasswordData?.password
    );
    setValidateMessages(errors);
    setResetPasswordData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleResetPasswordSubmit = () => {
    try {
      const { errors } = validateSubmitResetPassword(resetPasswordData);
      setValidateMessages((pre) => ({
        ...pre,
        ...errors,
      }));

      if (Object.keys(errors).length === 0) {
        resetPasswordCallback();
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const resetPasswordCallback = async () => {
    try {
      setIsLoading(true);

      const requestBody = {
        email: influencerEmail,
        password: resetPasswordData?.password,
      };

      const resetPasswordResponse = await doFetchResetPassword(requestBody);

      if (resetPasswordResponse?.status === 200) {
        setValidateMessages({});
        localStorage?.removeItem("email");
        setIsModalOpen(true);
      } else {
        toast.error(resetPasswordResponse?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  return {
    params,
    isLoading,
    isModalOpen,
    validateMessages,
    passwordShowIcon,
    resetPasswordData,
    navigate,
    handleModalOk,
    handleModalCancel,
    handleInputChange,
    handlePasswordShowIcon,
    handleResetPasswordSubmit,
  };
};
