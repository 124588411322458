import React, { useEffect, useState } from "react";
import { clsx } from "clsx";
import { ImageUploadIcon } from "hugeicons-react";
import styles from "./ILUpload.module.css";

export const ILUpload = ({
  htmlFor,
  id,
  name,
  multiple,
  accept,
  value,
  viewImg,
  handleChange,
  stylingUploadWrap,
  productFileUploader,
  title,
  errorMsg,
  className,
  imgClass,
}) => {
  const [file, setFile] = useState();

  useEffect(() => {
    if (value?.length) {
      if (typeof value !== "string") {
        setFile(URL?.createObjectURL(value[0]));
      } else {
        setFile(value);
      }
    }
  }, [value]);

  return (
    <>
      <label
        className={clsx(
          styles.uploadWrap,
          {
            [styles.stylingUploadWrap]: stylingUploadWrap,
            [styles.productFileUpload]: productFileUploader,
          },
          "d-flex align-items-center justify-content-center flex-column position-relative",
          className
        )}
        htmlFor={htmlFor}
      >
        <div
          className={clsx(
            styles.uploadFileInputWrap,
            "d-flex align-items-center justify-content-center border-0 rounded-circle"
          )}
        >
          <ImageUploadIcon size={32} color={"#db2f7f"} variant={"stroke"} />
        </div>
        <input
          type="file"
          className="opacity-0 w-100 h-100 position-absolute top-0 start-0 cursor-pointer"
          id={id}
          name={name}
          multiple={multiple}
          accept={accept}
          onChange={handleChange}
        />
        {title && (
          <h4 className="p text-dark-purple fw-medium text-center mb-0 mx-auto lh-22 px-1">
            {title}
          </h4>
        )}
        {viewImg && value?.length && (
          <div className="position-absolute top-0 start-0 w-100 h-100 radius-inherit cursor-pointer bg-white">
            <img
              src={file}
              className={clsx(
                imgClass,
                "radius-inherit object-fit-contain object-position-center w-100 h-100 aspect-ratio-1-1 bg-white"
              )}
            />
          </div>
        )}
      </label>
      {errorMsg && (
        <span className="p sm mt-3 lh-1 text-tomato fw-medium d-block">
          {errorMsg}
        </span>
      )}
    </>
  );
};
