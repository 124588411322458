import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import { ConfigProvider, Spin } from "antd";
import ILModalFlowProvider from "./context/ILModalFlowProvider";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/grid";
import "./assets/stylesheets/bootstrap.min.css";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <ILModalFlowProvider>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "'Instrument Sans', Arial, sans-serif",
            },
            components: {
              Radio: {
                radioSize: 20,
              },
              Slider: {
                handleSize: 16,
                handleSizeHover: 18,
                handleLineWidth: 4,
              },
            },
          }}
        >
          <RouterProvider fallbackElement={<Spin />} router={router} />
        </ConfigProvider>
      </ILModalFlowProvider>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
