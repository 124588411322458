import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { doFetchUserDetails } from "../../actions";
import { storeAuthUserDetails } from "../../reducers/authenticate.reducer";
import { getDecryptionString } from "../../services";

export const useHeaderHook = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("_token") ? true : false
  );
  const [isToggle, setIsToggle] = useState(false);
  const sidebarRef = useRef();
  const [userProfileDetails, setUserProfileDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { userDetails } = useSelector(
    (state) => state.storeAuthenticateReducer
  );

  useEffect(() => {
    if (isLoggedIn) {
      fetchUserDetails();
    }
  }, [isLoggedIn]);

  const dashboardNavigate = () => {
    if (isLoggedIn) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };

  const dashboardEditProfile = () => {
    navigate("/dashboard/my-profile/edit-profile");
  };

  const handleToggleMenu = () => {
    setIsToggle(!isToggle);
  };

  const fetchUserDetails = async () => {
    try {
      setIsLoading(true);

      if (!userDetails || Object?.keys(userDetails)?.length === 0) {
        const userDetailsResponse = await doFetchUserDetails();

        if (userDetailsResponse?.status === 200) {
          const decryptedUserDetails = await JSON.parse(
            getDecryptionString(userDetailsResponse?.data?.data)
          );
          dispatch(storeAuthUserDetails(decryptedUserDetails));
          setUserProfileDetails(decryptedUserDetails);
        } else {
          toast.error(userDetailsResponse?.data?.message);
        }
      } else {
        setUserProfileDetails(userDetails);
      }
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  return {
    isToggle,
    isLoading,
    sidebarRef,
    isLoggedIn,
    userProfileDetails,
    setIsToggle,
    handleToggleMenu,
    dashboardNavigate,
    dashboardEditProfile,
  };
};
