import { SquareLock01Icon, UserIcon } from "hugeicons-react";
import React from "react";
import {
  ILButton,
  ILTextInput,
  ILSelect,
  ILCheckbox,
  ILModal,
  ILSwiper,
  ILProductCard,
  ILBasicCheckbox,
  ILDashboardCard,
  ILProductGalleryCard,
  ILTable,
  ILDashboardGalleryCard,
  ILTab,
  ILProfileCard,
  ILRadioButton,
  ILCountryCodeInput,
  ILLoader,
  ILNoDataCard,
  ILPagination,
  ILSkeleton,
} from "../../components";
import {
  columns,
  countrySelectOption,
  data,
  featuredProductBreakPoints,
  featuredProductsData,
  ILProductGalleryCardDataList,
  ILProfileCardsList,
  notificationData,
  styleguideDemoCheckboxList,
} from "../../constants/data";
import { Button } from "antd";
import { useState } from "react";
import { SwiperSlide } from "swiper/react";
import { Icons } from "../../constants/icons";
import { bannerCard } from "../../constants/imageData";
import styles from "./ILStyleguide.module.css";
import { ILImageCrop } from "../../components/ILImageCrop";

// new react international phone start

// new react international phone end

const ILStyleguide = () => {
  const [value, setValue] = useState();

  // MODAL JS START
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLogOutModalOpen, setIsLogOutModalOpen] = useState(false);
  const [isResetPwModalOpen, setIsResetPwModalOpen] = useState(false);
  const [isPrivacyPolicyModalOpen, setIsPrivacyPolicyModalOpen] =
    useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleModalOk = () => {
    setIsModalOpen(false);
  };
  const handleModalCancel = () => {
    setIsModalOpen(false);
  };
  // MODAL JS END

  // LOG OUT MODAL START
  const showLogOutModal = () => {
    setIsLogOutModalOpen(true);
  };
  const handleLogOutModalOk = () => {
    setIsLogOutModalOpen(false);
  };
  const handleLogOutModalCancel = () => {
    setIsLogOutModalOpen(false);
  };
  // LOG OUT MODAL END

  // RESET PASSWORD MODAL START
  const showResetPwModal = () => {
    setIsResetPwModalOpen(true);
  };
  const handleResetPwModalOk = () => {
    setIsResetPwModalOpen(false);
  };
  const handleResetPwModalCancel = () => {
    setIsResetPwModalOpen(false);
  };
  // RESET PASSWORD MODAL END

  // PRIVACY POLICY MODAL START
  const showPrivacyPolicyModal = () => {
    setIsPrivacyPolicyModalOpen(true);
  };
  const handlePrivacyPolicyModalOk = () => {
    setIsPrivacyPolicyModalOpen(false);
  };
  const handlePrivacyPolicyModalCancel = () => {
    setIsPrivacyPolicyModalOpen(false);
  };
  // PRIVACY POLICY MODAL END

  // BASIC CHECKBOX JS START
  const onChangeCheckbox = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };
  // BASIC CHECKBOX JS END
  // TAB START
  const onChange = (key) => {
    console.log(key);
  };
  // TAB END

  // new react international phone start
  // new react international phone end
  return (
    <>
      {/* BUTTONS START */}
      <section className="bg-offwhite-shade1 py-5">
        <div className="container pt-5 mt-5">
          <h3 className="mb-5 text-center pt-5 mt-5">Buttons</h3>
          <div className="d-flex flex-wrap gap-5 pt-5">
            <ILButton variant={"primary-gradient"}>
              variant: "primary-gradient"
            </ILButton>
            <ILButton isArrow variant={"primary-gradient"}>
              isArrow
            </ILButton>
            <ILButton variant={"white-filled"}>
              variant: "white-filled"
            </ILButton>
            <ILButton variant={"pink-filled"}>variant: "pink-filled"</ILButton>
            <ILButton variant={"gradient-bordered"}>
              variant: "gradient-bordered"
            </ILButton>
            <ILButton variant={"black-bordered"}>
              variant: "black-bordered"
            </ILButton>
            <ILButton variant={"whiteBg-gradientText"}>
              variant: "whiteBg-gradientText"
            </ILButton>
          </div>
        </div>
      </section>
      {/* BUTTONS START */}

      {/* TEXT INPUTS START */}
      <section className="py-5">
        <div className="container">
          <h3 className="mb-5 text-center">Text Inputs</h3>
          <div className="d-flex flex-wrap gap-5 mt-5">
            <ILTextInput
              id="basicInput"
              datatestid="basicInput"
              type="text"
              name=""
              // value=""
              handleChange={() => {
                return false;
              }}
              placeholder={"Basic Input"}
            />
            <ILTextInput
              id="withLable"
              datatestid="withLable"
              type="text"
              name=""
              // value=""
              label="E-postadress"
              handleChange={() => {
                return false;
              }}
              placeholder={"Input with label"}
            />
            <ILTextInput
              prefix={
                <UserIcon
                  size={24}
                  color={"#1d1128"}
                  variant={"stroke"}
                  strokeWidth={1.5}
                />
              }
              id="withPrefix"
              datatestid="withPrefix"
              type="text"
              name=""
              // value=""
              handleChange={() => {
                return false;
              }}
              placeholder={"Input with prefix"}
            />
            <ILTextInput
              suffix={
                <>
                  <ILButton
                    ILBtnClass={"min-w-auto"}
                    variant={"primary-gradient"}
                  >
                    Kopiera länk
                  </ILButton>
                </>
              }
              id="copyLinkInput"
              datatestid="copyLinkInput"
              type="text"
              name=""
              value="Influencer.Ilonsi.com/136sdf"
              readOnly={true}
              handleChange={() => {
                return false;
              }}
              placeholder={"Kopiera länkingång"}
            />
            <ILSelect
              label="Land"
              id="countrySelect"
              datatestid="countrySelect"
              defaultValue="India"
              options={countrySelectOption}
              showSearch
            />

            <ILSelect
              // isRequired
              label="Stad"
              id="citySelect"
              datatestid="citySelect"
              options={countrySelectOption}
              // name="cityId"
              placeholder={"Välj stad"}
              // value={userProfileDetails?.cityId || null}
              // handleChange={(event) =>
              //   handleSelectChange(event, "cityId")
              // }
              // errorMsg={validateMessages?.cityId}
              showSearch
            />

            <ILCountryCodeInput
              value={value}
              setValue={setValue}
              defaultCountry={"IN"}
              placeholder={"Ange ditt telefonnumme"}
            />
            {/* new country number select dropdown start */}
            <ILCountryCodeInput
              isShowNew
              initialCountry={"se"}
              label={"Telefonnummer"}
              isRequired
              id={""}
              name={""}
              value={""}
              handleChange={(event) => {
                return false;
              }}
              errorMsg={""}
            />
            {/* new country number select dropdown end */}
          </div>
        </div>
      </section>
      {/* TEXT INPUTS END */}

      {/* CHECKBOX START */}
      <section>
        <div className="container">
          <div className="my-5">
            <h3 className="text-center">Skeleton</h3>
            <ILSkeleton count={3} />
          </div>
          <h3 className="mb-5 text-center">Checkboxes</h3>
          <div className="mt-5 d-flex gap-3">
            <ILCheckbox
              name={"styleguideCheckbox"}
              CheckboxList={styleguideDemoCheckboxList}
            />
          </div>
          <h3 className="mb-4">Basic checkbox</h3>
          <ILBasicCheckbox
            onChange={onChangeCheckbox}
            checked
            children="Sneakers & Sports Shoes"
          />
          <ILBasicCheckbox
            onChange={onChangeCheckbox}
            checked
            children="Track Pants"
          />
        </div>
      </section>
      {/* CHECKBOX END */}
      {/* MODAL START */}
      <section className="py-5">
        <div className="container">
          <h3 className="mb-5 text-center">Modal</h3>
          <Button type="primary" onClick={showModal}>
            Open Modal
          </Button>
          <ILModal
            open={isModalOpen}
            onOk={handleModalOk}
            onCancel={handleModalCancel}
            title={"Modal"}
            className=""
            wrapClassName=""
            centered={true}
            closable=""
            width="534px"
            children="sfiksd"
            isFooterRemove
          />
        </div>
      </section>
      {/* MODAL END */}
      {/* SWIPER SLIDER START */}
      <section className="py-5">
        <div className="container">
          <h3 className="mb-5 text-center">Swiper</h3>
          <ILSwiper
            className=""
            spaceBetween={20}
            slidesPerView={4}
            autoplay={false}
            loop={false}
            breakpoints={featuredProductBreakPoints}
            grabCursor={true}
          >
            {featuredProductsData.map((FPItem, FPIndex) => {
              return (
                <SwiperSlide key={FPIndex}>
                  <ILProductCard productDataShow={FPItem.productData} />
                </SwiperSlide>
              );
            })}
          </ILSwiper>
        </div>
      </section>
      {/* SWIPER SLIDER END */}

      {/* PRODUCT GALLERY START */}
      <section className="py-5">
        <div className="container">
          <h3 className="mb-5 text-center">Product Gallery Card</h3>
          <div>
            <ILProductGalleryCard
              ILProductGalleryCardDataList={ILProductGalleryCardDataList}
            />
          </div>
        </div>
      </section>
      {/* PRODUCT GALLERY END */}

      {/* Dashboard GALLERY START */}
      <section className="py-5">
        <div className="container">
          <h3 className="mb-5 text-center">Dashboard Gallery Card</h3>
          <div>
            <ILDashboardGalleryCard
              ILProductGalleryCardItem={ILProductGalleryCardDataList}
            />
          </div>
        </div>
      </section>
      {/* Dashboard GALLERY END */}

      {/* DASHBOARD CARD START */}
      <section className="py-5">
        <div className="container">
          <h3 className="mb-5 text-center">Dashboard Card</h3>
          <div>
            <ILDashboardCard
              title={"Table Component"}
              subTitle={"simple table"}
              extra={<>View</>}
            >
              <ILTable columns={columns} data={data} />
            </ILDashboardCard>
          </div>
        </div>
      </section>
      {/* DASHBOARD CARD END */}

      {/* Tab START */}
      <section className="py-5">
        <div className="container">
          <h3 className="mb-5 text-center">Tab</h3>
          <div className="mb-5">
            <ILTab
              defaultActiveKey="1"
              tabItem={notificationData}
              onChange={onChange}
              tabClassName=""
            />
          </div>

          <div>
            <ILTab
              defaultActiveKey="1"
              tabItem={notificationData}
              onChange={onChange}
              isSecondaryTabStyles
            />
          </div>
        </div>
      </section>
      {/* Tab END */}
      {/* LOG OUT CONFIRMATION MODAL START */}
      <section className="py-5">
        <div className="container">
          <h3 className="mb-5 text-center">Log Out Modal</h3>
          <div>
            <ILButton
              handleClick={showLogOutModal}
              variant={"primary-gradient"}
            >
              Log Out
            </ILButton>
          </div>
        </div>
      </section>
      <ILModal
        open={isLogOutModalOpen}
        onOk={handleLogOutModalOk}
        onCancel={handleLogOutModalCancel}
        title={""}
        wrapClassName=""
        centered={true}
        closable=""
        width="518px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5 gap-xxl-4">
              <span className="lh-0">{Icons.logOut}</span>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Åh nej! Du lämnar
              </h4>
              <p className="paragraph-sm-text paragraph-md-text text-center mb-0">
                Är du säker på att du vill logga ut?
              </p>
              <div className="d-flex gap-5 modal-more-btn">
                <ILButton ILBtnClass={"w-50"} variant={"primary-gradient"}>
                  Ja, Bekräfta
                </ILButton>
                <ILButton
                  handleClick={handleLogOutModalCancel}
                  ILBtnClass={"w-50"}
                  variant={"gradient-bordered"}
                >
                  Nej, Avbryt
                </ILButton>
              </div>
            </div>
          </>
        }
        isFooterRemove
      />
      {/* LOG OUT CONFIRMATION MODAL END */}

      {/* RESET PASSWORD MODAL START */}
      <section className="py-5">
        <div className="container">
          <h3 className="mb-5 text-center">Reset Password Modal</h3>
          <div>
            <ILButton
              handleClick={showResetPwModal}
              variant={"primary-gradient"}
            >
              Reset Password
            </ILButton>
          </div>
        </div>
      </section>
      <ILModal
        open={isResetPwModalOpen}
        onOk={handleResetPwModalOk}
        onCancel={handleResetPwModalCancel}
        title={
          <>
            <h5 className="big fw-semibold mb-2">Ändra ditt lösenord</h5>
            <p className="sm fw-normal text-gray">
              ILONSI gör det enkelt att hålla ditt konto säkert. Ändra ditt
              lösenord snabbt och smidigt.
            </p>
          </>
        }
        wrapClassName=""
        centered={true}
        closable=""
        width="518px"
        children={
          <>
            <form>
              <ILTextInput
                prefix={
                  <SquareLock01Icon
                    size={24}
                    color={"#1d1128"}
                    variant={"stroke"}
                    strokeWidth={1.5}
                  />
                }
                id="newPassword"
                datatestid="newPassword"
                type="password"
                name=""
                label="Nytt lösenord"
                // value=""
                handleChange={() => {
                  return false;
                }}
                placeholder={"Ange ditt nya lösenord"}
              />
              <ILTextInput
                prefix={
                  <SquareLock01Icon
                    size={24}
                    color={"#1d1128"}
                    variant={"stroke"}
                    strokeWidth={1.5}
                  />
                }
                id="confirmNewPassword"
                datatestid="confirmNewPassword"
                type="password"
                name=""
                label="Bekräfta nytt lösenord"
                // value=""
                handleChange={() => {
                  return false;
                }}
                placeholder={"Bekräfta lösenord"}
              />
              <ILButton ILBtnClass={"w-100"} variant={"primary-gradient"}>
                Ändra lösenord
              </ILButton>
            </form>
          </>
        }
        isFooterRemove
      />
      {/* RESET PASSWORD MODAL END */}

      {/* PRIVACY POLICY MODAL START */}
      <section className="py-5">
        <div className="container">
          <h3 className="mb-5 text-center">Privacy Policy Modal</h3>
          <div>
            <ILButton
              handleClick={showPrivacyPolicyModal}
              variant={"primary-gradient"}
            >
              Privacy Policy
            </ILButton>
          </div>
        </div>
      </section>
      <ILModal
        open={isPrivacyPolicyModalOpen}
        onOk={handlePrivacyPolicyModalOk}
        onCancel={handlePrivacyPolicyModalCancel}
        title={"Privacy Policy for ILONSI"}
        wrapClassName=""
        centered={true}
        closable=""
        width="1028px"
        children={
          <>
            <div>
              <div className="mb-4">
                <h6 className="p fw-semibold">Introduction</h6>
                <p className="paragraph-sm-text paragraph-xs-text">
                  Welcome to ILONSI. We value your privacy and strive to protect
                  your personal information. This Privacy Policy explains how we
                  collect, use, and share your information when you use our
                  website and services.
                </p>
              </div>
              <div className="mb-4">
                <h6 className="p fw-semibold">Information We Collect</h6>
                <ul className={styles.listItemCard}>
                  <li className={styles.listItem}>
                    Personal Information: Name, email address, phone number,
                    payment information, and other details you provide.
                  </li>
                  <li className={styles.listItem}>
                    Usage Data: Information about how you use our website,
                    including IP address, browser type, and browsing behavior.
                  </li>
                </ul>
              </div>
              <div className="mb-4">
                <h6 className="p fw-semibold">How We Use Your Information</h6>
                <ul className={styles.listItemCard}>
                  <li className={styles.listItem}>
                    To Provide Services: Process transactions, deliver products,
                    and offer customer support.
                  </li>
                  <li className={styles.listItem}>
                    To Improve Services: Analyze usage data to enhance our
                    website and services.
                  </li>
                  <li className={styles.listItem}>
                    Marketing: Send promotional materials and updates, with your
                    consent.
                  </li>
                </ul>
              </div>
              <div className="mb-4">
                <h6 className="p fw-semibold">Sharing Your Information</h6>
                <ul className={styles.listItemCard}>
                  <li className={styles.listItem}>
                    Service Providers: Share information with third parties who
                    perform services on our behalf.
                  </li>
                  <li className={styles.listItem}>
                    Legal Requirements: Disclose information if required by law
                    or to protect our rights.
                  </li>
                </ul>
              </div>
              <div className="mb-4">
                <h6 className="p fw-semibold">Security</h6>
                <p className="paragraph-sm-text paragraph-xs-text">
                  We use various security measures to protect your information,
                  but please note that no method of transmission over the
                  internet is completely secure.
                </p>
              </div>
              <div className="mb-4">
                <h6 className="p fw-semibold">Your Rights</h6>
                <ul className={styles.listItemCard}>
                  <li className={styles.listItem}>
                    Access: You can request access to your personal information.
                  </li>
                  <li className={styles.listItem}>
                    Correction: You can request corrections to your personal
                    information.
                  </li>
                  <li className={styles.listItem}>
                    Deletion: You can request the deletion of your personal
                    information.
                  </li>
                </ul>
              </div>
              <div className="mb-4">
                <h6 className="p fw-semibold">Changes to This Policy</h6>
                <p className="paragraph-sm-text paragraph-xs-text">
                  We may update this Privacy Policy from time to time. We will
                  notify you of any changes by posting the new policy on our
                  website.
                </p>
              </div>
              <div>
                <h6 className="p fw-semibold">Contact Us</h6>
                <p className="paragraph-sm-text paragraph-xs-text">
                  {" "}
                  If you have any questions or concerns about this Privacy
                  Policy, please contact us at [your contact information].
                </p>
              </div>
            </div>
          </>
        }
        isFooterRemove
      />
      {/* PRIVACY POLICY MODAL END */}

      {/* PROFILE CARD START */}
      <section className="py-5">
        <div className="container">
          <h3 className="mb-5 text-center">Profile Card</h3>
          <div>
            <ILProfileCard ILProfileCardsList={ILProfileCardsList} />
          </div>
        </div>
      </section>
      {/* PROFILE CARD END */}

      {/* RADIO BUTTON START */}
      <section className="py-5">
        <div className="container">
          <h3 className="mb-5 text-center">Radio Button</h3>
          <div>
            <ILRadioButton
              RadioButtonList={[
                {
                  title: "Spam",
                  value: 1,
                },
                {
                  title: "Adult Content",
                  value: 2,
                },
              ]}
            />
          </div>
        </div>
      </section>
      {/* RADIO BUTTON END */}

      {/* LOADER ABSOLUTE START */}
      <section className="section-xs-py">
        <h2 className="text-center mb-3">Section Loader</h2>
        <div className="container section-md-py position-relative">
          <ILLoader classname={"loader-wrap-absolute"} />
        </div>
      </section>
      {/* LOADER ABSOLUTE END */}

      {/* NO DATA CARD START */}
      <div className="container">
        <ILNoDataCard />
      </div>
      {/* NO DATA CARD END */}
      <img
        src={bannerCard}
        alt="advertising agency | ilonsi"
        className="w-100 h-100"
      />
      <div className="py-5 my-5">
        <div className="container">
          <ILImageCrop />
        </div>
      </div>
      <ILPagination
        defaultCurrent={1}
        defaultPageSize={15}
        total={500}
        // onChange={(event) => handleTableChange(event, "filterData")}
      />
    </>
  );
};
export default ILStyleguide;
