import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { setAuthHeader } from "../../axios";
import { getDecryptionString } from "../../services";
import { doFetchLogin } from "../../actions";
import { validateLogin, validateSubmitLogin } from "../../validations";

export const useLoginHook = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [loginData, setLoginData] = useState({});
  const [validateMessages, setValidateMessages] = useState({});
  const [isShowIcon, setIsShowIcon] = useState(false);

  const handleIconChange = () => {
    setIsShowIcon(!isShowIcon);
  };

  const handleRedirectHome = () => {
    navigate("/");
  };

  const handleInputChange = (event) => {
    const { name, value } = event?.target;
    const { errors } = validateLogin(name, value, validateMessages);
    setValidateMessages(errors);

    setLoginData((prevState) => ({
      ...prevState,
      [name]: event?.target?.value,
    }));
  };

  const handleLoginSubmit = () => {
    try {
      const { errors } = validateSubmitLogin(loginData);
      setValidateMessages(errors);
      if (Object.keys(errors).length === 0) {
        loginCallback();
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const loginCallback = async () => {
    try {
      setIsLoading(true);
      const requestData = {
        email: loginData?.email,
        password: loginData?.password,
      };

      const loginResponse = await doFetchLogin(requestData);
      if (loginResponse?.status === 200) {
        localStorage.clear();
        const decryptedLoginResponse = await JSON.parse(
          getDecryptionString(loginResponse?.data)
        );
        localStorage.setItem("_token", decryptedLoginResponse?.token);
        await setAuthHeader(`Bearer ${decryptedLoginResponse?.token}`);
        navigate(`/dashboard?user-name=${decryptedLoginResponse?.user_name}`);
      } else {
        toast.error(loginResponse?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error occurred during influencer login:", error);
    }
  };

  return {
    loginData,
    isLoading,
    isShowIcon,
    validateMessages,
    handleIconChange,
    handleLoginSubmit,
    handleInputChange,
    handleRedirectHome,
  };
};
