import { Col, Row } from "antd";
import { ILButton, ILHead, ILQualityCard, ILSkeleton } from "../../components";
import { Link } from "react-router-dom";
import clsx from "clsx";
import {
  fiveFullStarsRating,
  homeHeroImg,
  ladyBag,
  laptopDashboard,
} from "../../constants/imageData";
import {
  achievementDataList,
  benefitCardList,
  improvePerformaceDataList,
  influecerMarketingCardsList,
} from "../../constants/data";
import { Tick02Icon } from "hugeicons-react";
import { useHomeHook } from "../../hooks/website/home.hook";
import { appConfig } from "../../config";
import { Icons } from "../../constants/icons";
import styles from "./ILHome.module.css";

const ILHome = () => {
  const { isLoading, brandList, dashboardNavigate } = useHomeHook();

  return (
    <>
      <ILHead title="Hem" />
      {/* HERO SECTION START */}
      <section
        className={clsx(
          styles.homeHeroSection,
          "bg-dark-purple position-relative text-white hero-gradient-border min-vh-xl-100 inner-hero-gradient z-1"
        )}
      >
        <div className="position-relative inner-section-gradient">
          <div className="container">
            <div className="w-100 min-vh-xl-100 hero-section-space pb-0 d-flex flex-column flex-xl-row">
              <div
                className={clsx(
                  styles.heroContentWrap,
                  "hero-content-wrap align-self-center pe-xl-5 text-center text-xl-start mt-5 pt-5 mt-xl-0 pt-xl-0 mb-5 pb-5 mb-xl-0 pb-xl-0"
                )}
              >
                <h1 className="primary-title primary-xl-title mb-5 mb-xl-3 text-center text-xl-start">
                  Tjäna pengar på&nbsp;
                  <span className="d-block d-md-inline">
                    dina sociala medier
                  </span>
                  {/* <img
                    src={gradientFlower}
                    alt="advertising agency | ilonsi"
                    className="w-100 h-100 object-fit-contain hero-icon mx-4"
                  /> */}
                </h1>
                {/* <h1 className="primary-title primary-xl-title mb-5 mb-xl-3 text-center text-xl-start">
                  dina sociala medier
                  <img
                    src={gradientStar}
                    alt="advertising agency | ilonsi"
                    className="w-100 h-100 object-fit-contain hero-icon mx-4"
                  />
                  To Grow.
                </h1> */}
                <p className="text-blue-gray big fw-medium mb-0 text-center text-xl-start line-height-normal">
                  Starta din egen onlinebutik gratis med ILONSI och tjäna pengar
                  på allt från att sälja heta sneakers till att generera trafik
                  till din favoritwebbplats för smink.
                </p>
                <ILButton
                  ILBtnClass={"section-xs-mt mx-auto mx-xl-0"}
                  isArrow
                  variant={"primary-gradient"}
                  handleClick={dashboardNavigate}
                >
                  Skapa konto gratis
                </ILButton>
                <p className="text-gray fw-medium mt-5 mb-0">
                  Är du företagare?
                  <Link
                    to={`${appConfig?.APP_DOMAIN}/login`}
                    className="clr-white ps-3 hover-text"
                  >
                    Klicka här
                  </Link>
                </p>
              </div>
              <div
                className={clsx(
                  styles.heroImg,
                  "hero-img d-flex mt-auto pt-4 mt-xl-0 pt-xl-0 w-100"
                )}
              >
                <div
                  className={clsx(
                    styles.homeHeroImgWrap,
                    "text-end mt-auto w-100 mx-auto ms-xl-auto me-xl-0 d-flex align-items-end justify-content-center"
                  )}
                >
                  <img
                    src={homeHeroImg}
                    alt="advertising agency | ilonsi"
                    className="h-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* HERO SECTION END */}

      {/* BRANDS SECTION START */}
      <section className="section-md-pt">
        <div className="container">
          <h2 className="secondary-title text-center">Våra varumärken</h2>
          <p className="title-label">Några av hundratals företag på ILONSI</p>

          <div className={clsx(styles.brandsImgGridWrap, "d-flex d-xl-grid")}>
            {brandList?.map((brand, index) => {
              return (
                <div
                  className={clsx(styles.brandImg, "w-100 mx-auto text-center")}
                  key={index}
                >
                  {isLoading ? (
                    <ILSkeleton width={100} height={100} />
                  ) : (
                    <img
                      src={brand?.brandDetail?.profile_image}
                      alt={brand?.brandDetail?.name}
                      className="object-fit-contain object-position-center w-100 h-100 aspect-ratio-6-2"
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* BRANDS SECTION END */}

      {/* HOW IT WORKS SECTION START */}
      <section className="section-md-pt">
        <div className="container">
          <Row
            gutter={[24, 0]}
            className="align-items-center justify-content-between"
          >
            <Col xs={24} md={12}>
              <div
                className={clsx(
                  "ratio ratio-1x1 my-auto mx-auto mx-md-0",
                  styles.howItWorkImg
                )}
              >
                <img
                  src={ladyBag}
                  alt="advertising agency | ilonsi"
                  width={"100%"}
                  height={"100%"}
                  // className={"object-fit-cover"}
                />
              </div>
            </Col>
            <Col xs={24} md={12} className="mt-5 pt-5 mt-md-0 pt-md-0">
              <div className={styles.howItWorkContentCard}>
                <h2 className={clsx(styles.howItWorkTitle, "secondary-title")}>
                  Hur det fungerar?
                </h2>
                <p className="big text-gray">
                  ILONSI gör det möjligt att skapa en gratis onlinebutik på
                  mindre än två minuter, utan att behöva ett eget företag. Sälj
                  allt från smink och skor till restaurangbokningar genom din
                  ILONSI butik. Tjäna pengar på butikbesökare, klick som
                  genereras till anslutna företags hemsidor eller direkt via
                  dina sociala medier, samt få provision på försäljning och
                  bokningar. Vad väntar du på? Starta din ILONSI butik gratis
                  idag!
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      {/* HOW IT WORKS SECTION END */}

      {/* RECENT REVIEWS SECTION START */}
      <section
        className={clsx(
          styles.recentReviewsSection,
          "section-md-py section-md-mt bg-dark-purple inner-hero-gradient inner-section-gradient position-relative z-after-before-0"
        )}
      >
        <div className="container position-relative z-1">
          <span className="secondary-title text-white text-center d-block mb-3">
            Senaste omdömen
          </span>
          <p className="title-label fw-medium mb-0 px-md-5 mx-md-4">
            Se vad influencers och företag&nbsp;
            <span className="d-block d-sm-inline">
              tycker om ILONSI familjen!
            </span>
          </p>
          <img
            src={fiveFullStarsRating}
            className={clsx(
              styles.foutHalfStarsRatingImg,
              "h-auto mt-5 mx-auto d-flex"
            )}
            alt="advertising agency | ilonsi"
          />
        </div>
        <div
          className={clsx(
            styles.recentReviewsSwiperCardWrap,
            "section-xs-mt d-flex justify-content-center"
          )}
        >
          <ILButton
            ILBtnClass={"min-w-auto fw-medium"}
            variant={"primary-gradient"}
            // ILBgGreenText
            onClick={() =>
              window.open(
                "https://www.trustpilot.com/review/www.ilonsi.com",
                "_blank"
              )
            }
          >
            Ge oss ett
            <span className="px-2">{Icons.star}</span>
            omdöme
          </ILButton>
          {/* <Swiper
            slidesPerView={4.5}
            spaceBetween={40}
            autoplay={true}
            loop={false}
            breakpoints={recentReviewsSwiperBreakPoints}
            grabCursor={true}
            className="reviewSliderOne section-xs-mb"
          >
            {recentReviewsSwiperListOne &&
              recentReviewsSwiperListOne.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className={styles.reviewCard}>
                      <div className="d-flex align-items-center gap-3 mb-3">
                        <Avatar size={48} src={item.userImage} />
                        <div className={styles.ratingStarsImg}>
                          <img
                            width={"100%"}
                            height={"100%"}
                            src={item.ratingImage}
                            alt="advertising agency | ilonsi"
                          />
                        </div>
                      </div>
                      <div>
                        <p className="big text-white fw-semibold mb-2">
                          {item.userName}
                        </p>
                        <p
                          className={
                            "text-offwhite paragraph-sm-text mb-0 text-ellipsis line-clamp-3"
                          }
                        >
                          {item.description}
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
          <Swiper
            slidesPerView={4.5}
            spaceBetween={40}
            autoplay={true}
            loop={false}
            breakpoints={recentReviewsSwiperBreakPoints}
            grabCursor={true}
            dir="rtl"
            className="reviewSliderTwo"
          >
            {recentReviewsSwiperListTwo &&
              recentReviewsSwiperListTwo.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className={styles.reviewCard}>
                      <div className="d-flex align-items-center flex-row-reverse gap-3 mb-3">
                        <Avatar size={48} src={item.userImage} />
                        <div className={styles.ratingStarsImg}>
                          <img
                            width={"100%"}
                            height={"100%"}
                            src={item.ratingImage}
                            alt="advertising agency | ilonsi"
                          />
                        </div>
                      </div>
                      <div>
                        <p className="big text-white fw-semibold text-start mb-2">
                          {item.userName}
                        </p>
                        <p
                          className={
                            "text-offwhite paragraph-sm-text text-start mb-0 text-ellipsis line-clamp-3"
                          }
                        >
                          {item.description}
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper> */}
        </div>
      </section>
      {/* RECENT REVIEWS SECTION END */}

      {/* ELEVATE INFLUENCER MARKETING START */}
      <section className="section-md-pt">
        <div className="container">
          <span className="secondary-title mb-3 d-block text-center">
            Fördelarna med ILONSI
          </span>
          <p className="title-label mb-0 text-center">
            Vi är en användarvänlig och transparent plattform med fokus på våra
            användare.
          </p>
          <div className={clsx(styles.influecerMarketingCardsWrap, "d-grid")}>
            <ILQualityCard ourStoryCardList={influecerMarketingCardsList} />
          </div>
        </div>
      </section>
      {/* ELEVATE INFLUENCER MARKETING END */}

      {/* IMPROVE PERFORMANCE SECTION START */}
      <section className="section-md-pt">
        <div className="container">
          <div className={"alternate-row-data-container"}>
            {improvePerformaceDataList &&
              improvePerformaceDataList?.map((item, index) => {
                return (
                  <Row
                    gutter={[24, 0]}
                    key={index}
                    className="align-items-center justify-content-between"
                  >
                    <Col className={clsx(styles.empowerTextContent)} lg={10}>
                      <div className={"empower-text-content-wrap"}>
                        <span className="secondary-title d-block mb-3">
                          {item.title}
                        </span>
                        <div className="mb-5">
                          {item.description &&
                            item?.description.map((item, descriptionIndex) => {
                              return (
                                <p
                                  key={descriptionIndex}
                                  className="title-label text-start"
                                >
                                  {item.descriptionItem}
                                </p>
                              );
                            })}
                        </div>
                        <div className={"point-list"}>
                          {item.pointListItemData &&
                            item.pointListItemData.map(
                              (pointListItem, pointListIndex) => {
                                return (
                                  <div
                                    key={pointListIndex}
                                    className={"point-list-item"}
                                  >
                                    <span className="flex-shrink-0">
                                      <Tick02Icon
                                        size={24}
                                        color={"var(--clr-dark-purple)"}
                                        variant={"stroke"}
                                      />
                                    </span>
                                    <p className="big fw-medium mb-0">
                                      {pointListItem.pointDescription}
                                    </p>
                                  </div>
                                );
                              }
                            )}
                        </div>
                        <ILButton
                          ILBtnClass={"text-uppercase min-w-auto"}
                          variant={"primary-gradient"}
                          isArrow
                          handleClick={dashboardNavigate}
                        >
                          Skapa konto gratis
                        </ILButton>
                      </div>
                    </Col>
                    <Col
                      className={clsx(
                        styles.empowerImgContent,
                        "mt-5 mt-md-0 pt-5 pt-md-0"
                      )}
                      lg={14}
                    >
                      <div
                        className={clsx(
                          "empower-img-wrap rounded-0 shadow-none mx-auto ms-xl-auto"
                        )}
                      >
                        <img
                          src={laptopDashboard}
                          alt="advertising agency | ilonsi"
                          width={"100%"}
                          height={"100%"}
                          className={
                            "object-fit-contain object-position-center w-100 h-100 aspect-ratio-4-2"
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                );
              })}
          </div>
        </div>
      </section>
      {/* IMPROVE PERFORMANCE SECTION END */}

      {/* ACHIEVEMENT SECTION START */}
      <section
        className={clsx(
          styles.achievementListSection,
          "section-sm-py section-md-mt bg-dark-purple inner-hero-gradient inner-section-gradient position-relative z-after-before-0 overflow-hidden"
        )}
      >
        <div className="container position-relative z-1">
          <div className={clsx(styles.achievementListWrap, "d-grid")}>
            {achievementDataList &&
              achievementDataList.map((item, index) => {
                return (
                  <div key={index} className={styles.achievementDataCard}>
                    <div className="mb-5">{item.icon}</div>
                    <div>
                      <h4 className="semibold text-white mb-2">{item.title}</h4>
                      <p className="big mb-0 text-etude-lilac text-ellipsis line-clamp-4">
                        {item.description}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      {/* ACHIEVEMENT SECTION END */}

      {/* BENEFITS SECTION START */}
      <section className="section-md-py">
        <div className="container">
          <div className={clsx(styles.benefitCardsContainer, "d-grid")}>
            {benefitCardList &&
              benefitCardList.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={clsx(
                      styles.benefitCard,
                      "position-relative d-flex flex-column d-sm-block"
                    )}
                  >
                    <div
                      className={clsx(
                        styles.benefitCardImg,
                        "w-100 h-100 order-2 order-sm-1 mt-5 pt-4 mt-sm- pt-sm-0 mx-auto mx-sm-0"
                      )}
                    >
                      <img
                        src={item.image}
                        alt={item.title}
                        width={"100%"}
                        height={"100%"}
                        className={"object-fit-contain"}
                      />
                    </div>
                    <div
                      className={clsx(
                        styles.benefitCardContentWrap,
                        "ms-sm-auto d-flex flex-column h-100 ps-4 order-1 order-sm-2"
                      )}
                    >
                      <div>
                        <h5 className="fw-semibold mb-3 text-ellipsis line-clamp-3">
                          {item.title}
                        </h5>
                        <p className="sm text-gray mb-0 text-ellipsis line-clamp-2">
                          {item.description}
                        </p>
                      </div>
                      <ILButton
                        isArrow
                        variant={"primary-gradient"}
                        ILBtnClass="mt-auto w-max-content"
                        handleClick={dashboardNavigate}
                      >
                        Skapa konto gratis
                      </ILButton>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      {/* BENEFITS SECTION END */}
    </>
  );
};

export default ILHome;
