import { useState } from "react";
import { socialMediaSharingCardList } from "../../constants/data";
import { threeDotsIcon } from "../../constants/imageData";
import { ILTextInput } from "../ILTextInput";
import { ILButton } from "../ILButton";
import styles from "./ILDashboardGalleryCard.module.css";
import clsx from "clsx";

export const ILShareSocialMediaModal = ({
  selectedData,
  shareHandler,
  liveProfileLink,
  handleCopyLink,
  selectedSocialMedia,
  handleSelectSocialMedia,
}) => {
  const [showAll, setShowAll] = useState(false);
  const handleShowAll = () => {
    setShowAll(!showAll);
  };
  const socialMediaSharingCardListResults = showAll
    ? socialMediaSharingCardList
    : socialMediaSharingCardList.slice(0, 7);

  return (
    <>
      <p className="big fw-medium mb-4">
        Dela {selectedData?.data?.product?.short_code ? "produkte" : "tjänsten"}{" "}
        direkt via dina sociala medier
      </p>
      <div className="social-media-sharing-cards-wrap">
        {socialMediaSharingCardListResults &&
          socialMediaSharingCardListResults?.map((item, index) => {
            return (
              <label
                key={index}
                className={clsx(
                  styles.socialMediaSharingCard,
                  selectedSocialMedia?.title === item?.title &&
                    styles.socialIconActive,
                  "cursor-pointer"
                )}
                onClick={() => handleSelectSocialMedia(item)}
              >
                <div className="social-media-icon">
                  <img
                    src={item.image}
                    alt={item.title}
                    width={"100%"}
                    height={"100%"}
                  />
                </div>
                <p className="sm text-gray mb-0 text-center">{item.title}</p>
              </label>
            );
          })}
        {socialMediaSharingCardListResults?.length > 8 && (
          <div role={"button"} onClick={handleShowAll}>
            <div className="social-media-icon">
              <img
                src={threeDotsIcon}
                alt={showAll ? "Less" : "More"}
                width={"100%"}
                height={"100%"}
              />
              <p className="sm mb-0 text-center fw-medium">
                {showAll ? "Less" : "More"}
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="mb-5">
        <p className="big fw-medium mb-4">Eller kopiera länk</p>
        <ILTextInput
          ILInputLabelClass={"mb-0"}
          suffix={
            <>
              <ILButton
                ILBtnClass={"min-w-auto"}
                variant={"primary-gradient"}
                handleClick={handleCopyLink}
              >
                Kopiera
              </ILButton>
            </>
          }
          id="copyLinkInput"
          datatestid="copyLinkInput"
          type="text"
          name=""
          value={liveProfileLink}
          readOnly={true}
          handleChange={() => {
            return false;
          }}
          disabled
          placeholder={"Kopiera länkingång"}
        />
      </div>
      <div>
        <ILButton
          disabled={
            Object?.keys(selectedSocialMedia)?.length > 0 ? false : true
          }
          variant={"gradient-bordered"}
          ILBtnClass={"w-100"}
          onClick={shareHandler}
        >
          Dela
        </ILButton>
      </div>
    </>
  );
};
