import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "../../hooks/useMediaQuary";
import { getDecryptionString } from "../../services";
import {
  doFetchInfluencerDetails,
  doFetchSkipExploreSite,
} from "../../actions";
import { storeAuthUserDetails } from "../../reducers/authenticate.reducer";
import { useDispatch } from "react-redux";

export const useInfluencerDashboardHook = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [isWelcomeModalOpen, setWelcomeModalOpen] = useState(true);
  const [isTourOpen, setTourOpen] = useState(false);
  const isIPad = useMediaQuery("(max-width: 767px)");
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("_token") ? true : false
  );

  useEffect(() => {
    if (isLoggedIn) {
      fetchUserDetails();
    }
  }, [isLoggedIn]);

  const handleWelcomeModalCancel = () => {
    exploreSiteSkipCallback();
    setWelcomeModalOpen(false);
    fetchUserDetails();
  };

  const steps = [
    {
      title: "Översikt över dina intäkter",
      description:
        "Alla intäkter, klickintäkter och en översikt över dina butikbesökare. Dina mest klickade produkter och tjänster.",
      target: () => document.querySelector(".dashboardRef").parentElement,
      nextButtonProps: {
        onClick: () => navigate("/dashboard/my-shop"),
        children: <>Nästa</>,
      },
      placement: "rightBottom",
      cover: <></>,
    },
    {
      title: "Dina produkter och tjänster",
      description: "Du hanterar dina produkter, kollektioner och tjänster.",
      target: () => document.querySelector(".myShopRef").parentElement,
      prevButtonProps: {
        onClick: () => navigate("/dashboard"),
        children: <>Tillbaka</>,
      },
      placement: "rightBottom",
      cover: <></>,
    },
    {
      title: "Lägg till produkter och tjänster från marknadsplatsen",
      description:
        "Klicka för att lägga till produkter och tjänster från marknadsplatsen.",
      target: () => document.querySelector(".plusBtnRef"),
      prevButtonProps: {
        children: <>Tillbaka</>,
      },
      nextButtonProps: {
        children: <>Nästa</>,
      },
      placement: "leftBottom",
      cover: <></>,
    },
    {
      title: "Se din liveprofil",
      description:
        "Se din liveprofil och kopiera länken för att dela med andra.",
      target: () => document.querySelector(".viewLiveProfileBtnRef"),
      prevButtonProps: {
        children: <>Tillbaka</>,
      },
      nextButtonProps: {
        onClick: () => navigate("/dashboard/messaging"),
        children: <>Nästa</>,
      },
      placement: "bottomLeft",
      cover: <></>,
    },
    {
      title: "Hantera företagsförfrågningar",
      description:
        "Här kan du kan godkänna eller avvisa samarbeten och hålla koll på deras status.",
      target: () => document.querySelector(".messagingRef").parentElement,
      prevButtonProps: {
        children: <>Tillbaka</>,
        onClick: () => navigate("/dashboard/my-shop"),
      },
      nextButtonProps: {
        children: <>Nästa</>,
        onClick: () => navigate("/dashboard"),
      },
      placement: "rightTop",
      cover: <></>,
    },
    {
      title: "Hantera din profil",
      description:
        "Redigera din profil, se utbetalningar och hantera förfrågningar.",
      target: () => document.querySelector(".profileImgRef"),
      prevButtonProps: {
        children: <>Tillbaka</>,
        onClick: () => navigate("/dashboard"),
      },
      nextButtonProps: {
        children: <>Kom igång</>,
        onClick: () => {
          handleWelcomeModalCancel();
          navigate("/dashboard"), setTourOpen(false);
        },
      },
      placement: "bottomLeft",
      cover: <></>,
    },
  ];

  const exploreSiteSkipCallback = async () => {
    try {
      setIsLoading(true);

      const requestBody = {
        isExploreComplete: true,
      };
      const exploreSiteSkipResponse = await doFetchSkipExploreSite(requestBody);

      if (exploreSiteSkipResponse?.status === 200) {
        toast.success(exploreSiteSkipResponse?.message);
      } else {
        toast.error(exploreSiteSkipResponse?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  const fetchUserDetails = async () => {
    try {
      setIsLoading(true);

      const userDetailsResponse = await doFetchInfluencerDetails();

      if (userDetailsResponse?.status === 200) {
        const decryptedUserDetails = await JSON.parse(
          getDecryptionString(userDetailsResponse?.data?.data)
        );

        if (
          decryptedUserDetails?.userData?.is_email_verified === false ||
          decryptedUserDetails?.userData?.step < 7 ||
          !decryptedUserDetails?.userData?.is_profile_complete
        ) {
          localStorage.setItem(
            "userDetails",
            JSON.stringify(decryptedUserDetails)
          );
          navigate("/sign-up");
        }

        setUserDetails(decryptedUserDetails);
        // dispatch(storeAuthUserDetails(decryptedUserDetails));
      } else {
        toast.error(userDetailsResponse?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  return {
    steps,
    isIPad,
    isLoading,
    isTourOpen,
    userDetails,
    isCollapsed,
    isWelcomeModalOpen,
    navigate,
    setTourOpen,
    setIsCollapsed,
    setWelcomeModalOpen,
    handleWelcomeModalCancel,
  };
};
