import { Col, Row } from "antd";
import {
  featuredCollectionsBreakPoints,
  featuredProductBreakPoints,
} from "../../constants/data";
import { clsx } from "clsx";
import {
  ILProductGalleryCard,
  ILSwiper,
  ILBrandProductHero,
  ILProductCard,
  ILHead,
  ILNoDataCard,
  ILModal,
  ILButton,
  ILSkeleton,
} from "../../components";
import { SwiperSlide } from "swiper/react";
import { useBrandProductsHook } from "../../hooks/website/brandProducts.hook";
import { Link } from "react-router-dom";
import { gradientCircleRound } from "../../constants/imageData";
import styles from "./ILBrandProducts.module.css";

const ILBrandProducts = () => {
  const {
    params,
    brandData,
    isLoading,
    categoryId,
    isLoggedIn,
    isBtnLoading,
    categoryType,
    collectionList,
    isModalLoading,
    paginationData,
    latestProductList,
    relatedProductList,
    topSellProductList,
    selectedCollection,
    isShopCollectionModalOpen,
    isCollectionAddedModalOpen,
    isSuccessfullyReqModalOpen,
    isCollectionAlreadyAddedModalOpen,
    navigate,
    showSCModal,
    loginHandler,
    handleCAModalOk,
    handleSCModalOk,
    myShopNavigation,
    setIsAddedToShop,
    setPaginationData,
    sendAccessRequest,
    handleTableChange,
    handleProductDetail,
    handleSCModalCancel,
    handleSRModalClose,
    handleCollectionDetail,
    handleAddToShopCollection,
    sendCollectionAccessRequest,
  } = useBrandProductsHook();

  return (
    <>
      <ILHead title="Brand Info" />
      <ILBrandProductHero
        bgColor="#1d1128"
        isLoading={isLoading?.brandDetail}
        categoryId={categoryId}
        brandData={brandData}
      />
      <section
        className={clsx(styles.topSellingProductsSection, "section-md-pb")}
      >
        <div className="container">
          <Row className="section-sm-pt">
            <Col xs={24}>
              <div className="d-flex align-items-center flex-column flex-md-row justify-content-between gap-5 gap-md-4">
                <h2 className="md-secondary-title mb-0 text-center text-md-start">
                  Bäst Säljande&nbsp;
                  {categoryType === "product" ? "Produkter" : "Tjänster"}
                </h2>
                {!isLoading?.topSell && (
                  <Link
                    to="/product-search-filter"
                    className="p big-initial fw-medium gradient-text mb-0 flex-0-auto"
                    onClick={() => {
                      localStorage.setItem("categoryType", categoryType);
                      localStorage.setItem("brandId", params?.id);
                    }}
                  >
                    Visa alla{" "}
                    {categoryType === "product" ? "produkter" : "tjänster"}
                  </Link>
                )}
              </div>

              {isLoading?.topSell ? (
                <div className="row">
                  <div
                    className={clsx(
                      styles.skeletonCardWrap,
                      "col-md-3 skeleton-card border"
                    )}
                  >
                    <ILSkeleton isImgTitleLabelCard2 />
                  </div>
                </div>
              ) : (
                topSellProductList?.count > 0 && (
                  <ILSwiper
                    leftArrowClassName="left-selling-products-arrow"
                    rightArrowClassName="right-selling-products-arrow"
                    swiperButtonClassName="d-none d-md-block"
                    spaceBetween={20}
                    slidesPerView={4}
                    autoplay={false}
                    loop={false}
                    breakpoints={featuredProductBreakPoints}
                    grabCursor={true}
                  >
                    {topSellProductList?.rows?.map((data, SPIndex) => {
                      return (
                        <SwiperSlide key={SPIndex}>
                          <ILProductCard
                            isProductLabel
                            productDataShow={data}
                            isLoggedIn={isLoggedIn}
                            loginHandler={loginHandler}
                            paginationData={paginationData}
                            handleTableChange={handleTableChange}
                            productList={relatedProductList}
                            isRequestSentLoading={
                              isBtnLoading?.sendAccessRequest
                            }
                            setPaginationData={setPaginationData}
                            isModalLoading={isModalLoading}
                            setIsAddedToShop={setIsAddedToShop}
                            sendAccessRequest={sendAccessRequest}
                            handleProductDetail={handleProductDetail}
                            isService={
                              categoryType === "service" ? true : false
                            }
                          />
                        </SwiperSlide>
                      );
                    })}
                  </ILSwiper>
                )
              )}

              {!isLoading?.topSell && topSellProductList?.count === 0 && (
                <ILNoDataCard
                  isIcon
                  className="position-relative z-3 mt-5"
                  title={`Inga ${
                    categoryType === "product" ? "Produkter" : "Tjänster"
                  } Hittades`}
                />
              )}
            </Col>
          </Row>
          <Row className="section-sm-pt">
            <Col xs={24}>
              <div className="d-flex align-items-center flex-column flex-md-row justify-content-between gap-5 gap-md-4">
                <h2 className="md-secondary-title mb-0 text-center text-md-start">
                  Nyligen lanserad
                </h2>
                {!isLoading?.latestProducts && (
                  <Link
                    to="/product-search-filter"
                    className="p big-initial fw-medium gradient-text mb-0 flex-0-auto"
                    onClick={() => {
                      localStorage.setItem("categoryType", categoryType);
                      localStorage.setItem("brandId", params?.id);
                    }}
                  >
                    Visa alla{" "}
                    {categoryType === "product" ? "produkter" : "tjänster"}
                  </Link>
                )}
              </div>

              {isLoading?.latestProducts ? (
                <div className="row">
                  <div
                    className={clsx(
                      styles.skeletonCardWrap,
                      "col-md-3 skeleton-card border"
                    )}
                  >
                    <ILSkeleton isImgTitleLabelCard2 />
                  </div>
                </div>
              ) : (
                latestProductList?.count > 0 && (
                  <ILSwiper
                    leftArrowClassName="left-launched-products-arrow"
                    rightArrowClassName="right-launched-products-arrow"
                    swiperButtonClassName="d-none d-md-block"
                    spaceBetween={20}
                    slidesPerView={4}
                    autoplay={false}
                    loop={false}
                    breakpoints={featuredProductBreakPoints}
                    grabCursor={true}
                  >
                    {latestProductList?.rows?.map((data, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <ILProductCard
                            productDataShow={data}
                            isProductLabel
                            isLoggedIn={isLoggedIn}
                            isRequestSentLoading={
                              isBtnLoading?.sendAccessRequest
                            }
                            isModalLoading={isModalLoading}
                            loginHandler={loginHandler}
                            setIsAddedToShop={setIsAddedToShop}
                            productList={relatedProductList}
                            setPaginationData={setPaginationData}
                            sendAccessRequest={sendAccessRequest}
                            handleProductDetail={handleProductDetail}
                            isService={
                              categoryType === "service" ? true : false
                            }
                          />
                        </SwiperSlide>
                      );
                    })}
                  </ILSwiper>
                )
              )}

              {!isLoading?.latestProducts && latestProductList?.count === 0 && (
                <ILNoDataCard
                  isIcon
                  className="position-relative z-3 mt-5"
                  title={`Inga ${
                    categoryType === "product" ? "Produkter" : "Tjänster"
                  } Hittades`}
                />
              )}
            </Col>
          </Row>
          {categoryType === "product" && (
            <Row className="section-sm-pt">
              <Col xs={24}>
                <div className="d-flex align-items-center flex-column flex-md-row justify-content-between gap-5 gap-md-4">
                  <h2 className="md-secondary-title mb-0 text-center text-md-start">
                    Topp kollektioner
                  </h2>
                  <Link
                    to="/product-search-filter"
                    className="p big-initial fw-medium gradient-text mb-0 flex-0-auto"
                    onClick={() =>
                      localStorage.setItem("categoryType", "collection")
                    }
                  >
                    Visa alla kollektioner
                  </Link>
                </div>
                {isLoading?.getCollections ? (
                  <ILSkeleton isImgTitleLabelCard />
                ) : (
                  collectionList?.count > 0 && (
                    <ILSwiper
                      leftArrowClassName="left-featured-service-arrow"
                      rightArrowClassName="right-featured-service-arrow"
                      swiperButtonClassName="d-none d-md-block"
                      spaceBetween={20}
                      slidesPerView={3}
                      autoplay={false}
                      loop={false}
                      breakpoints={featuredCollectionsBreakPoints}
                      grabCursor={true}
                    >
                      {collectionList?.rows?.map((FCItem, FCIndex) => {
                        return (
                          <SwiperSlide key={FCIndex}>
                            <ILProductGalleryCard
                              ILProductGalleryCardDataList={FCItem}
                              isLoggedIn={isLoggedIn}
                              loginHandler={loginHandler}
                              handleCollectionDetail={handleCollectionDetail}
                              sendAccessRequest={sendCollectionAccessRequest}
                              collectionData={selectedCollection}
                              showSCModal={showSCModal}
                              isShopCollectionModalOpen={
                                isShopCollectionModalOpen
                              }
                              handleSCModalOk={handleSCModalOk}
                              handleSCModalCancel={handleSCModalCancel}
                              handleAddToShopCollection={
                                handleAddToShopCollection
                              }
                              isRequestSentLoading={
                                isBtnLoading?.sendAccessRequest
                              }
                              pagination={{ dynamicBullets: true }}
                              grabCursor={true}
                            />
                          </SwiperSlide>
                        );
                      })}
                    </ILSwiper>
                  )
                )}
                {!isLoading?.getCollections &&
                  categoryType === "product" &&
                  collectionList?.count === 0 && (
                    <ILNoDataCard
                      isIcon
                      className="position-relative z-3 mt-5"
                      title={"Inga Kollektion Hittades"}
                    />
                  )}
              </Col>
            </Row>
          )}
        </div>
      </section>

      <ILModal
        open={isCollectionAddedModalOpen}
        onOk={() => handleCAModalOk("new")}
        onCancel={() => handleCAModalOk("new")}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <span className="lh-0">
                <img
                  src={gradientCircleRound}
                  fluid
                  alt="advertising agency | ilonsi"
                />
              </span>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Din kollektion har lagts till!
              </h4>
              <ILButton
                ILBtnClass="w-100"
                variant={"primary-gradient"}
                handleClick={myShopNavigation}
              >
                Visa i min butik
              </ILButton>
            </div>
          </>
        }
        isFooterRemove
      />

      <ILModal
        open={isCollectionAlreadyAddedModalOpen}
        onOk={() => handleCAModalOk("already")}
        onCancel={() => handleCAModalOk("already")}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <span className="lh-0">
                <img
                  src={gradientCircleRound}
                  fluid
                  alt="advertising agency | ilonsi"
                />
              </span>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Din kollektion har lagts till redan!
              </h4>
              <ILButton
                ILBtnClass="w-100"
                variant={"primary-gradient"}
                handleClick={myShopNavigation}
              >
                Visa i min butik
              </ILButton>
            </div>
          </>
        }
        isFooterRemove
      />

      <ILModal
        open={isShopCollectionModalOpen}
        onOk={handleSCModalOk}
        onCancel={handleSCModalCancel}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <div
                className="brand-logo brand-logo-big flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center mx-auto"
                style={{ borderColor: "#000000" }}
              >
                <img
                  src={selectedCollection?.brand?.profile_image}
                  height={50}
                  width={50}
                  alt="advertising agency | ilonsi"
                  className="object-fit-contain img-fluid"
                />
              </div>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Vill du lägga till den här kollektionen i din butik?
              </h4>
              <p className="paragraph-sm-text paragraph-md-text text-center mb-0">
                Denna kollektion kommer att läggas till i din butik.
              </p>
              <div className="d-flex align-items-center gap-4 modal-more-btn">
                <ILButton
                  disabled={isLoading?.collectionAdd}
                  ILBtnClass="w-50"
                  variant={"primary-gradient"}
                  onClick={handleAddToShopCollection}
                >
                  Ja, Lägg till
                </ILButton>
                <ILButton
                  disabled={isLoading?.collectionAdd}
                  ILBtnClass="w-50"
                  variant={"gradient-bordered"}
                  onClick={handleSCModalCancel}
                >
                  Nej, Avbryt
                </ILButton>
              </div>
            </div>
          </>
        }
        isFooterRemove
      />

      <ILModal
        open={isSuccessfullyReqModalOpen}
        onOk={handleSRModalClose}
        onCancel={handleSRModalClose}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <span className="lh-0">
                <img
                  src={gradientCircleRound}
                  fluid
                  alt="advertising agency | ilonsi"
                />
              </span>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Förfrågan har skickats!
              </h4>
              <ILButton
                ILBtnClass="w-100"
                variant={"primary-gradient"}
                handleClick={() =>
                  navigate("/dashboard/my-profile/my-access-request")
                }
              >
                Hantera förfrågningars
              </ILButton>
            </div>
          </>
        }
        isFooterRemove
      />
    </>
  );
};
export default ILBrandProducts;
