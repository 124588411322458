import { Tabs } from "antd";
import { clsx } from "clsx";
import styles from "./ILTab.module.css";

export const ILTab = ({
  defaultActiveKey,
  tabItem,
  onChange,
  onTabClick,
  tabClassName,
  isSecondaryTabStyles,
  ...rest
}) => {
  return (
    <>
      <Tabs
        defaultActiveKey={defaultActiveKey}
        items={tabItem}
        onChange={onChange}
        onTabClick={onTabClick}
        className={clsx(
          isSecondaryTabStyles
            ? styles.secondaryTabWrap
            : styles.primaryTabWrap,
          tabClassName
        )}
        animated={true}
        {...rest}
      />
    </>
  );
};
