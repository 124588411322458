import React from "react";
import { Steps } from "antd";
import { Link } from "react-router-dom";
import { ArrowLeft02Icon } from "hugeicons-react";
import { ILButton } from "../ILButton";
import { ILModal } from "../ILModal";
import { ILSelect } from "../ILSelect";
import { ILTextInput } from "../ILTextInput";
import { ilonsiLogo } from "../../constants/imageData";
import styles from "./ILSteps.module.css";
import { ILRegistering } from "../../pages/AuthenticationPages/ILSignUp/ILSignUpSteps/ILRegistering";
import { ILSignUpEmailVerification } from "../../pages/AuthenticationPages/ILSignUp/ILSignUpSteps/ILSignUpEmailVerification";
import { ILAccounts } from "../../pages/AuthenticationPages/ILSignUp/ILSignUpSteps/ILAccounts";
import { ILGeneralInfo } from "../../pages/AuthenticationPages/ILSignUp/ILSignUpSteps/ILGeneralInfo";
import { ILInterests } from "../../pages/AuthenticationPages/ILSignUp/ILSignUpSteps/ILInterests";
import { ILUploadProfile } from "../../pages/AuthenticationPages/ILSignUp/ILSignUpSteps/ILUploadProfile";
import { ILBioInformation } from "../../pages/AuthenticationPages/ILSignUp/ILSignUpSteps/ILBioInformation";
import { useSignUpHook } from "../../hooks/auth/signup.hook";
import { ILLoader } from "../ILLoader";
import { ILAccountCreatedModal } from "../../pages/AuthenticationPages/ILSignUp/ILAccountCreatedModal";
import clsx from "clsx";

export const ILSteps = () => {
  const {
    time,
    current,
    otpData,
    isLoading,
    isShowIcon,
    signUpData,
    citiesList,
    isModalOpen,
    channelList,
    countryList,
    isCityLoading,
    resendOtpView,
    storedUserData,
    socialMediaData,
    countryCodeList,
    socialMediaList,
    interestsService,
    validateMessages,
    interestsProduct,
    productCategoryList,
    serviceCategoryList,
    isAccountCreatedModal,
    deleteChannelModalOpen,
    prev,
    navigate,
    setCurrent,
    handleModalOk,
    sendOtpCallback,
    handleModalCancel,
    handleInputChange,
    handleSelectChange,
    handleSignUpSubmit,
    handleIconOpenChange,
    handleAddChannelSubmit,
    handleFileInputChange,
    handleChannelDeleteModal,
    handleRemoveSocialMediaAcc,
    handleChannelDeleteModalCancel,
    homeNavigate,
  } = useSignUpHook();

  const signUpStepsData = [
    {
      title: "one",
      content: (
        <ILRegistering
          signUpData={signUpData}
          isShowIcon={isShowIcon}
          citiesList={citiesList}
          countryList={countryList}
          countryCodeList={countryCodeList}
          validateMessages={validateMessages}
          handleInputChange={handleInputChange}
          handleSelectChange={handleSelectChange}
          handleIconOpenChange={handleIconOpenChange}
        />
      ),
    },
    {
      title: "two",
      content: (
        <ILSignUpEmailVerification
          time={time}
          otpData={otpData}
          signUpData={signUpData}
          validateMessages={validateMessages}
          handleSelectChange={handleSelectChange}
        />
      ),
    },
    {
      title: "three",
      content: (
        <ILAccounts
          socialMediaData={socialMediaData}
          socialMediaList={socialMediaList}
          handleInputChange={handleInputChange}
          deleteChannelModalOpen={deleteChannelModalOpen}
          handleChannelDeleteModal={handleChannelDeleteModal}
          handleRemoveSocialMediaAcc={handleRemoveSocialMediaAcc}
          handleChannelDeleteModalCancel={handleChannelDeleteModalCancel}
        />
      ),
    },
    {
      title: "four",
      content: (
        <ILGeneralInfo
          signUpData={signUpData}
          validateMessages={validateMessages}
          handleInputChange={handleInputChange}
          handleSelectChange={handleSelectChange}
        />
      ),
    },
    {
      title: "five",
      content: (
        <ILInterests
          productCategoryList={productCategoryList}
          serviceCategoryList={serviceCategoryList}
          signUpData={signUpData}
          validateMessages={validateMessages}
          handleInputChange={handleInputChange}
          interestsProduct={interestsProduct}
          interestsService={interestsService}
        />
      ),
    },
    {
      title: "six",
      content: (
        <ILUploadProfile
          signUpData={signUpData}
          validateMessages={validateMessages}
          handleFileInputChange={handleFileInputChange}
        />
      ),
    },
    {
      title: "seven",
      content: (
        <ILBioInformation
          signUpData={signUpData}
          validateMessages={validateMessages}
          handleInputChange={handleInputChange}
        />
      ),
    },
  ];

  const stepCountingNumber = [
    {
      labelTitle: "Har du redan ett influencerkonto?",
      linkText: "Logga in",
    },
    {
      labelTitle: "Fick du inte någon kod?",
      linkText: resendOtpView ? "Skicka kod igen" : `Resend in ${time} sec`,
    },
    {
      labelTitle: "",
      linkText: "",
    },
    {
      labelTitle: "",
      linkText: "",
    },
    {
      labelTitle: "",
      linkText: "",
    },
    {
      labelTitle: "",
      linkText: "",
    },
    {
      labelTitle: "",
      linkText: "",
    },
  ];

  const items = signUpStepsData.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <>
      {isCityLoading && <ILLoader isBtn />}

      {current === 0 && (
        <img
          src={ilonsiLogo}
          alt="advertising agency | ilonsi"
          className="auth-logo cursor-pointer"
          onClick={() => homeNavigate("/")}
        />
      )}

      {storedUserData && Object?.keys(storedUserData)?.length > 0
        ? current > storedUserData?.userData?.step && (
            <span
              onClick={() => prev()}
              className="auth-back-arrow d-inline-flex cursor-pointer"
            >
              <ArrowLeft02Icon
                size={35}
                color={"#1D1128"}
                variant={"stroke"}
                strokeWidth={1.5}
                className="svg-hover"
              />
            </span>
          )
        : current > 0 && (
            <span
              onClick={() => prev()}
              className="auth-back-arrow d-inline-flex cursor-pointer"
            >
              <ArrowLeft02Icon
                size={35}
                color={"#1D1128"}
                variant={"stroke"}
                strokeWidth={1.5}
                className="svg-hover"
              />
            </span>
          )}

      <div className="d-flex align-items-center mb-4">
        <p className="h5 big fw-medium">
          {current + 1}/
          <span className="text-gray p big">{stepCountingNumber.length}</span>
        </p>
        {(current === 5 && (
          <>
            <Link
              onClick={() => setCurrent(current + 1)}
              className="gradient-text p fw-semibold ms-auto cursor-pointer outline-focus"
            >
              Hoppa över
            </Link>
          </>
        )) ||
          ""}
        {(current === 2 && (
          <>
            <span
              className="gradient-text p fw-semibold ms-auto cursor-pointer outline-focus"
              onClick={() => handleModalOk("channel")}
            >
              +Lägg till kanal
            </span>
          </>
        )) ||
          ""}
      </div>
      {/* STEPS HEADER */}
      <Steps current={current} items={items} />
      {/* STEPS CONTENT */}
      <div>{signUpStepsData[current]?.content}</div>
      {/* STEPS NEXT, PREV BUTTON */}
      <div>
        {current < signUpStepsData.length - 1 && (
          <>
            <ILButton
              disabled={
                current === 2
                  ? socialMediaList?.length > 0
                    ? false
                    : true
                  : isLoading
              }
              ILBtnClass="w-100"
              variant={"primary-gradient"}
              onClick={handleSignUpSubmit}
            >
              {isLoading ? (
                <ILLoader isBtn />
              ) : (
                (current === 6 && "Bli Medlem") ||
                (current === 1 && "Bekräfta") ||
                "Fortsätt"
              )}
            </ILButton>
          </>
        )}
        {current === signUpStepsData.length - 1 && (
          <ILButton
            ILBtnClass="w-100"
            variant={"primary-gradient"}
            onClick={handleSignUpSubmit}
          >
            Bli Medlem
          </ILButton>
        )}

        {stepCountingNumber.map((stepItem, signUpStepIndex) => {
          if (current === signUpStepIndex) {
            return (
              <div key={signUpStepIndex}>
                {stepItem.labelTitle && (
                  <p className="p sm fw-medium text-gray text-center">
                    {stepItem.labelTitle}&nbsp;&nbsp;
                    {stepItem.linkText && (
                      <Link
                        {...(current === 1 && {
                          disabled: resendOtpView ? false : true,
                        })}
                        {...(current === 1 && resendOtpView
                          ? {
                              onClick: () => sendOtpCallback({ resend: true }),
                            }
                          : { to: "/login" })}
                      >
                        {stepItem.linkText}
                      </Link>
                    )}
                  </p>
                )}
              </div>
            );
          }
        })}
      </div>
      {/* STEPS END */}
      {/* MODAL */}
      <ILModal
        open={isModalOpen}
        onOk={() => handleModalOk("channel")}
        onCancel={() => handleModalCancel("channel")}
        title={
          <>
            <span
              className="d-flex align-items-center gap-3 mb-4 cursor-pointer hover-text"
              onClick={handleModalCancel}
            >
              <ArrowLeft02Icon size={28} color={"#000000"} variant={"stroke"} />
              Lägg till dina sociala mediekonton
            </span>
          </>
        }
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <ILSelect
              isRequired
              label="Sociala mediekonto"
              id="signUpChannelName"
              options={channelList}
              datatestid="signUpChannelName"
              placeholder="Välj social medieplattform"
              name="channelId"
              value={socialMediaData?.channelId}
              handleChange={(event) => handleSelectChange(event, "channelId")}
              errorMsg={validateMessages?.channelId}
            />
            <ILTextInput
              isRequiredInput
              id="signUpChannelURL"
              datatestid="signUpChannelURL"
              type="text"
              name="mediaLink"
              value={socialMediaData?.mediaLink}
              label="URL för socialt mediekonto"
              handleChange={handleInputChange}
              placeholder={"Ange URL för socialt mediekonto"}
              errorMsg={validateMessages?.mediaLink}
            />
            <ILButton
              variant={"primary-gradient"}
              ILBtnClass="w-100 mt-5 mt-xl-4 mt-xxl-3"
              onClick={handleAddChannelSubmit}
            >
              Lägg till Sociala mediekonto
            </ILButton>
          </>
        }
        isFooterRemove
      />

      <ILModal
        open={isAccountCreatedModal}
        onOk={() => handleModalOk("accountCreated")}
        onCancel={() => handleModalCancel("accountCreated")}
        title={""}
        className={clsx(styles.authSuccessfullyModalWrap)}
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <ILAccountCreatedModal navigate={navigate} />
          </>
        }
        isFooterRemove
      />
    </>
  );
};
