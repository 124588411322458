import { Col, Form, Row } from "antd";
import { ILBasicCheckbox, ILTextInput } from "../../../../components";

export const ILBioInformation = ({
  signUpData,
  validateMessages,
  handleInputChange,
}) => {
  return (
    <>
      <h1 className="secondary-title md-secondary-title">Kort bio</h1>
      <p className="paragraph-sm-text paragraph-md-text">
        Vänligen skriv en kort bio om dig själv
      </p>
      <Form>
        <Row gutter={20}>
          <Col xs={24} className="mb-extra-small">
            <ILTextInput
              textAreaShow
              id="signUpDetails"
              datatestid="signUpDetails"
              ILInputLabelClass="mb-0"
              autoSize={{
                minRows: 8,
              }}
              name="bio"
              value={signUpData?.bio}
              label=""
              handleChange={handleInputChange}
              placeholder={"Jag älskar mode och resa!"}
              errorMsg={validateMessages?.bio}
            />
          </Col>
          <Col xs={24} className={"d-flex flex-column gap-4"}>
            <ILBasicCheckbox
              className="dark-purple text-dark-purple"
              children="Jag godkänner allmänna villkor"
              name="isAcceptTerms"
              value={signUpData?.isAcceptTerms}
              handleChange={handleInputChange}
              errorMsg={validateMessages?.isAcceptTerms}
            />
            <ILBasicCheckbox
              className="dark-purple text-dark-purple"
              children="Jag bekräftar att ILONSI kommer att samla in och behandla mina personuppgifter enligt integritetspolicy"
              name="isAcceptAcknowledge"
              value={signUpData?.isAcceptAcknowledge}
              handleChange={handleInputChange}
              errorMsg={validateMessages?.isAcceptAcknowledge}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};
