import {
  bioRegex,
  emailRegex,
  imageFormatRegex,
  nameRegex,
  numberRegex,
  passwordRegex,
  socialSecurityRegex,
  urlRegex,
  userNameRegex,
} from "../../regexs";

export const validateInfluencerRegister = (name, value, validateMessages) => {
  let errors = { ...validateMessages };

  switch (name) {
    case "name":
      if (
        value === undefined ||
        value?.trim() === null ||
        value?.trim() === ""
      ) {
        errors.name = "Influencerns namn krävs";
      } else if (!nameRegex.test(value)) {
        errors.name = "Namn kan bara innehålla alfabet och mellanslag";
      } else if (value.length < 2) {
        errors.name = "Namnet måste vara minst 2 bokstäver";
      } else if (value.length > 35) {
        errors.name = "Namnet måste vara mindre än eller lika med 35 bokstäver";
      } else {
        delete errors.name;
      }
      break;

    case "userName":
      if (
        value === undefined ||
        value?.trim() === null ||
        value?.trim() === ""
      ) {
        errors.userName = `Användarnamn krävs`;
      } else if (!userNameRegex.test(value)) {
        errors.userName =
          "Användarnamnet kan endast innehålla bokstäver, understreck, punkt och siffror";
      } else if (/\.$/.test(value)) {
        errors.userName = "Användarnamn kan inte sluta med en punkt (.)";
      } else if (value.length < 5) {
        errors.userName = "Användarnamnet måste vara minst 5 bokstäver";
      } else if (value.length > 35) {
        errors.userName =
          "Namnet måste vara mindre än eller lika med 35 bokstäver";
      } else {
        delete errors.userName;
      }
      break;

    case "ePostAddress":
      if (
        value === undefined ||
        value?.trim() === null ||
        value?.trim() === ""
      ) {
        errors.ePostAddress = "E-postadress krävs";
      } else if (!emailRegex.test(value)) {
        errors.ePostAddress = "Ogiltig e-postadress";
      } else {
        delete errors.ePostAddress;
      }
      break;

    case "phoneNumber":
      if (value === undefined || value === null || value === "") {
        errors.phoneNumber = "Telefonnummer krävs";
      } else if (value.startsWith("0")) {
        if (value?.length !== 10) {
          errors.phoneNumber =
            "Telefonnumrets längd måste vara lika med 10 siffror";
        } else {
          delete errors.phoneNumber;
        }
      } else if (!value.startsWith("0")) {
        if (value?.length !== 9) {
          errors.phoneNumber =
            "Telefonnumrets längd måste vara lika med 9 siffror";
        } else {
          delete errors.phoneNumber;
        }
      } else {
        delete errors.phoneNumber;
      }
      break;

    case "password":
      if (value.trim() === null || value.trim() === "" || value === undefined) {
        errors.password = "Lösenord krävs";
      } else if (!passwordRegex.test(value)) {
        errors.password =
          "Lösenordet måste innehålla en stor bokstav, en liten bokstav, ett nummer, ett specialtecken och inga mellanslag";
      } else if (value.length < 6) {
        errors.password = "Lösenordslängden måste vara minst 6 bokstäver";
      } else if (value.length > 16) {
        errors.password =
          "Lösenordslängden måste vara mindre än eller lika med 16";
      } else {
        delete errors.password;
      }
      break;

    case "gender":
      if (value === undefined || value === null || value === "") {
        errors.gender = "Vänligen välj kön";
      } else {
        delete errors.gender;
      }
      break;

    case "cityId":
      if (value === undefined || value === null || value === "") {
        errors.cityId = "Välj stad";
      } else {
        delete errors.cityId;
      }
      break;

    default:
      break;
  }

  return { errors };
};

export const validateSubmitInfluencerRegister = (data) => {
  let errors = {};

  if (data.name === undefined || data.name === null || data.name === "") {
    errors.name = "Influencerns namn krävs";
  }

  if (data.name) {
    if (!nameRegex.test(data.name)) {
      errors.name = "Namn kan bara innehålla alfabet och mellanslag";
    }
    if (data.name.length < 3) {
      errors.name = "Namnet måste bestå av minst 3 bokstäver";
    }
    if (data.name.length > 35) {
      errors.name = "Namnet måste vara mindre än eller lika med 35 bokstäver";
    }
  }

  if (
    data.userName === undefined ||
    data.userName === null ||
    data.userName === ""
  ) {
    errors.userName = "Användarnamn krävs";
  }

  if (data.userName) {
    if (!userNameRegex.test(data.userName)) {
      errors.userName =
        "Användarnamnet kan endast innehålla bokstäver, understreck, punkt och siffror";
    }
    if (/\.$/.test(data.userName)) {
      errors.userName = "Användarnamn kan inte sluta med en punkt (.)";
    }

    if (data.userName.length < 5) {
      errors.userName = "Användarnamn måste bestå av minst 5 bokstäver";
    }
    if (data.userName.length > 35) {
      errors.userName =
        "Användarnamn måste vara mindre än eller lika med 35 bokstäver";
    }
  }

  if (
    data.ePostAddress === undefined ||
    data.ePostAddress === null ||
    data.ePostAddress === ""
  ) {
    errors.ePostAddress = "E-postadress krävs";
  }

  if (data.ePostAddress && !emailRegex.test(data.ePostAddress)) {
    errors.ePostAddress = "Ogiltig e-postadress";
  }

  if (
    data.phoneNumber === undefined ||
    data.phoneNumber === null ||
    data.phoneNumber === ""
  ) {
    errors.phoneNumber = "Telefonnummer krävs";
  }

  if (data?.phoneNumber) {
    if (data?.phoneNumber.startsWith("0")) {
      if (data?.phoneNumber?.length !== 10) {
        errors.phoneNumber =
          "Telefonnumrets längd måste vara lika med 10 siffror";
      }
    } else if (!data?.phoneNumber.startsWith("0")) {
      if (data?.phoneNumber?.length !== 9) {
        errors.phoneNumber =
          "Telefonnumrets längd måste vara lika med 9 siffror";
      }
    }
  }

  if (
    data.password === undefined ||
    data.password?.trim() === null ||
    data.password?.trim() === ""
  ) {
    errors.password = "Lösenord krävs";
  }

  if (data.password) {
    if (!passwordRegex.test(data.password)) {
      errors.password =
        "Lösenordet måste innehålla en stor bokstav, en liten bokstav, ett nummer, ett specialtecken och inga mellanslag ";
    }
    if (data.password.length < 6) {
      errors.password = "Lösenordslängden måste vara minst 6 bokstäver";
    }
    if (data.password.length > 16) {
      errors.password =
        "Lösenordslängden måste vara mindre än eller lika med 16 bokstäver";
    }
  }

  if (
    data.gender === undefined ||
    data.gender?.trim() === null ||
    data.gender?.trim() === ""
  ) {
    errors.gender = "Välj kön";
  }

  if (data.cityId === undefined || data.cityId === null || data.cityId === "") {
    errors.cityId = "Välj stad";
  }

  return { errors };
};

export const validateAddChannel = (name, value, validateMessages) => {
  let errors = { ...validateMessages };
  switch (name) {
    case "channelId":
      if (value == undefined || value === null || value === "") {
        errors.channelId = "Välj kanal";
      } else {
        delete errors.channelId;
      }
      break;

    case "mediaLink":
      if (value == undefined || value === null || value === "") {
        errors.mediaLink = "URL för sociala mediekontot krävs";
      } else if (!urlRegex.test(value.trim())) {
        errors.mediaLink = "Ogiltig URL";
      } else {
        delete errors.mediaLink;
      }
      break;

    default:
      break;
  }

  return { errors };
};

export const validateSubmitAddChannel = (data) => {
  let errors = {};
  if (
    data.channelId === undefined ||
    data.channelId === null ||
    data.channelId === ""
  ) {
    errors.channelId = "Välj kanal";
  }

  if (
    data.mediaLink === undefined ||
    data.mediaLink === null ||
    data.mediaLink === ""
  ) {
    errors.mediaLink = "URL för sociala mediekontot krävs";
  }

  if (data.mediaLink && !urlRegex.test(data.mediaLink.trim())) {
    errors.mediaLink = "Ogiltig URL";
  }

  return { errors };
};

export const validateGeneralInformation = (name, value, validateMessages) => {
  let errors = { ...validateMessages };
  switch (name) {
    case "socialSecurityNumber":
      if (
        value === undefined ||
        value?.trim() === null ||
        value?.trim() === ""
      ) {
        errors.socialSecurityNumber = "Personnummer krävs";
      } else if (!socialSecurityRegex.test(value)) {
        errors.socialSecurityNumber = "Alfabet är inte tillåtna";
      } else if (value?.replace(/-/g, "")?.length < 10) {
        errors.socialSecurityNumber =
          "Personnummer måste vara minst 10 siffror långt";
      } else if (value?.replace(/-/g, "")?.length > 12) {
        errors.socialSecurityNumber =
          "Personnummerlängden måste vara mindre än eller lika med 12";
      } else {
        delete errors.socialSecurityNumber;
      }
      break;

    case "address":
      if (
        value === undefined ||
        value?.trim() === null ||
        value?.trim() === ""
      ) {
        errors.address = "Adress krävs";
      } else {
        delete errors.address;
      }
      break;

    case "details":
      if (value && value !== "") {
        if (value.length > 250) {
          errors.details = "Högst 250 tecken är tillåtna";
        } else {
          delete errors.details;
        }
      } else {
        delete errors.details;
      }
      break;

    case "pincode":
      if (value === undefined || value === null || value === "") {
        errors.pincode = "Postnummer krävs";
      } else if (value.length < 5) {
        errors.pincode = "Postnummer måste vara minst 5 siffror långt";
      } else if (value.length > 6) {
        errors.pincode =
          "Pinkodens längd måste vara mindre än eller lika med 6";
      } else {
        delete errors.pincode;
      }
      break;

    default:
      break;
  }

  return { errors };
};

export const validateSubmitGeneralInformation = (data) => {
  let errors = {};

  if (
    data.socialSecurityNumber === undefined ||
    data.socialSecurityNumber === null ||
    data.socialSecurityNumber === ""
  ) {
    errors.socialSecurityNumber = "Personnummer krävs";
  }

  if (
    data?.socialSecurityNumber?.length > 0 &&
    data?.socialSecurityNumber !== ""
  ) {
    if (!socialSecurityRegex.test(data?.socialSecurityNumber)) {
      errors.socialSecurityNumber = "Ogiltigt personnummer";
    }

    if (data?.socialSecurityNumber?.replace(/-/g, "")?.length < 10) {
      errors.socialSecurityNumber =
        "Personnummer måste vara minst 10 siffror långt";
    }
    if (data?.socialSecurityNumber?.replace(/-/g, "")?.length > 12) {
      errors.socialSecurityNumber =
        "Personnummerlängden måste vara mindre än eller lika med 12";
    }
  }

  if (
    data.address === undefined ||
    data.address === null ||
    data.address === ""
  ) {
    errors.address = "Adress krävs";
  }

  if (data?.details && data?.details?.length > 250) {
    errors.details = "Högst 250 tecken är tillåtna";
  }

  if (
    data.pincode === undefined ||
    data.pincode === null ||
    data.pincode === ""
  ) {
    errors.pincode = "Postnummer krävs";
  }

  if (data?.pincode) {
    if (data?.pincode?.length < 5) {
      errors.pincode = "Postnummer måste vara minst 5 siffror långt";
    }
    if (data?.pincode?.length > 6) {
      errors.pincode = "Pinkodens längd måste vara mindre än eller lika med 6";
    }

    if (!numberRegex.test(data.pincode)) {
      errors.pincode = "Ogiltig pinkod";
    }
  }

  return { errors };
};

export const validateCategory = (name, value, validateMessages) => {
  let errors = { ...validateMessages };
  switch (name) {
    case "categoryId":
      if (value == undefined || value === null || value === "") {
        errors.categoryId = "Välj en kategori";
      } else {
        delete errors.categoryId;
      }
      break;

    default:
      break;
  }

  return { errors };
};

export const validateSubmitCategory = (data) => {
  let errors = {};

  if (
    data.categoryId === undefined ||
    data.categoryId === null ||
    data.categoryId === ""
  ) {
    errors.categoryId = "Välj en kategori";
  }

  return { errors };
};

export const validateProfileImage = (name, value, validateMessages) => {
  let errors = { ...validateMessages };
  switch (name) {
    case "image":
      const fileSizeInMB = value?.size / 1024 / 1024;
      if (value) {
        if (fileSizeInMB > 5) {
          errors.image = "Filstorleken bör vara mindre än 5 MB";
        } else if (!imageFormatRegex?.test(value?.name)) {
          errors.image = "Endast filformaten .jpg, .jpeg och .png är tillåtna";
        } else {
          delete errors.image;
        }
      }
      break;

    default:
      break;
  }

  return { errors };
};

export const validateSubmitProfileImage = (data) => {
  let errors = {};

  if (typeof data?.image === "object") {
    const fileSizeInMB = data?.image[0]?.size / 1024 / 1024;

    if (!imageFormatRegex.test(data?.image[0]?.name)) {
      errors.image = "Endast filformaten .jpg, .jpeg och .png är tillåtna";
    }
    if (fileSizeInMB > 5) {
      errors.image = "Filstorleken bör vara mindre än 5 MB";
    }
  }

  return { errors };
};

export const validateAcceptTerms = (
  name,
  value,
  checkedValue,
  validateMessages
) => {
  let errors = { ...validateMessages };

  switch (name) {
    case "bio":
      if (
        value == undefined ||
        value === null ||
        value === "" ||
        value === false
      ) {
        errors.bio = "Vänligen skriv din bio";
      } else if (!bioRegex.test(value)) {
        errors.bio = "Bio måste vara mellan 1 och 100 tecken";
      } else {
        delete errors.bio;
      }
      break;

    case "isAcceptTerms":
      if (
        checkedValue == undefined ||
        checkedValue === null ||
        checkedValue === "" ||
        checkedValue === false
      ) {
        errors.isAcceptTerms = "Vänligen markera att du godkänner villkoren";
      } else {
        delete errors.isAcceptTerms;
      }
      break;

    case "isAcceptAcknowledge":
      if (
        checkedValue == undefined ||
        checkedValue === null ||
        checkedValue === "" ||
        checkedValue === false
      ) {
        errors.isAcceptAcknowledge =
          "Vänligen markera att du godkänner sekretesspolicyn";
      } else {
        delete errors.isAcceptAcknowledge;
      }
      break;

    default:
      break;
  }

  return { errors };
};

export const validateSubmitAcceptTerms = (data) => {
  let errors = {};

  if (data.bio === undefined || data.bio === null || data.bio === "") {
    errors.bio = "Vänligen skriv din bio";
  }

  if (data?.bio) {
    if (!bioRegex.test(data.bio)) {
      errors.bio = "Bio måste vara mellan 1 och 100 tecken";
    }
  }

  if (
    data.isAcceptTerms === undefined ||
    data.isAcceptTerms === null ||
    data.isAcceptTerms === "" ||
    data.isAcceptTerms === false
  ) {
    errors.isAcceptTerms = "Vänligen markera att du godkänner villkoren";
  }

  if (
    data.isAcceptAcknowledge === undefined ||
    data.isAcceptAcknowledge === null ||
    data.isAcceptAcknowledge === "" ||
    data.isAcceptAcknowledge === false
  ) {
    errors.isAcceptAcknowledge =
      "Vänligen markera att du godkänner sekretesspolicyn";
  }

  return { errors };
};
