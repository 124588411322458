import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getDecryptionString } from "../../../services";
import { useLocation, useParams } from "react-router-dom";
import { doFetchProductDetails, doFetchServiceDetails } from "../../../actions";
import {
  clickIcon,
  earningsIcon,
  sellIcon,
} from "../../../constants/imageData";

export const useMyShopProductDetailsHook = () => {
  const params = useParams();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [productServiceData, setProductServiceData] = useState({});
  const [isService, setIsService] = useState(
    location.pathname.includes("service-details") ? true : false
  );
  const [chartData, setChartData] = useState({});
  const [dateRange, setDateRange] = useState("weekly");

  useEffect(() => {
    fetchProductDetails(params?.id);
  }, []);

  useEffect(() => {
    if (dateRange === "weekly") {
      setChartData({
        labels: [
          "Måndag",
          "Tisdag",
          "Onsdag",
          "Torsdag",
          "Fredag",
          "Lördag",
          "Söndag",
        ],
        value: productServiceData?.earning_insight?.earningByWeek?.map(
          (data) => data?.earning
        ),
      });
    } else if (dateRange === "monthly") {
      setChartData({
        labels: [
          "Januari",
          "Februari",
          "Mars",
          "April",
          "Maj",
          "Juni",
          "Juli",
          "Augusti",
          "September",
          "Oktober",
          "November",
          "December",
        ],
        value: productServiceData?.earning_insight?.earningByMonth?.map(
          (data) => data?.earning
        ),
      });
    } else {
      setChartData({
        labels: productServiceData?.earning_insight?.earningByYear?.map(
          (data) => data?.year
        ),
        value: productServiceData?.earning_insight?.earningByYear?.map(
          (data) => data?.earning
        ),
      });
    }
  }, [dateRange, productServiceData]);

  const handleSelectChange = async (value, name) => {
    setDateRange(value);
  };

  const fetchProductDetails = async (id) => {
    try {
      setIsLoading(true);
      let productServiceDetailResponse;

      if (isService) {
        productServiceDetailResponse = await doFetchServiceDetails(id);
      } else {
        productServiceDetailResponse = await doFetchProductDetails(id);
      }

      if (productServiceDetailResponse?.status === 200) {
        const decryptedProductDetailData = await JSON.parse(
          getDecryptionString(productServiceDetailResponse?.data?.data)
        );
        setProductServiceData(decryptedProductDetailData);
      } else {
        toast.error(productServiceDetailResponse?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(
        `Error occurred during fetching  ${
          isService ? "service" : "product"
        } details:`,
        error
      );
      setIsLoading(false);
    }
  };

  const productSellClickList = [
    {
      data: productServiceData?.totalEarning,
      title: "Totala intäkter",
      image: earningsIcon,
    },
    {
      data: productServiceData?.clicks,
      title: "Totala klick",
      image: clickIcon,
    },
    {
      data: productServiceData?.sells,
      title: "Totala försäljningar",
      image: sellIcon,
    },
  ];

  return {
    isLoading,
    isService,
    chartData,
    dateRange,
    productServiceData,
    productSellClickList,
    handleSelectChange,
  };
};
