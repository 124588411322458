import React from "react";
import clsx from "clsx";
import { Swiper } from "swiper/react";
import { FreeMode, Autoplay, Pagination, Navigation } from "swiper/modules";
import { Icons } from "../../constants/icons";
import styles from "./ILSwiper.module.css";

export const ILSwiper = ({
  className,
  pagination,
  leftArrowClassName,
  rightArrowClassName,
  navigation = {
    nextEl: `.${rightArrowClassName}`,
    prevEl: `.${leftArrowClassName}`,
  },
  spaceBetween,
  slidesPerView,
  autoplay,
  centeredSlides,
  direction,
  loop,
  breakpoints,
  grabCursor,
  freeMode,
  children,
  isNeedDefaultSwiperButton,
  isNotDefaultSwiperSlider,
  swiperButtonClassName,
  ...rest
}) => {
  return (
    <>
      <div className="position-relative w-100">
        <Swiper
          className={clsx(
            isNeedDefaultSwiperButton ? styles.isNeedDefaultSwiperButton : "",
            isNotDefaultSwiperSlider ? "" : styles.defaultSwiperSlider,
            className
          )}
          pagination={pagination}
          navigation={navigation}
          spaceBetween={spaceBetween}
          slidesPerView={slidesPerView}
          autoplay={autoplay}
          centeredSlides={centeredSlides}
          direction={direction}
          loop={loop}
          breakpoints={breakpoints}
          grabCursor={grabCursor}
          freeMode={freeMode}
          modules={[FreeMode, Autoplay, Pagination, Navigation]}
          {...rest}
        >
          {children}
        </Swiper>
        <div
          className={clsx(
            isNeedDefaultSwiperButton ? "d-none" : "",
            swiperButtonClassName,
            "swiper-btn-wrapper d-flex align-items-center justify-content-center"
          )}
        >
          <button
            name="swiper-left-btn"
            className={clsx(
              leftArrowClassName,
              styles.swiperArrowIcon,
              styles.swiperLeftArrowIcon,
              "btn-swiper swiper-left-btn d-flex align-items-center justify-content-center rounded-circle transition-smooth position-absolute"
            )}
          >
            {Icons.leftArrow}
          </button>

          <button
            name="swiper-right-btn"
            className={clsx(
              rightArrowClassName,
              styles.swiperArrowIcon,
              styles.swiperRightArrowIcon,
              "btn-swiper swiper-right-btn d-flex align-items-center justify-content-center rounded-circle transition-smooth position-absolute"
            )}
          >
            {Icons.rightArrow}
          </button>
        </div>
      </div>
    </>
  );
};
