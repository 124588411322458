import { Layout, Tour } from "antd";
import React from "react";
import { Outlet } from "react-router-dom";
import { ILSider } from "../../components/ILSider";
import { ILButton, ILModal, ILScrollToTop } from "../../components";
import clsx from "clsx";
import styles from "./DashboardLayout.module.css";
import { useInfluencerDashboardHook } from "../../hooks/website/influencerDashboard.hook";

export const DashboardLayout = () => {
  const {
    steps,
    isIPad,
    isLoading,
    isTourOpen,
    userDetails,
    isCollapsed,
    isWelcomeModalOpen,
    setTourOpen,
    setIsCollapsed,
    setWelcomeModalOpen,
    handleWelcomeModalCancel,
  } = useInfluencerDashboardHook();

  return (
    <>
      <Layout hasSider>
        <ILSider
          collapsed={isCollapsed}
          handleSidebarCollapsed={() => setIsCollapsed(!isCollapsed)}
        />
        <Layout
          className={clsx(styles.ILMainContentLayout, {
            [styles.ILCollapsedLayout]: isCollapsed,
          })}
        >
          <ILScrollToTop />
          <main className={styles.mainContent}>
            <Outlet />
          </main>
        </Layout>

        {/* TOUR START */}
        <Tour
          open={isTourOpen}
          onClose={() => setTourOpen(false)}
          steps={steps}
          indicatorsRender={(current, total) => (
            <span>
              <span className="h5 big">{current + 1}/</span>{" "}
              <span className="h6 text-gray">{total}</span>
            </span>
          )}
        />
        {/* TOUR END */}

        {/* WELCOME MODAL START */}
        {!userDetails?.userData?.is_explore_complete &&
          isWelcomeModalOpen &&
          !isIPad &&
          !isLoading && (
            <ILModal
              open={!isIPad ? isWelcomeModalOpen : false}
              onCancel={handleWelcomeModalCancel}
              className=""
              wrapClassName=""
              centered={true}
              closable=""
              width="518px"
              maskClosable={false}
              isCloseIconRemove
              isFooterRemove
            >
              <div
                className={clsx(
                  styles.modalIcon,
                  "ratio ratio-1x1 rounded-circle"
                )}
              >
                <img
                  src={userDetails?.userData?.profile_image}
                  alt="advertising agency | ilonsi"
                  className="w-100 h-100 object-fit-cover radius-inherit"
                />
              </div>
              <h4 className="fw-semibold text-center mb-2">
                Välkommen {userDetails?.userData?.name}!
              </h4>
              <p className="paragraph-sm-text paragraph-text-16 text-center mb-0">
                Låt oss visa dig runt - hur du kan utforska funktionerna och
                använda instrumentpanelen.
              </p>
              <div className="d-flex gap-4 align-items-center justify-content-center mt-5 modal-more-btn">
                <ILButton
                  handleClick={() => {
                    setTourOpen(true);
                    setWelcomeModalOpen(false);
                  }}
                  ILBtnClass={"w-100"}
                  variant={"primary-gradient"}
                >
                  Utforska
                </ILButton>
                <ILButton
                  handleClick={handleWelcomeModalCancel}
                  ILBtnClass={"w-100"}
                  variant={"gradient-bordered"}
                >
                  Hoppa över
                </ILButton>
              </div>
            </ILModal>
          )}
        {/* WELCOME MODAL END */}
      </Layout>
    </>
  );
};
