import { Radio, Space } from "antd";
import React from "react";
import styles from "./ILRadioButton.module.css";

export const ILRadioButton = ({
  RadioButtonList,
  reportMessage,
  handleInputChange,
  ...rest
}) => {
  return (
    <Radio.Group
      className={styles.ILRadioGroup}
      {...rest}
      name="reportMessage"
      value={reportMessage}
      onChange={handleInputChange}
    >
      <Space size={"middle"} direction="vertical">
        {RadioButtonList &&
          RadioButtonList.map((item, index) => {
            return (
              <Radio key={index} value={item.value}>
                {item.title}
              </Radio>
            );
          })}
      </Space>
    </Radio.Group>
  );
};
