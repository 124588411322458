import React from "react";
import { ILButton } from "../ILButton";
import { ILModalFlow } from "../ILModalFlow";
import { useCopyLinkModalHook } from "../../hooks/website/copyLinkModal.hook";
import clsx from "clsx";
import {
  ArrowLeft02Icon,
  ArrowRight01Icon,
  Delete02Icon,
} from "hugeicons-react";
import { ILTextInput } from "../ILTextInput";
import { selectProductBreakPoints } from "../../constants/data";
import { gradientCircleRound, threeDotsIcon } from "../../constants/imageData";
import { Col, Row } from "antd";
import { ILSwiper } from "../ILSwiper";
import { SwiperSlide } from "swiper/react";
import ILProductCardCheckbox from "../ILProductCardCheckbox";
import { ILSelect } from "../ILSelect";
import { ILLoader } from "../ILLoader";
import { ILPagination } from "../ILPagination";
import { ILModal } from "../ILModal";
import { appConfig } from "../../config";
import styles from "./ILCopyLinkModalFlow.module.css";
import { ILSkeleton } from "../ILSkeleton";
import { isEmpty } from "../../services";

export const ILCopyLinkModalFlow = ({
  data,
  isService,
  paginationData,
  isModalLoading,
  productListData,
  setIsAddedToShop,
  setPaginationData,
  handleTableChange,
}) => {
  const {
    show,
    showAll,
    currentId,
    isPrevBtn,
    isLoading,
    productData,
    productList,
    collectionData,
    collectionList,
    validateMessages,
    isProductSelected,
    selectedSocialMedia,
    isCollectionLoading,
    isCollectionAddLoading,
    deleteChannelModalOpen,
    socialMediaSharingCardListResults,
    navigate,
    showModal,
    setCurrentId,
    handleShowAll,
    setProductList,
    handleCopyLink,
    addToShopHandle,
    handleSetProduct,
    handleModalClose,
    handleInputChange,
    handleSelectChange,
    generateSharableLink,
    handleAddMoreProducts,
    handleProductAddToShop,
    setSelectedSocialMedia,
    handleSubmitCollection,
    handleSelectSocialMedia,
    handleChannelDeleteModal,
    handleRemoveSocialMediaAcc,
    handleChannelDeleteModalCancel,
  } = useCopyLinkModalHook({
    data,
    isService,
    productListData,
    setIsAddedToShop,
    setPaginationData,
  });

  const steps = [
    {
      id: "copyLink",
      title: "Kopiera länk",
      content: (
        <>
          <div className="d-flex align-items-center gap-4 mb-5 w-100">
            <div className={clsx("productImg flex-0-auto")}>
              <img
                width={"100%"}
                height={"100%"}
                src={data?.image}
                alt="advertising agency | ilonsi"
                className="object-fit-contain object-position-center w-100 h-100 aspect-ratio-1-1"
              />
            </div>
            <div>
              <h5 className="big fw-semibold mb-1 line-height-normal text-ellipsis line-clamp-2">
                {data?.name}
              </h5>
              <p className="paragraph-sm-text mb-0 text-ellipsis line-clamp-2 word-break-word">
                {data?.description === ("" || "null" || null)
                  ? ""
                  : data?.description}
              </p>
            </div>
          </div>
          <div className="d-flex flex-wrap gap-5 mb-5">
            <div>
              <span className="paragraph-sm-text fw-medium d-block mb-2">
                Pris
              </span>
              <p className="big fw-medium mb-0">{data?.price} KR</p>
            </div>
            <div>
              <span className="paragraph-sm-text fw-medium d-block mb-2">
                Betalning per klick
              </span>
              <p className="big fw-medium mb-0">{data?.ppc_price} KR</p>
            </div>
            {!isEmpty(data?.sell_commission) && (
              <div>
                <span className="paragraph-sm-text fw-medium d-block mb-2">
                  Försäljningsprovision
                </span>
                <p className="big fw-medium mb-0">
                  {data?.sell_commission + " %"}
                </p>
              </div>
            )}
          </div>
          <div>
            <p className="big fw-medium">Generated Affiliate Link</p>
            <ILTextInput
              ILInputLabelClass={"mb-0"}
              suffix={
                <>
                  <ILButton
                    handleClick={(e) => next(e)}
                    ILBtnClass={"min-w-auto fw-medium"}
                    variant={"primary-gradient"}
                    id={"copiedSuccessfully"}
                    onClick={handleCopyLink}
                  >
                    Kopiera länk
                  </ILButton>
                </>
              }
              id="copyLinkInput"
              datatestid="copyLinkInput"
              type="text"
              name=""
              value={`${appConfig.APP_URL}${data?.short_code}`}
              readOnly={true}
              handleChange={() => {
                return false;
              }}
              placeholder={"Kopiera länkingång"}
            />
          </div>
          <div className="mt-3">
            {data?.product_code &&
              (data?.is_added_to_shop === 1 ||
                data?.is_added_to_collection === 1) && (
                <ILButton
                  disabled={isLoading}
                  isSmallSizeBtn
                  handleClick={addToShopHandle}
                  ILBtnClass={"w-100 min-w-auto"}
                  variant={"primary-gradient"}
                >
                  {isLoading ? <ILLoader isBtn /> : "Skapa en kollektion"}
                </ILButton>
              )}
          </div>
        </>
      ),
      width: "558px",
    },
    {
      id: "copiedSuccessfully",
      title: "",
      prevBtn: (
        <span
          id="addToShopAs"
          role={"button"}
          className="cursor-pointer hover-text"
          onClick={(e) => prev(e)}
        >
          <ArrowLeft02Icon size={28} color={"#000000"} variant={"stroke"} />
        </span>
      ),
      content: (
        <>
          <div className="text-center d-flex flex-column gap-5">
            <span className="lh-0">
              <img
                src={gradientCircleRound}
                fluid
                alt="advertising agency | ilonsi"
              />
            </span>
            <div>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Framgångsrikt kopierad!
              </h4>
              <p className="paragraph-sm-text paragraph-text-16 text-center">
                Länken har kopierats. Dela den på dina sociala medier och följ
                dina inkomster i realtid.
              </p>
            </div>
            <div>
              {/* <ILButton
                handleClick={addToShopHandle}
                id={"addToShopAs"}
                ILBtnClass={"w-100 mb-4"}
                variant={"primary-gradient"}
              >
               Lägg till i min butik
              </ILButton> */}
              <ILButton
                handleClick={(e) => next(e)}
                id={"shareInSocialMedia"}
                ILBtnClass={"w-100"}
                variant={"gradient-bordered"}
              >
                Dela länken på dina sociala medier
              </ILButton>
            </div>
          </div>
        </>
      ),
      width: "518px",
    },
    {
      id: "shareInSocialMedia",
      prevBtn: (
        <span
          id="copiedSuccessfully"
          role={"button"}
          className="cursor-pointer hover-text lh-0"
          onClick={(e) => prev(e)}
        >
          <ArrowLeft02Icon size={28} color={"#000000"} variant={"stroke"} />
        </span>
      ),
      title: "Dela på sociala medier",
      content: (
        <>
          <p className="big fw-medium mb-4">
            Dela {data?.product_code ? "produkten" : "tjänsten"} direkt via dina
            sociala medier
          </p>
          <div className="social-media-sharing-cards-wrap">
            {socialMediaSharingCardListResults &&
              socialMediaSharingCardListResults.map((item, index) => {
                return (
                  <label
                    key={index}
                    className={clsx(
                      styles.socialMediaSharingCard,
                      selectedSocialMedia?.title === item?.title &&
                        styles.socialIconActive,
                      "cursor-pointer"
                    )}
                    onClick={() => handleSelectSocialMedia(item)}
                  >
                    <div className="social-media-icon">
                      <img
                        src={item.image}
                        alt={item.title}
                        width={"100%"}
                        height={"100%"}
                      />
                    </div>
                    <p className="sm text-gray mb-0 text-center fw-semibold">
                      {item.title}
                    </p>
                  </label>
                );
              })}
            {socialMediaSharingCardListResults?.length > 8 && (
              <div role={"button"} onClick={handleShowAll}>
                <div className="social-media-icon">
                  <img
                    src={threeDotsIcon}
                    alt={showAll ? "Less" : "More"}
                    width={"100%"}
                    height={"100%"}
                  />
                </div>
                <p className="sm mb-0 text-center fw-medium">
                  {showAll ? "Less" : "More"}
                </p>
              </div>
            )}
          </div>
          {/* <div className="mb-5">
            <p className="big fw-medium mb-4">Eller kopiera länk</p>
            <ILTextInput
              ILInputLabelClass={"mb-0"}
              suffix={
                <>
                  <ILButton
                    ILBtnClass={"min-w-auto"}
                    variant={"primary-gradient"}
                  >
                    Kopiera
                  </ILButton>
                </>
              }
              id="copyLinkInput"
              datatestid="copyLinkInput"
              type="text"
              name=""
              value="Influencer.Ilonsi.com/136sdf"
              readOnly={true}
              handleChange={() => {
                return false;
              }}
              placeholder={"Kopiera länkingång"}
            />
          </div> */}
          <div>
            <ILButton
              disabled={
                Object?.keys(selectedSocialMedia)?.length > 0 ? false : true
              }
              variant={"gradient-bordered"}
              ILBtnClass={"w-100"}
              handleClick={generateSharableLink}
            >
              Dela
            </ILButton>
          </div>
        </>
      ),
      width: "518px",
    },
    {
      id: "addToShopAs",
      title: "Lägg till i butik som",
      prevBtn: !isPrevBtn && (
        <span
          id="copiedSuccessfully"
          role={"button"}
          className="cursor-pointer hover-text"
          onClick={(e) => prev(e)}
        >
          <ArrowLeft02Icon size={28} color={"#000000"} variant={"stroke"} />
        </span>
      ),
      content: (
        <>
          {data?.is_added_to_shop === 0 && (
            <div className="d-flex align-items-center justify-content-between gap-4 mb-5 mb-xxl-2">
              <p className="big-initial fw-medium py-3 mb-0 text-ellipsis line-clamp-3">
                Lägg till som enskild produkt
              </p>
              {isLoading ? (
                <ILLoader isChatLoader />
              ) : (
                <div
                  onClick={(e) => next(e)}
                  id="productAdded"
                  role={"button"}
                  className={"round-circle-btn"}
                >
                  <ArrowRight01Icon
                    disabled={isLoading}
                    size={20}
                    color={"currentcolor"}
                    variant={"stroke"}
                  />
                </div>
              )}
            </div>
          )}
          <div className="d-flex align-items-center justify-content-between gap-4">
            <p className="big-initial fw-medium py-3 mb-0 text-ellipsis line-clamp-3">
              Skapa en kollektion
            </p>
            <div
              onClick={(e) => next(e)}
              id="createCollection"
              role={"button"}
              className={"round-circle-btn"}
            >
              <ArrowRight01Icon
                size={20}
                color={"currentcolor"}
                variant={"stroke"}
              />
            </div>
          </div>
        </>
      ),
      width: "444px",
    },
    {
      id: "productAdded",
      title: "",
      content: (
        <>
          <div className="text-center d-flex flex-column gap-5">
            <span className="lh-0">
              <img
                src={gradientCircleRound}
                fluid
                alt="advertising agency | ilonsi"
              />
            </span>
            <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
              Din {isService ? "tjänst" : "produkt"} har lagts till!
            </h4>
            <ILButton
              ILBtnClass={"w-100"}
              variant={"primary-gradient"}
              handleClick={() => navigate("/dashboard/my-shop")}
            >
              Visa i min butik
            </ILButton>
          </div>
        </>
      ),
      width: "518px",
    },
    {
      id: "createCollection",
      title: "Skapa kollektion",
      prevBtn: (
        <span
          id="addToShopAs"
          role={"button"}
          className="cursor-pointer hover-text"
          onClick={(e) => prev(e)}
        >
          {data?.is_added_to_shop === 0 && (
            <ArrowLeft02Icon size={28} color={"#000000"} variant={"stroke"} />
          )}
        </span>
      ),
      content: (
        <>
          <div className="d-flex align-items-center justify-content-between gap-4 mb-5 mb-xxl-2">
            <p className="big-initial fw-medium py-3 mb-0 text-ellipsis line-clamp-3">
              Skapa en ny kollektion
            </p>
            <div
              onClick={(e) => next(e)}
              id="addNewCollection"
              role={"button"}
              className={"round-circle-btn"}
            >
              <ArrowRight01Icon
                size={20}
                color={"currentcolor"}
                variant={"stroke"}
              />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between gap-4">
            <p className="big-initial fw-medium py-3 mb-0 text-ellipsis line-clamp-3">
              Lägg till i befintlig kollektion{" "}
            </p>
            <div
              onClick={(e) => next(e)}
              id="addExistingCollection"
              role={"button"}
              className={"round-circle-btn"}
            >
              <ArrowRight01Icon
                size={20}
                color={"currentcolor"}
                variant={"stroke"}
              />
            </div>
          </div>
        </>
      ),
      width: "444px",
    },
    {
      id: "addNewCollection",
      title: "Lägg till ny kollektion",
      prevBtn: (
        <span
          id="createCollection"
          role={"button"}
          className="cursor-pointer hover-text"
          onClick={(e) => prev(e)}
        >
          <ArrowLeft02Icon size={28} color={"#000000"} variant={"stroke"} />
        </span>
      ),
      content: (
        <>
          <div className="mb-5">
            <ILTextInput
              isRequiredInput
              ILInputLabelClass={"mb-0"}
              id="collectionName"
              datatestid="collectionName"
              type="text"
              name="name"
              value={collectionData?.name}
              label="Kollektionens namn"
              placeholder={"Ange kollektionens namn"}
              handleChange={handleInputChange}
              errorMsg={validateMessages?.name}
            />
          </div>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <p className="big fw-medium mb-0"> </p>
            <a
              id="selectProduct"
              onClick={(e) => {
                e.preventDefault();
                handleAddMoreProducts("newCollection");
              }}
              href="#"
              className="gradient-text p big fw-medium"
            >
              +Lägg till fler produkter
            </a>
          </div>
          <div className="mb-5">
            {productList?.map((productData, index) => {
              return (
                <Row
                  gutter={24}
                  className="align-items-center pb-3 pb-xxl-0 mb-4"
                  key={index}
                >
                  <Col xs={24} sm={16}>
                    <ILTextInput
                      ILInputLabelClass={"mb-0"}
                      id="link1"
                      datatestid="link1"
                      type="text"
                      label={
                        <div className="d-flex align-items-center gap-3 gap-xxl-2">
                          <div className="order-2">{`Länk ${index + 1}`}</div>
                          {index > 0 && (
                            <Delete02Icon
                              size={20}
                              color={"#db2f7f"}
                              variant={"stroke"}
                              className={clsx(
                                styles.deleteSocialAcc,
                                "transition-smooth cursor-pointer order-1"
                              )}
                              onClick={() =>
                                handleChannelDeleteModal(productData)
                              }
                            />
                          )}
                        </div>
                      }
                      name="link 1"
                      placeholder={"Länk 1"}
                      value={productData?.link}
                      disabled
                    />
                  </Col>
                  <Col
                    xs={24}
                    sm={8}
                    className={clsx(
                      styles.payPerClickSellWrap,
                      "align-self-end mt-4 mt-sm-0"
                    )}
                  >
                    <div className="d-flex gap-3">
                      <div>
                        <span className="paragraph-sm-text fw-medium mb-4 d-block">
                          CPC
                        </span>
                        <p className="fw-semibold mb-0 sm">
                          {productData?.ppc_price} KR
                        </p>
                      </div>
                      {!isEmpty(productData?.sell_commission) && (
                        <div className="ms-auto">
                          <span className="paragraph-sm-text fw-medium mb-4 d-block">
                            Provision
                          </span>

                          <p className="fw-semibold mb-0 sm">
                            {productData?.sell_commission + " %"}
                          </p>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              );
            })}
          </div>
          <ILButton
            disabled={isCollectionAddLoading}
            handleClick={(e) => handleSubmitCollection(e)}
            id={"collectionCreated"}
            ILBtnClass={"w-100"}
            variant={"primary-gradient"}
          >
            {isCollectionAddLoading ? (
              <ILLoader isBtn />
            ) : (
              "Lägg till ny kollektion"
            )}
          </ILButton>
        </>
      ),
      width: "636px",
    },
    {
      id: "selectProduct",
      title: "Välj produkt",
      prevBtn: (
        <span
          id="addNewCollection"
          role={"button"}
          className="cursor-pointer hover-text"
          onClick={(e) => prev(e)}
        >
          <ArrowLeft02Icon size={28} color={"#000000"} variant={"stroke"} />
        </span>
      ),
      subTitle:
        "Du kan välja en produkt åt gången för att lägga till i din kollektion",
      content: (
        <>
          <div className="position-relative">
            {isModalLoading ? (
              <div className="skeleton-card border w-100">
                <ILSkeleton isImgTitleLabelCard2 width="50px" />
              </div>
            ) : (
              productListData?.rows
                ?.filter((prodData) => prodData?.brand?.id == data?.brand?.id)
                ?.filter((prodData) => prodData?.id !== data?.id)
                ?.filter(
                  (productData) =>
                    !productList?.some(
                      (product) => product?.id === productData?.id
                    )
                )?.length > 0 && (
                <>
                  <ILSwiper
                    className=""
                    spaceBetween={20}
                    slidesPerView={4}
                    autoplay={false}
                    loop={false}
                    leftArrowClassName={"select-product-left-nav"}
                    rightArrowClassName={"select-product-right-nav"}
                    breakpoints={selectProductBreakPoints}
                    grabCursor={true}
                  >
                    {productListData?.rows
                      ?.filter(
                        (prodData) => prodData?.brand?.id == data?.brand?.id
                      )
                      ?.filter((prodData) => prodData?.id !== data?.id)
                      ?.filter(
                        (productData) =>
                          !productList?.some(
                            (product) => product?.id === productData?.id
                          )
                      )
                      ?.map((item, index) => {
                        return (
                          <SwiperSlide>
                            <ILProductCardCheckbox
                              noButtons={true}
                              index={index}
                              productCardClassname={"modal-product-card"}
                              name={"select_product"}
                              selectedData={productData}
                              item={item}
                              handleSetProduct={handleSetProduct}
                            />
                          </SwiperSlide>
                        );
                      })}
                  </ILSwiper>
                  <div className="mt-5 pt-2 d-md-flex align-items-center flex-wrap">
                    <div className="d-flex justify-content-center justify-content-md-start flex-wrap gap-4 mb-5 mb-md-0">
                      <ILButton
                        disabled={!isProductSelected}
                        id={"addNewCollection"}
                        handleClick={(e) => {
                          e.preventDefault();
                          handleCopyLink("selectProduct");
                        }}
                        ILBtnClass={"mt-0 fw-normal"}
                        variant={"primary-gradient"}
                      >
                        Kopiera länk
                      </ILButton>
                    </div>
                    {(productListData?.hasPrevious ||
                      productListData?.hasNext) && (
                      <ILPagination
                        defaultCurrent={paginationData?.page}
                        total={productListData?.totalItems}
                        onChange={handleTableChange}
                        defaultPageSize={paginationData?.pageSize}
                        className="flex-0-auto ms-md-auto mt-0"
                      />
                    )}
                  </div>
                </>
              )
            )}

            {!isModalLoading &&
              productListData?.rows
                ?.filter((prodData) => prodData?.id !== data?.id)
                ?.filter(
                  (productData) =>
                    !productList?.some(
                      (product) => product?.id === productData?.id
                    )
                )?.length === 0 && (
                <h6 className="fw-semibold text-center py-5 mb-0">
                  Inga Produkter Hittades
                </h6>
              )}
          </div>
        </>
      ),
      width: "1280px",
    },
    {
      id: "addExistingCollection",
      title: "Lägg till i befintlig kollektion",
      prevBtn: (
        <span
          id="createCollection"
          role={"button"}
          className="cursor-pointer hover-text"
          onClick={(e) => prev(e)}
        >
          <ArrowLeft02Icon size={28} color={"#000000"} variant={"stroke"} />
        </span>
      ),
      content: (
        <>
          {isCollectionLoading ? (
            <ILSkeleton isDataCard widthOne="50px" />
          ) : (
            <>
              <div className="mb-5">
                <ILSelect
                  isRequired
                  ILSelectLabelClass={"mb-0"}
                  label="Välj kollektion"
                  id="selectCollection"
                  datatestid="selectCollection"
                  options={collectionList}
                  placeholder={"Välj kollektion"}
                  name="id"
                  value={collectionData?.id}
                  handleChange={(event) => handleSelectChange(event, "id")}
                  errorMsg={validateMessages?.id}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between mb-5">
                <p className="big fw-medium mb-0">Produkter</p>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleAddMoreProducts("existingCollection");
                  }}
                  className="gradient-text p big fw-medium"
                >
                  +Lägg till fler produkter
                </a>
              </div>
              <div className="mb-5">
                {productList?.map((productData, index) => {
                  return (
                    <Row
                      gutter={24}
                      className="align-items-center pb-3 pb-xxl-0 mb-4"
                      key={index}
                    >
                      <Col xs={24} sm={16}>
                        <ILTextInput
                          ILInputLabelClass={"mb-0"}
                          id="link1"
                          datatestid="link1"
                          type="text"
                          name="link 1"
                          placeholder={"Länk 1"}
                          value={productData?.link}
                          disabled
                          label={
                            <div className="d-flex align-items-center gap-3 gap-xxl-2">
                              <div className="order-2">{`Länk ${
                                index + 1
                              }`}</div>
                              {index > 0 && (
                                <Delete02Icon
                                  size={20}
                                  color={"#db2f7f"}
                                  variant={"stroke"}
                                  className={clsx(
                                    styles.deleteSocialAcc,
                                    "transition-smooth cursor-pointer order-1"
                                  )}
                                  onClick={() =>
                                    handleChannelDeleteModal(productData)
                                  }
                                />
                              )}
                            </div>
                          }
                        />
                      </Col>
                      <Col
                        xs={24}
                        sm={8}
                        className={clsx(
                          styles.payPerClickSellWrap,
                          "align-self-end mt-4 mt-sm-0"
                        )}
                      >
                        <div className="d-flex gap-3">
                          <div>
                            <span className="paragraph-sm-text fw-medium mb-4 d-block">
                              CPC
                            </span>
                            <p className="fw-semibold mb-0 sm">
                              {productData?.ppc_price} KR
                            </p>
                          </div>
                          {!isEmpty(productData?.sell_commission) && (
                            <div className="ms-auto">
                              <span className="paragraph-sm-text fw-medium mb-4 d-block">
                                Provision
                              </span>
                              <p className="fw-semibold mb-0 sm">
                                {productData?.sell_commission + " %"}
                              </p>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  );
                })}
              </div>
              <ILButton
                disabled={isCollectionAddLoading}
                id={"yourProductAdded"}
                ILBtnClass={"w-100"}
                variant={"primary-gradient"}
                handleClick={(e) => handleSubmitCollection(e)}
              >
                {isCollectionAddLoading ? (
                  <ILLoader isBtn />
                ) : (
                  "Lägg till i befintlig kollektion"
                )}
              </ILButton>
            </>
          )}
        </>
      ),
      width: "636px",
    },
    {
      id: "collectionCreated",
      title: "",
      content: (
        <>
          <div className="text-center d-flex flex-column gap-5">
            <span className="lh-0">
              <img
                src={gradientCircleRound}
                fluid
                alt="advertising agency | ilonsi"
              />
            </span>
            <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
              Din kollektion har skapats och produkterna har lagts till!
            </h4>
            <ILButton
              ILBtnClass={"w-100"}
              variant={"primary-gradient"}
              handleClick={() => navigate("/dashboard/my-shop")}
            >
              Visa i min butik
            </ILButton>
          </div>
        </>
      ),
      width: "518px",
    },
    {
      id: "collectionUpdated",
      title: "",
      content: (
        <>
          <div className="text-center d-flex flex-column gap-5">
            <span className="lh-0">
              <img
                src={gradientCircleRound}
                fluid
                alt="advertising agency | ilonsi"
              />
            </span>
            <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
              Produkter som lagts till i din samling!
            </h4>
            <ILButton
              ILBtnClass={"w-100"}
              variant={"primary-gradient"}
              handleClick={() => navigate("/dashboard/my-shop")}
            >
              Visa i min butik
            </ILButton>
          </div>
        </>
      ),
      width: "518px",
    },
    {
      id: "yourProductAdded",
      title: "",
      content: (
        <>
          <div className="text-center d-flex flex-column gap-5">
            <span className="lh-0">
              <img
                src={gradientCircleRound}
                fluid
                alt="advertising agency | ilonsi"
              />
            </span>
            <div>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Din produkt har lagts till!
              </h4>
              <p className="paragraph-sm-text paragraph-text-16 text-center">
                Din produkt har lagts till!&nbsp;
                <span className="text-dark-purple fw-medium">
                  My Skin care collection.
                </span>
              </p>
            </div>
            <div>
              <ILButton
                ILBtnClass={"w-100"}
                variant={"primary-gradient"}
                handleClick={() => navigate("/dashboard/my-shop")}
              >
                Visa i min butik
              </ILButton>
            </div>
          </div>
        </>
      ),
    },
    {
      id: "alreadyAdded",
      title: "",
      content: (
        <>
          <div className="text-center d-flex flex-column gap-5">
            <span className="lh-0">
              <img
                src={gradientCircleRound}
                fluid
                alt="advertising agency | ilonsi"
              />
            </span>
            <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
              {isService ? "Tjänsten" : "Produkter"} har redan lagts till!
            </h4>
            <div>
              <ILButton
                ILBtnClass={"w-100"}
                variant={"primary-gradient"}
                handleClick={() => navigate("/dashboard/my-shop")}
              >
                Visa i min butik
              </ILButton>
            </div>
          </div>
        </>
      ),
    },
  ];

  const currentStep = steps.find((step) => step.id === currentId);
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const next = (e) => {
    if (e?.currentTarget?.id === "productAdded") {
      handleProductAddToShop(e?.currentTarget?.id);
    } else if (e === "addNewCollection") {
      setCurrentId("collectionCreated");
    } else if (e === "addExistingCollection") {
      setCurrentId("collectionUpdated");
    } else {
      const currentIndex = steps.findIndex(
        (step) => step?.id === e?.currentTarget?.id
      );
      setCurrentId(steps[currentIndex]?.id);
    }
  };

  const prev = (e) => {
    if (e.currentTarget.id === "createCollection") {
      setProductList([data]);
      setCurrentId("addToShopAs");
    } else if (currentId === "copiedSuccessfully") {
      setSelectedSocialMedia({});
      setCurrentId("copyLink");
    } else {
      const currentIndex = steps.findIndex(
        (step) => step.id === e.currentTarget.id
      );
      setCurrentId(steps[currentIndex].id);
    }
  };

  return (
    <>
      {(data?.is_added_to_shop === 1 || data?.is_added_to_collection === 1) && (
        <ILButton
          isSmallSizeBtn
          handleClick={showModal}
          ILBtnClass={"w-100 min-w-auto"}
          variant={"gradient-bordered"}
        >
          Kopiera länk
        </ILButton>
      )}
      {((isService && data?.is_added_to_shop === 0) ||
        (!isService &&
          data?.is_added_to_shop === 0 &&
          data?.is_added_to_collection === 0)) && (
        <ILButton
          // disabled={isLoading}
          isSmallSizeBtn
          handleClick={addToShopHandle}
          ILBtnClass={"w-100 min-w-auto"}
          variant={"primary-gradient"}
        >
          Lägg till i butik
        </ILButton>
      )}

      <ILModalFlow
        currentStep={currentStep}
        steps={steps}
        open={show}
        isLoading={isLoading}
        items={items}
        currentId={currentId}
        onCancel={handleModalClose}
      />

      <ILModal
        open={deleteChannelModalOpen}
        onOk={handleChannelDeleteModal}
        onCancel={handleChannelDeleteModalCancel}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="518px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <div
                className="brand-logo brand-logo-big flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center mx-auto"
                style={{ borderColor: "#ED4F32", borderWidth: "3px" }}
              >
                <Delete02Icon
                  size={34}
                  color={"#ED4F32"}
                  variant={"stroke"}
                  strokeWidth={2}
                />
              </div>

              <p className="paragraph-sm-text paragraph-md-text text-center mb-0">
                Vill du ta bort den här produkt?
              </p>
              <div className="d-flex align-items-center gap-4 modal-more-btn">
                <ILButton
                  ILBtnClass="w-50"
                  variant={"primary-gradient"}
                  onClick={handleRemoveSocialMediaAcc}
                >
                  Ja, Bekräfta
                </ILButton>
                <ILButton
                  ILBtnClass="w-50"
                  variant={"gradient-bordered"}
                  onClick={handleChannelDeleteModalCancel}
                >
                  Nej, Avbryt
                </ILButton>
              </div>
            </div>
          </>
        }
        isFooterRemove
      />
    </>
  );
};
