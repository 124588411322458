import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  FreeMode,
  Navigation,
  Thumbs,
  Pagination,
  Autoplay,
} from "swiper/modules";
import clsx from "clsx";
import { FavouriteIcon, MoreVerticalCircle01Icon } from "hugeicons-react";
import { Dropdown } from "antd";
import { capitalize } from "lodash";
import styles from "./ILDashboardGalleryCard.module.css";

export const ILDashboardGalleryCard = ({
  items,
  ILProductGalleryCardItem,
  customGallerySliderClassname,
  customGalleryClassname,
  customMainGalleryImgClassname,
  setSelectedData,
  isLiveProfile = false,
  navigate,
  handleViewCollectionProducts,
  navigation,
  autoplay,
  pagination,
  grabCursor,
}) => {
  const collectionProducts =
    ILProductGalleryCardItem?.collectionProducts?.length > 0
      ? ILProductGalleryCardItem?.collectionProducts
      : ILProductGalleryCardItem?.brandProductCollection
          ?.brandCollectionProducts;

  // thumb swiper click start
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  // thumb swiper click end

  return (
    <>
      {ILProductGalleryCardItem && (
        <div
          className={clsx(styles.ILProductGalleryCard, customGalleryClassname)}
        >
          <div className={styles.ILProductGallerySliderContainer}>
            <Swiper
              loop={true}
              spaceBetween={8.5}
              thumbs={{
                swiper: `.${ILProductGalleryCardItem.thumbsClassName}`,
                swiper: thumbsSwiper,
              }}
              modules={[FreeMode, Navigation, Thumbs, Pagination, Autoplay]}
              navigation={navigation}
              className={clsx(
                styles.mainGallerytSlider,
                customGallerySliderClassname
              )}
              autoplay={autoplay}
              pagination={pagination}
              grabCursor={grabCursor}
            >
              {collectionProducts &&
                collectionProducts?.map((imageItem, index) => {
                  return (
                    <SwiperSlide
                      key={index}
                      className="radius-xs d-flex align-items-center justify-content-center"
                    >
                      <div
                        className={clsx(
                          styles.mainGalleryImg,
                          customMainGalleryImgClassname
                        )}
                      >
                        <img
                          width={"100%"}
                          height={"100%"}
                          className={
                            "object-fit-contain object-position-center w-100 h-100 aspect-ratio-1-1"
                          }
                          src={imageItem.product?.image}
                          alt={""}
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
            {/* {!isLiveProfile && collectionProducts?.length > 1 && (
              <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={8.5}
                slidesPerView={3}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                direction="verticle"
                className={clsx(
                  styles.thumbGallerySlider,
                  "thumb-gallery-slider",
                  ILProductGalleryCardItem.thumbsClassName
                )}
              >
                {collectionProducts &&
                  collectionProducts?.map((imageItem, index) => {
                    return (
                      <SwiperSlide
                        key={index}
                        className="bg-clr-black-gray radius-extra-xs d-flex align-items-center justify-content-center"
                      >
                        <div
                          className={clsx(
                            styles.thumbImg,
                            "w-100 h-100 cursor-pointer"
                          )}
                        >
                          <img
                            className={
                              "object-fit-cover object-position-center w-100 h-100 aspect-ratio-1-1"
                            }
                            src={imageItem?.product?.image}
                            alt="advertising agency | ilonsi"
                          />
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            )} */}
          </div>
          <div className="w-100 cursor-pointer">
            {ILProductGalleryCardItem?.brandProductCollection
              ?.brandCollectionProducts?.length > 0 && (
              <div
                className={clsx(
                  styles.collectionCardHead,
                  "mt-4 mt-xxl-3 d-flex align-items-center gap-3 gap-xxl-2"
                )}
                onClick={() =>
                  isLiveProfile
                    ? handleViewCollectionProducts(ILProductGalleryCardItem)
                    : navigate(
                        `/dashboard/my-shop/view-all-collections/view-all-products-collections/${ILProductGalleryCardItem?.id}`
                      )
                }
              >
                <div
                  className={clsx(
                    styles.brandLogo,
                    "ratio ratio-1x1 border radius-extra-xs"
                  )}
                >
                  <img
                    className="object-fit-cover radius-inherit"
                    width={"100%"}
                    height={"100%"}
                    src={
                      ILProductGalleryCardItem?.brandProductCollection?.brand
                        ?.profile_image
                    }
                    alt={
                      ILProductGalleryCardItem?.brandProductCollection?.brand
                        ?.name
                    }
                  />
                </div>
                <p className="fw-semibold mb-0 text-ellipsis line-clamp-1 word-break-word">
                  {capitalize(
                    ILProductGalleryCardItem?.brandProductCollection?.brand
                      ?.name
                  )}
                </p>
              </div>
            )}

            {ILProductGalleryCardItem?.collectionProducts?.length > 0 && (
              <div
                className={clsx(
                  styles.collectionCardHead,
                  "mt-4 mt-xxl-3 d-flex align-items-center gap-3 gap-xxl-2"
                )}
                onClick={() =>
                  isLiveProfile
                    ? handleViewCollectionProducts(ILProductGalleryCardItem)
                    : navigate(
                        `/dashboard/my-shop/view-all-collections/view-all-products-collections/${ILProductGalleryCardItem?.id}`
                      )
                }
              >
                <FavouriteIcon
                  size={16}
                  color={"#db2f7f"}
                  variant={"stroke"}
                  className="translateY--015"
                  strokeWidth="3"
                />
                <p className="sm fw-semibold mb-0">Min kollektion</p>
              </div>
            )}
            <div className="d-flex mt-3 align-items-center justify-content-between">
              <div
                onClick={() =>
                  isLiveProfile
                    ? handleViewCollectionProducts(ILProductGalleryCardItem)
                    : navigate(
                        `/dashboard/my-shop/view-all-collections/view-all-products-collections/${ILProductGalleryCardItem?.id}`
                      )
                }
              >
                <p
                  className={clsx(
                    styles.anchorText,
                    "fw-medium mb-2 transition-smooth text-ellipsis line-clamp-1 word-break-word cursor-pointer"
                  )}
                >
                  {ILProductGalleryCardItem?.collectionProducts?.length > 0
                    ? capitalize(ILProductGalleryCardItem?.name)
                    : capitalize(
                        ILProductGalleryCardItem?.brandProductCollection?.name
                      )}
                </p>
                <p className="sm text-gray fw-medium mb-0">
                  {ILProductGalleryCardItem?.product_count > 0
                    ? ILProductGalleryCardItem?.product_count
                    : ILProductGalleryCardItem?.brand_collection_product_count}{" "}
                  Product
                  {(ILProductGalleryCardItem?.product_count > 1 ||
                    ILProductGalleryCardItem?.brand_collection_product_count >
                      1) &&
                    "s"}
                </p>
              </div>
              {!isLiveProfile && (
                <Dropdown
                  onClick={() =>
                    setSelectedData({
                      data: ILProductGalleryCardItem,
                      type: "collection",
                    })
                  }
                  overlayClassName={styles.galleryCardDropdown}
                  menu={{
                    items,
                  }}
                  placement="bottomRight"
                  trigger={["click"]}
                >
                  <div role={"button"}>
                    <MoreVerticalCircle01Icon
                      size={20}
                      color={"var(--clr-dark-purple)"}
                      variant={"stroke"}
                    />
                  </div>
                </Dropdown>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
