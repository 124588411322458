import { clsx } from "clsx";
import { Link } from "react-router-dom";
import { ArrowLeft02Icon } from "hugeicons-react";
import styles from "./ILBrandProductHero.module.css";
import { capitalize } from "lodash";
import { ILSkeleton } from "../ILSkeleton";
import { defaultUser } from "../../constants/imageData";

export const ILBrandProductHero = ({
  isLoading,
  bgColor,
  categoryId,
  brandData,
}) => {
  const labelNumberInfoList = [
    {
      label: brandData?.selling_type === "PRODUCT" ? "Produkter" : "Tjänster",
      number: brandData?.count,
    },
    {
      label: "Försäljning",
      number: brandData?.totalSelling,
    },
    {
      label: "Influencers",
      number: brandData?.creatorCount,
    },
  ];

  return (
    <>
      <section className="w-100">
        <div
          className={clsx(styles.brandProductLinkWrap)}
          style={{ backgroundColor: bgColor }}
        >
          <div className="container">
            <div className="d-flex align-items-center gap-3">
              <Link to={`/brands-list/${categoryId}`}>
                <ArrowLeft02Icon
                  size={34}
                  color={"#FFFFFF"}
                  variant={"stroke"}
                  strokeWidth={2}
                />
              </Link>
              <h4 className="p big text-white fw-semibold lh-22 mb-0">
                Företag
              </h4>
            </div>
          </div>
        </div>
        <div
          className={clsx(styles.brandProductInfoWrap, "position-relative z-1")}
        >
          <div
            className={clsx(
              styles.brandProductInfoBeforeLayer,
              "position-absolute top-0 start-0 w-100 z-0 hero-gradient-border"
            )}
            style={{ backgroundColor: bgColor }}
          ></div>
          <div className="container mb-md-5 position-relative z-1 ">
            <div className="d-flex flex-md-row flex-column">
              <div
                className={clsx(styles.productInfoCard, "d-flex flex-shrink-0")}
              >
                <div
                  className={clsx(
                    styles.productInfoImgBox,
                    "bg-white d-flex align-items-center justify-content-center h-100 overflow-hidden text-center"
                  )}
                >
                  <img
                    src={
                      isLoading
                        ? defaultUser
                        : brandData?.brandDetail?.profile_image
                    }
                    className="object-fit-contain object-position-center w-100 h-100 aspect-ratio-1-1"
                    alt="advertising agency | ilonsi"
                  />
                </div>
              </div>
              <div className={clsx(styles.productInfoContent, "mt-5 mt-md-0")}>
                <h2 className="md-secondary-title text-ellipsis line-clamp-1 mb-0 d-flex align-items-center">
                  {isLoading ? (
                    <ILSkeleton count={1} height={12} />
                  ) : (
                    capitalize(brandData?.brandDetail?.name)
                  )}
                </h2>
                <div className={clsx(styles.productInfoSpaceTop)}>
                  <p className="paragraph-sm-text mb-5 mb-md-4 text-ellipsis line-clamp-3 fw-medium">
                    {isLoading ? (
                      <ILSkeleton count={1} width={100} height={10} />
                    ) : brandData?.brandDetail?.bio === "" || null ? (
                      ""
                    ) : (
                      capitalize(brandData?.brandDetail?.bio)
                    )}
                  </p>
                  <div
                    className={clsx(
                      styles.productInfoNumber,
                      "d-flex flex-wrap"
                    )}
                  >
                    {labelNumberInfoList &&
                      labelNumberInfoList.map((lnItem, lnIndex) => {
                        return (
                          <div key={lnIndex}>
                            {isLoading ? (
                              <ILSkeleton count={1} height={10} />
                            ) : (
                              <span className="d-block text-shark-fin fw-semibold p xs">
                                {lnItem.label}
                              </span>
                            )}

                            {isLoading ? (
                              <ILSkeleton count={1} width={30} height={10} />
                            ) : (
                              <h4 className="fw-semibold gradient-text mb-0">
                                {lnItem.number}
                              </h4>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
