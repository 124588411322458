import { Slider } from "antd";
import clsx from "clsx";
import styles from "./ILRangeSlider.module.css";

export const ILRangeSlider = ({
  className,
  handleChange,
  name,
  value,
  min = 0,
  max = 100,
}) => {
  return (
    <Slider
      name={name}
      value={value}
      min={min}
      max={max}
      className={clsx(styles.ILRangeSlider, className)}
      onChange={handleChange}
    />
  );
};
