import React from "react";
import { Col, Form, Row } from "antd";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo/ilonsi.svg";
import clsx from "clsx";
import { ILTextInput } from "../ILTextInput";
import { ILButton } from "../ILButton";
import {
  footerContactDetailsList,
  ILPagesListData,
  socialsList,
} from "../../constants/data";
import styles from "./ILFooter.module.css";
import { useBrandFooterHook } from "../../hooks/website/footer.hook";
import { ILLoader } from "../ILLoader";

export const ILFooter = () => {
  const {
    emailData,
    isSubmitLoading,
    validateMessages,
    handleInputChange,
    handleSubmitEmail,
  } = useBrandFooterHook();

  return (
    <footer className="bg-dark-purple lh-big inner-hero-gradient position-relative z-1 overflow-hidden">
      <div className="container">
        <div className="section-sm-py">
          <Row gutter={[20, 30]}>
            <Col lg={8} md={10} xs={24}>
              <Link to={"/"} className="d-flex mb-5 pb-5 pb-xl-4">
                <img
                  className="logo"
                  src={Logo}
                  alt="advertising agency | ilonsi"
                />
              </Link>
              <div className={styles.contactDetailContainer}>
                {footerContactDetailsList &&
                  footerContactDetailsList.map((item, index) => {
                    return (
                      <a
                        key={index}
                        className={styles.footerLink}
                        href={item.href}
                        target={item.target}
                      >
                        {item.icon}
                        <p className="font-400 sm mb-0 lh-normal">
                          {item.contactDetail}
                        </p>
                      </a>
                    );
                  })}
              </div>
            </Col>
            <Col lg={4} md={7} xs={12}>
              <p className="font-400 text-light-gray mb-0">Main menu</p>
              <ul className={styles.footerPagesList}>
                {ILPagesListData.map((listItem, index) => {
                  return (
                    <li key={index}>
                      <Link
                        to={listItem.link}
                        className={clsx("p", styles.footerLink)}
                      >
                        {listItem.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </Col>
            <Col lg={4} md={7} xs={12}>
              <p className="font-400 text-light-gray mb-0">Juridisk</p>
              <ul className={styles.footerPagesList}>
                <li>
                  <Link
                    to={"/privacy-policy"}
                    className={clsx("p", styles.footerLink)}
                  >
                    Integritetspolicy
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/terms-of-use"}
                    className={clsx("p", styles.footerLink)}
                  >
                    Allmänna villkor
                  </Link>
                </li>
              </ul>
            </Col>
            <Col lg={8} md={12} xs={24}>
              <Form>
                <ILTextInput
                  type="email"
                  id={"subscriptionInput"}
                  ILInputLabelClass={clsx(styles.filledDarkInput, "w-100")}
                  label={"Prenumerera på vårt nyhetsbrev"}
                  placeholder={"E-post"}
                  // isRequiredInput
                  name="email"
                  value={emailData?.email}
                  handleChange={handleInputChange}
                  errorMsg={validateMessages?.email}
                />
                <ILButton
                  disabled={isSubmitLoading}
                  handleClick={handleSubmitEmail}
                  ILBtnClass={clsx(
                    "mt-4 p sm text-uppercase",
                    styles.footerSubscribeBtn
                  )}
                  variant={"primary-gradient"}
                  isArrow
                >
                  {isSubmitLoading ? <ILLoader isBtn /> : "Prenumerera"}
                </ILButton>
              </Form>
              <div
                className={clsx(
                  styles.socialsWrap,
                  "justify-content-xl-start justify-content-between"
                )}
              >
                {socialsList &&
                  socialsList.map((item, index) => {
                    return (
                      <a
                        key={index}
                        className={styles.footerLink}
                        href={item.href}
                        target={"_blank"}
                      >
                        {item.icon}
                      </a>
                    );
                  })}
              </div>
            </Col>
          </Row>
        </div>
        <div className={styles.copyrightTextWrap}>
          <p className="text-light-gray p text-center fw-medium mb-0">
            <b>&copy;</b> 2024 ILONSI AB (Publ).&nbsp;
            <span className="text-white">Alla rättigheter förbehållna.</span>
          </p>
        </div>
      </div>
    </footer>
  );
};
