import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import { ILFooter, ILScrollToTop } from "../../components";
import { ILHeader } from "../../components/ILHeader";
import styles from "./RootLayout.module.css";

export const RootLayout = () => {
  return (
    <Layout className={styles.ILRootLayout}>
      <ILScrollToTop />
      <ILHeader />
      <main>
        <Outlet />
      </main>
      <ILFooter />
    </Layout>
  );
};
