import { Col, Form, Row } from "antd";
import { ILButton, ILModal, ILTextInput } from "../../../../components";
import { Delete02Icon } from "hugeicons-react";
import { clsx } from "clsx";
import { channel } from "../../../../constants/imageData";
import styles from "./../ILSignUp.module.css";

export const ILAccounts = ({
  socialMediaList,
  handleInputChange,
  deleteChannelModalOpen,
  handleChannelDeleteModal,
  handleRemoveSocialMediaAcc,
  handleChannelDeleteModalCancel,
}) => {
  return (
    <>
      <h1 className="secondary-title md-secondary-title">
        Länka dina sociala mediekonton
      </h1>
      <p className="paragraph-sm-text paragraph-md-text">
        Dela med oss minst ett socialt mediekonto för att kunna fortsätta.
      </p>

      {socialMediaList?.length === 0 && (
        <div className="d-flex flex-column justify-content-center gap-5">
          <img
            src={channel}
            className={clsx(styles.socialMediaListImg, "mx-auto mb-3")}
            alt="advertising agency | ilonsi"
          />
          <h6 className="text-clr-dark-purple mb-0 text-center fw-medium">
            Vänligen lägg till dina sociala mediekonton
          </h6>
        </div>
      )}

      <Form>
        <Row gutter={20} className="align-items-center">
          {socialMediaList?.map((data, index) => {
            return (
              <Col xs={24} xl={12} key={index}>
                <div>
                  <ILTextInput
                    isRequiredInput
                    prefix={<img src={data?.image} height={20} width={20} />}
                    id="signUpInstagram"
                    datatestid="signUpInstagram"
                    type="text"
                    name={data?.label}
                    value={data?.mediaLink}
                    label={
                      <div className="d-flex align-items-center gap-3 gap-xxl-2">
                        <div className="order-2">{data?.label}</div>
                        <Delete02Icon
                          size={20}
                          color={"#db2f7f"}
                          variant={"stroke"}
                          className={clsx(
                            styles.deleteSocialAcc,
                            "transition-smooth cursor-pointer order-1"
                          )}
                          onClick={() => handleChannelDeleteModal(data)}
                        />
                      </div>
                    }
                    handleChange={handleInputChange}
                    placeholder={"Lägg till kanalnamn"}
                  />
                </div>
              </Col>
            );
          })}
          <Col xs={24} className="text-end d-none">
            <div
              className={clsx(
                styles.deleteSocialAcc,
                "d-inline-flex align-items-center gap-2 justify-content-end"
              )}
            >
              <Delete02Icon
                size={20}
                color={"#db2f7f"}
                variant={"stroke"}
                className="transition-smooth"
              />
              <span className="gradient-text p fw-semibold cursor-pointer outline-focus lh-normal">
                Delete
              </span>
            </div>
          </Col>
        </Row>
      </Form>

      <ILModal
        open={deleteChannelModalOpen}
        onOk={handleChannelDeleteModal}
        onCancel={handleChannelDeleteModalCancel}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="518px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <div
                className="brand-logo brand-logo-big flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center mx-auto"
                style={{ borderColor: "#ED4F32", borderWidth: "3px" }}
              >
                <Delete02Icon
                  size={34}
                  color={"#ED4F32"}
                  variant={"stroke"}
                  strokeWidth={2}
                />
              </div>

              <p className="paragraph-sm-text paragraph-md-text text-center mb-0">
                Vill du ta bort den här kanalen?
              </p>
              <div className="d-flex align-items-center gap-4 modal-more-btn">
                <ILButton
                  ILBtnClass="w-50"
                  variant={"primary-gradient"}
                  onClick={handleRemoveSocialMediaAcc}
                >
                  Ja, Bekräfta
                </ILButton>
                <ILButton
                  ILBtnClass="w-50"
                  variant={"gradient-bordered"}
                  onClick={handleChannelDeleteModalCancel}
                >
                  Nej, Avbryt
                </ILButton>
              </div>
            </div>
          </>
        }
        isFooterRemove
      />
    </>
  );
};
