import { Checkbox } from "antd";
import { clsx } from "clsx";
import styles from "./ILBasicCheckbox.module.css";
export const ILBasicCheckbox = ({
  className,
  checked,
  defaultChecked,
  disabled,
  onChange,
  children,
  errorMsg,
  name,
  value,
  handleChange,
}) => {
  return (
    <>
      <Checkbox
        className={clsx(
          styles.basicCheckboxStyle,
          className,
          "p sm fw-400 text-clr-gray basic-checkbox-style"
        )}
        checked={value}
        // defaultChecked={defaultChecked}
        // disabled={disabled}
        onChange={handleChange}
        name={name}
        value={value}
      >
        {children}
      </Checkbox>
      {errorMsg && (
        <span className="p sm mt-3 lh-1 text-tomato fw-medium d-block">
          {errorMsg}
        </span>
      )}
    </>
  );
};
