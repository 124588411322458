import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Col, Row } from "antd";
import clsx from "clsx";

export const ILSkeleton = ({
  count,
  width,
  height,
  isImgTile2,
  isDataCard,
  className,
  styleWidth,
  isNotImgTileC,
  isExtraLine,
  widthOne,
  widthTwo,
  widthTwo2,
  widthThree,
  heightOne,
  heightTwo,
  heightThree,
  isImgTitleLabelCard,
  isImgTitleLabelCard2,
  isForm,
  isFullFormCol,
  isNormalParagraph,
  isRoundCircle,
  isNotNeed,
  isImageTitleDesc,
  ...rest
}) => {
  return (
    <>
      {isImgTile2 ? (
        <div className="d-flex align-items-center gap-3 w-100">
          <div className="d-flex align-items-center gap-3 w-100">
            <Skeleton
              circle
              width={48}
              height={48}
              containerClassName="avatar-skeleton w-auto"
              baseColor="#f7f5fa"
              highlightColor="#efefef"
              duration={1}
              style={{ width: widthOne }}
            />
            <div>
              <Skeleton
                baseColor="#f7f5fa"
                highlightColor="#efefef"
                duration={1}
                width={width}
                height={height}
                style={{ width: widthTwo }}
              />
              {isExtraLine && (
                <Skeleton
                  width={width}
                  height={height}
                  baseColor="#f7f5fa"
                  highlightColor="#efefef"
                  duration={1}
                  style={{ width: widthTwo2 }}
                />
              )}
            </div>
          </div>
          {isNotImgTileC ? (
            ""
          ) : (
            <div className="ms-auto text-end w-100">
              <Skeleton
                baseColor="#f7f5fa"
                highlightColor="#efefef"
                duration={1}
                width={width}
                height={height}
                style={{ width: widthThree }}
              />
            </div>
          )}
        </div>
      ) : isDataCard ? (
        <div className="d-flex align-items-center flex-row-reverse gap-3 w-100">
          <div className="w-100">
            <Skeleton
              baseColor="#f7f5fa"
              highlightColor="#efefef"
              duration={1}
              width={width}
              height={height}
              style={{ width: widthOne }}
            />
            <Skeleton
              className={clsx(className, "w-size-100")}
              baseColor="#f7f5fa"
              highlightColor="#efefef"
              duration={1}
              width={width}
              height={height}
              style={{ width: widthTwo }}
            />
          </div>
          <div className="ms-auto text-end">
            <Skeleton
              circle
              width={48}
              height={48}
              containerClassName="avatar-skeleton w-auto"
              baseColor="#f7f5fa"
              highlightColor="#efefef"
              duration={1}
              style={{ width: widthThree }}
            />
          </div>
        </div>
      ) : isImgTitleLabelCard ? (
        <>
          <Skeleton
            width="100%"
            height="100px"
            containerClassName="avatar-skeleton w-auto"
            baseColor="#f7f5fa"
            highlightColor="#efefef"
            duration={1}
            style={{ width: widthThree }}
          />
          <div className="mb-3"></div>
          <Skeleton
            count={count}
            className={clsx(className, "w-size-100")}
            baseColor="#f7f5fa"
            highlightColor="#efefef"
            duration={1}
            width={100}
            height={height}
          />
          <div className="mb-3"></div>
          <Skeleton
            count={count}
            className={clsx(className, "w-size-100")}
            baseColor="#f7f5fa"
            highlightColor="#efefef"
            duration={1}
            width={250}
            height={height}
          />
        </>
      ) : isImgTitleLabelCard2 ? (
        <>
          <Skeleton
            width="100%"
            height="100px"
            containerClassName="avatar-skeleton w-auto"
            baseColor="#f7f5fa"
            highlightColor="#efefef"
            duration={1}
            style={{ width: widthThree }}
          />
          <div className="d-flex align-items-center gap-3 w-100 mt-3">
            <Skeleton
              width={48}
              height={48}
              containerClassName="avatar-skeleton w-auto"
              baseColor="#f7f5fa"
              highlightColor="#efefef"
              duration={1}
              style={{ width: widthOne }}
            />
            <Skeleton
              baseColor="#f7f5fa"
              highlightColor="#efefef"
              duration={1}
              width={width}
              height={height}
              style={{ width: widthTwo }}
            />
          </div>
          {isNotNeed ? (
            ""
          ) : (
            <>
              <div className="mb-3"></div>
              <Skeleton
                count={count}
                className={clsx(className, "w-size-100")}
                baseColor="#f7f5fa"
                highlightColor="#efefef"
                duration={1}
                width={width}
                height={height}
              />
              <div className="mb-3"></div>
              <Skeleton
                count={count}
                className={clsx(className, "w-size-100")}
                baseColor="#f7f5fa"
                highlightColor="#efefef"
                duration={1}
                width={width}
                height={height}
              />
            </>
          )}
          <div className="mb-5"></div>
          <Skeleton
            count={count}
            className={clsx(className, "w-size-100")}
            baseColor="#f7f5fa"
            highlightColor="#efefef"
            duration={1}
            width={"50px"}
            height={height}
          />
          <Skeleton
            count={count}
            className={clsx(className, "w-size-100")}
            baseColor="#f7f5fa"
            highlightColor="#efefef"
            duration={1}
            width={"120px"}
            height={height}
          />
          {isNotNeed ? (
            ""
          ) : (
            <div className="mt-4">
              <Skeleton
                width={"100%"}
                height={48}
                containerClassName="avatar-skeleton w-auto"
                baseColor="#f7f5fa"
                highlightColor="#efefef"
                duration={1}
                style={{ width: widthThree }}
                borderRadius={"30px"}
              />
            </div>
          )}
        </>
      ) : isForm ? (
        <div className="pt-3">
          {isFullFormCol ? (
            <Row gutter={24}>
              <Col xs={24}>
                <Skeleton
                  count={count}
                  className={clsx(className, "w-size-100")}
                  baseColor="#f7f5fa"
                  highlightColor="#efefef"
                  duration={1}
                  width={"100px"}
                  {...rest}
                />
                <Skeleton
                  count={count}
                  className={clsx(className, "w-size-100")}
                  baseColor="#f7f5fa"
                  highlightColor="#efefef"
                  duration={1}
                  width={width}
                  height={height}
                  {...rest}
                />
              </Col>
            </Row>
          ) : (
            <Row gutter={24}>
              <Col xs={24} md={12}>
                <Skeleton
                  count={count}
                  className={clsx(className, "w-size-100")}
                  baseColor="#f7f5fa"
                  highlightColor="#efefef"
                  duration={1}
                  width={"100px"}
                  {...rest}
                />
                <Skeleton
                  count={count}
                  className={clsx(className, "w-size-100")}
                  baseColor="#f7f5fa"
                  highlightColor="#efefef"
                  duration={1}
                  width={width}
                  height={height}
                  {...rest}
                />
              </Col>
              <Col xs={24} md={12}>
                <Skeleton
                  count={count}
                  className={clsx(className, "w-size-100")}
                  baseColor="#f7f5fa"
                  highlightColor="#efefef"
                  duration={1}
                  width={"100px"}
                  {...rest}
                />
                <Skeleton
                  count={count}
                  className={clsx(className, "w-size-100")}
                  baseColor="#f7f5fa"
                  highlightColor="#efefef"
                  duration={1}
                  width={width}
                  height={height}
                  {...rest}
                />
              </Col>
            </Row>
          )}
        </div>
      ) : isNormalParagraph ? (
        <>
          <Skeleton
            width={200}
            height={12}
            baseColor="#f7f5fa"
            highlightColor="#efefef"
            duration={1}
          />
          <Skeleton
            width={250}
            height={12}
            baseColor="#f7f5fa"
            highlightColor="#efefef"
            duration={1}
          />
          <Skeleton
            height={12}
            baseColor="#f7f5fa"
            highlightColor="#efefef"
            duration={1}
          />
        </>
      ) : isRoundCircle ? (
        <div className="text-center">
          <Skeleton
            width={250}
            height={250}
            borderRadius={"100%"}
            baseColor="#f7f5fa"
            highlightColor="#efefef"
            duration={1}
          />
        </div>
      ) : isImageTitleDesc ? (
        <>
          <Skeleton
            circle
            width={48}
            height={48}
            containerClassName="avatar-skeleton w-auto"
            baseColor="#f7f5fa"
            highlightColor="#efefef"
            duration={1}
            style={{ width: widthOne, height: heightOne }}
          />
          <div className="mt-5 d-flex gap-4 flex-wrap">
            <Skeleton
              baseColor="#f7f5fa"
              highlightColor="#efefef"
              duration={1}
              style={{ width: widthTwo, height: heightTwo }}
            />
            <Skeleton
              baseColor="#f7f5fa"
              highlightColor="#efefef"
              duration={1}
              count={2}
              style={{ width: widthThree, height: heightThree }}
            />
          </div>
        </>
      ) : (
        <>
          <Skeleton
            count={count}
            className={clsx(className, "w-size-100")}
            baseColor="#f7f5fa"
            highlightColor="#efefef"
            duration={1}
            width={width}
            height={height}
            {...rest}
          />
        </>
      )}
    </>
  );
};
