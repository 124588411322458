import { axiosApi } from "../../../axios";

export const doFetchAllProducts = async (
  pageSize = 10,
  currentPage = 1,
  search = null,
  type = null
) => {
  try {
    const response = await axiosApi({
      method: "get",
      url: `v1/influencer/product/find-all?currentPage=${currentPage}&pageSize=${pageSize}&search=${search}&type=${type}`,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchAllServices = async (
  pageSize = 10,
  currentPage = 1,
  search = null,
  type = null
) => {
  try {
    const response = await axiosApi({
      method: "get",
      url: `v1/influencer/service/find-all?currentPage=${currentPage}&pageSize=${pageSize}&search=${search}&type=${type}`,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchAllCollection = async (
  pageSize = 10,
  currentPage = 1,
  search = null,
  type = null
) => {
  try {
    const response = await axiosApi({
      method: "get",
      url: `v1/influencer/collection/find-all?currentPage=${currentPage}&pageSize=${pageSize}&search=${search}&collectionType=${type}`,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchDeleteProduct = async (id) => {
  try {
    const response = await axiosApi({
      method: "delete",
      url: `v1/influencer/product/${id}/delete`,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchDeleteService = async (id) => {
  try {
    const response = await axiosApi({
      method: "delete",
      url: `v1/influencer/service/${id}/delete`,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchDeleteCollection = async (id) => {
  try {
    const response = await axiosApi({
      method: "delete",
      url: `v1/influencer/collection/${id}/delete`,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchProductDetails = async (id) => {
  try {
    const response = await axiosApi({
      method: "get",
      url: `v1/influencer/product/${id}/details
      `,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchServiceDetails = async (id) => {
  try {
    const response = await axiosApi({
      method: "get",
      url: `v1/influencer/service/${id}/details
      `,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchCollectionProducts = async (
  id,
  page = 1,
  pageSize = 5,
  search = ""
) => {
  try {
    const response = await axiosApi({
      method: "get",
      url: `v1/influencer/collection/${id}/product/list?currentPage=${page}&pageSize=${pageSize}&search=${search}`,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchInfluencerDetailsByUserName = async (userName) => {
  try {
    const response = await axiosApi({
      method: "get",
      url: `v1/user/${userName}/details`,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchLiveProfilesAllProducts = async (
  userName,
  currentPage = 1,
  pageSize = 10,
  search = null,
  data = {}
) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: `v1/influencer/shop/${userName}/product/find-all?currentPage=${currentPage}&pageSize=${pageSize}&search=${search}`,
      data: data,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchLiveProfilesAllServices = async (
  userName,
  currentPage = 1,
  pageSize = 10,
  search = null,
  data = {}
) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: `v1/influencer/shop/${userName}/service/find-all?currentPage=${currentPage}&pageSize=${pageSize}&search=${search}`,
      data: data,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchLiveProfilesAllCollection = async (
  userName,
  page = 1,
  pageSize = 10,
  search = null
) => {
  try {
    const response = await axiosApi({
      method: "get",
      url: `v1/influencer/shop/${userName}/collection/find-all?currentPage=${page}&pageSize=${pageSize}&search=${search}`,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchLiveProfilesAllProductsOfCollection = async (
  shortCode,
  page = 1,
  pageSize = 10,
  search = null,
  data = {}
) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: `v1/influencer/shop/collection/${shortCode}/product/list?currentPage=${page}&pageSize=${pageSize}&search=${search}`,
      data: data,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchLiveProfilesVisits = async (userName) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: `v1/influencer/shop/${userName}/visit`,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchAccessRequest = async (
  status = "",
  type = "",
  isNotification = "",
  pageSize = 10,
  page = 1
) => {
  try {
    const response = await axiosApi({
      method: "get",
      url: `v1/influencer/request/list?status=${status}&type=${type}&isNotification=${isNotification}&pageSize=${pageSize}&currentPage=${page}`,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchRequestAction = async (data) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: "v1/influencer/request/status",
      data: data,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchMarkAllAsRead = async (data) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: "v1/influencer/request/read-notification",
      data: data,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchDashboardSummary = async () => {
  try {
    const response = await axiosApi({
      method: "get",
      url: "v1/influencer/dashboard/summary",
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchDashboardInsights = async (type = "", dateRange = "") => {
  try {
    const response = await axiosApi({
      method: "get",
      url: `v1/influencer/dashboard/insight?type=${type}&dateRange=${dateRange}`,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchShopClickCount = async (data) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: "v1/influencer/shop/click/count",
      data: data,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchGigaPayEarn = async () => {
  try {
    const response = await axiosApi({
      method: "get",
      url: "v1/influencer/gigapay/earn",
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchGigaPayoutHistory = async (
  currentPage = 1,
  pageSize = 5
) => {
  try {
    const response = await axiosApi({
      method: "get",
      url: `v1/influencer/gigapay/payout-history?currentPage=${currentPage}&pageSize=${pageSize}`,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchInfluencerBrandList = async (userName, type) => {
  try {
    const response = await axiosApi({
      method: "get",
      url: `v1/influencer/shop/${userName}/brand/list?type=${type}`,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchGigaPayCreate = async (data) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: "v1/influencer/gigapay/create",
      data: data,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};
