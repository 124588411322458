import { clsx } from "clsx";
import {
  ILDashboardCard,
  ILPagination,
  ILSelect,
  ILDashboardGalleryCard,
  ILDashboardHeader,
  ILHead,
  ILSocialShareMedia,
  ILModal,
  ILButton,
  ILLoader,
  ILNoDataCard,
  ILSkeleton,
} from "../../../components";
import { dsAllCollectionsSelectOption } from "../../../constants/data";
import { Col, Row } from "antd";
import styles from "./ILMyShop.module.css";
import { useCollectionViewAllListHook } from "../../../hooks/dashboard/myShop/collectionViewAllList.hook";
import { countHandler } from "../../../services";
import { Delete02Icon, Share08Icon } from "hugeicons-react";
import { appConfig } from "../../../config";
import { ILShareSocialMediaModal } from "../../../components/ILDashboardGalleryCard/ILShareSocialMediaModal";

export const ILMyShopViewAllCollections = () => {
  const {
    isLoading,
    selectedData,
    isDeleteModal,
    paginationData,
    isDeleteLoading,
    isShareSMModalOpen,
    collectionListData,
    selectedSocialMedia,
    isSuccessfullyDeleteModalOpen,
    navigate,
    handleCopyLink,
    setSelectedData,
    showShareSMModal,
    handleTableChange,
    handleSelectChange,
    generateSharableLink,
    showDeleteConfirmModal,
    shareSocialMediaHandler,
    handleSelectSocialMedia,
    handleDeleteCollectionSubmit,
    handleDeleteConfirmModalCancel,
    handleSuccessfullyDeleteModalOk,
  } = useCollectionViewAllListHook();

  const items = [
    {
      key: "1",
      icon: <Share08Icon size={20} color={"currentcolor"} variant={"stroke"} />,
      label: (
        <>
          <ILSocialShareMedia
            socialShareText
            showShareSMModal={showShareSMModal}
          />
        </>
      ),
    },
    {
      key: "2",
      icon: (
        <Delete02Icon
          size={20}
          color={"#ED4F32"}
          variant={"stroke"}
          onClick={() => showDeleteConfirmModal(selectedData?.data)}
        />
      ),
      label: (
        <>
          <div onClick={() => showDeleteConfirmModal(selectedData?.data)}>
            Radera kollektion
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <ILHead title="Visa alla kollektioner | Min butik" />
      <ILDashboardHeader
        extra={true}
        title="Min butik"
        breadCrumbItems={[
          { title: "Hem", href: "/dashboard" },
          { title: "Min butik", href: "/dashboard/my-shop" },
          { title: "Visa alla kollektioner" },
        ]}
      />
      <div className="d-flex align-items-center flex-wrap gap-4 justify-content-between mb-5">
        <h4 className="fw-semibold mb-0">
          Mina Kollektioner{" "}
          {collectionListData?.totalItems > 0
            ? `(${countHandler(collectionListData?.totalItems)})`
            : ""}
        </h4>
        {collectionListData?.count > 0 && (
          <ILSelect
            label="Välj kollektion"
            id="dsAllCollectionsSelect"
            datatestid="dsAllCollectionsSelect"
            placeholder={"Välj kollektion"}
            name={"collectionType"}
            value={paginationData?.collectionType}
            handleChange={(event) =>
              handleSelectChange(event, "collectionType")
            }
            options={dsAllCollectionsSelectOption}
            ILSelectLabelClass="w-auto min-w-auto mb-0"
          />
        )}
      </div>

      {isLoading ? (
        <div className="w-100 skeleton-card">
          <ILSkeleton isImgTitleLabelCard2 isNotNeed widthTwo={150} />
        </div>
      ) : (
        collectionListData?.rows?.length > 0 && (
          <ILDashboardCard
            className={clsx(styles.viewAllCollectionWrap, "h-auto")}
            title=""
            noHeader
            innerCardClassName={clsx(
              styles.viewAllCollectionListGrid,
              "d-flex w-100 flex-wrap"
            )}
          >
            {/* {isLoading ? (
            <ILSkeleton count={5} height={20} />
          ) : (
            collectionListData?.rows?.map((item, index) => {
              return (
                <ILDashboardGalleryCard
                  key={index}
                  items={items}
                  navigate={navigate}
                  ILProductGalleryCardItem={item}
                  setSelectedData={setSelectedData}
                  customGalleryClassname={clsx(styles.viewAllCollectionCol)}
                  // navigation
                  autoplay={false}
                  pagination={{ dynamicBullets: true }}
                  grabCursor={true}
                />
              );
            })
          )} */}

            {collectionListData?.rows?.map((item, index) => {
              return (
                <ILDashboardGalleryCard
                  key={index}
                  items={items}
                  navigate={navigate}
                  ILProductGalleryCardItem={item}
                  setSelectedData={setSelectedData}
                  customGalleryClassname={clsx(styles.viewAllCollectionCol)}
                  // navigation
                  autoplay={false}
                  pagination={{ dynamicBullets: true }}
                  grabCursor={true}
                />
              );
            })}
          </ILDashboardCard>
        )
      )}

      {!isLoading &&
        (collectionListData?.hasPrevious || collectionListData?.hasNext) && (
          <Row>
            <Col xs={24} className="text-end">
              <ILPagination
                defaultCurrent={paginationData?.page}
                total={collectionListData?.totalItems}
                onChange={handleTableChange}
                defaultPageSize={paginationData?.pageSize}
                endPagination
                className="mt-5"
              />
            </Col>
          </Row>
        )}

      {!isLoading && collectionListData?.totalItems === 0 && (
        <ILNoDataCard
          className="position-relative z-3"
          title="Inga Kollektion Hittades"
          isIcon
        />
      )}

      <ILModal
        open={isDeleteModal}
        onOk={() => showDeleteConfirmModal(selectedData?.data)}
        onCancel={handleDeleteConfirmModalCancel}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="518px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <div
                className="brand-logo brand-logo-big flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center mx-auto"
                style={{ borderColor: "#ED4F32", borderWidth: "3px" }}
              >
                <Delete02Icon
                  size={34}
                  color={"#ED4F32"}
                  variant={"stroke"}
                  strokeWidth={2}
                />
              </div>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4 lh-base">
                Vill du verkligen ta bort kollektionen?
              </h4>
              <p className="paragraph-sm-text paragraph-md-text text-center mb-0">
                Detta tar bort din kollektion från butiken
              </p>
              <div className="d-flex align-items-center gap-4 modal-more-btn">
                <ILButton
                  disabled={isDeleteLoading}
                  ILBtnClass="w-50"
                  variant={"primary-gradient"}
                  onClick={handleDeleteCollectionSubmit}
                >
                  {isDeleteLoading ? <ILLoader isBtn /> : "Ja, Bekräfta"}
                </ILButton>
                <ILButton
                  disabled={isDeleteLoading}
                  ILBtnClass="w-50"
                  variant={"gradient-bordered"}
                  onClick={handleDeleteConfirmModalCancel}
                >
                  Nej, Avbryt
                </ILButton>
              </div>
            </div>
          </>
        }
        isFooterRemove
      />

      {/* SUCCESSFULLY DELETE MODAL */}
      <ILModal
        open={isSuccessfullyDeleteModalOpen}
        onOk={handleSuccessfullyDeleteModalOk}
        onCancel={handleSuccessfullyDeleteModalOk}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="518px"
        isCloseIconRemove
        children={
          <>
            <div className="text-center d-flex flex-column justify-content-center modal-content-mh-inherit gap-5 gap-xxl-4">
              {/* <span className="lh-0">{Icons.circleRoundIcon}</span> */}
              <div
                className="brand-logo brand-logo-big flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center mx-auto"
                style={{ borderColor: "#ED4F32", borderWidth: "3px" }}
              >
                <Delete02Icon
                  size={34}
                  color={"#ED4F32"}
                  variant={"stroke"}
                  strokeWidth={2}
                />
              </div>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Kollektionen har raderats!
              </h4>
              <ILButton
                ILBtnClass="w-100"
                variant={"primary-gradient"}
                onClick={handleSuccessfullyDeleteModalOk}
              >
                Okej
              </ILButton>
            </div>
          </>
        }
        isFooterRemove
      />

      <ILModal
        open={isShareSMModalOpen}
        onOk={showShareSMModal}
        onCancel={showShareSMModal}
        title={
          <>
            <div className="d-flex align-items-center gap-3">
              Dela på sociala medier
            </div>
          </>
        }
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="518px"
        children={
          <>
            <ILShareSocialMediaModal
              liveProfileLink={`${appConfig.APP_URL}collection/${selectedData?.data?.short_code}`}
              handleCopyLink={() => handleCopyLink("collection")}
              shareHandler={shareSocialMediaHandler}
              selectedSocialMedia={selectedSocialMedia}
              generateSharableLink={generateSharableLink}
              handleSelectSocialMedia={handleSelectSocialMedia}
            />
          </>
        }
        isFooterRemove
      />
    </>
  );
};
