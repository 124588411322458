import { nameAlphaNumericOnlyRegex } from "../../regexs";

export const validateCollectionAdd = (name, value, validateMessages) => {
  let errors = { ...validateMessages };

  switch (name) {
    case "name":
      if (
        value === undefined ||
        value?.trim() === null ||
        value?.trim() === ""
      ) {
        errors.name = "Samlingens namn krävs";
      } else if (!nameAlphaNumericOnlyRegex.test(value)) {
        errors.name =
          "Samlingens namn får inte bara innehålla siffror och specialtecken";
      } else if (value.length < 3) {
        errors.name = "Samlingens namn måste bestå av minst 3 bokstäver";
      } else if (value.length > 60) {
        errors.name =
          "Samlingens namn måste vara mindre än eller lika med 60 bokstäver";
      } else {
        delete errors.name;
      }
      break;

    default:
      break;
  }

  return { errors };
};

export const validateSubmitAddCollection = (data) => {
  let errors = {};

  if (data.name === undefined || data.name === null || data.name === "") {
    errors.name = "Samlingens namn krävs";
  }
  if (data.name) {
    if (!nameAlphaNumericOnlyRegex.test(data.name)) {
      errors.name =
        "Samlingens namn får inte bara innehålla siffror och specialtecken";
    }
    if (data.name.length < 3) {
      errors.name = "Samlingens namn måste bestå av minst 3 bokstäver";
    }
    if (data.name.length > 60) {
      errors.name =
        "Samlingens namn måste vara mindre än eller lika med 60 bokstäver";
    }
  }
  return { errors };
};

export const validateExistingCollectionAdd = (
  name,
  value,
  validateMessages
) => {
  let errors = { ...validateMessages };

  switch (name) {
    case "id":
      if (
        value === undefined ||
        value?.trim() === null ||
        value?.trim() === ""
      ) {
        errors.id = "Välj samling";
      } else {
        delete errors.id;
      }
      break;

    default:
      break;
  }

  return { errors };
};

export const validateSubmitExistingAddCollection = (data) => {
  let errors = {};

  if (data.id === undefined || data.id === null || data.id === "") {
    errors.id = "Välj samling";
  }

  return { errors };
};
