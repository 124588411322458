import clsx from "clsx";
import { SquareLock02Icon } from "hugeicons-react";
import { capitalize, debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  doFetchAllProducts,
  doFetchAllServices,
  doFetchDashboardInsights,
  doFetchDashboardSummary,
} from "../../../actions";
import { Icons } from "../../../constants/icons";
import { getDecryptionString, isEmpty } from "../../../services";

export const useDashboardHook = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState({
    count: false,
    earning: false,
    visitor: false,
  });
  const [isProductLoading, setIsProductLoading] = useState(false);
  const [productList, setProductList] = useState({});
  const [serviceList, setServiceList] = useState({});
  const [dashboardSummaryData, setDashboardSummaryData] = useState({});
  const [earningChartData, setEarningChartData] = useState({});
  const [dashboardInsightsVisitor, setDashboardInsightsVisitor] = useState({});
  const [insightSelectData, setInsightSelectData] = useState({
    visitor: "",
  });
  const [apiStatus, setApiStatus] = useState(false);
  const [paginationData, setPaginationData] = useState({
    pageSize: 5,
    page: 1,
    search: "",
    categoryType: "Products",
  });
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("_token") ? true : false
  );
  const [dateRange, setDateRange] = useState("weekly");
  const { userDetails } = useSelector(
    (state) => state.storeAuthenticateReducer
  );

  useEffect(() => {
    if (isLoggedIn) {
      let queryParams = new URLSearchParams(location.search);
      userDetails &&
        queryParams.set("user-name", userDetails?.userData?.user_name);
      queryParams.set("res", apiStatus ? "true" : "false");

      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    }
  }, [isLoggedIn, userDetails, location?.pathname, apiStatus]);

  useEffect(() => {
    if (isLoggedIn) {
      localStorage?.removeItem("data");
      fetchDashboardSummary();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchDashboardInsightVisitor();
    }
  }, [insightSelectData?.visitor, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchDashboardInsightEarning();
    }
  }, [isLoggedIn, dateRange]);

  useEffect(() => {
    if (paginationData?.categoryType === "Products" && isLoggedIn) {
      fetchAllProducts(paginationData);
    } else {
      fetchAllServices(paginationData);
    }
  }, [paginationData?.categoryType, isLoggedIn]);

  const handleSelectChange = async (value, name) => {
    if (name === "visitor") {
      setInsightSelectData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (name === "earning") {
      setDateRange(value);
    } else {
      setPaginationData((prevState) => ({
        ...prevState,
        [name]: value,
        page: 1,
        search: "",
      }));
    }
  };

  const handleInputChange = (event) => {
    const { value, name } = event?.target;
    setPaginationData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (paginationData?.categoryType === "Products") {
      debounceProductSearchHandler({
        ...paginationData,
        [name]: value,
        page: 1,
      });
    } else {
      debounceServiceSearchHandler({
        ...paginationData,
        [name]: value,
        page: 1,
      });
    }
  };

  const debounceProductSearchHandler = useCallback(
    debounce(async (data) => {
      await fetchAllProducts(data);
    }, 1000),
    []
  );

  const debounceServiceSearchHandler = useCallback(
    debounce(async (data) => {
      await fetchAllServices(data);
    }, 1000),
    []
  );

  const productColumns = useMemo(() => {
    return [
      {
        title: "Produkter",
        dataIndex: "products",
        key: "products",
        render: (_, item) => (
          <div
            className="d-flex align-items-center gap-4 cursor-pointer"
            onClick={() =>
              navigate(
                `/dashboard/my-shop/product-details/${item?.product?.id}`
              )
            }
          >
            <div
              className={clsx(
                "ratio ratio-1x1 product-service-img flex-shrink-0 bg-clr-black-gray"
              )}
            >
              <img
                className={"object-fit-cover h-100 w-100"}
                src={item?.product?.image}
                alt={item?.product?.name}
              />
            </div>
            <div>
              <p className="fw-medium mb-0 text-ellipsis line-clamp-1 hover-text">
                {capitalize(item?.product?.name)}
              </p>
              <p className="mb-0 text-gray text-ellipsis line-clamp-1 word-break-word">
                {item?.product?.description !== ("" || null || "null") &&
                  capitalize(item?.product?.description)}
              </p>
              <p className="fw-medium mb-0 gradient-text">
                {item?.product?.price} KR
              </p>
            </div>
          </div>
        ),
        width: "300px",
      },
      {
        title: "Företag",
        dataIndex: "brand",
        key: "brand",
        render: (_, item) => (
          <>
            <div className="d-flex align-items-center gap-3">
              <div className="table-logo-img flex-shrink-0 ratio ratio-1x1">
                <img
                  width={"100%"}
                  height={"100%"}
                  className={"object-fit-cover radius-inherit"}
                  src={item?.product?.brand?.profile_image}
                  alt={item?.product?.brand?.name}
                />
              </div>
              <p className="fw-medium mb-0">{item?.product?.brand?.name}</p>
            </div>
          </>
        ),
        width: "250px",
      },
      {
        title: "Klicks",
        dataIndex: "clicks",
        key: "clicks",
        width: "120px",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {item?.product?.clicks}
          </p>
        ),
      },
      {
        title: "CPC",
        dataIndex: "ppc",
        key: "ppc",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {item?.product?.ppc_price}
          </p>
        ),
        width: "120px",
      },
      {
        title: "Försäljning",
        dataIndex: "sell",
        key: "sell",
        width: "120px",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {item?.product?.sells}
          </p>
        ),
      },
      {
        title: "Provision %",
        dataIndex: "sale",
        key: "sale",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {isEmpty(item?.product?.sell_commission)
              ? "-"
              : item?.product?.sell_commission + " %"}
          </p>
        ),
        width: "120px",
      },
    ];
  }, [productList]);

  const serviceColumns = useMemo(() => {
    return [
      {
        title: "Plats",
        dataIndex: "services",
        key: "services",
        render: (_, item) => (
          <div
            className="d-flex align-items-center gap-4"
            onClick={() =>
              navigate(
                `/dashboard/my-shop/service-details/${item?.service?.id}`
              )
            }
          >
            <div
              className={clsx(
                "ratio ratio-1x1 product-service-img flex-shrink-0 bg-clr-black-gray"
              )}
            >
              <Link
                to={`service-details/${item?.service?.id}`}
                className="radius-inherit"
              >
                <img
                  className={"object-fit-cover h-100 w-100"}
                  src={item?.service?.image}
                  alt={item?.service?.name}
                />
              </Link>
              {item?.service?.request_to_access && (
                <span className="lock-icon-card d-flex rounded-circle align-items-center justify-content-center">
                  <SquareLock02Icon
                    size={10}
                    color={"#000000"}
                    variant={"stroke"}
                  />
                </span>
              )}
            </div>
            <div>
              <Link
                to={`service-details/${item?.service?.id}`}
                className="text-black"
              >
                <p className="fw-medium mb-0 text-ellipsis line-clamp-2">
                  <a href="" className="text-dark-purple">
                    {capitalize(item?.service?.name)}
                  </a>
                </p>
                <p className="mb-0 text-gray text-ellipsis line-clamp-1 word-break-word">
                  {item?.service?.address !== "undefined"
                    ? item?.service?.address
                    : item?.service?.city?.name +
                      "," +
                      item?.service?.country?.name}
                </p>
                <p className="fw-medium mb-0 gradient-text">
                  {item?.service?.price} KR
                </p>
              </Link>
            </div>
          </div>
        ),
        width: "300px",
      },
      {
        title: "Omdöme",
        dataIndex: "ratings",
        key: "ratings",
        width: "120px",
        render: (_, item) => (
          <>
            {item?.service?.rating > 0 ? (
              <>
                <div className="d-flex align-items-center gap-2">
                  {Icons.star}
                  <p className="fw-medium gradient-text max-w-max-content mb-0">
                    {item?.service?.rating}
                  </p>
                </div>
              </>
            ) : (
              "-"
            )}
          </>
        ),
      },
      {
        title: "Klicks",
        dataIndex: "clicks",
        key: "clicks",
        width: "120px",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {item?.service?.clicks}
          </p>
        ),
      },
      {
        title: "CPC",
        dataIndex: "ppc",
        key: "ppc",
        width: "120px",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {item?.service?.ppc_price} KR
          </p>
        ),
      },
      {
        title: "Försäljning",
        dataIndex: "sell",
        key: "sell",
        width: "120px",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {item?.service?.sells}
          </p>
        ),
      },
      {
        title: "Provision %",
        dataIndex: "sale",
        key: "sale",
        width: "120px",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {isEmpty(item?.service?.sell_commission)
              ? "-"
              : item?.service?.sell_commission + " %"}
          </p>
        ),
      },
    ];
  }, [serviceList]);

  const fetchAllProducts = async (data) => {
    try {
      setIsProductLoading(true);

      const productListResponse = await doFetchAllProducts(
        data?.pageSize,
        data?.page,
        data?.search,
        "topClicked"
      );

      if (productListResponse?.status === 200) {
        const decryptedProductListData = await JSON.parse(
          getDecryptionString(productListResponse?.data?.data)
        );

        setProductList(decryptedProductListData);
      }
      setIsProductLoading(false);
    } catch (error) {
      setIsProductLoading(false);
      console.error("Error occurred during fetching products list:", error);
    }
  };

  const fetchAllServices = async (data) => {
    try {
      setIsProductLoading(true);

      const serviceListResponse = await doFetchAllServices(
        data?.pageSize,
        data?.page,
        data?.search,
        "topClicked"
      );

      if (serviceListResponse?.status === 200) {
        const decryptedServiceListData = await JSON.parse(
          getDecryptionString(serviceListResponse?.data?.data)
        );

        setServiceList(decryptedServiceListData);
      }
      setIsProductLoading(false);
    } catch (error) {
      setIsProductLoading(false);
      console.error("Error occurred during fetching services list:", error);
    }
  };

  const fetchDashboardSummary = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, count: true }));

      const dashboardSummaryResponse = await doFetchDashboardSummary();

      if (dashboardSummaryResponse?.status === 200) {
        setApiStatus(true);

        const decryptedDashboardSummary = await JSON.parse(
          getDecryptionString(dashboardSummaryResponse?.data?.data)
        );

        setDashboardSummaryData(decryptedDashboardSummary);
      } else {
        setApiStatus(false);
      }
      setIsLoading((prev) => ({ ...prev, count: false }));
    } catch (error) {
      setIsLoading((prev) => ({ ...prev, count: false }));
      console.error(
        "Error occurred during fetching dashboards summary:",
        error
      );
    }
  };

  const fetchDashboardInsightVisitor = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, visitor: true }));

      const dashboardInsightVisitorResponse = await doFetchDashboardInsights(
        "visitor"
      );

      if (dashboardInsightVisitorResponse?.status === 200) {
        const decryptedDashboardInsightsVisitors = await JSON.parse(
          getDecryptionString(dashboardInsightVisitorResponse?.data?.data)
        );

        setDashboardInsightsVisitor(decryptedDashboardInsightsVisitors);
      }
      setIsLoading((prev) => ({ ...prev, visitor: false }));
    } catch (error) {
      setIsLoading((prev) => ({ ...prev, visitor: false }));
      console.error(
        "Error occurred during fetching dashboards insights visitors:",
        error
      );
    }
  };

  const fetchDashboardInsightEarning = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, earning: true }));
      const dashboardInsightEarningResponse = await doFetchDashboardInsights(
        "earning",
        dateRange
      );

      if (dashboardInsightEarningResponse?.status === 200) {
        const decryptedDashboardInsightsEarning = await JSON.parse(
          getDecryptionString(dashboardInsightEarningResponse?.data?.data)
        );

        if (dateRange === "weekly") {
          setEarningChartData({
            labels: [
              "Måndag",
              "Tisdag",
              "Onsdag",
              "Torsdag",
              "Fredag",
              "Lördag",
              "Söndag",
            ],
            value:
              decryptedDashboardInsightsEarning?.earning?.earningByWeek?.map(
                (data) => data?.earning
              ),
          });
        } else if (dateRange === "monthly") {
          setEarningChartData({
            labels: [
              "Januari",
              "Februari",
              "Mars",
              "April",
              "Maj",
              "Juni",
              "Juli",
              "Augusti",
              "September",
              "Oktober",
              "November",
              "December",
            ],
            value:
              decryptedDashboardInsightsEarning?.earning?.earningByMonth?.map(
                (data) => data?.earning
              ),
          });
        } else {
          setEarningChartData({
            labels:
              decryptedDashboardInsightsEarning?.earning?.earningByYear?.map(
                (data) => data?.year
              ),
            value:
              decryptedDashboardInsightsEarning?.earning?.earningByYear?.map(
                (data) => data?.earning
              ),
          });
        }
      }
      setIsLoading((prev) => ({ ...prev, earning: false }));
    } catch (error) {
      setIsLoading((prev) => ({ ...prev, earning: false }));
      console.error(
        "Error occurred during fetching dashboards insights earnings:",
        error
      );
    }
  };

  return {
    isLoading,
    dateRange,
    productList,
    serviceList,
    productColumns,
    serviceColumns,
    paginationData,
    isProductLoading,
    earningChartData,
    insightSelectData,
    dashboardSummaryData,
    dashboardInsightsVisitor,
    handleInputChange,
    handleSelectChange,
  };
};
