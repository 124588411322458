import { Share08Icon } from "hugeicons-react";

export const ILSocialShareMedia = ({
  socialShareText,
  socialShareBtn,
  showShareSMModal,
}) => {
  return (
    <>
      {socialShareBtn ? (
        <span
          onClick={showShareSMModal}
          className="d-flex align-items-center gap-4 gap-xxl-3"
        >
          <span className="d-flex">
            <Share08Icon
              size={18}
              color={"#fff"}
              variant={"stroke"}
              strokeWidth={2}
            />
          </span>
          Dela kollektion
        </span>
      ) : socialShareText ? (
        <div onClick={showShareSMModal}>Dela kollektion</div>
      ) : (
        <span role={"button"}>
          <Share08Icon
            size={20}
            color={"#392306"}
            variant={"stroke"}
            onClick={showShareSMModal}
          />
        </span>
      )}
    </>
  );
};
