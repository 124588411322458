import React from "react";
import { Collapse } from "antd";
import { clsx } from "clsx";
import { PlusSignIcon } from "hugeicons-react";
import styles from "./ILAccordion.module.css";

export const ILAccordion = ({
  items,
  defaultActiveKey,
  className,
  expandIconPosition = "start",
  children,
  iconSize = "24",
  ...rest
}) => {
  return (
    <>
      <Collapse
        accordion
        items={items}
        defaultActiveKey={[defaultActiveKey]}
        className={clsx(styles.faqWrap, className)}
        expandIconPosition={expandIconPosition}
        expandIcon={({ isActive }) => (
          <PlusSignIcon
            size={iconSize}
            color={"#000000"}
            variant={"stroke"}
            className={isActive ? "rotate-45deg" : ""}
          />
        )}
        {...rest}
      >
        {children}
      </Collapse>
    </>
  );
};
