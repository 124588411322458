import {
  ArrowRight01Icon,
  Delete02Icon,
  Logout04Icon,
  SecurityCheckIcon,
  SquareLock01Icon,
  ViewIcon,
  ViewOffIcon,
} from "hugeicons-react";
import {
  ILAccordion,
  ILButton,
  ILModal,
  ILTextInput,
  ILDashboardHeader,
  ILHead,
  ILLoader,
} from "../../../components";
import { Icons } from "../../../constants/icons";
import { useInfluencerSettingsHook } from "../../../hooks/dashboard/profile/influencerSettings.hook";
import { Link } from "react-router-dom";

const ILSettings = () => {
  const {
    isLoading,
    isDeleteLoading,
    validateMessages,
    passwordShowIcon,
    resetPasswordData,
    isResetPwModalOpen,
    isAccountDeleteModal,
    isPrivacyPolicyModalOpen,
    isConfirmResetPwModalOpen,
    logoutHandler,
    showResetPwModal,
    handleInputChange,
    handleResetPwModal,
    privacyPolicyModal,
    handleDeleteAccount,
    handlePasswordShowIcon,
    handleConfirmResetPwModal,
    handleDeleteAccountCancel,
    handleResetPasswordSubmit,
  } = useInfluencerSettingsHook();

  const settingsAccordionData = [
    // {
    //   key: "1",
    //   label: (
    //     <>
    //       <div className="d-flex align-items-center gap-4">
    //         <div
    //           className={
    //             "gradient-icon-card rounded-circle d-flex justify-content-center align-items-center flex-0-auto"
    //           }
    //         >
    //           <Notification02Icon
    //             size={20}
    //             color={"var(--clr-white)"}
    //             variant={"stroke"}
    //           />
    //         </div>
    //         <div>
    //           <h6 className="p big fw-semibold">Notification Preferences</h6>
    //           <p className="paragraph-sm-text paragraph-xs-text fw-medium mb-0">
    //             Change Notification Preferences
    //           </p>
    //         </div>
    //       </div>
    //     </>
    //   ),
    //   children: (
    //     <>
    //       <div className="faq-inner-card d-flex justify-content-between align-items-center gap-5">
    //         <div>
    //           <h6 className="p fw-semibold text-dark-purple">
    //             Email notifications
    //           </h6>
    //           <p className="paragraph-sm-text paragraph-xs-text fw-medium mb-0">
    //             Receive e-mail notifications
    //           </p>
    //         </div>
    //         <div>
    //           <ILSwitch
    //             onChange={() => {
    //               return false;
    //             }}
    //             defaultChecked={true}
    //           />
    //         </div>
    //       </div>
    //       <div className="faq-inner-card d-flex justify-content-between align-items-center gap-5">
    //         <div>
    //           <h6 className="p fw-semibold text-dark-purple">
    //             Message Notifications
    //           </h6>
    //           <p className="paragraph-sm-text paragraph-xs-text fw-medium mb-0">
    //             Customs request and general Messnges
    //           </p>
    //         </div>
    //         <div>
    //           <ILSwitch
    //             onChange={() => {
    //               return false;
    //             }}
    //           />
    //         </div>
    //       </div>
    //     </>
    //   ),
    // },
    {
      key: "1",
      label: (
        <>
          <div className="d-flex align-items-center gap-4">
            <div
              className={
                "gradient-icon-card rounded-circle d-flex justify-content-center align-items-center flex-0-auto"
              }
            >
              <SecurityCheckIcon
                size={20}
                color={"var(--clr-white)"}
                variant={"stroke"}
              />
            </div>
            <div>
              <h6 className="p big fw-semibold">Integritet &#38; Säkerhet</h6>
              <p className="paragraph-sm-text paragraph-xs-text fw-medium mb-0">
                Integritetsrelaterad information & &#38; säkerhet
              </p>
            </div>
          </div>
        </>
      ),
      children: (
        <>
          <div
            className="faq-inner-card d-flex justify-content-between align-items-center gap-4 cursor-pointer"
            onClick={privacyPolicyModal}
          >
            <div>
              <h6 className="p fw-semibold text-dark-purple transition-smooth">
                Integritetspolicy
              </h6>
              <p className="paragraph-sm-text paragraph-xs-text fw-medium mb-0">
                Vi värnar om din integritet. Läs vår integritetspolicy.
              </p>
            </div>
            <span>
              <ArrowRight01Icon
                size={20}
                color={"var(--clr-dark-purple)"}
                variant={"stroke"}
              />
            </span>
          </div>
          <div
            className="faq-inner-card d-flex justify-content-between align-items-center gap-4 "
            onClick={showResetPwModal}
          >
            <div className="cursor-pointer">
              <h6 className="p fw-semibold text-dark-purple transition-smooth">
                Byt lösenord
              </h6>
              <p className="paragraph-sm-text paragraph-xs-text fw-medium mb-0">
                Byt ditt lösenord.
              </p>
            </div>
            <span className="cursor-pointer">
              <ArrowRight01Icon
                size={20}
                color={"var(--clr-dark-purple)"}
                variant={"stroke"}
              />
            </span>
          </div>
          <div className="faq-inner-card d-flex justify-content-between align-items-center gap-5 ">
            <div onClick={logoutHandler}>
              <h6 className="p fw-semibold text-tomato cursor-pointer transition-smooth">
                Logga ut
              </h6>
              <p className="paragraph-sm-text paragraph-xs-text fw-medium mb-0 cursor-pointer">
                Logga ut från detta konto på ILONSI.
              </p>
            </div>
            <span onClick={logoutHandler} className="cursor-pointer">
              <Logout04Icon
                size={20}
                color={"var(--clr-tomato)"}
                variant={"stroke"}
              />
            </span>
          </div>
          <div
            className="faq-inner-card d-flex justify-content-between align-items-center gap-5"
            onClick={handleDeleteAccountCancel}
          >
            <div>
              <h6 className="p fw-semibold text-tomato cursor-pointer transition-smooth">
                Ta bort konto
              </h6>
            </div>
            <span className="cursor-pointer">
              <Delete02Icon
                size={20}
                color={"var(--clr-tomato)"}
                variant={"stroke"}
              />
            </span>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <ILHead title="Inställningar" />
      <ILDashboardHeader
        extra={true}
        title="Inställningar"
        breadCrumbItems={[
          { title: "Hem", href: "/dashboard" },
          { title: "Inställningar" },
        ]}
      />
      <section>
        <div>
          <ILAccordion
            items={settingsAccordionData}
            defaultActiveKey="1"
            className="support-and-help-faq"
            expandIconPosition="end"
          />
        </div>
      </section>
      <ILModal
        open={isPrivacyPolicyModalOpen}
        onOk={privacyPolicyModal}
        onCancel={privacyPolicyModal}
        title={"Integritetspolicy för ILONSI"}
        wrapClassName=""
        centered={true}
        closable=""
        width="1028px"
        children={
          <>
            <div>
              <div className="mb-4">
                <h6 className="p fw-semibold">Introduktion:</h6>
                <p className="paragraph-sm-text">
                  På ILONSI respekterar vi din integritet och är engagerade i
                  att skydda dina personuppgifter. Den här integritetspolicyn
                  förklarar hur vi samlar in, använder och skyddar information
                  som du delar med oss när du använder våra tjänster.
                </p>
              </div>
              <div className="mb-4">
                <h6 className="p fw-semibold">Information vi samlar in:</h6>
                <p className="paragraph-sm-text">
                  Vi samlar in information som du tillhandahåller när du
                  registrerar dig på vår plattform, inklusive namn,
                  e-postadress, telefonnummer, och sociala medier-konton.
                </p>
              </div>
              <div className="mb-4">
                <h6 className="p fw-semibold">
                  Hur vi använder din information:
                </h6>
                <p className="paragraph-sm-text">
                  Din information används för att tillhandahålla våra tjänster,
                  förbättra din användarupplevelse och kommunicera med dig om
                  våra produkter och tjänster.
                </p>
              </div>
              <div className="mb-4">
                <h6 className="p fw-semibold">Delning av din information:</h6>
                <p className="paragraph-sm-text">
                  Vi delar inte dina personuppgifter med tredje part utan ditt
                  samtycke, förutom om det krävs enligt lag.
                </p>
              </div>
              <div className="mb-4">
                <h6 className="p fw-semibold">Säkerhet:</h6>
                <p className="paragraph-sm-text">
                  Vi använder säkerhetsteknik för att skydda dina
                  personuppgifter från obehörig åtkomst eller förlust.
                </p>
              </div>
              <div className="mb-4">
                <h6 className="p fw-semibold">Dina rättigheter:</h6>
                <p className="paragraph-sm-text">
                  Du har rätt att när som helst begära tillgång till eller
                  radering av dina personuppgifter. Om du vill utöva dessa
                  rättigheter kan du kontakta oss på
                  <Link to="mailto:info@ilonsi.com" className="ms-2">
                    info@ilonsi.com
                  </Link>
                  .
                </p>
              </div>
            </div>
          </>
        }
        isFooterRemove
      />

      <ILModal
        open={isResetPwModalOpen}
        onOk={handleResetPwModal}
        onCancel={handleResetPwModal}
        title={
          <>
            <h5 className="big fw-semibold mb-2">Ändra ditt lösenord</h5>
            <p className="sm fw-normal text-gray">
              ILONSI gör det enkelt att hålla ditt konto säkert. Ändra ditt
              lösenord snabbt och smidigt.
            </p>
          </>
        }
        wrapClassName=""
        centered={true}
        closable=""
        width="518px"
        children={
          <>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <ILTextInput
                prefix={
                  <SquareLock01Icon
                    size={24}
                    color={"#1d1128"}
                    variant={"stroke"}
                    strokeWidth={1.5}
                  />
                }
                suffix={
                  passwordShowIcon?.newPasswordShowIcon ? (
                    <span
                      className="cursor-pointer"
                      onClick={() => handlePasswordShowIcon("newPassword")}
                    >
                      <ViewOffIcon
                        size={24}
                        color={"#1d1128"}
                        variant={"stroke"}
                        strokeWidth={1.5}
                      />
                    </span>
                  ) : (
                    <span
                      className="cursor-pointer"
                      onClick={() => handlePasswordShowIcon("newPassword")}
                    >
                      <ViewIcon
                        size={24}
                        color={"#1d1128"}
                        variant={"stroke"}
                        strokeWidth={1.5}
                      />
                    </span>
                  )
                }
                id="newPassword"
                datatestid="newPassword"
                label="Nytt lösenord"
                placeholder={"Ange ditt nya lösenord"}
                type={
                  passwordShowIcon?.newPasswordShowIcon ? "text" : "password"
                }
                name="password"
                value={resetPasswordData?.password}
                handleChange={handleInputChange}
                errorMsg={validateMessages?.password}
              />
              <ILTextInput
                prefix={
                  <SquareLock01Icon
                    size={24}
                    color={"#1d1128"}
                    variant={"stroke"}
                    strokeWidth={1.5}
                  />
                }
                suffix={
                  passwordShowIcon?.confirmPasswordShowIcon ? (
                    <span
                      className="cursor-pointer"
                      onClick={handlePasswordShowIcon}
                    >
                      <ViewOffIcon
                        size={24}
                        color={"#1d1128"}
                        variant={"stroke"}
                        strokeWidth={1.5}
                      />
                    </span>
                  ) : (
                    <span
                      className="cursor-pointer"
                      onClick={handlePasswordShowIcon}
                    >
                      <ViewIcon
                        size={24}
                        color={"#1d1128"}
                        variant={"stroke"}
                        strokeWidth={1.5}
                      />
                    </span>
                  )
                }
                type={
                  passwordShowIcon?.confirmPasswordShowIcon
                    ? "text"
                    : "password"
                }
                id="confirmNewPassword"
                datatestid="confirmNewPassword"
                label="Bekräfta nytt lösenord"
                placeholder={"Bekräfta lösenord"}
                name="confirmPassword"
                value={resetPasswordData?.confirmPassword}
                handleChange={handleInputChange}
                errorMsg={validateMessages?.confirmPassword}
              />
              <ILButton
                ILBtnClass={"w-100"}
                variant={"primary-gradient"}
                handleClick={handleResetPasswordSubmit}
              >
                Ändra lösenord
              </ILButton>
            </form>
          </>
        }
        isFooterRemove
      />

      <ILModal
        open={isConfirmResetPwModalOpen}
        onOk={handleConfirmResetPwModal}
        onCancel={handleConfirmResetPwModal}
        title={""}
        wrapClassName=""
        centered={true}
        closable=""
        maskClosable={false}
        isCloseIconRemove
        width="518px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5 gap-xxl-4">
              <span className="lh-0">{Icons.circleRoundIcon}</span>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Lösenordet har ändrats!
              </h4>
              <p className="paragraph-sm-text paragraph-md-text text-center mb-0">
                Logga in för att komma igång.
              </p>
              <ILButton
                ILBtnClass="w-100"
                variant={"primary-gradient"}
                handleClick={logoutHandler}
              >
                Login now
              </ILButton>
            </div>
          </>
        }
        isFooterRemove
      />

      <ILModal
        open={isAccountDeleteModal}
        onOk={handleDeleteAccountCancel}
        onCancel={handleDeleteAccountCancel}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="518px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <div
                className="brand-logo brand-logo-big flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center mx-auto"
                style={{ borderColor: "#ED4F32", borderWidth: "3px" }}
              >
                <Delete02Icon
                  size={34}
                  color={"#ED4F32"}
                  variant={"stroke"}
                  strokeWidth={2}
                />
              </div>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4 lh-base">
                Vill du verkligen radera ditt konto?
              </h4>
              <p className="paragraph-sm-text paragraph-md-text text-center mb-0">
                Detta kommer att radera ditt konto från ILONSI
              </p>
              <div className="d-flex align-items-center gap-4 modal-more-btn">
                <ILButton
                  disabled={isDeleteLoading}
                  ILBtnClass="w-50"
                  variant={"primary-gradient"}
                  onClick={handleDeleteAccount}
                >
                  {isDeleteLoading ? <ILLoader isBtn /> : "Ja, Bekräfta"}
                </ILButton>
                <ILButton
                  disabled={isDeleteLoading}
                  ILBtnClass="w-50"
                  variant={"gradient-bordered"}
                  onClick={handleDeleteAccountCancel}
                >
                  Nej, Avbryt
                </ILButton>
              </div>
            </div>
          </>
        }
        isFooterRemove
      />
    </>
  );
};

export default ILSettings;
