import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  doFetchLiveProfilesAllProductsOfCollection,
  doFetchSingleCollection,
} from "../../../actions";
import { getDecryptionString } from "../../../services";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { useInView } from "react-intersection-observer";

export const useLiveProfileCollectionProductsHook = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [productList, setProductList] = useState({});
  const [collectionDetails, setCollectionDetails] = useState({});
  const [paginationData, setPaginationData] = useState({
    page: 1,
    pageSize: 40,
    search: "",
  });
  const [filterModal, setFilterModal] = useState(false);
  const [productFilterData, setProductFilterData] = useState({
    ppcRange: [],
    priceRange: [],
    rating: "",
  });
  const { userDetails } = useSelector(
    (state) => state.storeAuthenticateReducer
  );
  const { ref, inView, entry } = useInView({ threshold: 1 });

  useEffect(() => {
    if (inView) {
      observerScroll();
    }
  }, [inView]);

  useEffect(() => {
    fetchCollectionDetails();
  }, []);

  useEffect(() => {
    fetchInfluencerAllProductsOfCollection({
      ...paginationData,
      ...productFilterData,
    });
  }, [paginationData?.page]);

  const observerScroll = (entries) => {
    setPaginationData((prevState) => ({
      ...prevState,
      page: prevState?.page + 1,
    }));
  };

  const handleInputChange = (event) => {
    const { value, name } = event?.target;

    setPaginationData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "search") {
      debounceProductSearchHandler({
        ...paginationData,
        ...productFilterData,
        [name]: value,
        page: 1,
      });
    }
  };

  const debounceProductSearchHandler = useCallback(
    debounce(async (data) => {
      await fetchInfluencerAllProductsOfCollection(data);
    }, 1000),
    []
  );

  const handleClearAllFilters = () => {
    setProductFilterData({ ppcRange: [], priceRange: [] });
    setFilterModal(false);
    fetchInfluencerAllProductsOfCollection({
      ...paginationData,
      ppcRange: [],
      priceRange: [],
    });
  };

  const showFilterDataWrap = () => {
    setFilterModal(!filterModal);
  };

  const hideFilterDataWrap = () => {
    setFilterModal(false);
  };

  const handleApplyFilterModalCancel = () => {
    setFilterModal(false);
    fetchInfluencerAllProductsOfCollection({
      ...paginationData,
      ...productFilterData,
    });
  };

  const handleBackToLiveProfile = () => {
    navigate(`/shop/${collectionDetails?.user?.user_name}`, { replace: true });
  };

  const handleProductDetail = (data) => {
    window.open(`/${data?.short_code}`, "_blank");
  };

  const handleFilterInputChange = (event) => {
    const { name, value } = event?.target;
    if (name === "priceRange") {
      setProductFilterData((prevState) => ({
        ...prevState,
        priceRange: prevState?.priceRange?.includes(value)
          ? prevState?.priceRange?.filter((data) => data !== value)
          : [...prevState?.priceRange, value],
      }));
    } else if (name === "ppcRange") {
      setProductFilterData((prevState) => ({
        ...prevState,
        ppcRange: prevState?.ppcRange?.includes(value)
          ? prevState?.ppcRange?.filter((data) => data !== value)
          : [...prevState?.ppcRange, value],
      }));
    }
  };

  const fetchCollectionDetails = async () => {
    try {
      setIsLoading(true);

      const collectionDetailsResponse = await doFetchSingleCollection(
        params?.shortCode
      );

      if (collectionDetailsResponse?.status === 200) {
        const decryptedCollectionDetails = await JSON.parse(
          getDecryptionString(collectionDetailsResponse?.data)
        );
        setCollectionDetails(decryptedCollectionDetails);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(
        "Error occurred during fetching all collection details:",
        error
      );
    }
  };

  const fetchInfluencerAllProductsOfCollection = async (data) => {
    try {
      if (data?.page == 1) {
        setIsLoading(true);
      }

      const requestBody = {
        ppcRange: data?.ppcRange ? data?.ppcRange : [],
        priceRange: data?.priceRange ? data?.priceRange : [],
      };

      const influencerAllProductsResponse =
        await doFetchLiveProfilesAllProductsOfCollection(
          params?.shortCode,
          data?.page,
          data?.pageSize,
          data?.search,
          requestBody
        );

      if (influencerAllProductsResponse?.status === 200) {
        const decryptedInfluencerProductsDetails = await JSON.parse(
          getDecryptionString(influencerAllProductsResponse?.data?.data)
        );

        setProductList(decryptedInfluencerProductsDetails);
      } else {
        toast.error(influencerAllProductsResponse?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    userDetails,
    filterModal,
    productList,
    paginationData,
    productFilterData,
    collectionDetails,
    ref,
    observerScroll,
    handleInputChange,
    showFilterDataWrap,
    hideFilterDataWrap,
    handleProductDetail,
    handleClearAllFilters,
    handleBackToLiveProfile,
    handleFilterInputChange,
    handleApplyFilterModalCancel,
  };
};
