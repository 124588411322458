import { ILButton } from "../ILButton";
import clsx from "clsx";
import { Location01Icon } from "hugeicons-react";
import { Icons } from "../../constants/icons";
import { Tooltip } from "antd";
import { ILSendAccessReqModal } from "../ILSendAccessReqModal";
import { Link } from "react-router-dom";
import { ILCopyLinkModalFlow } from "../ILCopyLinkModalFlow";
import { capitalize } from "lodash";
import styles from "./ILProductCard.module.css";
import { ILLoader } from "../ILLoader";
import { isEmpty } from "../../services";

export const ILProductCard = ({
  productDataShow,
  noProductLogo,
  noButtons,
  productCardClassname,
  isLiveProfile = false,
  isPriceAmountText = true,
  isService = false,
  isLoggedIn = false,
  isProductLabel = false,
  loginHandler,
  handleProductDetail = () => {},
  sendAccessRequest,
  productList,
  isBrandProduct = false,
  addToShopHandle,
  showModal,
  paginationData,
  handleTableChange,
  brandRequest = false,
  productImgClass,
  isModalLoading,
  isRequestSentLoading,
  setIsAddedToShop,
  setPaginationData,
  selectedData = {},
  addToShopLoader = false,
}) => {
  const today = new Date();

  return (
    <>
      <div
        className={clsx(
          styles.productCard,
          productCardClassname,
          "w-100 position-relative bg-white",
          { ["bg-white"]: productDataShow?.isBg }
        )}
      >
        <div
          {...(!isLiveProfile && {
            onClick: () => handleProductDetail(productDataShow),
          })}
        >
          <div
            className={clsx(
              styles.productImg,
              {
                [""]: !productDataShow?.isMyProductImg,
              },
              {
                ["cursor-pointer"]: !isLiveProfile,
              },
              productImgClass
            )}
          >
            <img
              src={
                brandRequest
                  ? productDataShow?.product_request?.product_details === null
                    ? productDataShow?.product_request?.service_details?.image
                    : productDataShow?.product_request?.product_details?.image
                  : productDataShow?.image ||
                    productDataShow?.product?.image ||
                    productDataShow?.service?.image
              }
              alt="advertising agency | ilonsi"
              className={clsx(
                "object-fit-contain object-position-center w-100 h-100 aspect-ratio-3-2",
                productDataShow?.isMyProductImg && "h-auto max-w-100 w-100"
              )}
            />
          </div>
          <div className="w-100">
            {noProductLogo ? (
              <></>
            ) : (
              <>
                <div
                  className={clsx(
                    styles.productLogoWrap,
                    `d-flex align-items-center gap-4 gap-xxl-3 ${
                      !isLiveProfile && "cursor-pointer"
                    }`
                  )}
                >
                  <div
                    className={clsx(
                      styles.productLogoImgBox,
                      "flex-0-auto ratio ratio-1x1"
                    )}
                  >
                    <img
                      src={
                        brandRequest
                          ? productDataShow?.brand_details?.profile_image
                          : productDataShow?.brand?.profile_image ||
                            productDataShow?.logo ||
                            productDataShow?.product?.brand?.profile_image ||
                            productDataShow?.service?.brand?.profile_image
                      }
                      // height={50}
                      // width={50}
                      alt="advertising agency | ilonsi"
                      className="object-fit-contain w-100 h-100 max-w-100"
                    />
                  </div>
                  <h6 className="p big fw-semibold text-dark-purple mb-0 text-ellipsis line-clamp-2 word-break-word">
                    {capitalize(
                      brandRequest
                        ? productDataShow?.brand_details?.name
                        : productDataShow?.brand?.name ||
                            productDataShow?.product?.brand?.name ||
                            productDataShow?.service?.brand?.name
                    )}
                  </h6>
                </div>
              </>
            )}
            {/* product name */}
            {isLiveProfile ? (
              <h6 className="p big fw-medium text-dark-purple mb-3 text-ellipsis line-clamp-2 word-break-word">
                {capitalize(
                  brandRequest
                    ? productDataShow?.product_request?.product_details === null
                      ? productDataShow?.product_request?.service_details?.name
                      : productDataShow?.product_request?.product_details?.name
                    : productDataShow?.name ||
                        productDataShow?.product?.name ||
                        productDataShow?.service?.name
                )}
              </h6>
            ) : (
              (productDataShow?.product_request ||
                productDataShow?.name ||
                productDataShow?.product?.name ||
                productDataShow?.service?.name) && (
                <Link
                  {...(!isLiveProfile && {
                    onClick: () => handleProductDetail(productDataShow),
                  })}
                  className="p big fw-semibold text-dark-purple text-ellipsis line-clamp-1 mb-4 mb-3 mb-xxl-1 word-break-word"
                >
                  {capitalize(
                    brandRequest
                      ? productDataShow?.product_request?.product_details ===
                        null
                        ? productDataShow?.product_request?.service_details
                            ?.name
                        : productDataShow?.product_request?.product_details
                            ?.name
                      : productDataShow?.name ||
                          productDataShow?.product?.name ||
                          productDataShow?.service?.name
                  )}{" "}
                </Link>
              )
            )}

            {isService ? (
              <>
                {(productDataShow?.rating > 0 ||
                  productDataShow?.service?.rating ||
                  productDataShow?.product_request?.service_details?.rating) >
                  0 && (
                  <div className="d-flex align-items-center mb-4 mb-xxl-3">
                    <span className={clsx(styles.ratingStarIcon, "lh-0")}>
                      {Icons.star}
                    </span>
                    <span className="d-block p sm lh-normal">
                      {productDataShow?.rating ||
                        productDataShow?.service?.rating ||
                        productDataShow?.product_request?.service_details
                          ?.rating}
                    </span>
                  </div>
                )}
                <p className="sm d-flex align-items-center text-gray fw-medium pt-2 pt-xxl-0">
                  <span className="me-3 me-xxl-2 lh-0">
                    <Location01Icon
                      size={14}
                      color={"#696C79"}
                      variant={"stroke"}
                    />
                  </span>
                  <span className="text-ellipsis line-clamp-1 lh-normal">
                    {productDataShow?.address !== "undefined"
                      ? productDataShow?.address
                      : productDataShow?.city?.name +
                        "," +
                        productDataShow?.country?.name}
                    {/* {productDataShow?.address ||
                      productDataShow?.service?.address ||
                      productDataShow?.product_request?.service_details
                        ?.address} */}
                  </span>
                </p>
              </>
            ) : (
              <>
                {productDataShow?.product_request?.product_details
                  ?.description === ("" || null || "null") ||
                productDataShow?.description === ("" || null || "null") ||
                productDataShow?.product?.description ===
                  ("" || null || "null") ? (
                  ""
                ) : (
                  <p className="paragraph-sm-text text-ellipsis line-clamp-1 word-break-word">
                    {capitalize(
                      productDataShow?.description ||
                        productDataShow?.product?.description ||
                        productDataShow?.product_request?.product_details
                          ?.description
                    )}
                  </p>
                )}
              </>
            )}
          </div>
        </div>
        <div className="mt-auto">
          <div
            {...(!isLiveProfile && {
              onClick: () => handleProductDetail(productDataShow),
            })}
          >
            {isPriceAmountText && (
              <h6 className="p big fw-normal text-gray mb-3">
                {isService ? "Tjänstens pris" : "Pris"}
              </h6>
            )}
            <div className="d-flex align-items-center flex-wrap gap-4 gap-xxl-3">
              <h6 className="p fw-semibold text-dark-purple mb-0 word-break-word">
                {new Date(
                  productDataShow?.product?.discount_available_at?.end_at
                ) >= today &&
                isLiveProfile &&
                productDataShow?.product?.discount !== null
                  ? (productDataShow?.product?.price ||
                      productDataShow?.service?.price) -
                    ((productDataShow?.product?.price ||
                      productDataShow?.service?.price) *
                      (productDataShow?.product?.discount ||
                        productDataShow?.service?.discount)) /
                      100
                  : productDataShow?.price ||
                    productDataShow?.product?.price ||
                    productDataShow?.service?.price ||
                    productDataShow?.product_request?.service_details?.price ||
                    productDataShow?.product_request?.product_details
                      ?.price}{" "}
                KR
              </h6>
              {/* discount label start  */}
              {new Date(
                productDataShow?.product?.discount_available_at?.end_at
              ) >= today &&
                isLiveProfile &&
                productDataShow?.product?.discount !== null &&
                productDataShow?.service?.discount !== null && (
                  <>
                    <h6 className="p fw-normal text-gray mb-0 line-through word-break-word">
                      {productDataShow?.product?.price ||
                        productDataShow?.service?.price}{" "}
                      KR
                    </h6>
                    <div className="discount-label-wrap d-flex align-items-center justify-content-center pr-1 py-1 position-absolute end-0">
                      <h5 className="text-white fw-medium mb-0 p sm word-break-word letter-spacing">
                        {productDataShow?.product?.discount ||
                          productDataShow?.service?.discount}
                        % off
                      </h5>
                    </div>
                  </>
                )}
              {/* discount label end */}
            </div>
          </div>
          {!noButtons && (
            <div
              className={clsx(
                styles.productBtnWrap,
                "d-flex align-items-center gap-3 flex-md-nowrap flex-wrap product-btn-wrap"
              )}
            >
              {isLiveProfile && (
                <>
                  {isService ? (
                    <ILButton
                      variant={"gradient-bordered"}
                      isSmallSizeBtn
                      handleClick={() => handleProductDetail(productDataShow)}
                      ILBtnClass="min-w-auto line-height-normal font-semibold w-100 text-clr-pink"
                    >
                      Boka nu
                    </ILButton>
                  ) : (
                    <ILButton
                      variant={"gradient-bordered"}
                      isSmallSizeBtn
                      handleClick={() => handleProductDetail(productDataShow)}
                      ILBtnClass="min-w-auto line-height-normal font-semibold w-100 text-clr-pink"
                    >
                      Köp nu
                    </ILButton>
                  )}
                </>
              )}
              {isLoggedIn ? (
                productDataShow?.has_access === "ACCESS_NEEDED" ||
                productDataShow?.has_access === "ACCESS_DENIED" ? (
                  <ILSendAccessReqModal
                    sendAccessRequest={sendAccessRequest}
                    data={productDataShow}
                    isRequestSentLoading={isRequestSentLoading}
                  />
                ) : productDataShow?.has_access === "NO_REQUEST_NEEDED" ||
                  productDataShow?.has_access === "ACCESS_GRANTED" ? (
                  isBrandProduct ? (
                    <>
                      {" "}
                      {(productDataShow?.is_added_to_shop === 1 ||
                        productDataShow?.is_added_to_collection === 1) && (
                        <ILButton
                          isSmallSizeBtn
                          handleClick={() =>
                            showModal(productDataShow, isService)
                          }
                          ILBtnClass={"w-100 min-w-auto mb-1"}
                          variant={"gradient-bordered"}
                        >
                          Kopiera länk
                        </ILButton>
                      )}
                      {((!isService &&
                        productDataShow?.is_added_to_shop === 0 &&
                        productDataShow?.is_added_to_collection === 0) ||
                        (isService &&
                          productDataShow?.is_added_to_shop === 0)) && (
                        <ILButton
                          isSmallSizeBtn
                          disabled={
                            addToShopLoader &&
                            selectedData?.id === productDataShow?.id
                          }
                          handleClick={() =>
                            addToShopHandle(productDataShow, isService)
                          }
                          ILBtnClass={"w-100 min-w-auto"}
                          variant={"primary-gradient"}
                        >
                          {addToShopLoader &&
                          selectedData?.id === productDataShow?.id ? (
                            <ILLoader isBtn />
                          ) : (
                            "Lägg till i butik"
                          )}
                        </ILButton>
                      )}
                    </>
                  ) : (
                    <ILCopyLinkModalFlow
                      data={productDataShow}
                      productListData={productList}
                      isService={isService}
                      isModalLoading={isModalLoading}
                      paginationData={paginationData}
                      setIsAddedToShop={setIsAddedToShop}
                      setPaginationData={setPaginationData}
                      handleTableChange={handleTableChange}
                    />
                  )
                ) : productDataShow?.has_access === "PENDING_REQUEST" ? (
                  <ILButton
                    disabled={true}
                    isSmallSizeBtn
                    isArrow
                    ILBtnClass={"w-100 text-clr-pink"}
                    variant={"gradient-bordered"}
                  >
                    Förfrågan Skickad
                  </ILButton>
                ) : (
                  <ILButton
                    disabled
                    isSmallSizeBtn
                    isArrow
                    ILBtnClass={"w-100 text-clr-pink"}
                    variant={"gradient-bordered"}
                  >
                    Requested Access Denied
                  </ILButton>
                )
              ) : (
                !isLiveProfile && (
                  <ILButton
                    isSmallSizeBtn
                    ILBtnClass={"w-100 text-clr-pink text-uppercase p sm"}
                    variant={"gradient-bordered"}
                    handleClick={loginHandler}
                  >
                    Logga in för att kopiera länken
                  </ILButton>
                )
              )}
            </div>
          )}
        </div>

        {isProductLabel && (
          <div
            className={clsx(
              styles.productLabelWrap,
              "position-absolute bg-white d-flex align-items-center justify-content-center"
            )}
          >
            <div className="d-flex flex-column gap-1">
              <Tooltip
                overlayClassName={styles.badgeTooltip}
                color={"white"}
                placement="bottomRight"
                title={
                  <>
                    <p className="big fw-semibold text-dark-purple mb-2">
                      Vad är CPC?
                    </p>
                    <p className="text-gray mb-0">
                      CPC står för Cost-Per-Click, vilket är en prismodell inom
                      onlineannonsering där annonsörer betalar en avgift varje
                      gång någon klickar på deras annons.
                    </p>
                  </>
                }
              >
                <h6
                  className={`fw-semibold p extra-xs mb-0 text-uppercase ${
                    !isLiveProfile && "cursor-pointer"
                  }`}
                >
                  CPC:{" "}
                  {productDataShow?.ppc_price ||
                    productDataShow?.product?.ppc_price}{" "}
                  KR
                </h6>
              </Tooltip>

              {((productDataShow?.sell_commission &&
                !isEmpty(productDataShow?.sell_commission)) ||
                (productDataShow?.product?.sell_commission &&
                  !isEmpty(productDataShow?.product?.sell_commission))) && (
                <Tooltip
                  overlayClassName={styles.badgeTooltip}
                  color={"white"}
                  placement="bottomRight"
                  title={
                    <>
                      <p className="big fw-semibold text-dark-purple mb-2">
                        Försäljning
                      </p>
                      <p className="text-gray mb-0">
                        Per försäljningsprovision
                      </p>
                    </>
                  }
                >
                  <h6
                    className={`fw-semibold p extra-xs mb-0 text-uppercase ${
                      !isLiveProfile && "cursor-pointer"
                    }`}
                  >
                    SELL:{" "}
                    {productDataShow?.sell_commission + " %" ||
                      productDataShow?.product?.sell_commission + " %"}
                  </h6>
                </Tooltip>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
