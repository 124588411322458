import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  doFetchCities,
  doFetchCountryList,
  doFetchInfluencerBrandList,
  doFetchLiveProfilesAllCollection,
  doFetchLiveProfilesAllProducts,
  doFetchLiveProfilesAllServices,
  doFetchLiveProfilesVisits,
  doFetchProductCategories,
  doFetchServiceCategories,
} from "../../../actions";
import { getDecryptionString } from "../../../services";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";

export const useLiveProfileHook = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [isLoading, setIsLoading] = useState({
    productList: false,
    serviceList: false,
    collectionList: false,
  });

  const [collectionList, setCollectionList] = useState({});
  const [productList, setProductList] = useState({});
  const [serviceList, setServiceList] = useState({});
  const [category, setCategory] = useState({
    categoryType: "product",
  });
  const [collectionPaginationData, setCollectionPaginationData] = useState({
    page: 1,
    pageSize: 40,
    search: "",
  });
  const [productPaginationData, setProductPaginationData] = useState({
    page: 1,
    pageSize: 40,
    search: "",
  });
  const [servicePaginationData, setServicePaginationData] = useState({
    page: 1,
    pageSize: 40,
    search: "",
  });
  const [setFilterData, isSetFilterData] = useState(false);
  const [productServiceFilterData, setProductServiceFilterData] = useState({
    ppcRange: [],
    priceRange: [],
    rating: "",
    categoryId: [],
    brandId: [],
    country: [],
    cities: [],
    countryId: [],
  });
  const { ref, inView, entry } = useInView({ threshold: 1 });
  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const { userDetails } = useSelector(
    (state) => state.storeAuthenticateReducer
  );

  useEffect(() => {
    fetchCountryList();
    fetchInfluencerLiveProfileVisit();
  }, []);

  useEffect(() => {
    fetchBrandList();
  }, [category?.categoryType]);

  useEffect(() => {
    fetchCitiesList();
  }, [productServiceFilterData?.countryId]);

  useEffect(() => {
    if (inView) {
      observerScroll();
    }
  }, [inView]);

  useEffect(() => {
    setProductPaginationData((prevState) => ({
      ...prevState,
      search: "",
    }));

    setCollectionPaginationData((prevState) => ({
      ...prevState,
      search: "",
    }));

    setServicePaginationData((prevState) => ({
      ...prevState,
      search: "",
    }));
  }, [category?.categoryType]);

  useEffect(() => {
    if (category?.categoryType === "product") {
      fetchInfluencerAllCollections(collectionPaginationData);
    }
  }, [collectionPaginationData?.page, category?.categoryType]);

  useEffect(() => {
    if (category?.categoryType === "product") {
      fetchInfluencerAllProducts(productPaginationData);
    }
  }, [productPaginationData?.page, category?.categoryType]);

  useEffect(() => {
    if (category?.categoryType === "service") {
      fetchInfluencerAllServices(servicePaginationData);
    }
  }, [servicePaginationData?.page, category?.categoryType]);

  useEffect(() => {
    fetchCategoryList();
  }, [category?.categoryType]);

  const observerScroll = (entries, type) => {
    if (type === "collection") {
      setCollectionPaginationData((prevState) => ({
        ...prevState,
        page: prevState?.page + 1,
      }));
    } else if (type === "product") {
      setProductPaginationData((prevState) => ({
        ...prevState,
        page: prevState?.page + 1,
      }));
    } else {
      setServicePaginationData((prevState) => ({
        ...prevState,
        page: prevState?.page + 1,
      }));
    }
  };

  const showFilterDataWrap = () => {
    isSetFilterData(true);
  };

  const hideFilterDataWrap = () => {
    isSetFilterData(false);
  };

  const handleApplyFilterModalCancel = () => {
    isSetFilterData(false);
    if (category?.categoryType === "service") {
      fetchInfluencerAllServices(servicePaginationData);
    } else {
      fetchInfluencerAllProducts(productPaginationData);
    }
  };

  const handleClearAllFilters = () => {
    setProductServiceFilterData({
      ppcRange: [],
      rating: "",
      priceRange: [],
      categoryId: [],
      brandId: [],
      country: [],
      countryId: [],
      cities: [],
    });
  };

  const handleInputChange = (event, type) => {
    const { value, name } = event?.target;
    if (type === "product") {
      setProductPaginationData((prevState) => ({
        ...prevState,
        [name]: value,
      }));

      setCollectionPaginationData((prevState) => ({
        ...prevState,
        [name]: value,
      }));

      debounceProductSearchHandler({
        ...productPaginationData,
        [name]: value,
        page: 1,
      });

      debounceCollectionSearchHandler({
        ...collectionPaginationData,
        [name]: value,
        page: 1,
      });
    } else {
      setServicePaginationData((prevState) => ({
        ...prevState,
        [name]: value,
      }));

      debounceServiceSearchHandler({
        ...servicePaginationData,
        [name]: value,
        page: 1,
      });
    }
  };

  const debounceProductSearchHandler = useCallback(
    debounce(async (data) => {
      await fetchInfluencerAllProducts(data);
    }, 1000),
    []
  );

  const debounceCollectionSearchHandler = useCallback(
    debounce(async (data) => {
      await fetchInfluencerAllCollections(data);
    }, 1000),
    []
  );

  const debounceServiceSearchHandler = useCallback(
    debounce(async (data) => {
      await fetchInfluencerAllServices(data);
    }, 1000),
    []
  );

  const handleSelectChange = (value, name) => {
    if (name === "rating") {
      setProductServiceFilterData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setCategory((prevState) => ({ ...prevState, [name]: value }));
      handleClearAllFilters();
    }
  };

  const handleTableChange = (event, type) => {
    try {
      if (type === "collection") {
        setCollectionPaginationData((prev) => ({
          ...prev,
          page: event,
        }));
      } else if (type === "product") {
        setProductPaginationData((prev) => ({
          ...prev,
          page: event,
        }));
      } else {
        setServicePaginationData((prev) => ({
          ...prev,
          page: event,
        }));
      }
    } catch (error) {
      console.error("Error occurred during pagination:", error);
    }
  };

  const handleProductDetail = (data) => {
    const shortCode =
      category?.categoryType === "product"
        ? data?.product?.short_code
        : data?.service?.short_code;
    window.open(`/${shortCode}`, "_blank");
  };

  const handleViewCollectionProducts = (data) => {
    navigate(`/collection/${data?.short_code}`);
  };

  const handleFilterInputChange = (event, type) => {
    const { name, value } = event?.target;

    if (type === "priceRange") {
      setProductServiceFilterData((prevState) => ({
        ...prevState,
        priceRange: prevState?.priceRange?.includes(value)
          ? prevState?.priceRange?.filter((data) => data !== value)
          : [...prevState?.priceRange, value],
      }));
    } else if (type === "ppcRange") {
      setProductServiceFilterData((prevState) => ({
        ...prevState,
        ppcRange: prevState?.ppcRange?.includes(value)
          ? prevState?.ppcRange?.filter((data) => data !== value)
          : [...prevState?.ppcRange, value],
      }));
    } else if (type === "categoryId") {
      setProductServiceFilterData((prevState) => ({
        ...prevState,
        categoryId: prevState?.categoryId?.includes(value)
          ? prevState?.categoryId?.filter((data) => data !== value)
          : [...prevState?.categoryId, value],
      }));
    } else if (type === "brandId") {
      setProductServiceFilterData((prevState) => ({
        ...prevState,
        brandId: prevState?.brandId?.includes(value)
          ? prevState?.brandId?.filter((data) => data !== value)
          : [...prevState?.brandId, value],
      }));
    } else if (type === "country") {
      setProductServiceFilterData((prevState) => ({
        ...prevState,
        country: prevState?.country?.includes(value)
          ? prevState?.country?.filter((data) => data !== value)
          : [...prevState?.countryId, name],
      }));
    } else if (type === "cities") {
      setProductServiceFilterData((prevState) => ({
        ...prevState,
        cities: prevState?.cities?.includes(value)
          ? prevState?.cities?.filter((id) => id !== value)
          : [...prevState?.cities, value],
      }));
    }
  };

  const fetchInfluencerLiveProfileVisit = async () => {
    try {
      const influencerVisitResponse = await doFetchLiveProfilesVisits(
        params?.userName
      );
      if (influencerVisitResponse?.status === 200) {
      } else {
        toast.error(influencerVisitResponse?.message);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const fetchInfluencerAllCollections = async (data) => {
    try {
      if (data?.page === 1) {
        setIsLoading((prev) => ({ ...prev, collectionList: true }));
      }

      const influencerCollectionsResponse =
        await doFetchLiveProfilesAllCollection(
          params?.userName,
          data?.page,
          data?.pageSize,
          data?.search
        );
      if (influencerCollectionsResponse?.status === 200) {
        const decryptedInfluencerCollectionsDetails = await JSON.parse(
          getDecryptionString(influencerCollectionsResponse?.data?.data)
        );

        const updatedCollections = {
          ...decryptedInfluencerCollectionsDetails,
          rows:
            data?.page > 1 && collectionList?.rows
              ? [
                  ...collectionList?.rows,
                  ...decryptedInfluencerCollectionsDetails?.rows,
                ]
              : decryptedInfluencerCollectionsDetails?.rows,
        };

        setCollectionList(updatedCollections);
      } else {
        toast.error(influencerCollectionsResponse?.message);
      }
      setIsLoading((prev) => ({ ...prev, collectionList: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prev) => ({ ...prev, collectionList: false }));
    }
  };

  const fetchInfluencerAllProducts = async (data) => {
    try {
      if (data?.page === 1) {
        setIsLoading((prev) => ({ ...prev, productList: true }));
      }

      const requestBody = {
        ppcRange: productServiceFilterData?.ppcRange
          ? productServiceFilterData?.ppcRange
          : [],
        priceRange: productServiceFilterData?.priceRange
          ? productServiceFilterData?.priceRange
          : [],
        categoryId: productServiceFilterData?.categoryId
          ? productServiceFilterData?.categoryId
          : [],
        brand: productServiceFilterData?.brandId
          ? productServiceFilterData?.brandId
          : [],
      };

      const influencerProductsResponse = await doFetchLiveProfilesAllProducts(
        params?.userName,
        data?.page,
        data?.pageSize,
        data?.search,
        requestBody
      );
      if (influencerProductsResponse?.status === 200) {
        const decryptedInfluencerProductsDetails = await JSON.parse(
          getDecryptionString(influencerProductsResponse?.data?.data)
        );

        const updatedProducts = {
          ...decryptedInfluencerProductsDetails,
          rows:
            data?.page > 1 && productList?.rows
              ? [
                  ...productList?.rows,
                  ...decryptedInfluencerProductsDetails?.rows,
                ]
              : decryptedInfluencerProductsDetails?.rows,
        };

        setProductList(updatedProducts);
      } else {
        toast.error(influencerProductsResponse?.message);
      }
      setIsLoading((prev) => ({ ...prev, productList: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prev) => ({ ...prev, productList: false }));
    }
  };

  const fetchInfluencerAllServices = async (data) => {
    try {
      if (data?.page === 1) {
        setIsLoading((prev) => ({ ...prev, serviceList: true }));
      }

      const requestBody = {
        ppcRange: productServiceFilterData?.ppcRange
          ? productServiceFilterData?.ppcRange
          : [],
        priceRange: productServiceFilterData?.priceRange
          ? productServiceFilterData?.priceRange
          : [],
        rating:
          productServiceFilterData?.rating > 0
            ? productServiceFilterData?.rating
            : "",
        categoryId: productServiceFilterData?.categoryId
          ? productServiceFilterData?.categoryId
          : [],
        brand: productServiceFilterData?.brandId
          ? productServiceFilterData?.brandId
          : [],
        countries: productServiceFilterData?.country
          ? productServiceFilterData?.country
          : [],
        cities: productServiceFilterData?.cities
          ? productServiceFilterData?.cities
          : [],
      };

      const influencerServicesResponse = await doFetchLiveProfilesAllServices(
        params?.userName,
        data?.page,
        data?.pageSize,
        data?.search,
        requestBody
      );
      if (influencerServicesResponse?.status === 200) {
        const decryptedInfluencerServiceDetails = await JSON.parse(
          getDecryptionString(influencerServicesResponse?.data?.data)
        );

        const updatedServices = {
          ...decryptedInfluencerServiceDetails,
          rows:
            data?.page > 1 && serviceList?.rows
              ? [
                  ...serviceList?.rows,
                  ...decryptedInfluencerServiceDetails?.rows,
                ]
              : decryptedInfluencerServiceDetails?.rows,
        };

        setServiceList(updatedServices);
      } else {
        toast.error(influencerServicesResponse?.message);
      }
      setIsLoading((prev) => ({ ...prev, serviceList: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prev) => ({ ...prev, serviceList: false }));
    }
  };

  const fetchCategoryList = async () => {
    try {
      let categoryListResponse;

      if (category?.categoryType === "product") {
        categoryListResponse = await doFetchProductCategories();
      } else {
        categoryListResponse = await doFetchServiceCategories();
      }
      if (categoryListResponse?.status === 200) {
        const decryptedCategoryList = await JSON.parse(
          getDecryptionString(categoryListResponse?.data)
        );

        const categoryListData = decryptedCategoryList?.map((data) => {
          return {
            value: data?.id,
            label: data?.name,
          };
        });
        setCategoryList(categoryListData);
      } else {
        toast.error(categoryListResponse?.message);
      }
    } catch (error) {
      console.error("Error occurred during fetching category list:", error);
    }
  };

  const fetchBrandList = async () => {
    try {
      const brandListResponse = await doFetchInfluencerBrandList(
        params?.userName,
        category?.categoryType
      );

      if (brandListResponse?.status === 200) {
        const decryptedBrandList = await JSON.parse(
          getDecryptionString(brandListResponse?.data?.data)
        );
        const brandListData = decryptedBrandList?.map((data) => {
          return {
            value: data?.brand_id,
            label: data?.brand?.name,
          };
        });
        setBrandList(brandListData);
      } else {
        toast.error(brandListResponse?.message);
      }
    } catch (error) {
      console.error("Error occurred during fetching brand list:", error);
    }
  };

  const fetchCountryList = async () => {
    try {
      const countryListResponse = await doFetchCountryList();
      if (countryListResponse?.status === 200) {
        const decryptedCountryList = await JSON.parse(
          getDecryptionString(countryListResponse?.data?.data)
        );
        const countriesListData = decryptedCountryList?.map((data) => {
          return {
            value: data?.name,
            label: data?.name,
            id: data?.id,
          };
        });
        setCountryList(countriesListData);
      } else {
        toast.error(countryListResponse?.message);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const fetchCitiesList = async () => {
    try {
      const citiesListResponse = await doFetchCities(
        productServiceFilterData?.countryId?.length > 0
          ? productServiceFilterData?.countryId?.join(",")
          : "e434260b-cbfa-47f2-807c-d49116afe944"
      );
      if (citiesListResponse?.status === 200) {
        const decryptedCitiesList = await JSON.parse(
          getDecryptionString(citiesListResponse?.data)
        );
        const citiesListData = decryptedCitiesList?.map((data) => {
          return {
            value: data?.id,
            label: data?.name,
          };
        });

        setCitiesList(citiesListData);
      } else {
        toast.error(citiesListResponse?.message);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return {
    category,
    brandList,
    isLoading,
    citiesList,
    productList,
    serviceList,
    countryList,
    categoryList,
    setFilterData,
    collectionList,
    servicePaginationData,
    productPaginationData,
    productServiceFilterData,
    collectionPaginationData,
    ref,
    navigate,
    observerScroll,
    handleTableChange,
    handleInputChange,
    hideFilterDataWrap,
    showFilterDataWrap,
    handleSelectChange,
    handleProductDetail,
    handleClearAllFilters,
    handleFilterInputChange,
    handleApplyFilterModalCancel,
    handleViewCollectionProducts,
  };
};
