import React from "react";
import clsx from "clsx";
import styles from "./ILCheckbox.module.css";

export const ILCheckbox = ({
  name,
  value,
  checkboxList,
  iconImg,
  handleChange,
  errorMsg,
  multiple = false,
  selectedIds,
  isNoClick,
  ...rest
}) => {
  return (
    <>
      {checkboxList &&
        checkboxList.map((item, index) => {
          return (
            <div
              key={index}
              className={clsx(isNoClick && styles.isNoClick, "h-auto")}
            >
              <input
                className={clsx(styles.checkboxInput)}
                type="checkbox"
                name={name}
                id={item.id}
                value={item?.id}
                onChange={handleChange}
                checked={selectedIds?.includes(item.id)}
                // multiple={multiple}
                {...rest}
              />
              <label
                className={clsx(
                  styles.checkboxLabel,
                  "h-100 custom-checkbox-label"
                )}
                htmlFor={item.id}
              >
                {iconImg ? (
                  <img
                    // crossorigin="anonymous"
                    src={item.icon}
                    className={clsx(styles.productCategoriesIcon)}
                  />
                ) : (
                  item.icon
                )}
                <div
                  className={clsx(
                    { "section-xs-mt": item.labelTitle },
                    { "mt-4": !item.labelTitle }
                  )}
                >
                  {item.labelTitle && (
                    <h5 className="p big text-capitalize text-dark-purple mb-3">
                      {item.labelTitle}
                    </h5>
                  )}
                  <p
                    className={clsx(
                      { "paragraph-sm-text": item.labelTitle },
                      { [styles.labelSubtitle]: !item.labelTitle },
                      "mb-0"
                    )}
                  >
                    {item.labelSubtitle}
                  </p>
                </div>
              </label>
            </div>
          );
        })}
      {errorMsg && (
        <span className="p sm mt-3 lh-1 text-tomato fw-medium d-block">
          {errorMsg}
        </span>
      )}
    </>
  );
};
