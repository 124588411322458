import { ilonsiWhiteLogo } from "../../constants/imageData";
import clsx from "clsx";
import styles from "./ILLiveProfileHeader.module.css";

export const ILLiveProfileHeader = () => {
  return (
    <>
      <header
        className={clsx(
          styles.ILLiveProfileHeader,
          "live-profile-hero-gradient inner-hero-gradient overflow-hidden z-2"
        )}
      >
        <a
          href="/dashboard/my-shop"
          className={clsx(styles.logoWrap, "d-flex position-absolute")}
        >
          <img
            src={ilonsiWhiteLogo}
            alt="advertising agency | ilonsi"
            className={clsx(styles.logoImg)}
          />
        </a>
        {/* <ILTextInput
          ILInputLabelClass={"live-profile-search-input mb-0"}
          placeholder={"Sök här"}
          type={"search"}
          prefix={
            <Search01Icon
              size={20}
              color={"var(--clr-dark-purple)"}
              variant={"stroke"}
            />
          }
          allowClear
        /> */}
      </header>
    </>
  );
};
