import { Form } from "antd";
import { ILUpload } from "../../../../components";

export const ILUploadProfile = ({
  signUpData,
  handleFileInputChange,
  validateMessages,
}) => {
  return (
    <>
      <h1 className="secondary-title md-secondary-title">
        Ladda upp profilbild
      </h1>
      <p className="paragraph-sm-text paragraph-md-text">
        En profil med profilbild ökar dina chanser till samarbeten med över 80%.
      </p>
      <Form className="d-flex align-items-center flex-column gap-5">
        <ILUpload
          htmlFor="imgUploadProfile"
          id="imgUploadProfile"
          name="image"
          multiple={false}
          stylingUploadWrap
          isshowOutSideTitle
          accept=""
          viewImg
          value={signUpData?.image}
          handleChange={handleFileInputChange}
          errorMsg={validateMessages?.image}
          className="mx-0"
          imgClass="object-fit-cover"
        />
        <p className="p text-dark-purple fw-medium text-center mb-0 mx-auto lh-22 px-1">
          Endast JPG, PNG, JPEG filformat tillåts | ska vara 108*108 px | max 30
          MB
        </p>
      </Form>
    </>
  );
};
