import { clsx } from "clsx";
import { ILButton } from "..";

export const ILAddYourCollectionModal = ({
  isLoading,
  collectionData,
  className,
  showSCModal,
}) => {
  return (
    <>
      {/* MODAL START */}
      <ILButton
        disabled={isLoading}
        isSmallSizeBtn
        ILBtnClass={clsx(className, "w-100 mt-5 mt-xl-4 mt-xxl-3")}
        variant={"primary-gradient"}
        handleClick={() => {
          showSCModal(collectionData);
        }}
      >
        Lägg till kollektion
      </ILButton>
      {/* ADD TO YOUR COLLECTION MODAL */}
      {/* <ILModal
        open={isShopCollectionModalOpen}
        onOk={handleSCModalOk}
        onCancel={handleSCModalCancel}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <div
                className="brand-logo brand-logo-big flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center mx-auto"
                style={{ borderColor: "#000000" }}
              >
                <img
                  src={collectionData?.brand?.profile_image}
                  height={50}
                  width={50}
                  alt="advertising agency | ilonsi"
                  className="object-fit-contain img-fluid"
                />
              </div>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Vill du lägga till den här kollektionen i din butik?
              </h4>
              <p className="paragraph-sm-text paragraph-md-text text-center mb-0">
             Denna kollektion kommer att läggas till i din butik.
              </p>
              <div className="d-flex align-items-center gap-4 modal-more-btn">
                <ILButton
                  disabled={isLoading}
                  ILBtnClass="w-50"
                  variant={"primary-gradient"}
                  onClick={handleAddToShopCollection}
                >
                  Ja, Lägg till
                </ILButton>
                <ILButton
                  ILBtnClass="w-50"
                  variant={"gradient-bordered"}
                  onClick={handleSCModalCancel}
                >
                  Nej, Avbryt
                </ILButton>
              </div>
            </div>
          </>
        }
        isFooterRemove
      /> */}
      {/* SUCCESSFULLY REQUET MODAL */}
      {/* <ILModal
        open={isCollectionAddedModalOpen}
        onOk={() => handleCAModalOk("new")}
        onCancel={() => handleCAModalOk("new")}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <span className="lh-0">
                <img
                  src={gradientCircleRound}
                  fluid
                  alt="advertising agency | ilonsi"
                />
              </span>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Din kollektion har lagts till!
              </h4>
              <ILButton
                ILBtnClass="w-100"
                variant={"primary-gradient"}
                handleClick={myShopNavigation}
              >
                Visa i min butik
              </ILButton>
            </div>
          </>
        }
        isFooterRemove
      />

      <ILModal
        open={isCollectionAlreadyAddedModalOpen}
        onOk={() => handleCAModalOk("already")}
        onCancel={() => handleCAModalOk("already")}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <span className="lh-0">
                <img
                  src={gradientCircleRound}
                  fluid
                  alt="advertising agency | ilonsi"
                />
              </span>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Din kollektion har lagts till redan!
              </h4>
              <ILButton
                ILBtnClass="w-100"
                variant={"primary-gradient"}
                handleClick={myShopNavigation}
              >
                Visa i min butik
              </ILButton>
            </div>
          </>
        }
        isFooterRemove
      /> */}

      {/* MODAL END */}
    </>
  );
};
