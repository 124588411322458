import { ILTextInput } from "../../../../components";
import { Form } from "antd";

export const ILSignUpEmailVerification = ({
  otpData,
  signUpData,
  validateMessages,
  handleSelectChange,
}) => {
  return (
    <>
      <h1 className="secondary-title md-secondary-title">E-postverifiering</h1>
      <p className="paragraph-sm-text paragraph-md-text">
        Vänligen ange den 6-siffriga verifieringskoden vi skickade till{" "}
        <a
          href="mailto:JohnSmith@gmail.com"
          className="text-dark-purple fw-medium"
        >
          {signUpData?.ePostAddress}
        </a>
      </p>
      <Form>
        <ILTextInput
          name="otp"
          type="number"
          isRequiredOtp
          isOTPInput
          value={otpData?.otp}
          otpLength={6}
          errorMsg={validateMessages?.otp}
          onChangeOtpInput={(event) => handleSelectChange(event, "otp")}
        />
      </Form>
    </>
  );
};
