import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import clsx from "clsx";
import styles from "./ILDoughnutChart.module.css";
import { Icons } from "../../../constants/icons";

const ILDoughnutChart = ({ chartData }) => {
  // Original visitors data
  // const visitorsData = [0, 0, 0];
  const visitorsData = [
    chartData?.visitors?.previous_week_visitor || 0,
    chartData?.visitors?.previous_month_visitor || 0,
    chartData?.visitors?.previous_year_visitor || 0,
  ];
  // Filter out zero values from the visitors data
  const filteredData = visitorsData.map((value) =>
    value === 0 ? null : value
  );
  const filteredLabels = [
    "Föregående vecka",
    "Föregående månad",
    "Föregående år",
  ].filter((_, index) => filteredData[index] !== null);
  const filteredRenderingData = filteredData.filter((value) => value !== null);
  // Utility function to check if all data points are zero
  const isAllDataZero = filteredRenderingData.length === 0;
  const data = {
    labels: filteredLabels,
    datasets: [
      {
        label: "Besökare",
        data: isAllDataZero ? [1] : filteredRenderingData, // Show a dummy value if all data is zero
        backgroundColor: ["#F58BBC", "#F15DA1", "#F9B9D7"].slice(
          0,
          filteredRenderingData.length
        ),
        hoverOffset: 1,
      },
    ],
  };
  // HTML Legend
  const getOrCreateLegendList = (chart, id) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer?.querySelector("ul");
    if (!listContainer) {
      listContainer = document.createElement("ul");
      listContainer.classList.add(styles.legendContainer);
      legendContainer?.appendChild(listContainer);
    }
    return listContainer;
  };
  const htmlLegendPlugin = {
    id: "htmlLegend",
    afterUpdate(chart, args, options) {
      const legendContainer = document.getElementById(options?.containerID);
      if (!legendContainer) return;
      // Clear the existing legend
      while (legendContainer.firstChild) {
        legendContainer.firstChild.remove();
      }
      if (isAllDataZero) {
        // Show "No data" text when all data points are zero
        const noDataText = document.createElement("p");
        // noDataText.textContent = "No data";
        noDataText.classList.add("text-center", "fw-semibold", "text-muted");
        legendContainer.appendChild(noDataText);
      } else {
        // Generate legend items for non-zero data
        const ul = document.createElement("ul");
        ul.classList.add(styles.legendContainer);
        const items = chart.options.plugins.legend.labels.generateLabels(chart);
        items.forEach((item) => {
          const li = document.createElement("li");
          li.classList.add(styles.legendList);
          const boxSpan = document.createElement("span");
          boxSpan.style.background = item.fillStyle;
          boxSpan.classList.add(styles.legendBoxSpan);
          let textSpan = document.createElement("span");
          textSpan.style.color = item.fillStyle;
          textSpan.textContent = item.text;
          textSpan.classList.add(styles.legendText);

          li.appendChild(boxSpan);
          li.appendChild(textSpan);

          ul.appendChild(li);
        });
        legendContainer.appendChild(ul);
      }
    },
  };
  return (
    <>
      <div className={clsx(styles.doughnutChartWrapper, "mx-auto w-100")}>
        {isAllDataZero ? (
          <div className={clsx(styles.NoDataText, "text-center pt-xl-5")}>
            <span>{Icons.packageOpenBoxIcon}</span>
            <h6 className={clsx("fw-normal text-center pt-4 mb-0 text-gray")}>
              Ingen data
            </h6>
          </div>
        ) : (
          <Doughnut
            data={data}
            plugins={[htmlLegendPlugin, ChartDataLabels]}
            options={{
              borderWidth: 0,
              responsive: true,
              plugins: {
                tooltip: {
                  enabled: true,
                },
                datalabels: {
                  color: "white",
                  formatter: (value) => value, // Show actual values in the data labels
                },
                htmlLegend: {
                  containerID: "legend-container",
                },
                legend: {
                  display: false, // Disable the default legend
                },
              },
            }}
          />
        )}
      </div>
      {!isAllDataZero && (
        <div id="legend-container" className="legend-container mt-5">
          <h6 className="text-center mt-4 mb-4 p fw-semibold text-clr-pink">
            Visitors
          </h6>
        </div>
      )}
    </>
  );
};
export default ILDoughnutChart;
