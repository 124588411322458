import clsx from "clsx";
import { noData } from "../../constants/imageData";
import { Icons } from "../../constants/icons";
import styles from "./ILNoDataCard.module.css";

export const ILNoDataCard = ({
  className,
  title = "Inga företag hittades i den här kategorin",
  description,
  img = noData,
  isIcon,
}) => {
  return (
    <>
      <div className={clsx(styles.noDataCard, className, "mx-auto bg-white")}>
        <div className="d-grid text-center gap-3">
          {isIcon ? (
            <span className="mb-3">{Icons.packageOpenBoxIcon}</span>
          ) : (
            <div className={styles.noDataImg}>
              <img
                src={img}
                className="img-fluid"
                alt="advertising agency | ilonsi"
              />
            </div>
          )}
          <h5 className="big mb-0 lh-base">{title}</h5>
          <p className="mb-0">{description ? description : ""}</p>
        </div>
      </div>
    </>
  );
};
