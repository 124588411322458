export const Icons = {
  linkArrow: (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1C12 0.447714 11.5523 -7.61451e-07 11 -3.39982e-07L2 -2.13542e-07C1.44772 -5.50717e-07 1 0.447715 1 0.999999C1 1.55228 1.44772 2 2 2L10 2L10 10C10 10.5523 10.4477 11 11 11C11.5523 11 12 10.5523 12 10L12 1ZM1.70711 11.7071L11.7071 1.70711L10.2929 0.292893L0.292893 10.2929L1.70711 11.7071Z"
        fill="currentcolor"
      />
    </svg>
  ),
  whatsappIcon: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1299_326)">
        <path
          d="M0.0517578 24L1.73926 17.8359C0.698633 16.0359 0.154883 13.9875 0.154883 11.8922C0.154883 5.33438 5.49395 0 12.0518 0C15.2346 0 18.2205 1.24219 20.4658 3.4875C22.7111 5.7375 23.9486 8.72344 23.9486 11.9016C23.9439 18.4594 18.6096 23.7938 12.0518 23.7938H12.0471C10.0549 23.7938 8.10019 23.2922 6.36113 22.3453L0.0517578 24ZM6.65176 20.1937L7.01269 20.4094C8.53144 21.3094 10.2705 21.7875 12.0471 21.7875H12.0518C17.5033 21.7875 21.9377 17.3531 21.9377 11.9016C21.9377 9.2625 20.9111 6.77812 19.0455 4.90781C17.1799 3.0375 14.6955 2.01094 12.0564 2.01094C6.60019 2.01094 2.16582 6.44531 2.16582 11.8969C2.16582 13.7625 2.68613 15.5813 3.6752 17.1562L3.90957 17.5312L2.91113 21.1781L6.65176 20.1937Z"
          fill="white"
        />
        <path
          d="M0.46875 23.5828L2.1 17.6344C1.09219 15.8953 0.5625 13.9172 0.5625 11.8969C0.567188 5.56405 5.71875 0.417175 12.0469 0.417175C15.1172 0.417175 18.0047 1.61249 20.1703 3.7828C22.3406 5.95311 23.5359 8.83593 23.5313 11.9062C23.5266 18.2344 18.375 23.3859 12.0469 23.3859H12.0422C10.1203 23.3859 8.23125 22.9031 6.55313 21.9891L0.46875 23.5828Z"
          fill="url(#paint0_linear_1299_326)"
        />
        <path
          d="M0.0517578 24L1.73926 17.8359C0.698633 16.0359 0.154883 13.9875 0.154883 11.8922C0.154883 5.33438 5.49395 0 12.0518 0C15.2346 0 18.2205 1.24219 20.4658 3.4875C22.7111 5.7375 23.9486 8.72344 23.9486 11.9016C23.9439 18.4594 18.6096 23.7938 12.0518 23.7938H12.0471C10.0549 23.7938 8.10019 23.2922 6.36113 22.3453L0.0517578 24ZM6.65176 20.1937L7.01269 20.4094C8.53144 21.3094 10.2705 21.7875 12.0471 21.7875H12.0518C17.5033 21.7875 21.9377 17.3531 21.9377 11.9016C21.9377 9.2625 20.9111 6.77812 19.0455 4.90781C17.1799 3.0375 14.6955 2.01094 12.0564 2.01094C6.60019 2.01094 2.16582 6.44531 2.16582 11.8969C2.16582 13.7625 2.68613 15.5813 3.6752 17.1562L3.90957 17.5312L2.91113 21.1781L6.65176 20.1937Z"
          fill="url(#paint1_linear_1299_326)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.075 6.91876C8.85 6.42189 8.61563 6.41251 8.40469 6.40314C8.23125 6.39376 8.03438 6.39845 7.83281 6.39845C7.63594 6.39845 7.3125 6.47345 7.04062 6.76876C6.76875 7.06407 6 7.78595 6 9.24845C6 10.7109 7.06406 12.1266 7.21406 12.3234C7.36406 12.5203 9.27188 15.6188 12.2906 16.8094C14.8031 17.7984 15.3141 17.6016 15.8578 17.5547C16.4016 17.5078 17.6156 16.8375 17.8641 16.1438C18.1125 15.45 18.1125 14.8547 18.0375 14.7328C17.9625 14.6109 17.7656 14.5359 17.4656 14.3859C17.1703 14.2359 15.7078 13.5188 15.4359 13.4203C15.1641 13.3219 14.9672 13.2703 14.7656 13.5703C14.5688 13.8656 13.9969 14.5359 13.8234 14.7328C13.65 14.9297 13.4766 14.9578 13.1813 14.8078C12.8859 14.6578 11.925 14.3438 10.7906 13.3313C9.90469 12.5438 9.30937 11.5688 9.13594 11.2734C8.9625 10.9781 9.11719 10.8141 9.26719 10.6688C9.39844 10.5375 9.5625 10.3219 9.7125 10.1484C9.8625 9.97501 9.90937 9.85314 10.0078 9.65157C10.1062 9.4547 10.0594 9.28126 9.98438 9.13126C9.91875 8.97657 9.34219 7.50939 9.075 6.91876Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1299_326"
          x1="11.9999"
          y1="23.5847"
          x2="11.9999"
          y2="0.415582"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#20B038" />
          <stop offset="1" stop-color="#60D66A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1299_326"
          x1="12.0002"
          y1="24"
          x2="12.0002"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F9F9F9" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <clipPath id="clip0_1299_326">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  instagram: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5537 3.29509C14.3903 3.29847 15.1917 3.634 15.7833 4.22857C16.3748 4.82314 16.7087 5.62857 16.712 6.46941V13.5306C16.7087 14.3714 16.3748 15.1769 15.7833 15.7714C15.1917 16.366 14.3903 16.7015 13.5537 16.7049H6.44567C5.60907 16.7015 4.80769 16.366 4.21611 15.7714C3.62454 15.1769 3.29071 14.3714 3.28734 13.5306V6.46941C3.29071 5.62857 3.62454 4.82314 4.21611 4.22857C4.80769 3.634 5.60907 3.29847 6.44567 3.29509H13.5537ZM13.5537 1.81865H6.44567C3.90052 1.81865 1.81787 3.91423 1.81787 6.46989V13.5306C1.81787 16.0886 3.90289 18.1818 6.44567 18.1818H13.5537C16.0989 18.1818 18.1815 16.0862 18.1815 13.5306V6.46941C18.1815 3.91138 16.0989 1.81818 13.5537 1.81818V1.81865Z"
        fill="currentcolor"
      />
      <path
        d="M9.99956 7.04009C10.585 7.04009 11.1572 7.21369 11.644 7.53892C12.1307 7.86416 12.5101 8.32643 12.7341 8.86728C12.9582 9.40813 13.0168 10.0033 12.9026 10.5774C12.7884 11.1516 12.5065 11.679 12.0925 12.0929C11.6786 12.5069 11.1512 12.7888 10.577 12.903C10.0028 13.0172 9.40771 12.9586 8.86686 12.7346C8.32601 12.5105 7.86374 12.1312 7.5385 11.6444C7.21326 11.1577 7.03967 10.5854 7.03967 9.99998C7.04062 9.21526 7.35276 8.46295 7.90765 7.90807C8.46253 7.35319 9.21484 7.04104 9.99956 7.04009ZM9.99956 5.45453C9.10055 5.45453 8.22173 5.72111 7.47424 6.22058C6.72674 6.72004 6.14414 7.42994 5.80011 8.26051C5.45607 9.09109 5.36606 10.005 5.54144 10.8868C5.71683 11.7685 6.14974 12.5784 6.78544 13.2141C7.42113 13.8498 8.23105 14.2827 9.11278 14.4581C9.99451 14.6335 10.9085 14.5435 11.739 14.1994C12.5696 13.8554 13.2795 13.2728 13.779 12.5253C14.2784 11.7778 14.545 10.899 14.545 9.99998C14.545 8.79445 14.0661 7.6383 13.2137 6.78586C12.3612 5.93342 11.2051 5.45453 9.99956 5.45453Z"
        fill="currentcolor"
      />
      <path
        d="M14.5448 6.36365C15.0469 6.36365 15.4539 5.95664 15.4539 5.45456C15.4539 4.95248 15.0469 4.54547 14.5448 4.54547C14.0428 4.54547 13.6357 4.95248 13.6357 5.45456C13.6357 5.95664 14.0428 6.36365 14.5448 6.36365Z"
        fill="currentcolor"
      />
    </svg>
  ),
  facebook: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1032_2107)">
        <path
          d="M17.9463 0.916809H2.05254C1.75163 0.917634 1.46328 1.03754 1.2505 1.25031C1.03772 1.46309 0.917817 1.75144 0.916992 2.05236V17.9461C0.917817 18.247 1.03772 18.5354 1.2505 18.7481C1.46328 18.9609 1.75163 19.0808 2.05254 19.0817H10.6002V12.0574H8.23066V9.30665H10.6002V7.26564C10.6002 5.65743 10.9717 4.73751 11.7744 4.14454C12.4623 3.63673 13.3588 3.60431 13.9061 3.62111C14.492 3.63907 16.2549 3.73829 16.2549 3.73829V6.22931L14.6201 6.21368C14.6201 6.21368 13.9381 6.18986 13.6826 6.57853C13.4646 6.91212 13.4604 7.48322 13.4604 7.48322V9.2922H16.142L15.8404 12.0586H13.4459V19.0817H17.9463C18.2472 19.0808 18.5356 18.9609 18.7483 18.7481C18.9611 18.5354 19.081 18.247 19.0818 17.9461V2.05236C19.081 1.75144 18.9611 1.46309 18.7483 1.25031C18.5356 1.03754 18.2472 0.917634 17.9463 0.916809Z"
          fill="currentcolor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1032_2107">
          <rect
            width="20"
            height="20"
            fill="currentcolor"
            transform="translate(-0.000488281)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  linkdin: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1032_2109)">
        <path
          d="M17.7438 0.916809H2.25479C1.90172 0.91784 1.56341 1.05855 1.31376 1.3082C1.0641 1.55786 0.923394 1.89617 0.922363 2.24923V17.7383C0.923394 18.0914 1.0641 18.4297 1.31376 18.6793C1.56341 18.929 1.90172 19.0697 2.25479 19.0707H17.7438C18.0969 19.0697 18.4352 18.929 18.6849 18.6793C18.9345 18.4297 19.0752 18.0914 19.0763 17.7383V2.24923C19.0752 1.89617 18.9345 1.55786 18.6849 1.3082C18.4352 1.05855 18.0969 0.91784 17.7438 0.916809ZM6.4001 16.3856H3.72549V7.73947H6.4001V16.3856ZM5.06299 6.52697C4.74971 6.52697 4.44346 6.43407 4.18297 6.26002C3.92249 6.08597 3.71947 5.83858 3.59958 5.54915C3.47969 5.25971 3.44832 4.94122 3.50944 4.63396C3.57056 4.3267 3.72142 4.04446 3.94294 3.82293C4.16447 3.60141 4.44671 3.45055 4.75397 3.38943C5.06123 3.32831 5.37972 3.35968 5.66915 3.47957C5.95859 3.59946 6.20597 3.80248 6.38002 4.06297C6.55407 4.32345 6.64697 4.6297 6.64697 4.94298C6.64702 5.15101 6.60609 5.35701 6.5265 5.54921C6.44692 5.74141 6.33025 5.91604 6.18315 6.06314C6.03605 6.21024 5.86141 6.32691 5.66921 6.4065C5.47701 6.48608 5.27101 6.52702 5.06299 6.52697ZM16.5196 16.3875H13.8274V11.6445C13.8274 11.6445 13.8903 10.6938 13.3817 10.1992C12.9192 9.7504 12.2763 9.86017 12.2763 9.86017C12.2763 9.86017 11.561 9.87814 11.1708 10.4852C10.9364 10.8496 10.8677 11.3465 10.8501 11.6844V16.3875H8.1751V7.73947H10.8497V8.78087C11.0919 8.43673 11.5165 7.97853 12.1333 7.73947C13.0708 7.37697 13.8759 7.43009 14.5677 7.63868C15.4353 7.90001 15.9302 8.62931 16.1095 8.9879C16.3505 9.46915 16.5196 10.111 16.5196 10.9137V16.3875Z"
          fill="currentcolor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1032_2109">
          <rect
            width="20"
            height="20"
            fill="currentcolor"
            transform="translate(-0.000488281)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  tiktok: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4995 8.52118V5.50279C13.5555 5.33627 13.4292 1.75279 13.4292 1.41606V1.39069H10.3812V13.1402C10.381 13.648 10.2206 14.1427 9.92296 14.5541C9.6253 14.9655 9.2055 15.2725 8.72329 15.4315C8.24107 15.5904 7.72099 15.5933 7.23705 15.4396C6.7531 15.286 6.32995 14.9836 6.02778 14.5755C5.72562 14.1675 5.55983 13.6745 5.55401 13.1668C5.5482 12.6591 5.70265 12.1625 5.99538 11.7476C6.28812 11.3327 6.70424 11.0208 7.18454 10.856C7.66483 10.6913 8.18484 10.6823 8.67057 10.8302V7.71821C8.43649 7.6889 8.20078 7.6746 7.96487 7.6754C6.88359 7.67571 5.82668 7.99666 4.92781 8.59766C4.02894 9.19866 3.32848 10.0527 2.915 11.0518C2.50153 12.0509 2.39361 13.1502 2.60491 14.2106C2.8162 15.2711 3.33721 16.245 4.10205 17.0094C4.86689 17.7737 5.84121 18.294 6.90179 18.5046C7.96237 18.7152 9.06157 18.6065 10.0604 18.1924C11.0592 17.7782 11.9128 17.0772 12.5132 16.1779C13.1136 15.2786 13.4338 14.2215 13.4334 13.1402C13.4334 13.0636 13.4334 12.9875 13.4281 12.9119V7.12617C14.8316 8.41017 17.4995 8.52118 17.4995 8.52118Z"
        fill="currentcolor"
      />
    </svg>
  ),
  threads: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0413 7.0874C14.7271 2.19837 10.208 2.50377 10.208 2.50377C10.208 2.50377 3.95801 2.0876 3.95801 10C3.95801 17.9124 10.208 17.4963 10.208 17.4963C10.208 17.4963 13.9228 17.7437 15.6247 14.2318C16.1803 12.6837 16.0413 9.58742 10.6247 9.58742C10.6247 9.58742 8.12467 9.58742 8.12467 11.6708C8.12467 12.4843 8.95801 13.3374 10.208 13.3374C11.458 13.3374 12.8507 12.4814 13.1247 10.8374C13.958 5.8374 9.37467 5.42074 8.12467 7.50407"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  ),
  leftArrow: (
    <svg
      width="13"
      height="20"
      viewBox="0 0 13 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 2L3 10L11 18" stroke="#161C2D" strokeWidth="3" />
    </svg>
  ),
  rightArrow: (
    <svg
      width="13"
      height="20"
      viewBox="0 0 13 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 2L10 10L2 18" stroke="#161C2D" strokeWidth="3" />
    </svg>
  ),
  youtubeArrow: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      width="24"
      height="24"
    >
      <path d="M19.6069 6.99482C19.5307 6.69695 19.3152 6.47221 19.0684 6.40288C18.6299 6.28062 16.501 6 12.001 6C7.50098 6 5.37252 6.28073 4.93225 6.40323C4.68776 6.47123 4.4723 6.69593 4.3951 6.99482C4.2863 7.41923 4.00098 9.19595 4.00098 12C4.00098 14.804 4.2863 16.5808 4.3954 17.0064C4.47126 17.3031 4.68676 17.5278 4.93251 17.5968C5.37252 17.7193 7.50098 18 12.001 18C16.501 18 18.6299 17.7194 19.0697 17.5968C19.3142 17.5288 19.5297 17.3041 19.6069 17.0052C19.7157 16.5808 20.001 14.8 20.001 12C20.001 9.2 19.7157 7.41923 19.6069 6.99482ZM21.5442 6.49818C22.001 8.28 22.001 12 22.001 12C22.001 12 22.001 15.72 21.5442 17.5018C21.2897 18.4873 20.547 19.2618 19.6056 19.5236C17.8971 20 12.001 20 12.001 20C12.001 20 6.10837 20 4.39637 19.5236C3.45146 19.2582 2.70879 18.4836 2.45774 17.5018C2.00098 15.72 2.00098 12 2.00098 12C2.00098 12 2.00098 8.28 2.45774 6.49818C2.71227 5.51273 3.45495 4.73818 4.39637 4.47636C6.10837 4 12.001 4 12.001 4C12.001 4 17.8971 4 19.6056 4.47636C20.5505 4.74182 21.2932 5.51636 21.5442 6.49818ZM10.001 15.5V8.5L16.001 12L10.001 15.5Z"></path>
    </svg>
  ),
  circleRoundIcon: (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2210_3751)">
        <path
          d="M31.9998 0C14.3268 0 0 14.3268 0 31.9998C0 49.6728 14.3268 64.0002 31.9998 64.0002C49.6728 64.0002 64.0002 49.6728 64.0002 31.9998C63.9797 14.3355 49.6646 0.0205078 31.9998 0ZM31.9998 60.7998C16.0939 60.7998 3.1998 47.9057 3.1998 31.9998C3.1998 16.0939 16.0939 3.1998 31.9998 3.1998C47.9057 3.1998 60.7998 16.0939 60.7998 31.9998C60.7822 47.8986 47.8986 60.7822 31.9998 60.7998Z"
          fill="url(#paint0_linear_2210_3751)"
        />
        <path
          d="M41.9498 22.9991L30.2668 37.6024L23.5308 30.8682C22.9027 30.2618 21.9048 30.2706 21.2879 30.8876C20.6709 31.5046 20.6621 32.5024 21.2685 33.1306L29.2683 41.1304C29.5683 41.4304 29.9755 41.5991 30.3998 41.5991C30.4291 41.5997 30.4589 41.5985 30.4888 41.5962C30.9429 41.5716 31.3654 41.3548 31.6496 40.9991L44.4494 24.9995C45.0019 24.3087 44.89 23.3015 44.1998 22.7495C43.5095 22.197 42.5017 22.3089 41.9498 22.9991Z"
          fill="url(#paint1_linear_2210_3751)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2210_3751"
          x1="0"
          y1="32.0001"
          x2="64.0002"
          y2="32.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#448AD5" />
          <stop offset="1" stop-color="#DB2F7F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2210_3751"
          x1="20.8193"
          y1="31.9988"
          x2="44.8004"
          y2="31.9988"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#448AD5" />
          <stop offset="1" stop-color="#DB2F7F" />
        </linearGradient>
        <clipPath id="clip0_2210_3751">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  bulb: (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2135_9157)">
        <path
          d="M20 6V1"
          stroke="#1D1128"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39 20H34"
          stroke="#1D1128"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 20H1"
          stroke="#1D1128"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.2016 10.237L33.7983 6.76367"
          stroke="#1D1128"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.79836 10.237L6.20166 6.76367"
          stroke="#1D1128"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 33H24V35.5C24 37.1569 22.6569 38.5 21 38.5H19C17.3431 38.5 16 37.1569 16 35.5V33Z"
          stroke="#1D1128"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M15.2715 32.9999C15.2715 30.4371 15.4998 29 12.7211 25.9931C11.3349 24.339 10.5015 22.2073 10.5015 19.8825C10.5015 14.6173 14.7762 10.3425 20.0415 10.3425C25.3067 10.3425 29.5815 14.6173 29.5815 19.8825C29.5815 22.2073 28.748 24.339 27.364 25.9949C24.4998 29 24.8143 30.4378 24.8143 32.9999L24.8114 33L15.2715 32.9999Z"
          stroke="#1D1128"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.9658 15.2592C20.2962 15.1554 19.6125 15.1888 18.9563 15.3574C18.3 15.526 17.6848 15.8262 17.1481 16.2398C16.6114 16.6534 16.1644 17.1718 15.8342 17.7635C15.5039 18.3551 15.2974 19.0077 15.2272 19.6817"
          stroke="#1D1128"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2135_9157">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  cloud: (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2135_9175)">
        <path
          d="M34.3202 12.56C34.3215 12.4984 34.3226 12.4368 34.3226 12.3748C34.3226 7.16201 30.0968 2.93624 24.8841 2.93624C21.1709 2.93624 17.9592 5.08069 16.4183 8.19833C15.4369 7.37959 14.1745 6.88646 12.7965 6.88646C9.69851 6.88646 7.18344 9.37708 7.14117 12.465C4.03462 12.9835 1.6665 15.6836 1.6665 18.9374C1.6665 22.5617 4.60478 25.5 8.22914 25.5H32.7677C36.3921 25.5 39.3302 22.5618 39.3302 18.9374C39.3302 15.848 37.1952 13.2575 34.3202 12.56Z"
          stroke="#1D1128"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.85707 36.4622C10.2435 36.4622 11.3675 35.3382 11.3675 33.9518C11.3675 32.5653 10.2435 31.4414 8.85707 31.4414C7.47062 31.4414 6.34668 32.5653 6.34668 33.9518C6.34668 35.3382 7.47062 36.4622 8.85707 36.4622Z"
          stroke="#1D1128"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.6647 36.4622C22.0511 36.4622 23.1751 35.3382 23.1751 33.9518C23.1751 32.5653 22.0511 31.4414 20.6647 31.4414C19.2782 31.4414 18.1543 32.5653 18.1543 33.9518C18.1543 35.3382 19.2782 36.4622 20.6647 36.4622Z"
          stroke="#1D1128"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.4713 36.4622C33.8578 36.4622 34.9817 35.3382 34.9817 33.9518C34.9817 32.5653 33.8578 31.4414 32.4713 31.4414C31.0849 31.4414 29.9609 32.5653 29.9609 33.9518C29.9609 35.3382 31.0849 36.4622 32.4713 36.4622Z"
          stroke="#1D1128"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.6641 26.4199V31.4408"
          stroke="#1D1128"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.6966 32.1763L27.4507 28.9304V26.4199"
          stroke="#1D1128"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6318 32.1763L13.8778 28.9304V26.4199"
          stroke="#1D1128"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2135_9175">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(0.666504)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  influencerSuccess: (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.01562 8.70313H9.03648M31.6302 8.70313H36.6511M30.1171 3.05469L33.3781 1.17188M7.28922 1.17188L10.5502 3.05469M19.0355 3.31391C19.1374 3.05264 19.3159 2.82823 19.5474 2.67001C19.779 2.51179 20.0529 2.42713 20.3334 2.42711C20.6139 2.42703 20.888 2.51164 21.1196 2.66986C21.3513 2.82808 21.5299 3.05256 21.6319 3.31391L22.547 5.65672L25.058 5.80297C25.338 5.81931 25.6066 5.91977 25.8286 6.09119C26.0507 6.26262 26.2158 6.49704 26.3025 6.76383C26.3892 7.03062 26.3934 7.31738 26.3145 7.58659C26.2355 7.85579 26.0772 8.09489 25.8601 8.27258L23.9152 9.86672L24.5521 12.2999C24.6229 12.5714 24.6103 12.8578 24.5158 13.1219C24.4213 13.3861 24.2493 13.6156 24.0224 13.7805C23.7955 13.9454 23.5242 14.0381 23.2438 14.0462C22.9634 14.0544 22.687 13.9777 22.4509 13.8262L20.3334 12.4688L18.2164 13.8262C17.9803 13.9777 17.704 14.0544 17.4236 14.0462C17.1432 14.0381 16.8718 13.9454 16.6449 13.7805C16.4179 13.6157 16.2459 13.3862 16.1513 13.122C16.0568 12.8579 16.0443 12.5713 16.1152 12.2999L16.7523 9.86672L14.8073 8.27258C14.363 7.90859 14.1873 7.30984 14.3648 6.76383C14.5424 6.21781 15.0364 5.83625 15.6094 5.80297L18.1204 5.65672L19.0355 3.31391Z"
        stroke="#1D1128"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3335 29.1806C22.8686 29.1806 24.9238 27.1255 24.9238 24.5903C24.9238 22.0552 22.8686 20 20.3335 20C17.7983 20 15.7432 22.0552 15.7432 24.5903C15.7432 27.1255 17.7983 29.1806 20.3335 29.1806Z"
        stroke="#1D1128"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.8647 38.8284V34.6323L33.9254 28.5715C34.3917 28.1053 34.7616 27.5518 35.0139 26.9426C35.2662 26.3334 35.396 25.6805 35.3959 25.0211V17.4899C35.3959 16.1036 34.2726 14.9795 32.8855 14.9795C31.4992 14.9795 30.3751 16.1035 30.3751 17.4899L30.3745 25.0218C24.829 30.5673 15.838 30.5673 10.2925 25.0218L10.2918 17.4899C10.2918 16.1036 9.16842 14.9795 7.78139 14.9795C6.39506 14.9795 5.271 16.1035 5.271 17.4899V25.0218C5.27106 25.6809 5.40095 26.3336 5.65325 26.9425C5.90556 27.5515 6.27533 28.1048 6.74147 28.5708C8.93303 30.7624 12.8022 34.6322 12.8022 34.6322V38.8284"
        stroke="#1D1128"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Mouse: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 6V2"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 7.5C12 7.03406 12 6.80109 12.0761 6.61732C12.1776 6.37229 12.3723 6.17761 12.6173 6.07612C12.8011 6 13.0341 6 13.5 6C13.9659 6 14.1989 6 14.3827 6.07612C14.6277 6.17761 14.8224 6.37229 14.9239 6.61732C15 6.80109 15 7.03406 15 7.5V9.5C15 9.96594 15 10.1989 14.9239 10.3827C14.8224 10.6277 14.6277 10.8224 14.3827 10.9239C14.1989 11 13.9659 11 13.5 11C13.0341 11 12.8011 11 12.6173 10.9239C12.3723 10.8224 12.1776 10.6277 12.0761 10.3827C12 10.1989 12 9.96594 12 9.5V7.5Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M13.5 22C19.5 22 21 17.49 21 12C21 6.50998 19.5 2 13.5 2C7.49993 2 6 6.50996 6 12C6 17.49 7.49993 22 13.5 22Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M5 2C3.94531 3.13158 3.23544 4.50113 3 6"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  ),
  Aim: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14 2.20004C13.3538 2.06886 12.6849 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 11.3151 21.9311 10.6462 21.8 10"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12.0303 11.9624L16.5832 7.40949L12.0303 11.9624ZM19.7404 4.34451L19.1872 2.35737C19.0853 2.03 18.6914 1.89954 18.4259 2.11651C16.9898 3.29007 15.4254 4.8708 16.703 7.36408C19.2771 8.56443 20.7466 6.94573 21.8733 5.58519C22.0975 5.31449 21.9623 4.90756 21.6247 4.80994L19.7404 4.34451Z"
        fill="white"
      />
      <path
        d="M12.0303 11.9624L16.5832 7.40949M19.7404 4.34451L19.1872 2.35737C19.0853 2.03 18.6914 1.89954 18.4259 2.11651C16.9898 3.29007 15.4254 4.8708 16.703 7.36408C19.2771 8.56443 20.7466 6.94573 21.8733 5.58519C22.0975 5.31449 21.9623 4.90756 21.6247 4.80994L19.7404 4.34451Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  networkIcon: (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3325_42892)">
        <mask
          id="mask0_3325_42892"
          style={{ maskType: "luminance" + "em" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="40"
          height="40"
        >
          <path d="M0 0.000118256H39.9999V40H0V0.000118256Z" fill="white" />
        </mask>
        <g mask="url(#mask0_3325_42892)">
          <path
            d="M24.3752 11.6752L24.3717 14.2328C23.064 14.9054 21.581 15.2852 20.0093 15.2852C18.4377 15.2852 16.9549 14.9054 15.6471 14.2329L15.6436 11.6731C15.6424 10.8099 16.3475 10.106 17.2105 10.106C19.0553 10.106 20.9389 10.1082 22.7836 10.1082C23.6467 10.1082 24.3764 10.8123 24.3752 11.6752Z"
            stroke="#1D1128"
            strokeWidth="1.5"
            strokeMiterlimit="22.926"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.0081 6.91797C21.5904 6.91797 22.8809 5.62743 22.8809 4.04517C22.8809 2.46283 21.5904 1.17228 20.0081 1.17228C18.4257 1.17228 17.1353 2.46283 17.1353 4.04517C17.1353 5.62743 18.4257 6.91797 20.0081 6.91797Z"
            stroke="#1D1128"
            strokeWidth="1.5"
            strokeMiterlimit="22.926"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.90357 35.2182L9.90005 37.7758C8.5924 38.4484 7.10944 38.8281 5.53772 38.8281C3.96609 38.8281 2.4832 38.4484 1.17547 37.7759L1.17188 35.2159C1.17078 34.3528 1.87586 33.649 2.7389 33.649C4.58366 33.649 6.46725 33.6512 8.31201 33.6512C9.17506 33.6512 9.90482 34.3552 9.90357 35.2182Z"
            stroke="#1D1128"
            strokeWidth="1.5"
            strokeMiterlimit="22.926"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.53687 30.4609C7.11921 30.4609 8.40967 29.1704 8.40967 27.5881C8.40967 26.0058 7.11921 24.7153 5.53687 24.7153C3.95453 24.7153 2.66406 26.0058 2.66406 27.5881C2.66406 29.1704 3.95453 30.4609 5.53687 30.4609Z"
            stroke="#1D1128"
            strokeWidth="1.5"
            strokeMiterlimit="22.926"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M38.8281 35.2182L38.8246 37.7758C37.5169 38.4484 36.0339 38.8281 34.4623 38.8281C32.8906 38.8281 31.4078 38.4484 30.1 37.7759L30.0964 35.2159C30.0953 34.3528 30.8003 33.649 31.6635 33.649C33.5081 33.649 35.3917 33.6512 37.2365 33.6512C38.0995 33.6512 38.8293 34.3552 38.8281 35.2182Z"
            stroke="#1D1128"
            strokeWidth="1.5"
            strokeMiterlimit="22.926"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.4607 30.4609C36.043 30.4609 37.3336 29.1704 37.3336 27.5881C37.3336 26.0058 36.043 24.7153 34.4607 24.7153C32.8784 24.7153 31.5879 26.0058 31.5879 27.5881C31.5879 29.1704 32.8784 30.4609 34.4607 30.4609Z"
            stroke="#1D1128"
            strokeWidth="1.5"
            strokeMiterlimit="22.926"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.009 20V25.625"
            stroke="#1D1128"
            strokeWidth="1.5"
            strokeMiterlimit="22.926"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.1382 28.4316L20.0096 25.6191"
            stroke="#1D1128"
            strokeWidth="1.5"
            strokeMiterlimit="22.926"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24.8782 28.4297L20.0068 25.6172"
            stroke="#1D1128"
            strokeWidth="1.5"
            strokeMiterlimit="22.926"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3325_42892">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  collaborateIcon: (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3325_42913)">
        <mask
          id="mask0_3325_42913"
          style={{ maskType: "luminance" + "em" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="41"
          height="40"
        >
          <path
            d="M0.333496 0.000118256H40.3334V40H0.333496V0.000118256Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_3325_42913)">
          <path
            d="M20.3335 3.51562V1.17188"
            stroke="#1D1128"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25.021 5.85938L27.3647 3.51563"
            stroke="#1D1128"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.646 5.85938L13.3022 3.51563"
            stroke="#1D1128"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.70848 16.5625C9.70848 18.5041 8.1345 20.0781 6.19286 20.0781C4.25123 20.0781 2.67725 18.5041 2.67725 16.5625C2.67725 14.6209 4.25123 13.0469 6.19286 13.0469C8.1345 13.0469 9.70848 14.6209 9.70848 16.5625Z"
            stroke="#1D1128"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.8869 26.913L10.8803 38.8281H1.50537V27.1094C1.50537 24.5205 3.60396 22.4219 6.19286 22.4219H8.5366C13.7059 22.4219 17.9897 18.1381 17.9897 12.9688C17.9897 11.6745 19.0391 10.6251 20.3334 10.6251C21.6277 10.6251 22.6772 11.6745 22.6772 12.9688C22.6772 19.9226 17.5264 25.7914 10.8869 26.913Z"
            stroke="#1D1128"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M30.9585 16.5625C30.9585 18.5041 32.5325 20.0781 34.4741 20.0781C36.4157 20.0781 37.9897 18.5041 37.9897 16.5625C37.9897 14.6209 36.4157 13.0469 34.4741 13.0469C32.5325 13.0469 30.9585 14.6209 30.9585 16.5625Z"
            stroke="#1D1128"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.3335 20.735C22.4417 23.9057 25.8795 26.2542 29.78 26.9131L29.7866 38.8281H39.1616V27.1094C39.1616 24.5206 37.0629 22.4219 34.4741 22.4219H32.1303C26.9611 22.4219 22.6772 18.1381 22.6772 12.9688"
            stroke="#1D1128"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3325_42913">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(0.333496)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  barChart: (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3325_42932"
        style={{ maskType: "luminance" + "em" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="41"
        height="40"
      >
        <path
          d="M0.666504 0.000118256H40.6664V40H0.666504V0.000118256Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_3325_42932)">
        <path
          d="M6.66699 38.8281V1.27613H12.9431V38.8281"
          stroke="#1D1128"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.9639 38.8281V20.0001H24.2399V38.8281"
          stroke="#1D1128"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.2607 38.8281V13.7241H35.5367V38.8281"
          stroke="#1D1128"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  ),
  star: (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.40039 0L8.97199 4.83688H14.0578L9.94329 7.82624L11.5149 12.6631L7.40039 9.67376L3.28589 12.6631L4.85749 7.82624L0.742995 4.83688H5.82879L7.40039 0Z"
        fill="#FFA927"
      />
    </svg>
  ),
  logOut: (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 32.0003C2 48.5687 15.4313 62 31.9997 62C48.5682 62 62 48.5687 62 32.0003C62 15.4318 48.5682 2 31.9997 2C15.4395 2.01923 2.01923 15.4395 2 32.0003ZM58.9996 32.0003C58.9996 46.912 46.9114 59.0002 31.9997 59.0002C17.088 59.0002 4.99981 46.912 4.99981 32.0003C4.99981 17.0886 17.088 5.00036 31.9997 5.00036C46.9048 5.01684 58.9832 17.0952 58.9996 32.0003Z"
        fill="url(#paint0_linear_3325_44124)"
      />
      <path
        d="M24.668 40C26.8652 42.455 29.7805 44 33.3345 44C39.962 44 45.3345 38.6274 45.3345 32C45.3345 25.3725 39.962 20 33.3345 20C29.7805 20 26.8652 21.5451 24.668 24"
        stroke="url(#paint1_linear_3325_44124)"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.668 31.9999H30.668M18.668 31.9999C18.668 32.9335 21.327 34.6779 22.0013 35.3333M18.668 31.9999C18.668 31.0663 21.327 29.3219 22.0013 28.6666"
        stroke="url(#paint2_linear_3325_44124)"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3325_44124"
          x1="32"
          y1="62"
          x2="32"
          y2="2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#448AD5" />
          <stop offset="1" stop-color="#DB2F7F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3325_44124"
          x1="35.0012"
          y1="44"
          x2="35.0012"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#448AD5" />
          <stop offset="1" stop-color="#DB2F7F" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3325_44124"
          x1="24.668"
          y1="35.3333"
          x2="24.668"
          y2="28.6666"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#448AD5" />
          <stop offset="1" stop-color="#DB2F7F" />
        </linearGradient>
      </defs>
    </svg>
  ),
  brush: (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 9.16732L10 10.5006M8 9.16732C5.01368 10.9978 3.25448 10.3545 2 9.73452C2 11.1204 2.45415 12.2801 3.13073 13.1673M8 9.16732L10 6.10996M10 10.5006C9.854 11.8588 8.95113 14.624 6.7704 15.1673C5.56043 15.1673 4.11175 14.4537 3.13073 13.1673M10 10.5006L11.7319 7.16732M3.13073 13.1673C3.82297 13.2784 5.52005 13.3006 6.7704 12.5006M10 6.10996L12.5384 2.22957C12.7431 1.86862 13.1958 1.73089 13.5679 1.91631C13.9539 2.1086 14.1103 2.57625 13.9173 2.96084L11.7319 7.16732M10 6.10996L11.7319 7.16732"
        stroke="url(#paint0_linear_3878_25436)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 3.16602L4.14739 3.56432C4.34065 4.0866 4.43728 4.34774 4.62778 4.53824C4.81827 4.72874 5.07942 4.82537 5.6017 5.01863L6 5.16602L5.6017 5.3134C5.07942 5.50666 4.81827 5.6033 4.62778 5.7938C4.43728 5.98429 4.34065 6.24544 4.14739 6.76772L4 7.16602L3.85261 6.76772C3.65935 6.24544 3.56272 5.98429 3.37222 5.7938C3.18173 5.6033 2.92058 5.50666 2.3983 5.3134L2 5.16602L2.3983 5.01863C2.92058 4.82537 3.18173 4.72874 3.37222 4.53824C3.56272 4.34774 3.65935 4.0866 3.85261 3.56432L4 3.16602Z"
        stroke="url(#paint1_linear_3878_25436)"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3878_25436"
          x1="2"
          y1="8.50065"
          x2="14"
          y2="8.50065"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#448AD5" />
          <stop offset="1" stop-color="#DB2F7F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3878_25436"
          x1="2"
          y1="5.16602"
          x2="6"
          y2="5.16602"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#448AD5" />
          <stop offset="1" stop-color="#DB2F7F" />
        </linearGradient>
      </defs>
    </svg>
  ),
  dumbell: (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6693 5.83203C10.5067 6.31961 10.1723 6.69388 9.699 6.90372C8.17874 7.57768 7.0816 8.67481 6.40762 10.1951C6.19778 10.6684 5.82352 11.0028 5.33594 11.1653"
        stroke="url(#paint0_linear_3878_25364)"
        strokeLinejoin="round"
      />
      <path
        d="M2.13594 14.3653L1.33594 15.1653M13.8693 2.63203L14.6693 1.83203"
        stroke="url(#paint1_linear_3878_25364)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8597 2.11467L10.8516 3.08343L13.412 5.66775L14.397 4.71126C14.6927 4.36299 14.7832 4.10065 14.4152 3.61747L13.678 2.84219L12.9306 2.10499C12.4529 1.67649 12.0766 1.90705 11.8597 2.11467Z"
        stroke="url(#paint2_linear_3878_25364)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.34036 3.00439C10.0808 2.22184 10.5878 2.77879 10.8482 3.08892L13.3867 5.63756C13.7026 5.89086 14.2806 6.37683 13.5167 7.13355C13.3934 7.25566 13.2726 7.38353 13.1386 7.49373C12.644 7.9006 12.1694 7.56326 11.9178 7.2534L9.33403 4.6696C9.06196 4.42523 8.5919 3.98246 8.9889 3.39061C9.0959 3.25402 9.2211 3.13042 9.34036 3.00439Z"
        stroke="url(#paint3_linear_3878_25364)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.05894 14.9128L2.29804 14.1608L1.5754 13.3994C1.14659 12.9705 1.39398 12.5417 1.60575 12.3288L2.5938 11.3396L5.16486 13.9218L4.15288 14.9158C3.79897 15.2046 3.5563 15.2932 3.08032 14.9158M4.14954 9.78231C3.88913 9.47217 3.38204 8.91517 2.64164 9.69777C2.5224 9.82377 2.39719 9.94737 2.2902 10.084C1.8932 10.6758 2.36322 11.1186 2.63528 11.363L5.21913 13.9468C5.47063 14.2566 5.9453 14.594 6.43988 14.1871C6.57388 14.0768 6.69472 13.949 6.81798 13.8269C7.58192 13.0702 7.00392 12.5842 6.68798 12.3309L4.14954 9.78231Z"
        stroke="url(#paint4_linear_3878_25364)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3878_25364"
          x1="5.33594"
          y1="8.49869"
          x2="10.6693"
          y2="8.49869"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#448AD5" />
          <stop offset="1" stop-color="#DB2F7F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3878_25364"
          x1="1.33594"
          y1="8.49869"
          x2="14.6693"
          y2="8.49869"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#448AD5" />
          <stop offset="1" stop-color="#DB2F7F" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3878_25364"
          x1="10.8516"
          y1="3.76551"
          x2="14.6566"
          y2="3.76551"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#448AD5" />
          <stop offset="1" stop-color="#DB2F7F" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3878_25364"
          x1="8.83594"
          y1="5.1433"
          x2="13.8949"
          y2="5.1433"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#448AD5" />
          <stop offset="1" stop-color="#DB2F7F" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3878_25364"
          x1="1.33984"
          y1="12.2328"
          x2="7.19616"
          y2="12.2328"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#448AD5" />
          <stop offset="1" stop-color="#DB2F7F" />
        </linearGradient>
      </defs>
    </svg>
  ),
  phone: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3878_21448)">
        <path
          d="M2.20101 6.96673C1.648 6.00242 1.38097 5.21502 1.21997 4.41687C0.98184 3.23641 1.52678 2.0833 2.42953 1.34752C2.81107 1.03655 3.24845 1.1428 3.47406 1.54756L3.98342 2.46136C4.38715 3.18566 4.58901 3.5478 4.54897 3.93175C4.50894 4.3157 4.23669 4.62841 3.69221 5.25383L2.20101 6.96673ZM2.20101 6.96673C3.32036 8.9185 5.07697 10.6761 7.03099 11.7967L2.20101 6.96673ZM7.03099 11.7967C7.9953 12.3497 8.78269 12.6168 9.58086 12.7778C10.7613 13.0159 11.9144 12.4709 12.6502 11.5682C12.9612 11.1867 12.8549 10.7493 12.4502 10.5237L11.5364 10.0143C10.812 9.61057 10.4499 9.40874 10.066 9.44875C9.68201 9.48877 9.36929 9.76101 8.7439 10.3055L7.03099 11.7967Z"
          fill="currentColor"
        />
        <path
          d="M2.20101 6.96673C1.648 6.00242 1.38097 5.21502 1.21997 4.41687C0.98184 3.23641 1.52678 2.0833 2.42953 1.34752C2.81107 1.03655 3.24845 1.1428 3.47406 1.54756L3.98342 2.46136C4.38715 3.18566 4.58901 3.5478 4.54897 3.93175C4.50894 4.3157 4.23669 4.62841 3.69221 5.25383L2.20101 6.96673ZM2.20101 6.96673C3.32036 8.9185 5.07697 10.6761 7.031 11.7967M7.031 11.7967C7.9953 12.3497 8.78269 12.6168 9.58086 12.7778C10.7613 13.0159 11.9144 12.4709 12.6502 11.5682C12.9612 11.1867 12.8549 10.7493 12.4502 10.5237L11.5364 10.0143C10.812 9.61057 10.4499 9.40874 10.066 9.44875C9.68201 9.48877 9.36929 9.76101 8.7439 10.3055L7.031 11.7967Z"
          stroke="currentColor"
          strokeWidth="0.875"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3878_21448">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  email: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.17326 7.86109C1.21139 9.64936 1.23046 10.5434 1.89029 11.2058C2.55011 11.8681 3.46842 11.8912 5.30505 11.9373C6.43699 11.9658 7.5578 11.9658 8.68976 11.9373C10.5264 11.8912 11.4447 11.8681 12.1045 11.2058C12.7643 10.5434 12.7834 9.64936 12.8215 7.86109C12.8338 7.2861 12.8338 6.71455 12.8215 6.13956C12.7834 4.35133 12.7643 3.45721 12.1045 2.79488C11.4447 2.13254 10.5264 2.10947 8.68976 2.06333C7.5578 2.03488 6.43699 2.03488 5.30504 2.06332C3.46842 2.10946 2.55011 2.13253 1.89028 2.79487C1.23046 3.45721 1.21139 4.35132 1.17326 6.13956C1.16099 6.71455 1.161 7.2861 1.17326 7.86109Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.875"
        strokeLinejoin="round"
      />
      <path
        d="M1.16406 3.5L5.19666 5.7849C6.68333 6.62725 7.31146 6.62725 8.79815 5.7849L12.8307 3.5"
        stroke="#EDF4FC"
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  location: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.94366 12.4644C7.69073 12.7012 7.35257 12.8337 7.00064 12.8337C6.64872 12.8337 6.31062 12.7012 6.05763 12.4644C3.74093 10.2822 0.636277 7.84435 2.15032 4.30513C2.96895 2.3915 4.93403 1.16699 7.00064 1.16699C9.06728 1.16699 11.0324 2.3915 11.851 4.30513C13.3631 7.83992 10.2661 10.2897 7.94366 12.4644Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.875"
      />
      <path
        d="M9.04037 6.41667C9.04037 7.54425 8.12628 8.45833 6.9987 8.45833C5.87111 8.45833 4.95703 7.54425 4.95703 6.41667C4.95703 5.28908 5.87111 4.375 6.9987 4.375C8.12628 4.375 9.04037 5.28908 9.04037 6.41667Z"
        fill="#FCEBEF"
      />
    </svg>
  ),
  audienceThrough: (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5225 21.8308C20.4485 21.8148 20.3726 21.8119 20.2976 21.8231C19.5463 21.7356 18.9796 21.1 18.9783 20.3439C18.9783 19.9816 18.6845 19.6878 18.3222 19.6878C17.9599 19.6878 17.6661 19.9816 17.6661 20.3439C17.668 21.6065 18.5125 22.7124 19.7299 23.0466V23.7027C19.7299 24.065 20.0237 24.3588 20.386 24.3588C20.7484 24.3588 21.0421 24.065 21.0421 23.7027V23.0863C22.4473 22.7922 23.4023 21.4835 23.2546 20.0556C23.1066 18.6277 21.9036 17.5426 20.468 17.5423C19.6453 17.5423 18.9783 16.8753 18.9783 16.0526C18.9783 15.2299 19.6453 14.5629 20.468 14.5629C21.2907 14.5629 21.9577 15.2299 21.9577 16.0526C21.9577 16.4149 22.2515 16.7087 22.6139 16.7087C22.9762 16.7087 23.27 16.4149 23.27 16.0526C23.2681 14.7269 22.3393 13.5835 21.0421 13.3099V12.7128C21.0421 12.3504 20.7484 12.0566 20.386 12.0566C20.0237 12.0566 19.7299 12.3504 19.7299 12.7128V13.35C18.376 13.7197 17.5046 15.0341 17.6911 16.4252C17.8775 17.8162 19.0645 18.8545 20.468 18.8542C21.2798 18.8542 21.942 19.5043 21.9568 20.3158C21.9718 21.1272 21.3337 21.8013 20.5225 21.8308Z"
        fill="black"
      />
      <path
        d="M10.9531 18.2487C10.9531 23.5034 15.2127 27.763 20.4674 27.763C25.7221 27.763 29.9818 23.5034 29.9818 18.2487C29.9818 12.994 25.7221 8.73438 20.4674 8.73438C15.2153 8.74014 10.9592 12.9966 10.9531 18.2487ZM20.4674 10.0466C24.9975 10.0466 28.6695 13.7187 28.6695 18.2487C28.6695 22.7787 24.9975 26.4508 20.4674 26.4508C15.9374 26.4508 12.2654 22.7787 12.2654 18.2487C12.2705 13.7209 15.9397 10.0517 20.4674 10.0466Z"
        fill="black"
      />
      <path
        d="M33.2166 29.3798L28.0769 31.8082C27.3673 30.4882 26.006 29.6482 24.508 29.6059L19.7633 29.4756C19.012 29.4541 18.2749 29.2638 17.6069 28.9188L17.1241 28.6685C14.6521 27.38 11.7057 27.3832 9.2363 28.6769L9.26642 27.5831C9.27635 27.2208 8.9909 26.919 8.62856 26.9091L3.42577 26.7659C3.06343 26.7559 2.76196 27.0414 2.75203 27.4037L2.43775 38.821C2.42782 39.1834 2.71327 39.4852 3.07561 39.4951L8.2784 39.6383H8.29634C8.65195 39.6383 8.94284 39.3554 8.95245 39.0001L8.96751 38.4539L10.3191 37.7302C10.8497 37.4447 11.4699 37.3768 12.0495 37.5408L20.1208 39.8062C20.1349 39.8103 20.149 39.8135 20.1634 39.8167C20.7468 39.9388 21.3414 40 21.9373 39.9997C23.1996 40.0003 24.4471 39.7283 25.5943 39.2016C25.6225 39.1888 25.6498 39.1738 25.6757 39.1568L37.3785 31.5871C37.67 31.3984 37.7642 31.0156 37.5934 30.7131C36.7246 29.1715 34.7976 28.5843 33.2166 29.3798ZM3.7676 38.2014L4.04568 28.0957L7.93656 28.2027L7.65848 38.3088L3.7676 38.2014ZM25.0033 38.0297C23.5779 38.673 21.986 38.8505 20.454 38.5375L12.4044 36.2779C11.4984 36.022 10.5287 36.128 9.69987 36.5736L9.00916 36.9437L9.19401 30.2246C11.3828 28.7688 14.1898 28.6192 16.5208 29.8337L17.0036 30.0839C17.8475 30.5196 18.7782 30.7599 19.7274 30.7875L24.4724 30.9179C25.8051 30.9563 26.937 31.9052 27.2074 33.2111L20.1397 33.016C19.7774 33.006 19.4759 33.2918 19.4657 33.6538C19.4557 34.0161 19.7415 34.3179 20.1035 34.3279L27.9032 34.5425H27.9215C28.2768 34.5422 28.5674 34.2593 28.577 33.9043C28.585 33.6192 28.5638 33.3338 28.5135 33.0531L33.7852 30.5622C33.7903 30.56 33.7955 30.5574 33.8006 30.5549C34.5483 30.1765 35.4524 30.3031 36.0675 30.8724L25.0033 38.0297Z"
        fill="black"
      />
      <path
        d="M21.1247 6.88921V0.656115C21.1247 0.293778 20.831 0 20.4686 0C20.1063 0 19.8125 0.293778 19.8125 0.656115V6.88921C19.8125 7.25155 20.1063 7.54533 20.4686 7.54533C20.831 7.54533 21.1247 7.25155 21.1247 6.88921Z"
        fill="black"
      />
      <path
        d="M26.0447 6.88989V3.93737C26.0447 3.57503 25.7509 3.28125 25.3885 3.28125C25.0262 3.28125 24.7324 3.57503 24.7324 3.93737V6.88989C24.7324 7.25222 25.0262 7.546 25.3885 7.546C25.7509 7.546 26.0447 7.25222 26.0447 6.88989Z"
        fill="black"
      />
      <path
        d="M16.2029 6.88989V3.93737C16.2029 3.57503 15.9091 3.28125 15.5467 3.28125C15.1844 3.28125 14.8906 3.57503 14.8906 3.93737V6.88989C14.8906 7.25222 15.1844 7.546 15.5467 7.546C15.9091 7.546 16.2029 7.25222 16.2029 6.88989Z"
        fill="black"
      />
    </svg>
  ),
  doubleTickIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      width="18"
      height="18"
    >
      <path d="M11.602 13.7599L13.014 15.1719L21.4795 6.7063L22.8938 8.12051L13.014 18.0003L6.65 11.6363L8.06421 10.2221L10.189 12.3469L11.6025 13.7594L11.602 13.7599ZM11.6037 10.9322L16.5563 5.97949L17.9666 7.38977L13.014 12.3424L11.6037 10.9322ZM8.77698 16.5873L7.36396 18.0003L1 11.6363L2.41421 10.2221L3.82723 11.6352L3.82604 11.6363L8.77698 16.5873Z"></path>
    </svg>
  ),
  packageOpenBoxIcon: (
    <svg
      width="60"
      height="60"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.5 44.5C22.8636 44.5 21.3004 43.8292 18.1739 42.4878C10.3913 39.1486 6.5 37.479 6.5 34.6706V15.995M24.5 44.5C26.1364 44.5 27.6996 43.8292 30.8262 42.4878C38.6088 39.1486 42.5 37.479 42.5 34.6706V15.995M24.5 44.5V24.8414M6.5 15.995C6.5 17.2025 8.10314 17.965 11.3094 19.49L17.1518 22.2688C20.7576 23.9838 22.5606 24.8414 24.5 24.8414M6.5 15.995C6.5 14.7875 8.10314 14.025 11.3094 12.5M42.5 15.995C42.5 17.2025 40.8968 17.965 37.6906 19.49L31.8482 22.2688C28.2424 23.9838 26.4394 24.8414 24.5 24.8414M42.5 15.995C42.5 14.7875 40.8968 14.025 37.6906 12.5M13.1641 27.122L17.1518 29.0188"
        stroke="url(#paint0_linear_4989_14305)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.5 4.5V8.5M32.5 6.5L29.5 10.5M16.5 6.5L19.5 10.5"
        stroke="url(#paint1_linear_4989_14305)"
        stroke-width="2"
        stroke-linecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4989_14305"
          x1="6.5"
          y1="28.5"
          x2="42.5"
          y2="28.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#448AD5" />
          <stop offset="1" stop-color="#DB2F7F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4989_14305"
          x1="16.5"
          y1="7.5"
          x2="32.5"
          y2="7.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#448AD5" />
          <stop offset="1" stop-color="#DB2F7F" />
        </linearGradient>
      </defs>
    </svg>
  ),
  closeIcon: (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.91539 1.08398L1.08203 6.91733M1.08203 1.08398L6.91539 6.91733"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};
