import React from "react";
import { authBannerOne } from "../../../constants/imageData";
import { Form } from "antd";
import { ILTextInput, ILButton, ILHead, ILLoader } from "../../../components";
import { ArrowLeft02Icon, Mail01Icon } from "hugeicons-react";
import { Link } from "react-router-dom";
import { useForgotPassword } from "../../../hooks/auth/forgotPassword.hook";

const ILForgotPassword = () => {
  const {
    isLoading,
    passwordData,
    validateMessages,
    handleInputChange,
    handleSendOtpSubmit,
  } = useForgotPassword();

  return (
    <>
      <ILHead title="Glömt ditt lösenord?" />
      <section className="authentication-section">
        <div className="authentication-flex d-flex flex-wrap">
          <div className="authentication-col d-flex overflow-y-auto order-2 order-lg-1 py-5 py-xl-4">
            <div className="m-auto authentication-sm-box">
              <Link to="/login" className="auth-back-arrow d-inline-flex">
                <ArrowLeft02Icon
                  size={35}
                  color={"#1D1128"}
                  variant={"stroke"}
                  strokeWidth={1.5}
                  className="svg-hover"
                />
              </Link>
              <h1 className="secondary-title md-secondary-title">
                Glömt ditt lösenord?
              </h1>
              <p className="paragraph-sm-text paragraph-md-text">
                Ange din e-postadress för att få en länk för återställning av
                lösenord.
              </p>
              <Form>
                <ILTextInput
                  isRequiredInput
                  prefix={
                    <Mail01Icon
                      size={24}
                      color={"#1D1128"}
                      variant={"stroke"}
                      strokeWidth={1.5}
                    />
                  }
                  id="EPostadress"
                  datatestid="EPostadress"
                  type="email"
                  label="E-postadress"
                  placeholder={"Ange din e-postadress"}
                  name="email"
                  value={passwordData?.email}
                  handleChange={handleInputChange}
                  errorMsg={validateMessages?.email}
                />
                <ILButton
                  disabled={isLoading}
                  handleClick={handleSendOtpSubmit}
                  ILBtnClass="w-100"
                  variant={"primary-gradient"}
                >
                  {isLoading ? <ILLoader isBtn /> : "Skicka kod"}
                </ILButton>
                <p className="p sm fw-medium text-gray text-center">
                  <Link to="/login">Tillbaka till login</Link>
                </p>
              </Form>
            </div>
          </div>
          <div className="authentication-col bg-dark authentication-right-col order-1 order-lg-2 mb-5 mb-lg-0">
            <img
              src={authBannerOne}
              alt="advertising agency | ilonsi"
              className="w-100 h-100 object-fit-cover"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default ILForgotPassword;
