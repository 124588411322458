import { axiosApi } from "../../axios";

export const doFetchLogin = async (data) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: "v1/influencer/login",
      data: data,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchSendOtp = async (data) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: "v1/send-otp",
      data: data,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchVerifyOtp = async (data) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: "v1/verify-otp",
      data: data,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchResetPassword = async (data) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: "v1/reset-password",
      data: data,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchDeleteAccount = async () => {
  try {
    const response = await axiosApi({
      method: "delete",
      url: "v1/influencer/delete/account",
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchInfluencerDetails = async () => {
  try {
    const response = await axiosApi({
      method: "get",
      url: "v1/user/details",
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchSkipExploreSite = async (data) => {
  try {
    const response = await axiosApi({
      method: "put",
      url: "v1/explore/site",
      data: data,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchCountryList = async () => {
  try {
    const response = await axiosApi({
      method: "get",
      url: "v1/country/list",
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchInfluencerRegister = async (data) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: "v1/influencer/create",
      data: data,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchSocialMediaChannelList = async () => {
  try {
    const response = await axiosApi({
      method: "get",
      url: "v1/admin/channel/list",
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchProductCategories = async () => {
  try {
    const response = await axiosApi({
      method: "get",
      url: "v1/product-category/list",
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchServiceCategories = async () => {
  try {
    const response = await axiosApi({
      method: "get",
      url: "v1/service-category/list",
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchInfluencerProfileUpdate = async (data) => {
  try {
    const response = await axiosApi({
      method: "put",
      url: "v1/influencer/update-profile",
      data: data,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchCities = async (countryId) => {
  try {
    const response = await axiosApi({
      method: "get",
      url: `v1/country/cities?countryId=${countryId}`,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchUsernameAvailableCheck = async (data) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: "v1/check/available/username",
      data: data,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchSubscriber = async (data) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: "v1/subscriber",
      data: data,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};
