import clsx from "clsx";
import styles from "./ComingSoonPage.module.css";
import { comingSoon } from "../../constants/imageData";
import { useNavigate } from "react-router-dom";
import { ILButton } from "../../components";

const ComingSoonPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <section
        className={clsx(
          styles.comingSoonWrap,
          "vh-100 d-flex justify-content-center align-items-center flex-column py-5"
        )}
      >
        <img src={comingSoon} alt="coming soon" />
        <ILButton
          variant={"primary-gradient"}
          handleClick={() => navigate("/")}
        >
          Tillbaka till Hem
        </ILButton>
      </section>
    </>
  );
};

export default ComingSoonPage;
