import React, { createContext, useState } from "react";

export const ILModalFlowContext = createContext();

export const ILModalFlowProvider = ({ children }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <ILModalFlowContext.Provider value={{ show, setShow }}>
        {children}
      </ILModalFlowContext.Provider>
    </>
  );
};
export default ILModalFlowProvider;
