import React from "react";
import {
  ILTextInput,
  ILTab,
  ILButton,
  ILModal,
  ILProductCard,
  ILRadioButton,
  ILDashboardHeader,
  ILModalFlow,
  ILHead,
  ILLoader,
  ILReadMoreLess,
  ILSelect,
  ILMSGProductCard,
  ILSwiper,
} from "../../../components";
import {
  Alert02Icon,
  ArrowLeft02Icon,
  ArrowRight01Icon,
  Delete02Icon,
  Delete03Icon,
  EyeIcon,
  MultiplicationSignIcon,
  Search01Icon,
  SentIcon,
  SmileIcon,
  Tick02Icon,
  UnavailableIcon,
  UserMultiple02Icon,
} from "hugeicons-react";
import clsx from "clsx";
import {
  msgPreviewProductBreakPoints,
  reportMessageRadioList,
} from "../../../constants/data";
import { gradientCircleRound, ilonsiLogo } from "../../../constants/imageData";
import { SwiperSlide } from "swiper/react";
import { useChatHook } from "../../../hooks/dashboard/messaging/useChat.hook";
import { capitalize } from "lodash";
import {
  countHandler,
  formatTime,
  getMessageDateLabel,
  isEmpty,
} from "../../../services";
import { Col, Row } from "antd";
import { InView } from "react-intersection-observer";
import { ILCommunityGuidelines } from "./ILCommunityGuidelines";
import styles from "./ILMessaging.module.css";

const ILMessaging = () => {
  const {
    currentId,
    isLoading,
    userDetails,
    chatUserList,
    selectedRoom,
    reportMessage,
    scrollToBottom,
    collectionList,
    isModalLoading,
    isChatListOpen,
    paginationData,
    collectionData,
    isButtonLoading,
    viewProductData,
    sendMessageData,
    validateMessages,
    isDeleteChatModal,
    usersRoomChatList,
    isBlockHandleModal,
    selectedProductData,
    customRequestTabData,
    isReportMessageModal,
    isPreviewProductModal,
    isComGuidelinesModalOpen,
    ref,
    setCurrentId,
    handleMessage,
    observerScroll,
    fetchDeleteChat,
    addToShopHandle,
    setChatListOpen,
    handleViewDetail,
    handleViewInShop,
    handleModalClose,
    handleInputChange,
    handleSelectChange,
    setDeleteChatModal,
    sendMessageHandler,
    fetchReportMessage,
    handleAcceptRequest,
    handleRejectRequest,
    setBlockHandleModal,
    handleDeleteChatModal,
    setReportMessageModal,
    handleSubmitCollection,
    handleProductAddToShop,
    setPreviewProductModal,
    handleBlockSenderModal,
    handleReportMessageModal,
    handleInputMessageKeyDown,
    showComGuidelinesModal,
    handleComGuidelinesModalOk,
    handleComGuidelinesModalCancel,
  } = useChatHook();

  const steps = [
    {
      id: "previewProduct",
      title: `Preview ${
        viewProductData?.product_request?.collection_details !== null
          ? "Collection"
          : paginationData?.type === "PRODUCT"
          ? "Product"
          : "Service"
      }`,
      content: (
        <>
          {viewProductData?.product_request?.collection_details === null ? (
            <div className="w-100">
              <ILProductCard
                noButtons
                isService={
                  viewProductData?.product_request?.product_details === null
                    ? true
                    : false
                }
                brandRequest
                productDataShow={viewProductData}
              />
            </div>
          ) : (
            <div className="is-need-default-swiper-wrap">
              <ILSwiper
                className="mt-0"
                autoplay={false}
                loop={false}
                breakpoints={msgPreviewProductBreakPoints}
                grabCursor={true}
                navigation={true}
                isNeedDefaultSwiperButton
              >
                <SwiperSlide>
                  <ILMSGProductCard
                    ILProductGalleryCardData={viewProductData}
                    classname={clsx(styles.msgProductImgCardWrap)}
                  />
                </SwiperSlide>
              </ILSwiper>
            </div>
          )}
          <div className="d-flex gap-3 justify-content-between modal-more-btn mt-4">
            {viewProductData?.product_request?.status === "PENDING" &&
              viewProductData?.is_added_to_shop === 0 && (
                <>
                  {" "}
                  <ILButton
                    disabled={
                      selectedProductData?.id === viewProductData?.id &&
                      isButtonLoading?.acceptBtn
                    }
                    ILBtnClass={"w-100 min-w-auto"}
                    variant={"primary-gradient"}
                    handleClick={() => {
                      handleAcceptRequest(viewProductData, "accept");
                    }}
                    isSmallSizeBtn
                    id={"acceptedCustomMessage"}
                  >
                    {selectedProductData?.id === viewProductData?.id &&
                    isButtonLoading?.acceptBtn ? (
                      <ILLoader isBtn />
                    ) : (
                      <>
                        <span className="me-2 d-inline-block">
                          <Tick02Icon
                            size={20}
                            color={"var(--clr-white)"}
                            variant={"stroke"}
                          />
                        </span>{" "}
                        Acceptera
                      </>
                    )}
                  </ILButton>
                  <ILButton
                    disabled={isButtonLoading?.rejectBtn}
                    ILBtnClass={"w-100 min-w-auto"}
                    variant={"gradient-bordered"}
                    handleClick={() =>
                      handleRejectRequest(viewProductData, "reject")
                    }
                    isSmallSizeBtn
                  >
                    {isButtonLoading?.rejectBtn ? (
                      <ILLoader isBtn />
                    ) : (
                      <>
                        <span className="me-2 d-inline-block">
                          <MultiplicationSignIcon
                            size={20}
                            color={"var(--clr-pink)"}
                            variant={"stroke"}
                          />
                        </span>
                        Avisa
                      </>
                    )}
                  </ILButton>
                </>
              )}

            {viewProductData?.product_request?.status === "ACCEPTED" &&
              ((viewProductData?.product_request?.service_details &&
                viewProductData?.product_request?.is_added_to_shop === 0) ||
                (viewProductData?.product_request?.collection_details &&
                  viewProductData?.product_request?.is_added_to_shop === 0) ||
                viewProductData?.product_request?.product_details) && (
                <ILButton
                  handleClick={() => addToShopHandle(viewProductData)}
                  ILBtnClass={"w-100 min-w-auto"}
                  variant={"gradient-bordered"}
                >
                  <span className="me-2 d-inline-block"></span>
                  Lägg till i butik
                </ILButton>
              )}
          </div>
        </>
      ),
      width: "401px",
    },
    {
      id: "acceptedCustomMessage",
      content: (
        <>
          <div className="d-flex align-items-center justify-content-center mb-5">
            <img
              width={"64px"}
              height={"64px"}
              src={selectedRoom?.other_user?.room_member?.profile_image}
              alt="advertising agency | ilonsi"
              className="rounded-circle"
            />
          </div>
          <h4 className="mb-2 fw-semibold text-center">Godkänn förfrågan!</h4>
          <p className="paragraph-sm-text paragraph-text-16 text-center mb-0">
            Godkänd! Lägg nu till den här produkten i din butik.
          </p>
          <div className="mt-5">
            <ILButton
              handleClick={() => addToShopHandle(selectedProductData)}
              id={"addToShop"}
              ILBtnClass={"w-100"}
              variant={"primary-gradient"}
            >
              Lägg till i butik
            </ILButton>
          </div>
        </>
      ),
      width: "518px",
    },
    {
      id: "addToShop",
      title: "Lägg till i butik som",
      prevBtn: (
        <span
          id="acceptedCustomMessage"
          role={"button"}
          className="cursor-pointer hover-text"
          onClick={(e) => prev(e)}
        >
          <ArrowLeft02Icon size={28} color={"#000000"} variant={"stroke"} />
        </span>
      ),
      content: (
        <>
          {selectedProductData?.is_added_to_shop === 0 && (
            <div className="d-flex align-items-center justify-content-between gap-4 mb-5 mb-xxl-2">
              <p className="big-initial fw-medium py-3 mb-0 text-ellipsis line-clamp-3">
                Lägg till som enskild produkt
              </p>
              {isModalLoading ? (
                <ILLoader isChatLoader />
              ) : (
                <div
                  onClick={handleProductAddToShop}
                  id="productAdded"
                  role={"button"}
                  className={styles.roundCircleBtn}
                >
                  <ArrowRight01Icon
                    size={20}
                    color={"currentcolor"}
                    variant={"stroke"}
                  />
                </div>
              )}
            </div>
          )}

          <div className="d-flex align-items-center justify-content-between gap-4">
            <p className="big-initial fw-medium py-3 mb-0 text-ellipsis line-clamp-3">
              Skapa en kollektion
            </p>
            <div
              onClick={(e) => next(e)}
              id="createCollection"
              role={"button"}
              className={styles.roundCircleBtn}
            >
              <ArrowRight01Icon
                size={20}
                color={"currentcolor"}
                variant={"stroke"}
              />
            </div>
          </div>
        </>
      ),
      width: "444px",
    },
    {
      id: "productAdded",
      content: (
        <>
          <div className="text-center d-flex flex-column gap-5">
            <span className="lh-0">
              <img
                src={gradientCircleRound}
                fluid
                alt="advertising agency | ilonsi"
              />
            </span>
            <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
              Din{" "}
              {paginationData?.type === "SERVICE"
                ? "service"
                : `${
                    selectedProductData?.product_request?.product_details ===
                    null
                      ? "kollektion"
                      : "produkt"
                  }`}{" "}
              har lagts till!
            </h4>
            <ILButton
              handleClick={handleViewInShop}
              ILBtnClass={"w-100"}
              variant={"primary-gradient"}
            >
              Visa i min butik
            </ILButton>
          </div>
        </>
      ),
      width: "518px",
    },
    {
      id: "alreadyAdded",
      content: (
        <>
          <div className="text-center d-flex flex-column gap-5">
            <span className="lh-0">
              <img
                src={gradientCircleRound}
                fluid
                alt="advertising agency | ilonsi"
              />
            </span>
            <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
              {paginationData?.type === "SERVICE"
                ? "Tjänsten"
                : `${
                    selectedProductData?.product_request?.product_details ===
                    null
                      ? "Kollektion"
                      : "Produkter"
                  }`}{" "}
              har redan lagts till!
            </h4>
            <ILButton
              handleClick={handleViewInShop}
              ILBtnClass={"w-100"}
              variant={"primary-gradient"}
            >
              Visa i min butik
            </ILButton>
          </div>
        </>
      ),
      width: "518px",
    },
    {
      id: "rejectProduct",
      content: (
        <>
          <div className="text-center d-flex flex-column gap-5">
            <span className="lh-0">
              <img
                src={gradientCircleRound}
                fluid
                alt="advertising agency | ilonsi"
              />
            </span>
            <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
              Din {paginationData?.type === "SERVICE" ? "tjänste" : "produkt"}{" "}
              tackade nej!
            </h4>
            <ILButton
              handleClick={() => setPreviewProductModal(false)}
              ILBtnClass={"w-100"}
              variant={"primary-gradient"}
            >
              Okej
            </ILButton>
          </div>
        </>
      ),
      width: "518px",
    },
    {
      id: "createCollection",
      title: "Skapa kollektion",
      prevBtn: (
        <span
          id="addToShopAs"
          role={"button"}
          className="cursor-pointer hover-text"
          onClick={(e) => prev(e)}
        >
          <ArrowLeft02Icon size={28} color={"#000000"} variant={"stroke"} />
        </span>
      ),
      content: (
        <>
          <div className="d-flex align-items-center justify-content-between gap-4 mb-5 mb-xxl-2">
            <p className="big-initial fw-medium py-3 mb-0 text-ellipsis line-clamp-3">
              Skapa en ny kollektion
            </p>
            <div
              onClick={(e) => next(e)}
              id="addNewCollection"
              role={"button"}
              className={"round-circle-btn"}
            >
              <ArrowRight01Icon
                size={20}
                color={"currentcolor"}
                variant={"stroke"}
              />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between gap-4">
            <p className="big-initial fw-medium py-3 mb-0 text-ellipsis line-clamp-3">
              Lägg till i befintlig kollektion{" "}
            </p>
            <div
              onClick={(e) => next(e)}
              id="addExistingCollection"
              role={"button"}
              className={"round-circle-btn"}
            >
              <ArrowRight01Icon
                size={20}
                color={"currentcolor"}
                variant={"stroke"}
              />
            </div>
          </div>
        </>
      ),
      width: "444px",
    },
    {
      id: "addNewCollection",
      title: "Lägg till ny kollektion",
      prevBtn: (
        <span
          id="createCollection"
          role={"button"}
          className="cursor-pointer hover-text"
          onClick={(e) => prev(e)}
        >
          <ArrowLeft02Icon size={28} color={"#000000"} variant={"stroke"} />
        </span>
      ),
      content: (
        <>
          <div className="mb-5">
            <ILTextInput
              isRequiredInput
              ILInputLabelClass={"mb-0"}
              id="collectionName"
              datatestid="collectionName"
              type="text"
              name="name"
              value={collectionData?.name}
              label="Kollektionens namn"
              placeholder={"Ange kollektionens namn"}
              handleChange={handleInputChange}
              errorMsg={validateMessages?.name}
            />
          </div>
          <div className="d-flex align-items-center justify-content-between mb-5">
            <p className="big fw-medium mb-0">Produkter</p>
          </div>

          <div className="mb-5">
            <Row gutter={24} className="align-items-center pb-3 pb-xxl-0 mb-4">
              <Col xs={24} xxl={16}>
                <ILTextInput
                  ILInputLabelClass={"mb-0"}
                  id="link1"
                  datatestid="link1"
                  type="text"
                  label={
                    <div className="d-flex align-items-center gap-3 gap-xxl-2">
                      <div className="order-2">Link</div>
                    </div>
                  }
                  name="link 1"
                  placeholder={"Länk 1"}
                  value={
                    selectedProductData?.product_request?.product_details?.link
                  }
                  disabled
                />
              </Col>
              <Col xs={24} xxl={8} className={clsx(styles.payPerClickSellWrap)}>
                <div className="d-flex gap-3">
                  <div>
                    <span className="paragraph-sm-text fw-medium mb-4 d-block">
                      CPC
                    </span>
                    <p className="fw-semibold mb-0 sm">
                      {
                        selectedProductData?.product_request?.product_details
                          ?.ppc_price
                      }{" "}
                      KR
                    </p>
                  </div>
                  {!isEmpty(
                    selectedProductData?.product_request?.product_details
                      ?.sell_commission
                  ) && (
                    <div className="ms-auto">
                      <span className="paragraph-sm-text fw-medium mb-4 d-block">
                        Provision
                      </span>

                      <p className="fw-semibold mb-0 sm">
                        {selectedProductData?.product_request?.product_details
                          ?.sell_commission + " %"}
                      </p>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <ILButton
            handleClick={(e) => handleSubmitCollection(e)}
            id={"collectionCreated"}
            ILBtnClass={"w-100"}
            variant={"primary-gradient"}
          >
            Lägg till ny kollektion
          </ILButton>
        </>
      ),
      width: "636px",
    },
    {
      id: "addExistingCollection",
      title: "Lägg till i befintlig kollektion",
      prevBtn: (
        <span
          id="createCollection"
          role={"button"}
          className="cursor-pointer hover-text"
          onClick={(e) => prev(e)}
        >
          <ArrowLeft02Icon size={28} color={"#000000"} variant={"stroke"} />
        </span>
      ),
      content: (
        <>
          {isModalLoading && <ILLoader classname="loader-wrap-absolute" />}

          <div className="mb-5">
            <ILSelect
              isRequired
              ILSelectLabelClass={"mb-0"}
              label="Välj kollektion"
              id="selectCollection"
              datatestid="selectCollection"
              options={collectionList}
              placeholder={"Välj kollektion"}
              name="id"
              value={collectionData?.id}
              handleChange={(event) => handleSelectChange(event, "id")}
              errorMsg={validateMessages?.id}
            />
          </div>
          <div className="d-flex align-items-center justify-content-between mb-5">
            <p className="big fw-medium mb-0">Products</p>
          </div>
          <div className="mb-5">
            <Row gutter={24} className="align-items-center pb-3 pb-xxl-0 mb-4">
              <Col xs={24} xxl={16}>
                <ILTextInput
                  ILInputLabelClass={"mb-0"}
                  id="link1"
                  datatestid="link1"
                  type="text"
                  label={
                    <div className="d-flex align-items-center gap-3 gap-xxl-2">
                      <div className="order-2">Länk 1</div>
                    </div>
                  }
                  name="link 1"
                  placeholder={"Länk 1"}
                  value={
                    selectedProductData?.product_request?.product_details?.link
                  }
                  disabled
                />
              </Col>
              <Col xs={24} xxl={8} className={clsx(styles.payPerClickSellWrap)}>
                <div className="d-flex gap-3">
                  <div>
                    <span className="paragraph-sm-text fw-medium mb-4 d-block">
                      CPC
                    </span>
                    <p className="fw-semibold mb-0 sm">
                      {
                        selectedProductData?.product_request?.product_details
                          ?.ppc_price
                      }{" "}
                      KR
                    </p>
                  </div>
                  {!isEmpty(
                    selectedProductData?.product_request?.product_details
                      ?.sell_commission
                  ) && (
                    <div className="ms-auto">
                      <span className="paragraph-sm-text fw-medium mb-4 d-block">
                        Provision
                      </span>

                      <p className="fw-semibold mb-0 sm">
                        {selectedProductData?.product_request?.product_details
                          ?.sell_commission + " %"}
                      </p>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <ILButton
            id={"yourProductAdded"}
            ILBtnClass={"w-100"}
            variant={"primary-gradient"}
            handleClick={(e) => handleSubmitCollection(e)}
          >
            Lägg till i befintlig kollektion
          </ILButton>
        </>
      ),
      width: "636px",
    },
    {
      id: "collectionCreated",
      title: "",
      content: (
        <>
          <div className="text-center d-flex flex-column gap-5">
            <span className="lh-0">
              <img
                src={gradientCircleRound}
                fluid
                alt="advertising agency | ilonsi"
              />
            </span>
            <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
              Din kollektion har skapats och produkterna har lagts till!
            </h4>
            <ILButton
              ILBtnClass={"w-100"}
              variant={"primary-gradient"}
              handleClick={handleViewInShop}
            >
              Visa i min butik
            </ILButton>
          </div>
        </>
      ),
      width: "518px",
    },
    {
      id: "collectionUpdated",
      title: "",
      content: (
        <>
          <div className="text-center d-flex flex-column gap-5">
            <span className="lh-0">
              <img
                src={gradientCircleRound}
                fluid
                alt="advertising agency | ilonsi"
              />
            </span>
            <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
              Products added to your collection !
            </h4>
            <ILButton
              ILBtnClass={"w-100"}
              variant={"primary-gradient"}
              handleClick={handleViewInShop}
            >
              Visa i min butik
            </ILButton>
          </div>
        </>
      ),
      width: "518px",
    },
  ];

  const prev = (e) => {
    const currentIndex = steps.findIndex(
      (step) => step?.id === e?.currentTarget?.id
    );
    setCurrentId(steps[currentIndex]?.id);
  };

  const next = (e) => {
    const currentIndex = steps.findIndex(
      (step) => step.id === e.currentTarget.id
    );
    setCurrentId(steps[currentIndex].id);
  };

  const currentStep = steps.find((step) => step.id === currentId);
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <>
      <ILHead title="Meddelanden" />
      <ILDashboardHeader
        extra={true}
        title="Meddelanden"
        breadCrumbItems={[
          { title: "Hem", href: "/dashboard" },
          { title: "Meddelanden" },
        ]}
      />
      <div className={clsx(styles.ILMessaging, "h-100")}>
        <div
          className={clsx(styles.requestsSide, {
            [styles.requestsSideOpen]: isChatListOpen,
          })}
        >
          <div className="px-4 mb-5">
            <ILTextInput
              ILInputLabelClass={"mb-0"}
              type={"search"}
              prefix={
                <Search01Icon
                  size={20}
                  color={"var(--clr-dark-purple)"}
                  variant={"stroke"}
                />
              }
              placeholder={"Sök här"}
              allowClear
              name="search"
              value={paginationData?.search}
              handleChange={handleInputChange}
            />
          </div>
          <div
            className={clsx(
              "mb-3 d-flex justify-content-between gap-2",
              styles.chatRequestTitle
            )}
          >
            <p className={clsx("fw-semibold mb-0")}>{`Förfrågningar ${
              chatUserList?.totalRequest > 0
                ? `(${countHandler(chatUserList?.totalRequest)})`
                : ""
            }`}</p>
            <span
              onClick={() => {
                setChatListOpen(true);
              }}
              role={"button"}
              className={styles.chatListBackArrow}
            >
              <ArrowLeft02Icon
                size={24}
                color={"var(--dark-purple)"}
                variant={"stroke"}
              />
            </span>
          </div>
          <div
            className={"h-100 d-flex align-items-center w-100"}
            onClick={() => {
              setChatListOpen(true);
            }}
          >
            <ILTab
              defaultActiveKey={paginationData?.type}
              tabItem={customRequestTabData}
              isSecondaryTabStyles
              tabClassName={"custom-request-tab h-100 w-inherit"}
              onChange={(event) => handleSelectChange(event, "type")}
            />
          </div>
        </div>
        <div
          className={clsx(
            !isChatListOpen && styles.chatRoomReqSideOpen,
            styles.chatRoomContainer
          )}
        >
          {Object?.keys(selectedRoom)?.length > 0 ? (
            <div className="w-100 h-100">
              <div className={styles.chatRoomHeader}>
                <div className="d-flex align-items-center gap-3">
                  <div
                    className={clsx(
                      styles.headerProfileImg,
                      "bg-clr-black-gray rounded-circle ratio ratio-1x1 ratio-w-100"
                    )}
                  >
                    <img
                      className={
                        "radius-inherit object-fit-cover w-100 h-100 max-w-100"
                      }
                      src={selectedRoom?.other_user?.room_member?.profile_image}
                      alt="advertising agency | ilonsi"
                    />
                  </div>
                  <h5 className="fw-semibold mb-0 sm text-ellipsis line-clamp-2 word-break-word">
                    {selectedRoom?.other_user?.room_member?.name}
                  </h5>
                </div>
                <div
                  className={clsx(
                    "d-flex align-items-center gap-5 gap-xl-4",
                    styles.actionBtnsWrap
                  )}
                >
                  <span
                    onClick={() => {
                      setReportMessageModal(true);
                    }}
                    role={"button"}
                  >
                    <Alert02Icon
                      size={24}
                      color={"var(--clr-gray)"}
                      variant={"stroke"}
                    />
                  </span>
                  <span
                    onClick={() => {
                      setBlockHandleModal(true);
                    }}
                    role={"button"}
                  >
                    <UnavailableIcon
                      size={24}
                      color={"var(--clr-gray)"}
                      variant={"stroke"}
                    />
                  </span>
                  <span
                    onClick={() => {
                      setDeleteChatModal(true);
                    }}
                    role={"button"}
                  >
                    <Delete03Icon
                      size={24}
                      color={"var(--clr-tomato)"}
                      variant={"stroke"}
                    />
                  </span>
                </div>
              </div>
              <div className={clsx(styles.chatRoom, "position-relative")}>
                <div
                  onClick={() => {
                    setChatListOpen(false);
                  }}
                  role={"button"}
                  className={clsx(
                    styles.chatListOpenIcon,
                    "bg-white rounded-circle p-3"
                  )}
                >
                  <UserMultiple02Icon
                    size={24}
                    color={"var(--clr-pink)"}
                    variant={"stroke"}
                  />
                </div>
                <div className={styles.chatTransactionContainer}>
                  {usersRoomChatList?.currentPage <
                    usersRoomChatList?.totalPages && (
                    <InView
                      as="div"
                      onChange={(inView, entry) =>
                        inView && observerScroll(entry, "chat")
                      }
                    >
                      <div
                        ref={ref}
                        className="w-100 h-100 text-center position-relative py-5"
                      >
                        <div className="w-100 h-100">
                          <ILLoader
                            classname={"loader-wrap-absolute bg-transparent"}
                            isLoaderBgOpacitySm
                            isChatLoader
                          />
                        </div>
                      </div>
                    </InView>
                  )}
                  {usersRoomChatList?.rows?.map((data, index) => {
                    const messageDateLabel =
                      index === 0 ||
                      getMessageDateLabel(data.created_at) !==
                        getMessageDateLabel(
                          usersRoomChatList.rows[index - 1]?.created_at
                        )
                        ? getMessageDateLabel(data.created_at)
                        : null;
                    return (
                      <>
                        {messageDateLabel && (
                          <p className="sm fw-medium text-gray text-center">
                            {messageDateLabel}
                          </p>
                        )}
                        {userDetails?.userData?.id === data?.from_fk ? (
                          <div
                            className={clsx(
                              styles.rightSideShowMsg,
                              styles.chatWrap
                            )}
                          >
                            <div className={styles.chatContainer}>
                              <ILReadMoreLess
                                text={data?.message}
                                sliceLength={200}
                                sliceClassName="custom-text-class text-tomato"
                                className="big text-white custom-text-class word-break-word"
                                onClick={(e) => e.stopPropagation()}
                                isBlackReadMoreText
                              />

                              <p className="sm text-white text-end mb-0 mt-4 mt-xxl-3 d-flex align-items-center justify-content-end gap-2 word-break-word">
                                {formatTime(data?.created_at)}
                                {/* <span className="text-royal-peacock lh-noraml">
                                  {Icons.doubleTickIcon}
                                </span> */}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div
                            className={clsx(
                              styles.senderSideChatWrap,
                              styles.chatWrap
                            )}
                          >
                            {data?.product_request?.product_details === null &&
                            data?.product_request?.service_details === null ? (
                              <>
                                <div
                                  className={clsx(
                                    styles.headerProfileImg,
                                    "bg-clr-black-gray ratio ratio-1x1 ratio-w-100 rounded-circle"
                                  )}
                                >
                                  <img
                                    src={data?.brand_details?.profile_image}
                                    alt="advertising agency | ilonsi"
                                    className={
                                      "radius-inherit w-100 h-100 max-w-100"
                                    }
                                  />
                                </div>
                                <div
                                  className={clsx(
                                    styles.chatContainer,
                                    data?.product_request?.collection_details
                                      ?.is_deleted && styles?.chatBoxBlur
                                  )}
                                >
                                  <p className="fw-semibold mb-2 word-break-word">
                                    Hej, vi är från {data?.brand_details?.name}{" "}
                                  </p>
                                  <ILReadMoreLess
                                    text={data?.message}
                                    sliceLength={200}
                                    sliceClassName="custom-text-class"
                                    className="big text-gray custom-text-class word-break-word"
                                    onClick={(e) => e.stopPropagation()}
                                  />
                                  <div className="w-100">
                                    <ILMSGProductCard
                                      ILProductGalleryCardData={data}
                                      classname={clsx(
                                        styles.msgProductImgCardWrap,
                                        "mt-4"
                                      )}
                                    />
                                    {!data?.product_request?.collection_details
                                      ?.is_deleted && (
                                      <div
                                        className={clsx(
                                          "d-flex gap-4 gap-xxl-3 flex-xl-nowrap flex-wrap mt-5",
                                          styles.productActionBtnsWrap
                                        )}
                                      >
                                        {data?.is_added_to_shop === 0 &&
                                          data?.product_request?.status !==
                                            "REJECTED" &&
                                          (data?.product_request?.status ===
                                          "PENDING" ? (
                                            <>
                                              <ILButton
                                                disabled={
                                                  selectedProductData?.id ===
                                                    data?.id &&
                                                  isButtonLoading?.acceptBtn
                                                }
                                                handleClick={() => {
                                                  handleAcceptRequest(
                                                    data,
                                                    "accept"
                                                  );
                                                }}
                                                isSmallSizeBtn
                                                ILBtnClass={"min-w-auto"}
                                                ILBtnInnerSpanClass={
                                                  "d-flex gap-1 align-items-center"
                                                }
                                                variant={"primary-gradient"}
                                              >
                                                {selectedProductData?.id ===
                                                  data?.id &&
                                                isButtonLoading?.acceptBtn ? (
                                                  <ILLoader isBtn />
                                                ) : (
                                                  <>
                                                    <Tick02Icon
                                                      size={20}
                                                      color={"currentcolor"}
                                                      variant={"stroke"}
                                                    />
                                                    Acceptera
                                                  </>
                                                )}
                                              </ILButton>
                                              <ILButton
                                                disabled={
                                                  selectedProductData?.id ===
                                                    data?.id &&
                                                  isButtonLoading?.rejectBtn
                                                }
                                                isSmallSizeBtn
                                                ILBtnClass={"min-w-auto"}
                                                ILBtnInnerSpanClass={
                                                  "d-flex gap-1 align-items-center gradient-text"
                                                }
                                                variant={"whiteBg-gradientText"}
                                                handleClick={() =>
                                                  handleRejectRequest(
                                                    data,
                                                    "reject"
                                                  )
                                                }
                                              >
                                                {selectedProductData?.id ===
                                                  data?.id &&
                                                isButtonLoading?.rejectBtn ? (
                                                  <ILLoader isBtn />
                                                ) : (
                                                  <>
                                                    <MultiplicationSignIcon
                                                      size={20}
                                                      color={"#DB2F7F"}
                                                      variant={"stroke"}
                                                    />
                                                    Avvisa
                                                  </>
                                                )}
                                              </ILButton>
                                            </>
                                          ) : (
                                            <ILButton
                                              handleClick={() =>
                                                addToShopHandle(data)
                                              }
                                              isSmallSizeBtn
                                              id={"addToShop"}
                                              ILBtnClass={"min-w-auto"}
                                              ILBtnInnerSpanClass={
                                                "d-flex gap-1 align-items-center"
                                              }
                                              variant={"primary-gradient"}
                                            >
                                              Lägg till i butik
                                            </ILButton>
                                          ))}
                                        <ILButton
                                          isSmallSizeBtn
                                          handleClick={() =>
                                            handleViewDetail(data)
                                          }
                                          ILBtnClass={"min-w-auto"}
                                          ILBtnInnerSpanClass={
                                            "d-flex gap-1 align-items-center gradient-text"
                                          }
                                          variant={"whiteBg-gradientText"}
                                        >
                                          <EyeIcon
                                            size={20}
                                            color={"#DB2F7F"}
                                            variant={"stroke"}
                                          />
                                          Visa
                                        </ILButton>
                                      </div>
                                    )}
                                    <div className="d-flex align-items-center gap-4 gap-xxl-3 justify-content-end">
                                      <p className="sm text-gray text-end mb-0 mt-2">
                                        {formatTime(data?.created_at)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : data?.product_request?.collection_details ===
                                null &&
                              data?.product_request?.service_details ===
                                null ? (
                              <>
                                <div
                                  className={clsx(
                                    styles.chatRoomProfileImg,
                                    "ratio ratio-1x1 ratio-w-100 rounded-circle "
                                  )}
                                >
                                  <img
                                    src={data?.brand_details?.profile_image}
                                    alt="advertising agency | ilonsi"
                                    className={
                                      "radius-inherit object-fit-cover w-100 h-100 max-w-100"
                                    }
                                  />
                                </div>
                                <div
                                  className={clsx(
                                    styles.chatContainer,
                                    data?.product_request?.product_details
                                      ?.is_deleted && styles?.chatBoxBlur
                                  )}
                                >
                                  <p className="fw-semibold mb-2 word-break-word">
                                    Hej, vi är från {data?.brand_details?.name}{" "}
                                  </p>
                                  <ILReadMoreLess
                                    text={data?.message}
                                    sliceLength={200}
                                    sliceClassName="custom-text-class"
                                    className="mb-0 fw-medium big text-gray custom-text-class word-break-word"
                                    onClick={(e) => e.stopPropagation()}
                                  />
                                  <div className={styles.productServiceCard}>
                                    <div
                                      className={clsx(
                                        "ratio ratio-1x1 flex-0-auto bg-clr-black-gray",
                                        styles.productServiceImg
                                      )}
                                    >
                                      <img
                                        src={
                                          data?.product_request?.product_details
                                            ?.image
                                        }
                                        width={"100%"}
                                        height={"100%"}
                                        className={"object-fit-cover"}
                                      />
                                    </div>
                                    <div>
                                      <p className="fw-medium mb-2 text-ellipsis line-clamp-2">
                                        {capitalize(
                                          data?.product_request?.product_details
                                            ?.name
                                        )}
                                      </p>

                                      <ILReadMoreLess
                                        text={
                                          data?.product_request?.product_details
                                            ?.description !==
                                            ("null" || null) &&
                                          capitalize(
                                            data?.product_request
                                              ?.product_details?.description
                                          )
                                        }
                                        sliceLength={100}
                                        sliceClassName="custom-text-class"
                                        className="text-gray custom-text-class word-break-word sm text-gray fw-medium"
                                        onClick={(e) => e.stopPropagation()}
                                      />

                                      <div className="d-flex align-items-center gap-4">
                                        <div>
                                          <p className="sm d-inline-flex align-items-center mb-0 fw-medium gradient-text max-w-max-content">
                                            Pris
                                          </p>
                                          <p className="sm fw-semibold mb-0">
                                            {
                                              data?.product_request
                                                ?.product_details?.price
                                            }
                                            &nbsp; KR
                                          </p>
                                        </div>
                                        <div>
                                          <p className="sm d-inline-flex align-items-center mb-0 fw-medium gradient-text max-w-max-content">
                                            <span>
                                              CPC <b className="mx-1">:</b>
                                            </span>
                                            &nbsp;
                                            <span className="fw-semibold">
                                              {
                                                data?.product_request
                                                  ?.product_details?.ppc_price
                                              }
                                              &nbsp;KR
                                            </span>
                                          </p>
                                          {!isEmpty(
                                            data?.product_request
                                              ?.product_details?.sell_commission
                                          ) && (
                                            <p className="xs d-block mb-0 fw-medium gradient-text max-w-max-content">
                                              <span>
                                                SELL <b className="mx-1">:</b>
                                              </span>
                                              <span className="fw-semibold">
                                                {data?.product_request
                                                  ?.product_details
                                                  ?.sell_commission + " %"}
                                              </span>
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {!data?.product_request?.product_details
                                    ?.is_deleted && (
                                    <div
                                      className={clsx(
                                        "d-flex gap-3 flex-xl-nowrap flex-wrap",
                                        styles.productActionBtnsWrap
                                      )}
                                    >
                                      {data?.is_added_to_shop === 0 &&
                                        data?.product_request?.status !==
                                          "REJECTED" &&
                                        data?.product_request?.status ===
                                          "PENDING" && (
                                          <>
                                            <ILButton
                                              disabled={
                                                selectedProductData?.id ===
                                                  data?.id &&
                                                isButtonLoading?.acceptBtn
                                              }
                                              handleClick={() => {
                                                handleAcceptRequest(
                                                  data,
                                                  "accept"
                                                );
                                              }}
                                              isSmallSizeBtn
                                              ILBtnClass={"min-w-auto"}
                                              ILBtnInnerSpanClass={
                                                "d-flex gap-1 align-items-center"
                                              }
                                              variant={"primary-gradient"}
                                            >
                                              {selectedProductData?.id ===
                                                data?.id &&
                                              isButtonLoading?.acceptBtn ? (
                                                <ILLoader isBtn />
                                              ) : (
                                                <>
                                                  <Tick02Icon
                                                    size={20}
                                                    color={"currentcolor"}
                                                    variant={"stroke"}
                                                  />
                                                  Acceptera
                                                </>
                                              )}
                                            </ILButton>
                                            <ILButton
                                              disabled={
                                                selectedProductData?.id ===
                                                  data?.id &&
                                                isButtonLoading?.rejectBtn
                                              }
                                              isSmallSizeBtn
                                              ILBtnClass={"min-w-auto"}
                                              ILBtnInnerSpanClass={
                                                "d-flex gap-1 align-items-center gradient-text"
                                              }
                                              variant={"whiteBg-gradientText"}
                                              handleClick={() =>
                                                handleRejectRequest(
                                                  data,
                                                  "reject"
                                                )
                                              }
                                            >
                                              {selectedProductData?.id ===
                                                data?.id &&
                                              isButtonLoading?.rejectBtn ? (
                                                <ILLoader isBtn />
                                              ) : (
                                                <>
                                                  <MultiplicationSignIcon
                                                    size={20}
                                                    color={"#DB2F7F"}
                                                    variant={"stroke"}
                                                  />
                                                  Avvisa
                                                </>
                                              )}
                                            </ILButton>
                                          </>
                                        )}

                                      {data?.product_request?.status ===
                                        "ACCEPTED" && (
                                        <ILButton
                                          handleClick={() =>
                                            addToShopHandle(data)
                                          }
                                          isSmallSizeBtn
                                          id={"addToShop"}
                                          ILBtnClass={"min-w-auto"}
                                          ILBtnInnerSpanClass={
                                            "d-flex gap-1 align-items-center"
                                          }
                                          variant={"primary-gradient"}
                                        >
                                          Lägg till i butik
                                        </ILButton>
                                      )}
                                      <ILButton
                                        isSmallSizeBtn
                                        handleClick={() =>
                                          handleViewDetail(data)
                                        }
                                        ILBtnClass={"min-w-auto"}
                                        ILBtnInnerSpanClass={
                                          "d-flex gap-1 align-items-center gradient-text"
                                        }
                                        variant={"whiteBg-gradientText"}
                                      >
                                        <EyeIcon
                                          size={20}
                                          color={"#DB2F7F"}
                                          variant={"stroke"}
                                        />
                                        Visa
                                      </ILButton>
                                    </div>
                                  )}
                                  <p className="sm text-gray text-end mb-0 mt-4 mt-xxl-3">
                                    {formatTime(data?.created_at)}
                                  </p>
                                </div>
                              </>
                            ) : data?.product_request?.collection_details ===
                                null &&
                              data?.product_request?.product_details ===
                                null ? (
                              <>
                                <div
                                  className={clsx(
                                    styles.chatRoomProfileImg,
                                    "ratio ratio-1x1 ratio-w-100 rounded-circle "
                                  )}
                                >
                                  <img
                                    src={data?.brand_details?.profile_image}
                                    alt="advertising agency | ilonsi"
                                    className={
                                      "radius-inherit object-fit-cover w-100 h-100 max-w-100"
                                    }
                                  />
                                </div>
                                <div
                                  className={clsx(
                                    styles.chatContainer,
                                    data?.product_request?.service_details
                                      ?.is_deleted && styles?.chatBoxBlur
                                  )}
                                >
                                  <p className="fw-semibold mb-2 word-break-word">
                                    Hej, vi är från {data?.brand_details?.name}
                                  </p>
                                  <ILReadMoreLess
                                    text={data?.message}
                                    sliceLength={200}
                                    sliceClassName="custom-text-class"
                                    className="mb-0 fw-medium big text-gray custom-text-class word-break-word"
                                    onClick={(e) => e.stopPropagation()}
                                  />
                                  <div className={styles.productServiceCard}>
                                    <div
                                      className={clsx(
                                        "ratio ratio-1x1",
                                        styles.productServiceImg
                                      )}
                                    >
                                      <img
                                        src={
                                          data?.product_request?.service_details
                                            ?.image
                                        }
                                        width={"100%"}
                                        height={"100%"}
                                        className={"object-fit-cover"}
                                      />
                                    </div>
                                    <div>
                                      <p className="fw-medium mb-2 text-ellipsis line-clamp-2">
                                        {capitalize(
                                          data?.product_request?.service_details
                                            ?.name
                                        )}
                                      </p>
                                      <ILReadMoreLess
                                        text={capitalize(
                                          data?.product_request?.service_details
                                            ?.description
                                        )}
                                        sliceLength={100}
                                        sliceClassName="custom-text-class"
                                        className="text-gray custom-text-class word-break-word sm text-gray fw-medium"
                                        onClick={(e) => e.stopPropagation()}
                                      />
                                      <div className="d-flex align-items-center gap-4">
                                        <div>
                                          <p className="sm d-inline-flex align-items-center mb-0 fw-medium gradient-text max-w-max-content">
                                            Pris
                                          </p>
                                          <p className="sm fw-semibold mb-0">
                                            {
                                              data?.product_request
                                                ?.service_details?.price
                                            }
                                            &nbsp;KR
                                          </p>
                                        </div>
                                        <div>
                                          <p className="sm d-inline-flex align-items-center mb-0 fw-medium gradient-text max-w-max-content">
                                            <span>
                                              CPC <b className="mx-1">:</b>
                                            </span>
                                            &nbsp;
                                            <span className="fw-semibold">
                                              {
                                                data?.product_request
                                                  ?.service_details?.ppc_price
                                              }
                                              &nbsp;KR
                                            </span>
                                          </p>
                                          {!isEmpty(
                                            data?.product_request
                                              ?.service_details?.sell_commission
                                          ) && (
                                            <p className="xs d-block mb-0 fw-medium gradient-text max-w-max-content">
                                              <span>
                                                SELL <b className="mx-1">:</b>
                                              </span>
                                              &nbsp;
                                              <span className="fw-semibold">
                                                {data?.product_request
                                                  ?.service_details
                                                  ?.sell_commission + " %"}
                                              </span>
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {!data?.product_request?.service_details
                                    ?.is_deleted && (
                                    <div
                                      className={clsx(
                                        "d-flex gap-3 flex-xl-nowrap flex-wrap",
                                        styles.productActionBtnsWrap
                                      )}
                                    >
                                      {data?.is_added_to_shop === 0 &&
                                        (data?.product_request?.status ===
                                        "PENDING" ? (
                                          <>
                                            <ILButton
                                              disabled={
                                                selectedProductData?.id ===
                                                  data?.id &&
                                                isButtonLoading?.acceptBtn
                                              }
                                              isSmallSizeBtn
                                              ILBtnClass={"min-w-auto"}
                                              ILBtnInnerSpanClass={
                                                "d-flex gap-1 align-items-center"
                                              }
                                              variant={"primary-gradient"}
                                              handleClick={() => {
                                                handleAcceptRequest(
                                                  data,
                                                  "accept"
                                                );
                                              }}
                                            >
                                              {selectedProductData?.id ===
                                                data?.id &&
                                              isButtonLoading?.acceptBtn ? (
                                                <ILLoader isBtn />
                                              ) : (
                                                <>
                                                  <Tick02Icon
                                                    size={20}
                                                    color={"currentcolor"}
                                                    variant={"stroke"}
                                                  />
                                                  Acceptera
                                                </>
                                              )}
                                            </ILButton>
                                            <ILButton
                                              disabled={
                                                selectedProductData?.id ===
                                                  data?.id &&
                                                isButtonLoading?.rejectBtn
                                              }
                                              isSmallSizeBtn
                                              ILBtnClass={"min-w-auto"}
                                              ILBtnInnerSpanClass={
                                                "d-flex gap-1 align-items-center gradient-text"
                                              }
                                              variant={"whiteBg-gradientText"}
                                              handleClick={() =>
                                                handleRejectRequest(
                                                  data,
                                                  "reject"
                                                )
                                              }
                                            >
                                              <MultiplicationSignIcon
                                                size={20}
                                                color={"#DB2F7F"}
                                                variant={"stroke"}
                                              />

                                              {selectedProductData?.id ===
                                                data?.id &&
                                              isButtonLoading?.rejectBtn ? (
                                                <ILLoader isBtn />
                                              ) : (
                                                "Avvisa"
                                              )}
                                            </ILButton>{" "}
                                          </>
                                        ) : (
                                          <ILButton
                                            isSmallSizeBtn
                                            ILBtnClass={"min-w-auto"}
                                            ILBtnInnerSpanClass={
                                              "d-flex gap-1 align-items-center"
                                            }
                                            variant={"primary-gradient"}
                                            handleClick={() => {
                                              handleProductAddToShop(data);
                                            }}
                                          >
                                            <Tick02Icon
                                              size={20}
                                              color={"currentcolor"}
                                              variant={"stroke"}
                                            />
                                            Lägg till i butik
                                          </ILButton>
                                        ))}
                                      <ILButton
                                        isSmallSizeBtn
                                        handleClick={() =>
                                          handleViewDetail(data)
                                        }
                                        ILBtnClass={"min-w-auto"}
                                        ILBtnInnerSpanClass={
                                          "d-flex gap-1 align-items-center gradient-text"
                                        }
                                        variant={"whiteBg-gradientText"}
                                      >
                                        <EyeIcon
                                          size={20}
                                          color={"#DB2F7F"}
                                          variant={"stroke"}
                                        />
                                        Visa
                                      </ILButton>
                                    </div>
                                  )}
                                  <p className="sm text-gray text-end mb-0 mt-4 mt-xxl-3">
                                    {formatTime(data?.created_at)}
                                  </p>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className={clsx(
                                    "ratio ratio-1x1 ratio-w-100 rounded-circle ",
                                    styles.chatRoomProfileImg
                                  )}
                                >
                                  <img
                                    src={data?.brand_details?.profile_image}
                                    className={
                                      "radius-inherit object-fit-cover w-100 h-100 max-w-100"
                                    }
                                  />
                                </div>
                                <div className={styles.chatContainer}>
                                  <ILReadMoreLess
                                    text={data?.message}
                                    sliceLength={200}
                                    sliceClassName="custom-text-class"
                                    className="big text-gray custom-text-class word-break-word"
                                    onClick={(e) => e.stopPropagation()}
                                  />
                                  <div className="d-flex align-items-center gap-4 gap-xxl-3 justify-content-end">
                                    <p className="sm text-gray text-end mb-0 mt-2">
                                      {formatTime(data?.created_at)}
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
                {/* chat list scroll to bottom start*/}
                <div className="scroll-to-bottom" ref={scrollToBottom}></div>
                {/* chat list scroll to bottom end */}
                {/* chat loader start */}
                {isLoading?.chatList && (
                  <div
                    className={clsx(
                      styles.messageChatContentLoaderArea,
                      "w-100 h-100 d-flex align-items-center justify-content-center flex-column"
                    )}
                  >
                    <ILLoader
                      classname={"loader-wrap-absolute bg-transparent"}
                      isLoaderBgOpacitySm
                      isChatLoader
                    />
                    <h6>Loading...</h6>
                  </div>
                )}
                {/* chat loader end */}
              </div>
              <div className={styles.chatRoomFooter}>
                <ILTextInput
                  ILInputLabelClass={"mb-0"}
                  isSuffixIconPresent
                  placeholder={"Type a message here"}
                  // prefix={
                  //   <span role={"button"}>
                  //     <SmileIcon
                  //       size={20}
                  //       color={"var(--clr-dark-purple)"}
                  //       variant={"stroke"}
                  //     />
                  //   </span>
                  // }
                  name="message"
                  onInputKeyDown={handleInputMessageKeyDown}
                  value={sendMessageData?.message}
                  handleChange={handleMessage}
                />

                {sendMessageData?.message?.trim() !== "" &&
                  sendMessageData?.message?.length > 0 && (
                    <div role={"button"} className={styles.sentBtn}>
                      <SentIcon
                        size={25}
                        color={"var(--clr-white)"}
                        variant={"stroke"}
                        onClick={sendMessageHandler}
                      />
                    </div>
                  )}
              </div>
            </div>
          ) : (
            <div
              className={clsx(
                styles.chatRoomEmptyScreenSWrap,
                "w-100 h-100 px-5 py-5 overflow-y-auto"
              )}
            >
              <div
                onClick={() => {
                  setChatListOpen(false);
                }}
                role={"button"}
                className={clsx(
                  styles.chatListOpenIcon,
                  "bg-white rounded-circle p-3"
                )}
              >
                <UserMultiple02Icon
                  size={22}
                  color={"var(--clr-pink)"}
                  variant={"stroke"}
                />
              </div>
              <div
                className={clsx(
                  styles.chatRoomEmptyScreenRow,
                  "d-flex align-items-center justify-content-center flex-column text-center"
                )}
              >
                <img
                  src={ilonsiLogo}
                  className="img-fluid mb-4"
                  alt="advertising agency | ilonsi"
                />
                <h5 className="text-dark-purple fw-semibold mb-0 h5-initial lh-base">
                  Det verkar som att du inte har valt någon chatt
                </h5>
                <p className="h6 text-gray fw-normal mb-0 big-initial lh-base">
                  Välj en chatt för att visa meddelanden
                </p>
              </div>
            </div>
          )}

          {/* REPORT MESSAGE MODAL START */}
          <ILModal
            open={isReportMessageModal}
            onCancel={handleReportMessageModal}
            title={"Rapportera meddelande"}
            centered={true}
            closable=""
            width="518px"
            isFooterRemove
          >
            <p className="text-dark-purple mb-5">
              Vänligen gå igenom våra&nbsp;
              <span
                className="text-decoration-underline text-clr-pink hover-text-secondary cursor-pointer px-1"
                onClick={showComGuidelinesModal}
              >
                Gemenskapsriktlinjer
              </span>
              &nbsp;för ytterligare information om vad som är och inte är
              tillåtet på ILONSI
            </p>
            <ILRadioButton
              reportMessage={reportMessage}
              handleInputChange={handleInputChange}
              RadioButtonList={reportMessageRadioList}
            />
            <div className="mt-5 d-flex gap-4 justify-content-between modal-more-btn">
              <ILButton
                disabled={
                  reportMessage && !isLoading?.reportMessage ? false : true
                }
                ILBtnClass={"w-100"}
                variant={"primary-gradient"}
                handleClick={fetchReportMessage}
              >
                {isLoading?.reportMessage ? <ILLoader isBtn /> : "Skicka"}
              </ILButton>
              <ILButton
                disabled={isLoading?.reportMessage}
                handleClick={handleReportMessageModal}
                ILBtnClass={"w-100"}
                variant={"gradient-bordered"}
              >
                Avbryt
              </ILButton>
            </div>
          </ILModal>
          {/* REPORT MESSAGE MODAL END */}

          {/* BLOCK SENDER MODAL START */}
          <ILModal
            open={isBlockHandleModal}
            onCancel={handleBlockSenderModal}
            centered={true}
            closable=""
            width="518px"
            isFooterRemove
          >
            <div className="d-flex align-items-center justify-content-center mb-5">
              <img
                width={"64px"}
                height={"64px"}
                src={selectedRoom?.other_user?.room_member?.profile_image}
                alt="advertising agency | ilonsi"
                className="rounded-circle"
              />
            </div>
            <h4 className="mb-2 fw-semibold text-center">
              Blockera {`${selectedRoom?.other_user?.room_member?.name}?`}
            </h4>
            <p className="paragraph-sm-text paragraph-text-16 text-center mb-0">
              Denna användare kommer inte att bli notifierad när du blockerar
              dem på ILONSI.
            </p>
            <div className="mt-5 d-flex gap-4 justify-content-between modal-more-btn">
              <ILButton
                ILBtnClass={"w-100"}
                variant={"primary-gradient"}
                handleClick={fetchReportMessage}
              >
                Blockera Företag
              </ILButton>
              <ILButton
                handleClick={handleBlockSenderModal}
                ILBtnClass={"w-100"}
                variant={"gradient-bordered"}
              >
                Nej Tack
              </ILButton>
            </div>
          </ILModal>
          {/* BLOCK SENDER MODAL END */}

          {/* DELETE CHAT MODAL START  */}
          <ILModal
            open={isDeleteChatModal}
            onCancel={handleDeleteChatModal}
            centered={true}
            closable=""
            width="518px"
            isFooterRemove
          >
            <div
              className="brand-logo brand-logo-big flex-shrink-0 rounded-circle d-flex align-items-center mb-5 justify-content-center mx-auto"
              style={{ borderColor: "#ED4F32", borderWidth: "3px" }}
            >
              <Delete02Icon
                size={34}
                color={"#ED4F32"}
                variant={"stroke"}
                strokeWidth={2}
              />
            </div>
            <h4 className="mb-2 fw-semibold text-center">
              Radera chatt från inkorgen?
            </h4>
            <p className="paragraph-sm-text paragraph-text-16 text-center mb-0">
              Avsändaren av meddelandet kan fortfarande se konversationen och
              kontakta dig igen, om du inte väljer att blockera dem.
            </p>
            <div className="mt-5 d-flex gap-4 justify-content-between modal-more-btn">
              <ILButton
                disabled={isButtonLoading?.deleteChatBtn}
                ILBtnClass={"w-100"}
                variant={"primary-gradient"}
                handleClick={fetchDeleteChat}
              >
                {isButtonLoading?.deleteChatBtn ? <ILLoader isBtn /> : "Radera"}
              </ILButton>
              <ILButton
                disabled={isButtonLoading?.deleteChatBtn}
                handleClick={handleDeleteChatModal}
                ILBtnClass={"w-100"}
                variant={"gradient-bordered"}
              >
                Avbryt
              </ILButton>
            </div>
          </ILModal>
          {/* DELETE CHAT MODAL END  */}

          {/* PREVIEW PRODUCT MODAL FLOW START */}

          <ILModalFlow
            currentStep={currentStep}
            steps={steps}
            open={isPreviewProductModal}
            items={items}
            currentId={currentId}
            onCancel={handleModalClose}
          />
          {/* PREVIEW PRODUCT MODAL FLOW END */}

          {/* COMMUNITY GUIDELINES MODAL START  */}
          <ILModal
            open={isComGuidelinesModalOpen}
            onOk={handleComGuidelinesModalOk}
            onCancel={handleComGuidelinesModalCancel}
            title={"Gemenskapsriktlinjer"}
            className=""
            wrapClassName=""
            centered={true}
            closable=""
            width="1024px"
            children={<ILCommunityGuidelines />}
            isFooterRemove
          />
          {/* COMMUNITY GUIDELINES MODAL END  */}
        </div>
      </div>
    </>
  );
};

export default ILMessaging;
