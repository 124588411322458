import { Radio } from "antd";
import styles from "./ILRadio.module.css";

export const ILRadio = ({ handleChange, value, radioList }) => {
  return (
    <>
      <Radio.Group
        onChange={handleChange}
        value={value}
        className={styles.ILRadioGroup}
      >
        {radioList &&
          radioList.map((item, index) => (
            <Radio value={index + 1} key={index} className={styles.ILRadio}>
              {item.text}
            </Radio>
          ))}
      </Radio.Group>
    </>
  );
};
