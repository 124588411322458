import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { faqList } from "../../constants/data";

export const useFaqHook = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { userDetails } = useSelector(
    (state) => state.storeAuthenticateReducer
  );

  useEffect(() => {
    if (userDetails) {
      let queryParams = new URLSearchParams(location.search);
      queryParams.set("user-name", userDetails?.userData?.user_name);

      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    }
  }, [location?.pathname, userDetails]);

  return { faqList };
};
