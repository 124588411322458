import { debounce } from "lodash";
import moment from "moment-timezone";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  doFetchCities,
  doFetchCountryList,
  doFetchInfluencerRegister,
  doFetchProductCategories,
  doFetchSendOtp,
  doFetchServiceCategories,
  doFetchSocialMediaChannelList,
  doFetchUsernameAvailableCheck,
  doFetchVerifyOtp,
} from "../../actions";
import { getDecryptionString } from "../../services";
import {
  validateAcceptTerms,
  validateAddChannel,
  validateCategory,
  validateGeneralInformation,
  validateInfluencerRegister,
  validateProfileImage,
  validateSubmitAcceptTerms,
  validateSubmitAddChannel,
  validateSubmitCategory,
  validateSubmitGeneralInformation,
  validateSubmitInfluencerRegister,
  validateSubmitProfileImage,
  validateSubmitVerifyOtp,
  validateVerifyOtp,
} from "../../validations";

export const useSignUpHook = () => {
  const navigate = useNavigate();
  const homeNavigate = useNavigate();
  const storedUserDetails = localStorage.getItem("userDetails");
  const storedUserData = JSON.parse(storedUserDetails);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAccountCreatedModal, setIsAccountCreatedModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCityLoading, setIsCityLoading] = useState(false);
  const [signUpData, setSignUpData] = useState({});
  const [isEditData, setIsEditData] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [socialMediaData, setSocialMediaData] = useState({});
  const [socialMediaList, setSocialMediaList] = useState([]);
  const [channelList, setChannelList] = useState([]);
  const [originalChannelList, setOriginalChannelList] = useState(channelList);
  const [isEmailEdit, setIsEmailEdit] = useState(false);
  const [otpData, setOtpData] = useState({});
  const [isVerified, setIsVerified] = useState(false);
  const [validateMessages, setValidateMessages] = useState({});
  const [productCategoryList, setProductCategoryList] = useState([]);
  const [serviceCategoryList, setServiceCategoryList] = useState([]);
  const [interestsProduct, setInterestsProduct] = useState([]);
  const [interestsService, setInterestsService] = useState([]);
  const [current, setCurrent] = useState(
    storedUserData
      ? storedUserData?.userData?.step < 6 && storedUserData?.userData?.step
      : 0
  );
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [resendOtpView, setResendOtpView] = useState(true);
  const [time, setTime] = useState(60);
  const [isShowIcon, setIsShowIcon] = useState(false);
  const [deleteChannelModalOpen, setDeleteChannelModalOpen] = useState(false);
  const [channelId, setChannelId] = useState("");
  const [influencerDetails, setInfluencerDetails] = useState({});

  useEffect(() => {
    if (
      storedUserData !== null &&
      !storedUserData?.userData?.is_profile_complete
    ) {
      toast.warning("Vänligen fyll i din profil");
    }
    if (
      storedUserData !== null &&
      !storedUserData?.userData?.is_email_verified &&
      storedUserData?.userData?.step === 1
    ) {
      setSignUpData((prevState) => ({
        ...prevState,
        ePostAddress: storedUserData?.userData?.ePostAddress,
      }));

      sendOtpCallback({ resend: false });
      toast.warning("Vänligen verifiera din e-post");
    }

    if (!signUpData?.countryId) {
      setSignUpData((prevData) => ({
        ...prevData,
        countryId: "e434260b-cbfa-47f2-807c-d49116afe944",
        countryCode: "+46",
      }));
    }
  }, []);

  useEffect(() => {
    if (current === 0) {
      fetchCountryList();
    }
    if (current === 2) {
      fetchSocialMediaChannelList();
    }
    if (current === 4) {
      fetchProductCategoryList();
      fetchServiceCategoryList();
    }
  }, [current]);

  useEffect(() => {
    if (current === 0) {
      if (signUpData?.countryId) {
        fetchCitiesList(signUpData);
      }
    }
  }, [current, signUpData?.countryId]);

  useEffect(() => {
    if (current === 1 && !resendOtpView) {
      if (time > 0) {
        const interval = setInterval(() => {
          setTime((prevTime) => prevTime - 1);
        }, 1000);

        return () => clearInterval(interval);
      } else {
        onTimeUp();
      }
    }
  }, [time, current, resendOtpView]);

  useEffect(() => {
    const updatedChannelList = originalChannelList.filter(
      (channel) =>
        !socialMediaList.some(
          (socialMedia) => socialMedia.channelId === channel.value
        )
    );

    setChannelList(updatedChannelList);
  }, [socialMediaList, originalChannelList]);

  const handleChannelDeleteModal = (data) => {
    setChannelId(data?.channelId);
    setDeleteChannelModalOpen(true);
  };

  const handleChannelDeleteModalCancel = () => {
    setDeleteChannelModalOpen(false);
  };

  const onTimeUp = () => {
    setResendOtpView(true);
  };

  const handleRemoveSocialMediaAcc = () => {
    const updatedSocialMediaAccountsList = socialMediaList.filter(
      (channel) => channel.channelId !== channelId
    );

    setSocialMediaList(updatedSocialMediaAccountsList);
    setDeleteChannelModalOpen(false);
  };

  const handleModalOk = (name) => {
    if (name === "channel") {
      setIsModalOpen(true);
    } else {
      setIsAccountCreatedModal(true);
    }
  };

  const handleModalCancel = (name) => {
    if (name === "channel") {
      setIsModalOpen(false);
      setSocialMediaData({});
    } else {
      setIsAccountCreatedModal(false);
      navigate("/login");
    }
  };

  const handleIconOpenChange = (name) => {
    setIsShowIcon(!isShowIcon);
  };

  const next = () => {
    if (current == 0 && !isEmailEdit && !isEditData) {
      if (isVerified) {
        setCurrent(current + 2);
      } else {
        setCurrent(current + 1);
      }
    } else if (current == 0 && !isEmailEdit && isEditData) {
      if (isVerified) {
        setCurrent(current + 2);
      } else {
        setCurrent(current + 1);
      }
    } else {
      setCurrent(current + 1);
    }
    setValidateMessages({});
  };

  const prev = () => {
    setCurrent(current === 2 && isVerified ? current - 2 : current - 1);
  };

  const handleFileInputChange = (event) => {
    const { files, name } = event.target;

    const { errors } = validateProfileImage(name, files[0], validateMessages);
    setValidateMessages(errors);

    setIsEditData(true);
    setSignUpData((prevState) => ({ ...prevState, [name]: files }));
  };

  const fetchProductCategoryList = async () => {
    try {
      const productCategoryList = await doFetchProductCategories();

      if (productCategoryList?.status === 200) {
        const decryptedCategoryList = await JSON.parse(
          getDecryptionString(productCategoryList?.data)
        );

        const categoryListData = decryptedCategoryList?.map((data) => {
          return {
            id: data?.id,
            labelSubtitle: data?.name,
            icon: data?.image,
          };
        });
        setProductCategoryList(categoryListData);
      }
    } catch (error) {
      console.error(
        "Error occurred during fetching product category list:",
        error
      );
    }
  };

  const fetchServiceCategoryList = async () => {
    try {
      const serviceCategoryList = await doFetchServiceCategories();

      if (serviceCategoryList?.status === 200) {
        const decryptedCategoryList = await JSON.parse(
          getDecryptionString(serviceCategoryList?.data)
        );

        const categoryListData = decryptedCategoryList?.map((data) => {
          return {
            id: data?.id,
            labelSubtitle: data?.name,
            icon: data?.image,
          };
        });
        setServiceCategoryList(categoryListData);
      }
    } catch (error) {
      console.error(
        "Error occurred during fetching service category list:",
        error
      );
    }
  };

  const handleSelectChange = (value, name) => {
    if (name === "countryCode") {
      setSignUpData((prevState) => ({ ...prevState, [name]: value }));
    } else if (current === 0) {
      setIsEditData(true);
      if (name === "countryId") {
        setSignUpData((prevState) => ({ ...prevState, cityId: "" }));
      }
      const { errors } = validateInfluencerRegister(
        name,
        value,
        validateMessages
      );
      setValidateMessages(errors);
      setSignUpData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (current === 1) {
      const { errors } = validateVerifyOtp(name, value, validateMessages);
      setValidateMessages(errors);
      setOtpData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (current === 2) {
      setIsEditData(true);
      const { errors } = validateAddChannel(name, value, validateMessages);
      setValidateMessages(errors);

      const socialMediaImage = channelList.filter(
        (data) => data?.value === value
      );
      setSocialMediaData((prevState) => ({
        ...prevState,
        [name]: value,
        label: socialMediaImage[0]?.label,
        image: socialMediaImage[0]?.image,
      }));
    }
  };

  const handleInputChange = (event, type = "") => {
    const { name, value, checked } = event?.target;

    setIsEditData(true);
    if (name === "phoneNumber") {
      const { errors } = validateInfluencerRegister(
        name,
        value,
        validateMessages
      );
      setValidateMessages(errors);
      setSignUpData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (current === 0) {
      const { errors } = validateInfluencerRegister(
        name,
        value,
        validateMessages
      );
      if (name === "ePostAddress") {
        setIsEmailEdit(true);
        setIsVerified(false);
      } else {
        setIsEditData(true);
      }
      if (name === "userName") {
        if (Object.keys(errors)?.length === 0) {
          debounceUserNameCheckHandler(value);
        }
      }

      setValidateMessages(errors);
      setSignUpData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (current === 2) {
      setIsEditData(true);
      const { errors } = validateAddChannel(name, value, validateMessages);
      setValidateMessages(errors);
      setSocialMediaData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (current === 3) {
      const { errors } = validateGeneralInformation(
        name,
        value,
        validateMessages
      );
      setValidateMessages(errors);
      setSignUpData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (current === 4) {
      if (type === "product") {
        const updatedInterestsProduct = interestsProduct.filter(
          (id) => id === value
        );
        if (updatedInterestsProduct.length > 0) {
          setInterestsProduct((prevInterestsProduct) =>
            prevInterestsProduct.filter((id) => id !== value)
          );
        } else {
          setInterestsProduct((prevState) => [...prevState, value]);
        }
      } else {
        const updatedInterestsServices = interestsService.filter(
          (data) => data === value
        );
        if (updatedInterestsServices.length > 0) {
          setInterestsService((prevInterestsService) =>
            prevInterestsService.filter((id) => id !== value)
          );
        } else {
          setInterestsService((prevState) => [...prevState, value]);
        }
      }
      const { errors } = validateCategory(name, value, validateMessages);
      setValidateMessages(errors);
      setSignUpData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (current === 6) {
      const { errors } = validateAcceptTerms(
        name,
        value,
        checked,
        validateMessages
      );
      setValidateMessages(errors);
      setSignUpData((prevState) => ({
        ...prevState,
        [name]: name === "bio" ? value : checked,
      }));
    }
  };

  const debounceUserNameCheckHandler = useCallback(
    debounce(async (data) => {
      await fetchCheckUsername(data);
    }, 2000),
    []
  );

  const fetchCheckUsername = async (data) => {
    try {
      setIsLoading(true);
      const requestBody = {
        userName: data,
        userId: influencerDetails?.id ? influencerDetails?.id : null,
      };
      const userNameCheckResponse = await doFetchUsernameAvailableCheck(
        requestBody
      );

      if (userNameCheckResponse?.status === 200) {
        setValidateMessages((prevMessages) => {
          const updatedMessages = { ...prevMessages };
          delete updatedMessages.userName;
          return updatedMessages;
        });
      } else {
        setValidateMessages({ userName: userNameCheckResponse?.data?.message });
      }
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  const handleAddChannelSubmit = () => {
    const { errors } = validateSubmitAddChannel(socialMediaData);
    setValidateMessages(errors);

    if (Object.keys(errors).length === 0) {
      setSocialMediaList((prevSocialMediaList) => [
        ...prevSocialMediaList,
        socialMediaData,
      ]);
      handleModalCancel("channel");
    }
  };

  const handleSignUpSubmit = () => {
    try {
      if (current === 0) {
        const { errors } = validateSubmitInfluencerRegister(signUpData);
        setValidateMessages(errors);
        if (Object.keys(errors).length === 0) {
          influencerRegisterCallback();
        }
      } else if (current === 1) {
        const { errors } = validateSubmitVerifyOtp(otpData);
        setValidateMessages(errors);
        if (Object.keys(errors).length === 0) {
          verifyOtpCallback();
        }
      } else if (current === 2) {
        influencerRegisterCallback();
      } else if (current === 3) {
        const { errors } = validateSubmitGeneralInformation(signUpData);
        setValidateMessages(errors);
        if (Object.keys(errors).length === 0) {
          influencerRegisterCallback();
        }
      } else if (current === 4) {
        const { errors } = validateSubmitCategory(signUpData);
        setValidateMessages(errors);
        if (Object.keys(errors).length === 0) {
          influencerRegisterCallback();
        }
      } else if (current === 5) {
        const { errors } = validateSubmitProfileImage(signUpData);
        setValidateMessages(errors);
        if (Object.keys(errors).length === 0) {
          influencerRegisterCallback();
        }
      } else if (current === 6) {
        const { errors } = validateSubmitAcceptTerms(signUpData);
        setValidateMessages(errors);
        if (Object.keys(errors).length === 0) {
          influencerRegisterCallback();
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const influencerRegisterCallback = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();

      if (isEditData) {
        if (current === 0) {
          formData.append("name", signUpData?.name);
          formData.append("phoneCode", signUpData?.countryCode);
          formData.append("phoneNumber", signUpData?.phoneNumber);
          formData.append("password", signUpData?.password);
          formData.append("gender", signUpData?.gender);
          formData.append("countryId", signUpData?.countryId);
          formData.append("cityId", signUpData?.cityId);
          formData.append("userName", signUpData?.userName);
          formData.append("timezone", moment.tz.guess());
        } else if (current === 2) {
          const socialMediaAccountArr = [];
          socialMediaList?.map((data) =>
            socialMediaAccountArr?.push({
              channelId: data.channelId,
              mediaLink: data?.mediaLink,
            })
          );

          socialMediaAccountArr.forEach((channel, index) => {
            formData.append(
              `socialMediaAccount[${index}][channelId]`,
              channel.channelId
            );
            formData.append(
              `socialMediaAccount[${index}][mediaLink]`,
              channel.mediaLink
            );
          });
        } else if (current === 3) {
          formData.append(
            "socialSecurityNumber",
            signUpData?.socialSecurityNumber !== undefined
              ? signUpData?.socialSecurityNumber
              : ""
          );
          formData.append("address", signUpData?.address);
          formData.append(
            "details",
            signUpData?.details ? signUpData?.details : null
          );
          formData.append("pincode", signUpData?.pincode);
        } else if (current === 4) {
          interestsProduct.forEach((item) => {
            formData.append("interestsProduct[]", item);
          });
          interestsService.forEach((item) => {
            formData.append("interestsService[]", item);
          });
        } else if (current === 5) {
          formData.append("image", signUpData?.image[0]);
        } else if (current === 6) {
          formData.append("bio", signUpData?.bio);
          formData.append("isAcceptTerms", signUpData?.isAcceptTerms);
          formData.append(
            "isAcceptAcknowledge",
            signUpData?.isAcceptAcknowledge
          );
        }
        if (current === 0 && isEmailEdit) {
          formData.append(
            "ePostAddress",
            storedUserData !== null
              ? storedUserData?.userData?.email
              : signUpData?.ePostAddress
          );
        } else {
          formData.append(
            "ePostAddress",
            storedUserData !== null
              ? storedUserData?.userData?.email
              : signUpData?.ePostAddress
          );
        }
        formData.append("step", current + 1);
        formData.append(
          "userId",
          influencerDetails?.id ? influencerDetails?.id : null
        );

        const influencerRegisterResponse = await doFetchInfluencerRegister(
          formData
        );

        if (influencerRegisterResponse?.status === 200) {
          const decryptedRegisterResponse = JSON.parse(
            getDecryptionString(influencerRegisterResponse?.data)
          );

          if (current === 6) {
            localStorage?.setItem("_token", decryptedRegisterResponse?.token);
          }
          if (current === 0) {
            setInfluencerDetails(decryptedRegisterResponse);

            if (isEmailEdit) {
              await sendOtpCallback({ resend: false });
            }
          }
          if (current === 6) {
            setIsAccountCreatedModal(true);
          } else {
            next();
          }
          setIsEditData(false);
          setIsEmailEdit(false);
        } else {
          toast.error(influencerRegisterResponse?.data?.message);
        }
      } else {
        next();
      }
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  const sendOtpCallback = async (data) => {
    try {
      setIsLoading(true);
      setOtpData({});
      if (data?.resend) {
        setResendOtpView(false);
      } else {
        setResendOtpView(true);
      }
      setTime(60);

      const requestData = {
        email:
          storedUserData !== null
            ? storedUserData?.userData?.email
            : signUpData?.ePostAddress,
        type: "EMAIL",
        resend: data?.resend,
      };

      const sendOtpResponse = await doFetchSendOtp(requestData);

      if (sendOtpResponse?.status === 200) {
        toast.success(sendOtpResponse?.message);
      } else {
        toast.error(sendOtpResponse?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  const verifyOtpCallback = async () => {
    try {
      setIsLoading(true);
      const requestData = {
        otp: otpData?.otp,
        email:
          storedUserData !== null
            ? storedUserData?.userData?.email
            : signUpData?.ePostAddress,
        type: "EMAIL",
        verifyUserType: "Influencer",
      };

      const verifyOtpResponse = await doFetchVerifyOtp(requestData);

      if (verifyOtpResponse?.status === 200) {
        toast.success(verifyOtpResponse?.message);
        setIsVerified(true);
        next();
      } else {
        toast.error(verifyOtpResponse?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  const fetchCountryList = async () => {
    try {
      setIsLoading(true);

      const countryListResponse = await doFetchCountryList();
      if (countryListResponse?.status === 200) {
        const decryptedCountryList = await JSON.parse(
          getDecryptionString(countryListResponse?.data?.data)
        );

        const countryListData = decryptedCountryList?.map((data) => {
          return {
            value: data?.id,
            label: (
              <>
                <img
                  src={data?.image}
                  alt={data?.name}
                  width="25"
                  height="25"
                  className="me-2"
                />
                <span>{data?.name}</span>
              </>
            ),
          };
        });

        const countryCode = decryptedCountryList?.map((data) => {
          return {
            value: data?.phone_code,
            label: (
              <div className="d-flex align-items-center gap-3">
                <img
                  src={data?.image}
                  style={{ maxWidth: "24px", height: "auto" }}
                />
                <span>{data?.phone_code}</span>
              </div>
            ),
          };
        });

        setCountryCodeList(countryCode);
        setCountryList(countryListData);
      } else {
        toast.error(countryListResponse?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  const fetchCitiesList = async (data) => {
    try {
      setIsCityLoading(true);

      const citiesListResponse = await doFetchCities(data?.countryId);
      if (citiesListResponse?.status === 200) {
        const decryptedCitiesList = await JSON.parse(
          getDecryptionString(citiesListResponse?.data)
        );

        const citiesListData = decryptedCitiesList?.map((data) => {
          return {
            value: data?.id,
            label: data?.name,
          };
        });

        setCitiesList(citiesListData);
      } else {
        toast.error(citiesListResponse?.message);
      }
      setIsCityLoading(false);
    } catch (error) {
      console.log({ error });
      setIsCityLoading(false);
    }
  };

  const fetchSocialMediaChannelList = async () => {
    try {
      setIsLoading(true);

      const channelListResponse = await doFetchSocialMediaChannelList();

      if (channelListResponse?.status === 200) {
        const decryptedChannelList = await JSON.parse(
          getDecryptionString(channelListResponse?.data?.data)
        );
        const channelListData = decryptedChannelList?.map((data) => {
          return {
            value: data?.id,
            label: data?.name,
            image: data?.image_2,
          };
        });

        setChannelList(channelListData);
        setOriginalChannelList(channelListData);
      } else {
        toast.error(channelListResponse?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  return {
    time,
    current,
    otpData,
    isLoading,
    citiesList,
    isShowIcon,
    signUpData,
    countryList,
    isModalOpen,
    channelList,
    isCityLoading,
    resendOtpView,
    storedUserData,
    socialMediaList,
    socialMediaData,
    countryCodeList,
    interestsService,
    interestsProduct,
    validateMessages,
    productCategoryList,
    serviceCategoryList,
    isAccountCreatedModal,
    deleteChannelModalOpen,
    next,
    prev,
    navigate,
    setCurrent,
    handleModalOk,
    sendOtpCallback,
    handleModalCancel,
    handleInputChange,
    handleSelectChange,
    handleSignUpSubmit,
    handleIconOpenChange,
    handleFileInputChange,
    handleAddChannelSubmit,
    handleChannelDeleteModal,
    handleRemoveSocialMediaAcc,
    handleChannelDeleteModalCancel,
    homeNavigate,
  };
};
