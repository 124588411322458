import React from "react";
import { Link, NavLink } from "react-router-dom";
import { ILButton } from "../ILButton";
import clsx from "clsx";
import { defaultUser, ilonsiLogo } from "../../constants/imageData";
import { ILPagesListData } from "../../constants/data";
import { useHeaderHook } from "../../hooks/website/header.hook";
import styles from "./ILHeader.module.css";

export const ILHeader = () => {
  const {
    isToggle,
    isLoading,
    sidebarRef,
    isLoggedIn,
    userProfileDetails,
    setIsToggle,
    handleToggleMenu,
    dashboardNavigate,
    dashboardEditProfile,
  } = useHeaderHook();

  return (
    <>
      <div className={clsx("container z-index-top", styles.ILHeaderContainer)}>
        <header>
          <div className={styles.ILHeaderWrapper}>
            <Link to={"/"} className={"flex"}>
              <img
                className="logo"
                src={ilonsiLogo}
                alt="advertising agency | ilonsi"
              />
            </Link>
            <ul
              className={clsx(styles.ILHeaderLinkList, {
                [clsx(styles.active, "")]: isToggle,
              })}
              ref={sidebarRef}
            >
              {ILPagesListData.map((listItem, index) => {
                return (
                  <li key={index}>
                    <NavLink
                      to={listItem.link}
                      className={clsx(styles.headerLink, "position-relative")}
                      onClick={() => isToggle === true && setIsToggle(false)}
                    >
                      {listItem.title}
                    </NavLink>
                  </li>
                );
              })}
              <ILButton
                ILBtnClass={
                  "p btn-small flex-shrink-0 text-uppercase d-md-none d-flex"
                }
                variant={"primary-gradient"}
                handleClick={dashboardNavigate}
              >
                GÅ TILL DASHBOARD
              </ILButton>
            </ul>
            <div className="d-flex align-items-center gap-5 gap-xl-4">
              <ILButton
                ILBtnClass={
                  "p btn-small flex-shrink-0 text-uppercase d-md-flex d-none"
                }
                variant={"primary-gradient"}
                handleClick={dashboardNavigate}
              >
                GÅ TILL DASHBOARD
              </ILButton>
              {isLoggedIn && (
                <div
                  className={clsx("user-img cursor-pointer ratio ratio-1x1")}
                  onClick={dashboardEditProfile}
                >
                  <img
                    src={
                      isLoading
                        ? defaultUser
                        : userProfileDetails?.userData?.profile_image
                    }
                    alt="advertising agency | ilonsi"
                    className="object-fit-cover w-100 h-100 max-w-100"
                  />
                </div>
              )}
              <div onClick={handleToggleMenu} className="ps-3">
                <button
                  // ref={sidebarRef}
                  className={clsx(
                    styles.toggleMenu,
                    { [styles.active]: isToggle },
                    "d-xl-none d-flex"
                  )}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
              {/* <Dropdown
                menu={{ items: userDropdownItems }}
                trigger={["click"]}
                placement="bottom"
                className={styles.userDropdown}
              >
                <div className="d-flex align-items-center gap-4 gap-xxl-3">
                  <div className={clsx(styles.userImg)}>
                    <img
                      src="https://i.pravatar.cc/48"
                      alt="advertising agency | ilonsi"
                      width={"48px"}
                      height={"48px"}
                      className="object-fit-cover"
                    />
                  </div>
                  <ArrowDown01Icon
                    size={24}
                    color={"var(--clr-dark-purple)"}
                    variant={"stroke"}
                    strokeWidth={1.5}
                  />
                </div>
              </Dropdown> */}
            </div>
          </div>
        </header>
      </div>
      <div
        className={clsx(styles.backdrop, { [styles.backdropActive]: isToggle })}
        onClick={handleToggleMenu}
      ></div>
    </>
  );
};
