import {
  bioRegex,
  imageFormatRegex,
  nameAlphaNumericOnlyRegex,
  socialSecurityRegex,
} from "../../regexs";

export const validateInfluencerProfile = (name, value, validateMessages) => {
  let errors = { ...validateMessages };

  switch (name) {
    case "image":
      const fileSizeInMB = value?.size / 1024 / 1024;

      if (value == undefined || value === null || value === "") {
        errors.image = "Varumärkeslogotyp krävs";
      } else if (fileSizeInMB > 5) {
        errors.image = "Filstorleken bör vara mindre än 5 MB";
      } else if (!imageFormatRegex.test(value?.name)) {
        errors.image = "Endast filformaten .jpg, .jpeg och .png är tillåtna";
      } else {
        delete errors.image;
      }
      break;

    case "name":
      if (
        value === undefined ||
        value?.trim() === null ||
        value?.trim() === ""
      ) {
        errors.name = "Namn krävs";
      } else if (!nameAlphaNumericOnlyRegex.test(value)) {
        errors.name =
          "Namnet får inte bara innehålla siffror och specialtecken";
      } else if (value.length < 3) {
        errors.name = "Namnet måste bestå av minst 3 bokstäver";
      } else if (value.length > 35) {
        errors.name = "Namnet måste vara mindre än eller lika med 35 bokstäver";
      } else {
        delete errors.name;
      }
      break;

    case "phoneNumber":
      if (value === undefined || value === null || value === "") {
        errors.phoneNumber = "Telefonnummer krävs";
      } else if (value.startsWith("0")) {
        if (value?.length !== 10) {
          errors.phoneNumber =
            "Telefonnumrets längd måste vara lika med 10 siffror";
        } else {
          delete errors.phoneNumber;
        }
      } else if (!value.startsWith("0")) {
        if (value?.length !== 9) {
          errors.phoneNumber =
            "Telefonnumrets längd måste vara lika med 9 siffror";
        } else {
          delete errors.phoneNumber;
        }
      } else {
        delete errors.phoneNumber;
      }
      break;

    case "countryId":
      if (value === undefined || value === null || value === "") {
        errors.countryId = "Välj land";
      } else {
        delete errors.countryId;
      }
      break;

    case "cityId":
      if (value === undefined || value === null || value === "") {
        errors.cityId = "Välj stad";
      } else {
        delete errors.cityId;
      }
      break;

    case "address":
      if (value && value !== "") {
        if (!nameAlphaNumericOnlyRegex.test(value)) {
          errors.address =
            "Företagshuvudkontoret kan inte bara innehålla siffror och specialtecken";
        } else if (value.length < 3) {
          errors.address =
            "Företagets huvudkontor måste bestå av minst 3 bokstäver";
        } else if (value.length > 50) {
          errors.address =
            "Företagens huvudkontor måste vara mindre än eller lika med 50 bokstäver";
        } else {
          delete errors.address;
        }
      } else {
        delete errors.address;
      }
      break;

    case "details":
      if (value && value !== "") {
        if (value.length > 250) {
          errors.details = "Högst 250 tecken är tillåtna";
        } else {
          delete errors.details;
        }
      } else {
        delete errors.details;
      }
      break;

    case "pincode":
      if (value === undefined || value === null || value === "") {
        errors.pincode = "Postnummer krävs";
      } else {
        delete errors.pincode;
      }
      break;

    case "socialSecurityNumber":
      if (
        value === undefined ||
        value?.trim() === null ||
        value?.trim() === ""
      ) {
        errors.socialSecurityNumber = "Personnummer krävs";
      } else if (!socialSecurityRegex.test(value)) {
        errors.socialSecurityNumber = "Alfabet är inte tillåtna";
      } else if (value.replace(/-/g, "")?.length < 10) {
        errors.socialSecurityNumber =
          "Personnummer måste vara minst 10 siffror långt";
      } else if (value.replace(/-/g, "")?.length > 12) {
        errors.socialSecurityNumber =
          "Personnummerlängden måste vara mindre än eller lika med 12";
      } else {
        delete errors.socialSecurityNumber;
      }
      break;

    case "bio":
      if (
        value == undefined ||
        value === null ||
        value === "" ||
        value === false
      ) {
        errors.bio = "Vänligen skriv din bio";
      } else if (!bioRegex.test(value)) {
        errors.bio = "Bio måste vara mellan 1 och 100 tecken";
      } else {
        delete errors.bio;
      }
      break;

    default:
      break;
  }

  return { errors };
};

export const validateSubmitInfluencerProfile = (data) => {
  let errors = {};

  if (data.image === undefined || data.image === null || data.image === "") {
    errors.image = "Varumärkeslogotyp krävs";
  }

  if (typeof data?.image === "object") {
    const fileSizeInMB = data?.image[0]?.size / 1024 / 1024;

    if (!imageFormatRegex.test(data.image[0]?.name)) {
      errors.image = "Endast filformaten .jpg, .jpeg och .png är tillåtna";
    }
    if (fileSizeInMB > 5) {
      errors.image = "Filstorleken bör vara mindre än 5 MB";
    }
  }

  if (data.name === undefined || data.name === null || data.name === "") {
    errors.name = "Namn krävs";
  }
  if (data.name) {
    if (!nameAlphaNumericOnlyRegex.test(data.name)) {
      errors.name = "Namnet får inte bara innehålla siffror och specialtecken";
    }
    if (data.name.length < 3) {
      errors.name = "Namnet måste bestå av minst 3 bokstäver";
    }
    if (data.name.length > 35) {
      errors.name = "Namnet måste vara mindre än eller lika med 35 bokstäver";
    }
  }

  if (
    data.phoneNumber === undefined ||
    data.phoneNumber === null ||
    data.phoneNumber === ""
  ) {
    errors.phoneNumber = "Telefonnummer krävs";
  }

  if (data?.phoneNumber) {
    if (data?.phoneNumber.startsWith("0")) {
      if (data?.phoneNumber?.length !== 10) {
        errors.phoneNumber =
          "Telefonnumrets längd måste vara lika med 10 siffror";
      }
    } else if (!data?.phoneNumber.startsWith("0")) {
      if (data?.phoneNumber?.length !== 9) {
        errors.phoneNumber =
          "Telefonnumrets längd måste vara lika med 9 siffror";
      }
    }
  }

  if (
    data.countryId === undefined ||
    data.countryId === null ||
    data.countryId === ""
  ) {
    errors.countryId = "Välj land";
  }

  if (data.cityId === undefined || data.cityId === null || data.cityId === "") {
    errors.cityId = "Välj stad";
  }

  if (data.address) {
    if (!nameAlphaNumericOnlyRegex.test(data.address)) {
      errors.address =
        "Namnet får inte bara innehålla siffror och specialtecken";
    }
    if (data.address.length < 3) {
      errors.address =
        "Företagets huvudkontor måste bestå av minst 3 bokstäver";
    }
    if (data.address.length > 50) {
      errors.address =
        "Företagens huvudkontor måste vara mindre än eller lika med 50 bokstäver";
    }
  }
  if (data?.details && data?.details?.length > 250) {
    errors.details = "Högst 250 tecken är tillåtna";
  }

  if (
    data.socialSecurityNumber === undefined ||
    data.socialSecurityNumber === null ||
    data.socialSecurityNumber === ""
  ) {
    errors.socialSecurityNumber = "Personnummer krävs";
  }

  if (
    data?.socialSecurityNumber?.length > 0 &&
    data?.socialSecurityNumber !== "undefined"
  ) {
    if (!socialSecurityRegex.test(data.socialSecurityNumber)) {
      errors.socialSecurityNumber = "Ogiltigt personnummer";
    }
    if (data?.socialSecurityNumber?.replace(/-/g, "")?.length < 10) {
      errors.socialSecurityNumber =
        "Personnummer måste vara minst 10 siffror långt";
    }
    if (data?.socialSecurityNumber?.replace(/-/g, "")?.length > 12) {
      errors.socialSecurityNumber =
        "Personnummerlängden måste vara mindre än eller lika med 12";
    }
  }

  if (data.bio === undefined || data.bio === null || data.bio === "") {
    errors.bio = "Vänligen skriv din bio";
  }

  if (data?.bio) {
    if (!bioRegex.test(data.bio)) {
      errors.bio = "Bio måste vara mellan 1 och 100 tecken";
    }
  }

  return { errors };
};
