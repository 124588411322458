import clsx from "clsx";
import { ILTextInput } from "../ILTextInput";
import { Col, Row } from "antd";
import { ILButton } from "../ILButton";
import { Link } from "react-router-dom";
import { ArrowLeft02Icon, Search01Icon } from "hugeicons-react";
import styles from "./ILInnerHero.module.css";
import { ILSelect } from "../ILSelect";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";

export const ILInnerHero = ({
  className,
  heroTitle,
  heroParagraph,
  heroSearchBarShow,
  heroLearnMoreBtnShow,
  placeholder,
  link,
  pageTitle,
  heroContentClass,
  name,
  value,
  options,
  isLoading = false,
  handleChange,
  handleSearchChange,
  handleSelectSearchChange,
  isShowSearchDropDown = false,
}) => {
  // DASHBOARD NAVIGATION START

  // DASHBOARD NAVIGATION END

  //  HERO SEARCH BAR JS START
  const [isOpen, setIsOpen] = useState(false);
  const searchBarRef = useRef(null);

  const handleClickOutside = (event) => {
    if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
      setIsOpen(false); // Close the search bar if clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // HERO SEARCH BAR JS END

  return (
    <>
      <section
        className={clsx(
          styles.innerHeroSection,
          className,
          "position-relative z-1 inner-hero-gradient hero-section-space"
        )}
      >
        <div className="container">
          <Row>
            <Col xs={24}>
              {/*  */}
              <div className="d-flex align-items-center gap-3">
                {link && (
                  <Link to={link}>
                    <ArrowLeft02Icon
                      size={34}
                      color={"#FFFFFF"}
                      variant={"stroke"}
                      strokeWidth={2}
                    />
                  </Link>
                )}
                <h4 className="p big text-white fw-semibold lh-22 mb-0">
                  {pageTitle}
                </h4>
              </div>
              {/*  */}
              <div
                className={clsx(
                  styles.heroContentWrap,
                  heroContentClass,
                  "w-100"
                )}
              >
                <div className="w-100">
                  <h1 className="secondary-title text-white">{heroTitle}</h1>
                  <p className="fw-medium text-in-the-twiligh p big line-height-normal mx-auto">
                    {heroParagraph}
                  </p>
                </div>
                {/* HERO SEARCH BAR START */}
                <div className="w-100">
                  {heroSearchBarShow && (
                    <div
                      className={clsx(
                        styles.heroSearchBarWrap,
                        "hero-search-bar-wrap position-relative"
                      )}
                    >
                      <div
                        className="w-100 h-100"
                        onClick={() => setIsOpen(true)}
                      >
                        <ILTextInput
                          id="pageDataSearch"
                          datatestid=""
                          type="text"
                          name={name}
                          value={value}
                          prefix={
                            <Search01Icon
                              size={24}
                              color={"#1D1128"}
                              variant={"stroke"}
                            />
                          }
                          handleChange={handleSearchChange}
                          placeholder={!placeholder ? "Sök här" : placeholder}
                          allowClear
                          ILInputLabelClass="mb-0"
                        />
                      </div>
                      {isShowSearchDropDown && (
                        <div
                          ref={searchBarRef}
                          className={clsx(
                            isOpen ? styles.openHeroSearchBarListWrap : "",
                            styles.heroSearchBarListWrap,
                            "w-100 bg-white mt-3 position-absolute"
                          )}
                        >
                          {options?.length === 0 && value == "" && (
                            <h5 className="fw-medium text-clr-indigo p cursor-pointer text-center big mb-0">
                              Vänligen sök företag
                            </h5>
                          )}
                          {!isLoading &&
                            options?.length === 0 &&
                            value !== "" && (
                              <h5 className="fw-medium text-clr-indigo p cursor-pointer text-center big mb-0">
                                Inga företag hittades
                              </h5>
                            )}
                          <ul className="overflow-y-auto pe-2 small-track">
                            {options?.length > 0 &&
                              options?.map((data) => (
                                <li
                                  className="fw-medium text-clr-indigo p cursor-pointer transition-smooth hover-text"
                                  onClick={() =>
                                    handleSelectSearchChange(
                                      data?.value,
                                      "selectBrand"
                                    )
                                  }
                                >
                                  {data?.label}
                                </li>
                              ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                  {heroLearnMoreBtnShow && (
                    <div className="d-flex justify-content-center mt-5">
                      <ILButton
                        ILBtnClass={"min-w-auto text-uppercase line-height-0"}
                        variant={"primary-gradient"}
                      >
                        Learn more
                      </ILButton>
                    </div>
                  )}
                </div>
                {/* HERO SEARCH BAR END */}
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};
