import React, { useState } from "react";
import { authBannerTwo } from "../../../constants/imageData";
import { ILSteps, ILHead } from "../../../components";

const ILSignUp = () => {
  return (
    <>
      <ILHead title="Sign Up" />
      <section className="authentication-section">
        <div className="authentication-flex d-flex flex-wrap">
          <div className="authentication-col d-flex overflow-y-auto order-2 order-lg-1 py-5 py-xl-4">
            <div className="authentication-md-box w-100 m-auto">
              <ILSteps />
            </div>
          </div>
          <div className="authentication-col bg-dark authentication-right-col order-1 order-lg-2 mb-5 mb-lg-0">
            <img
              src={authBannerTwo}
              alt="advertising agency | ilonsi"
              className="w-100 h-100 object-fit-cover"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default ILSignUp;
