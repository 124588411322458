import {
  ArrowRight01Icon,
  CreditCardIcon,
  Edit02Icon,
  HelpCircleIcon,
  Logout01Icon,
  Notification02Icon,
  SentIcon,
  Settings01Icon,
} from "hugeicons-react";
import React from "react";
import { ILBreadCrumb } from "../ILBreadCrumb";
import { ILPopover } from "../ILPopover";
import { ILTab } from "../ILTab";
import clsx from "clsx";
import {
  notificationTabData,
  notificationTabInnerData,
} from "../../constants/data";
import { ILNotificationCard } from "../ILNotificationCard";
import { ILModal } from "../ILModal";
import { Icons } from "../../constants/icons";
import { Link } from "react-router-dom";
import { ILButton } from "../ILButton";
import { useDashboardHeaderHook } from "../../hooks/website/dashboardHeader.hook";
import { capitalize } from "lodash";
import { defaultUser, ilonsiLogo } from "../../constants/imageData";
import { ILSkeleton } from "../ILSkeleton";
import styles from "./ILDashboardHeader.module.css";

export const ILDashboardHeader = ({
  breadCrumbItems,
  extra,
  title,
  ILDashboardHeaderDefaultClass,
}) => {
  const {
    isLoading,
    isTabActive,
    selectedRequest,
    accessRequestTab,
    accessRequestList,
    isLogOutModalOpen,
    userProfileDetails,
    isAddToShopLoading,
    navigate,
    logoutHandler,
    handleTabClick,
    handleAddToShop,
    showLogOutModal,
    handleViewDetails,
    fetchMarkAllAsRead,
    handleSelectChange,
    handleAccessRequest,
    handleLogOutModalOk,
    handleLogOutModalCancel,
    handleNavigateAccessRequest,
  } = useDashboardHeaderHook();

  let filterTabData;

  isTabActive === notificationTabData[0].key
    ? (filterTabData = notificationTabInnerData)
    : (filterTabData = notificationTabInnerData.filter(
        (data) => data.category === isTabActive
      ));

  const notificationPopoverContent = (
    <div>
      <ILTab
        defaultActiveKey="1"
        tabItem={notificationTabData}
        onTabClick={(key) => handleTabClick(key)}
        tabClassName=""
        onChange={(event) => handleSelectChange(event, "type")}
      />

      {!isLoading?.accessRequestList && accessRequestList?.totalItems > 0 && (
        <div
          className={clsx(styles.notificationCardWrap, "d-grid small-track")}
        >
          {accessRequestList?.rows?.map((item, index) => {
            return (
              <ILNotificationCard
                key={index}
                navigate={navigate}
                notificationItem={item}
                isLoading={isAddToShopLoading}
                selectedRequest={selectedRequest}
                handleAddToShop={handleAddToShop}
                handleViewDetails={handleViewDetails}
                handleAccessRequest={handleAccessRequest}
                handleNavigateAccessRequest={handleNavigateAccessRequest}
              />
            );
          })}
        </div>
      )}

      {isLoading?.accessRequest && (
        <div className="mt-3">
          <ILSkeleton isNormalParagraph />
        </div>
      )}

      {!isLoading?.accessRequestList && accessRequestList?.totalItems === 0 && (
        <>
          <div className="d-flex flex-column justify-content-center align-items-center gap-5 gap-xl-4 pt-5 pb-3">
            <img
              src={ilonsiLogo}
              className="img-fluid"
              alt="advertising agency | ilonsi"
            />
            <h6 className="text-dark-purple big mb-0">
              {`Inga ${
                accessRequestTab?.type === "CUSTOM"
                  ? "anpassad förfrågningar hittades"
                  : accessRequestTab?.type === "ACCESS"
                  ? "åtkomstförfrågningar hittades"
                  : "förfrågningar hittades"
              }`}
            </h6>
          </div>
        </>
      )}
    </div>
  );

  const profilePopoverContent = (
    <div>
      <div
        className={clsx(
          styles.profileAvatarWrap,
          "d-flex flex-column align-items-center"
        )}
      >
        <div className={"user-img profile-img ratio ratio-1x1"}>
          <img
            src={
              isLoading?.userDetails
                ? defaultUser
                : userProfileDetails?.userData?.profile_image
            }
            alt="advertising agency | ilonsi"
            className="object-fit-cover w-100 h-100 max-w-100"
          />
        </div>
        <div className="mt-4">
          <h5 className="big fw-semibold text-center word-break-word">
            {isLoading?.userDetails ? (
              <ILSkeleton count={1} height={10} />
            ) : (
              capitalize(userProfileDetails?.userData?.name)
            )}
          </h5>
          <p className="paragraph-sm-text mb-0 text-center">
            {isLoading?.userDetails ? (
              <ILSkeleton count={1} height={10} width={100} />
            ) : (
              userProfileDetails?.userData?.address
            )}
          </p>
        </div>
      </div>
      <ul
        className={clsx(styles.profileActivityListWrap, "d-grid small-track")}
      >
        <li>
          <Link
            to="/dashboard/my-profile/edit-profile"
            className={clsx(
              styles.profileActivity,
              "text-dark-purple d-flex align-items-center justify-content-between gap-4"
            )}
          >
            <div className="d-flex align-items-center">
              <Edit02Icon
                size={20}
                color={"var(--clr-dark-purple)"}
                variant={"stroke"}
              />
              <span className="p ms-3 d-block">Redigera profil</span>
            </div>
            <div
              className={clsx(
                styles.rightArrowCard,
                "icon-card-sm bg-white transition-smooth d-flex justify-content-center align-items-center rounded-circle"
              )}
            >
              <ArrowRight01Icon
                size={20}
                color={"var(--clr-dark-purple)"}
                variant={"stroke"}
              />
            </div>
          </Link>
        </li>
        <li>
          <Link
            to="/dashboard/my-profile/my-access-request"
            className={clsx(
              styles.profileActivity,
              "text-dark-purple d-flex align-items-center justify-content-between gap-4"
            )}
          >
            <div className="d-flex align-items-center">
              <SentIcon
                size={20}
                color={"var(--clr-dark-purple)"}
                variant={"stroke"}
              />
              <span className="p ms-3 d-block">Mina förfrågningar</span>
            </div>
            <div
              className={clsx(
                styles.rightArrowCard,
                "icon-card-sm bg-white transition-smooth d-flex justify-content-center align-items-center rounded-circle"
              )}
            >
              <ArrowRight01Icon
                size={20}
                color={"var(--clr-dark-purple)"}
                variant={"stroke"}
              />
            </div>
          </Link>
        </li>
        <li>
          <Link
            to="/dashboard/my-profile/my-payment"
            className={clsx(
              styles.profileActivity,
              "text-dark-purple d-flex align-items-center justify-content-between gap-4"
            )}
          >
            <div className="d-flex align-items-center">
              <CreditCardIcon
                size={20}
                color={"var(--clr-dark-purple)"}
                variant={"stroke"}
              />
              <span className="p ms-3 d-block">Utbetalningar</span>
            </div>
            <div
              className={clsx(
                styles.rightArrowCard,
                "icon-card-sm bg-white transition-smooth d-flex justify-content-center align-items-center rounded-circle"
              )}
            >
              <ArrowRight01Icon
                size={20}
                color={"var(--clr-dark-purple)"}
                variant={"stroke"}
              />
            </div>
          </Link>
        </li>
        <li>
          <Link
            to="/dashboard/my-profile/support-and-help"
            className={clsx(
              styles.profileActivity,
              "text-dark-purple d-flex align-items-center justify-content-between gap-4"
            )}
          >
            <div className="d-flex align-items-center">
              <HelpCircleIcon
                size={20}
                color={"var(--clr-dark-purple)"}
                variant={"stroke"}
              />
              <span className="p ms-3 d-block">Support &#38; Hjälp</span>
            </div>
            <div
              className={clsx(
                styles.rightArrowCard,
                "icon-card-sm bg-white transition-smooth d-flex justify-content-center align-items-center rounded-circle"
              )}
            >
              <ArrowRight01Icon
                size={20}
                color={"var(--clr-dark-purple)"}
                variant={"stroke"}
              />
            </div>
          </Link>
        </li>
        <li>
          <Link
            to="/dashboard/my-profile/settings"
            className={clsx(
              styles.profileActivity,
              "text-dark-purple d-flex align-items-center justify-content-between gap-4"
            )}
          >
            <div className="d-flex align-items-center">
              <Settings01Icon
                size={20}
                color={"var(--clr-dark-purple)"}
                variant={"stroke"}
              />
              <span className="p ms-3 d-block">Inställningar</span>
            </div>
            <div
              className={clsx(
                styles.rightArrowCard,
                "icon-card-sm bg-white transition-smooth d-flex justify-content-center align-items-center rounded-circle"
              )}
            >
              <ArrowRight01Icon
                size={20}
                color={"var(--clr-dark-purple)"}
                variant={"stroke"}
              />
            </div>
          </Link>
        </li>
        <li>
          <span
            href="#"
            className={clsx(
              styles.profileActivity,
              "text-tomato d-flex align-items-center justify-content-between gap-4 cursor-pointer"
            )}
            onClick={showLogOutModal}
          >
            <div className="d-flex align-items-center">
              <Logout01Icon
                size={20}
                color={"var(--clr-tomato)"}
                variant={"stroke"}
              />
              <span className="p ms-3 d-block">Logga ut</span>
            </div>
            <div
              className={clsx(
                styles.rightArrowCard,
                "icon-card-sm bg-white transition-smooth d-flex justify-content-center align-items-center rounded-circle"
              )}
            >
              <ArrowRight01Icon
                size={20}
                color={"var(--clr-dark-purple)"}
                variant={"stroke"}
              />
            </div>
          </span>
        </li>
      </ul>
    </div>
  );

  return (
    <>
      <div
        className={clsx(
          isLoading?.userDetails ? styles.scrolled : "",
          styles.ILDashboardHeaderWrap,
          ILDashboardHeaderDefaultClass
        )}
      >
        <div className="ps-3 ps-md-0">
          <h4 className="fw-semibold mb-xl-3 mb-2 text-ellipsis line-clamp-1">
            {title}
          </h4>
          <ILBreadCrumb className={"mt-xl-3 mt-2"} items={breadCrumbItems} />
        </div>
        {extra && (
          <div className="d-flex align-items-center gap-4">
            <div className="d-flex align-items-center gap-4">
              <ILPopover
                content={notificationPopoverContent}
                title={
                  <>
                    <div
                      className={clsx(
                        styles.popoverTitleCard,
                        "d-flex justify-content-between align-items-center"
                      )}
                    >
                      <h3 className="mb-0 h5 fw-medium">Notifikationer</h3>
                      <a
                        href=""
                        className="gradient-text"
                        onClick={(event) => {
                          event.preventDefault();
                          fetchMarkAllAsRead();
                        }}
                      >
                        Markera alla som lästa
                      </a>
                    </div>
                  </>
                }
                customClassname="notification-popover"
              >
                <div
                  role={"button"}
                  className={clsx(
                    styles.iconRoundBox,
                    "bg-clr-white rounded-circle d-flex align-items-center justify-content-center position-relative"
                  )}
                >
                  <Notification02Icon
                    size={24}
                    color={"var(--clr-dark-purple)"}
                    variant={"stroke"}
                  />
                  {accessRequestList?.unReadNotification > 0 && (
                    <div
                      className={clsx(
                        styles.notificationCountBox,
                        "d-flex align-items-center justify-content-center position-absolute"
                      )}
                    >
                      <span className="text-white fw-bold text-center w-100 overflow-hidden">
                        {accessRequestList?.unReadNotification}
                      </span>
                    </div>
                  )}
                </div>
              </ILPopover>
              <span
                className={clsx(styles.dashHeaderLine, "bg-clr-orchid-mist")}
              ></span>
              <ILPopover
                content={profilePopoverContent}
                customClassname={"profile-popover"}
              >
                <div
                  className={
                    "user-img profileImgRef cursor-pointer ratio ratio-1x1"
                  }
                >
                  <img
                    src={
                      isLoading?.userDetails
                        ? defaultUser
                        : userProfileDetails?.userData?.profile_image
                    }
                    alt="advertising agency | ilonsi"
                    className="object-fit-cover w-100 h-100 max-w-100"
                  />
                </div>
              </ILPopover>
            </div>
          </div>
        )}

        <ILModal
          open={isLogOutModalOpen}
          onOk={handleLogOutModalOk}
          onCancel={handleLogOutModalCancel}
          title={""}
          wrapClassName=""
          centered={true}
          closable=""
          width="518px"
          children={
            <>
              <div className="text-center d-flex flex-column gap-5 gap-xxl-4">
                <span className="lh-0">{Icons.logOut}</span>
                <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                  Åh nej! Du lämnar
                </h4>
                <p className="paragraph-sm-text paragraph-md-text text-center mb-0">
                  Är du säker på att du vill logga ut?
                </p>
                <div className="d-flex gap-5 modal-more-btn">
                  <ILButton
                    ILBtnClass={"w-50"}
                    variant={"primary-gradient"}
                    handleClick={logoutHandler}
                  >
                    Ja, Bekräfta
                  </ILButton>
                  <ILButton
                    handleClick={handleLogOutModalCancel}
                    ILBtnClass={"w-50"}
                    variant={"gradient-bordered"}
                  >
                    Nej, Avbryt
                  </ILButton>
                </div>
              </div>
            </>
          }
          isFooterRemove
        />
      </div>
    </>
  );
};
