import { Input } from "antd";
import clsx from "clsx";
import React from "react";
import styles from "./ILTextInput.module.css";

export const ILTextInput = ({
  id,
  datatestid,
  type,
  name,
  value,
  label,
  placeholder,
  ILInputLabelClass,
  handleChange,
  prefix,
  textAreaShow,
  autoSize,
  maxLength,
  searchIconPositionLeft,
  suffix,
  isSuffixIconPresent,
  readOnly,
  onChangeOtpInput,
  isOTPInput,
  disabled,
  otpLength,
  customOtpCardClassname,
  errorMsg,
  onInputKeyDown,
  ILTextInputClass,
  isRequiredInput = false,
  isRequiredTextarea = false,
  isRequiredOtp = false,
  ...rest
}) => {
  // textarea
  const { TextArea } = Input;
  return (
    <>
      {textAreaShow ? (
        <>
          <label
            className={clsx(styles.ILInputLabel, ILInputLabelClass, "w-100")}
            htmlFor={id}
          >
            {label && (
              <span
                className={clsx(
                  styles.ILInputLabelText,
                  "fw-medium text-dark-purple d-flex gap-2 gap-xxl-1"
                )}
              >
                {label}
                {isRequiredTextarea && <span className="text-tomato">*</span>}
              </span>
            )}
            <TextArea
              id={id}
              datatestid={datatestid}
              name={name}
              value={value}
              placeholder={placeholder}
              maxLength={maxLength}
              className={clsx(styles.ILTextInput, "small-track")}
              onChange={handleChange}
              prefix={prefix}
              autoSize={autoSize}
              {...rest}
            />
            {errorMsg && (
              <span className="p sm mt-3 lh-1 text-tomato fw-medium d-block">
                {errorMsg}
              </span>
            )}
          </label>
        </>
      ) : isOTPInput ? (
        <>
          <div className={clsx(styles.ILOtpInputCard, customOtpCardClassname)}>
            <span
              className={clsx(
                styles.ILInputLabelText,
                "fw-medium text-dark-purple d-flex gap-2 gap-xxl-1"
              )}
            >
              {"Kod"}
              {isRequiredOtp && <span className="text-tomato">*</span>}
            </span>
            <Input.OTP length={otpLength} onChange={onChangeOtpInput} />
            {errorMsg && (
              <span className="p sm mt-3 lh-1 text-tomato fw-medium d-block">
                {errorMsg}
              </span>
            )}
          </div>
        </>
      ) : (
        <>
          <label
            className={clsx(styles.ILInputLabel, ILInputLabelClass, "w-100")}
            htmlFor={id}
          >
            {label && (
              <span
                className={clsx(
                  styles.ILInputLabelText,
                  "fw-medium text-dark-purple d-flex gap-2 gap-xxl-1"
                )}
              >
                {label}
                {isRequiredInput && <span className="text-tomato">*</span>}
              </span>
            )}
            <Input
              id={id}
              datatestid={datatestid}
              type={type}
              name={name}
              value={value}
              disabled={disabled}
              placeholder={placeholder}
              className={clsx([
                styles.ILTextInput,
                {
                  [styles.ILSuffixTextInput]: suffix,
                },
                isSuffixIconPresent && "suffix-icon-present",
                ILTextInputClass,
              ])}
              onChange={handleChange}
              prefix={prefix}
              suffix={suffix}
              onKeyDown={onInputKeyDown}
              readOnly={readOnly}
              {...rest}
            />
            {errorMsg && (
              <span className="p sm mt-3 lh-1 text-tomato fw-medium d-block">
                {errorMsg}
              </span>
            )}
          </label>
        </>
      )}
    </>
  );
};
