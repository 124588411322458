import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  FreeMode,
  Thumbs,
  Pagination,
  Autoplay,
  Navigation,
} from "swiper/modules";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { ILSendAccessReqModal } from "../ILSendAccessReqModal";
import { ILAddYourCollectionModal } from "../ILAddYourCollectionModal";
import { ILButton } from "../ILButton";
import styles from "./ILProductGalleryCard.module.css";

export const ILProductGalleryCard = ({
  ILProductGalleryCardDataList,
  classname,
  isLoggedIn,
  loginHandler,
  sendAccessRequest,
  handleCollectionDetail,
  productContainerClass,
  isLoading,
  showSCModal,
  isShopCollectionModalOpen,
  handleSCModalOk,
  handleSCModalCancel,
  handleAddToShopCollection,
  navigation,
  autoplay,
  pagination,
  grabCursor,
  isRequestSentLoading,
}) => {
  // thumb swiper click start
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  // thumb swiper click end

  return (
    <div
      className={clsx(
        styles.ILProductGalleryCard,
        classname,
        "h-100 d-flex flex-column justify-content-between"
      )}
    >
      <div
      // onClick={() => handleCollectionDetail(ILProductGalleryCardDataList)}
      >
        <div
          className={clsx(
            styles.ILProductGallerySliderContainer,
            productContainerClass
          )}
        >
          <Swiper
            loop={true}
            thumbs={{
              swiper: `.${ILProductGalleryCardDataList.thumbsClassName}`,
              swiper: thumbsSwiper,
            }}
            modules={[FreeMode, Thumbs, Pagination, Autoplay, Navigation]}
            className={styles.mainGallerytSlider}
            navigation={navigation}
            autoplay={autoplay}
            pagination={pagination}
            grabCursor={grabCursor}
          >
            {ILProductGalleryCardDataList?.collectionProducts &&
              ILProductGalleryCardDataList?.collectionProducts.map(
                (imageItem, index) => {
                  return (
                    <SwiperSlide
                      key={index}
                      className="radius-xs d-flex align-items-center justify-content-center cursor-pointer"
                    >
                      <div className={clsx(styles.mainGalleryImg)}>
                        <img
                          width={"100%"}
                          height={"100%"}
                          className={
                            "object-fit-contain object-position-center w-100 h-100 aspect-ratio-1-1"
                          }
                          src={imageItem.product?.image}
                          alt={""}
                        />
                      </div>
                    </SwiperSlide>
                  );
                }
              )}
          </Swiper>
          {ILProductGalleryCardDataList.collectionProducts?.length > 1 && (
            <Swiper
              onSwiper={setThumbsSwiper}
              loop={true}
              spaceBetween={8.5}
              slidesPerView={3}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Thumbs]}
              direction="verticle"
              className={clsx(
                styles.thumbGallerySlider,
                "thumb-gallery-slider",
                ILProductGalleryCardDataList.thumbsClassName
              )}
            >
              {ILProductGalleryCardDataList.collectionProducts.map(
                (imageItem, index) => {
                  return (
                    <SwiperSlide
                      key={index}
                      className="bg-clr-black-gray radius-extra-xs d-flex align-items-center justify-content-center cursor-pointer"
                    >
                      <div
                        className={clsx(
                          styles.thumbImg,
                          "w-100 h-100 cursor-pointer"
                        )}
                      >
                        <img
                          className={
                            "object-fit-cover object-position-center w-100 h-100 aspect-ratio-1-1"
                          }
                          src={imageItem.product?.image}
                          alt={""}
                        />
                      </div>
                    </SwiperSlide>
                  );
                }
              )}
            </Swiper>
          )}
        </div>
        <div
          className="mt-5 mt-xxl-4 d-flex align-items-center gap-4 gap-xxl-3 cursor-pointer"
          onClick={() => handleCollectionDetail(ILProductGalleryCardDataList)}
        >
          <div
            className={clsx(styles.brandLogo, "ratio ratio-1x1 bg-clr-beluga")}
          >
            <img
              className="object-fit-cover max-w-100 w-100 h-100"
              src={ILProductGalleryCardDataList.brand?.profile_image}
              alt="advertising agency | ilonsi"
            />
          </div>
          <p className="big fw-semibold mb-0">
            {ILProductGalleryCardDataList.brand?.name}
          </p>
        </div>
        <div className="d-flex mt-4 align-items-center justify-content-between">
          <div>
            <Link
              onClick={() =>
                handleCollectionDetail(ILProductGalleryCardDataList)
              }
              className="p big fw-semibold text-dark-purple mb-2 text-ellipsis line-clamp-2 word-break-word"
            >
              {ILProductGalleryCardDataList.name}
            </Link>
            <p className="sm text-gray fw-medium mb-0">
              {ILProductGalleryCardDataList?.product_count} Product
              {ILProductGalleryCardDataList?.product_count > 1 && "s"}
            </p>
          </div>
        </div>
      </div>

      {isLoggedIn ? (
        ILProductGalleryCardDataList?.is_added_to_shop === 0 && (
          <div>
            {ILProductGalleryCardDataList?.has_access === "ACCESS_NEEDED" ||
            ILProductGalleryCardDataList?.has_access === "ACCESS_DENIED" ? (
              <ILSendAccessReqModal
                sendAccessRequest={sendAccessRequest}
                data={ILProductGalleryCardDataList}
                isRequestSentLoading={isRequestSentLoading}
              />
            ) : ILProductGalleryCardDataList?.has_access ===
                "NO_REQUEST_NEEDED" ||
              ILProductGalleryCardDataList?.has_access === "ACCESS_GRANTED" ? (
              <ILAddYourCollectionModal
                collectionData={ILProductGalleryCardDataList}
                isLoading={isLoading}
                showSCModal={showSCModal}
                isShopCollectionModalOpen={isShopCollectionModalOpen}
                handleSCModalOk={handleSCModalOk}
                handleSCModalCancel={handleSCModalCancel}
                handleAddToShopCollection={handleAddToShopCollection}
              />
            ) : ILProductGalleryCardDataList?.has_access ===
              "PENDING_REQUEST" ? (
              <ILButton
                disabled
                isSmallSizeBtn
                isArrow
                ILBtnClass={"w-100 mt-5 mt-xxl-4 text-clr-pink"}
                variant={"gradient-bordered"}
              >
                Förfrågan Skickad
              </ILButton>
            ) : (
              <ILButton
                disabled
                isSmallSizeBtn
                isArrow
                ILBtnClass={"w-100 mt-5 mt-xxl-4 text-clr-pink"}
                variant={"gradient-bordered"}
              >
                Requested Access Denied
              </ILButton>
            )}
          </div>
        )
      ) : (
        <ILButton
          isSmallSizeBtn
          ILBtnClass={"w-100 mt-5 mt-xxl-4 text-clr-pink text-uppercase p sm"}
          variant={"gradient-bordered"}
          handleClick={loginHandler}
        >
          Logga in för att kopiera länken
        </ILButton>
      )}
    </div>
  );
};
