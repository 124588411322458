import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { doFetchBrandDetails } from "../../actions";
import { getDecryptionString } from "../../services";

export const useHomeHook = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [brandList, setBrandList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("_token") ? true : false
  );
  const [apiStatus, setApiStatus] = useState(false);
  const { userDetails } = useSelector(
    (state) => state.storeAuthenticateReducer
  );

  useEffect(() => {
    let queryParams = new URLSearchParams(location.search);
    userDetails &&
      queryParams.set("user-name", userDetails?.userData?.user_name);
    queryParams.set("res", apiStatus ? "true" : "false");

    navigate(`${location.pathname}?${queryParams.toString()}`, {
      replace: true,
    });
  }, [location?.pathname, userDetails, apiStatus]);

  useEffect(() => {
    fetchBrandList();
  }, []);

  const dashboardNavigate = () => {
    if (isLoggedIn) {
      navigate("/dashboard");
    } else {
      navigate("/sign-up");
    }
  };

  const fetchBrandList = async () => {
    try {
      setIsLoading(true);

      const requestBody = {
        isPaginate: true,
      };

      const brandsListResponse = await doFetchBrandDetails(requestBody);
      if (brandsListResponse?.status === 200) {
        setApiStatus(true);

        const decryptedBrandDetails = await JSON.parse(
          getDecryptionString(brandsListResponse?.data?.data)
        );
        setBrandList(decryptedBrandDetails?.rows);
      } else {
        setApiStatus(false);
        toast.error(brandsListResponse?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    brandList,
    navigate,
    dashboardNavigate,
  };
};
