import { Delete02Icon } from "hugeicons-react";
import { capitalize } from "lodash";
import {
  ILButton,
  ILDashboardCard,
  ILDashboardHeader,
  ILHead,
  ILModal,
  ILSocialShareMedia,
  ILTable,
} from "../../../components";
import { ILShareSocialMediaModal } from "../../../components/ILDashboardGalleryCard/ILShareSocialMediaModal";
import { appConfig } from "../../../config";
import { useCollectionProductViewAllListHook } from "../../../hooks/dashboard/myShop/collectionProductsViewAll.hook.";
import { countHandler } from "../../../services";

const ILMyProductsViewAllProductsCollection = () => {
  const {
    isLoading,
    productColumns,
    paginationData,
    productListData,
    collectionDetails,
    isShareSMModalOpen,
    isConfirmModalOpen,
    selectedSocialMedia,
    isSuccessfullyDeleteModalOpen,
    handleCopyLink,
    showShareSMModal,
    handleTableChange,
    handleDeleteSubmit,
    generateSharableLink,
    handleShareSMModalOk,
    handleConfirmModal,
    shareSocialMediaHandler,
    handleSelectSocialMedia,
    handleSuccessfullyDeleteModalClose,
  } = useCollectionProductViewAllListHook();

  return (
    <>
      <ILHead title="Visa alla produkter kollektioner | Min butik" />
      <ILDashboardHeader
        extra={true}
        title="Products"
        breadCrumbItems={[
          { title: "Hem", href: "/dashboard" },
          { title: "Min butik", href: "/dashboard/my-shop" },
          { title: `${capitalize(collectionDetails?.name)} collection` },
        ]}
      />
      {/* PRODUCTS TABLE STARTS */}
      <div className="d-flex align-items-center justify-content-between gap-4 mb-extra-small flex-sm-nowrap flex-wrap">
        <h5 className="big fw-semibold mb-0">{`${capitalize(
          collectionDetails?.name
        )} ${
          productListData?.rows?.length > 0
            ? `(${countHandler(collectionDetails?.product_count)})`
            : ""
        }`}</h5>
        <div className="d-flex justify-content-sm-end gap-3 flex-sm-nowrap flex-wrap">
          <ILButton variant={"primary-gradient"} isSmallSizeBtn>
            <ILSocialShareMedia
              socialShareBtn
              showShareSMModal={showShareSMModal}
            />
          </ILButton>
          <ILButton
            handleClick={handleConfirmModal}
            variant={"gradient-bordered"}
            isSmallSizeBtn
            ILBtnInnerSpanClass="d-flex align-items-center gap-4 gap-xxl-3"
          >
            <span className="d-flex me-2">
              <Delete02Icon
                size={18}
                color={"#db2f7f"}
                variant={"stroke"}
                strokeWidth={2}
              />
            </span>
            Radera kollektion
          </ILButton>
        </div>
      </div>
      <ILDashboardCard className="h-auto">
        <ILTable
          columns={productColumns}
          data={productListData?.rows}
          scroll={{ x: "max-content" }}
          isLoading={isLoading}
          pagination={true}
          current={paginationData?.page}
          pageSize={paginationData?.pageSize}
          total={productListData?.totalItems}
          handleTableChange={handleTableChange}
        />
      </ILDashboardCard>
      {/* PRODUCTS TABLE ENDS */}
      <ILModal
        open={isConfirmModalOpen}
        onOk={handleConfirmModal}
        onCancel={handleConfirmModal}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="518px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <div
                className="brand-logo brand-logo-big flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center mx-auto"
                style={{ borderColor: "#ED4F32", borderWidth: "3px" }}
              >
                <Delete02Icon
                  size={34}
                  color={"#ED4F32"}
                  variant={"stroke"}
                  strokeWidth={2}
                />
              </div>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4 lh-base">
                Vill du verkligen ta bort kollektionen?
              </h4>
              <p className="paragraph-sm-text paragraph-md-text text-center mb-0">
                Detta tar bort din kollektion från butiken?
              </p>
              <div className="d-flex align-items-center gap-4 modal-more-btn">
                <ILButton
                  ILBtnClass="w-50"
                  variant={"primary-gradient"}
                  onClick={handleDeleteSubmit}
                >
                  Ja, Bekräfta
                </ILButton>
                <ILButton
                  ILBtnClass="w-50"
                  variant={"gradient-bordered"}
                  onClick={handleConfirmModal}
                >
                  Nej, Avbryt
                </ILButton>
              </div>
            </div>
          </>
        }
        isFooterRemove
      />
      {/* SUCCESSFULLY DELETE MODAL */}
      <ILModal
        open={isSuccessfullyDeleteModalOpen}
        onOk={handleSuccessfullyDeleteModalClose}
        onCancel={handleSuccessfullyDeleteModalClose}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="518px"
        children={
          <>
            <div className="text-center d-flex flex-column justify-content-center modal-content-mh-inherit gap-5 gap-xxl-4">
              {/* <span className="lh-0">{Icons.circleRoundIcon}</span> */}
              <div
                className="brand-logo brand-logo-big flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center mx-auto"
                style={{ borderColor: "#ED4F32", borderWidth: "3px" }}
              >
                <Delete02Icon
                  size={34}
                  color={"#ED4F32"}
                  variant={"stroke"}
                  strokeWidth={2}
                />
              </div>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Kollektionen har raderats!
              </h4>
              <ILButton
                ILBtnClass="w-100"
                variant={"primary-gradient"}
                onClick={handleSuccessfullyDeleteModalClose}
              >
                Okej
              </ILButton>
            </div>
          </>
        }
        isFooterRemove
      />

      <ILModal
        open={isShareSMModalOpen}
        onOk={handleShareSMModalOk}
        onCancel={handleShareSMModalOk}
        title={
          <>
            <div className="d-flex align-items-center gap-3">
              Dela på sociala medier
            </div>
          </>
        }
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="518px"
        children={
          <>
            <ILShareSocialMediaModal
              handleCopyLink={() => handleCopyLink(collectionDetails)}
              liveProfileLink={`${appConfig.APP_URL}${collectionDetails?.short_code}`}
              shareHandler={shareSocialMediaHandler}
              selectedSocialMedia={selectedSocialMedia}
              generateSharableLink={generateSharableLink}
              handleSelectSocialMedia={handleSelectSocialMedia}
            />
          </>
        }
        isFooterRemove
      />
    </>
  );
};

export default ILMyProductsViewAllProductsCollection;
