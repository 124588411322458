import { Checkbox } from "antd";
import { ILRangeSlider } from "../../components";
import {
  CSGenderFilterCheckbox,
  CSBrandCategoriesFilterCheckbox,
  CSShopVisitorsFilterCheckbox,
  CSShopClicksFilterCheckbox,
  CSProductPriceFilterCheckbox,
  CSPayPerClickFilterCheckbox,
} from "../../constants/data";

export const ILCSFilterCheckboxList = ({
  name,
  brandList,
  citiesList,
  categoryList,
  handleChange,
  countryList,
  creatorsFilterData,
  CSBrandFilterCheckboxCon,
  CSCitiesFilterCheckboxCon,
  CSGenderFilterCheckboxCon,
  CSCountryFilterCheckboxCon,
  CSCategoryFilterCheckboxCon,
  CSBrandCategoriesFilterCheckboxCon,
  CSClicksFilterCheckboxCon,
  CSPriceFilterCheckboxCon,
  CSRatingFilterCheckboxCon,
  CSPayPerClicksFilterCheckboxCon,
  handleSelectChange,
  CSShopVisitorsFilterCheckboxCon,
}) => {
  return (
    <>
      <div className="d-flex flex-column gap-5 gap-xl-4">
        {CSGenderFilterCheckboxCon && (
          <>
            {CSGenderFilterCheckbox.map((sfProductItem, sfProductIndex) => {
              return (
                <Checkbox
                  name={name}
                  value={sfProductItem?.value}
                  // onChange={handleChange}
                  onChange={(event) => handleChange(event, "gender")}
                  checked={creatorsFilterData?.gender?.includes(
                    sfProductItem.value
                  )}
                  className="basic-checkbox-style"
                  key={sfProductIndex}
                >
                  {sfProductItem.label}
                </Checkbox>
              );
            })}
          </>
        )}

        {CSCountryFilterCheckboxCon && (
          <div className="d-flex flex-column country-scroll small-track">
            {countryList?.map((sfProductItem, sfProductIndex) => {
              return (
                <Checkbox
                  name={sfProductItem?.id}
                  value={sfProductItem?.id}
                  onChange={(event) => handleChange(event, "country")}
                  className="basic-checkbox-style"
                  key={sfProductIndex}
                  checked={creatorsFilterData?.country?.includes(
                    sfProductItem.id
                  )}
                >
                  {sfProductItem.label}
                </Checkbox>
              );
            })}
          </div>
        )}

        {CSCitiesFilterCheckboxCon && (
          <div className="d-flex flex-column country-scroll small-track">
            {citiesList?.map((sfProductItem, sfProductIndex) => {
              return (
                <Checkbox
                  name={sfProductItem?.id}
                  value={sfProductItem?.value}
                  onChange={(event) => handleChange(event, "cities")}
                  className="basic-checkbox-style"
                  key={sfProductIndex}
                  checked={creatorsFilterData?.cities?.includes(
                    sfProductItem.value
                  )}
                >
                  {sfProductItem.label}
                </Checkbox>
              );
            })}
          </div>
        )}

        {CSCategoryFilterCheckboxCon && (
          <div className="d-flex flex-column country-scroll small-track">
            {categoryList?.map((sfProductItem, sfProductIndex) => {
              return (
                <Checkbox
                  name={"categoryId"}
                  value={sfProductItem?.value}
                  onChange={(event) => handleChange(event, "categoryId")}
                  className="basic-checkbox-style"
                  key={sfProductIndex}
                  checked={creatorsFilterData?.categoryId?.includes(
                    sfProductItem.value
                  )}
                >
                  {sfProductItem.label}
                </Checkbox>
              );
            })}
          </div>
        )}

        {CSBrandFilterCheckboxCon && (
          <div className="d-flex flex-column country-scroll small-track">
            {brandList?.map((sfProductItem, sfProductIndex) => {
              return (
                <Checkbox
                  name={"brandId"}
                  value={sfProductItem?.value}
                  onChange={(event) => handleChange(event, "brandId")}
                  className="basic-checkbox-style"
                  key={sfProductIndex}
                  checked={creatorsFilterData?.brandId?.includes(
                    sfProductItem.value
                  )}
                >
                  {sfProductItem.label}
                </Checkbox>
              );
            })}
          </div>
        )}

        {CSBrandCategoriesFilterCheckboxCon && (
          <>
            {CSBrandCategoriesFilterCheckbox.map(
              (sfProductItem, sfProductIndex) => {
                return (
                  <Checkbox
                    name={name}
                    onChange={handleChange}
                    className="basic-checkbox-style"
                    key={sfProductIndex}
                  >
                    {sfProductItem.label}
                  </Checkbox>
                );
              }
            )}
          </>
        )}

        {CSClicksFilterCheckboxCon && (
          <>
            {CSShopClicksFilterCheckbox?.map(
              (sfProductItem, sfProductIndex) => {
                return (
                  <Checkbox
                    name={name}
                    value={sfProductItem?.value}
                    onChange={(event) => handleChange(event, "clicks")}
                    className="basic-checkbox-style"
                    key={sfProductIndex}
                    checked={creatorsFilterData?.clicks?.includes(
                      sfProductItem.value
                    )}
                  >
                    {sfProductItem.label}
                  </Checkbox>
                );
              }
            )}
          </>
        )}

        {CSPriceFilterCheckboxCon && (
          <>
            {CSProductPriceFilterCheckbox?.map(
              (sfProductItem, sfProductIndex) => {
                return (
                  <Checkbox
                    name={name}
                    value={sfProductItem?.value}
                    onChange={(event) => handleChange(event, "priceRange")}
                    className="basic-checkbox-style"
                    key={sfProductIndex}
                    checked={creatorsFilterData?.priceRange?.includes(
                      sfProductItem.value
                    )}
                  >
                    {sfProductItem.label}
                  </Checkbox>
                );
              }
            )}
          </>
        )}

        {CSPayPerClicksFilterCheckboxCon && (
          <>
            {CSPayPerClickFilterCheckbox?.map(
              (sfProductItem, sfProductIndex) => {
                return (
                  <Checkbox
                    name={name}
                    value={sfProductItem?.value}
                    onChange={(event) => handleChange(event, "ppcRange")}
                    className="basic-checkbox-style"
                    key={sfProductIndex}
                    checked={creatorsFilterData?.ppcRange?.includes(
                      sfProductItem.value
                    )}
                  >
                    {sfProductItem.label}
                  </Checkbox>
                );
              }
            )}
          </>
        )}

        {CSRatingFilterCheckboxCon && (
          <div>
            <ILRangeSlider
              name={name}
              min={0}
              max={5}
              value={creatorsFilterData?.rating}
              handleChange={(event) => handleSelectChange(event, "rating")}
            />
            <div className="d-flex justify-content-between align-items-center gap-3 mt-5 mt-xxl-2">
              <span className="paragraph-sm-text paragraph-xs-text d-block w-50">
                0
              </span>
              <span className="paragraph-sm-text paragraph-xs-text d-block w-50 text-end">
                5
              </span>
            </div>
          </div>
        )}

        {/* {CSSellFilterCheckboxCon && (
          <div>
            <ILRangeSlider
              name={name}
              value={creatorsFilterData?.sell}
              handleChange={(event) => handleSelectChange(event, "sell")}
            />
            <div className="d-flex justify-content-between align-items-center gap-3 mt-5 mt-xxl-2">
              <span className="paragraph-sm-text paragraph-xs-text d-block w-50">
                0
              </span>
              <span className="paragraph-sm-text paragraph-xs-text d-block w-50 text-end">
                100%
              </span>
            </div>
          </div>
        )} */}
        {CSShopVisitorsFilterCheckboxCon && (
          <>
            {CSShopVisitorsFilterCheckbox?.map(
              (sfProductItem, sfProductIndex) => {
                return (
                  <Checkbox
                    name={name}
                    value={sfProductItem?.value}
                    onChange={(event) => handleChange(event, "visitors")}
                    className="basic-checkbox-style"
                    key={sfProductIndex}
                    checked={creatorsFilterData?.visitors?.includes(
                      sfProductItem.value
                    )}
                  >
                    {sfProductItem.label}
                  </Checkbox>
                );
              }
            )}
          </>
        )}
      </div>
    </>
  );
};
