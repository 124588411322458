import clsx from "clsx";
import React from "react";
import { Col, Row } from "antd";
import { Tick02Icon } from "hugeicons-react";
import { ILButton, ILCheckbox, ILHead, ILTab } from "../../components";
import { empowerMarketingDataList } from "../../constants/data";
import { influencerHero } from "../../constants/imageData";
import { useInfluencerHook } from "../../hooks/website/influencers.hook.";
import styles from "./ILInfluencers.module.css";
import { appConfig } from "../../config";

const ILInfluencers = () => {
  const {
    categoryList,
    categoryType,
    dashboardNavigate,
    handleInputChange,
    handleSelectChange,
  } = useInfluencerHook();

  const brandCategoriesTabItem = [
    {
      key: "product",
      label: "Produkter",
    },
    {
      key: "service",
      label: "Tjänster",
    },
  ];

  return (
    <>
      <ILHead title="Influencers" />
      {/* HERO SECTION START */}
      <section
        className={clsx(
          "bg-dark-purple position-relative text-white hero-gradient-border min-vh-xl-100 inner-hero-gradient z-1",
          styles.aboutUsHero
        )}
      >
        <div className="position-relative inner-section-gradient">
          {/* <div className={clsx(styles.influencerHeroContainer)}> */}
          <div className="container">
            <div className="w-100 min-vh-xl-100 hero-section-space pb-0 d-flex align-items-center flex-column flex-xl-row">
              <div
                className={clsx(
                  styles.influencerHeroContentCard,
                  "align-self-center"
                )}
              >
                <div
                  className={clsx(
                    styles.heroContentWrap,
                    "hero-content-wrap align-self-center pe-xl-5 text-center text-xl-start mt-5 pt-5 mt-xl-0 pt-xl-0 mb-5 pb-5 mb-xl-0 pb-xl-0"
                  )}
                >
                  <h1 className="primary-title primary-xl-title mb-5 mb-xl-3 text-center text-xl-start">
                    Företag möter influencers&nbsp;
                    <span className="d-block d-md-inline">ILONSI butiker</span>
                  </h1>
                  <p className="text-blue-gray big fw-medium mb-0 text-center text-xl-start line-height-normal">
                    Få ut mer av dina sociala medier genom att marknadsföra
                    produkter och tjänster för företag direkt via din ILONSI
                    butik eller dina sociala medieplattformar.
                  </p>
                  <ILButton
                    ILBtnClass={"section-xs-mt mx-auto mx-xl-0"}
                    isArrow
                    variant={"primary-gradient"}
                    handleClick={dashboardNavigate}
                  >
                    Skapa konto gratis
                  </ILButton>
                </div>
              </div>
              <div
                className={clsx(
                  styles.influencerHeroImgCard,
                  // styles.heroImg,
                  "d-flex mx-auto ms-xl-auto me-xl-0 mt-auto pt-4 pb-5 mt-xl-0 pt-xl-0 pb-xl-0 mb-5 mb-xl-0"
                )}
              >
                <div className="text-end">
                  <img
                    src={influencerHero}
                    alt="advertising agency | ilonsi"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* HERO SECTION END */}
      {/* EMPOWER MARKETING SECTION START */}
      <section className={clsx(styles.empowerSection, "section-md-pt")}>
        <div className="container">
          <span className="secondary-title d-block mb-3 text-center">
            Ta del av den växande handeln på sociala medier med ILONSI
          </span>
          <p className="title-label fw-medium mb-0">
            Få ut mer av dina sociala medier och utnyttja den växande handeln på
            sociala medier.
          </p>
          <div
            className={clsx(styles.empowerMarketingCardWrap, "section-md-mt")}
          >
            <div
              className={clsx(
                styles.empowerMarketingCard,
                "alternate-row-data-container"
              )}
            >
              {empowerMarketingDataList &&
                empowerMarketingDataList.map((item, index) => {
                  return (
                    <Row
                      gutter={[
                        { xs: 10, sm: 20, md: 30 },
                        { xs: 10, sm: 20, md: 30 },
                      ]}
                      key={index}
                      className="align-items-center"
                    >
                      <Col
                        className={styles.empowerTextContent}
                        lg={14}
                        xs={24}
                      >
                        <div
                          className={"empower-text-content-wrap mt-4 mt-sm-0"}
                        >
                          <span className="secondary-title d-block mb-3">
                            {item.title}
                          </span>
                          <div className="mb-5">
                            {item.description &&
                              item.description.map((item, descriptionIndex) => {
                                return (
                                  <p
                                    key={descriptionIndex}
                                    className="title-label text-start"
                                  >
                                    {item.descriptionItem}
                                  </p>
                                );
                              })}
                          </div>
                          <div className={"point-list"}>
                            {item.pointListItemData &&
                              item.pointListItemData.map(
                                (pointListItem, pointListIndex) => {
                                  return (
                                    <div
                                      key={pointListIndex}
                                      className={"point-list-item"}
                                    >
                                      <span className="flex-shrink-0">
                                        <Tick02Icon
                                          size={24}
                                          color={"var(--clr-dark-purple)"}
                                          variant={"stroke"}
                                        />
                                      </span>
                                      <p className="big fw-medium mb-0">
                                        {pointListItem.pointDescription}
                                      </p>
                                    </div>
                                  );
                                }
                              )}
                          </div>
                          <ILButton
                            ILBtnClass={"min-w-auto"}
                            isArrow
                            variant={"primary-gradient"}
                            handleClick={dashboardNavigate}
                          >
                            Skapa konto gratis
                          </ILButton>
                        </div>
                      </Col>
                      <Col
                        className={clsx(
                          styles.empowerImgContent,
                          "mx-lg-0 mx-auto"
                        )}
                        lg={10}
                        sm={18}
                        xs={24}
                      >
                        <div
                          className={clsx(
                            "empower-img-wrap",
                            item.empowerClass
                          )}
                        >
                          <img
                            src={item.image}
                            alt="advertising agency | ilonsi"
                            width={"100%"}
                            height={"100%"}
                            className={clsx(
                              item.empowerImgClass,
                              "object-position-center w-100 h-100 aspect-ratio-1-1"
                            )}
                          />
                        </div>
                      </Col>
                    </Row>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
      {/* EMPOWER MARKETING SECTION END */}

      {/* REACHING CATEGORIES SECTION START */}
      <section className="section-md-py">
        <div className="container">
          <div className={styles.sectionTitleContainer}>
            <span className="secondary-title d-block mb-3 text-center">
              Kom igång med samarbeten idag
            </span>
            <p className="title-label fw-medium mb-0">
              Alla företag, oavsett bransch eller storlek, är välkomna till
              ILONSI. Välj den bransch du gillar och tjäna pengar på dina
              sociala medier samtidigt som du hjälper entreprenörer att växa.
            </p>
          </div>
          <div className="section-sm-mt d-flex flex-column">
            <div className="tab-wrapper influencer-tab-wrap align-self-center">
              <ILTab
                defaultActiveKey="1"
                tabItem={brandCategoriesTabItem}
                onChange={(event) => handleSelectChange(event, "category")}
                tabClassName="influencer-tab-wrap"
              />
            </div>
            <div className={styles.reachingCategoriesCheckboxesList}>
              <ILCheckbox
                checkboxList={categoryList}
                iconImg
                name="categoryId"
                value={categoryType?.categoryId}
                handleChange={handleInputChange}
              ></ILCheckbox>
            </div>
          </div>
        </div>
      </section>
      {/* REACHING CATEGORIES SECTION END */}
    </>
  );
};

export default ILInfluencers;
