export const emailRegex = RegExp(
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

export const nameRegex = RegExp(/^[a-zA-Z ]{1,100}$/);

export const userNameRegex = RegExp(/^[a-zA-Z0-9._]+$/);

export const nameAlphaNumericOnlyRegex = RegExp(
  /^(?=.*[A-Za-zÀ-ÖØ-öø-ÿ])[A-Za-zÀ-ÖØ-öø-ÿ0-9!@#$%^&*(),.?":{}|<>_\-+\[\] /|]+$/
);

export const urlRegex = RegExp(
  /^(https?:\/\/)?(www\.)?[\w.-]+\.[a-zA-Z]{2,}([\/\?#][^\s]*)?$/
);

export const bioRegex = /^.{1,100}$/;

export const numberRegex = RegExp(/^\d+(\.\d+)?$/);

export const socialSecurityRegex = RegExp(/^\d+(-?\d+)*(\.\d+)?$/);

export const imageFormatRegex = /^.*\.(jpg|jpeg|png)$/i;

export const passwordRegex = RegExp(
  /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#^!%*?&])[A-Za-z\d@#^$!%*?&]*$/
);

export const numberOnlyRegex = RegExp(/^[0-9]+$/);
export const numberAlphaNumericOnlyRegex = RegExp(/^[0-9a-zA-Z-]+$/);
export const nameAccountRegex = RegExp(/^([a-zA-Z\/ ]){1,250}$/i);
