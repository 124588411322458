import clsx from "clsx";
import copy from "copy-to-clipboard";
import { Delete02Icon, SquareLock02Icon } from "hugeicons-react";
import { capitalize } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  doFetchAllCollection,
  doFetchAllProducts,
  doFetchAllServices,
  doFetchDeleteCollection,
  doFetchDeleteProduct,
  doFetchDeleteService,
} from "../../../actions";
import { ILSocialShareMedia } from "../../../components";
import { appConfig } from "../../../config";
import { Icons } from "../../../constants/icons";
import { getDecryptionString, isEmpty } from "../../../services";

export const useShopListHook = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState({
    productsList: false,
    serviceList: false,
    collectionList: false,
    productDelete: false,
    serviceDelete: false,
    collectionDelete: false,
  });
  const [productList, setProductList] = useState({});
  const [serviceList, setServiceList] = useState({});
  const [collectionList, setCollectionList] = useState({});
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isSuccessfullyDeleteModalOpen, setIsSuccessfullyDeleteModalOpen] =
    useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [isProduct, setIsProduct] = useState(false);
  const [isService, setIsService] = useState(false);
  const [selectedSocialMedia, setSelectedSocialMedia] = useState({});
  const [isShareSMModalOpen, setIsShareSMModalOpen] = useState(false);
  const [apiStatus, setApiStatus] = useState(false);
  const { userDetails } = useSelector(
    (state) => state.storeAuthenticateReducer
  );

  useEffect(() => {
    let queryParams = new URLSearchParams(location.search);
    userDetails &&
      queryParams.set("user-name", userDetails?.userData?.user_name);
    queryParams.set("res", apiStatus ? "true" : "false");

    navigate(`${location.pathname}?${queryParams.toString()}`, {
      replace: true,
    });
  }, [location?.pathname, userDetails, apiStatus]);

  useEffect(() => {
    localStorage?.removeItem("data");
    fetchAllProducts();
    fetchAllServices();
    fetchAllCollections();
  }, []);

  const showShareSMModal = () => {
    setIsShareSMModalOpen(true);
  };

  const handleShareSMModalOk = () => {
    setIsShareSMModalOpen(false);
    setSelectedSocialMedia({});
  };

  const shareSocialMediaHandler = () => {
    setIsShareSMModalOpen(false);
    generateSharableLink();
  };

  const handleCopyLink = (type) => {
    if (type === "liveProfile") {
      copy(`${appConfig.APP_URL}shop/${userDetails?.userData?.user_name}`);
      toast.success("Länk kopierad");
    } else {
      if (type === "collection") {
        copy(
          `${appConfig.APP_URL}collection/${selectedData?.data?.short_code}`
        );
      } else {
        copy(
          `${appConfig.APP_URL}${
            type === "product"
              ? selectedData?.data?.product?.short_code
              : selectedData?.data?.service?.short_code
          }`
        );
      }
      toast.success("Länk kopierad");
    }
  };

  const navigateLiveProfile = () => {
    navigate(`/shop/${userDetails?.userData?.user_name}`, {
      replace: true,
    });
  };

  const showConfirmModal = (type, data) => {
    if (type === "product") {
      setIsProduct(true);
    } else if (type === "service") {
      setIsService(true);
    }
    setSelectedData({ data: data, type: type });
    setIsConfirmModalOpen(true);
  };

  const handleConfirmModalOk = () => {
    setIsConfirmModalOpen(false);
  };

  const handleConfirmModalCancel = () => {
    setIsConfirmModalOpen(false);
    setIsProduct(false);
    setIsService(false);
  };

  const showSuccessfullyDeleteModal = () => {
    handleDeleteSubmit();
  };

  const handleSuccessfullyDeleteModalOk = () => {
    setIsProduct(false);
    setIsService(false);
    setSelectedData("");
    setIsSuccessfullyDeleteModalOpen(false);
  };

  const handleSuccessfullyDeleteModalCancel = () => {
    setIsSuccessfullyDeleteModalOpen(false);
  };

  const handleDeleteSubmit = async () => {
    try {
      if (isProduct) {
        setIsLoading((prevState) => ({ ...prevState, productDelete: true }));
      } else if (isService) {
        setIsLoading((prevState) => ({ ...prevState, serviceDelete: true }));
      } else {
        setIsLoading((prevState) => ({ ...prevState, collectionDelete: true }));
      }
      let deleteResponse;
      if (isService) {
        deleteResponse = await doFetchDeleteService(selectedData?.data?.id);
      } else if (isProduct) {
        deleteResponse = await doFetchDeleteProduct(selectedData?.data?.id);
      } else {
        deleteResponse = await doFetchDeleteCollection(selectedData?.data?.id);
      }

      if (deleteResponse?.status === 200) {
        setIsConfirmModalOpen(false);
        setIsSuccessfullyDeleteModalOpen(true);
        if (selectedData?.type === "product") {
          fetchAllProducts();
        } else if (selectedData?.type === "service") {
          fetchAllServices();
        } else {
          fetchAllCollections();
        }
      } else {
        toast.error(deleteResponse?.data?.message);
      }

      setIsLoading((prevState) => ({
        ...prevState,
        productDelete: false,
        serviceDelete: false,
        collectionDelete: false,
      }));
    } catch (error) {
      setIsLoading((prevState) => ({
        ...prevState,
        productDelete: false,
        serviceDelete: false,
        collectionDelete: false,
      }));
      console.error(
        "Error occurred during deleting product ,service and collection:",
        error
      );
    }
  };

  const productColumns = useMemo(() => {
    return [
      {
        title: "Produkter",
        dataIndex: "products",
        key: "products",
        render: (_, item) => (
          <div
            className="d-flex align-items-center gap-4 cursor-pointer"
            onClick={() =>
              navigate(
                `/dashboard/my-shop/product-details/${item?.product?.id}`
              )
            }
          >
            <div
              className={clsx(
                "ratio ratio-1x1 product-service-img flex-shrink-0 bg-clr-black-gray"
              )}
            >
              <img
                className={"object-fit-cover h-100 w-100"}
                src={item?.product?.image}
                alt={item?.product?.name}
              />
            </div>
            <div>
              <p className="fw-medium mb-0 text-ellipsis line-clamp-1 hover-text">
                {capitalize(item?.product?.name)}
              </p>
              <p className="mb-0 text-gray text-ellipsis line-clamp-1 word-break-word">
                {item?.product?.description !== ("" || null || "null") &&
                  capitalize(item?.product?.description)}
              </p>
              <p className="fw-medium mb-0 gradient-text">
                {item?.product?.price} KR
              </p>
            </div>
          </div>
        ),
        width: "300px",
      },
      {
        title: "Företag",
        dataIndex: "brand",
        key: "brand",
        render: (_, item) => (
          <>
            <div className="d-flex align-items-center gap-3">
              <div className="table-logo-img flex-shrink-0 ratio ratio-1x1">
                <img
                  width={"100%"}
                  height={"100%"}
                  className={"object-fit-cover radius-inherit"}
                  src={item?.product?.brand?.profile_image}
                  alt={item?.product?.brand?.name}
                />
              </div>
              <p className="fw-medium mb-0">{item?.product?.brand?.name}</p>
            </div>
          </>
        ),
        width: "350px",
      },
      {
        title: "Klicks",
        dataIndex: "clicks",
        key: "clicks",
        width: "120px",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {item?.product?.clicks}
          </p>
        ),
      },
      {
        title: "CPC",
        dataIndex: "ppc",
        key: "ppc",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {item?.product?.ppc_price}
          </p>
        ),
        width: "120px",
      },
      {
        title: "Försäljning",
        dataIndex: "sell",
        key: "sell",
        width: "120px",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {item?.product?.sells}
          </p>
        ),
      },
      {
        title: "Provision %",
        dataIndex: "sale",
        key: "sale",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {isEmpty(item?.product?.sell_commission)
              ? "-"
              : item?.product?.sell_commission + " %"}
          </p>
        ),
        width: "120px",
      },
      {
        title: "Redigera",
        dataIndex: "action",
        key: "action",
        render: (_, item) => (
          <>
            <div
              className="d-flex align-items-center gap-5 action-btns-wrap cursor-pointer"
              onClick={() => setSelectedData({ data: item, type: "product" })}
            >
              <span className="w-50 text-center">
                <ILSocialShareMedia showShareSMModal={showShareSMModal} />
              </span>
              <span className="w-50 text-center">
                <Delete02Icon
                  size={20}
                  color={"#ED4F32"}
                  variant={"stroke"}
                  onClick={() => showConfirmModal("product", item)}
                />
              </span>
            </div>
          </>
        ),
        fixed: "right",
        width: "100px",
      },
    ];
  }, [productList, userDetails]);

  const serviceColumns = useMemo(() => {
    return [
      {
        title: "Tjänster/Plats",
        dataIndex: "services",
        key: "services",
        render: (_, item) => (
          <div
            className="d-flex align-items-center gap-4"
            onClick={() =>
              navigate(
                `/dashboard/my-shop/service-details/${item?.service?.id}`
              )
            }
          >
            <div className={clsx("ratio ratio-1x1 product-service-img")}>
              <Link
                to={`service-details/${item?.service?.id}`}
                className="radius-inherit"
              >
                <img
                  className={"object-fit-cover h-100 w-100"}
                  src={item?.service?.image}
                  alt={item?.service?.name}
                />
              </Link>
              {item?.service?.request_to_access && (
                <span className="lock-icon-card d-flex rounded-circle align-items-center justify-content-center">
                  <SquareLock02Icon
                    size={10}
                    color={"#000000"}
                    variant={"stroke"}
                  />
                </span>
              )}
            </div>
            <div>
              <Link
                to={`service-details/${item?.service?.id}`}
                className="text-black"
              >
                <p className="fw-medium mb-0 text-ellipsis line-clamp-2">
                  <a href="" className="text-dark-purple">
                    {capitalize(item?.service?.name)}
                  </a>
                </p>
                <p className="mb-0 text-gray text-ellipsis line-clamp-1 word-break-word">
                  {item?.service?.address !== "undefined"
                    ? capitalize(item?.service?.address)
                    : item?.service?.city?.name +
                      ", " +
                      item?.service?.country?.name}
                </p>
                <p className="fw-medium mb-0 gradient-text">
                  {item?.service?.price} KR
                </p>
              </Link>
            </div>
          </div>
        ),
        width: "300px",
      },
      {
        title: "Omdömen",
        dataIndex: "ratings",
        key: "ratings",
        width: "120px",
        render: (_, item) => (
          <>
            {item?.service?.rating > 0 ? (
              <>
                <div className="d-flex align-items-center gap-2">
                  {Icons.star}
                  <p className="fw-medium gradient-text max-w-max-content mb-0">
                    {item?.service?.rating}
                  </p>
                </div>
              </>
            ) : (
              "-"
            )}
          </>
        ),
      },
      {
        title: "Klicks",
        dataIndex: "clicks",
        key: "clicks",
        width: "120px",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {item?.service?.clicks}
          </p>
        ),
      },
      {
        title: "CPC",
        dataIndex: "ppc",
        key: "ppc",
        width: "120px",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {item?.service?.ppc_price} KR
          </p>
        ),
      },
      {
        title: "Försäljning",
        dataIndex: "sell",
        key: "sell",
        width: "120px",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {item?.service?.sells}
          </p>
        ),
      },
      {
        title: "Provision %",
        dataIndex: "sale",
        key: "sale",
        width: "120px",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {isEmpty(item?.service?.sell_commission)
              ? "-"
              : item?.service?.sell_commission + " %"}
          </p>
        ),
      },
      {
        title: "Redigera",
        dataIndex: "action",
        key: "action",
        fixed: "right",
        width: "100px",
        render: (_, item) => (
          <>
            <div
              className="d-flex align-items-center gap-5 action-btns-wrap cursor-pointer"
              onClick={() => setSelectedData({ data: item, type: "service" })}
            >
              <span className="w-50 text-center">
                <ILSocialShareMedia showShareSMModal={showShareSMModal} />
              </span>
              <span className="w-50 text-center">
                <Delete02Icon
                  size={20}
                  color={"#ED4F32"}
                  variant={"stroke"}
                  onClick={() => showConfirmModal("service", item)}
                />
              </span>
            </div>
          </>
        ),
      },
    ];
  }, [serviceList]);

  const fetchAllProducts = async () => {
    try {
      setIsLoading((prevState) => ({ ...prevState, productsList: true }));

      const productListResponse = await doFetchAllProducts(4);

      if (productListResponse?.status === 200) {
        setApiStatus(true);

        const decryptedProductListData = await JSON.parse(
          getDecryptionString(productListResponse?.data?.data)
        );

        setProductList(decryptedProductListData);
      } else {
        setApiStatus(false);
      }
      setIsLoading((prevState) => ({ ...prevState, productsList: false }));
    } catch (error) {
      setIsLoading((prevState) => ({ ...prevState, productsList: false }));
      console.error("Error occurred during fetching products list:", error);
    }
  };

  const fetchAllServices = async () => {
    try {
      setIsLoading((prevState) => ({ ...prevState, serviceList: true }));

      const serviceListResponse = await doFetchAllServices(4);

      if (serviceListResponse?.status === 200) {
        const decryptedServiceListData = await JSON.parse(
          getDecryptionString(serviceListResponse?.data?.data)
        );

        setServiceList(decryptedServiceListData);
      }
      setIsLoading((prevState) => ({ ...prevState, serviceList: false }));
    } catch (error) {
      setIsLoading((prevState) => ({ ...prevState, serviceList: false }));
      console.error("Error occurred during fetching services list:", error);
    }
  };

  const fetchAllCollections = async () => {
    try {
      setIsLoading((prevState) => ({ ...prevState, collectionList: true }));

      const collectionListResponse = await doFetchAllCollection(4);

      if (collectionListResponse?.status === 200) {
        const decryptedCollectionListData = await JSON.parse(
          getDecryptionString(collectionListResponse?.data?.data)
        );

        setCollectionList(decryptedCollectionListData);
      }
      setIsLoading((prevState) => ({ ...prevState, collectionList: false }));
    } catch (error) {
      setIsLoading((prevState) => ({ ...prevState, collectionList: false }));
      console.error("Error occurred during fetching collection list:", error);
    }
  };

  const generateSharableLink = () => {
    window.open(
      `${selectedSocialMedia?.link}${appConfig.APP_URL}${
        selectedData?.type !== "product" && selectedData?.type !== "service"
          ? "collection/"
          : ""
      }${
        selectedData?.data?.product?.short_code ||
        selectedData?.data?.service?.short_code ||
        selectedData?.data?.short_code
      }`,
      "_blank"
    );
    setSelectedSocialMedia({});
    setIsShareSMModalOpen(false);
  };

  const handleSelectSocialMedia = (data) => {
    setSelectedSocialMedia(data);
  };

  return {
    isLoading,
    productList,
    userDetails,
    serviceList,
    selectedData,
    productColumns,
    collectionList,
    serviceColumns,
    isConfirmModalOpen,
    isShareSMModalOpen,
    selectedSocialMedia,
    isSuccessfullyDeleteModalOpen,
    navigate,
    handleCopyLink,
    setSelectedData,
    showConfirmModal,
    showShareSMModal,
    navigateLiveProfile,
    handleConfirmModalOk,
    generateSharableLink,
    handleShareSMModalOk,
    handleSelectSocialMedia,
    shareSocialMediaHandler,
    handleConfirmModalCancel,
    showSuccessfullyDeleteModal,
    handleSuccessfullyDeleteModalOk,
    handleSuccessfullyDeleteModalCancel,
  };
};
