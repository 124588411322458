import { Col, Row } from "antd";
import { ILHead, ILInnerHero } from "../../components";
import { Link } from "react-router-dom";

export const ILPrivacyPolicy = () => {
  return (
    <>
      <ILHead title="Integritetspolicy" />
      <ILInnerHero
        className="pb-5 hero-gradient-border"
        heroTitle="Integritetspolicy"
        heroParagraph="Användarvillkor för Företag hos ILONSI AB"
      />
      <section className="mt-4 section-sm-py">
        <div className="container">
          <Row className="d-flex flex-column gap-5">
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold lh-base">1. Inledning</h4>
              <p className="big text-gray mb-0">
                Välkommen till ILONSI AB! Vi värnar om din integritet och är
                måna om att skydda de personuppgifter som samlas in från dig som
                influenser på vår plattform. Denna integritetspolicy beskriver
                hur vi samlar in, använder, lagrar och skyddar dina
                personuppgifter.
              </p>
            </Col>
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold">2. Ansvarig för personuppgifter</h4>
              <p className="big text-gray">
                ILONSI AB, organisationsnummer 559055-0587, är
                personuppgiftsansvarig och ansvarar för behandlingen av de
                personuppgifter som tillhandahålls i samband med användningen av
                våra tjänster.
              </p>
            </Col>
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold">3. Insamling av personuppgifter</h4>
              <p className="big text-gray">
                Vi samlar in personuppgifter för att kunna tillhandahålla och
                förbättra våra tjänster. Detta inkluderar:
              </p>
              <ul className="ps-5 d-flex flex-column gap-4 list-style-disc">
                <li className="p big text-gray mb-0">
                  Kontaktinformation: Namn, e-postadress, telefonnummer och
                  adress.
                </li>
                <li className="p big text-gray mb-0">
                  Användardata: Information om hur du använder våra tjänster,
                  exempelvis inloggningsuppgifter och aktivitet på plattformen.
                </li>
                <li className="p big text-gray mb-0">
                  Teknisk data: IP-adress, webbläsartyp och andra tekniska
                  uppgifter som härleds från användningen av vår plattform.
                </li>
              </ul>
            </Col>
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold">
                4. Syfte med behandling av personuppgifter
              </h4>
              <p className="big text-gray">
                Vi behandlar personuppgifter för att:
              </p>
              <ul className="ps-5 d-flex flex-column gap-4 list-style-disc">
                <li className="p big text-gray mb-0">
                  Tillhandahålla och administrera våra tjänster.
                </li>
                <li className="p big text-gray mb-0">
                  Underlätta samarbeten mellan dig och företag.
                </li>
                <li className="p big text-gray mb-0">
                  Kommunicera med dig gällande support eller uppdateringar.
                </li>
                <li className="p big text-gray mb-0">
                  Förbättra och utveckla våra tjänster.
                </li>
                <li className="p big text-gray mb-0">
                  Följa lagstadgade skyldigheter.
                </li>
              </ul>
            </Col>
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold">5. Delning av personuppgifter</h4>
              <p className="big text-gray">
                Vi delar endast personuppgifter med tredje parter när det är
                nödvändigt för att tillhandahålla våra tjänster eller när vi är
                skyldiga enligt lag. Detta kan inkludera:
              </p>
              <ul className="ps-5 d-flex flex-column gap-4 list-style-disc">
                <li className="p big text-gray mb-0">
                  Betalningsleverantörer för hantering av transaktioner.
                </li>
                <li className="p big text-gray mb-0">
                  Samarbetspartners som är involverade i tjänstens genomförande.
                </li>
                <li className="p big text-gray mb-0">
                  Myndigheter vid juridiska åtaganden.
                </li>
              </ul>
              <p className="big text-gray">
                Underleverantörer som hanterar data på uppdrag av oss, där vi
                säkerställer att adekvata säkerhetsåtgärder är på plats.
              </p>
            </Col>
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold">6. Lagring av personuppgifter</h4>
              <p className="big text-gray">
                Vi lagrar personuppgifter så länge det är nödvändigt för att
                uppfylla de syften som beskrivs i denna policy eller så länge
                det krävs enligt lag. Vissa uppgifter kan behöva sparas längre
                enligt bokförings- eller andra lagkrav.
              </p>
            </Col>
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold">7. Dina rättigheter</h4>
              <p className="big text-gray">Som influenser har du rätt att:</p>
              <ul className="ps-5 d-flex flex-column gap-4 list-style-disc">
                <li className="p big text-gray mb-0">
                  Begära tillgång till de personuppgifter vi behandlar om dig.
                </li>
                <li className="p big text-gray mb-0">
                  Rätta felaktiga eller ofullständiga personuppgifter.
                </li>
                <li className="p big text-gray mb-0">
                  Begära radering av dina personuppgifter (med vissa undantag
                  enligt lag).
                </li>
                <li className="p big text-gray mb-0">
                  Invända mot behandling av dina personuppgifter.
                </li>
                <li className="p big text-gray mb-0">
                  Begära att behandlingen begränsas.
                </li>
                <li className="p big text-gray mb-0">
                  Begära dataportabilitet, vilket innebär att du kan få dina
                  personuppgifter i ett strukturerat, maskinläsbart format.
                </li>
                <li className="p big text-gray mb-0">
                  Lämna klagomål till Datainspektionen
                  (Integritetsskyddsmyndigheten) om du anser att vi behandlar
                  dina personuppgifter felaktigt.
                </li>
                <li className="p big text-gray mb-0">
                  Få svar inom 24 timmar på förfrågningar om dina rättigheter.
                </li>
              </ul>
              <p>
                För att utöva dessa rättigheter, kontakta oss på
                <Link to="mailto:info@ilonsi.com" className="ms-2">
                  info@ilonsi.com
                </Link>
                .
              </p>
            </Col>
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold">8. Säkerhet</h4>
              <p className="big text-gray">
                Vi vidtar lämpliga tekniska och organisatoriska åtgärder för att
                skydda dina personuppgifter mot obehörig åtkomst, förlust eller
                missbruk. Detta inkluderar kryptering, brandväggar och
                regelbundna säkerhetsgenomgångar.
              </p>
            </Col>
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold">
                9. Användning av cookies och spårningsteknik
              </h4>
              <p className="big text-gray">
                Vår plattform använder cookies och liknande spårningsteknik för
                att förbättra användarupplevelsen, analysera användning och
                tillhandahålla personligt anpassat innehåll. Du kan hantera dina
                cookie-inställningar via din webbläsare. Mer information finns i
                vår cookie-policy.
              </p>
            </Col>
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold">10. Förändringar i denna policy</h4>
              <p className="big text-gray">
                Vi kan komma att uppdatera denna integritetspolicy vid behov.
                Eventuella ändringar meddelas via vår plattform eller annan
                lämplig kanal. Vi uppmanar dig att regelbundet granska policyn
                för att hålla dig informerad.
              </p>
            </Col>
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold lh-base">11. Kontakt</h4>
              <p className="big text-gray">
                Om du har frågor om denna policy eller hur vi behandlar dina
                personuppgifter, kontakta oss på:
              </p>
              <h4 className="fw-bold">ILONSI AB</h4>
              <ul className="d-flex flex-column gap-4 list-style-disc">
                <a
                  href="mailto:info@ilonsi.com"
                  className="p big text-gray mb-0"
                >
                  <b className="text-black me-2">Email:</b> info@ilonsi.com
                </a>
                <a href="telto:0700-649429" className="p big text-gray mb-0">
                  <b className="text-black me-2">Telefon:</b> 0700-649429
                </a>
                <a
                  href="https://g.co/kgs/dQHJ5ek"
                  className="p big text-gray mb-0"
                  target="_blank"
                >
                  <b className="text-black me-2">Adress:</b> Aschebergsgatan 21,
                  411 27, Göteborg.
                </a>
              </ul>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};
