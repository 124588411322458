import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { doFetchShopClickCount } from "../../actions";

export const useEmptyPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (location?.pathname?.includes("index.html")) {
      navigate("/");
      return;
    }
  }, [location]);

  useEffect(() => {
    if (params?.shortCode && !location?.pathname?.includes("index.html")) {
      fetchInfluencerShopProductDetails();
    }
  }, [params, location]);

  const fetchInfluencerShopProductDetails = async () => {
    try {
      setIsLoading(true);

      const shopProductDetailResponse = await doFetchShopClickCount({
        code: params?.shortCode,
      });

      if (shopProductDetailResponse?.data?.data?.expired) {
        navigate(`/shop/${shopProductDetailResponse?.data?.data?.user}`);
        toast.warning("Länken har löpt ut");
      } else if (shopProductDetailResponse?.data?.data?.url === null) {
        navigate("not-found");
      } else {
        if (shopProductDetailResponse?.status === 200) {
          window.location.href = shopProductDetailResponse?.data?.data?.url;
        } else {
          toast.error(shopProductDetailResponse?.message);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  return { isLoading };
};
