import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  validateForgotPassword,
  validateSubmitForgotPassword,
} from "../../validations";
import { doFetchSendOtp } from "../../actions";

export const useForgotPassword = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [passwordData, setPasswordData] = useState({});
  const [validateMessages, setValidateMessages] = useState({});

  const handleInputChange = async (event) => {
    const { value, name } = event?.target;
    const { errors } = validateForgotPassword(name, value, validateMessages);
    setValidateMessages(errors);
    setPasswordData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSendOtpSubmit = () => {
    try {
      const { errors } = validateSubmitForgotPassword(passwordData);
      setValidateMessages(errors);

      if (Object.keys(errors).length === 0) {
        sendOtpCallback();
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const sendOtpCallback = async () => {
    try {
      setIsLoading(true);

      const requestBody = {
        email: passwordData?.email,
        type: "PASSWORD",
      };
      const sendOtpResponse = await doFetchSendOtp(requestBody);

      if (sendOtpResponse?.status === 200) {
        setValidateMessages({});
        localStorage.setItem("email", passwordData?.email);
        toast.success(sendOtpResponse?.message);
        navigate("/email-verification");
      } else {
        toast.error(sendOtpResponse?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    passwordData,
    validateMessages,
    navigate,
    handleInputChange,
    handleSendOtpSubmit,
  };
};
