import { Pagination } from "antd";
import { clsx } from "clsx";
import styles from "./ILPagination.module.css";

export const ILPagination = ({
  defaultCurrent,
  total,
  disabled,
  responsive = true,
  onChange,
  className,
  endPagination,
  defaultPageSize,
  ...rest
}) => {
  const customLocale = {
    items_per_page: "/ Sida",
  };
  return (
    <>
      <Pagination
        defaultPageSize={defaultPageSize}
        defaultCurrent={defaultCurrent}
        total={total}
        disabled={disabled}
        responsive={responsive}
        onChange={onChange}
        {...rest}
        className={clsx(
          styles.paginationWrap,
          className,
          "d-flex justify-content-center flex-wrap gap-4 gap-xxl-3",
          !endPagination && "justify-content-center"
        )}
        locale={customLocale}
      />
    </>
  );
};
