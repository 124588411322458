import { Col, Row } from "antd";
import {
  ILAccordion,
  ILDashboardCard,
  ILDashboardHeader,
  ILHead,
} from "../../../components";
import { Icons } from "../../../constants/icons";
import clsx from "clsx";
import { supportAnHelpAccordionData } from "../../../constants/data";
import { Link } from "react-router-dom";
import styles from "./ILSupportAndHelp.module.css";

const ILSupportAndHelp = () => {
  return (
    <>
      <ILHead title="Support och Hjälp" />
      <ILDashboardHeader
        extra={true}
        title="Support och Hjälp"
        breadCrumbItems={[
          { title: "Hem", href: "/dashboard" },
          { title: "Support och Hjälp" },
        ]}
      />
      <section>
        <div>
          <Row
            gutter={[
              { xs: 15, sm: 20, md: 25, lg: 30, xl: 35, xxl: 40 },
              { xs: 15, sm: 20, md: 25 },
            ]}
          >
            <Col xs={24} lg={18} xl={10}>
              <div className={clsx(styles.contactInformationWrap)}>
                <ILDashboardCard
                  title={"Kontaktinformation"}
                  subTitle={
                    <>
                      Ni är alltid varmt välkomna att kontakta oss direkt på
                      Instagram&nbsp;
                      <Link
                        to="https://www.instagram.com/ilonsisverige"
                        target="_blank"
                      >
                        @ilonsisverige
                      </Link>
                      , via mejl eller genom att ringa oss på telefonnumret
                      nedan.
                    </>
                  }
                  className={"contact-info-card"}
                >
                  <div className={styles.contactDetailsWrap}>
                    <a
                      href="tel:0700-649429"
                      className={clsx(
                        styles.contactDetailCard,
                        "d-flex align-items-center gap-3"
                      )}
                    >
                      <span
                        className={clsx(
                          styles.contactIcon,
                          styles.greenIcon,
                          "d-flex justify-content-center align-items-center rounded-circle flex-0-auto"
                        )}
                      >
                        <span className="d-flex justify-content-center align-items-center ">
                          {Icons.phone}
                        </span>
                      </span>
                      <span className="p big">0700-649429</span>
                    </a>
                    <a
                      href="mailto:info@ilonsi.com"
                      className={clsx(
                        styles.contactDetailCard,
                        "d-flex align-items-center gap-3"
                      )}
                    >
                      <span
                        className={clsx(
                          styles.contactIcon,
                          styles.blueIcon,
                          "d-flex justify-content-center align-items-center rounded-circle flex-0-auto"
                        )}
                      >
                        <span className="d-flex justify-content-center align-items-center">
                          {Icons.email}
                        </span>
                      </span>
                      <span className="p big">info@ilonsi.com</span>
                    </a>
                    <a
                      href="https://maps.app.goo.gl/Qwn7QphzWMAGVM5K7"
                      target="_blank"
                      className={clsx(
                        styles.contactDetailCard,
                        "d-flex align-items-center gap-4"
                      )}
                    >
                      <span
                        className={clsx(
                          styles.contactIcon,
                          styles.redIcon,
                          "d-flex justify-content-center align-items-center rounded-circle flex-0-auto"
                        )}
                      >
                        <span className="d-flex justify-content-center align-items-center">
                          {Icons.location}
                        </span>
                      </span>
                      <span className="p big">ILONSI AB</span>
                    </a>
                  </div>
                </ILDashboardCard>
              </div>
            </Col>
            <Col xs={24} xl={14}>
              <div className="mt-4 mt-xl-0">
                <h5 className="big fw-semibold text-uppercase mb-5">
                  Frågor och Sva
                </h5>
                <div className={styles.supportHelpFaq}>
                  <ILAccordion
                    items={supportAnHelpAccordionData}
                    defaultActiveKey="1"
                    className="support-and-help-faq"
                    expandIconPosition="end"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default ILSupportAndHelp;
