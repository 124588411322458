import { Col, Row } from "antd";
import { Search01Icon } from "hugeicons-react";
import React from "react";
import {
  ILDashboardCard,
  ILSelect,
  ILTextInput,
  ILDataOverviewCard,
  ILTable,
  ILDashboardHeader,
  ILHead,
  ILSkeleton,
} from "../../../components";
import ILDoughnutChart from "../../../components/ILCharts/ILDoughnutChart";
import ILLineNewChart from "../../../components/ILCharts/ILLineNewChart";
import { dmyOption, productServiceOption } from "../../../constants/data";
import {
  clickEarningsIcon,
  clickIcon,
  earningsIcon,
  productServiceIcon,
} from "../../../constants/imageData";
import { useDashboardHook } from "../../../hooks/dashboard/home/dashboard.hook";
import styles from "./ILDashboard.module.css";
import clsx from "clsx";

const ILDashboard = () => {
  const {
    dateRange,
    isLoading,
    productList,
    serviceList,
    paginationData,
    productColumns,
    serviceColumns,
    earningChartData,
    isProductLoading,
    insightSelectData,
    dashboardSummaryData,
    dashboardInsightsVisitor,
    handleInputChange,
    handleSelectChange,
  } = useDashboardHook();

  const dataCardsList = [
    {
      data: `${
        dashboardSummaryData?.totalEarning > 0
          ? dashboardSummaryData?.totalEarning + " KR"
          : dashboardSummaryData?.totalEarning
      }`,
      title: "Totala intäkter",
      image: earningsIcon,
    },
    {
      data: `${dashboardSummaryData?.totalClick}`,
      title: "Totala klick",
      image: clickIcon,
    },
    {
      data: `${dashboardSummaryData?.totalSell}`,
      title: "Totala försäljningar",
      image: clickEarningsIcon,
    },
    {
      data: `${dashboardSummaryData?.productAndServiceCount}`,
      title: "Totala produkter och tjänster",
      image: productServiceIcon,
    },
  ];

  return (
    <>
      <ILHead title="Översikt" />
      <ILDashboardHeader
        extra={true}
        title="Översikt"
        breadCrumbItems={[
          { title: "Hem", href: "/dashboard" },
          { title: "Översikt" },
        ]}
      />
      {/* DATA CARDS SECTION START */}
      <section>
        <div className={styles.dataCardsListWrap}>
          <ILDataOverviewCard
            isLoading={isLoading?.count}
            dataCardsList={dataCardsList}
            className={clsx(styles.dataCard)}
          />
        </div>
      </section>
      {/* DATA CARDS SECTION END */}

      {/* INSIGHTS-VISITORS SECTION START */}
      <section className="section-xs-mt">
        <Row
          gutter={[
            { xs: 15, sm: 20, md: 25, lg: 30, xl: 35, xxl: 40 },
            { xs: 15, sm: 20, md: 25, lg: 30, xl: 35, xxl: 40 },
          ]}
        >
          <Col xl={16} md={24} className="w-100">
            <ILDashboardCard
              title={"Intäktsinsikter"}
              extra={
                !isLoading?.earning && (
                  <ILSelect
                    ILSelectLabelClass={styles.tableSelect}
                    id="dmy_select"
                    name="earning"
                    datatestid="dmy_select"
                    defaultValue="weekly"
                    options={dmyOption}
                    isSelectInputSmall
                    value={dateRange}
                    handleChange={(event) =>
                      handleSelectChange(event, "earning")
                    }
                  />
                )
              }
            >
              {isLoading?.earning ? (
                <ILSkeleton count={1} height={150} />
              ) : (
                <div className="mt-5 overflow-x-auto h-100">
                  <ILLineNewChart
                    chartData={earningChartData}
                    isLegendVisible={false}
                  />
                </div>
              )}
            </ILDashboardCard>
          </Col>

          <Col xl={8} md={24} className="w-100">
            <ILDashboardCard
              title={"Butiksbesökare"}
              // extra={
              //   !isLoading?.visitor && (
              //     <ILSelect
              //       ILSelectLabelClass={styles.tableSelect}
              //       id="time_range_select"
              //       datatestid="time_range_select"
              //       defaultValue="today"
              //       options={timeRangeOption}
              //       name="visitor"
              //       value={insightSelectData?.visitor}
              //       handleChange={(event) =>
              //         handleSelectChange(event, "visitor")
              //       }
              //       isSelectInputSmall
              //     />
              //   )
              // }
            >
              {isLoading?.visitor ? (
                <ILSkeleton isRoundCircle />
              ) : (
                <div className="w-100">
                  <ILDoughnutChart chartData={dashboardInsightsVisitor} />
                </div>
              )}
            </ILDashboardCard>
          </Col>
        </Row>
      </section>
      {/* INSIGHTS-VISITORS SECTION END */}

      {/* TOP CLICKED PRODUCTS SECTION START */}
      <section className="section-xs-mt">
        <ILDashboardCard
          title={`Mina ${
            paginationData?.categoryType === "Products"
              ? "produkter"
              : "tjänster"
          }`}
          subTitle={`Översikt över intäkter genom klick och försäljning ${
            paginationData?.categoryType === "Products"
              ? "produkter"
              : "tjänster"
          }`}
          extra={
            <>
              <div className="d-flex flex-wrap flex-md-nowrap gap-4">
                <ILTextInput
                  ILInputLabelClass={styles.tableSearch}
                  type={"search"}
                  placeholder={`Sök ${
                    paginationData?.categoryType === "Products"
                      ? "producter"
                      : "tjänster"
                  }`}
                  prefix={
                    <Search01Icon
                      size={20}
                      color={"#392306"}
                      variant={"stroke"}
                    />
                  }
                  name="search"
                  value={paginationData?.search}
                  handleChange={handleInputChange}
                  allowClear
                />
                <ILSelect
                  ILSelectLabelClass={styles.tableSelect}
                  id="product_service"
                  datatestid="product_service"
                  defaultValue="Products"
                  options={productServiceOption}
                  name="categoryType"
                  value={paginationData?.categoryType}
                  handleChange={(event) =>
                    handleSelectChange(event, "categoryType")
                  }
                />
              </div>
            </>
          }
        >
          <ILTable
            columns={
              paginationData?.categoryType === "Products"
                ? productColumns
                : serviceColumns
            }
            data={
              paginationData?.categoryType === "Products"
                ? productList?.rows
                : serviceList?.rows
            }
            scroll={{ x: "max-content" }}
            isLoading={isProductLoading}
          />
        </ILDashboardCard>
      </section>
      {/* TOP CLICKED PRODUCTS SECTION END */}
    </>
  );
};

export default ILDashboard;
