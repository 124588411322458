import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ children, isAuth, path, role }) => {
  const token = localStorage.getItem("_token");
  const userDetails = localStorage.getItem("userDetails");
  const isAuthenticated = token === null || token === undefined ? false : true;
  // const isRole = isAuthenticated && isAuth ? true : false;
  if (token && isAuth !== undefined && !isAuth && !userDetails) {
    return (
      <>
        {role === "influencer" ? (
          <Navigate to="dashboard" replace={true} />
        ) : (
          <Navigate to="/" replace={true} />
        )}
      </>
    );
  } else if ((token && isAuth) || (isAuth !== undefined && !isAuth)) {
    return <>{children}</>;
  } else if (isAuth === undefined) {
    return <>{children}</>;
  } else {
    return (
      <>
        {role === "influencer" ? (
          <Navigate to="/login" replace={true} />
        ) : (
          <Navigate to="/" replace={true} />
        )}
      </>
    );
  }
};
