import clsx from "clsx";
import {
  ILInnerHero,
  ILBrandsSelectionCard,
  ILHead,
  ILNoDataCard,
  ILSkeleton,
} from "../../components";
import { Link } from "react-router-dom";
import styles from "./ILBrandsList.module.css";
import { useBrandListHook } from "../../hooks/website/brandList.hook";

const ILBrandsList = () => {
  const {
    isLoading,
    brandList,
    paginationData,
    categoryDetails,
    handleViewMore,
    handleBrandSelect,
    handleInputChange,
  } = useBrandListHook();

  return (
    <>
      <ILHead title="Brand List" />
      <ILInnerHero
        link="/brands"
        pageTitle="Företag"
        heroTitle={categoryDetails?.name}
        heroParagraph={categoryDetails?.description}
        // heroSearchBarShow={brandList?.rows?.length > 0 ? true : false}
        placeholder="Sök företag"
        heroContentClass="inner-hero-content-wrap d-flex align-items-center flex-md-row flex-column gap-md-5"
        name="search"
        value={paginationData?.search}
        handleSearchChange={handleInputChange}
        heroSearchBarShow
      />
      <section
        className={clsx(
          styles.brandsSelectCardSection,
          "section-md-pb position-relative hero-gradient-border bg-cover-layer"
        )}
      >
        <div className="container">
          <div
            className={clsx(
              styles.brandsSelectCardGrid,
              "d-grid position-relative z-1"
            )}
          >
            {isLoading ? (
              <div className="skeleton-card border">
                <ILSkeleton isImageTitleDesc widthTwo={150} />
              </div>
            ) : (
              brandList?.rows?.length > 0 && (
                <ILBrandsSelectionCard
                  isLoading={isLoading}
                  brandCardMap={brandList}
                  handleBrandSelect={handleBrandSelect}
                />
              )
            )}
          </div>

          {brandList?.hasNext && (
            <Link
              className={clsx(
                styles.viewMoreText,
                "p big fw-semibold gradient-text mt-5 mb-0 text-center d-block"
              )}
              onClick={handleViewMore}
            >
              Visa mer
            </Link>
          )}

          {!isLoading && brandList?.rows?.length === 0 && (
            <ILNoDataCard isIcon className="position-relative z-3" />
          )}
        </div>
      </section>
    </>
  );
};

export default ILBrandsList;
