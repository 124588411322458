import React from "react";
import { clsx } from "clsx";
import { Icons } from "../../constants/icons";
import styles from "./ILButton.module.css";

export const ILButton = ({
  id,
  datatestid,
  children,
  isArrow,
  variant,
  handleClick,
  ILBtnClass,
  handleChange,
  isSmallSizeBtn,
  ILBgGreenText,
  disabled,
  ILBtnInnerSpanClass,
  ref,
  ...rest
}) => {
  return (
    <button
      id={id}
      datatestid={datatestid}
      onClick={handleClick}
      className={clsx([
        ILBtnClass,
        styles.ILBtn,
        {
          [styles.ILPrimaryGradient]: variant === "primary-gradient",
          [styles.ILBlackBordered]: variant === "black-bordered",
          [styles.ILPinkFilled]: variant === "pink-filled",
          [styles.ILGradientBordered]: variant === "gradient-bordered",
          [styles.ILWhiteBgGradientText]: variant === "whiteBg-gradientText",
          [styles.smallSizeBtn]: isSmallSizeBtn,
          [styles.ILBgGreenText]: ILBgGreenText,
        },
      ])}
      onChange={handleChange}
      disabled={disabled}
      ref={ref}
      {...rest}
    >
      <span className={ILBtnInnerSpanClass}>{children}</span>
      {isArrow && (
        <span className="ms-3 inline-flex lh-0">{Icons.linkArrow}</span>
      )}
    </button>
  );
};
