import { ArrowLeft02Icon, Search01Icon } from "hugeicons-react";
import { Link } from "react-router-dom";
import {
  ILAccordion,
  ILButton,
  ILHead,
  ILLoader,
  ILProductCard,
  ILSkeleton,
  ILTextInput,
} from "../../../components";
import clsx from "clsx";
import styles from "./ILViewCollection.module.css";
import { useLiveProfileCollectionProductsHook } from "../../../hooks/dashboard/myShop/liveProfileCollectionProducts.hook";
import { countHandler } from "../../../services";
import { ILCSFilterCheckboxList } from "../../ILCSFilterCheckboxList/ILCSFilterCheckboxList";
import { Icons } from "../../../constants/icons";
import { defaultUser } from "../../../constants/imageData";
import { InView } from "react-intersection-observer";

const ILViewCollection = () => {
  const {
    isLoading,
    productList,
    filterModal,
    paginationData,
    collectionDetails,
    productFilterData,
    ref,
    observerScroll,
    handleInputChange,
    showFilterDataWrap,
    handleProductDetail,
    handleClearAllFilters,
    handleFilterInputChange,
    handleBackToLiveProfile,
    handleApplyFilterModalCancel,
  } = useLiveProfileCollectionProductsHook();

  const applyFilterCreatorSearchData = [
    {
      key: "price",
      label: "Price",
      children: (
        <ILCSFilterCheckboxList
          name="priceRange"
          CSPriceFilterCheckboxCon
          creatorsFilterData={productFilterData}
          handleChange={handleFilterInputChange}
        />
      ),
    },
    {
      key: "PayPerClicks",
      label: "PayPerClicks",
      children: (
        <ILCSFilterCheckboxList
          name="ppcRange"
          CSPayPerClicksFilterCheckboxCon
          creatorsFilterData={productFilterData}
          handleChange={handleFilterInputChange}
        />
      ),
    },
  ];

  return (
    <>
      <ILHead title="View Collections | Live Profile" />
      <section className="pt-3 section-sm-pb">
        <div className="container">
          <div className="d-flex align-items-start flex-column gap-3 mb-extra-small position-sticky top-0 py-4 z-99 bg-clr-walkie-chalkie default-border-bottom-1 px-4 px-xxl-3">
            <div
              className="cursor-pointer rounded-circle d-flex align-items-center gap-3"
              onClick={handleBackToLiveProfile}
            >
              <img
                src={
                  isLoading
                    ? defaultUser
                    : collectionDetails?.user?.profile_image
                }
                className={clsx(
                  styles.shareLiveProfileImgBox,
                  "radius-inherit"
                )}
              />
              <h6 className="mb-0 fw-medium">
                {isLoading ? (
                  <ILSkeleton isNormalParagraph />
                ) : (
                  `${collectionDetails?.user?.name}'s Shop`
                )}
              </h6>
            </div>
            <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 gap-md-3 gap-xl-4 w-100">
              <div className="d-flex align-items-center gap-4">
                <Link
                  onClick={handleBackToLiveProfile}
                  className="d-flex align-items-center gap-4"
                >
                  <ArrowLeft02Icon
                    size={32}
                    color={"#1D1128"}
                    variant={"stroke"}
                    strokeWidth={1.5}
                    className="svg-hover"
                  />
                </Link>
                {isLoading ? (
                  <ILSkeleton isNormalParagraph />
                ) : (
                  <h4 className="mb-0 fw-semibold">
                    {collectionDetails?.name}{" "}
                    {`${
                      productList?.totalItems > 0
                        ? `(${countHandler(productList?.totalItems)} Products)`
                        : ""
                    }`}
                  </h4>
                )}
              </div>
              <div className="d-flex align-items-center gap-3 filterDataHead min-h min-h-auto">
                <ILTextInput
                  ILInputLabelClass={
                    "search-input search-input-full-sm ms-auto"
                  }
                  placeholder={"Search Product"}
                  type={"search"}
                  name={"search"}
                  value={paginationData?.search}
                  handleChange={handleInputChange}
                  prefix={
                    <Search01Icon
                      size={20}
                      color={"var(--clr-dark-purple)"}
                      variant={"stroke"}
                    />
                  }
                  allowClear
                />
                {/* <ILButton
                  variant={"gradient-bordered"}
                  handleClick={showFilterDataWrap}
                  ILBtnClass="btn-small min min-w-auto"
                  ILBtnInnerSpanClass="d-flex align-items-center"
                >
                  <b className="lh-0 me-2 text-clr-purple">
                    <FilterIcon size={20} variant={"stroke"} />
                  </b>
                  Filtrera
                </ILButton> */}
              </div>
            </div>
          </div>
          <div className={clsx(styles.viewCollectionCardWrap, "d-grid")}>
            {isLoading ? (
              <div className="row">
                <div className="skeleton-card border">
                  <ILSkeleton isImgTitleLabelCard2 />
                </div>
              </div>
            ) : (
              productList?.totalItems > 0 &&
              productList?.rows?.map((MPItem, MPIndex) => {
                return (
                  <ILProductCard
                    productDataShow={MPItem}
                    key={MPIndex}
                    isLiveProfile
                    productCardClassname="bg-white"
                    handleProductDetail={handleProductDetail}
                  />
                );
              })
            )}
          </div>

          {productList?.hasNext &&
            paginationData?.page < productList?.totalPages && (
              <InView
                as="div"
                onChange={(inView, entry) => inView && observerScroll(entry)}
              >
                <div
                  ref={ref}
                  className={clsx(
                    styles.swiperSlideLoader,
                    "w-100 h-100 text-center position-relative py-5 d-flex align-items-center justify-content-center"
                  )}
                >
                  <ILLoader
                    classname={"loader-wrap-absolute bg-transparent"}
                    isLoaderBgOpacitySm
                    isChatLoader
                  />
                </div>
              </InView>
            )}

          {!isLoading &&
            paginationData?.search &&
            productList?.rows?.length === 0 && (
              <div className="text-gray flex-column d-flex align-items-center h-100 w-100 min-h-inherit mt-3">
                {Icons.packageOpenBoxIcon}
                <span className="paragraph-sm-text mt-3">
                  Inga sökta produkter hittades, sök efter annan produkt
                </span>
              </div>
            )}

          {!isLoading &&
            !paginationData?.search &&
            productList?.totalItems === 0 && (
              <div className="text-gray flex-column d-flex align-items-center h-100 w-100 min-h-inherit mt-3">
                {Icons.packageOpenBoxIcon}
                <h6 className="fw-semibold text-center py-5 mb-0">
                  Inga Produkter Hittades
                </h6>
              </div>
            )}
        </div>
      </section>

      {/* filter data sidebar start */}
      <div
        className={clsx(
          filterModal
            ? "showFilterDataShowWrap noHeaderFilterDataShowWrap"
            : "",
          "w-100 bg-white position-fixed end-0 z-99 top-0 transition-smooth filterDataShowWrap"
        )}
      >
        <div
          className={clsx(
            "d-flex align-items-center position-relative searchFilterClearAllBox"
          )}
        >
          <span
            className={clsx(
              "d-flex align-items-center justify-content-center cursor-pointer rounded-circle closeIcon order-3 order-md-1"
            )}
            onClick={showFilterDataWrap}
          >
            {Icons.closeIcon}
          </span>
          <h6 className="fw-semibold text-dark-purple mb-0 order-1">
            Använd filter
          </h6>
          <Link
            className="text-gray fw-medium mb-0 ms-auto p big order-2 me-5 me-md-0"
            onClick={(e) => {
              e.preventDefault();
              handleClearAllFilters();
            }}
          >
            Rensa alla
          </Link>
        </div>
        {/* <span
          className={clsx(
            styles.closeIcon,
            "d-flex align-items-center cursor-pointer hover-text"
          )}
          onClick={hideFilterDataWrap}
        >
          {Icons.closeIcon}
        </span> */}

        <div className="filterDataContentWrap overflow-y-auto midium-track">
          <ILAccordion
            items={applyFilterCreatorSearchData}
            defaultActiveKey="1"
            className={clsx(styles.filterAccordion, "filter-accordion my-0")}
            expandIconPosition="end"
            iconSize="20"
          />
        </div>
        <div className="filterDataApplyBtnWrap d-flex align-items-center justify-content-center px-5 gap-4">
          <ILButton
            variant={"primary-gradient"}
            handleClick={handleApplyFilterModalCancel}
            ILBtnClass="w-50 btn-small min min-w-auto mt-0"
          >
            Tillämpas
          </ILButton>
          <ILButton
            variant={"gradient-bordered"}
            handleClick={showFilterDataWrap}
            ILBtnClass="w-50 btn-small min min-w-auto mt-0"
          >
            Avboka
          </ILButton>
        </div>
      </div>
      {/* filter data sidebar end */}
    </>
  );
};

export default ILViewCollection;
