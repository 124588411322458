import clsx from "clsx";
import { useState } from "react";

export const ILReadMoreLess = ({
  text,
  sliceLength,
  sliceClassName,
  className,
  isBlackReadMoreText,
}) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const textLength = text?.length;
  return (
    <p
      className={clsx(
        "read-more-less-text paragraph-sm-text",
        className ? className : ""
      )}
    >
      {textLength > sliceLength
        ? isReadMore
          ? text.slice(0, sliceLength) + "... "
          : text + " "
        : text}
      {textLength > sliceLength && (
        <span
          onClick={toggleReadMore}
          className={clsx(
            isBlackReadMoreText ? "text-black" : "text-magical-merlin",
            "read-more-less-link fw-700 cursor-pointer ps-2",
            sliceClassName ? sliceClassName : ""
          )}
        >
          {isReadMore ? " Read more" : " Read less"}
        </span>
      )}
    </p>
  );
};
