import React from "react";
import { clsx } from "clsx";
import styles from "./ILDashboardCard.module.css";

export const ILDashboardCard = ({
  title,
  subTitle,
  extra,
  children,
  noHeader,
  className,
  dashboardCardHeaderClass,
  innerCardClassName,
}) => {
  return (
    <>
      <div className={clsx(styles.ILDashboardCard, className)}>
        {!noHeader && (
          <div
            className={clsx(
              styles.ILDashboardCardHeader,
              dashboardCardHeaderClass
            )}
          >
            <div>
              <h5 className="big fw-semibold mb-0">{title}</h5>
              {subTitle && (
                <p className="title-label text-start mt-4 mt-xxl-3 mb-0">
                  {subTitle}
                </p>
              )}
            </div>
            {extra}
          </div>
        )}
        <div className={clsx(innerCardClassName, "h-100")}>{children}</div>
      </div>
    </>
  );
};
