import { Delete02Icon, Search01Icon } from "hugeicons-react";
import {
  ILButton,
  ILDashboardCard,
  ILDashboardHeader,
  ILHead,
  ILLoader,
  ILModal,
  ILTable,
  ILTextInput,
} from "../../../components";
import { ILShareSocialMediaModal } from "../../../components/ILDashboardGalleryCard/ILShareSocialMediaModal";
import { appConfig } from "../../../config";
import { useServiceViewAllListHook } from "../../../hooks/dashboard/myShop/serviceViewAll.hook";
import { countHandler } from "../../../services";

const MyShopViewAllServices = () => {
  const {
    isLoading,
    serviceId,
    selectedData,
    isDeleteModal,
    serviceColumns,
    paginationData,
    serviceListData,
    isShareSMModalOpen,
    selectedSocialMedia,
    isServiceDeleteLoading,
    isSuccessfullyDeleteModalOpen,
    handleCopyLink,
    handleTableChange,
    handleInputChange,
    showDeleteConfirmModal,
    shareSocialMediaHandler,
    handleSelectSocialMedia,
    handleShareSMModalClose,
    showSuccessfullyDeleteModal,
    handleDeleteConfirmModalCancel,
    handleSuccessfullyDeleteModalOk,
  } = useServiceViewAllListHook();

  return (
    <>
      <ILHead title="Visa alla tjänster | Min butik" />
      <ILDashboardHeader
        extra={true}
        title="Min butik"
        breadCrumbItems={[
          { title: "Hem", href: "/dashboard" },
          { title: "Min butik", href: "/dashboard/my-shop" },
          { title: "Visa alla tjänster" },
        ]}
      />
      {/* SERVICE TABLE STARTS */}

      <div className="d-flex justify-content-end mb-extra-small">
        <ILTextInput
          ILInputLabelClass={"search-input w-auto mb-0"}
          placeholder={"Search Services"}
          type={"search"}
          name={"search"}
          value={paginationData?.search}
          handleChange={handleInputChange}
          prefix={
            <Search01Icon
              size={20}
              color={"var(--clr-dark-purple)"}
              variant={"stroke"}
            />
          }
          allowClear
        />
      </div>

      <ILDashboardCard
        title={`Tjänster ${
          serviceListData?.totalItems > 0
            ? `(${countHandler(serviceListData?.totalItems)})`
            : ""
        }`}
        className="h-auto"
      >
        <ILTable
          columns={serviceColumns}
          data={serviceListData?.rows}
          scroll={{ x: "max-content" }}
          pagination={true}
          isLoading={isLoading}
          current={paginationData?.page}
          pageSize={paginationData?.pageSize}
          total={serviceListData?.totalItems}
          handleTableChange={handleTableChange}
        />
      </ILDashboardCard>
      {/* SERVICE TABLE ENDS */}

      <ILModal
        open={isDeleteModal}
        onOk={() => showDeleteConfirmModal(serviceId)}
        onCancel={handleDeleteConfirmModalCancel}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="518px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <div
                className="brand-logo brand-logo-big flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center mx-auto"
                style={{ borderColor: "#ED4F32", borderWidth: "3px" }}
              >
                <Delete02Icon
                  size={34}
                  color={"#ED4F32"}
                  variant={"stroke"}
                  strokeWidth={2}
                />
              </div>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4 lh-base">
                Vill du verkligen radera tjänsten?
              </h4>
              <p className="paragraph-sm-text paragraph-md-text text-center mb-0">
                Detta kommer att radera din tjänst från butiken?
              </p>
              <div className="d-flex align-items-center gap-4 modal-more-btn">
                <ILButton
                  disabled={isServiceDeleteLoading}
                  ILBtnClass="w-50"
                  variant={"primary-gradient"}
                  onClick={showSuccessfullyDeleteModal}
                >
                  {isServiceDeleteLoading ? <ILLoader isBtn /> : "Ja, Bekräfta"}
                </ILButton>
                <ILButton
                  disabled={isServiceDeleteLoading}
                  ILBtnClass="w-50"
                  variant={"gradient-bordered"}
                  onClick={handleDeleteConfirmModalCancel}
                >
                  Nej, Avbryt
                </ILButton>
              </div>
            </div>
          </>
        }
        isFooterRemove
      />

      <ILModal
        open={isSuccessfullyDeleteModalOpen}
        onOk={handleSuccessfullyDeleteModalOk}
        onCancel={handleSuccessfullyDeleteModalOk}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="518px"
        isCloseIconRemove
        children={
          <>
            <div className="text-center d-flex flex-column justify-content-center modal-content-mh-inherit gap-5 gap-xxl-4">
              {/* <span className="lh-0">{Icons.circleRoundIcon}</span> */}
              <div
                className="brand-logo brand-logo-big flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center mx-auto"
                style={{ borderColor: "#ED4F32", borderWidth: "3px" }}
              >
                <Delete02Icon
                  size={34}
                  color={"#ED4F32"}
                  variant={"stroke"}
                  strokeWidth={2}
                />
              </div>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Tjänst raderad framgångsrikt!
              </h4>
              <ILButton
                ILBtnClass="w-100"
                variant={"primary-gradient"}
                onClick={handleSuccessfullyDeleteModalOk}
              >
                Okej
              </ILButton>
            </div>
          </>
        }
        isFooterRemove
      />

      <ILModal
        open={isShareSMModalOpen}
        onOk={handleShareSMModalClose}
        onCancel={handleShareSMModalClose}
        title={
          <>
            <div className="d-flex align-items-center gap-3">
              Dela på sociala medier
            </div>
          </>
        }
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="518px"
        children={
          <>
            <ILShareSocialMediaModal
              liveProfileLink={`${appConfig.APP_URL}${selectedData?.service?.short_code}`}
              handleCopyLink={() => handleCopyLink()}
              shareHandler={shareSocialMediaHandler}
              selectedSocialMedia={selectedSocialMedia}
              handleSelectSocialMedia={handleSelectSocialMedia}
            />
          </>
        }
        isFooterRemove
      />
    </>
  );
};

export default MyShopViewAllServices;
