import clsx from "clsx";
import { getTimeDifference } from "../../services";
import { ILButton } from "../ILButton";
import { ILLoader } from "../ILLoader";
import styles from "./ILNotificationCard.module.css";

export const ILNotificationCard = ({
  notificationItem,
  className,
  handleAddToShop,
  handleViewDetails,
  isLoading,
  selectedRequest,
  handleNavigateAccessRequest = () => {},
}) => {
  return (
    <>
      <div className={clsx(styles.brandNotificationCard, className)}>
        <div
          className={clsx(
            styles.brandContentWrap,
            "d-flex flex-column flex-sm-row"
          )}
          onClick={() => handleNavigateAccessRequest(notificationItem)}
        >
          <div
            className={clsx(
              styles.brandLogoCard,
              "d-flex justify-content-center align-items-center rounded-circle flex-shrink-0"
            )}
            // style={{
            //   backgroundColor:
            //     notificationItem.logoBg && notificationItem.logoBg,
            // }}
          >
            <img
              src={
                notificationItem?.brand_details?.profile_image ||
                notificationItem?.request_user?.profile_image
              }
              alt={notificationItem.logoAlt}
              className="object-fit-cover object-position-center w-100 h-100 aspect-ratio-1-1 radius-inherit"
            />
          </div>
          <div className="d-flex justify-content-between w-100 gap-4">
            <div className="cursor-pointer d-flex flex-column gap-4 gap-xl-3 gap-xxl-2">
              <h6 className="p big fw-medium mb-0">
                {notificationItem?.Notification?.title ||
                  notificationItem?.title}
              </h6>
              <p className="paragraph-xs-text fw-medium text-gray">
                {notificationItem?.Notification?.message ||
                  notificationItem?.message}
              </p>
              {/* {notificationItem.productsImgSrc && (
                <div
                  className={clsx(styles.brandProductImgWrap, "d-flex")}
                >
                  {notificationItem.productsImgSrc.map((img, index) => {
                    return (
                      <div
                        className={clsx(
                          styles.brandProductImgCard,
                          "flex-shrink-0"
                        )}
                        key={index}
                      >
                        <img
                          src={img.imgSrc}
                          alt={`product img ${index + 1}`}
                          className="w-100 h-100 object-fit-cover"
                        />
                      </div>
                    );
                  })}
                </div>
              )} */}
            </div>
            <span className="d-block fw-medium flex-0-auto text-gray">
              {getTimeDifference(notificationItem?.Notification?.created_at)}
            </span>
          </div>
        </div>

        <div
          className={clsx(
            styles.brandProductBtnCard,
            "d-flex flex-wrap w-100 mt-5 mt-xl-4"
          )}
        >
          {notificationItem.status === "ACCEPTED" &&
            (notificationItem?.product_request?.product_details
              ?.is_added_to_shop === 0 ||
              notificationItem?.product_request?.service_details
                ?.is_added_to_shop === 0 ||
              notificationItem?.product_request?.collection_details
                ?.is_added_to_shop === 0) && (
              <ILButton
                disabled={isLoading?.addToShop}
                variant={"primary-gradient"}
                isSmallSizeBtn
                handleClick={() => handleAddToShop(notificationItem)}
                ILBtnClass={"min-w-auto px-5 notification-car-btn"}
              >
                {selectedRequest?.id === notificationItem?.id ? (
                  isLoading?.addToShop ? (
                    <ILLoader isBtn />
                  ) : (
                    "Lägg till i butik"
                  )
                ) : (
                  "Lägg till i butik"
                )}
              </ILButton>
            )}
          {notificationItem?.room_fk && (
            <ILButton
              variant={"gradient-bordered"}
              handleClick={() => handleViewDetails(notificationItem)}
              ILBtnClass={"min-w-auto px-5 notification-car-btn"}
              isSmallSizeBtn
            >
              {notificationItem?.product_fk ||
              notificationItem?.product_request?.product_fk
                ? "Visa produkt"
                : notificationItem?.service_fk ||
                  notificationItem?.product_request?.service_fk
                ? "Visa tjänst"
                : "Visa kollektion"}
            </ILButton>
          )}
        </div>
      </div>
    </>
  );
};
