import copy from "copy-to-clipboard";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  doFetchAddProduct,
  doFetchAddProductsToCollection,
  doFetchAddService,
  doFetchAllCollections,
} from "../../actions";
import { appConfig } from "../../config";
import { socialMediaSharingCardList } from "../../constants/data";
import { getDecryptionString } from "../../services";
import {
  validateCollectionAdd,
  validateExistingCollectionAdd,
  validateSubmitAddCollection,
  validateSubmitExistingAddCollection,
} from "../../validations";

export const useCopyLinkModalHook = ({
  data,
  isService,
  setIsAddedToShop,
  setPaginationData,
}) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isCollectionAddLoading, setIsCollectionAddLoading] = useState(false);
  const [isCollectionLoading, setIsCollectionLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [collectionData, setCollectionData] = useState({});
  const [collectionList, setCollectionList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [productData, setProductData] = useState({});
  const [addProduct, setAddProduct] = useState({});
  const [selectedSocialMedia, setSelectedSocialMedia] = useState({});
  const [validateMessages, setValidateMessages] = useState({});
  const [isPrevBtn, setPrevBtn] = useState(false);
  const [show, setShow] = useState(false);
  const [currentId, setCurrentId] = useState("copyLink");
  const socialMediaSharingCardListResults = showAll
    ? socialMediaSharingCardList
    : socialMediaSharingCardList.slice(0, 7);
  const [isNewCollection, setIsNewCollection] = useState(true);
  const [deleteChannelModalOpen, setDeleteChannelModalOpen] = useState(false);
  const [isProductSelected, setIsProductSelected] = useState(false);

  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    if (data) {
      setProductList([data]);
    }
  }, [data, show]);

  useEffect(() => {
    if (currentId === "addExistingCollection" && collectionList?.length === 0) {
      fetchCollectionList();
    }
  }, [currentId, collectionList]);

  const handleInputChange = (event) => {
    const { name, value } = event?.target;
    const { errors } = validateCollectionAdd(name, value);
    setValidateMessages(errors);
    setCollectionData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChannelDeleteModal = (data) => {
    setAddProduct(data);
    setDeleteChannelModalOpen(true);
  };

  const handleChannelDeleteModalCancel = () => {
    setDeleteChannelModalOpen(false);
  };

  const handleRemoveSocialMediaAcc = () => {
    setDeleteChannelModalOpen(false);
    const filteredProducts = productList.filter(
      (data) => data.id !== addProduct?.id
    );

    setProductList(filteredProducts);
    setProductData(data);
  };

  const handleSubmitCollection = (event) => {
    try {
      if (event?.currentTarget?.id === "yourProductAdded") {
        const { errors } = validateSubmitExistingAddCollection(collectionData);
        setValidateMessages(errors);
        if (Object.keys(errors).length === 0) {
          handleCollectionCreateCallback(event);
        }
      } else {
        const { errors } = validateSubmitAddCollection(collectionData);
        setValidateMessages(errors);
        if (Object.keys(errors).length === 0) {
          handleCollectionCreateCallback(event);
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const showModal = () => {
    setShow(true);
  };

  const handleSelectChange = (value, name) => {
    const { errors } = validateExistingCollectionAdd(name, value);
    setValidateMessages(errors);
    setCollectionData((prevState) => ({ ...prevState, id: value }));
  };

  const handleCollectionCreateCallback = async () => {
    try {
      setIsAddedToShop(false);
      setIsCollectionAddLoading(true);
      const productListArr = [];

      productList?.map((item, index) =>
        productListArr.push({ id: item?.id, brandId: item?.brand?.id })
      );

      const requestBody = {
        products: productListArr,
        ...(currentId === "addExistingCollection"
          ? {
              collectionId: collectionData?.id,
            }
          : { name: collectionData?.name }),
      };

      const addCollectionResponse = await doFetchAddProductsToCollection(
        requestBody
      );

      if (addCollectionResponse?.status === 200) {
        if (currentId === "addExistingCollection") {
          setCurrentId("collectionUpdated");
        } else {
          setCurrentId("collectionCreated");
        }
      } else {
        toast.error(addCollectionResponse?.message);
      }
      setIsAddedToShop(true);
      setIsCollectionAddLoading(false);
    } catch (error) {
      console.log(error);
      setIsCollectionAddLoading(false);
    }
  };

  const addToShopHandle = () => {
    if (isService) {
      handleProductAddToShop();
    } else {
      setShow((prev) => true);
      setCurrentId(
        `${
          data?.is_added_to_shop === 1 || data?.is_added_to_collection === 1
            ? "createCollection"
            : "addToShopAs"
        }`
      );
      setPrevBtn((prev) => true);
    }
  };

  const handleAddMoreProducts = (type) => {
    if (type === "existingCollection") {
      setIsNewCollection(false);
    }
    setCurrentId("selectProduct");
    setProductData({});
    setIsProductSelected(false);
  };

  const handleModalClose = () => {
    setShow(false);
    setCurrentId("copyLink");
    setPrevBtn(false);
    setProductList([]);
    setCollectionData({});
    setSelectedSocialMedia({});
    setPaginationData((prev) => ({
      ...prev,
      page: 1,
    }));
  };

  const handleSetProduct = (data) => {
    setProductData(data);
    setIsProductSelected(true);
  };

  const handleProductAddToShop = async () => {
    try {
      setIsLoading(true);
      setIsAddedToShop(false);

      let addProductServiceResponse;

      const requestBody = {
        ...(isService
          ? {
              serviceId: data?.id,
            }
          : { productId: data?.id }),
      };

      if (isService) {
        addProductServiceResponse = await doFetchAddService(requestBody);
      } else {
        addProductServiceResponse = await doFetchAddProduct(requestBody);
      }

      if (addProductServiceResponse?.status === 200) {
        setShow(true);
        setCurrentId("productAdded");
      } else if (addProductServiceResponse?.status === 409) {
        setShow(true);
        setCurrentId("alreadyAdded");
      } else {
        toast.error(addProductServiceResponse?.data?.message);
        setShow(false);
        setPrevBtn(false);
      }
      setIsAddedToShop(true);
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  const fetchCollectionList = async () => {
    try {
      setIsCollectionLoading(true);

      const collectionListResponse = await doFetchAllCollections();

      if (collectionListResponse?.status === 200) {
        const decryptedCollectionList = await JSON.parse(
          getDecryptionString(collectionListResponse?.data)
        );
        const collectionListData = decryptedCollectionList?.map((data) => {
          return {
            value: data?.id,
            label: data?.name,
          };
        });
        setCollectionList(collectionListData);
      }
      setIsCollectionLoading(false);
    } catch (error) {
      console.error("Error occurred during fetching collection list:", error);
      setIsCollectionLoading(false);
    }
  };

  const handleCopyLink = (type) => {
    if (type === "selectProduct") {
      setProductList((prevProductList) => [...prevProductList, productData]);

      if (isNewCollection) {
        setCurrentId("addNewCollection");
      } else {
        setCurrentId("addExistingCollection");
      }
    } else {
      copy(`${appConfig.APP_URL}${data?.short_code}`);
      setCurrentId("copiedSuccessfully");
    }
    setIsProductSelected(false);
  };

  const generateSharableLink = () => {
    window.open(
      `${selectedSocialMedia?.link}${appConfig.APP_URL}${data?.short_code}`,
      "_blank"
    );
    handleModalClose();
  };

  const handleSelectSocialMedia = (data) => {
    setSelectedSocialMedia(data);
  };

  return {
    show,
    showAll,
    currentId,
    isLoading,
    isPrevBtn,
    productList,
    productData,
    collectionData,
    collectionList,
    isNewCollection,
    validateMessages,
    isProductSelected,
    selectedSocialMedia,
    isCollectionLoading,
    isCollectionAddLoading,
    deleteChannelModalOpen,
    socialMediaSharingCardListResults,
    navigate,
    showModal,
    setCurrentId,
    handleShowAll,
    handleCopyLink,
    setProductList,
    addToShopHandle,
    handleModalClose,
    handleSetProduct,
    handleInputChange,
    handleSelectChange,
    generateSharableLink,
    handleAddMoreProducts,
    handleProductAddToShop,
    setSelectedSocialMedia,
    handleSubmitCollection,
    handleSelectSocialMedia,
    handleChannelDeleteModal,
    handleRemoveSocialMediaAcc,
    handleChannelDeleteModalCancel,
  };
};
