import { clsx } from "clsx";
import { Col, Row } from "antd";
import { ArrowLeft02Icon } from "hugeicons-react";
import { Link } from "react-router-dom";
import {
  featuredCollectionsBreakPoints,
  featuredProductBreakPoints,
  featuredServicesBreakPoints,
} from "../../constants/data";
import {
  ILProductGalleryCard,
  ILSwiper,
  ILProductCard,
  ILHead,
  ILReadMoreLess,
  ILNoDataCard,
  ILButton,
  ILModal,
  ILSkeleton,
} from "../../components";
import { SwiperSlide } from "swiper/react";
import { useSearchProductsHook } from "../../hooks/website/searchProducts.hook";
import { countHandler } from "../../services";
import styles from "./ILSearchResults.module.css";
import { gradientCircleRound } from "../../constants/imageData";

const ILSearchResults = () => {
  const {
    params,
    brandData,
    isLoading,
    brandList,
    isLoggedIn,
    serviceList,
    productList,
    paginationData,
    collectionList,
    relatedProductList,
    selectedCollection,
    isRequestSentLoading,
    isShopCollectionModalOpen,
    isCollectionAddedModalOpen,
    isSuccessfullyReqModalOpen,
    isCollectionAlreadyAddedModalOpen,
    navigate,
    showSCModal,
    loginHandler,
    handleCAModalOk,
    handleSCModalOk,
    setIsAddedToShop,
    myShopNavigation,
    setPaginationData,
    handleTableChange,
    sendAccessRequest,
    handleSRModalClose,
    handleSCModalCancel,
    handleServiceDetail,
    handleProductDetail,
    handleCollectionDetail,
    handleAddToShopCollection,
    sendCollectionAccessRequest,
  } = useSearchProductsHook();

  return (
    <>
      <ILHead title="Search results" />
      {/* RELATED PRODUCTS SECTION START */}
      <section className={clsx(styles.itemSearchResultSection)}>
        <div className="container">
          <Row>
            <Col
              xs={24}
              className={clsx(
                styles.searchResultWrap,
                "d-flex align-items-baseline"
              )}
            >
              <Link to="/brands" className="text-dark-purple hover-text">
                <ArrowLeft02Icon
                  size={34}
                  color="currentColor"
                  variant={"stroke"}
                  strokeWidth={2}
                />
              </Link>
              <div>
                <h2 className="md-secondary-title">
                  Sökresultat för "<span>{brandData?.brandDetail?.name}</span>"
                </h2>
                {brandList?.count > 0 && (
                  <h5 className="text-shark-fin fw-medium p big mb-0">
                    <span className="text-dark-purple">
                      {`(${countHandler(brandList?.rows?.length)})`}
                    </span>{" "}
                    Resultat
                  </h5>
                )}
              </div>
            </Col>
            <Col
              xs={24}
              className={clsx(styles.relatedBrandsListWrap, "section-sm-pt")}
            >
              <div className="d-flex align-items-center flex-column flex-md-row justify-content-between gap-5 gap-md-4">
                <h5 className="big fw-medium text-dark-purple mb-4 mb-xxl-2 text-center text-md-start">
                  Relaterad Brands
                </h5>
              </div>

              <ul className={clsx(styles.relatedBrandsList, "d-grid")}>
                {isLoading?.brandList ? (
                  <div className="skeleton-card p-0 mb-5">
                    <ILSkeleton isDataCard />
                  </div>
                ) : (
                  brandList?.count > 0 &&
                  brandList?.rows?.map((rbItem, rbIndex) => {
                    return (
                      <li key={rbIndex} className="d-flex align-items-start">
                        <div
                          className={clsx(
                            styles.brandLogo,
                            "flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center bg-clr-walkie-chalkie"
                          )}
                          style={{ borderColor: rbItem.brandBorderColor }}
                        >
                          <img
                            src={rbItem?.image}
                            alt="advertising agency | ilonsi"
                            className="min-w-inherit min-h-inherit w-100 h-100 object-fit-cover radius-inherit"
                          />
                        </div>
                        <div className="w-100">
                          <h5 className="fw-semibold text-dark-purple p big">
                            {rbItem.label}
                          </h5>

                          <ILReadMoreLess
                            text={rbItem.bio !== null ? rbItem.bio : ""}
                            sliceLength={50}
                            sliceClassName="custom-text-class"
                            className="text-gray custom-text-class word-break-word mb-0"
                            onClick={(e) => e.stopPropagation()}
                          />
                        </div>
                      </li>
                    );
                  })
                )}
                {!isLoading?.brandList && brandList?.count === 0 && (
                  <p>Inga relaterade produkter hittades</p>
                )}
              </ul>
            </Col>
            {!isLoading?.brandList &&
              brandData &&
              brandData?.brandDetail?.selling_type === "PRODUCT" && (
                <Col
                  xs={24}
                  className={clsx(
                    styles.relatedBrandsListWrap,
                    "section-sm-pt"
                  )}
                >
                  <div className="d-flex align-items-center flex-column flex-md-row justify-content-between gap-5 gap-md-4">
                    <h5 className="big fw-medium text-dark-purple mb-0 text-center text-md-start">
                      Relaterade produkter
                    </h5>
                    {productList?.totalItems > 0 && (
                      <Link
                        to="/product-search-filter"
                        className="p big-initial fw-medium gradient-text mb-0 flex-0-auto"
                        onClick={() => {
                          localStorage.setItem("categoryType", "product");
                          localStorage.setItem("brandId", params?.id);
                        }}
                      >
                        Visa alla produkter
                      </Link>
                    )}
                  </div>
                  {isLoading?.productList ? (
                    <div className="skeleton-card border mt-5">
                      <ILSkeleton isImgTitleLabelCard2 />
                    </div>
                  ) : (
                    productList?.totalItems > 0 && (
                      <ILSwiper
                        leftArrowClassName="left-related-products-arrow"
                        rightArrowClassName="right-related-products-arrow"
                        swiperButtonClassName="d-none d-md-block"
                        spaceBetween={20}
                        slidesPerView={4}
                        autoplay={false}
                        loop={false}
                        breakpoints={featuredProductBreakPoints}
                        grabCursor={true}
                      >
                        {productList?.rows?.map((RPItem, RPIndex) => {
                          return (
                            <SwiperSlide key={RPIndex}>
                              <ILProductCard
                                isService={false}
                                productDataShow={RPItem}
                                isLoggedIn={isLoggedIn}
                                isProductLabel
                                loginHandler={loginHandler}
                                paginationData={paginationData}
                                productList={relatedProductList}
                                setIsAddedToShop={setIsAddedToShop}
                                setPaginationData={setPaginationData}
                                handleTableChange={handleTableChange}
                                handleProductDetail={handleProductDetail}
                                isRequestSentLoading={isRequestSentLoading}
                                sendAccessRequest={sendAccessRequest}
                              />
                            </SwiperSlide>
                          );
                        })}
                      </ILSwiper>
                    )
                  )}
                  {!isLoading?.productList && productList?.totalItems === 0 && (
                    <ILNoDataCard
                      isIcon
                      className="position-relative z-3 mt-5"
                      title={"Inga relaterade produkter hittades"}
                    />
                  )}
                </Col>
              )}
          </Row>
        </div>
      </section>
      {/* RELATED PRODUCTS SECTION END */}

      {/* RELATED SERVICES SECTION START */}
      {!isLoading?.brandList &&
        brandData &&
        brandData?.brandDetail?.selling_type === "SERVICE" && (
          <section className="section-md-pt section-md-pb">
            <div className="container">
              <Row>
                <Col xs={24}>
                  <div className="d-flex align-items-center flex-column flex-md-row justify-content-between gap-5 gap-md-4">
                    <h5 className="big fw-medium text-dark-purple mb-0 text-center text-md-start">
                      Relaterade tjänster
                    </h5>
                    {serviceList?.totalItems > 0 && (
                      <Link
                        to="/product-search-filter"
                        className="p big-initial fw-medium gradient-text mb-0 flex-0-auto"
                        onClick={() => {
                          localStorage.setItem("categoryType", "service");
                          localStorage.setItem("brandId", params?.id);
                        }}
                      >
                        Se alla tjänster
                      </Link>
                    )}
                  </div>
                  {isLoading?.serviceList ? (
                    <div className="skeleton-card border mt-5">
                      <ILSkeleton isImgTitleLabelCard2 />
                    </div>
                  ) : (
                    serviceList?.totalItems > 0 && (
                      <ILSwiper
                        leftArrowClassName="left-related-service-arrow"
                        rightArrowClassName="right-related-service-arrow"
                        swiperButtonClassName="d-none d-md-block"
                        spaceBetween={20}
                        slidesPerView={4}
                        autoplay={false}
                        loop={false}
                        breakpoints={featuredServicesBreakPoints}
                        grabCursor={true}
                      >
                        {serviceList?.rows?.map((FSItem, FSIndex) => {
                          return (
                            <SwiperSlide key={FSIndex}>
                              <ILProductCard
                                isService
                                isPriceChanged
                                isLoggedIn={isLoggedIn}
                                productDataShow={FSItem}
                                isRatingAndAddressPresent
                                loginHandler={loginHandler}
                                paginationData={paginationData}
                                productList={relatedProductList}
                                setPaginationData={setPaginationData}
                                setIsAddedToShop={setIsAddedToShop}
                                handleTableChange={handleTableChange}
                                handleProductDetail={handleServiceDetail}
                                isRequestSentLoading={isRequestSentLoading}
                                sendAccessRequest={sendAccessRequest}
                              />
                            </SwiperSlide>
                          );
                        })}
                      </ILSwiper>
                    )
                  )}
                  {!isLoading?.serviceList && serviceList?.totalItems === 0 && (
                    <ILNoDataCard
                      className="position-relative z-3 mt-5"
                      title={"Inga relaterade tjänster hittades"}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </section>
        )}
      {/* RELATED SERVICES SECTION END */}

      {/* RELATED COLLECTION START */}
      {brandData?.brandDetail?.selling_type === "PRODUCT" && (
        <section className="section-md-pt section-md-pb">
          <div className="container">
            <Row>
              <Col xs={24}>
                <div className="d-flex align-items-center flex-column flex-md-row justify-content-between gap-5 gap-md-4">
                  <h5 className="big fw-medium text-dark-purple mb-0 text-center text-md-start">
                    Relaterad kollection
                  </h5>
                  {!isLoading?.collectionList &&
                    collectionList?.totalItems > 0 && (
                      <Link
                        to="/product-search-filter"
                        className="p big-initial fw-medium gradient-text mb-0 flex-0-auto"
                        onClick={() => {
                          localStorage.setItem("categoryType", "collection");
                          localStorage.setItem("brandId", params?.id);
                        }}
                      >
                        Visa alla kollection
                      </Link>
                    )}
                </div>

                {isLoading?.collectionList ? (
                  <div className="skeleton-card border mt-5">
                    <ILSkeleton isImgTitleLabelCard2 />
                  </div>
                ) : (
                  collectionList?.totalItems > 0 && (
                    <ILSwiper
                      leftArrowClassName="left-related-collection-service-arrow"
                      rightArrowClassName="right-related-collection-service-arrow"
                      swiperButtonClassName="d-none d-md-block"
                      spaceBetween={20}
                      slidesPerView={3}
                      autoplay={false}
                      loop={false}
                      breakpoints={featuredCollectionsBreakPoints}
                      grabCursor={true}
                    >
                      {collectionList?.rows?.map((RCItem, RCIndex) => {
                        return (
                          <SwiperSlide key={RCIndex}>
                            <ILProductGalleryCard
                              isLoggedIn={isLoggedIn}
                              loginHandler={loginHandler}
                              ILProductGalleryCardDataList={RCItem}
                              sendAccessRequest={sendCollectionAccessRequest}
                              handleCollectionDetail={handleCollectionDetail}
                              collectionData={selectedCollection}
                              showSCModal={showSCModal}
                              isShopCollectionModalOpen={
                                isShopCollectionModalOpen
                              }
                              handleSCModalOk={handleSCModalOk}
                              handleSCModalCancel={handleSCModalCancel}
                              handleAddToShopCollection={
                                handleAddToShopCollection
                              }
                              pagination={{ dynamicBullets: true }}
                              grabCursor={true}
                              isRequestSentLoading={isRequestSentLoading}
                            />
                          </SwiperSlide>
                        );
                      })}
                    </ILSwiper>
                  )
                )}

                {!isLoading?.collectionList &&
                  collectionList?.totalItems === 0 && (
                    <ILNoDataCard
                      isIcon
                      className="position-relative z-3 mt-5"
                      title={"Inga relaterade kollektioner hittades"}
                    />
                  )}
              </Col>
            </Row>
          </div>
        </section>
      )}
      {/* RELATED COLLECTION END */}

      <ILModal
        open={isCollectionAddedModalOpen}
        onOk={() => handleCAModalOk("new")}
        onCancel={() => handleCAModalOk("new")}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <span className="lh-0">
                <img
                  src={gradientCircleRound}
                  fluid
                  alt="advertising agency | ilonsi"
                />
              </span>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Din kollektion har lagts till!
              </h4>
              <ILButton
                ILBtnClass="w-100"
                variant={"primary-gradient"}
                handleClick={myShopNavigation}
              >
                Visa i min butik
              </ILButton>
            </div>
          </>
        }
        isFooterRemove
      />

      <ILModal
        open={isCollectionAlreadyAddedModalOpen}
        onOk={() => handleCAModalOk("already")}
        onCancel={() => handleCAModalOk("already")}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <span className="lh-0">
                <img
                  src={gradientCircleRound}
                  fluid
                  alt="advertising agency | ilonsi"
                />
              </span>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Din kollektion har lagts till redan!
              </h4>
              <ILButton
                ILBtnClass="w-100"
                variant={"primary-gradient"}
                handleClick={myShopNavigation}
              >
                Visa i min butik
              </ILButton>
            </div>
          </>
        }
        isFooterRemove
      />

      <ILModal
        open={isShopCollectionModalOpen}
        onOk={handleSCModalOk}
        onCancel={handleSCModalCancel}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <div
                className="brand-logo brand-logo-big flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center mx-auto"
                style={{ borderColor: "#000000" }}
              >
                <img
                  src={selectedCollection?.brand?.profile_image}
                  height={50}
                  width={50}
                  alt="advertising agency | ilonsi"
                  className="object-fit-contain img-fluid"
                />
              </div>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Vill du lägga till den här kollektionen i din butik?
              </h4>
              <p className="paragraph-sm-text paragraph-md-text text-center mb-0">
                Denna kollektion kommer att läggas till i din butik.
              </p>
              <div className="d-flex align-items-center gap-4 modal-more-btn">
                <ILButton
                  disabled={isLoading}
                  ILBtnClass="w-50"
                  variant={"primary-gradient"}
                  onClick={handleAddToShopCollection}
                >
                  Ja, Lägg till
                </ILButton>
                <ILButton
                  ILBtnClass="w-50"
                  variant={"gradient-bordered"}
                  onClick={handleSCModalCancel}
                >
                  Nej, Avbryt
                </ILButton>
              </div>
            </div>
          </>
        }
        isFooterRemove
      />

      <ILModal
        open={isSuccessfullyReqModalOpen}
        onOk={handleSRModalClose}
        onCancel={handleSRModalClose}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <span className="lh-0">
                <img
                  src={gradientCircleRound}
                  fluid
                  alt="advertising agency | ilonsi"
                />
              </span>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Förfrågan har skickats!
              </h4>
              <ILButton
                ILBtnClass="w-100"
                variant={"primary-gradient"}
                handleClick={() =>
                  navigate("/dashboard/my-profile/my-access-request")
                }
              >
                Hantera förfrågningars
              </ILButton>
            </div>
          </>
        }
        isFooterRemove
      />
    </>
  );
};
export default ILSearchResults;
