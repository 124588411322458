import { emailRegex, numberRegex, passwordRegex } from "../../regexs";

export const validateLogin = (name, value, validateMessages) => {
  let errors = { ...validateMessages };
  switch (name) {
    case "email":
      if (
        value === undefined ||
        value?.trim() === null ||
        value?.trim() === ""
      ) {
        errors.email = "E-postadress krävs";
      } else {
        delete errors.email;
      }
      break;

    case "password":
      if (value.trim() === null || value.trim() === "" || value === undefined) {
        errors.password = "Lösenord krävs";
      } else {
        delete errors.password;
      }
      break;

    default:
      break;
  }

  return { errors };
};

export const validateSubmitLogin = (data) => {
  let errors = {};

  if (data.email === undefined || data.email === null || data.email === "") {
    errors.email = "E-postadress krävs";
  }

  if (
    data.password === undefined ||
    data.password === null ||
    data.password === ""
  ) {
    errors.password = "Lösenord krävs";
  }

  return { errors };
};

export const validateForgotPassword = (name, value, validateMessages) => {
  let errors = { ...validateMessages };
  switch (name) {
    case "email":
      if (value === null || value === "" || value === undefined) {
        errors.email = "E-postadress krävs";
      } else if (!emailRegex.test(value)) {
        errors.email = "Ogiltig e-postadress";
      } else {
        delete errors.email;
      }
      break;

    default:
      break;
  }
  return { errors };
};

export const validateSubmitForgotPassword = (data) => {
  let errors = {};
  if (data.email === null || data.email === "" || data.email === undefined) {
    errors.email = "E-postadress krävs";
  }

  if (data.email && !emailRegex.test(data.email)) {
    errors.email = "Ogiltig e-postadress";
  }

  return { errors };
};

export const validateResetPassword = (
  name,
  value,
  validateMessages,
  passwordValue
) => {
  let errors = { ...validateMessages };
  switch (name) {
    case "password":
      if (value === undefined || value.trim() === null || value.trim() === "") {
        errors.password = "Lösenord krävs";
      } else if (!passwordRegex.test(value)) {
        errors.password =
          "Lösenordet måste innehålla en stor bokstav, en liten bokstav, ett nummer, ett specialtecken och inga mellanslag";
      } else if (value.length < 6) {
        errors.password = "Lösenordslängden måste vara minst 6 bokstäver";
      } else if (value.length > 16) {
        errors.password =
          "Lösenordslängden måste vara mindre än eller lika med 16 bokstäver";
      } else {
        delete errors.password;
      }
      break;

    case "confirmPassword":
      if (value === undefined || value.trim() === null || value.trim() === "") {
        errors.confirmPassword = "Bekräfta lösenord krävs";
      } else if (passwordValue !== value) {
        errors.confirmPassword =
          "Lösenord och bekräftelselösenord stämmer inte överens";
      } else {
        delete errors.confirmPassword;
      }
      break;

    default:
      break;
  }
  return { errors };
};

export const validateSubmitResetPassword = (data) => {
  let errors = {};

  if (
    data.password === undefined ||
    data.password?.trim() === null ||
    data.password?.trim() === ""
  ) {
    errors.password = "Lösenord krävs";
  }

  if (data.password) {
    if (!passwordRegex.test(data.password)) {
      errors.password =
        "Lösenordet måste innehålla en stor bokstav, en liten bokstav, ett nummer, ett specialtecken och inga mellanslag";
    }
    if (data.password.length < 6) {
      errors.password = "Lösenordslängden måste vara minst 6 bokstäver";
    }
    if (data.password.length > 16) {
      errors.password =
        "Lösenordslängden måste vara mindre än eller lika med 16 bokstäver";
    }
  }

  if (
    data.confirmPassword === undefined ||
    data.confirmPassword?.trim() === null ||
    data.confirmPassword?.trim() === ""
  ) {
    errors.confirmPassword = "Bekräfta lösenord krävs";
  }

  if (data.password !== data.confirmPassword) {
    errors.confirmPassword = "Lösenord och Bekräfta lösenord matchar inte";
  }

  return { errors };
};

export const validateVerifyOtp = (name, value, validateMessages) => {
  let errors = { ...validateMessages };
  switch (name) {
    case "otp":
      if (value == undefined || value === null || value === "") {
        errors.otp = "OTP krävs";
      } else if (!numberRegex.test(value)) {
        errors.otp = "Vänligen ange giltig OTP";
      } else {
        delete errors.otp;
      }
      break;

    default:
      break;
  }

  return { errors };
};

export const validateSubmitVerifyOtp = (data) => {
  let errors = {};
  if (data.otp === undefined || data.otp === null || data.otp === "") {
    errors.otp = "OTP krävs";
  }
  if (data.otp && !numberRegex.test(data.otp)) {
    errors.otp = "Vänligen ange giltig OTP";
  }

  return { errors };
};
