import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  doFetchAddCollection,
  doFetchCollectionProductList,
  doFetchSendAccessRequest,
} from "../../actions";
import { getDecryptionString } from "../../services";

export const useBrandCollectionsHook = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [productList, setProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCollectionLoading, setIsCollectionLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({
    pageSize: 8,
    page: 1,
    sortByKey: "",
    sortByType: "",
  });
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("_token") ? true : false
  );
  const [isShopCollectionModalOpen, setIsShopCollectionModalOpen] =
    useState(false);
  const [isCollectionAddedModalOpen, setIsCollectionAddedModalOpen] =
    useState(false);
  const { userDetails } = useSelector(
    (state) => state.storeAuthenticateReducer
  );
  const [isAccessReqModalOpen, setIsAccessReqModalOpen] = useState(false);
  const [isSuccessfullyReqModalOpen, setIsSuccessfullyReqModalOpen] =
    useState(false);

  const [
    isCollectionAlreadyAddedModalOpen,
    setIsCollectionAlreadyAddedModalOpen,
  ] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState({});

  useEffect(() => {
    if (params?.id) {
      if (isLoggedIn && userDetails?.userData?.id) {
        fetchCollectionProductList(paginationData);
      } else {
        fetchCollectionProductList(paginationData);
      }
    }
  }, [isLoggedIn, paginationData?.page, paginationData?.pageSize, userDetails]);

  const showARModal = () => {
    setIsAccessReqModalOpen(true);
  };
  const handleARModalOk = () => {
    setIsAccessReqModalOpen(false);
  };
  const handleARModalCancel = () => {
    setIsAccessReqModalOpen(false);
  };

  const showSRModal = () => {
    sendCollectionAccessRequest();
  };
  const handleSRModalOk = () => {
    setIsSuccessfullyReqModalOpen(false);
  };
  const handleSRModalCancel = () => {
    setIsSuccessfullyReqModalOpen(false);
  };

  const showSCModal = (data) => {
    setSelectedCollection(data);
    setIsShopCollectionModalOpen(true);
  };

  const handleSCModalOk = () => {
    setIsShopCollectionModalOpen(false);
  };

  const handleSCModalCancel = () => {
    setIsShopCollectionModalOpen(false);
  };

  const handleCAModalOk = (name) => {
    if (name === "new") {
      setIsCollectionAddedModalOpen(!isCollectionAddedModalOpen);
    } else {
      setIsCollectionAlreadyAddedModalOpen(!isCollectionAlreadyAddedModalOpen);
    }
  };

  const navigateMyAccessReq = () => {
    navigate("/dashboard/my-profile/my-access-request");
  };

  const myShopNavigation = () => {
    navigate("/dashboard/my-shop");
  };

  const sendCollectionAccessRequest = async () => {
    try {
      setIsCollectionLoading(true);
      const requestBody = {
        type: "ACCESS_REQUEST",
        toUser: productList?.collection?.brand?.id,
        collectionId: productList?.collection?.id,
      };

      const accessRequestResponse = await doFetchSendAccessRequest(requestBody);

      if (accessRequestResponse?.status === 200) {
        fetchCollectionProductList(paginationData);
      } else {
        toast.error(accessRequestResponse?.message);
      }
      setIsAccessReqModalOpen(false);
      setIsCollectionLoading(false);
    } catch (error) {
      console.log({ error });
      setIsCollectionLoading(false);
    }
  };

  const handleAddToShopCollection = async () => {
    try {
      setIsCollectionLoading(true);

      const requestBody = {
        brandCollectionId: params?.id,
        categoryId: selectedCollection?.brand?.category_id,
      };

      const collectionAddResponse = await doFetchAddCollection(requestBody);

      if (collectionAddResponse?.status === 200) {
        setIsCollectionAddedModalOpen(true);
      } else if (collectionAddResponse?.status === 409) {
        setIsCollectionAlreadyAddedModalOpen(true);
      } else {
        toast.error(collectionAddResponse?.data?.message);
      }
      fetchCollectionProductList(paginationData);

      setIsShopCollectionModalOpen(false);
      setIsCollectionLoading(false);
    } catch (error) {
      console.log({ error });
      setIsCollectionLoading(false);
    }
  };

  const handleProductDetail = (data) => {
    navigate(`/brands/product-details/${data?.product?.id}`);
  };

  const handleTableChange = (event, filter, sort) => {
    try {
      setPaginationData((prev) => ({
        ...prev,
        page: event,
      }));
    } catch (error) {
      console.error("Error occurred during pagination:", error);
    }
  };

  const fetchCollectionProductList = async (data) => {
    try {
      setIsLoading(true);

      const productListData = await doFetchCollectionProductList(
        params?.id,
        data?.page,
        data?.pageSize,
        data?.sortByKey,
        data?.sortByType,
        userDetails?.userData?.id ? userDetails?.userData?.id : null
      );

      if (productListData?.status === 200) {
        const decryptedCategoryData = await JSON.parse(
          getDecryptionString(productListData?.data?.data)
        );
        setProductList(decryptedCategoryData);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(
        "Error occurred during fetching collection products:",
        error
      );
    }
  };

  return {
    isLoading,
    isLoggedIn,
    productList,
    paginationData,
    selectedCollection,
    isCollectionLoading,
    isAccessReqModalOpen,
    isShopCollectionModalOpen,
    isCollectionAddedModalOpen,
    isSuccessfullyReqModalOpen,
    isCollectionAlreadyAddedModalOpen,
    showSCModal,
    showARModal,
    showSRModal,
    handleARModalOk,
    handleSRModalOk,
    handleCAModalOk,
    handleSCModalOk,
    myShopNavigation,
    handleTableChange,
    handleARModalCancel,
    handleProductDetail,
    handleSRModalCancel,
    navigateMyAccessReq,
    handleSCModalCancel,
    setIsAccessReqModalOpen,
    handleAddToShopCollection,
  };
};
