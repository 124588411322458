import { useEffect, useMemo, useState } from "react";
import {
  doFetchGigaPayCreate,
  doFetchGigaPayEarn,
  doFetchGigaPayoutHistory,
} from "../../../actions";
import { getDecryptionString } from "../../../services";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { swedenFlag } from "../../../constants/imageData";

export const usePaymentMethod = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isWithdrawAmountLoading, setIsWithdrawAmountLoading] = useState(false);
  const [isPayHistoryLoading, setIsPayHistoryLoading] = useState(false);
  const [earningDetails, setEarningDetails] = useState({});
  const [payoutHistoryDetails, setPayoutHistoryDetails] = useState({});
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [isWithdrawMoneyModalOpen, setIsWithdrawMoneyModalOpen] =
    useState(false);
  const [paginationData, setPaginationData] = useState({
    pageSize: 10,
    page: 1,
  });

  useEffect(() => {
    fetchEarningDetails();
  }, []);

  useEffect(() => {
    fetchPayoutHistory(paginationData);
  }, [paginationData?.page, paginationData?.pageSize]);

  const handleWithdrawMoneyModal = () => {
    setIsWithdrawMoneyModalOpen(!isWithdrawMoneyModalOpen);
    setWithdrawAmount("");
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setWithdrawAmount(value);
  };

  const fetchEarningDetails = async () => {
    try {
      setIsLoading(true);

      const earningDetailsResponse = await doFetchGigaPayEarn();

      if (earningDetailsResponse?.status === 200) {
        const decryptedEarningDetails = await JSON.parse(
          getDecryptionString(earningDetailsResponse?.data?.data)
        );
        setEarningDetails(decryptedEarningDetails);
      } else {
        toast.error(earningDetailsResponse?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  const fetchPayoutHistory = async (data) => {
    try {
      setIsPayHistoryLoading(true);

      const payoutHistoryResponse = await doFetchGigaPayoutHistory(
        data?.page,
        data?.pageSize
      );

      if (payoutHistoryResponse?.status === 200) {
        const decryptedPayoutHistory = await JSON.parse(
          getDecryptionString(payoutHistoryResponse?.data?.data)
        );
        setPayoutHistoryDetails(decryptedPayoutHistory);
      } else {
        toast.error(payoutHistoryResponse?.data?.message);
      }
      setIsPayHistoryLoading(false);
    } catch (error) {
      console.log({ error });
      setIsPayHistoryLoading(false);
    }
  };

  const payoutHistoryColumns = useMemo(() => {
    return [
      {
        title: "Transaktionsdatum",
        dataIndex: "date",
        key: "date",
        render: (_, item) => (
          <>
            <p className="fw-medium gradient-text max-w-max-content">
              {moment(item?.created_at)?.format("DD MMM YYYY")}
            </p>
          </>
        ),
        width: "120px",
      },
      {
        title: "Beskrivning",
        dataIndex: "description",
        key: "description",
        width: "120px",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {item?.description}
          </p>
        ),
      },
      {
        title: "Land",
        dataIndex: "country",
        key: "country",
        render: (_, item) => (
          <div className="d-flex gap-3">
            <img src={swedenFlag} height={30} width={30} />
            <p className="fw-medium gradient-text max-w-max-content">Sweden</p>
          </div>
        ),
        width: "120px",
      },
      {
        title: "Valuta",
        dataIndex: "currency",
        key: "currency",
        width: "100px",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">SEK</p>
        ),
      },
      {
        title: "Belopp",
        dataIndex: "amount",
        key: "amount",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {item?.amount} KR
          </p>
        ),
        width: "120px",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {item?.status}
          </p>
        ),
        width: "120px",
      },
    ];
  }, [payoutHistoryDetails]);

  const handleTableChange = (event, filter, sort) => {
    try {
      setPaginationData((prev) => ({
        ...prev,
        page: event?.current,
        pageSize: event?.pageSize,
      }));
    } catch (error) {
      console.error("Error occurred during pagination:", error);
    }
  };

  const fetchGigapayWithdraw = async () => {
    try {
      setIsWithdrawAmountLoading(true);

      const requestBody = {
        currency: "SEK",
        description: "from ilonsi",
        cost: withdrawAmount,
      };
      const gigapayWithdrawResponse = await doFetchGigaPayCreate(requestBody);

      if (gigapayWithdrawResponse?.status === 200) {
        handleWithdrawMoneyModal();
        fetchPayoutHistory(paginationData);
        toast.success(gigapayWithdrawResponse?.data?.message);
      } else {
        toast.error(gigapayWithdrawResponse?.data?.message);
      }
      setIsWithdrawAmountLoading(false);
    } catch (error) {
      console.log({ error });
      setIsWithdrawAmountLoading(false);
    }
  };
  return {
    isLoading,
    withdrawAmount,
    earningDetails,
    paginationData,
    isPayHistoryLoading,
    payoutHistoryColumns,
    payoutHistoryDetails,
    isWithdrawAmountLoading,
    isWithdrawMoneyModalOpen,
    handleTableChange,
    handleInputChange,
    fetchGigapayWithdraw,
    handleWithdrawMoneyModal,
  };
};
