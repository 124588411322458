import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  doFetchBrandDetails,
  doFetchBrandProducts,
  doFetchBrandServices,
  doFetchSendAccessRequest,
  doFetchBrandCollections,
  doFetchProductCategories,
  doFetchServiceCategories,
  doFetchAddCollection,
  doFetchCountryList,
  doFetchCities,
} from "../../actions";
import { getDecryptionString } from "../../services";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";

export const useSearchFilterHook = () => {
  const ref = useRef(null);
  const navigate = useNavigate();

  const [categoryList, setCategoryList] = useState({});
  const [isAddedToShop, setIsAddedToShop] = useState(true);
  const [categoryType, setCategoryType] = useState(
    localStorage.getItem("categoryType")
      ? localStorage.getItem("categoryType")
      : "product"
  );
  const [isLoading, setIsLoading] = useState({
    collectionAdd: false,
    getProductAndServices: false,
  });
  const [paginationData, setPaginationData] = useState({
    pageSize: 12,
    page: 1,
    sellCommission: 100,
    brand: [],
    ppcRange: [],
    priceRange: [],
    userId: [],
    categoryIds: [],
    countries: [],
    cities: [],
  });
  const [brandPaginationData, setBrandPaginationData] = useState({
    pageSize: 40,
    page: 1,
  });
  const [isFilterSidebar, setIsFilterSidebar] = useState(false);
  const [radioValue, setRadioValue] = useState(
    categoryType === "service" ? 1 : categoryType === "product" ? 1 : 2
  );
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("_token") ? true : false
  );
  const [brandId, setBrandId] = useState(localStorage.getItem("brandId"));
  const [brandList, setBrandList] = useState({});
  const [botRef, botInView, botEntries] = useInView({
    threshold: 0,
  });
  const [relatedProductList, setRelatedProductList] = useState([]);
  const [brandCategoryList, setBrandCategoryList] = useState([]);
  const [isShopCollectionModalOpen, setIsShopCollectionModalOpen] =
    useState(false);
  const [isCollectionAddedModalOpen, setIsCollectionAddedModalOpen] =
    useState(false);
  const [
    isCollectionAlreadyAddedModalOpen,
    setIsCollectionAlreadyAddedModalOpen,
  ] = useState(false);
  const [apiStatus, setApiStatus] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState({});
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [modalPaginationData, setModalPaginationData] = useState({
    search: "",
    pageSize: 10,
    page: 1,
  });
  const [isSuccessfullyReqModalOpen, setIsSuccessfullyReqModalOpen] =
    useState(false);
  const [isRequestSentLoading, setIsRequestSentLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const { userDetails } = useSelector(
    (state) => state.storeAuthenticateReducer
  );

  useEffect(() => {
    let queryParams = new URLSearchParams(location.search);
    userDetails &&
      queryParams.set("user-name", userDetails?.userData?.user_name);
    queryParams.set("res", apiStatus ? "true" : "false");

    navigate(`${location.pathname}?${queryParams.toString()}`, {
      replace: true,
    });
  }, [location?.pathname, userDetails, apiStatus]);

  useEffect(() => {
    const brandId = localStorage.getItem("brandId");
    const categoryId = localStorage.getItem(localStorage.getItem("categoryId"));

    if (brandId && !paginationData?.brand?.includes(brandId)) {
      setPaginationData((prevState) => ({
        ...prevState,
        brand: [...prevState?.brand, brandId],
      }));
    }

    if (categoryId && !paginationData?.categoryIds.includes(categoryId)) {
      setPaginationData((prevState) => ({
        ...prevState,
        categoryIds: [...prevState?.categoryIds, categoryId],
      }));
    }
  }, []);

  useEffect(() => {
    if (categoryType === "service") {
      fetchCountryList();
    }
  }, [categoryType]);

  useEffect(() => {
    if (categoryType === "service") {
      fetchCitiesList();
    }
  }, [categoryType, paginationData?.countries]);

  useEffect(() => {
    if (categoryType === "product" && isLoggedIn && userDetails?.userData?.id) {
      fetchRelatedProducts(modalPaginationData);
    }
  }, [
    userDetails,
    brandId,
    categoryType,
    modalPaginationData?.page,
    isLoggedIn,
  ]);

  const memoizedBrand = useMemo(
    () => paginationData?.brand,
    [JSON.stringify(paginationData?.brand)]
  );
  const memoizedCategoryIds = useMemo(
    () => paginationData?.categoryIds,
    [JSON.stringify(paginationData?.categoryIds)]
  );

  useEffect(() => {
    if (brandList?.hasNext) {
      if (botInView) {
        setBrandPaginationData((prevState) => ({
          ...prevState,
          page: prevState?.page + 1,
        }));
      }
    }
  }, [botInView, brandList]);

  useEffect(() => {
    fetchBrandList(brandPaginationData);
  }, [
    radioValue,
    brandPaginationData?.pageSize,
    brandPaginationData?.page,
    paginationData?.categoryIds,
  ]);

  useEffect(() => {
    if (
      ((memoizedBrand.length > 0 || !localStorage.getItem("brandId")) &&
        memoizedCategoryIds) ||
      isAddedToShop
    ) {
      if (isLoggedIn) {
        setIsLoading((prev) => ({
          ...prev,
          getProductAndServices: true,
        }));

        if (userDetails?.userData?.id) {
          setPaginationData((prevState) => ({
            ...prevState,
            userId: [userDetails?.userData?.id],
          }));
          fetchProducts(paginationData);
        }
      } else {
        fetchProducts(paginationData);
      }
    }
  }, [
    memoizedBrand,
    memoizedCategoryIds,
    radioValue,
    userDetails,
    isLoggedIn,
    categoryType,
    isAddedToShop,
    paginationData?.page,
    paginationData?.brand,
    paginationData?.cities,
    paginationData?.pageSize,
    paginationData?.ppcRange,
    paginationData?.countries,
    paginationData?.priceRange,
  ]);

  useEffect(() => {
    function handleClickOutside(e) {
      if (!ref.current.contains(e.target)) {
        setIsFilterSidebar(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    fetchCategoryList();
  }, [categoryType]);

  const loginHandler = () => {
    navigate("/login");
  };

  const showSCModal = (data) => {
    setSelectedCollection(data);
    setIsShopCollectionModalOpen(true);
  };

  const handleSCModalOk = () => {
    setIsShopCollectionModalOpen(false);
  };
  const handleSCModalCancel = () => {
    setIsShopCollectionModalOpen(false);
  };

  const handleCAModalOk = (name) => {
    if (name === "new") {
      setIsCollectionAddedModalOpen(!isCollectionAddedModalOpen);
    } else {
      setIsCollectionAlreadyAddedModalOpen(!isCollectionAlreadyAddedModalOpen);
    }
  };

  const myShopNavigation = () => {
    navigate("/dashboard/my-shop");
  };

  const handleAddToShopCollection = async () => {
    try {
      setIsLoading((prev) => ({
        ...prev,
        collectionAdd: true,
      }));

      const requestBody = {
        brandCollectionId: selectedCollection?.id,
        categoryId: selectedCollection?.brand?.category_id,
      };

      const collectionAddResponse = await doFetchAddCollection(requestBody);

      if (collectionAddResponse?.status === 200) {
        setIsCollectionAddedModalOpen(true);
      } else if (collectionAddResponse?.status === 409) {
        setIsCollectionAlreadyAddedModalOpen(true);
      } else {
        toast.error(collectionAddResponse?.data?.message);
      }

      setIsShopCollectionModalOpen(false);
      fetchProducts(paginationData);

      setIsLoading((prev) => ({
        ...prev,
        collectionAdd: false,
      }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prev) => ({
        ...prev,
        collectionAdd: false,
      }));
    }
  };

  const handleSearchFilterOpen = () => {
    setIsFilterSidebar(true);
  };

  const handleSearchFilterClose = () => {
    setIsFilterSidebar(false);
  };

  const handleRadioChange = (event) => {
    if (categoryType === "product") {
      if (event?.target?.value === 2) {
        localStorage?.setItem("categoryType", "collection");
        setCategoryType("collection");
      }
    }
    if (categoryType === "collection") {
      if (event?.target?.value === 1) {
        localStorage?.setItem("categoryType", "product");
        setCategoryType("product");
      }
    }
    setRadioValue(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    const { name, value } = event?.target;

    if (name === "brand") {
      setPaginationData((prevState) => {
        const isValueInBrand = prevState?.brand?.includes(value);

        return {
          ...prevState,
          brand: isValueInBrand
            ? prevState?.brand?.filter((id) => id !== value)
            : [...prevState?.brand, value],
        };
      });
    } else if (name === "category") {
      setPaginationData((prevState) => {
        const isValueInCategories = prevState?.categoryIds?.includes(value);

        return {
          ...prevState,
          categoryIds: isValueInCategories
            ? prevState?.categoryIds?.filter((id) => id !== value)
            : [...prevState?.categoryIds, value],
        };
      });
    } else if (name === "ppc") {
      setPaginationData((prevState) => {
        const isValueInPpcRange = prevState?.ppcRange?.includes(value);

        return {
          ...prevState,
          ppcRange: isValueInPpcRange
            ? prevState?.ppcRange?.filter((id) => id !== value)
            : [...prevState?.ppcRange, value],
        };
      });
    } else if (name === "price") {
      setPaginationData((prevState) => {
        const isValueInPriceRange = prevState?.priceRange?.includes(value);

        return {
          ...prevState,
          priceRange: isValueInPriceRange
            ? prevState?.priceRange?.filter((id) => id !== value)
            : [...prevState?.priceRange, value],
        };
      });
    } else if (name === "countries") {
      setPaginationData((prevState) => {
        const isValueInCountries = prevState?.countries?.includes(value);

        return {
          ...prevState,
          countries: isValueInCountries
            ? prevState?.countries?.filter((id) => id !== value)
            : [...prevState?.countries, value],
        };
      });
    } else if (name === "cities") {
      setPaginationData((prevState) => {
        const isValueInCities = prevState?.cities?.includes(value);

        return {
          ...prevState,
          cities: isValueInCities
            ? prevState?.cities?.filter((id) => id !== value)
            : [...prevState?.cities, value],
        };
      });
    }

    setPaginationData((prevState) => ({ ...prevState, page: 1 }));
  };

  const handleSelectChange = (value, name) => {
    setPaginationData((prevState) => ({ ...prevState, [name]: value }));
    debounceSellCommissionHandler({
      ...paginationData,
      [name]: value,
    });
  };

  const debounceSellCommissionHandler = useCallback(
    debounce(async (data) => {
      await fetchProducts(data);
    }, 1000),
    []
  );

  const handleTableChange = (event, filter) => {
    try {
      setPaginationData((prev) => ({
        ...prev,
        page: event,
        pageSize: filter,
      }));
      window.scrollTo({ top: 0, behavior: "smooth" });
    } catch (error) {
      console.error("Error occurred during pagination:", error);
    }
  };

  const handleModalTableChange = (event, filter) => {
    try {
      setModalPaginationData((prev) => ({
        ...prev,
        page: event,
        pageSize: filter,
      }));
    } catch (error) {
      console.error("Error occurred during pagination:", error);
    }
  };

  const handleOnScrollBrand = () => {
    setBrandPaginationData((prevState) => ({
      ...prevState,
      page: prevState?.page + 1,
    }));

    debounceBrandsListHandler({
      ...brandPaginationData,
      page: brandPaginationData?.page + 1,
    });
  };

  const debounceBrandsListHandler = useCallback(
    debounce(async (data) => {
      await fetchBrandList(data);
    }, 1000),
    [brandPaginationData]
  );

  const handleClearFilter = () => {
    setPaginationData((prevState) => ({
      ...prevState,
      page: 1,
      pageSize: 12,
      sellCommission: 100,
      brand: [],
      countries: [],
      cities: [],
      ppcRange: [],
      priceRange: [],
      categoryIds: [],
    }));
  };

  const fetchProducts = async (data) => {
    try {
      setIsLoading((prev) => ({
        ...prev,
        getProductAndServices: true,
      }));

      let categoryData, requestBody;
      const filteredCountries = countryList
        ?.filter((country) => data?.countries.includes(country.id))
        ?.map((country) => country.label);

      const filteredCities = citiesList
        ?.filter((city) => data?.cities.includes(city?.value))
        ?.map((city) => city.label);

      requestBody = {
        currentPage: data?.page,
        pageSize: data?.pageSize,
        brand: data?.brand,
        ppcRange: data?.ppcRange,
        priceRange: data?.priceRange,
        sellCommission: {
          from: 0,
          to: data?.sellCommission,
        },
        userId: data?.userId,
        categoryId: data?.categoryIds,
        countries: filteredCountries?.length > 0 ? filteredCountries : [],
        cities: filteredCities?.length > 0 ? filteredCities : [],
      };
      if (categoryType === "product") {
        categoryData = await doFetchBrandProducts(requestBody);
      } else if (categoryType === "collection") {
        categoryData = await doFetchBrandCollections(requestBody);
      } else {
        categoryData = await doFetchBrandServices(requestBody);
      }

      if (categoryData?.status === 200) {
        const decryptedCategoryData = await JSON.parse(
          getDecryptionString(categoryData?.data)
        );
        setCategoryList(decryptedCategoryData);
      }
      setIsAddedToShop(false);
      setIsLoading((prev) => ({
        ...prev,
        getProductAndServices: false,
      }));
    } catch (error) {
      setIsLoading((prev) => ({
        ...prev,
        getProductAndServices: false,
      }));
      console.error("Error occurred during fetching products:", error);
    }
  };

  const handleProductDetail = (data) => {
    if (categoryType === "product") {
      navigate(`/brands/product-details/${data?.id}`);
    } else if (categoryType === "collection") {
      navigate(`/brand-collections/${data?.id}`);
    } else {
      navigate(`/brands/service-details/${data?.id}`);
    }
  };

  const sendAccessRequest = async (data) => {
    try {
      setIsRequestSentLoading(true);
      const requestBody = {
        type: "ACCESS_REQUEST",
        toUser: data?.brand?.id,
        ...(categoryType === "product"
          ? { productId: data?.id }
          : { serviceId: data?.id }),
      };

      const accessRequestResponse = await doFetchSendAccessRequest(requestBody);

      if (accessRequestResponse?.status === 200) {
        setIsSuccessfullyReqModalOpen(true);
        fetchProducts(paginationData);
      } else {
        toast.error(accessRequestResponse?.message);
      }
      setIsRequestSentLoading(false);
    } catch (error) {
      console.log({ error });
      setIsRequestSentLoading(false);
    }
  };

  const handleSRModalClose = () => {
    setIsSuccessfullyReqModalOpen(false);
  };

  const sendCollectionAccessRequest = async (data) => {
    try {
      setIsRequestSentLoading(true);
      const requestBody = {
        type: "ACCESS_REQUEST",
        toUser: data?.brand?.id,
        collectionId: data?.id,
      };

      const accessRequestResponse = await doFetchSendAccessRequest(requestBody);

      if (accessRequestResponse?.status === 200) {
        setIsSuccessfullyReqModalOpen(true);
        fetchCollections();
      } else {
        toast.error(accessRequestResponse?.message);
      }
      setIsRequestSentLoading(false);
    } catch (error) {
      console.log({ error });
      setIsRequestSentLoading(false);
    }
  };

  const fetchBrandList = async (data) => {
    try {
      const requestBody = {
        pageSize: data?.pageSize,
        currentPage: data?.page,
        type: categoryType === "service" ? "service" : "product",
        categoryId: paginationData?.categoryIds,
      };

      const brandsListResponse = await doFetchBrandDetails(requestBody);

      if (brandsListResponse?.status === 200) {
        const decryptedBrandDetails = await JSON.parse(
          getDecryptionString(brandsListResponse?.data?.data)
        );
        const brandListData = decryptedBrandDetails?.rows?.map((data) => {
          return {
            label: data?.brandDetail?.name,
            value: data?.brandDetail?.id,
          };
        });
        setBrandList({ ...decryptedBrandDetails, rows: brandListData });
      } else {
        toast.error(brandsListResponse?.message);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const fetchRelatedProducts = async (data) => {
    try {
      setIsModalLoading(true);

      let productBrandId = [];
      productBrandId.push(brandId);
      const requestBody = {
        brand: brandId !== null ? productBrandId : [],
        userId: userDetails ? userDetails?.userData?.id : null,
        hasAccess: true,
        pageSize: data?.pageSize,
        currentPage: data?.page,
      };

      const brandProductsResponse = await doFetchBrandProducts(requestBody);
      if (brandProductsResponse?.status === 200) {
        const decryptedBrandProducts = await JSON.parse(
          getDecryptionString(brandProductsResponse?.data)
        );

        setRelatedProductList(decryptedBrandProducts);
      } else {
        toast.error(brandProductsResponse?.message);
      }
      setIsModalLoading(false);
    } catch (error) {
      console.log({ error });
      setIsModalLoading(false);
    }
  };

  const fetchCategoryList = async () => {
    try {
      let categoryList;

      if (categoryType === "product" || categoryType === "collection") {
        categoryList = await doFetchProductCategories();
      } else {
        categoryList = await doFetchServiceCategories();
      }

      if (categoryList?.status === 200) {
        setApiStatus(true);

        const decryptedCategoryList = await JSON.parse(
          getDecryptionString(categoryList?.data)
        );

        const categoryListData = decryptedCategoryList?.map((data) => {
          return {
            value: data?.id,
            label: data?.name,
          };
        });
        setBrandCategoryList(categoryListData);
      } else {
        setApiStatus(false);
      }
    } catch (error) {
      console.error("Error occurred during fetching category list:", error);
    }
  };

  const fetchCountryList = async () => {
    try {
      const countryListResponse = await doFetchCountryList();
      if (countryListResponse?.status === 200) {
        const decryptedCountryList = await JSON.parse(
          getDecryptionString(countryListResponse?.data?.data)
        );
        const countriesListData = decryptedCountryList?.map((data) => {
          return {
            value: data?.name,
            label: data?.name,
            id: data?.id,
          };
        });
        setCountryList(countriesListData);
      } else {
        toast.error(countryListResponse?.message);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const fetchCitiesList = async () => {
    try {
      const citiesListResponse = await doFetchCities(
        paginationData?.countries?.length > 0
          ? paginationData?.countries?.join(",")
          : "e434260b-cbfa-47f2-807c-d49116afe944"
      );
      if (citiesListResponse?.status === 200) {
        const decryptedCitiesList = await JSON.parse(
          getDecryptionString(citiesListResponse?.data)
        );
        const citiesListData = decryptedCitiesList?.map((data) => {
          return {
            value: data?.id,
            label: data?.name,
          };
        });

        setCitiesList(citiesListData);
      } else {
        toast.error(citiesListResponse?.message);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return {
    ref,
    isLoading,
    brandList,
    isLoggedIn,
    radioValue,
    citiesList,
    countryList,
    categoryType,
    categoryList,
    paginationData,
    isModalLoading,
    isFilterSidebar,
    brandCategoryList,
    relatedProductList,
    selectedCollection,
    modalPaginationData,
    isRequestSentLoading,
    isShopCollectionModalOpen,
    isCollectionAddedModalOpen,
    isSuccessfullyReqModalOpen,
    isCollectionAlreadyAddedModalOpen,
    botRef,
    navigate,
    showSCModal,
    loginHandler,
    handleCAModalOk,
    handleSCModalOk,
    setIsAddedToShop,
    myShopNavigation,
    sendAccessRequest,
    handleClearFilter,
    handleTableChange,
    handleSRModalClose,
    handleRadioChange,
    handleSelectChange,
    handleSCModalCancel,
    handleProductDetail,
    handleOnScrollBrand,
    handleCheckboxChange,
    handleSearchFilterOpen,
    setModalPaginationData,
    handleModalTableChange,
    handleSearchFilterClose,
    handleAddToShopCollection,
    sendCollectionAccessRequest,
  };
};
