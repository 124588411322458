import {
  ArrowLeft02Icon,
  FilterHorizontalIcon,
  MultiplicationSignIcon,
} from "hugeicons-react";
import { Link } from "react-router-dom";
import { clsx } from "clsx";
import {
  ILAccordion,
  ILButton,
  ILHead,
  ILLoader,
  ILModal,
  ILNoDataCard,
  ILPagination,
  ILProductCard,
  ILProductGalleryCard,
  ILRadio,
  ILRangeSlider,
  ILSkeleton,
} from "../../components";
import { ILSearchFilterCheckbozList } from "./ILSearchFilterCheckbozList";
import { useSearchFilterHook } from "../../hooks/website/searchFilter.hook";
import { gradientCircleRound } from "../../constants/imageData";
import styles from "./ILProductSearchFilter.module.css";
import { countHandler } from "../../services";

const ILProductSearchFilter = () => {
  const {
    ref,
    brandList,
    isLoading,
    radioValue,
    isLoggedIn,
    citiesList,
    countryList,
    categoryType,
    categoryList,
    paginationData,
    isModalLoading,
    isFilterSidebar,
    brandCategoryList,
    selectedCollection,
    relatedProductList,
    modalPaginationData,
    isRequestSentLoading,
    isShopCollectionModalOpen,
    isCollectionAddedModalOpen,
    isSuccessfullyReqModalOpen,
    isCollectionAlreadyAddedModalOpen,
    botRef,
    navigate,
    showSCModal,
    loginHandler,
    handleSCModalOk,
    handleCAModalOk,
    setIsAddedToShop,
    myShopNavigation,
    handleClearFilter,
    handleTableChange,
    handleRadioChange,
    sendAccessRequest,
    handleSRModalClose,
    handleSelectChange,
    handleProductDetail,
    handleSCModalCancel,
    handleOnScrollBrand,
    handleCheckboxChange,
    handleSearchFilterOpen,
    setModalPaginationData,
    handleModalTableChange,
    handleSearchFilterClose,
    handleAddToShopCollection,
    sendCollectionAccessRequest,
  } = useSearchFilterHook();

  const radioList = [
    ...(categoryType === "product" || categoryType === "collection"
      ? [
          {
            text: "Produkter",
          },
          {
            text: "Företagsskollektioner",
          },
        ]
      : [
          {
            text: "Tjänster",
          },
        ]),
  ];

  const searchProductFilterData = [
    ...(radioValue !== 2
      ? [
          {
            key: "1",
            label: "CPC",
            children: (
              <ILSearchFilterCheckbozList
                ppcCheckbox
                name={"ppc"}
                ppcSelectedIds={paginationData?.ppcRange}
                handleChange={handleCheckboxChange}
              />
            ),
          },
          {
            key: "2",
            label: "Försäljningsprovision",
            children: (
              <div>
                <ILRangeSlider
                  name="sellCommission"
                  value={paginationData?.sellCommission}
                  handleChange={(event) =>
                    handleSelectChange(event, "sellCommission")
                  }
                />
                <div className="d-flex justify-content-between align-items-center gap-3 mt-5 mt-xxl-2">
                  <span className="paragraph-sm-text paragraph-xs-text d-block w-50">
                    0
                  </span>
                  <span className="paragraph-sm-text paragraph-xs-text d-block w-50 text-end">
                    100%
                  </span>
                </div>
              </div>
            ),
          },
          {
            key: "3",
            label: "Pris",
            children: (
              <ILSearchFilterCheckbozList
                filterPriceCheckbox
                name={"price"}
                priceRangeSelectedIds={paginationData?.priceRange}
                handleChange={handleCheckboxChange}
              />
            ),
          },
        ]
      : []),
    ...(categoryType === "service"
      ? [
          {
            key: "4",
            label: "Land",
            children: (
              <ILSearchFilterCheckbozList
                filterCountryCheckbox
                countryList={countryList}
                name={"countries"}
                countrySelectedIds={paginationData?.countries}
                handleChange={handleCheckboxChange}
              />
            ),
          },
          {
            key: "5",
            label: "Städer",
            children: (
              <ILSearchFilterCheckbozList
                filterCitiesCheckbox
                citiesList={citiesList}
                name={"cities"}
                citySelectedIds={paginationData?.cities}
                handleChange={handleCheckboxChange}
              />
            ),
          },
        ]
      : []),
    {
      key: "6",
      label: "Kategori",
      onChange: () => {},
      children: (
        <ILSearchFilterCheckbozList
          filterCategoryCheckbox
          categoryList={brandCategoryList}
          name={"category"}
          botRef={botRef}
          categorySelectedIds={paginationData?.categoryIds}
          handleOnScroll={handleOnScrollBrand}
          handleChange={handleCheckboxChange}
        />
      ),
    },
    {
      key: "7",
      label: "Företag",
      onChange: () => {},
      children: (
        <ILSearchFilterCheckbozList
          filterBrandCheckbox
          brandList={brandList?.rows}
          name={"brand"}
          botRef={botRef}
          brandSelectedIds={paginationData?.brand}
          handleOnScroll={handleOnScrollBrand}
          handleChange={handleCheckboxChange}
        />
      ),
    },
    {
      key: "8",
      label: "Produkttyp",
      children: (
        <div>
          <ILRadio
            radioList={radioList}
            handleChange={handleRadioChange}
            value={radioValue}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <ILHead title="Search filter" />
      <section className="default-inner-hero position-relative">
        <div className="container">
          <div className="d-flex align-items-center gap-3">
            <Link to="/brands">
              <ArrowLeft02Icon
                size={34}
                color={"#1D1128"}
                variant={"stroke"}
                strokeWidth={2}
              />
            </Link>
            <h4 className="fw-semibold lh-22 mb-0">Företag</h4>
          </div>
        </div>
      </section>
      <section className="section-md-pb">
        <div className="container">
          <div className={clsx(styles.searchFilterRow, "d-flex")}>
            <div
              className={clsx(
                styles.searchFilterLeftCol,
                { [styles.active]: isFilterSidebar },
                "small-track"
              )}
              ref={ref}
            >
              <span
                className={clsx(
                  "filter-icon cursor-pointer justify-content-end mb-5"
                )}
                onClick={handleSearchFilterClose}
              >
                <MultiplicationSignIcon
                  size={25}
                  color={"#1D1128"}
                  variant={"stroke"}
                  strokeWidth={1.5}
                />
              </span>
              <div
                className={clsx(
                  styles.searchFilterClearAllBox,
                  "d-flex align-items-center"
                )}
              >
                <h5 className="md-secondary-title fw-semibold text-dark-purple mb-0 big">
                  Filtrera efter
                </h5>
                <Link
                  className="text-gray fw-medium mb-0 ms-auto p big"
                  onClick={handleClearFilter}
                >
                  Rensa alla
                </Link>
              </div>
              <ILAccordion
                items={searchProductFilterData}
                defaultActiveKey="2"
                className={clsx(styles.filterAccordion, "filter-accordion")}
                expandIconPosition="start"
                iconSize="20"
              />
              {/* <ILButton
                variant={"primary-gradient"}
                isSmallSizeBtn
                ILBtnClass="min-w-auto line-height-normal font-semibold w-100"
              >
                Apply
              </ILButton> */}
            </div>
            <div
              className={clsx(
                isFilterSidebar && styles.backdropFilter,
                styles.searchFilterRightCol
              )}
            >
              <div
                className={clsx(
                  styles.searchFilterHead,
                  "w-100 d-flex align-items-center gap-4"
                )}
              >
                <span
                  onClick={handleSearchFilterOpen}
                  className={clsx(
                    styles.filterHorizontalIconBox,
                    "filter-icon cursor-pointer align-items-center justify-content-center rounded-circle highlight-background-animation"
                  )}
                >
                  <FilterHorizontalIcon
                    size={28}
                    color={"#ffffff"}
                    variant={"stroke"}
                    strokeWidth={1.5}
                  />
                </span>
                <div className="d-flex flex-column gap-3">
                  <h2 className="md-secondary-title xs-secondary-title mb-0">
                    Alla{" "}
                    {categoryType === "product"
                      ? "Produkter"
                      : categoryType === "collection"
                      ? "Kollektioner"
                      : "Tjänster"}
                  </h2>
                  {!isLoading?.getProductAndServices &&
                    categoryList?.totalItems > 0 && (
                      <h5 className="text-shark-fin fw-medium p big mb-0">
                        ({countHandler(categoryList?.totalItems)}{" "}
                        {categoryType === "product"
                          ? `Produkt${categoryList?.totalItems > 1 ? "s" : ""}`
                          : categoryType === "collection"
                          ? "Kollektioner"
                          : "Tjänster"}
                        )
                      </h5>
                    )}
                </div>
              </div>
              {/* <div
                className={clsx(
                  styles.appliedFiltersWrap,
                  "d-flex align-items-start flex-md-row flex-column gap-5"
                )}
              >
                <div className="order-md-0 order-1">
                  <span className="text-gray fw-medium p sm d-block">
                    Applied Filters
                  </span>
                  <div
                    className={clsx(
                      styles.filterLabelGrid,
                      "filter-label-grid d-flex"
                    )}
                  >
                    {filterLabelData.map((flItem, flIndex) => {
                      return (
                        <div
                          className={clsx(
                            styles.filterLabel,
                            "filter-label d-flex align-items-center justify-content-center gap-2"
                          )}
                          key={flIndex + "filterLabelIndex"}
                        >
                          <h6 className="p fw-medium text-gray mb-0">
                            {flItem.name}
                          </h6>
                          <span className="lh-0 cursor-pointer ms-auto hover-text">
                            <Cancel01Icon
                              size={14}
                              color={"#1D1128"}
                              variant={"stroke"}
                            />
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <ILSelect
                  ILSelectLabelClass="ms-md-auto min-w-auto w-auto min-h-auto mb-0"
                  label=""
                  id="countrySelect"
                  datatestid="countrySelect"
                  defaultValue="Sort by"
                  options={sfSortByData}
                />
              </div> */}

              <div
                className={clsx(styles.productFilterSearchGrid, {
                  ["d-grid"]: !(
                    (categoryType === "product" ||
                      categoryType === "service") &&
                    radioValue === 2
                  ),
                  ["d-flex flex-wrap"]: categoryType === "collection",
                })}
              >
                {isLoading?.getProductAndServices ? (
                  <div className="skeleton-card border w-100">
                    <ILSkeleton isImgTitleLabelCard2 />
                  </div>
                ) : (
                  categoryList?.rows?.map((item, index) => {
                    if (categoryType === "collection") {
                      return (
                        <ILProductGalleryCard
                          ILProductGalleryCardDataList={item}
                          classname="search-product-card h-auto"
                          isLoggedIn={isLoggedIn}
                          handleCollectionDetail={handleProductDetail}
                          sendAccessRequest={sendCollectionAccessRequest}
                          collectionData={selectedCollection}
                          showSCModal={showSCModal}
                          loginHandler={loginHandler}
                          isShopCollectionModalOpen={isShopCollectionModalOpen}
                          handleSCModalOk={handleSCModalOk}
                          handleSCModalCancel={handleSCModalCancel}
                          handleAddToShopCollection={handleAddToShopCollection}
                          pagination={{ dynamicBullets: true }}
                          grabCursor={true}
                          isRequestSentLoading={isRequestSentLoading}
                        />
                      );
                    } else {
                      return (
                        <ILProductCard
                          key={index}
                          productDataShow={item}
                          isProductLabel
                          loginHandler={loginHandler}
                          isService={categoryType === "service" ? true : false}
                          isLoggedIn={isLoggedIn}
                          productList={relatedProductList}
                          isModalLoading={isModalLoading}
                          paginationData={modalPaginationData}
                          setPaginationData={setModalPaginationData}
                          handleTableChange={handleModalTableChange}
                          sendAccessRequest={sendAccessRequest}
                          setIsAddedToShop={setIsAddedToShop}
                          handleProductDetail={handleProductDetail}
                          isRequestSentLoading={isRequestSentLoading}
                        />
                      );
                    }
                  })
                )}
              </div>
              {!isLoading?.getProductAndServices &&
                (categoryList?.hasPrevious || categoryList?.hasNext) && (
                  <ILPagination
                    defaultCurrent={paginationData?.page}
                    defaultPageSize={paginationData?.pageSize}
                    total={categoryList?.totalItems}
                    onChange={handleTableChange}
                  />
                )}
              {!isLoading?.getProductAndServices &&
                categoryList?.totalItems === 0 && (
                  <ILNoDataCard
                    isIcon
                    className="position-relative z-3"
                    title={`Inga ${
                      categoryType === "product"
                        ? "Produkter"
                        : categoryType === "collection"
                        ? "Kollektioner"
                        : "Tjänster"
                    } Hittades`}
                  />
                )}
            </div>
          </div>
        </div>
      </section>

      <ILModal
        open={isCollectionAddedModalOpen}
        onOk={() => handleCAModalOk("new")}
        onCancel={() => handleCAModalOk("new")}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <span className="lh-0">
                <img
                  src={gradientCircleRound}
                  fluid
                  alt="advertising agency | ilonsi"
                />
              </span>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Din kollektion har lagts till!
              </h4>
              <ILButton
                ILBtnClass="w-100"
                variant={"primary-gradient"}
                handleClick={myShopNavigation}
              >
                Visa i min butik
              </ILButton>
            </div>
          </>
        }
        isFooterRemove
      />

      <ILModal
        open={isCollectionAlreadyAddedModalOpen}
        onOk={() => handleCAModalOk("already")}
        onCancel={() => handleCAModalOk("already")}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <span className="lh-0">
                <img
                  src={gradientCircleRound}
                  fluid
                  alt="advertising agency | ilonsi"
                />
              </span>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Din kollektion har lagts till redan!
              </h4>
              <ILButton
                ILBtnClass="w-100"
                variant={"primary-gradient"}
                handleClick={myShopNavigation}
              >
                Visa i min butik
              </ILButton>
            </div>
          </>
        }
        isFooterRemove
      />

      <ILModal
        open={isShopCollectionModalOpen}
        onOk={handleSCModalOk}
        onCancel={handleSCModalCancel}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <div
                className="brand-logo brand-logo-big flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center mx-auto"
                style={{ borderColor: "#000000" }}
              >
                <img
                  src={selectedCollection?.brand?.profile_image}
                  height={50}
                  width={50}
                  alt="advertising agency | ilonsi"
                  className="object-fit-contain img-fluid"
                />
              </div>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Vill du lägga till den här kollektionen i din butik?
              </h4>
              <p className="paragraph-sm-text paragraph-md-text text-center mb-0">
                Denna kollektion kommer att läggas till i din butik.
              </p>
              <div className="d-flex align-items-center gap-4 modal-more-btn">
                <ILButton
                  disabled={isLoading?.collectionAdd}
                  ILBtnClass="w-50"
                  variant={"primary-gradient"}
                  onClick={handleAddToShopCollection}
                >
                  {isLoading?.collectionAdd ? (
                    <ILLoader isBtn />
                  ) : (
                    "Ja, Lägg till"
                  )}
                </ILButton>
                <ILButton
                  disabled={isLoading?.collectionAdd}
                  ILBtnClass="w-50"
                  variant={"gradient-bordered"}
                  onClick={handleSCModalCancel}
                >
                  Nej, Avbryt
                </ILButton>
              </div>
            </div>
          </>
        }
        isFooterRemove
      />

      <ILModal
        open={isSuccessfullyReqModalOpen}
        onOk={handleSRModalClose}
        onCancel={handleSRModalClose}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <span className="lh-0">
                <img
                  src={gradientCircleRound}
                  fluid
                  alt="advertising agency | ilonsi"
                />
              </span>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Förfrågan har skickats!
              </h4>
              <ILButton
                ILBtnClass="w-100"
                variant={"primary-gradient"}
                handleClick={() =>
                  navigate("/dashboard/my-profile/my-access-request")
                }
              >
                Hantera förfrågningars
              </ILButton>
            </div>
          </>
        }
        isFooterRemove
      />
    </>
  );
};
export default ILProductSearchFilter;
