import React, { useState } from "react";
import Sider from "antd/es/layout/Sider";
import { Menu } from "antd";
import { useLocation, useNavigate, Link } from "react-router-dom";
import {
  DashboardSquare02Icon,
  Store04Icon,
  Mail01Icon,
  ElectricHome01Icon,
  ApproximatelyEqualSquareIcon,
  HydroPowerIcon,
  PackageIcon,
} from "hugeicons-react";
import { ilonsiLogo } from "../../constants/imageData";
import { useMediaQuery } from "../../hooks/useMediaQuary";
import { clsx } from "clsx";
import styles from "./ILSidebar.module.css";

export const ILSider = ({ collapsed, handleSidebarCollapsed, ...rest }) => {
  // mobile sidebar js start
  const [isToggle, setIsToggle] = useState(false);
  // mobile sidebar js end
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const subMenuList = {
    main: ["/dashboard/overview", "/dashboard/my-shop", "/dashboard/messaging"],
  };
  const isIPad = useMediaQuery("(max-width: 767px)");

  let selectedsubMenu = [],
    subMenuName = [];
  for (const [subMenuTitle, subMenuItems] of Object.entries(subMenuList)) {
    const selectedMenuData = subMenuItems?.some((subItems) => {
      if (pathname.includes(subItems)) {
        subMenuName.push(subItems);
      }
      return pathname.includes(subItems);
    });
    if (selectedMenuData) {
      selectedsubMenu.push(subMenuTitle);
      break;
    }
  }

  function getItem(label, key, icon, children, type, onClick) {
    return {
      key,
      icon,
      children,
      label,
      type,
      onClick,
    };
  }

  const sidebarMenuItems = [
    getItem(
      "Dashboard",
      "/dashboard",
      <DashboardSquare02Icon
        size={20}
        className="dashboardRef"
        color={"#392306"}
        variant={"stroke"}
      />
    ),
    getItem(
      "Min butik",
      "/dashboard/my-shop",
      <Store04Icon
        className="myShopRef"
        size={20}
        color={"#392306"}
        variant={"stroke"}
      />
    ),
    getItem(
      "Förfrågningar",
      "/dashboard/messaging",
      <Mail01Icon
        className="messagingRef"
        size={20}
        color={"#392306"}
        variant={"stroke"}
      />
    ),
    getItem(
      "Tjänster",
      "/product-search-filter",
      <HydroPowerIcon
        className="messagingRef"
        size={20}
        color={"#392306"}
        variant={"stroke"}
      />,
      null,
      null,
      () => localStorage.setItem("categoryType", "service")
    ),
    getItem(
      "Produkter",
      "/product-search-filter",
      <PackageIcon
        className="messagingRef"
        size={20}
        color={"#392306"}
        variant={"stroke"}
      />,
      null,
      null,
      () => localStorage.setItem("categoryType", "product")
    ),
    getItem(
      "Kollektioner",
      "/product-search-filter",
      <ApproximatelyEqualSquareIcon
        size={20}
        color={"#392306"}
        variant={"stroke"}
      />,
      null,
      null,
      () => localStorage.setItem("categoryType", "collection")
    ),
  ];
  let selectMenuItem = [];
  let selectItem = null;

  sidebarMenuItems.forEach((item) => {
    if (
      pathname.includes(item.key) &&
      (!selectItem || item.key.length > selectItem.key.length)
    ) {
      selectItem = item;
    }
  });

  if (selectItem) {
    selectMenuItem.push(selectItem.key);
  }

  // mobile sidebar js start
  const handleToggleMenu = () => {
    setIsToggle(!isToggle);
  };
  // mobile sidebar js end

  return (
    <>
      <button
        // ref={sidebarRef}
        className={clsx(
          styles.toggleMenu,
          { [styles.active]: isToggle },
          "d-xl-none d-flex"
        )}
        onClick={handleToggleMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
      <Sider
        breakpoint="xxl"
        collapsible
        collapsed={collapsed}
        onCollapse={handleSidebarCollapsed}
        // className={styles.ILSider}
        className={clsx(styles.ILSider, {
          [clsx(styles.active, "")]: isToggle,
        })}
        theme="light"
        width={"var(--sider-width)"}
        collapsedWidth={isIPad ? 0 : 80}
        {...rest}
      >
        <div
          className={clsx(
            styles.siderLogoHead,
            "d-flex align-items-center justify-content-center"
          )}
        >
          <Link className={styles.ILSiderLogo} to={"/"}>
            <img
              src={ilonsiLogo}
              alt="advertising agency | ilonsi"
              width={"100%"}
              height={"100%"}
            />
          </Link>
        </div>
        <Menu
          mode="inline"
          items={sidebarMenuItems}
          className={clsx(styles.ILMenu, "overflow-y-auto")}
          selectedKeys={
            subMenuName.length !== 0
              ? subMenuName
              : selectMenuItem.length !== 0
              ? selectMenuItem
              : [pathname]
          }
          onSelect={({ key }) => {
            navigate(key);
          }}
        />
        <div
          className={clsx(
            styles.backToMarketPlace,
            "back-to-market-place w-100 text-center position-absolute align-items-center justify-content-center transition-smooth gap-3"
          )}
        >
          <Link to={"/"} className="lh-0">
            <ElectricHome01Icon size={20} variant={"stroke"} />
          </Link>
          <Link to={"/"} className="fw-medium gradient-text mb-0 p lh-normal">
            Till marknadsplatsen
          </Link>
        </div>
      </Sider>
    </>
  );
};
