import { ILButton, ILModal, ILLoader } from "../../components";
import { useState } from "react";

export const ILSendAccessReqModal = ({
  sendAccessRequest,
  data,
  isRequestSentLoading,
}) => {
  const [isAccessReqModalOpen, setIsAccessReqModalOpen] = useState(false);

  const showARModal = () => {
    setIsAccessReqModalOpen(true);
  };

  const handleARModalClose = () => {
    setIsAccessReqModalOpen(false);
  };

  // SUCCESSFULLY REQUET MODAL
  const showSRModal = () => {
    sendAccessRequest(data);
  };

  return (
    <>
      {/* MODAL START */}
      <ILButton
        isSmallSizeBtn
        isArrow
        ILBtnClass={"w-100 mt-5 mt-xl-4 mt-xxl-3 text-clr-pink"}
        variant={"gradient-bordered"}
        handleClick={showARModal}
      >
        Skicka förfrågan
      </ILButton>
      {/* SEND ACCESS REQUEST MODAL */}
      <ILModal
        open={isAccessReqModalOpen}
        onOk={handleARModalClose}
        onCancel={handleARModalClose}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <div
                className="brand-logo brand-logo-big flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center mx-auto"
                style={{ borderColor: "#000000" }}
              >
                <img
                  src={data?.brand?.profile_image}
                  alt="advertising agency | ilonsi"
                  className="object-fit-contain img-fluid"
                />
              </div>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Bekräfta för att skicka Samarbetsförfrågan
              </h4>
              <p className="paragraph-sm-text paragraph-md-text text-center mb-0">
                Det valda {data?.product_code ? "produkter" : "tjänster"}{" "}
                företaget kommer att granska ditt konto för att ge bekräftelse
                om åtkomst.
              </p>
              <div className="d-flex align-items-center gap-4 modal-more-btn">
                <ILButton
                  disabled={isRequestSentLoading}
                  ILBtnClass="w-50"
                  variant={"primary-gradient"}
                  onClick={showSRModal}
                >
                  {isRequestSentLoading ? <ILLoader isBtn /> : "Ja, Skicka"}
                </ILButton>
                <ILButton
                  disabled={isRequestSentLoading}
                  ILBtnClass="w-50"
                  variant={"gradient-bordered"}
                  onClick={handleARModalClose}
                >
                  Nej, Avbryt
                </ILButton>
              </div>
            </div>
          </>
        }
        isFooterRemove
      />
    </>
  );
};
