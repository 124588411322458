import { Line } from "react-chartjs-2";
import Chart, { Chart as ChartJS } from "chart.js/auto";
import clsx from "clsx";
import styles from "./ILLineChart.module.css";

const ILLineNewChart = ({ chartData, isLegendVisible, dashedAxis }) => {
  // CUSTOM TOOLTIP STARTS
  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector("div");

    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.classList.add(styles.customTooltip);

      const p = document.createElement("p");
      p.style.margin = "0px";
      p.classList.add(styles.gradientText, styles.customTooltipInnerText);
      tooltipEl.appendChild(p);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // HIDE IF NO TOOLTIP
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // SET TEXT
    if (tooltip.body) {
      const bodyLines = tooltip.body.map((b) => b.lines).flat();
      const tooltipText = bodyLines.join("\n") + " KR";
      const p = tooltipEl.querySelector("p");
      p.textContent = tooltipText;
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // DISPLAY, POSITION, AND SET STYLES FOR FONT
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + "px";
    tooltipEl.style.top = positionY + tooltip.caretY + "px";
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding =
      tooltip.options.padding + "px " + tooltip.options.padding + "px";
  };

  // CUSTOM TOOLTIP ENDS

  // ANNOTATION LINE
  const annotationLine = {
    id: "annotationLine",
    beforeDraw: (chart) => {
      if (chart?.tooltip._active && chart?.tooltip._active?.length) {
        const ctx = chart.ctx;
        const chartArea = chart?.chartArea;
        const activePoint = chart?.tooltip._active[0].element.x;

        // SAVE THE CURRENT STATE
        ctx.save();

        // CREATE A LINEAR GRADIENT
        const gradient = ctx.createLinearGradient(
          activePoint, // START X POSITION
          chartArea.top, // START Y POSITION
          activePoint, // END X POSITION
          chartArea.bottom // END Y POSITION
        );

        // Define the gradient colors and positions
        gradient.addColorStop(0, "rgba(68, 138, 213, 0)"); // START COLOR
        gradient.addColorStop(1, "rgba(219, 47, 127, 0.2)"); // END COLOR

        // DRAW THE LINE WITH THE GRADIENT STROKE STYLE
        ctx.beginPath();
        ctx.moveTo(activePoint, chartArea.top);
        ctx.lineTo(activePoint, chartArea.bottom);
        ctx.lineWidth = 40;
        ctx.strokeStyle = gradient; // USE THE GRADIENT AS STROKESTYLE
        ctx.stroke();

        // RESTORE THE PREVIOUS STATE
        ctx.restore();
      }
    },
  };

  const gradientLine = {
    id: "gradientLine",
    beforeDraw: function (chart, args, options) {
      if (gradientLine) {
        var ctx = chart.ctx;
        var dataset = chart.data.datasets[0]; // ASSUMING ONLY ONE DATASET

        // CREATE A GRADIENT THAT RUNS ALONG THE BORDER OF THE LINE
        var gradient = ctx.createLinearGradient(0, 0, chart.width, 0);
        gradient.addColorStop(0, "rgba(68, 138, 213, 1)"); // START COLOR
        gradient.addColorStop(1, "rgba(219, 47, 127, 1)"); // END COLOR

        // STORE THE ORIGINAL STROKE STYLE
        var originalStroke = dataset.borderColor;

        // OVERRIDE THE BORDERCOLOR PROPERTY WITH THE GRADIENT
        dataset.borderColor = gradient;

        // CALL THE ORIGINAL DRAW METHOD AFTER MODIFYING THE BORDERCOLOR
        chart.update();

        // RESTORE THE ORIGINAL BORDERCOLOR AFTER DRAWING
        dataset.borderColor = originalStroke;
      }
    },
  };

  const hoverLine = {
    id: "hoverLine",
    afterDatasetsDraw(chart, args, plugins) {
      const {
        ctx,
        tooltip,
        chartArea: { top, bottom, left, right, width, height },
        scales: { x, y },
      } = chart;
      if (tooltip._active.length > 0) {
        const xCoor = x.getPixelForValue(tooltip.dataPoints[0]?.dataIndex);
        const yCorr = y.getPixelForValue(tooltip.dataPoints[0]?.parsed.y);
        ctx.save();
        ctx.beginPath();
        ctx.lineWidth = 2;
        ctx.strokeStyle = "#696C7966";
        ctx.setLineDash([6, 6]);
        ctx.moveTo(xCoor, yCorr);
        ctx.lineTo(xCoor, bottom);
        ctx.stroke();
        ctx.closePath();
        ctx.setLineDash([]);
      }
    },
  };

  // SAMPLE DATASET

  const data = {
    labels: chartData?.labels,
    datasets: [
      {
        // label: "Monthly Data",
        data: chartData?.value,
        hoverBorderColor: "green",
        tension: 0,
      },
    ],
  };

  return (
    <>
      <div
        className={clsx(
          styles.lineChartWrapper,
          "w-100 h-100 position-relative"
        )}
      >
        <Line
          id="linechart"
          data={data}
          options={{
            maintainAspectRatio: false,
            hover: {
              mode: "dataset",
              intersect: true,
            },
            elements: {
              point: {
                borderWidth: 4,
                pointStyle: "circle",
                radius: 8,
                hoverRadius: 8,
                backgroundColor: "#fff",
                hoverBackgroundColor: "#fff",
                borderColor: "red",
                hoverBorderColor: "#fff",
                hoverBorderWidth: 4,
              },
            },
            responsive: true,
            scales: {
              x: {
                grid: {
                  display: false,
                },
                beginAtZero: true,
                border: dashedAxis && {
                  dash: [10, 4],
                },
                ticks: {
                  font: {
                    size: 14,
                    weight: 400,
                  },
                },
              },
              y: {
                beginAtZero: true,
                border: dashedAxis && {
                  dash: [10, 4],
                },
                ticks: {
                  maxTicksLimit: 5,
                  font: {
                    size: 14,
                    weight: 14,
                  },
                },
              },
            },
            plugins: {
              tooltip: {
                enabled: false,
                position: "nearest",
                external: externalTooltipHandler,
              },
              legend: {
                display: isLegendVisible,
                position: "top",
              },
              title: {
                display: false,
                text: "Product Sales Over Five Months",
              },
            },
          }}
          plugins={[gradientLine, hoverLine, annotationLine]}
        />
      </div>
    </>
  );
};

export default ILLineNewChart;
