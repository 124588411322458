import { Col, Row } from "antd";
import {
  ILButton,
  ILDashboardCard,
  ILTable,
  ILDashboardHeader,
  ILHead,
  ILLoader,
  ILModal,
  ILTextInput,
  ILSkeleton,
} from "../../../components";
import { AlertCircleIcon, Wallet03Icon } from "hugeicons-react";
import { gigaPay } from "../../../constants/imageData";
import clsx from "clsx";
import styles from "./ILMyPayment.module.css";
import { usePaymentMethod } from "../../../hooks/dashboard/profile/paymentMethod.hook";

const ILMyPayment = () => {
  const {
    isLoading,
    withdrawAmount,
    paginationData,
    earningDetails,
    isPayHistoryLoading,
    payoutHistoryColumns,
    payoutHistoryDetails,
    isWithdrawAmountLoading,
    isWithdrawMoneyModalOpen,
    handleTableChange,
    handleInputChange,
    fetchGigapayWithdraw,
    handleWithdrawMoneyModal,
  } = usePaymentMethod();

  return (
    <>
      <ILHead title="Mina Utbetalningar" />
      <ILDashboardHeader
        extra={true}
        title="Mina Utbetalningar"
        breadCrumbItems={[
          { title: "Hem", href: "/dashboard" },
          { title: "Mina Utbetalningar" },
        ]}
      />
      <section>
        <div>
          <Row
            gutter={[
              { xs: 20, sm: 40 },
              { xs: 20, sm: 40 },
            ]}
          >
            <Col xs={24} md={18} xl={11} sm={20}>
              <div>
                <ILDashboardCard
                  title={"Mitt betalningskonto"}
                  extra={
                    <>
                      <AlertCircleIcon
                        size={20}
                        color={"#000000"}
                        variant={"stroke"}
                      />
                    </>
                  }
                >
                  <div>
                    <div className={styles.connectAccountCard}>
                      <div
                        className={
                          "d-flex align-items-center justify-content-between gap-3 mb-3"
                        }
                      >
                        <div className={styles.paymentMethodImg}>
                          <img
                            src={gigaPay}
                            alt="advertising agency | ilonsi"
                            className="w-100 h-100"
                          />
                        </div>
                        <ILButton
                          variant={"primary-gradient"}
                          isArrow
                          isSmallSizeBtn
                          handleClick={handleWithdrawMoneyModal}
                        >
                          Uttagsbegäran
                        </ILButton>
                      </div>
                      <h6 className="p fw-semibold">Anslut till Gigapay</h6>
                      <p className="paragraph-sm-text paragraph-xs-text mb-0">
                        Vid uttag via ILONSI sker betalningarna genom Giapay.co,
                        som sköter skatter och gör utbetalningar via Swish eller
                        direkt till ditt bankkonto.
                      </p>
                    </div>
                    {isLoading ? (
                      <ILSkeleton isNormalParagraph width="100px" />
                    ) : (
                      <div
                        className={clsx(
                          styles.earningWrap,
                          "d-flex align-items-sm-center flex-sm-row flex-column"
                        )}
                      >
                        <div
                          className={clsx(
                            styles.earningCard,
                            "position-relative"
                          )}
                        >
                          <span className="h5 big fw-semibold gradient-text">
                            {earningDetails?.total_earnings?.toFixed(2)} KR
                          </span>
                          <span className="paragraph-sm-text fw-medium d-block mt-2">
                            Totala intäkter
                          </span>
                        </div>
                        <div
                          className={clsx(
                            styles.earningCard,
                            "position-relative"
                          )}
                        >
                          <span className="h5 big fw-semibold gradient-text">
                            {(
                              earningDetails?.total_earnings -
                              earningDetails?.available_withdraw
                            )?.toFixed(2)}{" "}
                            KR
                          </span>
                          <span className="paragraph-sm-text fw-medium d-block mt-2">
                            Uttaget belopp
                          </span>
                        </div>
                        <div
                          className={clsx(
                            styles.earningCard,
                            "position-relative"
                          )}
                        >
                          <span className="h5 big fw-semibold gradient-text">
                            {earningDetails?.available_withdraw?.toFixed(2)} KR
                          </span>
                          <span className="paragraph-sm-text fw-medium d-block mt-2">
                            Tillgängligt för uttag
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </ILDashboardCard>
              </div>
            </Col>
            <Col xs={24}>
              <ILDashboardCard
                title={"Min Uttagshistorik"}
                subTitle={"Översikt över totala intäkter"}
              >
                <ILTable
                  columns={payoutHistoryColumns}
                  data={payoutHistoryDetails?.rows}
                  scroll={{ x: "max-content" }}
                  isLoading={isPayHistoryLoading}
                  pagination={true}
                  current={paginationData?.page}
                  pageSize={paginationData?.pageSize}
                  total={payoutHistoryDetails?.totalItems}
                  handleTableChange={handleTableChange}
                />
              </ILDashboardCard>
            </Col>
          </Row>
        </div>
      </section>

      <ILModal
        open={isWithdrawMoneyModalOpen}
        onOk={handleWithdrawMoneyModal}
        onCancel={handleWithdrawMoneyModal}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="518px"
        isCloseIconRemove={isWithdrawAmountLoading}
        children={
          <>
            <div className="text-center d-flex flex-column justify-content-center modal-content-mh-inherit gap-5 gap-xxl-4">
              {earningDetails?.available_withdraw >= 500 ? (
                <>
                  <div
                    className="brand-logo brand-logo-big flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center mx-auto"
                    style={{ borderColor: "#ED4F32", borderWidth: "3px" }}
                  >
                    <Wallet03Icon
                      size={34}
                      color={"#ED4F32"}
                      variant={"stroke"}
                      strokeWidth={2}
                    />
                  </div>
                  <div>
                    <h5 className="text-dark-purple fw-semibold text-center mb-0 lh-base px-0 px-sm-5">
                      Ange beloppet du vill ta ut
                    </h5>
                  </div>
                  <>
                    <div>
                      <ILTextInput
                        ILInputLabelClass="mb-0"
                        id="price"
                        datatestid="price"
                        type={"number"}
                        isRequiredInput
                        placeholder={"Enter Amount"}
                        label={"Ange belopp (KR)"}
                        name="withdrawAmount"
                        value={withdrawAmount}
                        handleChange={handleInputChange}
                        errorMsg={"Minsta uttagsbelopp är 500"}
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-center gap-4 mt-4">
                      <ILButton
                        disabled={
                          withdrawAmount === "" ||
                          isWithdrawAmountLoading ||
                          withdrawAmount < 500
                            ? true
                            : false
                        }
                        ILBtnClass="w-50"
                        variant={"primary-gradient"}
                        onClick={fetchGigapayWithdraw}
                      >
                        {isWithdrawAmountLoading ? (
                          <ILLoader isBtn />
                        ) : (
                          "Skicka in"
                        )}
                      </ILButton>
                    </div>
                  </>
                </>
              ) : (
                <>
                  <div
                    className="brand-logo brand-logo-big flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center mx-auto"
                    style={{ borderColor: "#db2f7f", borderWidth: "3px" }}
                  >
                    <Wallet03Icon
                      size={34}
                      color={"#db2f7f"}
                      variant={"stroke"}
                      strokeWidth={2}
                    />
                  </div>
                  <div>
                    <h5 className="text-dark-purple fw-semibold text-center mb-0 lh-base px-0 px-sm-5">
                      För att ta ut pengar måste ditt konto ha ett saldo på
                      minst 500 KR
                    </h5>
                  </div>
                </>
              )}
            </div>
          </>
        }
        isFooterRemove
      />
    </>
  );
};

export default ILMyPayment;
