import { axiosApi } from "../../axios";

// export const doFetchBrandDetails = async (
//   pageSize = 10,
//   currentPage = 1,
//   type = null,
//   categoryId = "",
//   search = null,
//   brandId = "",
//   isPaginate = false
// ) => {
//   try {
//     const response = await axiosApi({
//       method: "post",
//       url: `v1/brand/find-all?currentPage=${currentPage}&pageSize=${pageSize}&type=${type}&search=${search}&categoryId=${categoryId}&brandId=${brandId}&isPaginate=${isPaginate}`,
//     });
//     return response;
//   } catch (error) {
//     return error.response;
//   }
// };

export const doFetchBrandDetails = async (data) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: "v1/brand/find-all",
      data: data,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchProductSingleCategory = async (id) => {
  try {
    const response = await axiosApi({
      method: "get",
      url: `v1/product-category/${id}/details`,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchServiceSingleCategory = async (id) => {
  try {
    const response = await axiosApi({
      method: "get",
      url: `v1/service-category/${id}/details`,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchSingleBrandDetails = async (id) => {
  try {
    const response = await axiosApi({
      method: "get",
      url: `v1/brand/${id}/details`,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchBrandProducts = async (data = {}) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: "v1/brand/product/view-all",
      data: data,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchBrandServices = async (data) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: "v1/brand/service/view-all",
      data: data,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchBrandCollections = async (data) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: "v1/brand/collection/view-all",
      data: data,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchSingleProductDetails = async (productId, userId = "") => {
  try {
    const response = await axiosApi({
      method: "get",
      url: `v1/brand/product/${productId}?userId=${userId}`,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchSingleServiceDetails = async (serviceId, userId = "") => {
  try {
    const response = await axiosApi({
      method: "get",
      url: `v1/brand/service/${serviceId}?userId=${userId}`,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchSendAccessRequest = async (data) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: "v1/influencer/request/send",
      data: data,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchUserDetails = async () => {
  try {
    const response = await axiosApi({
      method: "get",
      url: "v1/user/details",
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchAddCollection = async (data) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: "v1/influencer/collection/add",
      data: data,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchAddProduct = async (data) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: "v1/influencer/product/add",
      data: data,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchAddService = async (data) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: "v1/influencer/service/add",
      data: data,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchCollectionProductsList = async (
  collectionId = "",
  currentPage = 1,
  pageSize = 10,
  search = null
) => {
  try {
    const response = await axiosApi({
      method: "get",
      url: `v1/influencer/shop/collection/${collectionId}/product/list?currentPage=${currentPage}&pageSize=${pageSize}&search=${search}`,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchCollectionProductList = async (
  collectionId = "",
  currentPage = 1,
  pageSize = 10,
  sortByKey = "",
  sortByType = "",
  userId = null
) => {
  try {
    const response = await axiosApi({
      method: "get",
      url: `v1/brand/collection/${collectionId}/product/view-all?currentPage=${currentPage}&pageSize=${pageSize}&sortByKey=${sortByKey}&sortByType=${sortByType}&userId=${userId}`,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchSingleCollection = async (shortCode) => {
  try {
    const response = await axiosApi({
      method: "get",
      url: `v1/influencer/shop/collection/${shortCode}/details`,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchCollection = async (id) => {
  try {
    const response = await axiosApi({
      method: "get",
      url: `v1/influencer/collection/${id}/details`,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchAddProductsToCollection = async (data) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: "v1/influencer/collection/add/product",
      data: data,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchAllCollections = async () => {
  try {
    const response = await axiosApi({
      method: "get",
      url: "v1/influencer/collection/list",
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchViewAllCollections = async (data) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: "v1/brand/collection/view-all",
      data: data,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchViewAllServices = async (data) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: "v1/brand/service/view-all",
      data: data,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};
