import React from "react";
import { DatePicker, ConfigProvider } from "antd";
import { clsx } from "clsx";
import dayjs from "dayjs"; // Swedish locale
import "dayjs/locale/sv"; // Import Swedish locale for dayjs
import locale from "antd/locale/sv_SE"; // Import Swedish locale for Ant Design
import styles from "./../ILTextInput/ILTextInput.module.css";

// Set dayjs to use the Swedish locale
dayjs.locale("sv");

export const ILRangePicker = ({
  ILInputLabelClass,
  label,
  isRequiredInput,
  id,
  name,
  datatestid,
  value,
  ILTextInputClass,
  handleChange,
  showTime,
  picker,
  disabled,
  errorMsg,
  defaultValue,
  ...rest
}) => {
  return (
    <ConfigProvider locale={locale}>
      <label
        className={clsx(styles.ILInputLabel, ILInputLabelClass, "w-100")}
        htmlFor={id}
      >
        {label && (
          <span
            className={clsx(
              styles.ILInputLabelText,
              "fw-medium text-dark-purple d-block"
            )}
          >
            {label}
            {isRequiredInput && <span className="text-tomato">*</span>}
          </span>
        )}
        <DatePicker
          id={id}
          datatestid={datatestid}
          value={value}
          name={name}
          className={clsx([styles.ILTextInput, ILTextInputClass, "w-100"])}
          onChange={handleChange}
          showTime={showTime}
          picker={picker}
          disabled={disabled}
          format="YYYY-MM-DD"
          minDate={dayjs().add(1, "day")}
          {...rest}
        />
        {errorMsg && (
          <span className="p sm mt-3 lh-1 text-tomato fw-medium d-block">
            {errorMsg}
          </span>
        )}
      </label>
    </ConfigProvider>
  );
};
