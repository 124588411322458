import React from "react";
import styles from "./ILQualityCard.module.css";

export const ILQualityCard = ({ ourStoryCardList }) => {
  return (
    <>
      {ourStoryCardList &&
        ourStoryCardList.map((item, index) => {
          return (
            <div key={index} className={styles.ourStoryCardBg}>
              <div className={styles.ourStoryCard}>
                <div className={styles.ourStoryIconBg}>{item.icon}</div>
                <h5 className="fw-medium mb-0">{item.title}</h5>
                {item.description && (
                  <p className="mb-0 mt-3 text-gray text-ellipsis line-clamp-2">
                    {item.description}
                  </p>
                )}
              </div>
            </div>
          );
        })}
    </>
  );
};
