import React from "react";
import { ConfigProvider, Table } from "antd";
import styles from "./ILTable.module.css";

export const ILTable = ({
  columns,
  data,
  scroll,
  current,
  pageSize,
  total,
  isLoading,
  pagination = false,
  handleTableChange,
}) => {
  const customLocale = {
    items_per_page: "/ Sida",
  };
  return (
    <ConfigProvider
      renderEmpty={() => (
        <span className="text-gray fw-normal">Ingen data</span>
      )}
    >
      <Table
        className={styles.ILTable}
        columns={columns}
        dataSource={data}
        scroll={scroll}
        loading={isLoading}
        onChange={handleTableChange}
        pagination={
          pagination
            ? {
                position: ["bottomRight"],
                current: current,
                defaultPageSize: 10,
                // itemRender: itemRender,
                pageSizeOptions: [10, 20, 50, 100],
                pageSize: pageSize,
                total: total,
                showSizeChanger: true,
                locale: customLocale,
              }
            : false
        }
      />
    </ConfigProvider>
  );
};
