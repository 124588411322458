import { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { capitalize } from "lodash";
import { toast } from "react-toastify";
import { getDecryptionString, isEmpty } from "../../../services";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  doFetchCollection,
  doFetchCollectionProducts,
  doFetchDeleteCollection,
} from "../../../actions";
import copy from "copy-to-clipboard";
import { appConfig } from "../../../config";

export const useCollectionProductViewAllListHook = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [productListData, setProductListData] = useState({});
  const [collectionDetails, setCollectionDetails] = useState({});
  const [paginationData, setPaginationData] = useState({
    search: "",
    pageSize: 5,
    page: 1,
  });
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isSuccessfullyDeleteModalOpen, setIsSuccessfullyDeleteModalOpen] =
    useState(false);
  const [selectedSocialMedia, setSelectedSocialMedia] = useState({});
  const [isShareSMModalOpen, setIsShareSMModalOpen] = useState(false);

  useEffect(() => {
    fetchCollectionDetails();
  }, []);

  useEffect(() => {
    fetchAllCollectionProductList(paginationData);
  }, [paginationData?.page, paginationData?.pageSize]);

  const showShareSMModal = () => {
    setIsShareSMModalOpen(true);
  };

  const handleShareSMModalOk = () => {
    setIsShareSMModalOpen(false);
    setSelectedSocialMedia({});
  };

  const shareSocialMediaHandler = () => {
    setIsShareSMModalOpen(false);
    generateSharableLink();
  };

  const handleConfirmModal = () => {
    setIsConfirmModalOpen(!isConfirmModalOpen);
  };

  const handleSuccessfullyDeleteModalClose = () => {
    setIsSuccessfullyDeleteModalOpen(false);
  };

  const handleCopyLink = (data) => {
    copy(`${appConfig.APP_URL}collection/${data?.short_code}`);
    toast.success("Länk kopierad");
  };

  const handleTableChange = (event, filter, sort) => {
    try {
      setPaginationData((prev) => ({
        ...prev,
        page: event?.current,
        pageSize: event?.pageSize,
      }));
    } catch (error) {
      console.error("Error occurred during pagination:", error);
    }
  };

  const productColumns = useMemo(() => {
    return [
      {
        title: "Produkter",
        dataIndex: "products",
        key: "products",
        render: (_, item) => (
          <div className="d-flex align-items-center gap-4">
            <div
              className={clsx(
                "ratio ratio-1x1 product-service-img flex-shrink-0 bg-clr-black-gray"
              )}
            >
              <Link
                onClick={() =>
                  navigate(
                    `/dashboard/my-shop/product-details/${item?.product?.id}`
                  )
                }
              >
                <img
                  className={"object-fit-cover h-100 w-100"}
                  src={item?.product?.image}
                  alt={item?.product?.name}
                />
              </Link>
            </div>
            <div
              onClick={() =>
                navigate(
                  `/dashboard/my-shop/product-details/${item?.product?.id}`
                )
              }
            >
              <Link className="text-black cursor-pointer">
                <p className="fw-medium mb-0">
                  <Link className="text-dark-purple text-ellipsis line-clamp-1 word-break-word">
                    {capitalize(item?.product?.name)}
                  </Link>
                </p>
                <p className="mb-0 text-gray text-ellipsis line-clamp-1 word-break-word">
                  {item?.product?.description !== ("" || null || "null") &&
                    capitalize(item?.product?.description)}
                </p>
                <p className="fw-medium mb-0 gradient-text">
                  {item?.product?.price} KR
                </p>
              </Link>
            </div>
          </div>
        ),
        width: "300px",
      },
      {
        title: "Företag",
        dataIndex: "brand",
        key: "brand",
        render: (_, item) => (
          <>
            <div className="d-flex align-items-center gap-3">
              <div className="table-logo-img flex-shrink-0 ratio ratio-1x1">
                <img
                  width={"100%"}
                  height={"100%"}
                  className={"object-fit-cover radius-inherit"}
                  src={item?.product?.brand?.profile_image}
                  alt={item?.product?.brand?.name}
                />
              </div>
              <p className="fw-medium mb-0">{item?.product?.brand?.name}</p>
            </div>
          </>
        ),
        width: "350px",
      },
      {
        title: "Klicks",
        dataIndex: "clicks",
        key: "clicks",
        width: "120px",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {item?.product?.clicks}
          </p>
        ),
      },
      {
        title: "CPC",
        dataIndex: "ppc",
        key: "ppc",
        width: "120px",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {item?.product?.ppc_price} KR
          </p>
        ),
      },
      {
        title: "Försäljning",
        dataIndex: "sell",
        key: "sell",
        width: "120px",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {item?.product?.sells}
          </p>
        ),
      },
      {
        title: "Provision %",
        dataIndex: "sale",
        key: "sale",
        width: "120px",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {isEmpty(item?.product?.sell_commission)
              ? "-"
              : item?.product?.sell_commission + " %"}
          </p>
        ),
      },
    ];
  }, [productListData?.rows]);

  const fetchAllCollectionProductList = async (data) => {
    try {
      setIsLoading(true);

      const productListResponse = await doFetchCollectionProducts(
        params?.id,
        data?.page,
        data?.pageSize,
        data?.search
      );

      if (productListResponse?.status === 200) {
        const decryptedProductList = await JSON.parse(
          getDecryptionString(productListResponse?.data)
        );
        setProductListData(decryptedProductList);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(
        "Error occurred during fetching all collection products:",
        error
      );
    }
  };

  const fetchCollectionDetails = async () => {
    try {
      setIsLoading(true);

      const collectionDetailsResponse = await doFetchCollection(params?.id);

      if (collectionDetailsResponse?.status === 200) {
        const decryptedCollectionDetails = await JSON.parse(
          getDecryptionString(collectionDetailsResponse?.data)
        );
        setCollectionDetails(decryptedCollectionDetails);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(
        "Error occurred during fetching all collection details:",
        error
      );
    }
  };

  const handleDeleteSubmit = async () => {
    try {
      setIsLoading(true);
      const deleteResponse = await doFetchDeleteCollection(params?.id);
      if (deleteResponse?.status === 200) {
        setIsConfirmModalOpen(false);
        setIsSuccessfullyDeleteModalOpen(true);
        toast.success(deleteResponse?.message);
        navigate("/dashboard/my-shop");
      } else {
        toast.error(deleteResponse?.data?.message);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.error("Error occurred during deleting collection:", error);
    }
  };

  const handleSelectSocialMedia = (data) => {
    setSelectedSocialMedia(data);
  };

  const generateSharableLink = () => {
    window.open(
      `${selectedSocialMedia?.link}${appConfig.APP_URL}collection/${collectionDetails?.short_code}`,
      "_blank"
    );
    setSelectedSocialMedia({});
    setIsShareSMModalOpen(false);
  };

  return {
    isLoading,
    paginationData,
    productColumns,
    productListData,
    collectionDetails,
    isConfirmModalOpen,
    isShareSMModalOpen,
    selectedSocialMedia,
    isSuccessfullyDeleteModalOpen,
    handleCopyLink,
    showShareSMModal,
    handleTableChange,
    handleDeleteSubmit,
    handleConfirmModal,
    generateSharableLink,
    handleShareSMModalOk,
    handleSelectSocialMedia,
    shareSocialMediaHandler,
    handleSuccessfullyDeleteModalClose,
  };
};
