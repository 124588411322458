import React from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import styles from "./AuthenticationLayout.module.css";
import { ILScrollToTop } from "../../components";

export const AuthenticationLayout = () => {
  return (
    <>
      <Layout className={styles.ILAuthenticationLayout}>
        <ILScrollToTop />
        <main>
          <Outlet />
        </main>
      </Layout>
    </>
  );
};
