export const ILCommunityGuidelines = () => {
  return (
    <div className="d-flex flex-wrap gap-5">
      <div className="d-flex flex-column gap-3">
        <h6 className="mb-0 fw-medium">ILONSI Gemenskapsriktlinjer</h6>
        <p className="paragraph-sm-text mb-0">
          Vi på ILONSI strävar efter att skapa en säker, respektfull och positiv
          miljö för alla våra användare. För att upprätthålla detta, ber vi dig
          att följa våra gemenskapsriktlinjer. Dessa riktlinjer gäller alla
          användare av plattformen och är avsedda att säkerställa en bra
          upplevelse för alla.
        </p>
      </div>
      <div className="d-flex flex-column gap-3">
        <h6 className="mb-0 fw-medium">1. Respekt och vänlighet</h6>
        <p className="paragraph-sm-text mb-0">
          Var vänlig och respektfull mot andra användare. Diskriminering,
          trakasserier eller hot är inte tillåtet.
        </p>
      </div>
      <div className="d-flex flex-column gap-3">
        <h6 className="mb-0 fw-medium">2. Inga olagliga aktiviteter</h6>
        <p className="paragraph-sm-text mb-0">
          Användare får inte använda ILONSI för att delta i eller främja
          olagliga aktiviteter. Detta inkluderar bedrägeri, identitetsstöld och
          spridning av skadlig programvara.
        </p>
      </div>
      <div className="d-flex flex-column gap-3">
        <h6 className="mb-0 fw-medium">3. Inget spam eller oönskad reklam</h6>
        <p className="paragraph-sm-text mb-0">
          Spam, oönskad reklam eller marknadsföring som inte är relaterad till
          plattformens syfte är förbjudet.
        </p>
      </div>
      <div className="d-flex flex-column gap-3">
        <h6 className="mb-0 fw-medium">4. Ingen vuxeninnehåll</h6>
        <p className="paragraph-sm-text mb-0">
          Vänligen undvik att publicera eller dela innehåll som är olämpligt för
          en bred publik, inklusive vuxeninnehåll, nedsättande bilder eller
          språk.
        </p>
      </div>
      <div className="d-flex flex-column gap-3">
        <h6 className="mb-0 fw-medium">
          5. Ingen skadlig eller vilseledande information
        </h6>
        <p className="paragraph-sm-text mb-0">
          Det är inte tillåtet att publicera vilseledande eller falsk
          information. Var noga med att du delar verifierad och korrekt
          information.
        </p>
      </div>
      <div className="d-flex flex-column gap-3">
        <h6 className="mb-0 fw-medium">
          6. Skydda andra användares integritet
        </h6>
        <p className="paragraph-sm-text mb-0">
          Dela inte personlig information om andra utan deras tillstånd. Detta
          inkluderar, men är inte begränsat till, adress, telefonnummer eller
          annan känslig information.
        </p>
      </div>
      <div className="d-flex flex-column gap-3">
        <h6 className="mb-0 fw-medium">7. Säkerhet</h6>
        <p className="paragraph-sm-text mb-0">
          Användare får inte uppmuntra till eller delta i aktiviteter som kan
          orsaka fysisk skada eller andra säkerhetsrisker för någon.
        </p>
      </div>
      <div className="d-flex flex-column gap-3">
        <h6 className="mb-0 fw-medium">8. Inget hat eller våld</h6>
        <p className="paragraph-sm-text mb-0">
          Hate speech eller uppmaningar till våld är strikt förbjudet. Vi är en
          plattform som uppmuntrar till öppenhet och samarbete, inte till
          konflikt eller hat.
        </p>
        <p className="paragraph-sm-text mb-0">
          Genom att använda ILONSI samtycker du till att följa dessa riktlinjer
          och agera på ett sätt som främjar en säker och positiv gemenskap för
          alla. Vid brott mot dessa riktlinjer kan användarkonton bli åsidosatta
          eller avstängda.
        </p>
      </div>
      <div className="d-flex flex-column gap-3">
        <h6 className="mb-0 fw-semibold">
          Tack för att du är en del av ILONSI Familjen!
        </h6>
      </div>
    </div>
  );
};
