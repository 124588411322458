import Cropper from "react-easy-crop";
import styles from "./ILImageCrop.module.css";
import { ILModal } from "../ILModal";
import { useCallback, useState } from "react";
import { GetCroppedImg } from "./canvasPreview";
import { clsx } from "clsx";
import { Slider } from "antd";

export const ILImageCrop = () => {
  // Modal control
  const [isModalOpen, setIsCompanyLogoModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);

  // Cropper state
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [rotation, setRotation] = useState(0);

  // Modal handlers
  const showCompanyLogoModal = () => setIsCompanyLogoModalOpen(true);
  const handleCompanyLogoCancel = () => {
    setIsCompanyLogoModalOpen(false);
    setImageSrc(null); // Reset image when modal is canceled
  };

  // On crop complete handler
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // Handle file change
  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setImageSrc(reader.result); // Set the new image
        setIsCompanyLogoModalOpen(true); // Open the modal
      };

      reader.readAsDataURL(file);

      // Reset file input value to allow the same file selection
      e.target.value = null; // Ensures the `onChange` event triggers even for the same file
    }
  };

  // Crop and save image
  const onCropImage = async () => {
    try {
      const cropped = await GetCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(cropped);
      setIsCompanyLogoModalOpen(false); // Close modal
    } catch (error) {
      console.error("Crop error: ", error);
    }
  };

  const removeCropImage = () => setCroppedImage(null);

  return (
    <>
      {/* File input */}
      <label className="fw-semibold h6 cursor-pointer mb-4">
        Click me
        <input
          type="file"
          accept="image/*"
          onChange={onFileChange}
          className="d-none"
        />
      </label>

      {/* Display cropped image */}
      <div className="d-flex flex-wrap gap-3">
        {croppedImage && (
          <div
            className={clsx(
              styles.cropImgView,
              "position-relative d-flex align-items-center justify-content-center mt-2"
            )}
          >
            <img
              src={croppedImage}
              alt="Cropped"
              className={clsx("object-contain w-100 h-100")}
            />
          </div>
        )}
      </div>

      {/* Cropper Modal */}
      <ILModal
        open={isModalOpen}
        onCancel={handleCompanyLogoCancel}
        title={"Crop Image"}
        centered={true}
        width="534px"
        isFooterRemove
        maskClosable={false}
      >
        <>
          <div style={{ width: "100%", height: "400px", position: "relative" }}>
            {imageSrc && (
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                aspect={400 / 400} // Adjust aspect ratio as needed
                cropSize={{ width: 400, height: 400 }}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            )}
          </div>

          {/* Rotation Slider */}
          <div className="mt-4">
            <h6>Zoom</h6>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(value) => setZoom(value)}
            />
          </div>
          <div className="mt-4">
            <h6>Rotation</h6>
            <Slider
              value={rotation}
              min={0}
              max={360}
              step={1}
              onChange={(value) => setRotation(value)}
            />
          </div>

          {/* Modal actions */}
          <div className="mt-5 d-flex align-items-center justify-content-center gap-3">
            <div
              className="w-50 text-center cursor-pointer h6 mb-0 hover-text"
              onClick={onCropImage}
            >
              Crop & Save
            </div>
            <div
              className="w-50 text-center cursor-pointer h6 mb-0 hover-text"
              onClick={handleCompanyLogoCancel}
            >
              Cancel
            </div>
          </div>
        </>
      </ILModal>
    </>
  );
};
