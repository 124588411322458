import { emailRegex } from "../../regexs";

export const validateSubscribeEmail = (name, value, validateMessages) => {
  let errors = { ...validateMessages };
  switch (name) {
    case "email":
      if (
        value === undefined ||
        value?.trim() === null ||
        value?.trim() === ""
      ) {
        errors.email = "E-postadress krävs";
      } else if (!emailRegex.test(value)) {
        errors.email = "Ogiltig e-postadress";
      } else {
        delete errors.email;
      }
      break;

    default:
      break;
  }

  return { errors };
};

export const validateSubmitSubscribeEmail = (data) => {
  let errors = {};

  if (data.email === undefined || data.email === null || data.email === "") {
    errors.email = "E-postadress krävs";
  }

  if (data.email && !emailRegex.test(data.email)) {
    errors.email = "Ogiltig e-postadress";
  }

  return { errors };
};
