import { Popover } from "antd"
import clsx from "clsx"
import styles from "./ILPopover.module.css";

export const ILPopover = ({
  title,
  content,
  placement,
  customClassname,
  children,
  arrow = false,
}) => {
  return (
    <>
      <Popover
        content={content}
        title={title}
        overlayClassName={clsx(styles.ILPopover, customClassname)}
        placement={placement ? placement : "bottomRight"}
        arrow={arrow}
      >
        {children}
      </Popover>
    </>
  )
}