import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  validateResetPassword,
  validateSubmitResetPassword,
} from "../../../validations";
import { doFetchDeleteAccount, doFetchResetPassword } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { storeAuthUserDetails } from "../../../reducers/authenticate.reducer";

export const useInfluencerSettingsHook = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [validateMessages, setValidateMessages] = useState({});
  const [resetPasswordData, setResetPasswordData] = useState({});
  const [passwordShowIcon, setPasswordShowIcon] = useState({
    newPasswordShowIcon: false,
    confirmPasswordShowIcon: false,
  });
  const [isResetPwModalOpen, setIsResetPwModalOpen] = useState(false);
  const [isPrivacyPolicyModalOpen, setIsPrivacyPolicyModalOpen] =
    useState(false);
  const [isConfirmResetPwModalOpen, setIsConfirmResetPwModalOpen] =
    useState(false);
  const [isAccountDeleteModal, setIsAccountDeleteModal] = useState(false);
  const { userDetails } = useSelector(
    (state) => state.storeAuthenticateReducer
  );

  const handleDeleteAccountCancel = () => {
    setIsAccountDeleteModal(!isAccountDeleteModal);
  };

  const privacyPolicyModal = () => {
    setIsPrivacyPolicyModalOpen(!isPrivacyPolicyModalOpen);
  };

  const showResetPwModal = () => {
    setIsResetPwModalOpen(true);
  };

  const handleResetPwModal = () => {
    setIsResetPwModalOpen(false);
    setValidateMessages({});
    setResetPasswordData({});
  };

  const handleConfirmResetPwModal = () => {
    setIsConfirmResetPwModalOpen(false);
  };

  const handlePasswordShowIcon = (name = "") => {
    if (name === "newPassword") {
      setPasswordShowIcon((prevState) => ({
        ...prevState,
        newPasswordShowIcon: !prevState?.newPasswordShowIcon,
      }));
    } else {
      setPasswordShowIcon((prevState) => ({
        ...prevState,
        confirmPasswordShowIcon: !prevState?.confirmPasswordShowIcon,
      }));
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event?.target;
    const { errors } = validateResetPassword(
      name,
      value,
      validateMessages,
      resetPasswordData?.password
    );
    setValidateMessages(errors);
    setResetPasswordData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleResetPasswordSubmit = () => {
    try {
      const { errors } = validateSubmitResetPassword(resetPasswordData);
      setValidateMessages((pre) => ({
        ...pre,
        ...errors,
      }));

      if (Object.keys(errors).length === 0) {
        resetPasswordCallback();
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const logoutHandler = () => {
    navigate("/login");
    dispatch(storeAuthUserDetails({}));
    localStorage.removeItem("_token");
  };

  const resetPasswordCallback = async () => {
    try {
      setIsLoading(true);

      const requestBody = {
        email: userDetails?.userData?.email,
        password: resetPasswordData?.password,
      };

      const resetPasswordResponse = await doFetchResetPassword(requestBody);

      if (resetPasswordResponse?.status === 200) {
        setValidateMessages({});
        setIsConfirmResetPwModalOpen(true);
        setIsResetPwModalOpen(false);
      } else {
        toast.error(resetPasswordResponse?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      setIsDeleteLoading(true);
      const deleteAccountResponse = await doFetchDeleteAccount();

      if (deleteAccountResponse?.status === 200) {
        navigate("/login");
        localStorage.clear();
        toast?.success("konto raderades framgångsrikt!");
      } else {
        toast.error(deleteAccountResponse?.message);
      }
      setIsDeleteLoading(false);
    } catch (error) {
      console.log({ error });
      setIsDeleteLoading(false);
    }
  };

  return {
    params,
    isLoading,
    userDetails,
    isDeleteLoading,
    passwordShowIcon,
    validateMessages,
    resetPasswordData,
    isResetPwModalOpen,
    isAccountDeleteModal,
    isPrivacyPolicyModalOpen,
    isConfirmResetPwModalOpen,
    logoutHandler,
    showResetPwModal,
    handleInputChange,
    handleResetPwModal,
    handleDeleteAccount,
    privacyPolicyModal,
    handlePasswordShowIcon,
    handleDeleteAccountCancel,
    handleConfirmResetPwModal,
    handleResetPasswordSubmit,
  };
};
