import clsx from "clsx";
import { ColorRing, RotatingLines, ThreeDots } from "react-loader-spinner";

export const ILLoader = ({
  color = "#fff",
  size = "80",
  classname,
  isBtn = false,
  isLoaderBgOpacitySm,
  isChatLoader,
}) => {
  return (
    <>
      {isBtn ? (
        <ThreeDots
          visible={true}
          height="40"
          width="40"
          color="#fff"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      ) : isChatLoader ? (
        <>
          <ColorRing
            visible={true}
            height="40"
            width="40"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={["#db2f7f", "#db2f7f", "#db2f7f", "#db2f7f", "#db2f7f"]}
          />
        </>
      ) : (
        <div
          className={clsx(
            isLoaderBgOpacitySm ? "isLoaderBgOpacitySm" : "",
            "loader-wrap",
            classname
          )}
        >
          <RotatingLines
            visible={true}
            height={size}
            width={size}
            // color={"red"}
            strokeColor={color}
            ariaLabel="triangle-loading"
            wrapperStyle={{}}
            wrapperClass="ILLoader"
          />
        </div>
      )}
    </>
  );
};
