import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  doFetchAddCollection,
  doFetchAddProduct,
  doFetchAddProductsToCollection,
  doFetchAddService,
  doFetchAllCollections,
  doFetchBrandReportMessage,
  doFetchChatUsersList,
  doFetchDeleteChat,
  doFetchRequestAction,
  doFetchUsersChatList,
  doFetchUsersChatRead,
} from "../../../actions";
import { ILChatRow, ILLoader } from "../../../components";
import { getDecryptionString } from "../../../services";
import { socket } from "../../../services/socket";
import {
  validateCollectionAdd,
  validateExistingCollectionAdd,
  validateSubmitAddCollection,
  validateSubmitExistingAddCollection,
} from "../../../validations";
import { useInView } from "react-intersection-observer";
import { clsx } from "clsx";

export const useChatHook = () => {
  const navigate = useNavigate();

  const scrollToBottom = useRef(null);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isLoading, setIsLoading] = useState({
    userList: false,
    chatList: false,
    reportMessage: false,
  });
  const [isReportMessageModal, setReportMessageModal] = useState(false);
  const [isBlockHandleModal, setBlockHandleModal] = useState(false);
  const [isDeleteChatModal, setDeleteChatModal] = useState(false);
  const [isPreviewProductModal, setPreviewProductModal] = useState(false);
  const [isChatListOpen, setChatListOpen] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState({
    acceptBtn: false,
    rejectBtn: false,
    deleteChatBtn: false,
  });
  const [paginationData, setPaginationData] = useState({
    pageSize: 20,
    search: "",
    page: 1,
    type: localStorage?.getItem("data")
      ? JSON.parse(localStorage?.getItem("data"))?.type
      : "PRODUCT",
    roomId: "",
  });
  const [chatPaginationData, setChatPaginationData] = useState({
    pageSize: 20,
    search: "",
    page: 1,
  });
  const [apiStatus, setApiStatus] = useState(false);
  const [chatUserList, setChatUserList] = useState({});
  const [usersRoomChatList, setUsersRoomChatList] = useState({});
  const [selectedRoom, setSelectedRoom] = useState({});
  const [selectedProductData, setSelectedProductData] = useState({});
  const [reportMessage, setReportMessage] = useState("");
  const [viewProductData, setViewProductData] = useState({});
  const [currentId, setCurrentId] = useState("previewProduct");
  const [collectionData, setCollectionData] = useState({});
  const [validateMessages, setValidateMessages] = useState({});
  const [collectionList, setCollectionList] = useState([]);
  const [sendMessageData, setSendMessageData] = useState({});
  const { ref, inView, entry } = useInView({ threshold: 1 });
  const { userDetails } = useSelector(
    (state) => state.storeAuthenticateReducer
  );
  // COMMUNITY GUIDELINES MODAL START
  const [isComGuidelinesModalOpen, setIsComGuidelinesModalOpen] =
    useState(false);
  // COMMUNITY GUIDELINES MODAL END

  useEffect(() => {
    let queryParams = new URLSearchParams(location.search);
    userDetails &&
      queryParams.set("user-name", userDetails?.userData?.user_name);
    queryParams.set("res", apiStatus ? "true" : "false");

    navigate(`${location.pathname}?${queryParams.toString()}`, {
      replace: true,
    });
  }, [location?.pathname, userDetails, apiStatus]);

  useEffect(() => {
    const parsedData = JSON.parse(localStorage?.getItem("data"));
    if (parsedData) {
      setPaginationData((prevState) => ({
        ...prevState,
        type: parsedData?.type,
        roomId: parsedData?.id,
      }));
    }
  }, [localStorage?.getItem("data")]);

  useEffect(() => {
    if (inView) {
      observerScroll();
    }
  }, [inView]);

  useEffect(() => {
    setSelectedRoom({});
  }, [paginationData?.type]);

  useEffect(() => {
    if (Object?.keys(selectedRoom)?.length > 0) {
      const updatedRoomMessagesObject = {
        ...chatUserList,
        rows: chatUserList?.rows?.map((room) => {
          if (room.room_fk === selectedRoom.room_fk) {
            return {
              ...room,
              unread_messages_count: 0,
            };
          }
          return room;
        }),
      };
      setChatUserList(updatedRoomMessagesObject);
    }
  }, [selectedRoom]);

  useEffect(() => {
    scrollToBottom.current?.scrollIntoView({ behavior: "auto" });
  }, [usersRoomChatList]);

  useEffect(() => {
    try {
      socket.emit("check-connection");
      socket.on("done-connection", (result) => {
        console.log("on connected", result);
      });
      socket.emit("connect-user", {
        socketId: socket?.id,
        userId: userDetails?.userData?.id,
      });
    } catch (error) {
      console.error("Error emitting event:", error);
    }
  }, [socket, userDetails?.userData?.id]);

  useEffect(() => {
    if (paginationData?.page > 1) {
      fetchChatUsersList(paginationData);
    } else {
      fetchChatUsersList(paginationData, true);
    }
  }, [paginationData?.page, paginationData?.type]);

  useEffect(() => {
    try {
      socket.on("receive-message", (result) => {
        const userData = chatUserList?.rows?.filter(
          (data) => data?.room_fk === result?.room_fk
        );

        if (userData?.length > 0) {
          const updatedRoomMessagesObject = {
            ...chatUserList,
            rows: chatUserList?.rows?.map((room) => {
              if (room.room_fk === result.room_fk) {
                return {
                  ...room,
                  other_user: { ...room?.other_user, last_massage: [result] },
                  unread_messages_count:
                    selectedRoom?.room_fk === result?.room_fk
                      ? 0
                      : room.unread_messages_count + 1,
                };
              }
              return room;
            }),
          };

          const sortedUserList = updatedRoomMessagesObject?.rows?.sort(
            (a, b) => {
              const dateA = new Date(
                a.other_user.last_massage[0]?.created_at || 0
              );
              const dateB = new Date(
                b.other_user.last_massage[0]?.created_at || 0
              );
              return dateB - dateA;
            }
          );

          setChatUserList({
            ...updatedRoomMessagesObject,
            rows: sortedUserList,
          });
          if (selectedRoom?.room_fk === result?.room_fk) {
            setUsersRoomChatList((prevState) => ({
              ...prevState,
              rows: [...(prevState?.rows || []), result],
            }));
          }
        } else {
          setSelectedRoom({});
          fetchChatUsersList(paginationData, true);
        }
      });
    } catch (error) {
      console.error("Error emitting event:", error);
    }
    return () => {
      socket.off("receive-message");
    };
  }, [chatUserList, usersRoomChatList]);

  useEffect(() => {
    if (Object?.keys(selectedRoom)?.length > 0) {
      if (selectedRoom?.unread_messages_count > 0) {
        fetchUsersReadAllMessages(selectedRoom?.room_fk);
      }
      fetchUsersChatList(
        chatPaginationData,
        chatPaginationData?.page > 1 ? false : true
      );
    }
  }, [selectedRoom, chatPaginationData?.page]);

  // useEffect(() => {
  //   if (Object?.keys(selectedRoom)?.length > 0) {
  //     if (selectedRoom?.unread_messages_count > 0) {
  //       fetchUsersReadAllMessages(selectedRoom?.room_fk);
  //     }
  //     fetchUsersChatList(chatPaginationData);
  //   }
  // }, [chatPaginationData?.page]);

  useEffect(() => {
    if (currentId === "addExistingCollection") {
      fetchCollectionList();
    }
  }, [currentId]);

  const handleInputMessageKeyDown = (event) => {
    if (
      event?.key === "Enter" &&
      sendMessageData?.message?.trim()?.length > 0 &&
      sendMessageData?.message?.trim() !== ""
    ) {
      sendMessageHandler();
    }
  };

  const observerScroll = (entries, type) => {
    if (type === "chat") {
      if (entries?.isIntersecting && usersRoomChatList?.hasNext) {
        setChatPaginationData((prevState) => ({
          ...prevState,
          page: prevState?.page + 1,
        }));
      }
    } else {
      if (entries?.isIntersecting && chatUserList?.hasNext) {
        setPaginationData((prevState) => ({
          ...prevState,
          page: prevState?.page + 1,
        }));
      }
    }
  };

  const handleMessage = (event) => {
    const { name, value } = event?.target;
    setSendMessageData((prevState) => ({ ...prevState, [name]: value }));
  };

  const sendMessageHandler = () => {
    try {
      socket.emit(
        "send-message",
        {
          senderId: userDetails?.userData?.id,
          receiverId: selectedRoom?.other_user?.user_fk,
          roomId: selectedRoom?.room_fk,
          message: sendMessageData?.message,
        },
        (response) => {
          if (response) {
            console.log(response);
          }
        }
      );

      setUsersRoomChatList((prevState) => ({
        ...prevState,
        rows: [
          ...(prevState?.rows || []),
          {
            from_fk: userDetails?.userData?.id,
            to_fk: selectedRoom?.other_user?.user_fk,
            message: sendMessageData?.message,
            created_at: new Date().toISOString(),
          },
        ],
      }));

      setChatUserList((prevState) => ({
        ...prevState,
        rows: prevState?.rows
          ?.map((room) => {
            if (room.room_fk === selectedRoom.room_fk) {
              return {
                ...room,
                other_user: {
                  ...room?.other_user,
                  last_massage: [
                    {
                      from_fk: userDetails?.userData?.id,
                      to_fk: selectedRoom?.other_user?.user_fk,
                      message: sendMessageData?.message,
                      created_at: new Date().toISOString(),
                    },
                  ],
                },
              };
            }
            return room;
          })
          ?.sort((a, b) => {
            const dateA = new Date(
              a.other_user.last_massage[0]?.created_at || 0
            );
            const dateB = new Date(
              b.other_user.last_massage[0]?.created_at || 0
            );
            return dateB - dateA;
          }),
      }));

      setSendMessageData({});
    } catch (error) {
      console.error("Error emitting event:", error);
    }
  };

  const handleSelectChange = (value, name) => {
    if (name === "id") {
      const { errors } = validateExistingCollectionAdd(name, value);
      setValidateMessages(errors);
      setCollectionData((prevState) => ({ ...prevState, id: value }));
    } else {
      setSelectedRoom({});
      localStorage?.removeItem("data");
      setPaginationData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const handleViewDetail = (data) => {
    setPreviewProductModal(true);
    setViewProductData(data);
  };

  const handleInputChange = (event) => {
    const { value, name } = event?.target;

    if (name === "name") {
      const { errors } = validateCollectionAdd(name, value);
      setValidateMessages(errors);
      setCollectionData((prevState) => ({ ...prevState, [name]: value }));
    } else if (name === "reportMessage") {
      setReportMessage(value);
    } else {
      setPaginationData((prevState) => ({ ...prevState, [name]: value }));
      debounceUserSearchHandler({
        ...paginationData,
        [name]: value,
        page: 1,
      });
    }
  };

  const debounceUserSearchHandler = useCallback(
    debounce(async (data) => {
      await fetchChatUsersList(data);
    }, 1000),
    []
  );

  const handleGetRoomDetails = (data) => {
    setSelectedRoom({});
    localStorage?.removeItem("data");
    setChatPaginationData((prevState) => ({ ...prevState, page: 1 }));

    socket.emit("seen-message", {
      userId: userDetails?.userData?.id,
      activeUserId: data?.other_user?.user_fk,
    });
    setSelectedRoom(data);
  };

  const handleReportMessageModal = () => {
    setReportMessageModal(false);
    setReportMessage("");
  };

  const handleBlockSenderModal = () => {
    setBlockHandleModal(false);
  };

  const handleDeleteChatModal = () => {
    setDeleteChatModal(false);
  };

  const handleViewInShop = () => {
    navigate("/dashboard/my-shop");
  };

  const handleModalClose = () => {
    setPreviewProductModal(false);
    setCurrentId("previewProduct");
    setSelectedProductData({});
  };

  const handleAcceptRequest = (data, type) => {
    setSelectedProductData(data);
    handleAccessRequest(data, type);
  };

  const handleRejectRequest = (data, type) => {
    setSelectedProductData(data);
    handleAccessRequest(data, type);
  };

  const customRequestTabData = [
    {
      key: "PRODUCT",
      label: "Produkter",
      children: (
        <>
          {!isLoading?.userList && chatUserList?.rows?.length > 0 ? (
            <ILChatRow
              ref={ref}
              selectedRoom={selectedRoom}
              chatRowDataList={chatUserList}
              observerScroll={observerScroll}
              handleGetDetails={handleGetRoomDetails}
            />
          ) : !isLoading?.userList && chatUserList?.rows?.length === 0 ? (
            <p
              className={clsx(
                "d-flex gradient-text align-items-center justify-content-center flex-column fw-medium noCustomReqFounTitle"
              )}
            >
              Inga förfrågningar hittades
            </p>
          ) : (
            <p className="text-center fw-medium">
              <div className="w-100 h-100 d-flex align-items-center justify-content-center flex-column">
                <ILLoader
                  classname={"loader-wrap-absolute bg-transparent"}
                  isLoaderBgOpacitySm
                  isChatLoader
                />
                <h6>Loading...</h6>
              </div>
            </p>
          )}
        </>
      ),
    },
    {
      key: "SERVICE",
      label: "Tjänster",
      children: (
        <>
          {!isLoading?.userList && chatUserList?.rows?.length > 0 ? (
            <ILChatRow
              ref={ref}
              selectedRoom={selectedRoom}
              chatRowDataList={chatUserList}
              observerScroll={observerScroll}
              handleGetDetails={handleGetRoomDetails}
            />
          ) : !isLoading?.userList && chatUserList?.rows?.length === 0 ? (
            <p
              className={clsx(
                "d-flex gradient-text align-items-center justify-content-center flex-column fw-medium noCustomReqFounTitle"
              )}
            >
              Inga förfrågningar hittades
            </p>
          ) : (
            <p className="text-center fw-medium">
              <div className="w-100 h-100 d-flex align-items-center justify-content-center flex-column">
                <ILLoader
                  classname={"loader-wrap-absolute bg-transparent"}
                  isLoaderBgOpacitySm
                  isChatLoader
                />
                <h6>Loading...</h6>
              </div>
            </p>
          )}
        </>
      ),
    },
  ];

  const fetchChatUsersList = async (data, isInitialLoad = false) => {
    try {
      if (isInitialLoad) {
        setIsLoading((prevState) => ({ ...prevState, userList: true }));
      }
      const chatUserList = await doFetchChatUsersList(
        data?.page,
        data?.pageSize,
        data?.type,
        data?.search
      );

      if (chatUserList?.status === 200) {
        setApiStatus(true);

        const decryptedChatUserList = await JSON.parse(
          getDecryptionString(chatUserList?.data?.data)
        );

        const updatedUserList = {
          ...decryptedChatUserList,
          rows:
            chatUserList?.rows?.length > 0
              ? [...decryptedChatUserList?.rows, ...chatUserList?.rows]
              : decryptedChatUserList?.rows,
        };

        setChatUserList(updatedUserList);
        const storedData = localStorage?.getItem("data");
        if (storedData) {
          const parsedData = JSON.parse(storedData);

          const filteredData = updatedUserList?.rows?.filter(
            (data) => data?.room_fk === parsedData?.id
          );

          filteredData && setSelectedRoom(filteredData[0]);
        }
      } else {
        setApiStatus(false);
      }
      setIsLoading((prevState) => ({ ...prevState, userList: false }));
    } catch (error) {
      setIsLoading((prevState) => ({ ...prevState, userList: false }));
      console.error(
        "Error occurred during fetching chat users list details:",
        error
      );
    }
  };

  const fetchUsersChatList = async (data, isInitialLoad = false) => {
    try {
      if (isInitialLoad) {
        setIsLoading((prevState) => ({ ...prevState, chatList: true }));
      }

      const usersChatList = await doFetchUsersChatList(
        data?.page,
        data?.pageSize,
        selectedRoom?.other_user?.room_fk
      );

      if (usersChatList?.status === 200) {
        const decryptedUsersChatList = await JSON.parse(
          getDecryptionString(usersChatList?.data?.data)
        );

        const updatedRoomMessagesObject = {
          ...decryptedUsersChatList,
          rows: !isInitialLoad
            ? [
                ...decryptedUsersChatList?.rows?.slice()?.reverse(),
                ...usersRoomChatList?.rows,
              ]
            : decryptedUsersChatList?.rows?.slice()?.reverse(),
        };

        setUsersRoomChatList(updatedRoomMessagesObject);
      }
      setIsLoading((prevState) => ({ ...prevState, chatList: false }));
    } catch (error) {
      setIsLoading((prevState) => ({ ...prevState, chatList: false }));
      console.error("Error occurred during fetching users chat:", error);
    }
  };

  const handleAccessRequest = async (data, type) => {
    try {
      if (type == "accept") {
        setIsButtonLoading((prevState) => ({ ...prevState, acceptBtn: true }));
      } else {
        setIsButtonLoading((prevState) => ({ ...prevState, rejectBtn: true }));
      }
      const requestBody = {
        requestId: data?.custom_request_fk,
        status: type,
        roomId: data?.room_fk,
      };

      const accessRequestResponse = await doFetchRequestAction(requestBody);

      if (accessRequestResponse?.status === 200) {
        if (type === "reject") {
          setPreviewProductModal(true);
          setCurrentId("rejectProduct");
        } else {
          setPreviewProductModal(true);
          setCurrentId("acceptedCustomMessage");
        }
        fetchUsersChatList(chatPaginationData, true);
      } else {
        toast.error(accessRequestResponse?.data?.message);
      }
      setIsButtonLoading((prevState) => ({
        ...prevState,
        acceptBtn: false,
        rejectBtn: false,
      }));
    } catch (error) {
      console.log({ error });
      setIsButtonLoading((prevState) => ({
        ...prevState,
        acceptBtn: false,
        rejectBtn: false,
      }));
    }
  };

  const addToShopHandle = (data = {}) => {
    if (paginationData?.type === "SERVICE") {
      handleProductAddToShop(data);
    } else {
      setSelectedProductData(data);

      if (data?.product_request?.collection_details === null) {
        setPreviewProductModal(true);
        setCurrentId("addToShop");
      } else {
        handleProductAddToShop(data);
      }
    }
  };

  const handleProductAddToShop = async (data = {}) => {
    try {
      setIsModalLoading(true);
      let addProductServiceResponse;
      const requestBody = {
        ...(paginationData?.type === "SERVICE"
          ? {
              serviceId:
                Object?.keys(selectedProductData)?.length > 0
                  ? selectedProductData?.product_request?.service_details?.id
                  : data?.product_request?.service_details?.id,
            }
          : data?.product_request?.product_details === null
          ? {
              brandCollectionId: data?.product_request?.collection_details?.id,
            }
          : {
              productId:
                Object?.keys(selectedProductData)?.length > 0
                  ? selectedProductData?.product_request?.product_details?.id
                  : data?.product_request?.product_details?.id,
            }),
      };

      if (paginationData?.type === "SERVICE") {
        addProductServiceResponse = await doFetchAddService(requestBody);
      } else {
        if (data?.product_request?.product_details === null) {
          addProductServiceResponse = await doFetchAddCollection(requestBody);
        } else {
          addProductServiceResponse = await doFetchAddProduct(requestBody);
        }
      }

      if (addProductServiceResponse?.status === 200) {
        setPreviewProductModal(true);
        setCurrentId("productAdded");
      } else if (addProductServiceResponse?.status === 409) {
        setPreviewProductModal(true);
        setCurrentId("alreadyAdded");
      } else {
        toast.error(addProductServiceResponse?.data?.message);
      }
      fetchUsersChatList(chatPaginationData, true);
      setIsModalLoading(false);
    } catch (error) {
      console.log({ error });
      setIsModalLoading(false);
    }
  };

  const fetchReportMessage = async () => {
    try {
      setIsLoading((prevState) => ({ ...prevState, reportMessage: true }));

      const requestBody = {
        roomId: selectedRoom?.other_user?.room_fk,
        blockUserId: selectedRoom?.other_user?.user_fk,
        type: "BLOCK",
        message: reportMessage || "",
      };

      const reportMessageResponse = await doFetchBrandReportMessage(
        requestBody
      );

      if (reportMessageResponse?.status === 200) {
        setReportMessageModal(false);
        fetchChatUsersList(paginationData);
        setSelectedRoom({});
        handleBlockSenderModal();
      }
      setIsLoading((prevState) => ({ ...prevState, reportMessage: false }));
    } catch (error) {
      setIsLoading((prevState) => ({ ...prevState, reportMessage: false }));
      console.error("Error occurred during reporting message:", error);
    }
  };

  const fetchCollectionList = async () => {
    try {
      setIsModalLoading(true);

      const collectionListResponse = await doFetchAllCollections();

      if (collectionListResponse?.status === 200) {
        const decryptedCollectionList = await JSON.parse(
          getDecryptionString(collectionListResponse?.data)
        );
        const collectionListData = decryptedCollectionList?.map((data) => {
          return {
            value: data?.id,
            label: data?.name,
          };
        });
        setCollectionList(collectionListData);
      }
      setIsModalLoading(false);
    } catch (error) {
      console.error("Error occurred during fetching collection list:", error);
      setIsModalLoading(false);
    }
  };

  const handleSubmitCollection = () => {
    try {
      let errors = {};

      if (currentId === "addExistingCollection") {
        ({ errors } = validateSubmitExistingAddCollection(collectionData));
      } else {
        ({ errors } = validateSubmitAddCollection(collectionData));
      }

      setValidateMessages(errors);
      if (Object.keys(errors).length === 0) {
        handleCollectionCreateCallback();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCollectionCreateCallback = async () => {
    try {
      setIsModalLoading(true);
      const productListArr = [];

      productListArr.push({
        id: selectedProductData?.product_request?.product_details?.id,
        brandId: selectedProductData?.brand_details?.id,
      });

      const requestBody = {
        products: productListArr,
        ...(currentId === "addExistingCollection"
          ? {
              collectionId: collectionData?.id,
            }
          : { name: collectionData?.name }),
      };

      const addCollectionResponse = await doFetchAddProductsToCollection(
        requestBody
      );

      if (addCollectionResponse?.status === 200) {
        if (currentId === "addExistingCollection") {
          setCurrentId("collectionUpdated");
        } else {
          setCurrentId("collectionCreated");
        }
      } else {
        toast.error(addCollectionResponse?.message);
      }
      setIsModalLoading(false);
    } catch (error) {
      console.log(error);
      setIsModalLoading(false);
    }
  };

  const fetchDeleteChat = async () => {
    try {
      setIsButtonLoading((prevState) => ({
        ...prevState,
        deleteChatBtn: true,
      }));

      const deleteChatResponse = await doFetchDeleteChat(selectedRoom?.room_fk);

      if (deleteChatResponse?.status === 200) {
        handleDeleteChatModal();
        fetchUsersChatList(chatPaginationData, true);
        fetchChatUsersList(paginationData, true);
      }
      setIsButtonLoading((prevState) => ({
        ...prevState,
        deleteChatBtn: false,
      }));
    } catch (error) {
      console.error("Error occurred during deleting chat:", error);
      setIsButtonLoading((prevState) => ({
        ...prevState,
        deleteChatBtn: false,
      }));
    }
  };

  const fetchUsersReadAllMessages = async (id) => {
    try {
      await doFetchUsersChatRead(id);
    } catch (error) {
      console.error("Error occurred during reading all messages:", error);
    }
  };

  // COMMUNITY GUIDELINES MODAL START
  const showComGuidelinesModal = () => {
    setIsComGuidelinesModalOpen(true);
    setReportMessageModal(false);
  };
  const handleComGuidelinesModalOk = () => {
    setIsComGuidelinesModalOpen(false);
  };
  const handleComGuidelinesModalCancel = () => {
    setIsComGuidelinesModalOpen(false);
    setReportMessageModal(true);
  };
  // COMMUNITY GUIDELINES MODAL END

  return {
    currentId,
    isLoading,
    userDetails,
    chatUserList,
    selectedRoom,
    reportMessage,
    isChatListOpen,
    scrollToBottom,
    isModalLoading,
    collectionData,
    paginationData,
    collectionList,
    isButtonLoading,
    sendMessageData,
    viewProductData,
    validateMessages,
    usersRoomChatList,
    isDeleteChatModal,
    isBlockHandleModal,
    selectedProductData,
    customRequestTabData,
    isReportMessageModal,
    isPreviewProductModal,
    isComGuidelinesModalOpen,
    ref,
    setCurrentId,
    observerScroll,
    handleMessage,
    addToShopHandle,
    fetchDeleteChat,
    setChatListOpen,
    handleViewInShop,
    handleViewDetail,
    handleModalClose,
    handleInputChange,
    fetchReportMessage,
    sendMessageHandler,
    setDeleteChatModal,
    handleSelectChange,
    handleAcceptRequest,
    handleAccessRequest,
    setBlockHandleModal,
    handleRejectRequest,
    handleGetRoomDetails,
    handleDeleteChatModal,
    setReportMessageModal,
    handleBlockSenderModal,
    handleSubmitCollection,
    handleProductAddToShop,
    setPreviewProductModal,
    handleReportMessageModal,
    handleInputMessageKeyDown,
    showComGuidelinesModal,
    handleComGuidelinesModalOk,
    handleComGuidelinesModalCancel,
  };
};
