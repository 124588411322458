import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { doFetchSendOtp, doFetchVerifyOtp } from "../../actions";
import { validateSubmitVerifyOtp, validateVerifyOtp } from "../../validations";

export const useOtpVerify = () => {
  const navigate = useNavigate();

  const [influencerEmail, setInfluencerEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [otpData, setOtpData] = useState({});
  const [validateMessages, setValidateMessages] = useState({});
  const [resendOtpView, setResendOtpView] = useState(true);
  const [time, setTime] = useState(60);

  useEffect(() => {
    setInfluencerEmail(localStorage.getItem("email"));
  }, []);

  useEffect(() => {
    if (!resendOtpView) {
      if (time > 0) {
        const interval = setInterval(() => {
          setTime((prevTime) => prevTime - 1);
        }, 1000);

        return () => clearInterval(interval);
      } else {
        onTimeUp();
      }
    }
  }, [time, resendOtpView]);

  const onTimeUp = () => {
    setResendOtpView(true);
  };

  const handleOtpSelectChange = async (value, name) => {
    setIsLoading(true);

    const { errors } = validateVerifyOtp(name, value, validateMessages);
    setValidateMessages(errors);
    setOtpData({ ...otpData, [name]: value });
    setIsLoading(false);
  };

  const handleVerifyOtpSubmit = () => {
    try {
      const { errors } = validateSubmitVerifyOtp(otpData);
      setValidateMessages(errors);

      if (Object.keys(errors).length === 0) {
        verifyOtpCallback();
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const verifyOtpCallback = async () => {
    try {
      setIsLoading(true);

      const requestBody = {
        email: influencerEmail,
        otp: otpData?.otp,
        type: "PASSWORD",
        verifyUserType: "Brand",
      };
      const verifyOtpResponse = await doFetchVerifyOtp(requestBody);

      if (verifyOtpResponse?.status === 200) {
        toast.success(verifyOtpResponse?.message);
        navigate("/reset-password");
      } else {
        toast.error(verifyOtpResponse?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  const resendOtpCallback = async () => {
    try {
      setIsLoading(true);
      setOtpData({});
      setResendOtpView(false);
      setTime(60);

      const requestBody = {
        email: influencerEmail,
        type: "PASSWORD",
        resend: true,
      };
      const sendOtpResponse = await doFetchSendOtp(requestBody);

      if (sendOtpResponse?.status === 200) {
        setValidateMessages({});
        toast.success(sendOtpResponse?.message);
        navigate("/email-verification");
      } else {
        toast.error(sendOtpResponse?.data?.message);
      }

      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  return {
    time,
    otpData,
    isLoading,
    resendOtpView,
    influencerEmail,
    validateMessages,
    navigate,
    resendOtpCallback,
    handleVerifyOtpSubmit,
    handleOtpSelectChange,
  };
};
