import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { doFetchInfluencerDetailsByUserName } from "../../../actions";
import { getDecryptionString } from "../../../services";
import { toast } from "react-toastify";

export const useLiveProfileLayoutHook = () => {
  const params = useParams();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [influencerDetails, setInfluencerDetails] = useState({});

  useEffect(() => {
    if (!location?.pathname?.includes("collection") && params?.userName)
      fetchInfluencerDetails();
  }, [location, params]);

  const fetchInfluencerDetails = async () => {
    try {
      setIsLoading(true);

      const influencerDetailResponse = await doFetchInfluencerDetailsByUserName(
        params?.userName
      );
      if (influencerDetailResponse?.status === 200) {
        const decryptedInfluencerDetails = await JSON.parse(
          getDecryptionString(influencerDetailResponse?.data?.data)
        );

        setInfluencerDetails(decryptedInfluencerDetails?.userData);
      } else {
        toast.error(influencerDetailResponse?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    influencerDetails,
  };
};
