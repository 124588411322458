import React from "react";
import { authBannerOne } from "../../../constants/imageData";
import { Form } from "antd";
import {
  ILTextInput,
  ILButton,
  ILModal,
  ILHead,
  ILLoader,
} from "../../../components";
import {
  ArrowLeft02Icon,
  SquareLockPasswordIcon,
  ViewIcon,
  ViewOffIcon,
} from "hugeicons-react";
import { Link } from "react-router-dom";
import { ILSuccessfullyModal } from "./ILSuccessfullyModal";
import { clsx } from "clsx";
import styles from "./ILResetPassword.module.css";
import { useResetPasswordHook } from "../../../hooks/auth/resetPassword.hook";

const ILResetPassword = () => {
  const {
    isLoading,
    isModalOpen,
    validateMessages,
    passwordShowIcon,
    resetPasswordData,
    handleModalOk,
    handleModalCancel,
    handleInputChange,
    handlePasswordShowIcon,
    handleResetPasswordSubmit,
  } = useResetPasswordHook();

  return (
    <div>
      <ILHead title="Ändra ditt lösenord" />
      <section className="authentication-section">
        <div className="authentication-flex d-flex flex-wrap">
          <div className="authentication-col d-flex overflow-y-auto order-2 order-lg-1 py-5 py-xl-4">
            <div className="m-auto authentication-sm-box">
              <Link
                to="/forgot-password"
                className="auth-back-arrow d-inline-flex"
              >
                <ArrowLeft02Icon
                  size={35}
                  color={"#1D1128"}
                  variant={"stroke"}
                  strokeWidth={1.5}
                  className="svg-hover"
                />
              </Link>
              <h1 className="secondary-title md-secondary-title">
                Ändra ditt lösenord
              </h1>
              <p className="paragraph-sm-text paragraph-md-text">
                ILONSI gör det enkelt att hålla ditt konto säkert. Ändra ditt
                lösenord snabbt och smidigt.
              </p>
              <Form>
                <ILTextInput
                  prefix={
                    <SquareLockPasswordIcon
                      size={24}
                      color={"#1d1128"}
                      variant={"stroke"}
                      strokeWidth={1.5}
                    />
                  }
                  suffix={
                    passwordShowIcon?.newPasswordShowIcon ? (
                      <span
                        className="cursor-pointer"
                        onClick={() => handlePasswordShowIcon("newPassword")}
                      >
                        <ViewOffIcon
                          size={24}
                          color={"#1d1128"}
                          variant={"stroke"}
                          strokeWidth={1.5}
                        />
                      </span>
                    ) : (
                      <span
                        className="cursor-pointer"
                        onClick={() => handlePasswordShowIcon("newPassword")}
                      >
                        <ViewIcon
                          size={24}
                          color={"#1d1128"}
                          variant={"stroke"}
                          strokeWidth={1.5}
                        />
                      </span>
                    )
                  }
                  isRequiredInput
                  isSuffixIconPresent
                  id="LoginNewPassword"
                  datatestid="LoginNewPassword"
                  type={
                    passwordShowIcon?.newPasswordShowIcon ? "text" : "password"
                  }
                  label="Nytt lösenord"
                  placeholder={"Ange ditt nya lösenord"}
                  name="password"
                  value={resetPasswordData?.password}
                  handleChange={handleInputChange}
                  errorMsg={validateMessages?.password}
                />
                <ILTextInput
                  isRequiredInput
                  prefix={
                    <SquareLockPasswordIcon
                      size={24}
                      color={"#1d1128"}
                      variant={"stroke"}
                      strokeWidth={1.5}
                    />
                  }
                  suffix={
                    passwordShowIcon?.confirmPasswordShowIcon ? (
                      <span
                        className="cursor-pointer"
                        onClick={handlePasswordShowIcon}
                      >
                        <ViewOffIcon
                          size={24}
                          color={"#1d1128"}
                          variant={"stroke"}
                          strokeWidth={1.5}
                        />
                      </span>
                    ) : (
                      <span
                        className="cursor-pointer"
                        onClick={handlePasswordShowIcon}
                      >
                        <ViewIcon
                          size={24}
                          color={"#1d1128"}
                          variant={"stroke"}
                          strokeWidth={1.5}
                        />
                      </span>
                    )
                  }
                  isSuffixIconPresent
                  id="loginConfirmNewPassword"
                  datatestid="loginConfirmNewPassword"
                  label="Bekräfta nytt lösenord"
                  placeholder={"Bekräfta lösenord"}
                  type={
                    passwordShowIcon?.confirmPasswordShowIcon
                      ? "text"
                      : "password"
                  }
                  name="confirmPassword"
                  value={resetPasswordData?.confirmPassword}
                  handleChange={handleInputChange}
                  errorMsg={validateMessages?.confirmPassword}
                />
                <ILButton
                  disabled={isLoading}
                  ILBtnClass="w-100"
                  variant={"primary-gradient"}
                  onClick={handleResetPasswordSubmit}
                >
                  {isLoading ? <ILLoader isBtn /> : "Ändra lösenord"}
                </ILButton>
              </Form>
            </div>
          </div>
          <div className="authentication-col bg-dark authentication-right-col order-1 order-lg-2 mb-5 mb-lg-0">
            <img
              src={authBannerOne}
              alt="advertising agency | ilonsi"
              className="w-100 h-100 object-fit-cover"
            />
          </div>
        </div>
      </section>
      <ILModal
        open={isModalOpen}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        title={""}
        className={clsx(styles.authSuccessfullyModalWrap)}
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <ILSuccessfullyModal />
          </>
        }
        isFooterRemove
      />
    </div>
  );
};

export default ILResetPassword;
