import { format, isToday, isYesterday } from "date-fns";

export const countHandler = (count) => {
  if (count < 10 && count > 0) {
    return "0" + count;
  } else {
    return count;
  }
};

export const getTimeDifference = (timestamp) => {
  const timeUnits = [
    { unit: "years", value: 12 * 30 * 24 * 60 * 60 }, // approx number of seconds in a year
    { unit: "months", value: 30 * 24 * 60 * 60 }, // approx number of seconds in a month
    { unit: "d", value: 24 * 60 * 60 }, // seconds in a day
    { unit: "h", value: 60 * 60 }, // seconds in an hour
    { unit: "m", value: 60 }, // seconds in a minute
    { unit: "s", value: 1 }, // seconds
  ];

  const givenTime = new Date(timestamp).getTime();
  const currentTime = Date.now();
  let differenceInSeconds = Math.floor((currentTime - givenTime) / 1000);

  for (const { unit, value } of timeUnits) {
    const unitAmount = Math.floor(differenceInSeconds / value);
    if (unitAmount >= 1) {
      return `${unitAmount} ${unit}`;
    }
  }

  return "just now";
};

export const formatTime = (dateString) => {
  const date = new Date(dateString);

  // Get hours and minutes
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Format hours and minutes to always have two digits
  const formattedHours = hours < 10 ? "0" + hours : hours;
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

  // Return time in 24-hour format
  return `${formattedHours}:${formattedMinutes}`;
};

export const getMessageDateLabel = (date) => {
  const messageDate = new Date(date);

  if (isToday(messageDate)) {
    return "Idag";
  } else if (isYesterday(messageDate)) {
    return "i går";
  } else {
    return format(messageDate, "dd MMM yyyy");
  }
};

export const isEmpty = (value) => {
  if (value === undefined) return true;

  if (value === null || value.length === 0) return true;

  if (typeof value === "object") {
    let result = true;
    if (Object.keys(value) == "" || Object.keys(value).length > 0) {
      result = false;
    }
    return result;
  }

  if (typeof value === "string") {
    if (
      value.trim() === "" ||
      value === "null" ||
      value === "undefine" ||
      // value == "0" ||
      value === "NaN"
    ) {
      return true;
    } else {
      return false;
    }
  }
};
