import { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { capitalize, debounce } from "lodash";
import { toast } from "react-toastify";
import { Delete02Icon } from "hugeicons-react";
import { getDecryptionString, isEmpty } from "../../../services";
import { useNavigate } from "react-router-dom";
import { doFetchAllServices, doFetchDeleteService } from "../../../actions";
import { ILSocialShareMedia } from "../../../components";
import { Icons } from "../../../constants/icons";
import { appConfig } from "../../../config";
import copy from "copy-to-clipboard";
import { useSelector } from "react-redux";

export const useServiceViewAllListHook = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isServiceDeleteLoading, setIsServiceDeleteLoading] = useState(false);
  const [serviceId, setServiceId] = useState("");
  const [serviceListData, setServiceListData] = useState({});
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isSuccessfullyDeleteModalOpen, setIsSuccessfullyDeleteModalOpen] =
    useState(false);
  const [paginationData, setPaginationData] = useState({
    search: "",
    pageSize: 5,
    page: 1,
  });
  const [selectedSocialMedia, setSelectedSocialMedia] = useState({});
  const [isShareSMModalOpen, setIsShareSMModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const { userDetails } = useSelector(
    (state) => state.storeAuthenticateReducer
  );

  useEffect(() => {
    fetchAllServiceList(paginationData);
  }, [paginationData?.page, paginationData?.pageSize]);

  const showShareSMModal = () => {
    setIsShareSMModalOpen(true);
  };
  const handleShareSMModalClose = () => {
    setIsShareSMModalOpen(false);
  };

  const generateSharableLink = () => {
    window.open(
      `${selectedSocialMedia?.link}${appConfig.APP_URL}${selectedData?.service?.short_code}`,
      "_blank"
    );
    setSelectedSocialMedia({});
    setIsShareSMModalOpen(false);
  };

  const shareSocialMediaHandler = () => {
    setIsShareSMModalOpen(false);
    generateSharableLink();
  };

  const handleInputChange = (event) => {
    const { value, name } = event?.target;
    setPaginationData((prevState) => ({ ...prevState, [name]: value }));
    debounceServiceSearchHandler({
      ...paginationData,
      [name]: value,
      page: 1,
    });
  };

  const handleSelectSocialMedia = (data) => {
    setSelectedSocialMedia(data);
  };

  const debounceServiceSearchHandler = useCallback(
    debounce(async (data) => {
      await fetchAllServiceList(data);
    }, 1000),
    []
  );

  const showDeleteConfirmModal = (id) => {
    setServiceId(id);
    setIsDeleteModal(!isDeleteModal);
  };

  const handleDeleteConfirmModalCancel = () => {
    setIsDeleteModal(false);
  };

  const showSuccessfullyDeleteModal = () => {
    handleDeleteServiceSubmit();
  };

  const handleSuccessfullyDeleteModalOk = () => {
    setIsSuccessfullyDeleteModalOpen(false);
    setServiceId("");
  };

  const handleTableChange = (event, filter, sort) => {
    try {
      setPaginationData((prev) => ({
        ...prev,
        page: event?.current,
        pageSize: event?.pageSize,
      }));
    } catch (error) {
      console.error("Error occurred during pagination:", error);
    }
  };

  const handleCopyLink = () => {
    copy(`${appConfig.APP_URL}${selectedData?.service?.short_code}`);
    toast.success("Länk kopierad");
  };

  const serviceColumns = useMemo(() => {
    return [
      {
        title: "Tjänster/Plats",
        dataIndex: "services_location",
        key: "services_location",
        render: (_, item) => (
          <div
            className="d-flex align-items-center gap-4 cursor-pointer"
            onClick={() =>
              navigate(
                `/dashboard/my-shop/service-details/${item?.service?.id}`
              )
            }
          >
            <div className={clsx("ratio ratio-1x1 product-service-img")}>
              <img
                className={"object-fit-cover h-100 w-100"}
                src={item?.service?.image}
                alt={item?.service?.name}
              />
            </div>
            <div>
              <p className="fw-medium mb-2">{item?.service?.name}</p>
              <p className="sm mb-0 text-gray text-ellipsis line-clamp-1">
                {item?.service?.address !== "undefined"
                  ? capitalize(item?.service?.address)
                  : item?.service?.city?.name +
                    ", " +
                    item?.service?.country?.name}
              </p>
              <p className="fw-medium mb-0 gradient-text">
                {item?.service?.price} KR
              </p>
            </div>
          </div>
        ),
        width: "400px",
      },
      {
        title: "Företag",
        dataIndex: "brand",
        key: "brand",
        width: "200px",
        render: (_, item) => (
          <>
            <div className="d-flex gap-3 align-items-center">
              <div className="table-logo-img">
                <img
                  width={"100%"}
                  height={"100%"}
                  className={"object-fit-cover"}
                  src={item?.service?.brand?.profile_image}
                  alt={item?.service?.brand?.name}
                />
              </div>
              <p className="fw-medium mb-0">{item?.service?.brand?.name}</p>
            </div>
          </>
        ),
      },
      {
        title: "Omdömen",
        dataIndex: "ratings",
        key: "ratings",
        render: (_, item) => (
          <>
            {item?.service?.rating > 0 ? (
              <>
                <div className="d-flex align-items-center gap-2">
                  {Icons.star}
                  <p className="fw-medium gradient-text max-w-max-content mb-0">
                    {item?.service?.rating}
                  </p>
                </div>
              </>
            ) : (
              "-"
            )}
          </>
        ),
        width: "150px",
      },
      {
        title: "Klicks",
        dataIndex: "clicks",
        key: "clicks",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {item?.service?.clicks}
          </p>
        ),
      },
      {
        title: "CPC",
        dataIndex: "ppc",
        key: "ppc",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {item?.service?.ppc_price}
          </p>
        ),
      },
      {
        title: "Försäljning",
        dataIndex: "sell",
        key: "sell",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {item?.service?.sells}
          </p>
        ),
      },
      {
        title: "Provision %",
        dataIndex: "sale",
        key: "sale",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {isEmpty(item?.service?.sell_commission)
              ? "-"
              : item?.service?.sell_commission + " %"}
          </p>
        ),
      },
      {
        title: "Redigera",
        dataIndex: "action",
        key: "action",
        render: (_, item) => (
          <>
            <div
              className="d-flex align-items-center gap-5 action-btns-wrap cursor-pointer"
              onClick={() => setSelectedData(item)}
            >
              <span className="w-50 text-center">
                <ILSocialShareMedia showShareSMModal={showShareSMModal} />
              </span>
              <span className="w-50 text-center" role="button">
                <Delete02Icon
                  size={20}
                  color={"#ED4F32"}
                  variant={"stroke"}
                  onClick={() => showDeleteConfirmModal(item?.id)}
                />
              </span>
            </div>
          </>
        ),
        width: "100px",
        fixed: "right",
      },
    ];
  }, [serviceListData?.rows, userDetails]);

  const fetchAllServiceList = async (data) => {
    try {
      setIsLoading(true);

      const serviceListResponse = await doFetchAllServices(
        data?.pageSize,
        data?.page,
        data?.search
      );

      if (serviceListResponse?.status === 200) {
        const decryptedServiceList = await JSON.parse(
          getDecryptionString(serviceListResponse?.data?.data)
        );
        setServiceListData(decryptedServiceList);
      }
      setIsServiceDeleteLoading(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsServiceDeleteLoading(false);
      console.error("Error occurred during fetching all services:", error);
    }
  };

  const handleDeleteServiceSubmit = async () => {
    try {
      setIsServiceDeleteLoading(true);
      const serviceDeleteResponse = await doFetchDeleteService(serviceId);

      if (serviceDeleteResponse?.status === 200) {
        setIsDeleteModal(false);
        setIsSuccessfullyDeleteModalOpen(true);
        fetchAllServiceList(paginationData);
        setPaginationData((prevState) => ({ ...prevState, page: 1 }));
      } else {
        toast.error(serviceDeleteResponse?.data?.message);
      }
    } catch (error) {
      setIsServiceDeleteLoading(false);
      console.error("Error occurred during deleting service:", error);
    }
  };

  return {
    isLoading,
    serviceId,
    selectedData,
    isDeleteModal,
    serviceColumns,
    paginationData,
    serviceListData,
    isShareSMModalOpen,
    selectedSocialMedia,
    isServiceDeleteLoading,
    isSuccessfullyDeleteModalOpen,
    handleCopyLink,
    showShareSMModal,
    handleTableChange,
    handleInputChange,
    showDeleteConfirmModal,
    handleSelectSocialMedia,
    shareSocialMediaHandler,
    handleShareSMModalClose,
    showSuccessfullyDeleteModal,
    handleDeleteConfirmModalCancel,
    handleSuccessfullyDeleteModalOk,
  };
};
