import { axiosApi } from "../../../axios";

export const doFetchChatUsersList = async (
  page = 1,
  pageSize = 10,
  type = "",
  search = ""
) => {
  try {
    const response = await axiosApi({
      method: "get",
      url: `v1/influencer/chat/user-list?search=${search}&type=${type}&currentPage=${page}&pageSize=${pageSize}`,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchUsersChatList = async (
  page = 1,
  pageSize = 10,
  roomId = ""
) => {
  try {
    const response = await axiosApi({
      method: "get",
      url: `v1/influencer/chat/chat-list?roomId=${roomId}&currentPage=${page}&pageSize=${pageSize}`,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchBrandReportMessage = async (data) => {
  try {
    const response = await axiosApi({
      method: "post",
      url: "v1/influencer/block/add",
      data: data,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const doFetchDeleteChat = async (id) => {
  try {
    const response = await axiosApi({
      method: "delete",
      url: `v1/influencer/chat/${id}`,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

export const doFetchUsersChatRead = async (roomId = "") => {
  try {
    const response = await axiosApi({
      method: "post",
      url: `v1/Influencer/chat/read/${roomId}`,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};
