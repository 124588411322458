import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export const useAboutUsHook = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("_token") ? true : false
  );
  const { userDetails } = useSelector(
    (state) => state.storeAuthenticateReducer
  );

  useEffect(() => {
    if (userDetails) {
      let queryParams = new URLSearchParams(location.search);
      queryParams.set("user-name", userDetails?.userData?.user_name);

      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    }
  }, [location?.pathname, userDetails]);

  const dashboardNavigate = () => {
    if (isLoggedIn) {
      navigate("/dashboard");
    } else {
      navigate("/sign-up");
    }
  };

  return {
    navigate,
    dashboardNavigate,
  };
};
