import clsx from "clsx";
import { defaultUser } from "../../constants/imageData";
import { ILSkeleton } from "../ILSkeleton";
import styles from "./ILLiveProfileDetailsCard.module.css";

export const ILLiveProfileDetailsCard = ({ influencerDetails, isLoading }) => {
  return (
    <>
      <section className="live-profile-bg-cover bg-cover-layer bg-cover-layer-priamry-gradient position-relative z-1">
        <div className="container">
          <div className={clsx(styles.liveProfileDetailsWrap)}>
            <div
              className={clsx(
                styles.livProfileInnerCard,
                "position-relative z-1"
              )}
            >
              <div
                className={clsx(
                  styles.liveProfileAvatarCard,
                  "rounded-circle mx-auto"
                )}
              >
                <img
                  src={
                    isLoading ? defaultUser : influencerDetails?.profile_image
                  }
                  alt="advertising agency | ilonsi"
                  className="w-100 h-100 object-fit-cover rounded-circle bg-clr-cold-morning"
                />
              </div>

              <div className={clsx(styles.liveProfileContentCard)}>
                {isLoading ? (
                  <ILSkeleton width="200px" />
                ) : (
                  <h3 className="sm-secondary-title mb-3">
                    {influencerDetails?.name}
                  </h3>
                )}
                {isLoading ? (
                  <ILSkeleton width="100px" />
                ) : (
                  <p className="big mb-0 px-5 mx-5 px-xl-0 mx-xl-0">
                    {influencerDetails?.bio !== null
                      ? influencerDetails?.bio
                      : ""}
                  </p>
                )}
                <div className="d-flex gap-5 mt-5 align-items-center justify-content-center">
                  {isLoading ? (
                    <ILSkeleton width="28px" height="28px" />
                  ) : (
                    <>
                      {influencerDetails?.channel_links?.map((data) => {
                        return (
                          <a
                            href={data?.media_link}
                            target={"_blank"}
                            className=" d-block icon-card-sm"
                          >
                            <img
                              src={data?.channels?.image_2}
                              alt="advertising agency | ilonsi"
                              className="w-100 h-100"
                            />
                          </a>
                        );
                      })}
                    </>
                  )}
                  {/* <a
                    href="https://www.instagram.com/accounts/login/?hl=en"
                    target={"_blank"}
                    className=" d-block icon-card-sm"
                  >
                    <img
                      src={instagramIcon}
                      alt="advertising agency | ilonsi"
                      className="w-100 h-100"
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/"
                    target={"_blank"}
                    className="d-block icon-card-sm"
                  >
                    <img
                      src={facebookIcon}
                      alt="advertising agency | ilonsi"
                      className="w-100 h-100"
                    />
                  </a>
                  <a
                    href="https://www.tiktok.com/login"
                    target={"_blank"}
                    className="d-block icon-card-sm"
                  >
                    <img
                      src={tiktokLogo}
                      alt="advertising agency | ilonsi"
                      className="w-100 h-100"
                    />
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
