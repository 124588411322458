import { io } from "socket.io-client";
import { appConfig } from "../config";

const URL = appConfig?.SOCKET_URL;

export const socket = io(URL, {
  path: `/socket.io`,
  // reconnection: true,
  // reconnectionDelay: 500,
  // reconnectionAttempts: 3,
  // transports: ["websocket"],
}).connect();
