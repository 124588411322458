import { Tooltip } from "antd";
import clsx from "clsx";
import React from "react";
import { isEmpty } from "../../services";
import { ILButton } from "../ILButton";
import styles from "./ILProductCardCheckbox.module.css";

const ILProductCardCheckbox = ({
  item,
  name,
  index,
  noButtons,
  selectedData,
  noProductLogo,
  handleSetProduct,
  productCardClassname,
  // is disabled card
  isDisabledCard,
  ...rest
}) => {
  return (
    <>
      <div
        key={index}
        className={clsx(isDisabledCard && "cursor-no-drop", "h-100")}
      >
        <input
          className={clsx(
            // is disabled card
            isDisabledCard ? styles.isDisabledCardStyle : "",
            styles.checkboxInput
          )}
          type="checkbox"
          name={name}
          checked={selectedData?.id === item?.id}
          id={item.id}
          {...rest}
        />
        <label
          className={clsx(styles.checkboxLabel, "h-100 custom-checkbox-label")}
          htmlFor={item.id}
          onClick={() => handleSetProduct(item)}
        >
          <div
            className={clsx(
              styles.productCard,
              productCardClassname,
              "w-100 position-relative bg-white"
            )}
            key={index}
          >
            <div>
              <div className={clsx(styles.productImg, "")}>
                <img
                  src={item.image}
                  alt="advertising agency | ilonsi"
                  className="object-fit-contain object-position-center w-100 h-100 aspect-ratio-3-2"
                />
              </div>
              <div className="w-100">
                {noProductLogo ? (
                  <></>
                ) : (
                  <>
                    <div
                      className={clsx(
                        styles.productLogoWrap,
                        "d-flex align-items-center gap-4 gap-xxl-3"
                      )}
                    >
                      <div
                        className={clsx(
                          styles.productLogoImgBox,
                          "flex-0-auto ratio ratio-1x1 brand-logo me-0 border-0"
                        )}
                      >
                        <img
                          src={item?.brand?.profile_image}
                          alt="advertising agency | ilonsi"
                          className="object-fit-cover w-100 h-100 max-w-100"
                        />
                      </div>
                      <h6 className="p big fw-semibold text-dark-purple mb-0 text-ellipsis line-clamp-2 word-break-word">
                        {item?.brand?.name}
                      </h6>
                    </div>
                  </>
                )}
                <h4 className="h6 fw-semibold text-dark-purple text-ellipsis line-clamp-3">
                  {item.name}
                </h4>
                <p className="paragraph-sm-text text-ellipsis line-clamp-2">
                  {item.description === ("" || null || "null")
                    ? ""
                    : item.description}
                </p>
              </div>
            </div>
            <div className="mt-auto">
              <h5 className="p big fw-medium text-gray">Price</h5>
              <h4 className="h6 fw-medium text-dark-purple">{item.price} KR</h4>
              {!noButtons && (
                <div
                  className={clsx(
                    styles.productBtnWrap,
                    "d-flex align-items-center gap-4"
                  )}
                >
                  <ILButton
                    variant={"gradient-bordered"}
                    isSmallSizeBtn
                    ILBtnClass="min-w-auto line-height-normal font-semibold w-100"
                  >
                    Kopiera länk
                  </ILButton>
                  <ILButton
                    variant={"primary-gradient"}
                    isSmallSizeBtn
                    ILBtnClass="min-w-auto line-height-normal font-semibold w-100"
                  >
                    Lägg till i butik
                  </ILButton>
                </div>
              )}
            </div>
            <div>
              <div
                className={clsx(
                  styles.productLabelWrap,
                  "position-absolute bg-white d-flex gap-2 flex-column",
                  { ["flex-column gap-1"]: item.productLabelSellText }
                )}
              >
                <Tooltip
                  overlayClassName={styles.badgeTooltip}
                  color={"white"}
                  placement="bottomRight"
                  title={
                    <>
                      <p className="big fw-semibold text-dark-purple mb-2">
                        Vad är CPC?
                      </p>
                      <p className="text-gray mb-0">
                        CPC står för Cost-Per-Click, vilket är en prismodell
                        inom onlineannonsering där annonsörer betalar en avgift
                        varje gång någon klickar på deras annons.
                      </p>
                    </>
                  }
                >
                  <h6 className="fw-semibold p xs mb-0 cursor-pointer">
                    PPC&nbsp;<b className="px-1">:</b>&nbsp;
                    {item?.ppc_price || item?.ppc_price} KR
                  </h6>
                </Tooltip>
                {!isEmpty(item?.sell_commission) && (
                  <Tooltip
                    overlayClassName={styles.badgeTooltip}
                    color={"white"}
                    placement="bottomRight"
                    title={
                      <>
                        <p className="big fw-semibold text-dark-purple mb-2">
                          Försäljning
                        </p>
                        <p className="text-gray mb-0">
                          Per försäljningsprovision
                        </p>
                      </>
                    }
                  >
                    <h6 className="fw-semibold p xs mb-0 cursor-pointer">
                      Provision&nbsp;<b className="px-1">:</b>&nbsp;
                      {item?.sell_commission + " %"}
                    </h6>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </label>
      </div>
    </>
  );
};

export default ILProductCardCheckbox;
