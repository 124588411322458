import { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { capitalize, debounce } from "lodash";
import { toast } from "react-toastify";
import { Delete02Icon } from "hugeicons-react";
import { getDecryptionString, isEmpty } from "../../../services";
import { Link, useNavigate } from "react-router-dom";
import { doFetchAllProducts, doFetchDeleteProduct } from "../../../actions";
import { ILSocialShareMedia } from "../../../components";
import { appConfig } from "../../../config";
import copy from "copy-to-clipboard";
import { useSelector } from "react-redux";

export const useProductViewAllListHook = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isProductDeleteLoading, setIsProductDeleteLoading] = useState(false);
  const [productId, setProductId] = useState("");
  const [productListData, setProductListData] = useState({});
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isSuccessfullyDeleteModalOpen, setIsSuccessfullyDeleteModalOpen] =
    useState(false);
  const [paginationData, setPaginationData] = useState({
    search: "",
    pageSize: 5,
    page: 1,
  });
  const [selectedSocialMedia, setSelectedSocialMedia] = useState({});
  const [isShareSMModalOpen, setIsShareSMModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const { userDetails } = useSelector(
    (state) => state.storeAuthenticateReducer
  );

  useEffect(() => {
    fetchAllProductList(paginationData);
  }, [paginationData?.page, paginationData?.pageSize]);

  const showShareSMModal = () => {
    setIsShareSMModalOpen(true);
  };
  const handleShareSMModalClose = () => {
    setIsShareSMModalOpen(false);
    setSelectedSocialMedia({});
  };

  const generateSharableLink = () => {
    window.open(
      `${selectedSocialMedia?.link}${appConfig.APP_URL}${selectedData?.data?.product?.short_code}`,
      "_blank"
    );
    setSelectedSocialMedia({});
    setIsShareSMModalOpen(false);
  };

  const shareSocialMediaHandler = () => {
    setIsShareSMModalOpen(false);
    generateSharableLink();
  };

  const handleInputChange = (event) => {
    const { value, name } = event?.target;
    setPaginationData((prevState) => ({ ...prevState, [name]: value }));
    debounceProductSearchHandler({
      ...paginationData,
      [name]: value,
      page: 1,
    });
  };

  const debounceProductSearchHandler = useCallback(
    debounce(async (data) => {
      await fetchAllProductList(data);
    }, 1000),
    []
  );

  const handleSelectSocialMedia = (data) => {
    setSelectedSocialMedia(data);
  };

  const handleCopyLink = () => {
    copy(`${appConfig.APP_URL}${selectedData?.data?.product?.short_code}`);
    toast.success("Länk kopierad");
  };

  const showDeleteConfirmModal = (id) => {
    setProductId(id);
    setIsDeleteModal(!isDeleteModal);
  };

  const handleDeleteConfirmModalCancel = () => {
    setIsDeleteModal(false);
  };

  const showSuccessfullyDeleteModal = () => {
    handleDeleteProductSubmit();
  };

  const handleSuccessfullyDeleteModalOk = () => {
    setIsSuccessfullyDeleteModalOpen(false);
    setProductId("");
  };

  const handleSuccessfullyDeleteModalCancel = () => {
    setIsSuccessfullyDeleteModalOpen(false);
  };

  const handleTableChange = (event, filter, sort) => {
    try {
      setPaginationData((prev) => ({
        ...prev,
        page: event?.current,
        pageSize: event?.pageSize,
      }));
    } catch (error) {
      console.error("Error occurred during pagination:", error);
    }
  };

  const productColumns = useMemo(() => {
    return [
      {
        title: "Produkter",
        dataIndex: "products",
        key: "products",
        render: (_, item) => (
          <div className="d-flex align-items-center gap-4">
            <div
              className={clsx(
                "ratio ratio-1x1 product-service-img flex-shrink-0 bg-clr-black-gray"
              )}
            >
              <Link
                onClick={() =>
                  navigate(
                    `/dashboard/my-shop/product-details/${item?.product?.id}`
                  )
                }
              >
                <img
                  className={"object-fit-cover h-100 w-100"}
                  src={item?.product?.image}
                  alt={item?.product?.name}
                />
              </Link>
            </div>
            <div
              onClick={() =>
                navigate(
                  `/dashboard/my-shop/product-details/${item?.product?.id}`
                )
              }
            >
              <Link className="text-black cursor-pointer">
                <p className="fw-medium mb-0">
                  <Link className="text-dark-purple text-ellipsis line-clamp-1 word-break-word">
                    {capitalize(item?.product?.name)}
                  </Link>
                </p>
                <p className="mb-0 text-gray text-ellipsis line-clamp-1 word-break-word">
                  {item?.product?.description !== ("" || null || "null") &&
                    capitalize(item?.product?.description)}
                </p>
                <p className="fw-medium mb-0 gradient-text">
                  {item?.product?.price} KR
                </p>
              </Link>
            </div>
          </div>
        ),
        width: "300px",
      },
      {
        title: "Företag",
        dataIndex: "brand",
        key: "brand",
        render: (_, item) => (
          <>
            <div className="d-flex align-items-center gap-3">
              <div className="table-logo-img flex-shrink-0 ratio ratio-1x1">
                <img
                  width={"100%"}
                  height={"100%"}
                  className={"object-fit-cover radius-inherit"}
                  src={item?.product?.brand?.profile_image}
                  alt={item?.product?.brand?.name}
                />
              </div>
              <p className="fw-medium mb-0">{item?.product?.brand?.name}</p>
            </div>
          </>
        ),
        width: "350px",
      },
      {
        title: "Klicks",
        dataIndex: "clicks",
        key: "clicks",
        width: "120px",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {item?.product?.clicks}
          </p>
        ),
      },
      {
        title: "CPC",
        dataIndex: "ppc",
        key: "ppc",
        width: "120px",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {item?.product?.ppc_price} KR
          </p>
        ),
      },
      {
        title: "Försäljning",
        dataIndex: "sell",
        key: "sell",
        width: "120px",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {item?.product?.sells}
          </p>
        ),
      },
      {
        title: "Provision %",
        dataIndex: "sale",
        key: "sale",
        width: "120px",
        render: (_, item) => (
          <p className="fw-medium gradient-text max-w-max-content">
            {isEmpty(item?.product?.sell_commission)
              ? "-"
              : item?.product?.sell_commission + " %"}
          </p>
        ),
      },
      {
        title: "Redigera",
        dataIndex: "action",
        key: "action",
        fixed: "right",
        width: "100px",
        render: (_, item) => (
          <>
            <div
              className="d-flex align-items-center gap-5 action-btns-wrap cursor-pointer"
              onClick={() => setSelectedData({ data: item })}
            >
              <span className="w-50 text-center">
                <ILSocialShareMedia showShareSMModal={showShareSMModal} />
              </span>
              <span className="w-50 text-center">
                <Delete02Icon
                  size={20}
                  color={"#ED4F32"}
                  variant={"stroke"}
                  onClick={() => showDeleteConfirmModal(item?.id)}
                />
              </span>
            </div>
          </>
        ),
      },
    ];
  }, [productListData?.rows, userDetails]);

  const fetchAllProductList = async (data) => {
    try {
      setIsLoading(true);

      const productListResponse = await doFetchAllProducts(
        data?.pageSize,
        data?.page,
        data?.search
      );

      if (productListResponse?.status === 200) {
        const decryptedProductList = await JSON.parse(
          getDecryptionString(productListResponse?.data?.data)
        );
        setProductListData(decryptedProductList);
      }
      setIsLoading(false);
      setIsProductDeleteLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsProductDeleteLoading(false);
      console.error("Error occurred during fetching all products:", error);
    }
  };

  const handleDeleteProductSubmit = async () => {
    try {
      setIsProductDeleteLoading(true);
      const productDeleteResponse = await doFetchDeleteProduct(productId);

      if (productDeleteResponse?.status === 200) {
        setIsDeleteModal(false);
        setIsSuccessfullyDeleteModalOpen(true);
        fetchAllProductList(paginationData);
        setPaginationData({ page: 1 });
      } else {
        toast.error(productDeleteResponse?.data?.message);
      }
    } catch (error) {
      setIsProductDeleteLoading(false);
      console.error("Error occurred during deleting product:", error);
    }
  };

  return {
    isLoading,
    productId,
    selectedData,
    isDeleteModal,
    productColumns,
    paginationData,
    productListData,
    isShareSMModalOpen,
    selectedSocialMedia,
    isProductDeleteLoading,
    isSuccessfullyDeleteModalOpen,
    handleCopyLink,
    handleTableChange,
    handleInputChange,
    showDeleteConfirmModal,
    shareSocialMediaHandler,
    handleSelectSocialMedia,
    handleShareSMModalClose,
    showSuccessfullyDeleteModal,
    handleDeleteConfirmModalCancel,
    handleSuccessfullyDeleteModalOk,
    handleSuccessfullyDeleteModalCancel,
  };
};
