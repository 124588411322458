import { Form } from "antd";
import { authBannerOne } from "../../../constants/imageData";
import { ArrowLeft02Icon } from "hugeicons-react";
import { Link } from "react-router-dom";
import { ILButton, ILHead, ILLoader, ILTextInput } from "../../../components";
import { useOtpVerify } from "../../../hooks/auth/verifyOtp.hook";

export const ILEmailVerification = () => {
  const {
    time,
    otpData,
    isLoading,
    influencerEmail,
    resendOtpView,
    validateMessages,
    resendOtpCallback,
    handleVerifyOtpSubmit,
    handleOtpSelectChange,
  } = useOtpVerify();

  return (
    <>
      <ILHead title="E-postverifiering" />
      <section className="authentication-section">
        <div className="authentication-flex d-flex flex-wrap">
          <div className="authentication-col d-flex overflow-y-auto order-2 order-lg-1 py-5 py-xl-4">
            <div className="m-auto authentication-sm-box">
              <Link to="/login" className="auth-back-arrow d-inline-flex">
                <ArrowLeft02Icon
                  size={35}
                  color={"#1D1128"}
                  variant={"stroke"}
                  strokeWidth={1.5}
                  className="svg-hover"
                />
              </Link>
              <h1 className="secondary-title md-secondary-title">
                E-postverifiering
              </h1>
              <p className="paragraph-sm-text paragraph-md-text">
                Vänligen ange den 6-siffriga verifieringskoden vi skickade till{" "}
                <a
                  href="mailto:JohnSmith@gmail.com"
                  className="text-dark-purple fw-medium"
                >
                  {influencerEmail}
                </a>
              </p>
              <Form>
                <ILTextInput
                  isRequiredOtp
                  isOTPInput
                  otpLength={6}
                  name="otp"
                  value={otpData?.otp}
                  onChangeOtpInput={(event) =>
                    handleOtpSelectChange(event, "otp")
                  }
                  errorMsg={validateMessages?.otp}
                />
                <ILButton
                  disabled={isLoading}
                  handleClick={handleVerifyOtpSubmit}
                  ILBtnClass="w-100"
                  variant={"primary-gradient"}
                >
                  {isLoading ? <ILLoader isBtn /> : "Bekräfta"}
                </ILButton>
                <p className="p sm fw-medium text-gray text-center">
                  Fick du inte någon kod?&nbsp;&nbsp;
                  <Link
                    {...(resendOtpView
                      ? { onClick: resendOtpCallback }
                      : { to: "" })}
                    disabled={resendOtpView ? false : true}
                  >
                    {resendOtpView
                      ? "Skicka kod igen"
                      : `Resend in ${time} sec`}
                  </Link>
                </p>
              </Form>
            </div>
          </div>
          <div className="authentication-col bg-dark authentication-right-col order-1 order-lg-2 mb-5 mb-lg-0">
            <img
              src={authBannerOne}
              alt="advertising agency | ilonsi"
              className="w-100 h-100 object-fit-cover"
            />
          </div>
        </div>
      </section>
    </>
  );
};
