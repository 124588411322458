export const GetCroppedImg = (imageSrc, croppedAreaPixels, rotation = 0) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = imageSrc;

    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const maxSize = Math.max(image.width, image.height);
      canvas.width = maxSize;
      canvas.height = maxSize;

      // Fill the canvas with a white background
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Move the canvas' origin to the center of the image
      ctx.translate(maxSize / 2, maxSize / 2);
      ctx.rotate((rotation * Math.PI) / 180);
      ctx.translate(-maxSize / 2, -maxSize / 2);

      // Draw the image onto the canvas
      ctx.drawImage(
        image,
        0,
        0,
        image.width,
        image.height,
        maxSize / 2 - image.width / 2,
        maxSize / 2 - image.height / 2,
        image.width,
        image.height
      );

      // Crop the desired area from the rotated canvas
      const croppedCanvas = document.createElement("canvas");
      croppedCanvas.width = croppedAreaPixels.width;
      croppedCanvas.height = croppedAreaPixels.height;
      const croppedCtx = croppedCanvas.getContext("2d");

      // Fill the cropped canvas with a white background
      croppedCtx.fillStyle = "white";
      croppedCtx.fillRect(0, 0, croppedCanvas.width, croppedCanvas.height);

      croppedCtx.drawImage(
        canvas,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height
      );

      croppedCanvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        const croppedUrl = URL.createObjectURL(blob);
        resolve(croppedUrl);
      }, "image/jpeg");
    };

    image.onerror = () => reject(new Error("Failed to load the image"));
  });
};
