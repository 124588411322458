import { Select } from "antd";
import { clsx } from "clsx";
import styles from "./ILSelect.module.css";

export const ILSelect = ({
  label,
  ILSelectLabelClass,
  id,
  datatestid,
  defaultValue,
  options,
  isPrefixIcon,
  isRequired,
  handleChange,
  errorMsg,
  name,
  value,
  placeholder,
  handleSearch,
  showSearch = false,
  isOpen,
  isSelectInputSmall,
  ...rest
}) => {
  return (
    <>
      <label
        className={clsx(styles.ILSelectLabel, ILSelectLabelClass, "w-100")}
        htmlFor={id}
      >
        {label && (
          <span
            className={clsx(
              styles.ILSelectLabelText,
              "fw-medium text-dark-purple d-flex gap-2 gap-xxl-1"
            )}
          >
            {label}
            {isRequired && <span className="text-tomato">*</span>}
          </span>
        )}
        <div className="position-relative">
          <Select
            id={id}
            datatestid={datatestid}
            defaultValue={defaultValue}
            onChange={handleChange}
            name={name}
            value={value}
            placeholder={placeholder}
            className={clsx(
              styles.ILSelectInput,
              isSelectInputSmall && styles.ILSelectInputSmall,
              isPrefixIcon && "select-prefix-input",
              "w-100"
            )}
            options={options}
            open={isOpen}
            onSearch={handleSearch}
            {...(showSearch && {
              showSearch,
            })}
            {...rest}
          />

          {errorMsg && (
            <span className="p sm mt-3 lh-1 text-tomato fw-medium d-block">
              {errorMsg}
            </span>
          )}

          {isPrefixIcon && (
            <span className={clsx(styles.ILPrefixIcon, "position-absolute")}>
              {isPrefixIcon}
            </span>
          )}
        </div>
      </label>
    </>
  );
};
