import { ILButton } from "../../../components";
import { Icons } from "../../../constants/icons";

export const ILAccountCreatedModal = ({ navigate }) => {
  return (
    <>
      <div className="text-center d-flex flex-column gap-5 gap-xxl-4">
        <span className="lh-0">{Icons.circleRoundIcon}</span>
        <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
          Grattis! Ditt konto har skapats
        </h4>
        <p className="paragraph-sm-text paragraph-md-text text-center mb-0">
          Din registrering är klar, klicka på Dashboard
          <br />
          för att komma igång.
        </p>
        <ILButton
          variant={"primary-gradient"}
          handleClick={() => navigate("/dashboard")}
        >
          Dashboard
        </ILButton>
      </div>
    </>
  );
};
