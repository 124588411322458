import copy from "copy-to-clipboard";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  doFetchAddCollection,
  doFetchAddProduct,
  doFetchAddProductsToCollection,
  doFetchAddService,
  doFetchAllCollections,
  doFetchBrandCollections,
  doFetchBrandDetails,
  doFetchBrandProducts,
  doFetchBrandServices,
  doFetchProductCategories,
  doFetchSendAccessRequest,
  doFetchServiceCategories,
} from "../../actions";
import { appConfig } from "../../config";
import { socialMediaSharingCardList } from "../../constants/data";
import { getDecryptionString } from "../../services";
import {
  validateCollectionAdd,
  validateExistingCollectionAdd,
  validateSubmitAddCollection,
  validateSubmitExistingAddCollection,
} from "../../validations";

export const useBrandsHook = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [categoryList, setCategoryList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [collectionList, setCollectionList] = useState([]);
  const [collectionListData, setCollectionListData] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [categoryType, setCategoryType] = useState({
    category: "product",
    categoryId: "",
  });
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("_token") ? true : false
  );
  const [isLoading, setIsLoading] = useState({
    brandList: false,
    productList: false,
    serviceList: false,
    collectionList: false,
    addToShop: false,
  });
  const [isAddedToShop, setIsAddedToShop] = useState(true);
  const [isCollectionAddLoading, setIsCollectionAddLoading] = useState(false);
  const [isAddToShopLoading, setIsAddToShopLoading] = useState(false);
  const [currentId, setCurrentId] = useState("copyLink");
  const [relatedProductList, setRelatedProductList] = useState([]);
  const [collectionData, setCollectionData] = useState({});
  const [productListData, setProductListData] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isPrevBtn, setPrevBtn] = useState(false);
  const [show, setShow] = useState(false);
  const socialMediaSharingCardListResults = showAll
    ? socialMediaSharingCardList
    : socialMediaSharingCardList.slice(0, 7);
  const [isNewCollection, setIsNewCollection] = useState(true);
  const [validateMessages, setValidateMessages] = useState({});
  const [isService, setIsService] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [paginationData, setPaginationData] = useState({
    search: "",
    pageSize: 10,
    page: 1,
  });
  const [brandPaginationData, setBrandPaginationData] = useState({
    search: "",
    pageSize: 10,
    page: 1,
  });
  const [deleteProductModalOpen, setDeleteProductModalOpen] = useState(false);
  const [addProduct, setAddProduct] = useState({});
  const [productData, setProductData] = useState({});
  const [isShopCollectionModalOpen, setIsShopCollectionModalOpen] =
    useState(false);
  const [isCollectionAddedModalOpen, setIsCollectionAddedModalOpen] =
    useState(false);
  const [
    isCollectionAlreadyAddedModalOpen,
    setIsCollectionAlreadyAddedModalOpen,
  ] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState({});
  const [selectedSocialMedia, setSelectedSocialMedia] = useState({});
  const [isProductSelected, setIsProductSelected] = useState(false);
  const [isRequestSentLoading, setIsRequestSentLoading] = useState(false);
  const [isSuccessfullyReqModalOpen, setIsSuccessfullyReqModalOpen] =
    useState(false);
  const [apiStatus, setApiStatus] = useState(false);
  const { userDetails } = useSelector(
    (state) => state.storeAuthenticateReducer
  );

  useEffect(() => {
    let queryParams = new URLSearchParams(location.search);
    userDetails &&
      queryParams.set("user-name", userDetails?.userData?.user_name);
    queryParams.set("res", apiStatus ? "true" : "false");

    navigate(`${location.pathname}?${queryParams.toString()}`, {
      replace: true,
    });
  }, [location?.pathname, userDetails, apiStatus]);

  useEffect(() => {
    localStorage.removeItem("categoryType");
    localStorage.removeItem("categoryId");
    localStorage.removeItem("brandId");
  }, []);

  useEffect(() => {
    fetchCategoryList();
  }, [categoryType?.category]);

  useEffect(() => {
    if (isLoggedIn && userDetails && Object?.keys(selectedData)?.length > 0) {
      fetchProducts();
    }
  }, [isLoggedIn, paginationData?.page, userDetails, selectedData]);

  useEffect(() => {
    if (currentId === "createCollection") {
      fetchCollectionList();
    }
  }, [currentId]);

  useEffect(() => {
    if (isLoggedIn) {
      if (userDetails?.userData?.id && isAddedToShop) {
        fetchFeaturedProducts();
        fetchFeaturedServices();
        fetchCollections();
      }
    } else {
      fetchFeaturedProducts();
      fetchFeaturedServices();
      fetchCollections();
    }
  }, [userDetails, isLoggedIn, isAddedToShop]);

  const showSCModal = (data) => {
    setSelectedCollection(data);
    setIsShopCollectionModalOpen(true);
  };

  const handleSCModalOk = () => {
    setIsShopCollectionModalOpen(false);
  };
  const handleSCModalCancel = () => {
    setIsShopCollectionModalOpen(false);
  };

  const handleCAModalOk = (name) => {
    if (name === "new") {
      setIsCollectionAddedModalOpen(!isCollectionAddedModalOpen);
    } else {
      setIsCollectionAlreadyAddedModalOpen(!isCollectionAlreadyAddedModalOpen);
    }
  };

  const myShopNavigation = () => {
    navigate("/dashboard/my-shop");
  };

  const handleAddToShopCollection = async () => {
    try {
      setIsAddToShopLoading(true);

      const requestBody = {
        brandCollectionId: selectedCollection?.id,
        categoryId: selectedCollection?.brand?.category_id,
      };

      const collectionAddResponse = await doFetchAddCollection(requestBody);

      if (collectionAddResponse?.status === 200) {
        setIsCollectionAddedModalOpen(true);
      } else if (collectionAddResponse?.status === 409) {
        setIsCollectionAlreadyAddedModalOpen(true);
      } else {
        toast.error(collectionAddResponse?.data?.message);
      }

      setIsShopCollectionModalOpen(false);
      fetchCollections();
      setIsAddToShopLoading(false);
    } catch (error) {
      console.log({ error });
      setIsAddToShopLoading(false);
    }
  };

  const loginHandler = () => {
    navigate("/login");
  };

  const handleProductDeleteModal = (data) => {
    setAddProduct(data);
    setDeleteProductModalOpen(true);
  };

  const handleProductDeleteModalCancel = () => {
    setDeleteProductModalOpen(false);
  };

  const handleRemoveProduct = () => {
    setDeleteProductModalOpen(false);
    const filteredProducts = productListData?.filter(
      (data) => data.id !== addProduct?.id
    );
    setProductListData(filteredProducts);
  };

  const handleProductDetail = (data) => {
    navigate(`/brands/product-details/${data?.id}`);
  };

  const handleServiceDetail = (data) => {
    navigate(`/brands/service-details/${data?.id}`);
  };

  const handleCollectionDetail = (data) => {
    navigate(`/brand-collections/${data?.id}`);
  };

  const handleTableChange = (event) => {
    try {
      setPaginationData((prev) => ({
        ...prev,
        page: event,
      }));
    } catch (error) {
      console.error("Error occurred during pagination:", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event?.target;

    if (name === "name") {
      const { errors } = validateCollectionAdd(name, value);
      setValidateMessages(errors);
      setCollectionData((prevState) => ({ ...prevState, [name]: value }));
    } else {
      if (name === "categoryId") {
        localStorage.setItem("categoryType", categoryType?.category);
        navigate(`/brands-list/${value}`);
      }

      setCategoryType((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSearchChange = (event) => {
    const { name, value } = event?.target;
    setBrandPaginationData((prevState) => ({ ...prevState, [name]: value }));
    debounceBrandsSearchHandler({
      ...brandPaginationData,
      [name]: value,
      page: 1,
    });
  };

  const debounceBrandsSearchHandler = useCallback(
    debounce(async (data) => {
      if (data?.search === "") {
        setBrandList([]);
      } else {
        await fetchBrandList(data);
      }
    }, 1000),
    []
  );

  const fetchBrandList = async (data) => {
    try {
      setIsLoading((prev) => ({ ...prev, brandList: true }));

      const requestBody = {
        pageSize: data?.pageSize,
        currentPage: data?.page,
        search: data?.search ? data?.search : null,
        isPaginate: true,
      };

      const brandsListResponse = await doFetchBrandDetails(requestBody);
      if (brandsListResponse?.status === 200) {
        const decryptedBrandDetails = await JSON.parse(
          getDecryptionString(brandsListResponse?.data?.data)
        );
        const brandDetailsData = decryptedBrandDetails?.rows?.map((data) => {
          return {
            value: data?.brandDetail?.id,
            label: data?.brandDetail?.name,
          };
        });

        setBrandList(brandDetailsData);
      } else {
        toast.error(brandsListResponse?.message);
      }
      setIsLoading((prev) => ({ ...prev, brandList: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prev) => ({ ...prev, brandList: false }));
    }
  };

  const handleSelectSearchChange = (value, name) => {
    navigate(`/search-results/${value}`);
  };

  const handleSelectChange = (value, name) => {
    if (name === "id") {
      const { errors } = validateExistingCollectionAdd(name, value);
      setValidateMessages(errors);
      setCollectionData((prevState) => ({ ...prevState, id: value }));
    } else {
      setCategoryType((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const fetchCategoryList = async () => {
    try {
      let categoryList;

      if (categoryType?.category === "product") {
        categoryList = await doFetchProductCategories();
      } else {
        categoryList = await doFetchServiceCategories();
      }
      if (categoryList?.status === 200) {
        setApiStatus(true);

        const decryptedCategoryList = await JSON.parse(
          getDecryptionString(categoryList?.data)
        );

        const categoryListData = decryptedCategoryList?.map((data) => {
          return {
            id: data?.id,
            labelSubtitle: data?.name,
            icon: data?.image,
          };
        });
        setCategoryList(categoryListData);
      } else {
        setApiStatus(false);
      }
    } catch (error) {
      console.error("Error occurred during fetching category list:", error);
    }
  };

  const fetchFeaturedProducts = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, productList: true }));

      const requestBody = {
        userId: isLoggedIn ? userDetails?.userData?.id : null,
      };

      const productListResponse = await doFetchBrandProducts(requestBody);

      if (productListResponse?.status === 200) {
        const decryptedProducts = await JSON.parse(
          getDecryptionString(productListResponse?.data)
        );
        setProductList(decryptedProducts);
      } else {
        toast.error(productListResponse?.message);
      }
      setIsAddedToShop(false);
      setIsLoading((prev) => ({ ...prev, productList: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prev) => ({ ...prev, productList: false }));
    }
  };

  const fetchFeaturedServices = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, serviceList: true }));

      const requestBody = {
        userId: userDetails ? userDetails?.userData?.id : null,
      };
      const serviceListResponse = await doFetchBrandServices(requestBody);

      if (serviceListResponse?.status === 200) {
        const decryptedService = await JSON.parse(
          getDecryptionString(serviceListResponse?.data)
        );
        setServiceList(decryptedService);
      } else {
        toast.error(serviceListResponse?.message);
      }
      setIsAddedToShop(false);
      setIsLoading((prev) => ({ ...prev, serviceList: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prev) => ({ ...prev, serviceList: false }));
    }
  };

  const fetchCollections = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, collectionList: true }));

      const requestBody = {
        userId: userDetails ? userDetails?.userData?.id : null,
      };

      const collectionProductsResponse = await doFetchBrandCollections(
        requestBody
      );

      if (collectionProductsResponse?.status === 200) {
        const decryptedCollectionProducts = await JSON.parse(
          getDecryptionString(collectionProductsResponse?.data)
        );
        setCollectionList(decryptedCollectionProducts);
      } else {
        toast.error(collectionProductsResponse?.message);
      }
      setIsAddedToShop(false);
      setIsLoading((prev) => ({ ...prev, collectionList: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prev) => ({ ...prev, collectionList: false }));
    }
  };

  const fetchCollectionList = async () => {
    try {
      setIsModalLoading(true);

      const collectionListResponse = await doFetchAllCollections();

      if (collectionListResponse?.status === 200) {
        const decryptedCollectionList = await JSON.parse(
          getDecryptionString(collectionListResponse?.data)
        );
        const collectionListData = decryptedCollectionList?.map((data) => {
          return {
            value: data?.id,
            label: data?.name,
          };
        });
        setCollectionListData(collectionListData);
      }
      setIsModalLoading(false);
    } catch (error) {
      console.error("Error occurred during fetching collection list:", error);
      setIsModalLoading(false);
    }
  };

  const handleSRModalClose = () => {
    setIsSuccessfullyReqModalOpen(false);
  };

  const sendProductAccessRequest = async (data) => {
    try {
      setIsRequestSentLoading(true);
      const requestBody = {
        type: "ACCESS_REQUEST",
        toUser: data?.brand?.id,
        productId: data?.id,
      };

      const accessRequestResponse = await doFetchSendAccessRequest(requestBody);

      if (accessRequestResponse?.status === 200) {
        setIsSuccessfullyReqModalOpen(true);
        fetchFeaturedProducts();
        fetchFeaturedServices();
      } else {
        toast.error(accessRequestResponse?.message);
      }
      setIsRequestSentLoading(false);
    } catch (error) {
      console.log({ error });
      setIsRequestSentLoading(false);
    }
  };

  const sendServiceAccessRequest = async (data) => {
    try {
      setIsRequestSentLoading(true);
      const requestBody = {
        type: "ACCESS_REQUEST",
        toUser: data?.brand?.id,
        serviceId: data?.id,
      };

      const accessRequestResponse = await doFetchSendAccessRequest(requestBody);

      if (accessRequestResponse?.status === 200) {
        setIsSuccessfullyReqModalOpen(true);
        fetchFeaturedProducts();
        fetchFeaturedServices();
      } else {
        toast.error(accessRequestResponse?.message);
      }
      setIsRequestSentLoading(false);
    } catch (error) {
      console.log({ error });
      setIsRequestSentLoading(false);
    }
  };

  const sendCollectionAccessRequest = async (data) => {
    try {
      setIsRequestSentLoading(true);
      const requestBody = {
        type: "ACCESS_REQUEST",
        toUser: data?.brand?.id,
        collectionId: data?.id,
      };

      const accessRequestResponse = await doFetchSendAccessRequest(requestBody);

      if (accessRequestResponse?.status === 200) {
        setIsSuccessfullyReqModalOpen(true);
        fetchCollections();
      } else {
        toast.error(accessRequestResponse?.message);
      }
      setIsRequestSentLoading(false);
    } catch (error) {
      console.log({ error });
      setIsRequestSentLoading(false);
    }
  };

  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  const handleCopyLink = (type) => {
    if (type === "selectProduct") {
      setProductListData((prevProductList) => [
        ...prevProductList,
        productData,
      ]);

      if (isNewCollection) {
        setCurrentId("addNewCollection");
      } else {
        setCurrentId("addExistingCollection");
      }
    } else {
      copy(`${appConfig.APP_URL}${selectedData?.short_code}`);
      toast.success("Länk kopierad");
    }
    setIsProductSelected(false);
  };

  const showModal = (data, typeService) => {
    setProductListData([data]);
    setShow(true);
    setIsService(typeService);
    setCurrentId("copyLink");
    setSelectedData(data);
    setPrevBtn(true);
  };

  const addToShopHandle = (data, typeService) => {
    Object?.keys(selectedData)?.length === 0 && setSelectedData(data);

    if (typeService) {
      setIsService(true);
      handleProductAddToShop(data, typeService);
    } else {
      setShow((prev) => true);
      setCurrentId(
        `${
          selectedData?.is_added_to_shop === 1
            ? "createCollection"
            : "addToShopAs"
        }`
      );
      setPrevBtn((prev) => true);
      setProductListData([data]);
    }
  };

  const handleSetProduct = (data) => {
    setProductData(data);
    setIsProductSelected(true);
  };

  const handleSubmitCollection = (event) => {
    try {
      if (event?.currentTarget?.id === "yourProductAdded") {
        const { errors } = validateSubmitExistingAddCollection(collectionData);
        setValidateMessages(errors);
        if (Object.keys(errors).length === 0) {
          handleCollectionCreateCallback(event);
        }
      } else {
        const { errors } = validateSubmitAddCollection(collectionData);
        setValidateMessages(errors);
        if (Object.keys(errors).length === 0) {
          handleCollectionCreateCallback(event);
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const handleCollectionCreateCallback = async () => {
    try {
      setIsCollectionAddLoading(true);
      const productListArr = [];

      productListData?.map((item, index) =>
        productListArr.push({ id: item?.id, brandId: item?.brand?.id })
      );

      const requestBody = {
        products: productListArr,
        ...(currentId === "addExistingCollection"
          ? {
              collectionId: collectionData?.id,
            }
          : { name: collectionData?.name }),
        categoryId: collectionData?.brand?.category_id,
      };

      const addCollectionResponse = await doFetchAddProductsToCollection(
        requestBody
      );

      if (addCollectionResponse?.status === 200) {
        if (currentId === "addExistingCollection") {
          setCurrentId("collectionUpdated");
        } else {
          setCurrentId("collectionCreated");
        }
      } else {
        toast.error(addCollectionResponse?.message);
      }
      setIsAddedToShop(true);
      setIsCollectionAddLoading(false);
    } catch (error) {
      console.log(error);
      setIsCollectionAddLoading(false);
    }
  };

  const handleAddMoreProducts = (type) => {
    if (type === "existingCollection") {
      setIsNewCollection(false);
    }
    setCurrentId("selectProduct");
    setProductData({});
    setIsProductSelected(false);
  };

  const fetchProducts = async () => {
    try {
      setIsModalLoading(true);

      let brandId = [];
      brandId.push(selectedData?.brand?.id);
      const requestBody = {
        brand: brandId,
        userId: userDetails ? userDetails?.userData?.id : null,
        hasAccess: true,
        pageSize: paginationData?.pageSize,
        currentPage: paginationData?.page,
      };

      const brandProductsResponse = await doFetchBrandProducts(requestBody);

      if (brandProductsResponse?.status === 200) {
        const decryptedBrandProducts = await JSON.parse(
          getDecryptionString(brandProductsResponse?.data)
        );
        const filteredProductData = decryptedBrandProducts?.rows?.filter(
          (prodData) => prodData?.id !== selectedData?.id
        );
        setRelatedProductList(() => ({
          ...decryptedBrandProducts,
          rows: filteredProductData,
        }));
      } else {
        toast.error(brandProductsResponse?.message);
      }
      setIsModalLoading(false);
    } catch (error) {
      console.log({ error });
      setIsModalLoading(false);
    }
  };

  const handleProductAddToShop = async (data = {}, typeService) => {
    try {
      setIsLoading((prevState) => ({ ...prevState, addToShop: true }));
      let addProductServiceResponse;

      const requestBody = {
        categoryId:
          selectedData?.brand?.category_id || data?.brand?.category_id,
        ...(isService || typeService
          ? {
              serviceId: data?.id,
            }
          : { productId: selectedData?.id }),
      };

      if (isService || typeService) {
        addProductServiceResponse = await doFetchAddService(requestBody);
      } else {
        addProductServiceResponse = await doFetchAddProduct(requestBody);
      }

      if (addProductServiceResponse?.status === 200) {
        setIsAddedToShop(true);

        setShow(true);
        setCurrentId("productAdded");

        if (isService || typeService) {
          fetchFeaturedServices();
        } else {
          fetchFeaturedProducts();
        }
      } else if (addProductServiceResponse?.status === 409) {
        setShow(true);
        setCurrentId("alreadyAdded");
      } else {
        toast.error(addProductServiceResponse?.data?.message);
        setShow(false);
        setPrevBtn(false);
      }

      setIsLoading((prevState) => ({ ...prevState, addToShop: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prevState) => ({ ...prevState, addToShop: false }));
    }
  };

  const handleModalClose = () => {
    setShow(false);
    setCurrentId("copyLink");
    setPrevBtn(false);
    setProductListData([]);
    setCollectionData({});
    setSelectedData({});
    setIsService(false);
    setSelectedSocialMedia({});
    setPaginationData((prevState) => ({ ...prevState, page: 1 }));
  };

  const handleSelectSocialMedia = (data) => {
    setSelectedSocialMedia(data);
  };

  const generateSharableLink = () => {
    window.open(
      `${selectedSocialMedia?.link}${appConfig.APP_URL}${selectedData?.short_code}`,
      "_blank"
    );
    handleModalClose();
  };

  return {
    show,
    showAll,
    brandList,
    currentId,
    isPrevBtn,
    isService,
    isLoading,
    isLoggedIn,
    productList,
    productData,
    serviceList,
    categoryList,
    selectedData,
    categoryType,
    paginationData,
    collectionData,
    isModalLoading,
    collectionList,
    productListData,
    isNewCollection,
    validateMessages,
    isProductSelected,
    collectionListData,
    isAddToShopLoading,
    relatedProductList,
    selectedCollection,
    selectedSocialMedia,
    brandPaginationData,
    isRequestSentLoading,
    isCollectionAddLoading,
    deleteProductModalOpen,
    isShopCollectionModalOpen,
    isCollectionAddedModalOpen,
    isSuccessfullyReqModalOpen,
    isCollectionAlreadyAddedModalOpen,
    socialMediaSharingCardListResults,
    setShow,
    navigate,
    showModal,
    setPrevBtn,
    showSCModal,
    loginHandler,
    setCurrentId,
    handleShowAll,
    handleCopyLink,
    setProductData,
    addToShopHandle,
    handleCAModalOk,
    handleSCModalOk,
    myShopNavigation,
    handleModalClose,
    handleSetProduct,
    handleInputChange,
    handleTableChange,
    handleSRModalClose,
    handleSelectChange,
    handleSearchChange,
    handleSCModalCancel,
    handleProductDetail,
    handleServiceDetail,
    handleRemoveProduct,
    generateSharableLink,
    handleAddMoreProducts,
    setSelectedSocialMedia,
    handleProductAddToShop,
    handleSubmitCollection,
    handleCollectionDetail,
    handleSelectSocialMedia,
    sendServiceAccessRequest,
    handleSelectSearchChange,
    sendProductAccessRequest,
    handleProductDeleteModal,
    handleAddToShopCollection,
    sendCollectionAccessRequest,
    handleProductDeleteModalCancel,
  };
};
