import { clsx } from "clsx";
import { ILCheckbox, ILTab } from "../../../../components";
import styles from "../ILSignUp.module.css";

export const ILInterests = ({
  productCategoryList,
  serviceCategoryList,
  handleInputChange,
  signUpData,
  validateMessages,
  interestsProduct,
  interestsService,
}) => {
  const selectYourInterestTabData = [
    {
      key: "1",
      label: "Produkter",
      children: (
        <>
          <div className={clsx(styles.interestsSection, "d-grid")}>
            <ILCheckbox
              iconImg
              checkboxList={productCategoryList}
              selectedIds={interestsProduct}
              name={"categoryId"}
              value={signUpData?.categoryId}
              handleChange={(e) => handleInputChange(e, "product")}
              errorMsg={validateMessages?.categoryId}
            />
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: "Tjänster",
      children: (
        <>
          <div className={clsx(styles.interestsSection, "d-grid")}>
            <ILCheckbox
              iconImg
              name={"categoryId"}
              checkboxList={serviceCategoryList}
              selectedIds={interestsService}
              value={signUpData?.categoryId}
              handleChange={(e) => handleInputChange(e, "service")}
              errorMsg={validateMessages?.categoryId}
            />
          </div>
        </>
      ),
    },
  ];
  // TAB START
  const onChange = (key) => {
    console.log(key);
  };
  // TAB END
  return (
    <>
      <h1 className="secondary-title md-secondary-title">
        Välj dina intressen
      </h1>
      <p className="paragraph-sm-text paragraph-md-text">
        Du kan välja flera intressen
      </p>
      <div>
        <ILTab
          defaultActiveKey="1"
          tabItem={selectYourInterestTabData}
          onChange={onChange}
          isSecondaryTabStyles
        />
      </div>
    </>
  );
};
