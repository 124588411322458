import clsx from "clsx";
import styles from "./NotFoundPage.module.css";
import { ILButton } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <div
        className={clsx(
          styles.errorMsg,
          "position-relative vh-100 d-flex justify-content-center align-items-center"
        )}
      >
        <h2>404</h2>
        <h2>404</h2>
        <h4 className="gradient-text mt-5 pt-5">Inga Hittades</h4>
        <div className="error-msg-btn position-absolute mt-4">
          {!location?.pathname?.includes("not-found") && (
            <ILButton
              variant={"primary-gradient"}
              ILBtnClass={"mt-5"}
              handleClick={() => navigate("/")}
            >
              Tillbaka till Hem
            </ILButton>
          )}
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
