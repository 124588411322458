import { Checkbox } from "antd";
import {
  sfPpcCheckbox,
  sfPriceProductCheckbox,
  sfProductTypeCheckbox,
} from "../../constants/data";

export const ILSearchFilterCheckbozList = ({
  filterPriceCheckbox,
  filterBrandCheckbox,
  filterCategoryCheckbox,
  filterProductTypeCheckbox,
  filterCountryCheckbox,
  ppcCheckbox,
  brandList,
  categoryList,
  countryList,
  handleChange,
  name,
  ppcSelectedIds = [],
  citySelectedIds = [],
  filterCitiesCheckbox,
  priceRangeSelectedIds,
  categorySelectedIds = [],
  countrySelectedIds = [],
  brandSelectedIds = [],
  botRef,
  citiesList,
}) => {
  // BASIC CHECKBOX JS START
  const onChangeCheckbox = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };
  return (
    <>
      <div className="d-flex flex-column gap-4">
        {filterPriceCheckbox && (
          <>
            {sfPriceProductCheckbox.map((sfProductItem, sfProductIndex) => {
              return (
                <Checkbox
                  className="basic-checkbox-style"
                  key={sfProductIndex}
                  name={name}
                  value={sfProductItem?.value}
                  onChange={handleChange}
                  checked={priceRangeSelectedIds?.includes(sfProductItem.value)}
                >
                  {sfProductItem.label}
                </Checkbox>
              );
            })}
          </>
        )}
        {filterCategoryCheckbox && (
          <>
            <div className="filter-accordion-content-card d-flex flex-column gap-4 small-track">
              {categoryList?.map((sfProductItem, sfProductIndex) => {
                return (
                  <Checkbox
                    onChange={handleChange}
                    className="basic-checkbox-style"
                    key={sfProductIndex}
                    name={name}
                    value={sfProductItem?.value}
                    checked={categorySelectedIds?.includes(sfProductItem.value)}
                  >
                    {sfProductItem.label}
                  </Checkbox>
                );
              })}
              {categoryList?.hasNext && (
                <div ref={botRef} className="bottom-0">
                  <br />
                </div>
              )}
            </div>
          </>
        )}

        {filterCitiesCheckbox && (
          <>
            <div className="filter-accordion-content-card d-flex flex-column gap-4 small-track">
              {citiesList?.map((sfProductItem, sfProductIndex) => {
                return (
                  <Checkbox
                    onChange={handleChange}
                    className="basic-checkbox-style"
                    key={sfProductIndex}
                    name={name}
                    value={sfProductItem?.value}
                    checked={citySelectedIds?.includes(sfProductItem.value)}
                  >
                    {sfProductItem.label}
                  </Checkbox>
                );
              })}
              {citiesList?.hasNext && (
                <div ref={botRef} className="bottom-0">
                  <br />
                </div>
              )}
            </div>
          </>
        )}

        {filterCountryCheckbox && (
          <>
            <div className="filter-accordion-content-card d-flex flex-column gap-4 small-track">
              {countryList?.map((sfProductItem, sfProductIndex) => {
                return (
                  <Checkbox
                    onChange={handleChange}
                    className="basic-checkbox-style"
                    key={sfProductIndex}
                    name={name}
                    value={sfProductItem?.id}
                    checked={countrySelectedIds?.includes(sfProductItem.id)}
                  >
                    {sfProductItem.label}
                  </Checkbox>
                );
              })}
              {countryList?.hasNext && (
                <div ref={botRef} className="bottom-0">
                  <br />
                </div>
              )}
            </div>
          </>
        )}

        {filterBrandCheckbox && (
          <>
            <div className="filter-accordion-content-card d-flex flex-column gap-4 small-track">
              {brandList?.map((sfProductItem, sfProductIndex) => {
                return (
                  <Checkbox
                    onChange={handleChange}
                    className="basic-checkbox-style"
                    key={sfProductIndex}
                    name={name}
                    value={sfProductItem?.value}
                    checked={brandSelectedIds?.includes(sfProductItem.value)}
                  >
                    {sfProductItem.label}
                  </Checkbox>
                );
              })}
              {brandList?.length === 0 && <p>Inga Företag Hittades</p>}
              {brandList?.hasNext && (
                <div ref={botRef} className="bottom-0">
                  <br />
                </div>
              )}
            </div>
          </>
        )}
        {filterProductTypeCheckbox && (
          <>
            {sfProductTypeCheckbox.map((sfProductItem, sfProductIndex) => {
              return (
                <Checkbox
                  onChange={onChangeCheckbox}
                  className="basic-checkbox-style"
                  key={sfProductIndex}
                >
                  {sfProductItem.label}
                </Checkbox>
              );
            })}
          </>
        )}

        {ppcCheckbox && (
          <>
            {sfPpcCheckbox.map((sfProductItem, sfProductIndex) => {
              return (
                <Checkbox
                  className="basic-checkbox-style"
                  key={sfProductIndex}
                  name={name}
                  value={sfProductItem?.value}
                  onChange={handleChange}
                  checked={ppcSelectedIds?.includes(sfProductItem.value)}
                >
                  {sfProductItem.label}
                </Checkbox>
              );
            })}
          </>
        )}
      </div>
    </>
  );
};
