import CryptoJS from "crypto-js";
import { appConfig } from "../config";

const getEncryptedString = (plainText) => {
  const ciphertext = CryptoJS.AES.encrypt(
    plainText,
    appConfig?.ENCRYPTION_SECRET_KEY
  ).toString();
  return ciphertext;
};

const getDecryptionString = (cipherText) => {
  try {
    const key = CryptoJS.enc.Hex.parse(appConfig?.CRYPTO_KEY);
    const iv = CryptoJS.enc.Hex.parse(appConfig?.CRYPTO_IV);

    const encryptedDataHex = CryptoJS.enc.Hex.parse(cipherText);

    // Convert encrypted data to CryptoJS format
    const encryptedData = CryptoJS.lib.CipherParams.create({
      ciphertext: encryptedDataHex,
    });

    const decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
    return decryptedText;
  } catch (error) {
    console.error("Error during decrypting data:", error.message);
    throw error;
  }
};

export { getDecryptionString, getEncryptedString };
