import React from "react";
import { Row, Col } from "antd";
import { ILButton, ILHead, ILInfoCard, ILQualityCard } from "../../components";
import {
  aboutMobileDevice,
  aboutUsHero,
  coolShape,
  dotsDesign,
  influencerAbout,
  influencerFour,
  influencerOne,
  influencerThree,
  influencerTwo,
} from "../../constants/imageData";
import { clsx } from "clsx";
import { Icons } from "../../constants/icons";
import { ILInfoCardList, ourStoryCardList } from "../../constants/data";
import styles from "./ILAboutUs.module.css";
import { useAboutUsHook } from "../../hooks/website/aboutUs.hook";
import { appConfig } from "../../config";

const ILAboutUs = () => {
  const { dashboardNavigate } = useAboutUsHook();

  return (
    <>
      <ILHead title="Om oss" />
      {/* HERO SECTION START */}
      <section
        className={clsx(
          "bg-dark-purple position-relative text-white hero-gradient-border min-vh-xl-100 inner-hero-gradient z-1",
          styles.aboutUsHero
        )}
      >
        <div className="position-relative inner-section-gradient">
          <div className="container">
            <div className="w-100 min-vh-xl-100 hero-section-space pb-0 d-flex flex-column flex-xl-row">
              <div className="align-self-center">
                <div
                  className={clsx(
                    styles.heroContentWrap,
                    "hero-content-wrap align-self-center pe-lg-5 text-center text-xl-start mt-5 pt-5 mt-xl-0 pt-xl-0 mb-5 pb-5 mb-xl-0 pb-xl-0"
                  )}
                >
                  <h1 className="primary-title primary-xl-title mb-5 mb-xl-3 text-center text-xl-start">
                    Om oss &#38; plattformen
                  </h1>
                  <p className="text-blue-gray big fw-medium mb-0 text-center text-xl-start line-height-normal">
                    ILONSI strävar efter att göra marknadsföring på sociala
                    medier både smidigare och mer kostnadseffektiv. Vårt mål är
                    att ge företag, oavsett storlek, land eller bransch,
                    möjlighet att enkelt samarbeta med influencers.
                  </p>
                  <ILButton
                    ILBtnClass={"section-xs-mt mx-auto mx-xl-0"}
                    variant={"primary-gradient"}
                    isArrow
                    handleClick={dashboardNavigate}
                  >
                    Skapa konto gratis
                  </ILButton>
                </div>
              </div>
              <div
                className={clsx(
                  styles.heroImg,
                  "hero-img d-flex mx-auto ms-xl-auto me-xl-0 align-self-xl-end mt-auto pt-4 mt-xl-0 pt-xl-0"
                )}
              >
                <div className={clsx(styles.aboutUsHeroImgWrap, "text-end")}>
                  <img
                    src={aboutUsHero}
                    alt="advertising agency | ilonsi"
                    className="w-100 h-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* HERO SECTION END */}

      {/* OUR STORY SECTION START */}
      <section className="section-md-pt">
        <div className="container">
          <h2 className="secondary-title text-capitalize mb-3">
            Om plattformen
          </h2>
          <p className="h6 fw-normal mb-0">
            Vi är en plattform utvecklad med användarna i fokus.
          </p>
          <div className={styles.ourStoryCardContainer}>
            <ILQualityCard ourStoryCardList={ourStoryCardList} />
          </div>
          <div className="section-md-mt">
            <Row className="align-items-center">
              <Col xs={24} lg={12}>
                <div className={clsx(styles.discoverPowerSocialProfile)}>
                  <div
                    className={clsx(
                      styles.discoverImgBox,
                      "position-relative w-100 mx-auto mx-md-0"
                    )}
                  >
                    <img
                      src={influencerAbout}
                      className="object-fit-contain object-position-center w-100 h-100 aspect-ratio-3-3 position-relative z-1"
                      alt="advertising agency | ilonsi"
                    />
                  </div>
                </div>
                <div
                  className={clsx(styles.discoverPowerImgContentWrap, "d-none")}
                >
                  {" "}
                  <img
                    width={"100%"}
                    height={"100%"}
                    className={styles.dotsDesign}
                    src={dotsDesign}
                    alt="advertising agency | ilonsi"
                  />
                  <img
                    className={styles.coolShape}
                    width={"100%"}
                    height={"100%"}
                    src={coolShape}
                    alt="advertising agency | ilonsi"
                  />
                  <div
                    className={clsx(
                      styles.discoverPowerImgContentCard,
                      styles.topRightCardPortion
                    )}
                  >
                    <div className={styles.discoverPowerImgContentCardIcon}>
                      {Icons.Mouse}
                    </div>
                    <div>
                      <p className="paragraph-sm-text mb-2">Benefits</p>
                      <p className="big mb-0 fw-semibold">
                        Influencer marketing
                      </p>
                    </div>
                  </div>
                  <div
                    className={clsx(
                      styles.discoverPowerImgContentCard,
                      styles.leftBottomCardPortion
                    )}
                  >
                    <div
                      className={clsx(
                        styles.discoverPowerImgContentCardIcon,
                        styles.discoverPowerImgContentCardGreenBg
                      )}
                    >
                      {Icons.Aim}
                    </div>
                    <div>
                      <p className="paragraph-sm-text mb-2 text-dark-purple">
                        Business
                      </p>
                      <p className="big mb-0 fw-semibold">
                        Influencer marketing
                      </p>
                    </div>
                  </div>
                  <div
                    className={clsx(
                      styles.influencerImgWrap,
                      styles.influencerOne,
                      "ratio ratio-1x1"
                    )}
                  >
                    <img
                      src={influencerOne}
                      alt="advertising agency | ilonsi"
                      width={"100%"}
                      height={"100%"}
                      className={"object-fit-cover"}
                    />
                  </div>
                  <div
                    className={clsx(
                      styles.influencerImgWrap,
                      styles.influencerTwo,
                      "ratio ratio-1x1"
                    )}
                  >
                    <img
                      src={influencerTwo}
                      alt="advertising agency | ilonsi"
                      width={"100%"}
                      height={"100%"}
                      className={"object-fit-cover"}
                    />
                  </div>
                  <div
                    className={clsx(
                      styles.influencerImgWrap,
                      styles.influencerThree,
                      "ratio ratio-1x1"
                    )}
                  >
                    <img
                      src={influencerThree}
                      alt="advertising agency | ilonsi"
                      width={"100%"}
                      height={"100%"}
                      className={"object-fit-cover"}
                    />
                  </div>
                  <div
                    className={clsx(
                      styles.influencerImgWrap,
                      styles.influencerFour,
                      "ratio ratio-1x1"
                    )}
                  >
                    <img
                      src={influencerFour}
                      alt="advertising agency | ilonsi"
                      width={"100%"}
                      height={"100%"}
                      className={"object-fit-cover"}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} lg={12}>
                <div
                  className={clsx(
                    styles.discoverPowerTextContentWrap,
                    "ms-lg-auto mt-5 pt-4 mt-lg-0 pt-lg-0 w-100"
                  )}
                >
                  <span className="secondary-title sm-secondary-title text-capitalize mb-4 d-block">
                    Upptäck fördelarna med ILONSI och samarbeten med
                    influensers!
                  </span>
                  <p className="paragraph-md-text text-gray mb-0 text-ellipsis line-clamp-5">
                    Samarbeta med tusentals influencers med ett klick och nå
                    hundratusentals konsumenter. Betala endast för de resultat
                    influencers genererar, exponering är alltid gratis. Med 98 %
                    av konsumenterna som planerar att handla via sociala medier
                    i år, är ILONSI din chans att utnyttja trenden!
                  </p>
                  <div className="section-xs-mt">
                    <ILButton
                      isArrow
                      ILBtnClass={"lh-0"}
                      variant={"primary-gradient"}
                      handleClick={dashboardNavigate}
                    >
                      Skapa konto gratis
                    </ILButton>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      {/* OUR STORY SECTION END */}

      {/* CREATING INDUSTRY SECTION START */}
      <section className="section-md-mt section-sm-py bg-offwhite-shade1">
        <div className="container">
          <span className="secondary-title text-center text-capitalize d-block mb-4">
            ILONSI familjen
          </span>
          <p
            className={clsx(
              styles.createIndustryDescription,
              "title-label mb-0 mx-auto"
            )}
          >
            Hundratals företag och tusentals influencers har redan anslutit sig
            till ILONSI-familjen.
          </p>
          <div className="section-xs-mt">
            <div
              className={clsx(styles.creatingIndustryCardsWrapper, "d-grid")}
            >
              {ILInfoCardList &&
                ILInfoCardList.map((item, index) => {
                  return (
                    <ILInfoCard
                      key={index}
                      cardImg={item.image}
                      cardTitle={item.title}
                      cardDescription={item.description}
                      ILInfoCardNumberInfoList={item.ILInfoCardNumberInfoList}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </section>
      {/* CREATING INDUSTRY SECTION END */}

      {/* MEET CEO SECTION START */}
      <section className="section-md-py">
        <div className="container">
          <Row className="align-items-center">
            <Col xs={24} xl={12}>
              <div className={styles.meetCEOTextContent}>
                <span className="secondary-title pb-5 d-block">Om oss</span>
                <p className="title-label text-start mb-0">
                  ILONSI är en startup med visionen att förenkla och
                  effektivisera samarbeten mellan influencers på alla sociala
                  medieplattformar och företag inom alla storlekar och
                  branscher. Vår målsättning är att skapa en plattform där det
                  är lika enkelt att samarbeta med influencers i Tyskland som
                  att marknadsföra ett inlägg på sociala medier. Genom att
                  erbjuda en smidig lösning vill vi hjälpa företag att nå ut
                  till nya kunder och bygga framgångsrika partnerskap – oavsett
                  geografisk placering eller influencerstorlek. Bli en del av
                  ILONSI-familjen och följ med på vår resa mot en enklare, mer
                  effektiv och global influencer-marknadsföring!
                </p>
              </div>
            </Col>
            <Col xs={24} xl={12} className="mt-5 pt-5 mt-xl-0 pt-xl-0">
              <div
                className={clsx(styles.ceoImg, "mx-xl-0 mx-auto ms-xl-auto")}
              >
                <img
                  className={
                    "object-fit-contain object-position-center w-100 h-100 aspect-ratio-3-2"
                  }
                  src={aboutMobileDevice}
                  alt="advertising agency | ilonsi"
                />
              </div>
            </Col>
          </Row>
        </div>
      </section>
      {/* MEET CEO SECTION END */}
    </>
  );
};

export default ILAboutUs;
