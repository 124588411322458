import { Col, Form, Row } from "antd";
import { ILTextInput, ILSelect } from "../../../../components";
import {
  Mail01Icon,
  SquareLockPasswordIcon,
  UserIcon,
  ViewIcon,
  ViewOffIcon,
} from "hugeicons-react";
import { genderSelectOption } from "../../../../constants/data";
import clsx from "clsx";
import styles from "./../ILSignUp.module.css";

export const ILRegistering = ({
  signUpData,
  isShowIcon,
  citiesList,
  countryList,
  countryCodeList,
  validateMessages,
  handleInputChange,
  handleSelectChange,
  handleIconOpenChange,
}) => {
  return (
    <>
      <h1 className="secondary-title md-secondary-title">
        Influencer Registrering
      </h1>
      <p className="paragraph-sm-text paragraph-md-text">
        För att fortsätta till ILONSI, vänligen ange följande uppgifter.
      </p>
      <Form>
        <Row gutter={20}>
          <Col xs={24} xl={12}>
            <ILTextInput
              isRequiredInput
              prefix={
                <UserIcon
                  size={24}
                  color={"#1d1128"}
                  variant={"stroke"}
                  strokeWidth={1.5}
                />
              }
              id="signUpFullName"
              datatestid="signUpFullName"
              type="text"
              label="Ange förnamn och efternamn"
              placeholder={"Ange förnamn och efternamn"}
              name="name"
              value={signUpData?.name}
              handleChange={handleInputChange}
              errorMsg={validateMessages?.name}
            />
          </Col>

          <Col xs={24} xl={12}>
            <ILTextInput
              isRequiredInput
              prefix={
                <UserIcon
                  size={24}
                  color={"#1d1128"}
                  variant={"stroke"}
                  strokeWidth={1.5}
                />
              }
              id="signUpUserName"
              datatestid="signUpUserName"
              type="text"
              label="Användarnamn"
              placeholder={"Ange ditt användarnamn"}
              name="userName"
              value={signUpData?.userName}
              handleChange={handleInputChange}
              errorMsg={validateMessages?.userName}
            />
          </Col>

          <Col xs={24} xl={12}>
            <ILTextInput
              isRequiredInput
              prefix={
                <Mail01Icon
                  size={24}
                  color={"#1d1128"}
                  variant={"stroke"}
                  strokeWidth={1.5}
                />
              }
              id="signUpEPostadress"
              datatestid="signUpEPostadress"
              type="email"
              label="E-postadress"
              placeholder={"Ange din e-postadress"}
              name="ePostAddress"
              value={signUpData?.ePostAddress}
              handleChange={handleInputChange}
              errorMsg={validateMessages?.ePostAddress}
            />
          </Col>

          <Col xs={24} xl={12}>
            <div className="w-100">
              <label className="fw-medium text-dark-purple d-block ILSelectLabelText">
                Telefonnummer
              </label>
              <div
                className={clsx(
                  styles.telefonnummerWrap,
                  "d-flex align-items-start gap-2"
                )}
              >
                <ILSelect
                  id="countrySelect2"
                  ILSelectLabelClass={clsx(styles.countryCodeSelect, "w-auto")}
                  name="countryCode"
                  value={signUpData?.countryCode}
                  options={countryCodeList}
                  handleChange={(event) =>
                    handleSelectChange(event, "countryCode")
                  }
                />
                <ILTextInput
                  id="basicInput"
                  // datatestid="basicInput"
                  type="number"
                  label=""
                  name="phoneNumber"
                  value={signUpData?.phoneNumber}
                  handleChange={handleInputChange}
                  placeholder={"Telefonnummer"}
                  errorMsg={validateMessages?.phoneNumber}
                />
              </div>
            </div>
          </Col>

          <Col xs={24} xl={12}>
            <ILTextInput
              isRequiredInput
              prefix={
                <SquareLockPasswordIcon
                  size={24}
                  color={"#1d1128"}
                  variant={"stroke"}
                  strokeWidth={1.5}
                />
              }
              suffix={
                isShowIcon ? (
                  <span
                    onClick={handleIconOpenChange}
                    className="cursor-pointer"
                  >
                    <ViewOffIcon
                      size={24}
                      color={"#1d1128"}
                      variant={"stroke"}
                      strokeWidth={1.5}
                    />
                  </span>
                ) : (
                  <span
                    onClick={handleIconOpenChange}
                    className="cursor-pointer"
                  >
                    <ViewIcon
                      size={24}
                      color={"#1d1128"}
                      variant={"stroke"}
                      strokeWidth={1.5}
                    />
                  </span>
                )
              }
              isSuffixIconPresent
              id="signUpPassword"
              datatestid="signUpPassword"
              type={isShowIcon ? "text" : "password"}
              label="Lösenord"
              placeholder={"Skapa ett lösenord"}
              name="password"
              value={signUpData?.password}
              handleChange={handleInputChange}
              errorMsg={validateMessages?.password}
            />
          </Col>

          <Col xs={24} xl={12}>
            <ILSelect
              isRequired
              label="Kön"
              id="signUpGender"
              datatestid="signUpGender"
              placeholder="Välj kön"
              options={genderSelectOption}
              name="gender"
              value={signUpData?.gender}
              handleChange={(event) => handleSelectChange(event, "gender")}
              errorMsg={validateMessages?.gender}
            />
          </Col>

          <Col xs={24} xl={12}>
            <ILSelect
              isRequired
              label="Land"
              defaultValue={"e434260b-cbfa-47f2-807c-d49116afe944"}
              id="signUpCountrySelect"
              datatestid="signUpCountrySelect"
              options={countryList}
              name="countryId"
              placeholder={"Välj Land"}
              value={signUpData?.countryId}
              handleChange={(event) => handleSelectChange(event, "countryId")}
              errorMsg={validateMessages?.countryId}
            />
          </Col>

          <Col xs={24} xl={12}>
            <ILSelect
              isRequired
              label="Stad"
              id="signUpCitySelect"
              datatestid="signUpCitySelect"
              options={citiesList}
              name="cityId"
              placeholder={"Välj stad"}
              value={signUpData?.cityId || null}
              handleChange={(event) => handleSelectChange(event, "cityId")}
              errorMsg={validateMessages?.cityId}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};
