import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { doFetchSubscriber } from "../../actions";
import {
  validateSubmitSubscribeEmail,
  validateSubscribeEmail,
} from "../../validations";

export const useBrandFooterHook = () => {
  const navigate = useNavigate();

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [validateMessages, setValidateMessages] = useState({});
  const [emailData, setEmailData] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event?.target;
    const { errors } = validateSubscribeEmail(name, value, validateMessages);
    setValidateMessages(errors);
    setEmailData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmitEmail = () => {
    try {
      const { errors } = validateSubmitSubscribeEmail(emailData);
      setValidateMessages(errors);

      if (Object.keys(errors).length === 0) {
        handleEmailSubmit();
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const handleEmailSubmit = async () => {
    try {
      setIsSubmitLoading(true);
      const subscriberResponse = await doFetchSubscriber({
        email: emailData?.email,
      });

      if (subscriberResponse?.status === 200) {
        toast.success(subscriberResponse?.message);
        setEmailData({});
      } else {
        toast.error(subscriberResponse?.message);
      }
      setIsSubmitLoading(false);
    } catch (error) {
      setIsSubmitLoading(false);
      console.log(error);
    }
  };

  return {
    emailData,
    isSubmitLoading,
    validateMessages,
    navigate,
    handleInputChange,
    handleSubmitEmail,
  };
};
