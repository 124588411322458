import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  doFetchAddCollection,
  doFetchBrandCollections,
  doFetchBrandProducts,
  doFetchBrandServices,
  doFetchSendAccessRequest,
  doFetchSingleBrandDetails,
} from "../../actions";
import { getDecryptionString } from "../../services";

export const useBrandProductsHook = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [brandData, setBrandData] = useState({});
  const [topSellProductList, setTopSellProductList] = useState([]);
  const [latestProductList, setLatestProductList] = useState([]);
  const [collectionList, setCollectionList] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("_token") ? true : false
  );
  const [apiStatus, setApiStatus] = useState(false);
  const [relatedProductList, setRelatedProductList] = useState([]);
  const [categoryId, setCategoryId] = useState(
    localStorage.getItem("categoryId")
  );
  const [categoryType, setCategoryType] = useState(
    localStorage.getItem("categoryType")
  );
  const [isLoading, setIsLoading] = useState({
    collectionAdd: false,
    brandDetail: false,
    topSell: false,
    latestProducts: false,
    getCollections: false,
  });
  const [isAddedToShop, setIsAddedToShop] = useState(true);
  const [isBtnLoading, setBtnLoading] = useState({
    sendAccessRequest: false,
    productServiceAddToShop: false,
    collectionAddToShop: false,
  });
  const [isModalLoading, setIsModalLoading] = useState({});
  const [paginationData, setPaginationData] = useState({
    search: "",
    pageSize: 10,
    page: 1,
  });
  const [isSuccessfullyReqModalOpen, setIsSuccessfullyReqModalOpen] =
    useState(false);
  const [isShopCollectionModalOpen, setIsShopCollectionModalOpen] =
    useState(false);
  const [isCollectionAddedModalOpen, setIsCollectionAddedModalOpen] =
    useState(false);
  const [
    isCollectionAlreadyAddedModalOpen,
    setIsCollectionAlreadyAddedModalOpen,
  ] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState({});
  const { userDetails } = useSelector(
    (state) => state.storeAuthenticateReducer
  );

  useEffect(() => {
    let queryParams = new URLSearchParams(location.search);
    userDetails &&
      queryParams.set("user-name", userDetails?.userData?.user_name);
    queryParams.set("res", apiStatus ? "true" : "false");

    navigate(`${location.pathname}?${queryParams.toString()}`, {
      replace: true,
    });
  }, [location?.pathname, userDetails, apiStatus]);

  useEffect(() => {
    if (params?.id) {
      fetchBrandDetails(params?.id);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      if (userDetails?.userData?.id) {
        fetchProducts(params?.id);
      }
    } else {
      fetchProducts(params?.id);
    }
  }, [userDetails, paginationData?.page]);

  useEffect(() => {
    if (isAddedToShop) {
      if (isLoggedIn) {
        if (!userDetails) {
          setIsLoading((prev) => ({
            ...prev,
            topSell: true,
            latestProducts: true,
            getCollections: true,
          }));
          return;
        }
        if (userDetails?.userData?.id) {
          fetchTopSellProducts();
          fetchLatestProducts();
          fetchCollections();
        }
      } else {
        fetchTopSellProducts();
        fetchLatestProducts();
        fetchCollections();
      }
    }
  }, [isLoggedIn, userDetails?.userData?.id, isAddedToShop]);

  const handleSRModalClose = () => {
    setIsSuccessfullyReqModalOpen(false);
  };

  const showSCModal = (data) => {
    setSelectedCollection(data);
    setIsShopCollectionModalOpen(true);
  };

  const handleSCModalOk = () => {
    setIsShopCollectionModalOpen(false);
  };
  const handleSCModalCancel = () => {
    setIsShopCollectionModalOpen(false);
  };

  const handleCAModalOk = (name) => {
    if (name === "new") {
      setIsCollectionAddedModalOpen(!isCollectionAddedModalOpen);
    } else {
      setIsCollectionAlreadyAddedModalOpen(!isCollectionAlreadyAddedModalOpen);
    }
  };

  const myShopNavigation = () => {
    navigate("/dashboard/my-shop");
  };

  const handleAddToShopCollection = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, collectionAdd: true }));

      const requestBody = {
        brandCollectionId: selectedCollection?.id,
        categoryId: selectedCollection?.brand?.category_id,
      };

      const collectionAddResponse = await doFetchAddCollection(requestBody);

      if (collectionAddResponse?.status === 200) {
        setIsCollectionAddedModalOpen(true);
      } else if (collectionAddResponse?.status === 409) {
        setIsCollectionAlreadyAddedModalOpen(true);
      } else {
        toast.error(collectionAddResponse?.data?.message);
      }

      setIsShopCollectionModalOpen(false);
      fetchCollections();
      setIsLoading((prev) => ({ ...prev, collectionAdd: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prev) => ({ ...prev, collectionAdd: false }));
    }
  };

  const loginHandler = () => {
    navigate("/login");
  };

  const handleTableChange = (event) => {
    try {
      setPaginationData((prev) => ({
        ...prev,
        page: event,
      }));
    } catch (error) {
      console.error("Error occurred during pagination:", error);
    }
  };

  const handleProductDetail = (data) => {
    if (categoryType === "product") {
      navigate(`/brands/product-details/${data?.id}`);
    } else {
      navigate(`/brands/service-details/${data?.id}`);
    }
  };

  const handleCollectionDetail = (data) => {
    navigate(`/brand-collections/${data?.id}`);
  };

  const fetchBrandDetails = async (id) => {
    try {
      setIsLoading((prev) => ({ ...prev, brandDetail: true }));

      const brandResponse = await doFetchSingleBrandDetails(id);
      if (brandResponse?.status === 200) {
        setApiStatus(true);

        const decryptedBrandDetails = await JSON.parse(
          getDecryptionString(brandResponse?.data)
        );

        setBrandData(decryptedBrandDetails);
      } else {
        setApiStatus(false);
        toast.error(brandResponse?.message);
      }
      setIsLoading((prev) => ({ ...prev, brandDetail: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prev) => ({ ...prev, brandDetail: false }));
    }
  };

  const fetchTopSellProducts = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, topSell: true }));

      let brandId = [],
        brandProductsResponse;
      brandId.push(params?.id);
      const requestBody = {
        sortByKey: "topSell",
        brand: brandId,
        userId: userDetails ? userDetails?.userData?.id : null,
      };
      if (categoryType === "product") {
        brandProductsResponse = await doFetchBrandProducts(requestBody);
      } else {
        brandProductsResponse = await doFetchBrandServices(requestBody);
      }

      if (brandProductsResponse?.status === 200) {
        const decryptedBrandProducts = await JSON.parse(
          getDecryptionString(brandProductsResponse?.data)
        );
        setTopSellProductList(decryptedBrandProducts);
      } else {
        toast.error(brandProductsResponse?.message);
      }
      setIsAddedToShop(false);
      setIsLoading((prev) => ({ ...prev, topSell: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prev) => ({ ...prev, topSell: false }));
    }
  };

  const fetchLatestProducts = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, latestProducts: true }));

      let brandId = [],
        brandProductsResponse;
      brandId.push(params?.id);
      const requestBody = {
        sortByKey: "latest",
        brand: brandId,
        userId: userDetails ? userDetails?.userData?.id : null,
      };

      if (categoryType === "product") {
        brandProductsResponse = await doFetchBrandProducts(requestBody);
      } else {
        brandProductsResponse = await doFetchBrandServices(requestBody);
      }

      if (brandProductsResponse?.status === 200) {
        const decryptedBrandProducts = await JSON.parse(
          getDecryptionString(brandProductsResponse?.data)
        );
        setLatestProductList(decryptedBrandProducts);
      } else {
        toast.error(brandProductsResponse?.message);
      }

      setIsAddedToShop(false);
      setIsLoading((prev) => ({ ...prev, latestProducts: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prev) => ({ ...prev, latestProducts: false }));
    }
  };

  const fetchCollections = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, getCollections: true }));

      let brandId = [];
      brandId.push(params?.id);
      const requestBody = {
        brand: brandId,
        userId: userDetails ? userDetails?.userData?.id : null,
      };

      const brandCollectionProductsResponse = await doFetchBrandCollections(
        requestBody
      );

      if (brandCollectionProductsResponse?.status === 200) {
        const decryptedBrandCollectionProducts = await JSON.parse(
          getDecryptionString(brandCollectionProductsResponse?.data)
        );
        setCollectionList(decryptedBrandCollectionProducts);
      } else {
        toast.error(brandCollectionProductsResponse?.message);
      }
      setIsLoading((prev) => ({ ...prev, getCollections: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prev) => ({ ...prev, getCollections: false }));
    }
  };

  const sendCollectionAccessRequest = async (data) => {
    try {
      setBtnLoading({ sendAccessRequest: true });

      const requestBody = {
        type: "ACCESS_REQUEST",
        toUser: data?.brand?.id,
        collectionId: data?.id,
      };

      const accessRequestResponse = await doFetchSendAccessRequest(requestBody);
      if (accessRequestResponse?.status === 200) {
        setIsSuccessfullyReqModalOpen(true);
        fetchCollections();
      } else {
        toast.error(accessRequestResponse?.data?.message);
      }
      setBtnLoading({ sendAccessRequest: false });
    } catch (error) {
      console.log({ error });
      setBtnLoading({ sendAccessRequest: false });
    }
  };

  const sendAccessRequest = async (data) => {
    try {
      setBtnLoading({ sendAccessRequest: true });
      const requestBody = {
        type: "ACCESS_REQUEST",
        toUser: data?.brand?.id,
        ...(categoryType === "product"
          ? { productId: data?.id }
          : { serviceId: data?.id }),
      };

      const accessRequestResponse = await doFetchSendAccessRequest(requestBody);

      if (accessRequestResponse?.status === 200) {
        setIsSuccessfullyReqModalOpen(true);
        fetchTopSellProducts();
        fetchLatestProducts();
      } else {
        toast.error(accessRequestResponse?.data?.message);
      }
      setBtnLoading({ sendAccessRequest: false });
    } catch (error) {
      console.log({ error });
      setBtnLoading({ sendAccessRequest: false });
    }
  };

  const fetchProducts = async (id) => {
    try {
      setIsModalLoading(true);

      let brandId = [];
      brandId.push(id);
      const requestBody = {
        brand: brandId,
        userId: userDetails ? userDetails?.userData?.id : null,
        hasAccess: true,
        currentPage: paginationData?.page,
        pageSize: paginationData?.pageSize,
      };

      const brandProductsResponse = await doFetchBrandProducts(requestBody);

      if (brandProductsResponse?.status === 200) {
        const decryptedBrandProducts = await JSON.parse(
          getDecryptionString(brandProductsResponse?.data)
        );

        setRelatedProductList(decryptedBrandProducts);
      } else {
        toast.error(brandProductsResponse?.message);
      }
      setIsModalLoading(false);
    } catch (error) {
      console.log({ error });
      setIsModalLoading(false);
    }
  };

  return {
    params,
    isLoading,
    brandData,
    isLoggedIn,
    categoryId,
    categoryType,
    isBtnLoading,
    paginationData,
    collectionList,
    isModalLoading,
    latestProductList,
    selectedCollection,
    relatedProductList,
    topSellProductList,
    isShopCollectionModalOpen,
    isSuccessfullyReqModalOpen,
    isCollectionAddedModalOpen,
    isCollectionAlreadyAddedModalOpen,
    navigate,
    showSCModal,
    loginHandler,
    handleCAModalOk,
    handleSCModalOk,
    setIsAddedToShop,
    myShopNavigation,
    sendAccessRequest,
    setPaginationData,
    handleTableChange,
    handleSRModalClose,
    handleSCModalCancel,
    handleProductDetail,
    handleCollectionDetail,
    handleAddToShopCollection,
    sendCollectionAccessRequest,
  };
};
