import React from "react";
import {
  ILButton,
  ILTextInput,
  ILDashboardCard,
  ILTable,
  ILDashboardGalleryCard,
  ILDashboardHeader,
  ILHead,
  ILModal,
  ILNoDataCard,
  ILSocialShareMedia,
  ILLoader,
  ILSkeleton,
} from "../../../components";
import {
  Copy01Icon,
  Delete02Icon,
  PlusSignIcon,
  Share08Icon,
} from "hugeicons-react";
import { eyeIcon } from "../../../constants/imageData";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useShopListHook } from "../../../hooks/dashboard/myShop/shopList.hook";
import { countHandler } from "../../../services";
import { appConfig } from "../../../config";
import { ILShareSocialMediaModal } from "../../../components/ILDashboardGalleryCard/ILShareSocialMediaModal";
import styles from "./ILMyShop.module.css";

const ILMyShop = () => {
  const {
    isLoading,
    userDetails,
    serviceList,
    productList,
    selectedData,
    productColumns,
    collectionList,
    serviceColumns,
    isShareSMModalOpen,
    isConfirmModalOpen,
    selectedSocialMedia,
    isSuccessfullyDeleteModalOpen,
    navigate,
    handleCopyLink,
    setSelectedData,
    showConfirmModal,
    showShareSMModal,
    navigateLiveProfile,
    handleShareSMModalOk,
    handleConfirmModalOk,
    generateSharableLink,
    shareSocialMediaHandler,
    handleSelectSocialMedia,
    handleConfirmModalCancel,
    showSuccessfullyDeleteModal,
    handleSuccessfullyDeleteModalOk,
    handleSuccessfullyDeleteModalCancel,
  } = useShopListHook();

  const items = [
    {
      key: "1",
      icon: <Share08Icon size={20} color={"currentcolor"} variant={"stroke"} />,
      label: (
        <>
          <ILSocialShareMedia
            socialShareText
            showShareSMModal={showShareSMModal}
          />
        </>
      ),
    },
    {
      key: "2",
      icon: (
        <Delete02Icon
          size={20}
          color={"#ED4F32"}
          variant={"stroke"}
          onClick={() => showConfirmModal("collection", selectedData?.data)}
        />
      ),
      label: (
        <>
          <div
            onClick={() => showConfirmModal("collection", selectedData?.data)}
          >
            Radera kollektion
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <ILHead title="Min butik" />
      <ILDashboardHeader
        extra={true}
        title="Min butik"
        breadCrumbItems={[
          { title: "Hem", href: "/dashboard" },
          { title: "Min butik" },
        ]}
      />
      {/* COPY LINK SECTION START */}
      <section className="section-xs-mb">
        <div className="d-flex justify-content-between flex-wrap gap-3">
          <div className={styles.linkInput}>
            <ILTextInput
              ILInputLabelClass={"mb-0"}
              value={`${appConfig.APP_URL}shop/${userDetails?.userData?.user_name}`}
            />
          </div>
          <div className="d-flex align-items-sm-center flex-sm-nowrap flex-wrap justify-content-sm-center gap-3">
            <ILButton
              ILBtnClass={"min-w-auto"}
              handleClick={() => handleCopyLink("liveProfile")}
            >
              <div className="d-flex align-items-center gap-3">
                <Copy01Icon
                  size={20}
                  color={"currentcolor"}
                  variant={"stroke"}
                />
                Kopiera länk
              </div>
            </ILButton>
            <ILButton
              variant={"gradient-bordered"}
              ILBtnClass={clsx(
                "min-w-auto bg-white-btn",
                "viewLiveProfileBtnRef"
              )}
              handleClick={navigateLiveProfile}
            >
              <div className="d-flex align-items-center gap-3">
                <img src={eyeIcon} alt="advertising agency | ilonsi" />
                Se din butik
              </div>
            </ILButton>
          </div>
        </div>
      </section>
      {/* COPY LINK SECTION END */}

      {/* MY PRODUCTS & COLLECTIONS SECTION START */}
      <section className="section-xs-mb w-100">
        <h4 className="fw-semibold mb-4 pb-2">
          Mina Produkter &#38; Kollektioner
        </h4>
        <Row
          gutter={[
            { xs: 8, sm: 16, md: 30, xl: 30, xxl: 40 },
            { xs: 8, sm: 16, md: 30, xl: 30, xxl: 40 },
          ]}
        >
          <Col span={24} xxl={16}>
            <ILDashboardCard
              title={`Mina Produkter ${
                productList?.totalItems > 0
                  ? `(${countHandler(productList?.totalItems)})`
                  : ""
              }`}
              className={clsx(styles.myProductDashboardCard)}
              dashboardCardHeaderClass={clsx(styles.myProductDashboardCardHead)}
              extra={
                <div className="d-flex gap-4 align-items-center">
                  <Link
                    to="/brands"
                    role={"button"}
                    className={clsx(
                      styles.roundPlusBtn,
                      "d-md-none d-flex position-relative bottom-0"
                    )}
                  >
                    {" "}
                    <PlusSignIcon
                      size={24}
                      color={"var(--clr-white)"}
                      variant={"stroke"}
                    />
                  </Link>
                  <Link
                    className="gradient-text p paragraph-sm-text fw-medium"
                    to="view-all-products"
                  >
                    Visa alla
                  </Link>
                </div>
              }
            >
              <ILTable
                columns={productColumns}
                data={productList?.rows}
                scroll={{ x: "max-content" }}
                isLoading={isLoading?.productsList}
              />
            </ILDashboardCard>
          </Col>
          <Col span={24} xxl={8}>
            <ILDashboardCard
              title={`Mina Kollektioner ${
                collectionList?.totalItems > 0
                  ? `(${countHandler(collectionList?.totalItems)})`
                  : ""
              }`}
              extra={
                <>
                  <Link
                    className="gradient-text p paragraph-sm-text fw-medium"
                    to="view-all-collections"
                  >
                    Visa alla
                  </Link>
                </>
              }
              className={clsx(styles.adminMyCollectionsWrap, "h-auto")}
              dashboardCardHeaderClass={clsx(
                styles.adminMyCollectionsHead,
                "mb-0"
              )}
              innerCardClassName={clsx(
                styles.adminMyCollectionsContent,
                "overflow-y-auto pe-3 small-track pt-3"
              )}
            >
              <div className={styles.myCollectionGalleryCardsWrap}>
                {isLoading?.collectionList ? (
                  <div className="w-100">
                    <ILSkeleton isImgTitleLabelCard2 isNotNeed />
                  </div>
                ) : (
                  collectionList?.totalItems > 0 &&
                  collectionList?.rows?.map((item, index) => {
                    return (
                      <ILDashboardGalleryCard
                        key={index}
                        items={items}
                        navigate={navigate}
                        ILProductGalleryCardItem={item}
                        setSelectedData={setSelectedData}
                        // navigation
                        autoplay={false}
                        pagination={{ dynamicBullets: true }}
                        grabCursor={true}
                        noDataCardClass={clsx(
                          styles.myProductNoDataCard,
                          "d-flex align-items-center justify-content-center black-gray-02 bg-clr-black-gray-02 w-100"
                        )}
                      />
                    );
                  })
                )}

                {!isLoading?.collectionList &&
                  collectionList?.totalItems === 0 && (
                    <ILNoDataCard
                      className="position-relative z-3"
                      title={"Inga kollektioner har lagts till"}
                      isIcon
                    />
                  )}
              </div>
              <Link
                to="/brands"
                role={"button"}
                className={clsx(
                  styles.roundPlusBtn,
                  styles.roundPlusBtnMobile,
                  "plusBtnRef d-none d-md-flex"
                )}
              >
                {" "}
                <PlusSignIcon
                  size={24}
                  color={"var(--clr-white)"}
                  variant={"stroke"}
                />
              </Link>
            </ILDashboardCard>
          </Col>
        </Row>
      </section>
      {/* MY PRODUCTS & COLLECTIONS SECTION END */}

      {/* MY SERVICES SECTION START */}
      <section className="">
        <h4 className="fw-semibold mb-4 pb-2">Mina Tjänster</h4>
        <ILDashboardCard
          title={`Mina Tjänster ${
            serviceList?.totalItems > 0
              ? `(${countHandler(serviceList?.totalItems)})`
              : ""
          }`}
          extra={
            <>
              <Link
                className="gradient-text p paragraph-sm-text fw-medium"
                to="view-all-services"
              >
                Visa alla
              </Link>
            </>
          }
        >
          <ILTable
            columns={serviceColumns}
            data={serviceList?.rows}
            scroll={{ x: "max-content" }}
            isLoading={isLoading?.serviceList}
          />
        </ILDashboardCard>
      </section>
      {/* MY SERVICES SECTION END */}

      <ILModal
        open={isConfirmModalOpen}
        onOk={handleConfirmModalOk}
        onCancel={handleConfirmModalCancel}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="518px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <div
                className="brand-logo brand-logo-big flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center mx-auto"
                style={{ borderColor: "#ED4F32", borderWidth: "3px" }}
              >
                <Delete02Icon
                  size={34}
                  color={"#ED4F32"}
                  variant={"stroke"}
                  strokeWidth={2}
                />
              </div>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4 lh-base">
                Vill du verkligen ta bort{" "}
                {selectedData?.type === "product"
                  ? "produkten"
                  : selectedData?.type === "service"
                  ? "tjänsten"
                  : "samlingen"}{" "}
                ?
              </h4>
              <p className="paragraph-sm-text paragraph-md-text text-center mb-0">
                Detta tar bort din{" "}
                {selectedData?.type === "product"
                  ? "produkten"
                  : selectedData?.type === "service"
                  ? "tjänsten"
                  : "samlingen"}{" "}
                från butiken?
              </p>
              <div className="d-flex align-items-center gap-4 modal-more-btn">
                <ILButton
                  disabled={
                    isLoading?.productDelete ||
                    isLoading?.serviceDelete ||
                    isLoading?.collectionDelete
                  }
                  ILBtnClass="w-50"
                  variant={"primary-gradient"}
                  onClick={showSuccessfullyDeleteModal}
                >
                  {isLoading?.productDelete ||
                  isLoading?.serviceDelete ||
                  isLoading?.collectionDelete ? (
                    <ILLoader isBtn />
                  ) : (
                    "Ja, Bekräfta"
                  )}
                </ILButton>
                <ILButton
                  disabled={
                    isLoading?.productDelete ||
                    isLoading?.serviceDelete ||
                    isLoading?.collectionDelete
                  }
                  ILBtnClass="w-50"
                  variant={"gradient-bordered"}
                  onClick={handleConfirmModalCancel}
                >
                  Nej, Avbryt
                </ILButton>
              </div>
            </div>
          </>
        }
        isFooterRemove
      />
      {/* SUCCESSFULLY DELETE MODAL */}
      <ILModal
        open={isSuccessfullyDeleteModalOpen}
        onOk={handleSuccessfullyDeleteModalOk}
        onCancel={handleSuccessfullyDeleteModalCancel}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="518px"
        children={
          <>
            <div
              className={clsx(
                "text-center d-flex flex-column justify-content-center modal-content-mh-inherit gap-5 gap-xxl-4"
              )}
            >
              {/* <span className="lh-0">{Icons.circleRoundIcon}</span> */}
              <div
                className="brand-logo brand-logo-big flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center mx-auto"
                style={{ borderColor: "#ED4F32", borderWidth: "3px" }}
              >
                <Delete02Icon
                  size={34}
                  color={"#ED4F32"}
                  variant={"stroke"}
                  strokeWidth={2}
                />
              </div>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                {selectedData?.type === "product"
                  ? "Produkten"
                  : selectedData?.type === "service"
                  ? "Tjänsten "
                  : "Kollection "}{" "}
                raderades framgångsrikt!
              </h4>
              <ILButton
                ILBtnClass="w-100"
                variant={"primary-gradient"}
                onClick={handleSuccessfullyDeleteModalCancel}
              >
                Okej
              </ILButton>
            </div>
          </>
        }
        isFooterRemove
      />

      <ILModal
        open={isShareSMModalOpen}
        onOk={handleShareSMModalOk}
        onCancel={handleShareSMModalOk}
        title={
          <>
            <div className="d-flex align-items-center gap-3">
              Dela på sociala medier
            </div>
          </>
        }
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="518px"
        children={
          <>
            <ILShareSocialMediaModal
              liveProfileLink={`${appConfig.APP_URL}${
                selectedData?.type !== "product" &&
                selectedData?.type !== "service"
                  ? "collection/"
                  : ""
              }${
                selectedData?.data?.product?.short_code ||
                selectedData?.data?.service?.short_code ||
                selectedData?.data?.short_code
              }`}
              handleCopyLink={() =>
                handleCopyLink(
                  selectedData?.type == "product"
                    ? "product"
                    : selectedData?.type == "service"
                    ? "service"
                    : "collection"
                )
              }
              selectedData={selectedData}
              shareHandler={shareSocialMediaHandler}
              selectedSocialMedia={selectedSocialMedia}
              generateSharableLink={generateSharableLink}
              handleSelectSocialMedia={handleSelectSocialMedia}
            />
          </>
        }
        isFooterRemove
      />
    </>
  );
};
export default ILMyShop;
