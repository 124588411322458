import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  doFetchProductCategories,
  doFetchServiceCategories,
} from "../../actions";
import { getDecryptionString } from "../../services";

export const useInfluencerHook = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [categoryList, setCategoryList] = useState([]);
  const [categoryType, setCategoryType] = useState({
    category: "product",
    categoryId: "",
  });
  const [apiStatus, setApiStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("_token") ? true : false
  );
  const { userDetails } = useSelector(
    (state) => state.storeAuthenticateReducer
  );

  useEffect(() => {
    let queryParams = new URLSearchParams(location.search);
    userDetails &&
      queryParams.set("user-name", userDetails?.userData?.user_name);
    queryParams.set("res", apiStatus ? "true" : "false");

    navigate(`${location.pathname}?${queryParams.toString()}`, {
      replace: true,
    });
  }, [location?.pathname, userDetails, apiStatus]);

  useEffect(() => {
    localStorage.removeItem("categoryType");
  }, []);

  useEffect(() => {
    fetchCategoryList();
  }, [categoryType?.category]);

  const dashboardNavigate = () => {
    if (isLoggedIn) {
      navigate("/dashboard");
    } else {
      navigate("/sign-up");
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event?.target;
    if (name === "categoryId") {
      localStorage.setItem("categoryType", categoryType?.category);
      navigate(`/brands-list/${value}`);
    }
    setCategoryType((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (value, name) => {
    setCategoryType((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const fetchCategoryList = async () => {
    try {
      setIsLoading(true);

      let categoryList;

      if (categoryType?.category === "product") {
        categoryList = await doFetchProductCategories();
      } else {
        categoryList = await doFetchServiceCategories();
      }
      if (categoryList?.status === 200) {
        setApiStatus(true);

        const decryptedCategoryList = await JSON.parse(
          getDecryptionString(categoryList?.data)
        );

        const categoryListData = decryptedCategoryList?.map((data) => {
          return {
            id: data?.id,
            labelSubtitle: data?.name,
            icon: data?.image,
          };
        });
        setCategoryList(categoryListData);
      } else {
        setApiStatus(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error occurred during fetching category list:", error);
    }
  };

  return {
    isLoading,
    categoryType,
    categoryList,
    navigate,
    handleInputChange,
    dashboardNavigate,
    handleSelectChange,
  };
};
