import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  doFetchAccessRequest,
  doFetchAddCollection,
  doFetchAddProduct,
  doFetchAddService,
  doFetchRequestAction,
} from "../../../actions";
import {
  myAccessRequestTabData,
  myAccessRequestTabInnerData,
} from "../../../constants/data";
import { getDecryptionString } from "../../../services";

export const useAccessRequestHook = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState({
    addToShop: false,
    accessRequest: false,
    acceptRequest: false,
    rejectRequest: false,
  });
  const [accessRequestList, setAccessRequestList] = useState({});
  const [accessRequestTab, setAccessRequestTab] = useState({
    status: "",
    type: "ACCESS",
  });
  const [selectedRequest, setSelectedRequest] = useState({});
  const [isTabActive, setIsTabActive] = useState(myAccessRequestTabData[0].key);
  const [paginationData, setPaginationData] = useState({
    pageSize: 10,
    page: 1,
  });

  useEffect(() => {
    fetchAccessRequest({ ...accessRequestTab, ...paginationData });
  }, [accessRequestTab, paginationData?.page, paginationData?.pageSize]);

  const onTabClick = (key) => {
    setIsTabActive(key);
  };

  let filteredTabData;

  isTabActive === myAccessRequestTabData[0].key
    ? (filteredTabData = myAccessRequestTabInnerData)
    : (filteredTabData = myAccessRequestTabInnerData.filter(
        (item) => item.category === isTabActive
      ));

  const handleAddToShop = async (data) => {
    try {
      setIsLoading((prevState) => ({ ...prevState, addToShop: true }));

      let addProductServiceResponse;

      const requestBody = {
        ...(data?.service_fk !== null
          ? {
              serviceId: data?.service_fk,
            }
          : data?.product_fk !== null
          ? { productId: data?.product_fk }
          : { brandCollectionId: data?.collection_fk }),
      };
      if (data?.service_fk !== null) {
        addProductServiceResponse = await doFetchAddService(requestBody);
      } else if (data?.product_fk !== null) {
        addProductServiceResponse = await doFetchAddProduct(requestBody);
      } else {
        addProductServiceResponse = await doFetchAddCollection(requestBody);
      }

      if (addProductServiceResponse?.status === 200) {
        toast.success(
          `${
            data?.service_fk !== null
              ? "Service"
              : data?.product_fk !== null
              ? "Product"
              : "Collection"
          } added to your shop successfully`
        );
        fetchAccessRequest({ ...accessRequestTab, ...paginationData });
      } else {
        toast.error(addProductServiceResponse?.message);
      }

      setIsLoading((prevState) => ({ ...prevState, addToShop: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prevState) => ({ ...prevState, addToShop: false }));
    }
  };

  const handleViewDetails = (data) => {
    if (data?.type === "CUSTOM_REQUEST") {
      navigate("/dashboard/messaging");
    } else {
      if (data?.product_fk !== null) {
        if (data?.product_details?.is_added_to_shop === 1) {
          navigate(`/dashboard/my-shop/product-details/${data?.product_fk}`);
        } else {
          navigate(`/brands/product-details/${data?.product_fk}`);
        }
      } else if (data?.service_fk !== null) {
        if (data?.service_details?.is_added_to_shop === 1) {
          navigate(`/dashboard/my-shop/service-details/${data?.service_fk}`);
        } else {
          navigate(`/brands/service-details/${data?.service_fk}`);
        }
      } else {
        if (data?.collection_details?.is_added_to_shop === 1) {
          navigate(
            `/dashboard/my-shop/view-all-collections/view-all-products-collections/${data?.collection_fk}`
          );
        } else {
          navigate(`/brand-collections/${data?.collection_fk}`);
        }
      }
    }
  };

  const handleTableChange = (event, filter, sort) => {
    try {
      setPaginationData((prev) => ({
        ...prev,
        page: event,
        pageSize: filter,
      }));
    } catch (error) {
      console.error("Error occurred during pagination:", error);
    }
  };

  const handleSelectChange = (value, name) => {
    setAccessRequestTab((prevState) => ({
      ...prevState,
      [name]: value,
      type: value === "PENDING" ? "" : "ACCESS",
    }));
    setPaginationData({
      pageSize: 10,
      page: 1,
    });
  };

  const fetchAccessRequest = async (data) => {
    try {
      setIsLoading((prevState) => ({ ...prevState, accessRequest: true }));

      const accessRequestResponse = await doFetchAccessRequest(
        data?.status,
        data?.type,
        false,
        data?.pageSize,
        data?.page
      );

      if (accessRequestResponse?.status === 200) {
        const decryptedAccessRequest = await JSON.parse(
          getDecryptionString(accessRequestResponse?.data?.data)
        );
        setAccessRequestList(decryptedAccessRequest);
      } else {
        toast.error(accessRequestResponse?.data?.message);
      }
      setIsLoading((prevState) => ({ ...prevState, accessRequest: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prevState) => ({ ...prevState, accessRequest: false }));
    }
  };

  const handleAccessRequest = async (data, type) => {
    try {
      if (type === "accept") {
        setIsLoading((prevState) => ({ ...prevState, acceptRequest: true }));
      } else {
        setIsLoading((prevState) => ({ ...prevState, rejectRequest: true }));
      }
      setSelectedRequest(data);

      const requestBody = {
        requestId: data?.id,
        status: type,
      };

      const accessRequestResponse = await doFetchRequestAction(requestBody);

      if (accessRequestResponse?.status === 200) {
        toast.success(accessRequestResponse?.data?.message);
        fetchAccessRequest({ ...accessRequestTab, ...paginationData });
      } else {
        toast.error(accessRequestResponse?.data?.message);
      }
      setIsLoading((prevState) => ({
        ...prevState,
        acceptRequest: false,
        rejectRequest: false,
      }));
      setSelectedRequest({});
    } catch (error) {
      console.log({ error });
      setIsLoading((prevState) => ({
        ...prevState,
        acceptRequest: false,
        rejectRequest: false,
      }));
    }
  };

  return {
    isLoading,
    paginationData,
    selectedRequest,
    filteredTabData,
    accessRequestTab,
    accessRequestList,
    navigate,
    onTabClick,
    handleAddToShop,
    handleViewDetails,
    handleTableChange,
    handleSelectChange,
    handleAccessRequest,
  };
};
