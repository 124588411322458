import {
  ILDashboardCard,
  ILNotificationCard,
  ILTab,
  ILDashboardHeader,
  ILHead,
  ILNoDataCard,
  ILPagination,
  ILSkeleton,
} from "../../../components";
import { myAccessRequestTabData } from "../../../constants/data";
import { Col } from "antd";
import { useAccessRequestHook } from "../../../hooks/dashboard/profile/accessRequest.hook";
import styles from "./ILMyAccessRequest.module.css";

const ILMyAccessRequest = () => {
  const {
    isLoading,
    paginationData,
    selectedRequest,
    accessRequestTab,
    accessRequestList,
    navigate,
    onTabClick,
    handleAddToShop,
    handleViewDetails,
    handleTableChange,
    handleSelectChange,
    handleAccessRequest,
  } = useAccessRequestHook();

  return (
    <>
      <ILHead title="Mina förfrågningar" />
      <ILDashboardHeader
        extra={true}
        title="Mina förfrågningar"
        breadCrumbItems={[
          { title: "Hem", href: "/dashboard" },
          { title: "Mina förfrågningar" },
        ]}
      />
      <section>
        <div>
          <div className={styles.myAccessRequestTabWrap}>
            <ILTab
              defaultActiveKey="1"
              tabItem={myAccessRequestTabData}
              onTabClick={onTabClick}
              tabClassName="my-access-request-tab"
              onChange={(event) => handleSelectChange(event, "status")}
            />
          </div>

          <Col xs={24} xl={18} xxl={14}>
            <div className={styles.myAccessRequestTabContentWrap}>
              <ILDashboardCard noHeader>
                {isLoading?.accessRequest ? (
                  <ILSkeleton isImgTitleLabelCard />
                ) : (
                  accessRequestList?.rows?.map((tabData, index) => {
                    return (
                      <ILNotificationCard
                        notificationItem={tabData}
                        key={index}
                        navigate={navigate}
                        isLoading={isLoading}
                        className={"my-access-request-card"}
                        handleAccessRequest={handleAccessRequest}
                        handleAddToShop={handleAddToShop}
                        selectedRequest={selectedRequest}
                        handleViewDetails={handleViewDetails}
                      />
                    );
                  })
                )}
                {!isLoading?.accessRequest &&
                  accessRequestList?.totalItems === 0 && (
                    <ILNoDataCard
                      isIcon
                      className="position-relative z-3"
                      title={`Inga ${
                        accessRequestTab?.status === "ACCEPTED"
                          ? "accepterade"
                          : accessRequestTab?.status === "REJECTED"
                          ? "avvisade"
                          : ""
                      } förfrågningar hittades`}
                    />
                  )}
              </ILDashboardCard>
            </div>
            {!isLoading?.accessRequest &&
              (accessRequestList?.hasPrevious ||
                accessRequestList?.hasNext) && (
                <ILPagination
                  defaultCurrent={paginationData?.page}
                  total={accessRequestList?.totalItems}
                  defaultPageSize={paginationData?.pageSize}
                  onChange={handleTableChange}
                  endPagination
                  className="mt-5"
                />
              )}
          </Col>
        </div>
      </section>
    </>
  );
};

export default ILMyAccessRequest;
