import clsx from "clsx";
import React from "react";
import { ILButton } from "../ILButton";
import styles from "./ILProfileCard.module.css";

export const ILProfileCard = ({ ILProfileCardsList }) => {
  return (
    <>
      {ILProfileCardsList &&
        ILProfileCardsList.map((item, index) => {
          return (
            <div key={index} className={styles.ILProfileCard}>
              <div className="d-flex align-items-center justify-content-between gap-3 flex-wrap">
                <div className="d-flex align-items-center gap-4">
                  <div className={styles.profilePic}>
                    <img
                      src={item.image}
                      alt={item.name}
                      width={"100%"}
                      height={"100%"}
                      className={"object-fit-cover rounded-circle"}
                    />
                  </div>
                  <div>
                    <p className="big fw-semibold mb-2">{item.name}</p>
                    <p className="paragraph-sm-text fw-medium mb-0">
                      {item.address}
                    </p>
                  </div>
                </div>
                <ILButton
                  ILBtnClass={"min-w-auto"}
                  variant={"gradient-bordered"}
                >
                  View profile
                </ILButton>
              </div>
              <div className="my-4">
                <p className="sm text-in-the-twiligh mb-3">Brand categories</p>
                <div className={styles.brandCategoryBadgeWrap}>
                  {item.brandCategoryBadgeList &&
                    item.brandCategoryBadgeList.map((item, index) => {
                      return (
                        <span key={index} className={styles.brandCategoryBadge}>
                          {item.badgeIcon}
                          <p className="sm fw-medium mb-0">{item.badgeTitle}</p>
                        </span>
                      );
                    })}
                </div>
              </div>
              <div>
                <p className="sm text-in-the-twiligh mb-3">Earnings</p>
                <div
                  className={"earning-content-wrap d-flex align-items-center"}
                >
                  {item.earningContentList &&
                    item.earningContentList.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={clsx(
                            "earning-content d-flex flex-column flex-sm-row gap-3"
                          )}
                        >
                          <img
                            src={item.image}
                            alt={item.label}
                            width={"20px"}
                            height={"20px"}
                            className="earning-content-icon"
                          />
                          <div>
                            <p className="fw-medium mb-2">{item.number}</p>
                            <p className="sm fw-medium text-gray mb-0">
                              {item.label}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};
