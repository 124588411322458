import { Modal } from "antd";
import clsx from "clsx";
import { ILLoader } from "../ILLoader";
import styles from "./ILModal.module.css";

export const ILModal = ({
  open,
  onOk,
  onCancel,
  title,
  className,
  wrapClassName,
  centered = true,
  closable,
  width = "534px",
  children,
  isFooterRemove,
  isCloseIconRemove,
  maskClosable = true,
  isLoader,
  isDiffrentCloseButton,
  ...rest
}) => {
  return (
    <>
      <Modal
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        title={title}
        className={clsx(styles.defaultModalStyle, className, {
          [styles.footerRemove]: isFooterRemove,
          [styles.closeIconRemove]: isCloseIconRemove,
          [styles.isDiffrentCloseButton]: isDiffrentCloseButton,
        })}
        wrapClassName={wrapClassName}
        centered={centered}
        closable={closable}
        width={width}
        maskClosable={maskClosable}
        {...rest}
      >
        {isLoader && <ILLoader classname={"loader-wrap-fixed"} />}
        {children}
      </Modal>
    </>
  );
};
