import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getDecryptionString } from "../../../services";
import { useNavigate } from "react-router-dom";
import {
  doFetchCities,
  doFetchCountryList,
  doFetchInfluencerProfileUpdate,
  doFetchSocialMediaChannelList,
  doFetchUserDetails,
} from "../../../actions";
import {
  validateAddChannel,
  validateInfluencerProfile,
  validateSubmitAddChannel,
  validateSubmitInfluencerProfile,
} from "../../../validations";
import { capitalize } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { storeAuthUserDetails } from "../../../reducers/authenticate.reducer";

export const useInfluencerProfileHook = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isEditLogo, setIsEditLogo] = useState(false);
  const [isEditData, setIsEditData] = useState(false);
  const [userProfileDetails, setUserProfileDetails] = useState({});
  const [validateMessages, setValidateMessages] = useState({});
  const [isAddChannelModalOpen, setIsAddChannelModalOpen] = useState(false);
  const [channelList, setChannelList] = useState([]);
  const [originalChannelList, setOriginalChannelList] = useState(channelList);
  const [socialMediaData, setSocialMediaData] = useState({});
  const [socialMediaList, setSocialMediaList] = useState([]);
  const [countryList, setCountryList] = useState({});
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [deleteChannelModalOpen, setDeleteChannelModalOpen] = useState(false);
  const [channelData, setChannelData] = useState("");
  const { userDetails } = useSelector(
    (state) => state.storeAuthenticateReducer
  );

  useEffect(() => {
    fetchUserDetails();

    if (!userProfileDetails?.countryId) {
      setUserProfileDetails((prevData) => ({
        ...prevData,
        countryId: "e434260b-cbfa-47f2-807c-d49116afe944",
      }));
    }
  }, []);

  useEffect(() => {
    if (!userProfileDetails?.countryId) {
      setUserProfileDetails((prevData) => ({
        ...prevData,
        countryId: "e434260b-cbfa-47f2-807c-d49116afe944",
      }));
    }
    if (userProfileDetails?.countryId) {
      fetchCitiesList(userProfileDetails?.countryId);
    }
  }, [userProfileDetails?.countryId]);

  useEffect(() => {
    const updatedChannelList = originalChannelList.filter(
      (channel) =>
        !socialMediaList.some(
          (socialMedia) => socialMedia.value === channel.value
        )
    );

    setChannelList(updatedChannelList);
  }, [socialMediaList, originalChannelList]);

  const handleInputChange = (event) => {
    const { value, name } = event?.target;

    if (name === "mediaLink") {
      const { errors } = validateAddChannel(name, value, validateMessages);
      setValidateMessages(errors);
      setSocialMediaData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (name === "phoneNumber") {
      const { errors } = validateInfluencerProfile(
        name,
        value,
        validateMessages
      );
      setIsEditData(true);
      setValidateMessages(errors);
      setUserProfileDetails((prevState) => ({ ...prevState, [name]: value }));
    } else {
      const { errors } = validateInfluencerProfile(
        name,
        value,
        validateMessages
      );
      setValidateMessages(errors);
      setIsEditData(true);
      setUserProfileDetails((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleFileInputChange = (event) => {
    const { files, name } = event.target;
    const { errors } = validateInfluencerProfile(
      name,
      files[0],
      validateMessages
    );
    setIsEditLogo(true);
    setIsEditData(true);
    setValidateMessages(errors);
    setUserProfileDetails((prevState) => ({ ...prevState, [name]: files }));
  };

  const handleSelectChange = (value, name) => {
    if (name === "channelId") {
      const { errors } = validateAddChannel(name, value, validateMessages);
      setValidateMessages(errors);

      const socialMediaImage = channelList.filter(
        (data) => data?.value === value
      );
      setSocialMediaData((prevState) => ({
        ...prevState,
        [name]: value,
        label: socialMediaImage[0]?.label,
        image: socialMediaImage[0]?.image,
      }));
    } else if (name === "countryCode") {
      setIsEditData(true);
      setUserProfileDetails((prevState) => ({ ...prevState, [name]: value }));
    } else {
      const { errors } = validateInfluencerProfile(
        name,
        value,
        validateMessages
      );
      if (name === "countryId") {
        setUserProfileDetails((prevState) => ({ ...prevState, cityId: "" }));
      }
      setIsEditData(true);
      setValidateMessages(errors);
      setUserProfileDetails((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleAddChannelSubmit = () => {
    const { errors } = validateSubmitAddChannel(socialMediaData);
    setValidateMessages(errors);

    if (Object.keys(errors).length === 0) {
      setSocialMediaList((prevSocialMediaList) => [
        ...prevSocialMediaList,
        {
          value: socialMediaData?.channelId,
          label: socialMediaData?.label,
          image: socialMediaData?.image,
          mediaLink: socialMediaData?.mediaLink,
        },
      ]);
      showAddChannelModal();
      setIsEditData(true);
    }
  };

  const handleChannelDeleteModal = (data) => {
    setChannelData(data);
    setDeleteChannelModalOpen(true);
  };

  const handleChannelDeleteModalCancel = () => {
    setDeleteChannelModalOpen(false);
  };

  const handleUnlinkChannel = () => {
    setDeleteChannelModalOpen(false);

    const filterChannelListData = socialMediaList.filter(
      (data) => data?.value !== channelData?.value
    );

    setSocialMediaList([...filterChannelListData]);
    setIsEditData(true);
  };

  const showAddChannelModal = () => {
    setIsAddChannelModalOpen(!isAddChannelModalOpen);
    setSocialMediaData({});
    setValidateMessages({});
  };

  const fetchCountryList = async () => {
    try {
      const countryListResponse = await doFetchCountryList();
      if (countryListResponse?.status === 200) {
        const decryptedCountryList = await JSON.parse(
          getDecryptionString(countryListResponse?.data?.data)
        );

        const countryListData = decryptedCountryList?.map((data) => {
          return {
            value: data?.id,
            label: (
              <>
                <img
                  src={data?.image}
                  alt={data?.name}
                  width="25"
                  height="25"
                  className="me-2"
                />
                <span>{data?.name}</span>
              </>
            ),
          };
        });

        const countryCode = decryptedCountryList?.map((data) => {
          return {
            value: data?.phone_code,
            label: (
              <div className="d-flex align-items-center gap-3">
                <img
                  src={data?.image}
                  style={{ maxWidth: "24px", height: "auto" }}
                />
                <span>{data?.phone_code}</span>
              </div>
            ),
          };
        });

        setCountryCodeList(countryCode);
        setCountryList(countryListData);
      } else {
        toast.error(countryListResponse?.message);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const fetchCitiesList = async (data) => {
    try {
      const citiesListResponse = await doFetchCities(data);
      if (citiesListResponse?.status === 200) {
        const decryptedCitiesList = await JSON.parse(
          getDecryptionString(citiesListResponse?.data)
        );

        const citiesListData = decryptedCitiesList?.map((data) => {
          return {
            value: data?.id,
            label: data?.name,
          };
        });

        setCitiesList(citiesListData);
      } else {
        toast.error(citiesListResponse?.message);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const fetchSocialMediaChannelList = async () => {
    try {
      const channelListResponse = await doFetchSocialMediaChannelList();

      if (channelListResponse?.status === 200) {
        const decryptedChannelList = await JSON.parse(
          getDecryptionString(channelListResponse?.data?.data)
        );

        const channelListData = decryptedChannelList?.map((data) => {
          return {
            value: data?.id,
            label: data?.name,
            image: data?.image_2,
          };
        });
        setChannelList(channelListData);
        setOriginalChannelList(channelListData);
      } else {
        toast.error(channelListResponse?.message);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const fetchUserDetails = async () => {
    try {
      setIsLoading(true);
      fetchCountryList();

      const userDetailsResponse = await doFetchUserDetails();

      if (userDetailsResponse?.status === 200) {
        const decryptedUserDetails = await JSON.parse(
          getDecryptionString(userDetailsResponse?.data?.data)
        );

        const { userData } = decryptedUserDetails;

        const productCategoryListData = userData?.user_interested_products?.map(
          (data) => {
            return {
              value: data?.product_category?.id,
              name: data?.product_category?.name,
              icon: data?.product_category?.image,
            };
          }
        );
        const serviceCategoryListData = userData?.user_interested_services?.map(
          (data) => {
            return {
              value: data?.service_category?.id,
              name: data?.service_category?.name,
              icon: data?.service_category?.image,
            };
          }
        );

        const channelListData = userData?.channel_links?.map((data) => {
          return {
            value: data?.channels?.id,
            label: data?.channels?.name,
            image: data?.channels?.image_2,
            mediaLink: data?.media_link,
          };
        });

        setSocialMediaList((prevSocialMediaList) => [
          ...prevSocialMediaList,
          ...channelListData,
        ]);

        const userDetailsObj = {
          image: userData?.profile_image,
          email: userData?.email,
          name: userData?.name,
          userName: userData?.user_name,
          countryCode: userData?.phone_code,
          phoneNumber: userData?.phone_number,
          countryId: userData?.country?.id,
          cityId: userData?.city?.id,
          bio: userData?.bio,
          address: userData?.address,
          details: userData?.details ? userData?.details : null,
          pincode: userData?.pincode,
          socialSecurityNumber: userData?.ssn,
          channelListData: channelListData,
          productCategoryListData: productCategoryListData,
          serviceCategoryListData: serviceCategoryListData,
        };

        setUserProfileDetails(userDetailsObj);

        fetchSocialMediaChannelList();
      } else {
        toast.error(userDetailsResponse?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  const handleInfluencerProfileSubmit = () => {
    try {
      const { errors } = validateSubmitInfluencerProfile(userProfileDetails);
      setValidateMessages(errors);

      if (Object.keys(errors).length === 0) {
        brandProfileUpdateCallback();
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const brandProfileUpdateCallback = async () => {
    try {
      setIsSubmitLoading(true);

      const phoneCode = userProfileDetails?.phoneNumber?.slice(0, 3);
      const remainingNumber = userProfileDetails?.phoneNumber?.slice(3);

      const formData = new FormData();
      isEditLogo && formData.append("image", userProfileDetails?.image[0]);
      formData.append("name", capitalize(userProfileDetails?.name));
      formData.append("phoneCode", userProfileDetails?.countryCode);
      formData.append("phoneNumber", userProfileDetails?.phoneNumber);
      formData.append("bio", capitalize(userProfileDetails?.bio));
      formData.append("address", userProfileDetails?.address);
      formData.append("countryId", userProfileDetails?.countryId);
      formData.append("cityId", userProfileDetails?.cityId);
      formData.append("details", capitalize(userProfileDetails?.details));
      formData.append("pincode", userProfileDetails?.pincode);
      formData.append(
        "socialSecurityNumber",
        !userProfileDetails?.socialSecurityNumber
          ? ""
          : userProfileDetails?.socialSecurityNumber
      );

      const socialMediaAccountArr = [];
      socialMediaList?.map((data) =>
        socialMediaAccountArr?.push({
          channelId: data.value,
          mediaLink: data?.mediaLink,
        })
      );

      socialMediaAccountArr.forEach((channel, index) => {
        formData.append(
          `socialMediaAccount[${index}][channelId]`,
          channel.channelId
        );
        formData.append(
          `socialMediaAccount[${index}][mediaLink]`,
          channel.mediaLink
        );
      });

      const influencerProfileResponse = await doFetchInfluencerProfileUpdate(
        formData
      );
      if (influencerProfileResponse?.status === 200) {
        navigate("/dashboard");
        toast.success(influencerProfileResponse?.message);

        const productCategory =
          userProfileDetails?.productCategoryListData?.map((data) => {
            return {
              id: data?.value,
              product_category: { image: data?.icon, name: data?.name },
            };
          });

        const serviceCategory =
          userProfileDetails?.serviceCategoryListData?.map((data) => {
            return {
              id: data?.value,
              service_category: { image: data?.icon, name: data?.name },
            };
          });

        const channelLinksData = userProfileDetails?.channelListData?.map(
          (data) => {
            return {
              id: data?.value,
              media_link: data?.mediaLink,
              channels: { image_2: data?.image, name: data?.label },
            };
          }
        );
        const updatedUserDetails = {
          ...userDetails,
          userData: {
            ...userDetails.userData,
            channel_links: channelLinksData,
            city_fk: userProfileDetails?.cityId,
            country_fk: userProfileDetails?.countryId,
            profile_image: userProfileDetails?.image,
            phone_number: userProfileDetails?.phoneNumber,
            user_interested_products: productCategory,
            user_interested_services: serviceCategory,
            ssn: userProfileDetails?.socialSecurityNumber,
            user_name: userProfileDetails?.userName,
            pincode: userProfileDetails?.pincode,
            address: userProfileDetails?.address,
            details: userProfileDetails?.details,
            name: userProfileDetails?.name,
            bio: userProfileDetails?.bio,
          },
        };

        dispatch(storeAuthUserDetails(updatedUserDetails));

        setIsEditData(false);
      } else {
        toast.error(influencerProfileResponse?.data?.message);
      }
      setIsSubmitLoading(false);
    } catch (error) {
      console.log({ error });
      setIsSubmitLoading(false);
    }
  };

  return {
    isLoading,
    isEditData,
    citiesList,
    channelList,
    countryList,
    channelData,
    countryCodeList,
    socialMediaList,
    socialMediaData,
    validateMessages,
    isSubmitLoading,
    userProfileDetails,
    isAddChannelModalOpen,
    deleteChannelModalOpen,
    navigate,
    handleInputChange,
    handleSelectChange,
    handleUnlinkChannel,
    showAddChannelModal,
    handleFileInputChange,
    handleAddChannelSubmit,
    handleChannelDeleteModal,
    handleInfluencerProfileSubmit,
    handleChannelDeleteModalCancel,
  };
};
