import {
  CallIcon,
  Location06Icon,
  Mail01Icon,
  Shirt01Icon,
  ComputerPhoneSyncIcon,
  Home01Icon,
  BlushBrush02Icon,
  FootballIcon,
  Dumbbell02Icon,
  BabyBottleIcon,
  VegetarianFoodIcon,
  Configuration01Icon,
  EarRings01Icon,
  PaintBoardIcon,
  NewOfficeIcon,
  UserLove02Icon,
  Award01Icon,
  CoinsDollarIcon,
  BodyPartMuscleIcon,
  Hotel01Icon,
} from "hugeicons-react";
import { clsx } from "clsx";
import { Icons } from "./icons";
import {
  brands,
  creators,
  LadyWithMobile,
  nikeLogo,
  productOne,
  shoppers,
  HMImg,
  OUImg,
  adidasImg,
  bossImg,
  burberryImg,
  ckImg,
  espritImg,
  fitchImg,
  foreverImg,
  jackWolfskinImg,
  nikeImg,
  ralphLaurenImg,
  tomTailorImg,
  tommyHilfigerImg,
  vsImg,
  zaraImg,
  productTwo,
  productThree,
  productFour,
  nikeWhite,
  blackTshirt,
  productFive,
  productSix,
  productSeven,
  productEight,
  pumaLogoImg,
  ralphLaurenLogoImg,
  pumaImg,
  adidasWhiteImg,
  earphones,
  fossil,
  ralphLauren,
  gucci,
  puma,
  adidas,
  zara,
  chanel,
  boss,
  esprit,
  prada,
  youtubeIcon,
  facebookIcon,
  snapchatIcon,
  messengerIcon,
  whatsappIcon,
  twitterIcon,
  instagramIcon,
  laptop,
  fiveFullStarsRating,
  laptopDashboard,
  girlCelebrateVictory,
  menLaptop,
  ladyYellowTshirt,
  earningsIcon,
  clickIcon,
  clickEarningsIcon,
  productServiceIcon,
  productTshirt,
  freshaSalon,
  sinRamen,
  royalBeautySalon,
  serviceOne,
  serviceTwo,
  serviceThree,
  serviceFour,
  apple,
  sugarCosmetics,
  tommyHilfigerLogoImg,
  productEleven,
  productTwelve,
  productTen,
  productThirteen,
  productNine,
  productFourteen,
  productFifteen,
  productSixteen,
  productSeventeen,
  sellIcon,
  tiktokLogo,
  swedenFlag,
  pinterestIcon,
  telegramIcon,
} from "./imageData";
import {
  ILButton,
  ILDeleteCollectionModal,
  ILRangeSlider,
  ILSkeleton,
  ILSocialShareMedia,
} from "../components";
import { ILRegistering } from "../pages/AuthenticationPages/ILSignUp/ILSignUpSteps/ILRegistering";
import { ILAccounts } from "../pages/AuthenticationPages/ILSignUp/ILSignUpSteps/ILAccounts";
import { ILGeneralInfo } from "../pages/AuthenticationPages/ILSignUp/ILSignUpSteps/ILGeneralInfo";
import { ILInterests } from "../pages/AuthenticationPages/ILSignUp/ILSignUpSteps/ILInterests";
import { ILBioInformation } from "../pages/AuthenticationPages/ILSignUp/ILSignUpSteps/ILBioInformation";
import { ILSearchFilterCheckbozList } from "../pages/ILProductSearchFilter/ILSearchFilterCheckbozList";
import { ILUploadProfile } from "../pages/AuthenticationPages/ILSignUp/ILSignUpSteps/ILUploadProfile";

import { ILSignUpEmailVerification } from "../pages/AuthenticationPages/ILSignUp/ILSignUpSteps/ILSignUpEmailVerification";
import { Link } from "react-router-dom";
import { ILCSFilterCheckboxList } from "../pages/ILCSFilterCheckboxList/ILCSFilterCheckboxList";

export const genderSelectOption = [
  {
    value: "Male",
    label: "Man",
  },
  {
    value: "Female",
    label: "Kvinna",
  },
  {
    value: "Other",
    label: "Annat",
  },
];

export const countrySelectOption = [
  {
    value: "Select Collection",
    label: "Välj kollektion",
  },
  {
    value: "My Skin Care Collection (226 Items)",
    label: "My Skin Care Collection (226 Items)",
  },
  {
    value: "My Cloathes Collection (226 Items)",
    label: "My Cloathes Collection (226 Items)",
  },
  {
    value: "England",
    label: "England",
  },
];

export const productServiceOption = [
  {
    value: "Products",
    label: "Produkter",
  },
  {
    value: "Services",
    label: "Tjänster",
  },
];

export const dmyOption = [
  {
    value: "weekly",
    label: "Vecka",
  },
  {
    value: "monthly",
    label: "Månad",
  },
  {
    value: "yearly",
    label: "År",
  },
];

export const timeRangeOption = [
  {
    value: "yesterday",
    label: "I går",
  },
  {
    value: "today",
    label: "I dag",
  },
];

export const footerContactDetailsList = [
  {
    contactDetail: "ILONSI AB",
    href: "https://maps.app.goo.gl/HjKC8ck4Jj1ZidKv7",
    icon: (
      <Location06Icon
        size={20}
        color={"currentcolor"}
        variant={"stroke"}
        strokeWidth={1.5}
      />
    ),
    target: "_blank",
  },
  {
    contactDetail: "0700-649429",
    href: "tel:0700-649429",
    icon: (
      <CallIcon
        size={20}
        color={"currentcolor"}
        variant={"stroke"}
        strokeWidth={1.5}
      />
    ),
  },
  {
    contactDetail: "Info@ilonsi.com",
    href: "mailTo:Info@ilonsi.com",
    icon: (
      <Mail01Icon
        size={20}
        color={"currentcolor"}
        variant={"stroke"}
        strokeWidth={1.5}
      />
    ),
  },
  {
    contactDetail: (
      <>
        <span className="p big lh-normal translateY-1 d-inline-block">+</span>
        &nbsp;46708-708591
      </>
    ),
    href: "https://wa.me/+46708708591",
    icon: Icons.whatsappIcon,
    target: "_blank",
  },
];

export const socialsList = [
  {
    href: "https://www.instagram.com/ilonsisverige/",
    icon: Icons.instagram,
  },
  {
    href: "https://www.facebook.com/ilonsiofficial/?locale=sv_SE",
    icon: Icons.facebook,
  },
  {
    href: "https://www.linkedin.com/company/ilonsi-ab",
    icon: Icons.linkdin,
  },
  {
    href: "https://www.tiktok.com/@ilonsisverige",
    icon: Icons.tiktok,
  },
  {
    href: "https://x.com/ilonsi_official",
    icon: Icons.threads,
  },
  {
    href: "https://www.youtube.com/@ilonsisverige",
    icon: Icons.youtubeArrow,
  },
];

export const userDropdownItems = [
  {
    label: "1st menu item",
    key: "0",
  },
  {
    label: "2nd menu item",
    key: "1",
  },
  {
    type: "divider",
  },
  {
    label: "3rd menu item",
    key: "3",
  },
];
export const featuredProductBreakPoints = {
  0: {
    slidesPerView: 1,
    spaceBetween: 10,
  },
  375: {
    slidesPerView: 1.2,
    spaceBetween: 10,
  },
  400: {
    slidesPerView: 1.4,
    spaceBetween: 10,
  },
  576: {
    slidesPerView: 1.7,
    spaceBetween: 10,
  },
  768: {
    slidesPerView: 2.3,
    spaceBetween: 10,
  },
  992: {
    slidesPerView: 3,
    spaceBetween: 15,
  },
  1024: {
    slidesPerView: 3,
    spaceBetween: 15,
  },
  1200: {
    slidesPerView: 3.5,
    spaceBetween: 15,
  },
  1440: {
    slidesPerView: 4,
    spaceBetween: 20,
  },
};

export const previewProduct = [
  {
    image: productOne,
    logo: nikeLogo,
    label: "Nike",
    title: "Air Jordan 11 Retro Low Black Varsity",
    description:
      "Walk the path of greatness in the Air Jordan 11 Retro. These eter...",
    amount: "150.00 KR",
    productLabel: "PPC : 2KR",
    productLabelSellText: "SELL : 10%",
    isSendAccessRequestBtn: true,
    isPriceAmountText: true,
    isProductLabel: true,
  },
];

export const selectProductData = [
  {
    productData: [
      {
        id: "Jersey-linear",
        image: productOne,
        logo: adidasImg,
        label: "Adidas",
        description: "Jersey linear embroidered logo tee",
        amount: "150.00 KR",
        productLabel: "PPC : 2KR",
        productLabelSellText: "SELL : 10%",
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
  {
    productData: [
      {
        id: "soft t_shirt",
        image: productTwo,
        logo: adidasImg,
        label: "Adidas",
        description: " A soft t shirt with subtle sports style",
        amount: "165.69 KR",
        productLabel: "PPC : 2KR",
        productLabelSellText: "SELL : 10%",
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
  {
    productData: [
      {
        id: "absorbing_workout",
        image: productThree,
        logo: adidasImg,
        label: "Adidas",
        description: "A absorbing workout tee made with cotton",
        amount: "299.26 KR",
        productLabel: "PPC : 2KR",
        productLabelSellText: "SELL : 10%",
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
  {
    productData: [
      {
        id: "T_shirt",
        image: productFour,
        logo: adidasImg,
        label: "Adidas",
        description: "T shirt is made in recycled materials",
        amount: "153.20 KR",
        productLabel: "PPC : 2KR",
        productLabelSellText: "SELL : 10%",
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
  {
    productData: [
      {
        id: "T_shirt_01",
        image: productOne,
        logo: adidasImg,
        label: "Adidas",
        description: " Jersey linear embroidered logo tee",
        amount: "150.00 KR",
        productLabel: "2KR",
        productLabelSellText: "SELL : 10%",
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
];

export const featuredProductsData = [
  {
    productData: [
      {
        image: productOne,
        logo: nikeLogo,
        label: "Nike",
        title: "Air Jordan 11 Retro Low Black Varsity",
        description:
          "Walk the path of greatness in the Air Jordan 11 Retro. These eter...",
        amount: "150.00 KR",
        productLabel: "PPC : 2KR",
        productLabelSellText: "SELL : 10%",
        isSendAccessRequestBtn: true,
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productTwo,
        logo: adidasImg,
        label: "Adidas",
        title: "Air Jordan 11 Retro Low Black Varsity Royal",
        description:
          "You're only one workout away from a better mood. Slip on thi...",
        amount: "165.69 KR",
        productLabel: "PPC : 2KR",
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productThree,
        logo: adidasImg,
        label: "Adidas",
        title: "Essential Single Jersey Embroidered Small Logo",
        description:
          "A key player for your wardrobe. This adidas t-shirt is classic..",
        amount: "299.26 KR",
        productLabel: "PPC : 2KR",
        productLabelSellText: "SELL : 10%",
        isSendAccessRequestBtn: true,
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productFour,
        logo: zaraImg,
        label: "Zara",
        title: "Loose-Fitting Darted Trousers",
        description:
          "High-waist trousers with belt loops. Featuring side pockets...",
        amount: "153.20 KR",
        productLabel: "PPC : 2KR",
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productOne,
        logo: nikeLogo,
        label: "Nike",
        title: "Air Jordan 11 Retro Low Black Varsity Royal",
        description:
          " Walk the path of greatness in the Air Jordan 11 Retro. These eter...",
        amount: "150.00 KR",
        productLabel: "PPC : 2KR",
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productTwo,
        logo: nikeLogo,
        label: "Nike",
        title: "Air Jordan 11 Retro Low Black Varsity Royal",
        description:
          " Walk the path of greatness in the Air Jordan 11 Retro. These eter...",
        amount: "165.69 KR",
        productLabel: "PPC : 2KR",
        productLabelSellText: "SELL : 10%",
        isSendAccessRequestBtn: true,
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productThree,
        logo: adidasImg,
        label: "Adidas",
        title: "Essential Single Jersey Embroidered Small Logo",
        description:
          "A key player for your wardrobe. This adidas t-shirt is classic..",
        amount: "299.26 KR",
        productLabel: "PPC : 2KR",
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productFour,
        logo: zaraImg,
        label: "Zara",
        title: "Loose-Fitting Darted Trousers",
        description:
          "High-waist trousers with belt loops. Featuring side pockets...",
        amount: "153.20 KR",
        productLabel: "PPC : 2KR",
        productLabelSellText: "SELL : 10%",
        isSendAccessRequestBtn: true,
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
];

export const styleguideDemoCheckboxList = [
  {
    id: "fashion",
    labelSubtitle: "Fashion & Apparel",
    icon: (
      <Shirt01Icon
        size={32}
        color={"currentcolor"}
        variant={"stroke"}
        strokeWidth={1.5}
      />
    ),
    labelTitle: "Fashion & Apparel",
  },
  {
    id: "appeal",
    labelSubtitle: "Fashion & Apparel",
    icon: (
      <Shirt01Icon
        size={32}
        color={"currentcolor"}
        variant={"stroke"}
        strokeWidth={1.5}
      />
    ),
    labelTitle: "Fashion & Apparel",
  },
];

export const signUpStepsData = [
  {
    title: "one",
    content: <ILRegistering />,
  },
  {
    title: "two",
    content: <ILSignUpEmailVerification />,
  },
  {
    title: "three",
    content: <ILAccounts />,
  },
  {
    title: "four",
    content: <ILGeneralInfo />,
  },
  {
    title: "five",
    content: <ILInterests />,
  },
  {
    title: "six",
    content: <ILUploadProfile />,
  },
  {
    title: "seven",
    content: <ILBioInformation />,
  },
];

export const stepCountingNumber = [
  {
    labelTitle: "Har du redan ett influencerkonto?",
    linkText: "Logga in",
  },
  {
    labelTitle: "Did’t get a code?",
    linkText: "resend in 00:40.",
  },
  {
    labelTitle: "",
    linkText: "",
  },
  {
    labelTitle: "",
    linkText: "",
  },
  {
    labelTitle: "",
    linkText: "",
  },
  {
    labelTitle: "",
    linkText: "",
  },
  {
    labelTitle: "",
    linkText: "",
  },
];

export const ourStoryCardList = [
  {
    icon: Icons.bulb,
    title: "Ett klick",
    description:
      "Publicera era produkter eller tjänster i tusentals influencer-butiker med ett klick.",
  },
  {
    icon: Icons.cloud,
    title: "Datadrivna kampanjer",
    description:
      "Företag kan se influensers tidigare prestationer för att säkerställa bästa resultat",
  },
  {
    icon: Icons.influencerSuccess,
    title: "Betala för resultat",
    description:
      "Företag betalar endast för de resultat som genereras av influencers.",
  },
];

export const ILInfoCardList = [
  {
    image: brands,
    title: "Företag",
    description:
      "Företag från alla branscher, från kläder och smink till digitala tjänster, har anslutit sig till ILONSI för att stärka sina varumärken och öka försäljningen. Vår plattform kopplar företagets produkter och tjänster till influensers.",
    ILInfoCardNumberInfoList: [
      { gradientText: "10T+", labelText: "Produkter" },
      { gradientText: "200+", labelText: "Företag" },
    ],
  },
  {
    image: creators,
    title: "Influencers",
    description:
      "Vi är stolta över att ha tusentals influencers från plattformar som Instagram, TikTok, Snapchat och YouTube, med följare från 1 000 till en halv miljon. Vi ser fram emot att välkomna internationella influencers och företag för att gynna våra användare ännu mer.",
    ILInfoCardNumberInfoList: [
      { gradientText: "2000+", labelText: "Influencers" },
      { gradientText: "10M+", labelText: "Följare" },
    ],
  },
  {
    image: shoppers,
    title: "Konsumenter",
    description:
      "Influensers är nyckeln till ökad försäljning. Konsumenter litar mer på influensers än varumärken och klickar fem gånger oftare på deras annonser än företagsannonser. Med rätt strategi kan du nå en engagerad och lojal målgrupp som driver både synlighet och försäljning.",
    ILInfoCardNumberInfoList: [
      { gradientText: "69%", labelText: "Litar på influencers" },
      { gradientText: "2M", labelText: "Potentiella konsumenter" },
    ],
  },
];

export const empowerMarketingDataList = [
  {
    empowerClass: "background-conic-gradient",
    empowerImgClass: "object-fit-cover",
    image: "creator-ladies.png",
    title: "Sociala medier revolutionerar handeln",
    description: [
      {
        descriptionItem:
          "Sociala medier och influencer-marknadsföring växer snabbt och påverkar försäljningen. Under 2023 spenderade svenskarna 17,3 miljarder kronor på produkter via sociala plattformar. 36% av svenskarna har handlat via sociala medier, vilket öppnar upp stora möjligheter för både influencers och företag att öka försäljningen och nå nya målgrupper.",
      },
    ],
    pointListItemData: [
      {
        pointDescription:
          "Hälften av svenskarna i åldern 16-29 har köpt en produkt de upptäckt via sociala medier.",
      },
      {
        pointDescription:
          "48 % av svenska butiker har ökat sina intäkter genom att sälja online.",
      },
      {
        pointDescription:
          "Tre av fem konsumenter på sociala medier är kvinnor.",
      },
    ],
  },
  {
    empowerClass: "rouded-0 shadow-none",
    empowerImgClass: "object-fit-contain",
    image: "laptop.webp",
    title: "Därför ska du joina ILONSI-familjen",
    pointListItemData: [
      {
        pointDescription: "Breddade inkomstmöjligheter",
      },
      {
        pointDescription: "Samarbeten utan mellanhänder",
      },
      {
        pointDescription: "Bygg långsiktiga relationer",
      },
    ],
  },
];

export const improvePerformaceDataList = [
  {
    image: laptopDashboard,
    title: "Samarbeta med hundratals företag via vår plattform.",
    description: [
      {
        descriptionItem:
          "ILONSI är ett affiliateprogram som gör det enklare för företag och influencers att samarbeta och tjäna mer. Här är några sätt ILONSI effektiviserar processen för influencers:",
      },
    ],
    pointListItemData: [
      {
        pointDescription: "Tjäna pengar oavsett försäljning eller klick",
      },
      {
        pointDescription: "Jaga inte företag för samarbeten",
      },
      {
        pointDescription: "Lägg till produkter med ett klick",
      },
    ],
  },
];

export const reachingCategoriesList = [
  {
    id: "fashion_apparel",
    labelTitle: "Fashion & Apparel Brands",
    labelSubtitle: "Lorem ipsum dolor sit amet,",
    icon: <Shirt01Icon size={40} color={"#392306"} variant={"stroke"} />,
    path: "/brands-list",
  },
  {
    id: "electronics_tech",
    labelTitle: "Electronics & Technology",
    labelSubtitle: "Lorem ipsum dolor sit amet,",
    icon: (
      <ComputerPhoneSyncIcon size={40} color={"#392306"} variant={"stroke"} />
    ),
    path: "/brands-list",
  },
  {
    id: "home_lifestyle",
    labelTitle: "Home & Lifestyle",
    labelSubtitle: "Lorem ipsum dolor sit amet,",
    icon: <Home01Icon size={40} color={"#392306"} variant={"stroke"} />,
    path: "/brands-list",
  },
  {
    id: "beauty_cosmetics",
    labelTitle: "Beauty & Cosmetics",
    labelSubtitle: "Lorem ipsum dolor sit amet,",
    icon: <BlushBrush02Icon size={40} color={"#392306"} variant={"stroke"} />,
    path: "/brands-list",
  },
  {
    id: "sports_outdoor",
    labelTitle: "Sports & Outdoor",
    labelSubtitle: "Lorem ipsum dolor sit amet,",
    icon: <FootballIcon size={40} color={"#392306"} variant={"stroke"} />,
    path: "/brands-list",
  },
  {
    id: "health_wellness",
    labelTitle: "Health & Wellness",
    labelSubtitle: "Lorem ipsum dolor sit amet,",
    icon: <Dumbbell02Icon size={40} color={"#392306"} variant={"stroke"} />,
    path: "/brands-list",
  },
  {
    id: "baby_kids",
    labelTitle: "Baby & Kids",
    labelSubtitle: "Lorem ipsum dolor sit amet,",
    icon: <BabyBottleIcon size={40} color={"#392306"} variant={"stroke"} />,
    path: "/brands-list",
  },
  {
    id: "food_grocery",
    labelTitle: "Food & Grocery",
    labelSubtitle: "Lorem ipsum dolor sit amet,",
    icon: <VegetarianFoodIcon size={40} color={"#392306"} variant={"stroke"} />,
    path: "/brands-list",
  },
  {
    id: "automotive_tools",
    labelTitle: "Automotive & Tools",
    labelSubtitle: "Lorem ipsum dolor sit amet,",
    icon: (
      <Configuration01Icon size={40} color={"#392306"} variant={"stroke"} />
    ),
    path: "/brands-list",
  },
  {
    id: "jewelry_accessories",
    labelTitle: "Jewelry & Accessories",
    labelSubtitle: "Lorem ipsum dolor sit amet,",
    icon: <EarRings01Icon size={40} color={"#392306"} variant={"stroke"} />,
    path: "/brands-list",
  },
  {
    id: "home_improvement",
    labelTitle: "Home Improvement",
    labelSubtitle: "Lorem ipsum dolor sit amet,",
    icon: <NewOfficeIcon size={40} color={"#392306"} variant={"stroke"} />,
    path: "/brands-list",
  },
  {
    id: "arts_crafts",
    labelTitle: "Arts & Crafts",
    labelSubtitle: "Lorem ipsum dolor sit amet,",
    icon: <PaintBoardIcon size={40} color={"#392306"} variant={"stroke"} />,
    path: "/brands-list",
  },
];

export const brandCardData = [
  {
    brandBorderColor: "#DAC5A8",
    brandImg: foreverImg,
    title: "Forever 21",
    paragraphText: "Lorem ipsum dolor sit amet,",
  },
  {
    brandBorderColor: "#F26722",
    brandImg: nikeImg,
    title: "Nike",
    paragraphText: "Lorem ipsum dolor sit amet,",
  },
  {
    brandBorderColor: "#1C4882",
    brandImg: zaraImg,
    title: "Zara",
    paragraphText: "Lorem ipsum dolor sit amet,",
  },
  {
    brandBorderColor: "#CF4037",
    brandImg: HMImg,
    title: "H&M (Hennes & Mauritz)",
    paragraphText: "Lorem ipsum dolor sit amet,",
  },
  {
    brandBorderColor: "#2974BC",
    brandImg: adidasImg,
    title: "Adidas",
    paragraphText: "Lorem ipsum dolor sit amet,",
  },
  {
    brandBorderColor: "#2974BC",
    brandImg: ralphLaurenImg,
    title: "Ralph Lauren",
    paragraphText: "Lorem ipsum dolor sit amet,",
  },
  {
    brandBorderColor: "#B81D37",
    brandImg: tommyHilfigerImg,
    title: "Tommy Hilfiger",
    paragraphText: "Lorem ipsum dolor sit amet,",
  },
  {
    brandBorderColor: "#A8AAAE",
    brandImg: ckImg,
    title: "Calvin Klein",
    paragraphText: "Lorem ipsum dolor sit amet,",
  },
  {
    brandBorderColor: "#ECB0CA",
    brandImg: vsImg,
    title: "Victoria's Secret",
    paragraphText: "Lorem ipsum dolor sit amet,",
  },
  {
    brandBorderColor: "#000FA0",
    brandImg: burberryImg,
    title: "Burberry",
    paragraphText: "Lorem ipsum dolor sit amet,",
  },
  {
    brandBorderColor: "#222222",
    brandImg: OUImg,
    title: "Urban Outfitters",
    paragraphText: "Lorem ipsum dolor sit amet,",
  },
  {
    brandBorderColor: "#313B6F",
    brandImg: fitchImg,
    title: "Abercrombie & Fitch",
    paragraphText: "Lorem ipsum dolor sit amet,",
  },
  {
    brandBorderColor: "#D31927",
    brandImg: bossImg,
    title: "Hugo Boss",
    paragraphText: "Lorem ipsum dolor sit amet,",
  },
  {
    brandBorderColor: "#FBBA00",
    brandImg: jackWolfskinImg,
    title: "Jack Wolfskin",
    paragraphText: "Lorem ipsum dolor sit amet,",
  },

  {
    brandBorderColor: "#39577A",
    brandImg: tomTailorImg,
    title: "Tom Tailor",
    paragraphText: "Lorem ipsum dolor sit amet,",
  },
  {
    brandBorderColor: "#AD4C38",
    brandImg: espritImg,
    title: "Esprit",
    paragraphText: "Lorem ipsum dolor sit amet,",
  },
];

export const faqList = [
  {
    key: "1",
    label: "Vad är ILONSI?",
    children: `ILONSI är en affiliateplattform där influencers och sociala
          medieanvändare kan marknadsföra produkter och tjänster från både
          etablerade varumärken och nya entreprenörer. Genom att dela länkar och
          rekommendera produkter får du som influencer betalt.`,
  },
  {
    key: "2",
    label: "Kostar det att skapa sin egen ILONSI butik?",
    children: `Nej, det kostar inget att skapa din egen butik på ILONSI. Du kan börja marknadsföra produkter och tjänster direkt när du är klar. Bli en del av ILONSI familjen idag!`,
  },
  {
    key: "3",
    label: "Hur fungerar ILONSI för influencers?",
    children: `Som influencer får du notiser när företag vill samarbeta med dig. När du godkänner samarbetet läggs företagets produkter eller tjänster upp i din ILONSI butik. Du tjänar pengar både på klick och provision på försäljning, förutsatt att företaget har angett en provision.`,
  },
  {
    key: "4",
    label: "Måste jag ha massa följare för att skapa en butik?",
    children: `Du behöver inte ha en stor följarskara för att skapa en butik på ILONSI. Alla influencers, oavsett antal följare, är välkomna att ansluta sig och börja samarbeta med företag.`,
  },
  {
    key: "5",
    label: "Hur fungerar det med klick?",
    children: `Du delar företagets länkar i dina inlägg eller i din butik. För varje klick på din länk tjänar du pengar. Ju fler klick, desto mer kan du tjäna!`,
  },
  {
    key: "6",
    label: "Hur mycket kan jag tjäna på ILONSI som influencer?",
    children: `Din inkomst beror på hur många klick och försäljningar du genererar. Du får betalt för både klick och försäljningar som sker via din länk. Ju mer engagemang och trafik du får, desto mer kan du tjäna. ILONSI gör det möjligt att tjäna pengar även utan en stor följarskara.`,
  },
  {
    key: "7",
    label:
      "Kan man tjäna pengar utan att sälja eller generera trafik med ILONSI?",
    children: `Ja! Förutom att tjäna pengar på klick och försäljning kommer ILONSI att introducera möjligheten att växla besökare till pengar under kommande uppdateringar. Att lägga din butikslänk i din bio och dela den regelbundet i dina inlägg och händelser kommer att gynna dig. På ILONSI handlar det om att ha kul och skapa engagemang!`,
  },
  {
    key: "8",
    label: "Hur kan jag öka mina chanser att få samarbete som influencer?",
    children: `För att öka dina chanser att få samarbeten, fokusera på att engagera dina följare och skapa intressant och autentiskt innehåll. Företag söker ofta influencers med bra engagemang, inte bara stora följarantal och som passar deras varumärke och värderingar.`,
  },
  {
    key: "9",
    label: "Hur fungerar skatter på min inkomst som influencer?",
    children: `Som ILONSI-användare är din inkomst från samarbeten och försäljning skattepliktig. För att förenkla denna process samarbetar ILONSI med GigaPay.co, en svensk Fintech som hanterar skatterapportering och utbetalningar. Du kan få betalt direkt via Swish och GigaPay.co ser till att allt sköts korrekt och snabbt, utan administrativt krångel.`,
  },
  {
    key: "10",
    label: "Hur fungerar ILONSI för företag?",
    children: `Företag skapar enkelt en profil på under 2 minuter och kan välja vårt kostnadsfria medlemskap. De lägger till sina produkter och tjänster i vårt system, vilket gör att de syns på vår marknadsplats och skickar ut notiser till alla influencers. Företag kan även själva söka efter influencers och skicka produkter och tjänster för samarbete direkt via plattformen. Alla betalningar och villkor hanteras smidigt via ILONSI. `,
  },
  {
    key: "11",
    label: "Är alla företag välkomna till ILONSI?",
    children: `Ja, alla företag är välkomna att ansluta sig till ILONSI! Oavsett om ditt företag är stort eller litet, etablerat eller nystartat, kan du använda vår plattform för att hitta influencers och skapa samarbeten. Vi välkomnar företag från alla branscher och hjälper dem att nå rätt målgrupp genom influencer-marknadsföring.`,
  },
  {
    key: "12",
    label: "Hur många medlemskap har ILONSI för företag?",
    children: `ILONSI erbjuder fyra medlemskap för företag, varav ett är kostnadsfritt. De olika medlemskapsalternativen är utformade för att passa företag i olika storlekar och med olika behov.`,
  },
  {
    key: "13",
    label: "Kan företag säga upp sitt medlemskap när de vill?",
    children: `Ja, företag kan säga upp sitt medlemskap när som helst. Om de väljer att avsluta sitt medlemskap, kommer det inte att förnyas vid nästa betalningsperiod. Eventuella betalda medlemskap avslutas vid slutet av den aktuella perioden och inga ytterligare avgifter tas ut.`,
  },
  {
    key: "14",
    label: "Vad får företag för sitt ILONSI-medlemskap?",
    children: `Alla företag med ett ILONSI-medlemskap får en kredit i kronor som kan användas för att samarbeta med influencers. Medlemskapet ger även tillgång till detaljerad data om influencer-prestationer, samt insikter om vilka av företagets produkter och tjänster som presterar bäst hos olika influencers. Bli medlem idag, kostnadsfritt, och ta del av ett kostnadseffektivt sätt att växa och samarbeta med influencers på ILONSI.`,
  },
  {
    key: "15",
    label: "Hur kan företag sälja provisionsbaserat?",
    children: `Företag kan ställa in en provision för sina produkter eller tjänster på ILONSI-plattformen. För att göra detta behöver de integrera ILONSI:s API på sin hemsida (enkel att implementera). När influencers delar en produktlänk och en kund gör ett köp, får influencern en procentandel av försäljningen baserat på den angivna provisionen`,
  },
  {
    key: "16",
    label: "Varför ska mitt företag ansluta sig till ILONSI?",
    children: `Att ansluta ditt företag till ILONSI ger en "win-win"-lösning för att samarbeta med tusentals influencers och nå hundratusentals konsumenter. Företag betalar för resultat. Plattformen gör det enkelt att hitta rätt influencers och se deras prestationer i realtid, så att du kan fatta informerade beslut.`,
  },
  {
    key: "17",
    label: "Kan influencers och företag stänga sitt ILONSI-konto?",
    children: `Ja, både influencers och företag kan när som helst stänga sitt ILONSI-konto. Om du väljer att stänga kontot, kommer alla samarbeten och aktiviteter att avslutas.`,
  },
  {
    key: "18",
    label: "Hittar du inte svaret på din fråga?",
    children: (
      <>
        <p>
          Om du inte hittar svaret på din fråga, maila oss på&nbsp;
          <a href="mailto:info@ilonsi.com">info@ilonsi.com</a> eller DM:a oss på
          Instagram&nbsp;
          <a href="https://www.instagram.com/ilonsisverige" target="_blank">
            @ilonsisverige
          </a>
          .
        </p>
      </>
    ),
  },
];

export const sfPriceProductCheckbox = [
  {
    label: "1 KR - 300 KR",
    value: "1-300",
  },
  {
    label: "400 KR - 700 KR",
    value: "400-700",
  },
  {
    label: "800 KR - 1000 KR",
    value: "800-1000",
  },
  {
    label: "1100+",
    value: "1100above",
  },
];

export const sfBrandProductCheckbox = [
  {
    label: "Nike",
  },
  {
    label: "Adidas",
  },
  {
    label: "Puma",
  },
  {
    label: "Campus",
  },
  {
    label: "Sweatshirt & Hoodies",
  },
];

export const sfProductTypeCheckbox = [
  {
    label: "Produkter",
  },
  {
    label: "Tjänster",
  },
  {
    label: "Företagsskollektioner",
  },
];

export const sfPpcCheckbox = [
  {
    label: "0 - 5 KR",
    value: "0-5",
  },
  {
    label: "5 KR - 10 KR",
    value: "5-10",
  },
  {
    label: "10 KR +",
    value: "10above",
  },
];

export const productDetailsSwiperList = [
  {
    image: blackTshirt,
    productName: "Essential Single Jersey Embroidered Small Logo",
  },
];

export const relatedProductCardList = [
  {
    image: productFive,
    logo: adidasImg,
    label: "Adidas",
    title: "Adicolor Classics Trefoil Tank Top",
    description:
      "Whether it's a chill day or a busy one, this adidas tank top brings you all-da..",
    amount: "175.00 KR",
    productLabel: "PPC : 2KR",
    isProductLabel: true,
  },
  {
    image: productSix,
    logo: adidasImg,
    label: "Adidas",
    title: "Adicolor Classics Trefoil Tank Top",
    description:
      "Anthony Edwards signature logo on the front so you can celebrat...",
    amount: "165.69 KR",
    productLabel: "PPC : 2KR",
    isProductLabel: true,
  },
  {
    image: productSeven,
    logo: adidasImg,
    label: "Adidas",
    title: "Train Essential Feel Ready Logo Training Tee",
    description:
      "You're only one workout away from a better mood. Slip on thi...",
    amount: "299.26 KR",
    productLabel: "PPC : 2KR",
    isProductLabel: true,
  },
  {
    image: productEight,
    logo: adidasImg,
    label: "Adidas",
    title: "Loose-Fitting Darted Trousers",
    description:
      "High-waist trousers with belt loops. Featuring side pockets...",
    amount: "153.20 KR",
    productLabel: "PPC : 2KR",
    isProductLabel: true,
  },
];

export const brandProductInfoData = [
  {
    img: pumaLogoImg,
    title: "Puma",
    description: `PUMA is one of the world's leading Sports Brands,
                        designing, developing, selling and marketing footwear,
                        apparel and accessories. Sport style categories from
                        PUMA include Sneakers, Slip-ons and Loungewear.`,

    labelNumberInfoList: [
      {
        label: "Products",
        number: "6702+",
      },
      {
        label: "Selling",
        number: "9.5m",
      },
      {
        label: "Creator",
        number: "1.6k",
      },
    ],
  },
];

export const brandServicesInfoData = [
  {
    img: ralphLaurenLogoImg,
    title: "Ralph Lauren",
    description: `Ralph Lauren Corporation, commonly known as Ralph Lauren, is an American fashion brand founded by Ralph Lauren in 1967. The company is headquartered in New York City and is renowned for its luxury apparel, accessories, home furnishings, and...`,

    labelNumberInfoList: [
      {
        label: "Services",
        number: "198+",
      },
      {
        label: "Creator",
        number: "18.9",
      },
    ],
  },
];

export const sellingProductsData = [
  {
    image: productOne,
    title: "PUMA grip rubber outsole men's training shoes",
    description:
      "Introducing the latest high-performance training shoe from the Train PUMA squad",
    amount: "357.00 KR",
    productLabel: "PPC : 2KR",
    productLabelSellText: "SELL : 10%",
    isPriceAmountText: true,
    isProductLabel: true,
  },
  {
    image: productOne,
    title: "PUMA grip rubber outsole men's training shoes",
    description:
      "Introducing the latest high-performance training shoe from the Train PUMA squad",
    amount: "357.00 KR",
    productLabel: "PPC : 2KR",
    productLabelSellText: "SELL : 10%",
    isPriceAmountText: true,
    isProductLabel: true,
  },
  {
    image: productOne,
    title: "PUMA grip rubber outsole men's training shoes",
    description:
      "Introducing the latest high-performance training shoe from the Train PUMA squad",
    amount: "357.00 KR",
    productLabel: "PPC : 2KR",
    productLabelSellText: "SELL : 10%",
    isPriceAmountText: true,
    isProductLabel: true,
  },
  {
    image: productOne,
    title: "PUMA grip rubber outsole men's training shoes",
    description:
      "Introducing the latest high-performance training shoe from the Train PUMA squad",
    amount: "357.00 KR",
    productLabel: "PPC : 2KR",
    productLabelSellText: "SELL : 10%",
    isPriceAmountText: true,
    isProductLabel: true,
  },
  {
    image: productOne,
    title: "PUMA grip rubber outsole men's training shoes",
    description:
      "Introducing the latest high-performance training shoe from the Train PUMA squad",
    amount: "357.00 KR",
    productLabel: "PPC : 2KR",
    productLabelSellText: "SELL : 10%",
    isPriceAmountText: true,
    isProductLabel: true,
  },
  {
    image: productOne,
    title: "PUMA grip rubber outsole men's training shoes",
    description:
      "Introducing the latest high-performance training shoe from the Train PUMA squad",
    amount: "357.00 KR",
    productLabel: "PPC : 2KR",
    productLabelSellText: "SELL : 10%",
    isPriceAmountText: true,
    isProductLabel: true,
  },
  {
    image: productOne,
    title: "PUMA grip rubber outsole men's training shoes",
    description:
      "Introducing the latest high-performance training shoe from the Train PUMA squad",
    amount: "357.00 KR",
    productLabel: "PPC : 2KR",
    productLabelSellText: "SELL : 10%",
    isPriceAmountText: true,
    isProductLabel: true,
  },
  {
    image: productOne,
    title: "PUMA grip rubber outsole men's training shoes",
    description:
      "Introducing the latest high-performance training shoe from the Train PUMA squad",
    amount: "357.00 KR",
    productLabel: "PPC : 2KR",
    productLabelSellText: "SELL : 10%",
    isPriceAmountText: true,
    isProductLabel: true,
  },
];

export const newLauchedData = [
  {
    newLaunchedProductData: [
      {
        image: productOne,
        title: "Puma Flymo Injex Women's Flip-Flops",
        description:
          "making them the perfect companion for your summer adventures.",
        amount: "159.00 KR",
        productLabel: "PPC : 2KR",
        productLabelSellText: "SELL : 10%",
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
  {
    newLaunchedProductData: [
      {
        image: productOne,
        title: "PUMA Shaker Unisex Training Water Bottle 600 ml",
        description:
          "Whether you're headed to the gym, school or out for a day of errands",
        amount: "349.00 KR",
        productLabel: "PPC : 2KR",
        productLabelSellText: "SELL : 10%",
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
  {
    newLaunchedProductData: [
      {
        image: productOne,
        title: "Crew neck Skimmer Women's Tee",
        description:
          "This tee features a traditional crew neck design with a modern twist.",
        amount: "598.00 KR",
        productLabel: "PPC : 2KR",
        productLabelSellText: "SELL : 10%",
        isSendAccessRequestBtn: true,
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
  {
    newLaunchedProductData: [
      {
        image: productOne,
        title: "PUMA x one8 Core V2 Unisex Cap",
        description:
          "Energize your athletic spirit with PUMA x one8 Virat Kohli collection.",
        amount: "264.00 KR",
        productLabel: "PPC : 2KR",
        productLabelSellText: "SELL : 10%",
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
];

export const newLaunchedProductData = [
  {
    image: productOne,
    title: "Puma Flymo Injex Women's Flip-Flops",
    description:
      "making them the perfect companion for your summer adventures.",
    amount: "159.00 KR",
    productLabel: "PPC : 2KR",
    isPriceAmountText: true,
    isProductLabel: true,
  },
  {
    image: productOne,
    title: "PUMA Shaker Unisex Training Water Bottle 600 ml",
    description:
      "Whether you're headed to the gym, school or out for a day of errands",
    amount: "349.00 KR",
    productLabel: "PPC : 2KR",
    isPriceAmountText: true,
    isProductLabel: true,
  },
  {
    image: productOne,
    title: "Crew neck Skimmer Women's Tee",
    description:
      "This tee features a traditional crew neck design with a modern twist.",
    amount: "598.00 KR",
    productLabel: "PPC : 2KR",
    isPriceAmountText: true,
    isProductLabel: true,
  },
  {
    image: productOne,
    title: "PUMA x one8 Core V2 Unisex Cap",
    description:
      "Energize your athletic spirit with PUMA x one8 Virat Kohli collection.",
    amount: "264.00 KR",
    productLabel: "PPC : 2KR",
    isPriceAmountText: true,
    isProductLabel: true,
  },
];

export const relatedBrandsData = [
  {
    brandBorderColor: "#1C4882",
    brandImg: zaraImg,
    title: "Zara",
    paragraphText: "collections & lookbooks every week.",
  },
  {
    brandBorderColor: "#BA2026",
    brandImg: pumaImg,
    title: "Puma",
    paragraphText: "Puma is one of the world's leading",
  },
  {
    brandBorderColor: "#EA553B",
    brandImg: nikeImg,
    title: "Nike",
    paragraphText: "Clothing And Accessories",
  },
];

export const filterLabelData = [
  {
    name: "0.5 kr",
  },
  {
    name: "100KR- 300KR",
  },
  {
    name: "Nike",
  },
];

export const productFilterSearchData = [
  {
    logo: nikeLogo,
    label: "Nike",
    image: productOne,
    title: "SB logo cotton skate T-Shirt",
    description:
      "The Nike SB t-Shirt is a skate staple that features a classic logo on chest.",
    amount: "264.00 KR",
    productLabel: "PPC : 2KR",
    productLabelSellText: "SELL : 10%",
    isPriceAmountText: true,
    isProductLabel: true,
  },
  {
    logo: nikeLogo,
    label: "Nike",
    image: productOne,
    title: "Short-sleeve button down shirt",
    description:
      "Made from smooth woven cotton in an easygoing button-down design.",
    amount: "249.09 KR",
    productLabel: "PPC : 2KR",
    productLabelSellText: "SELL : 10%",
    isPriceAmountText: true,
    isProductLabel: true,
  },
  {
    logo: nikeLogo,
    label: "Nike",
    image: productOne,
    title: "Fit UV short-sleeve t Shirt",
    description:
      "Let the soft feel and classic fit help you get through your wild workout.",
    amount: "619.00 KR",
    productLabel: "PPC : 2KR",
    productLabelSellText: "SELL : 10%",
    isPriceAmountText: true,
    isProductLabel: true,
  },
];

export const sfSortByData = [
  {
    value: "Sort by",
    label: "Sort by",
  },
  {
    value: "T-shirt",
    label: "T-shirt",
  },
];

export const ILProductGalleryCardDataList = [
  {
    title: "Electronics",
    subTitle: "266 Items",
    brandTitle: "Adidas",
    ILProductGallerySliderList: [
      {
        mainImage: earphones,
        thumbImage: earphones,
      },
      {
        mainImage: earphones,
        thumbImage: earphones,
      },
      {
        mainImage: earphones,
        thumbImage: earphones,
      },
    ],
    brandLogo: adidasImg,
    accessRequestBtn: true,
  },
];

export const myCollectionGalleryCardsList = [
  {
    thumbsClassName: "thumb-gallery-slider-one",
    title: "Watches",
    subTitle: "14 Products",
    brandTitle: "Fossil",
    ILProductGallerySliderList: [
      {
        mainImage: earphones,
        thumbImage: earphones,
      },
      {
        mainImage: nikeImg,
        thumbImage: nikeImg,
      },
      {
        mainImage: earphones,
        thumbImage: earphones,
      },
    ],
    brandLogo: nikeImg,
    addToCollectionBtn: true,
    isDropdown: true,
  },
  {
    thumbsClassName: "thumb-gallery-slider-two",
    title: "Shoes",
    subTitle: "11 Products",
    brandTitle: "Puma",
    ILProductGallerySliderList: [
      {
        mainImage: earphones,
        thumbImage: earphones,
      },
      {
        mainImage: nikeImg,
        thumbImage: nikeImg,
      },
      {
        mainImage: earphones,
        thumbImage: earphones,
      },
    ],
    brandLogo: fossil,
    addToCollectionBtn: true,
    isDropdown: true,
  },
  {
    thumbsClassName: "thumb-gallery-slider-three",
    title: "Buds",
    subTitle: "21 Products",
    brandTitle: "Mina kollektion",
    ILProductGallerySliderList: [
      {
        mainImage: earphones,
        thumbImage: earphones,
      },
      {
        mainImage: nikeImg,
        thumbImage: nikeImg,
      },
      {
        mainImage: earphones,
        thumbImage: earphones,
      },
    ],
    brandLogo: puma,
    addToCollectionBtn: true,
    isDropdown: true,
  },
  {
    thumbsClassName: "thumb-gallery-slider-four",
    title: "Makeups",
    subTitle: "16 Products",
    brandTitle: "Sugar Cosmetics",
    ILProductGallerySliderList: [
      {
        mainImage: earphones,
        thumbImage: earphones,
      },
      {
        mainImage: nikeImg,
        thumbImage: nikeImg,
      },
      {
        mainImage: earphones,
        thumbImage: earphones,
      },
    ],
    brandLogo: adidasImg,
    addToCollectionBtn: true,
    isDropdown: true,
  },
];

export const brandsImgDataList = [
  {
    altText: "Fossil",
    imgSrc: fossil,
  },
  {
    altText: "Ralph Lauren",
    imgSrc: ralphLauren,
  },
  {
    altText: "Gucci",
    imgSrc: gucci,
  },
  {
    altText: "Puma",
    imgSrc: puma,
  },
  {
    altText: "Adidas",
    imgSrc: adidas,
  },
  {
    altText: "Zara",
    imgSrc: zara,
  },
  {
    altText: "Chanel",
    imgSrc: chanel,
  },
  {
    altText: "Boss",
    imgSrc: boss,
  },
  {
    altText: "Esprit",
    imgSrc: esprit,
  },
  {
    altText: "Prada",
    imgSrc: prada,
  },
];
export const socialMediaSharingCardList = [
  // {
  //   image: youtubeIcon,
  //   title: "You Tube",
  //   link: "",
  // },
  {
    image: facebookIcon,
    title: "Facebook",
    link: `https://www.facebook.com/sharer/sharer.php?u=`,
  },
  {
    image: telegramIcon,
    title: "Telegram",
    link: ` https://t.me/share/url?url=&text=`,
  },
  {
    image: snapchatIcon,
    title: "Snap chat",
    link: `https://www.snapchat.com/share?url=`,
  },
  {
    image: whatsappIcon,
    title: "What’s app",
    link: `https://wa.me/?text=`,
  },
  {
    image: twitterIcon,
    title: "Twitter",
    link: `https://twitter.com/intent/tweet?url=`,
  },
  {
    image: instagramIcon,
    title: "Instagram",
    link: `https://www.instagram.com/sharer.php/?u=`,
  },
  {
    image: pinterestIcon,
    title: "Pinterest",
    link: `https://www.pinterest.com/pin/create/button/?url=`,
  },
];
export const channelNameSelectOption = [
  {
    value: "Channel One",
    label: "Channel One",
  },
  {
    value: "Channel Two",
    label: "Channel Two",
  },
];
export const featuredServicesBreakPoints = {
  0: {
    slidesPerView: 1,
    spaceBetween: 10,
  },
  375: {
    slidesPerView: 1.2,
    spaceBetween: 10,
  },
  400: {
    slidesPerView: 1.4,
    spaceBetween: 10,
  },
  576: {
    slidesPerView: 1.7,
    spaceBetween: 10,
  },
  768: {
    slidesPerView: 2.3,
    spaceBetween: 10,
  },
  992: {
    slidesPerView: 3,
    spaceBetween: 15,
  },
  1024: {
    slidesPerView: 3,
    spaceBetween: 15,
  },
  1200: {
    slidesPerView: 3.5,
    spaceBetween: 15,
  },
  1440: {
    slidesPerView: 4,
    spaceBetween: 20,
  },
};
export const featuredServicesData = [
  {
    productData: [
      {
        image: serviceOne,
        logo: freshaSalon,
        label: "Fresha Saloon",
        title: "Haircut & beard trimming",
        rating: 4.9,
        address: "Office 1991, Al Ghubrah Street, 9,Muscat, Muscat Governorate",
        amount: "165.72 KR",
        productLabel: "PPC : 2KR",
        productLabelSellText: "SELL : 10%",
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
  {
    productData: [
      {
        image: serviceTwo,
        logo: sinRamen,
        label: "Sin Ramen Lounge",
        title: "Haircut & Beard Trimming",
        rating: 4.9,
        address: "Office 1991, Al Ghubrah Street, 9,Muscat, Muscat Governorate",
        amount: "165.72 KR",
        productLabel: "PPC : 2KR",
        productLabelSellText: "SELL : 10%",
        isSendAccessRequestBtn: true,
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
  {
    productData: [
      {
        image: serviceThree,
        logo: freshaSalon,
        label: "Fresha Saloon",
        title: "Massage services for women",
        rating: 4.9,
        address: "Office 1991, Al Ghubrah Street, 9,Muscat, Muscat Governorate",
        amount: "165.72 KR",
        productLabel: "PPC : 2KR",
        productLabelSellText: "SELL : 10%",
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
  {
    productData: [
      {
        image: serviceFour,
        logo: royalBeautySalon,
        label: "Roya Beauty Salon",
        title: "Clean spa services for women",
        rating: 4.8,
        address: "Skytteholm, Solna",
        amount: "165.72 KR",
        productLabel: "PPC : 2KR",
        productLabelSellText: "SELL : 10%",
        isSendAccessRequestBtn: true,
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
  {
    productData: [
      {
        image: serviceOne,
        logo: freshaSalon,
        label: "Fresha Saloon",
        title: "Haircut & beard trimming",
        rating: 4.9,
        address: "Office 1991, Al Ghubrah Street, 9,Muscat, Muscat Governorate",
        amount: "165.72 KR",
        productLabel: "PPC : 2KR",
        productLabelSellText: "SELL : 10%",
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
  {
    productData: [
      {
        image: serviceTwo,
        logo: sinRamen,
        label: "Sin Ramen Lounge",
        title: "Haircut & Beard Trimming",
        rating: 4.9,
        address: "Office 1991, Al Ghubrah Street, 9,Muscat, Muscat Governorate",
        amount: "165.72 KR",
        productLabel: "PPC : 2KR",
        productLabelSellText: "SELL : 10%",
        isSendAccessRequestBtn: true,
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
  {
    productData: [
      {
        image: serviceThree,
        logo: freshaSalon,
        label: "Fresha Saloon",
        title: "Massage services for women",
        rating: 4.9,
        address: "Office 1991, Al Ghubrah Street, 9,Muscat, Muscat Governorate",
        amount: "165.72 KR",
        productLabel: "PPC : 2KR",
        productLabelSellText: "SELL : 10%",
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
  {
    productData: [
      {
        image: serviceFour,
        logo: royalBeautySalon,
        label: "Roya Beauty Salon",
        title: "Clean spa services for women",
        rating: 4.8,
        address: "Skytteholm, Solna",
        amount: "165.72 KR",
        productLabel: "PPC : 2KR",
        productLabelSellText: "SELL : 10%",
        isSendAccessRequestBtn: true,
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
];
export const ProductCollectionCardDataList = [
  {
    title: "Electronics",
    subTitle: "266 Items",
    brandTitle: "Adidas",
    ILProductGallerySliderList: [
      {
        mainImage: earphones,
        thumbImage: earphones,
      },
      {
        mainImage: earphones,
        thumbImage: earphones,
      },
      {
        mainImage: earphones,
        thumbImage: earphones,
      },
    ],
    brandLogo: adidasImg,
    accessRequestBtn: true,
  },
];
export const featuredCollectionsBreakPoints = {
  0: {
    slidesPerView: 1,
    spaceBetween: 10,
  },
  375: {
    slidesPerView: 1.2,
    spaceBetween: 10,
  },
  400: {
    slidesPerView: 1.4,
    spaceBetween: 10,
  },
  576: {
    slidesPerView: 1.7,
    spaceBetween: 10,
  },
  768: {
    slidesPerView: 2.3,
    spaceBetween: 10,
  },
  992: {
    slidesPerView: 3,
    spaceBetween: 15,
  },
  1024: {
    slidesPerView: 3,
    spaceBetween: 15,
  },
  1200: {
    slidesPerView: 3.5,
    spaceBetween: 15,
  },
  1440: {
    slidesPerView: 4,
    spaceBetween: 20,
  },
};
export const featuredCollectionData = [
  {
    FeaturedProductCollectionCardDataList: [
      {
        thumbsClassName: "thumb-gallery-slider-one",
        title: "Electronics",
        subTitle: "266 Items",
        brandTitle: "Apple",
        ILProductGallerySliderList: [
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
          {
            mainImage: nikeImg,
            thumbImage: nikeImg,
          },
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
        ],
        brandLogo: apple,
        addToCollectionBtn: true,
      },
    ],
  },
  {
    FeaturedProductCollectionCardDataList: [
      {
        thumbsClassName: "thumb-gallery-slider-two",
        title: "Makeups",
        subTitle: "664 Items",
        brandTitle: "Zara",
        ILProductGallerySliderList: [
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
        ],
        brandLogo: zaraImg,
        accessRequestBtn: true,
      },
    ],
  },
  {
    FeaturedProductCollectionCardDataList: [
      {
        thumbsClassName: "thumb-gallery-slider-three",
        title: "T-shirt",
        subTitle: "987 Items",
        brandTitle: " Nike",
        ILProductGallerySliderList: [
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
        ],
        brandLogo: nikeLogo,
        addToCollectionBtn: true,
      },
    ],
  },
  {
    FeaturedProductCollectionCardDataList: [
      {
        thumbsClassName: "thumb-gallery-slider-four",
        title: "Makeups",
        subTitle: "664 Items",
        brandTitle: "Sugar Cosmetics",
        ILProductGallerySliderList: [
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
        ],
        brandLogo: sugarCosmetics,
        addToCollectionBtn: true,
      },
    ],
  },
];
export const topProductCollectionData = [
  {
    topProductCollectionCardDataList: [
      {
        thumbsClassName: "thumb-gallery-slider-two",
        title: "Electronics",
        subTitle: "266 Items",
        brandTitle: "Adidas",
        ILProductGallerySliderList: [
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
        ],
        brandLogo: adidasImg,
        accessRequestBtn: true,
      },
    ],
  },
  {
    topProductCollectionCardDataList: [
      {
        thumbsClassName: "thumb-gallery-slider-one",
        title: "Electronics",
        subTitle: "266 Items",
        brandTitle: "Adidas",
        ILProductGallerySliderList: [
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
          {
            mainImage: nikeImg,
            thumbImage: nikeImg,
          },
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
        ],
        brandLogo: adidasImg,
        addToCollectionBtn: true,
      },
    ],
  },
  {
    topProductCollectionCardDataList: [
      {
        thumbsClassName: "thumb-gallery-slider-three",
        title: "Electronics",
        subTitle: "266 Items",
        brandTitle: "Adidas",
        ILProductGallerySliderList: [
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
        ],
        brandLogo: adidasImg,
        addToCollectionBtn: true,
      },
    ],
  },
  {
    topProductCollectionCardDataList: [
      {
        thumbsClassName: "thumb-gallery-slider-four",
        title: "Electronics",
        subTitle: "266 Items",
        brandTitle: "Adidas",
        ILProductGallerySliderList: [
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
        ],
        brandLogo: adidasImg,
        addToCollectionBtn: true,
      },
    ],
  },
];

export const relatedProductsData = [
  {
    relatedBrandsProductData: [
      {
        image: productOne,
        logo: nikeLogo,
        label: "Nike",
        title: "Stripes Pique Classic Polo Shirt",
        description:
          "Sweat it out in style as you wear this pair of polo t-shirt by Adidas.",
        amount: "264.00 KR",
        productLabel: "PPC : 2KR",
        productLabelSellText: "SELL : 10%",
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
  {
    relatedBrandsProductData: [
      {
        image: productOne,
        logo: adidasImg,
        label: "Adidas",
        title: "Stripes dingle jersey woman crop top",
        description:
          "this styling staple is a must-have for beach days, brunch or parties.",
        amount: "249.09 KR",
        productLabel: "PPC : 2KR",
        productLabelSellText: "SELL : 10%",
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
  {
    relatedBrandsProductData: [
      {
        image: productOne,
        logo: zaraImg,
        label: "Zara",
        title: "Adifom Climacool breathable shoes",
        description:
          "Made for keep-cool comfort, they're like a breath of fresh air.",
        amount: "619.00 KR",
        productLabel: "PPC : 2KR",
        productLabelSellText: "SELL : 10%",
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
  {
    relatedBrandsProductData: [
      {
        image: productOne,
        logo: adidasImg,
        label: "Adidas",
        title: "Adi color Classic Curved From Trucker Cap",
        description: "This hat is all about kicking back and looking good.",
        amount: "117.34 KR",
        productLabel: "PPC : 2KR",
        productLabelSellText: "SELL : 10%",
        isPriceAmountText: true,
        isProductLabel: true,
      },
    ],
  },
];
export const RelatedServiceCollectionData = [
  {
    relatedServicesCardList: [
      {
        image: adidasWhiteImg,
        title: "Monogramming",
        description:
          "Monogramming is a custom embroidery service offered by Ralph Lauren for select apparel and accessories. With monogramming, customers have the opportunity to personalize their items by adding their initials or a custom design to the product.",
        price: "150.00 KR",
        iconBg: "#2974BC",
        label: "PPC : 2KR",
      },
    ],
  },
  {
    relatedServicesCardList: [
      {
        image: adidasWhiteImg,
        title: "Engraving",
        description:
          "Personalized engraving services available for certain jewelry and gift items. Customers can choose from a variety of fonts, styles, and thread colors to create their customized monogram. Whether it's adding initials to a shirt cuff, a handbag",
        price: "150.00 KR",
        iconBg: "#2974BC",
        label: "PPC : 2KR",
      },
    ],
  },
  {
    relatedServicesCardList: [
      {
        image: adidasWhiteImg,
        title: "In Store Services",
        description:
          "Personal shopping appointments, alterations, and styling consultations at select Ralph Lauren stores. Ralph Lauren's monogramming service is available both online and in select stores, providing customers with the convenience of customizing.",
        price: "150.00 KR",
        iconBg: "#2974BC",
        label: "PPC : 2KR",
      },
    ],
  },
];
export const relatedCollectionData = [
  {
    relatedCollectionCardDataList: [
      {
        thumbsClassName: "thumb-gallery-slider-two",
        title: "Electronics",
        subTitle: "266 Items",
        brandTitle: "Adidas",
        ILProductGallerySliderList: [
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
        ],
        brandLogo: adidasImg,
        accessRequestBtn: true,
      },
    ],
  },
  {
    relatedCollectionCardDataList: [
      {
        thumbsClassName: "thumb-gallery-slider-three",
        title: "Electronics",
        subTitle: "266 Items",
        brandTitle: "Adidas",
        ILProductGallerySliderList: [
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
        ],
        brandLogo: adidasImg,
        addToCollectionBtn: true,
      },
    ],
  },
  {
    relatedCollectionCardDataList: [
      {
        thumbsClassName: "thumb-gallery-slider-one",
        title: "Electronics",
        subTitle: "266 Items",
        brandTitle: "Adidas",
        ILProductGallerySliderList: [
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
          {
            mainImage: nikeImg,
            thumbImage: nikeImg,
          },
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
        ],
        brandLogo: adidasImg,
        addToCollectionBtn: true,
      },
    ],
  },
  {
    relatedCollectionCardDataList: [
      {
        thumbsClassName: "thumb-gallery-slider-four",
        title: "Electronics",
        subTitle: "266 Items",
        brandTitle: "Adidas",
        ILProductGallerySliderList: [
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
          {
            mainImage: earphones,
            thumbImage: earphones,
          },
        ],
        brandLogo: adidasImg,
        addToCollectionBtn: true,
      },
    ],
  },
];
export const collectionFilterSearchData = [
  {
    thumbsClassName: "thumb-gallery-slider-two",
    title: "Electronics",
    subTitle: "266 Items",
    brandTitle: "Adidas",
    ILProductGallerySliderList: [
      {
        mainImage: earphones,
        thumbImage: earphones,
      },
      {
        mainImage: earphones,
        thumbImage: earphones,
      },
      {
        mainImage: nikeImg,
        thumbImage: nikeImg,
      },
    ],
    brandLogo: adidasImg,
    addToCollectionBtn: true,
  },
];

export const brandCollectionData = [
  {
    image: productOne,
    logo: nikeLogo,
    label: "Nike",
    title: "Air Jordan 11 Retro Low Black Varsity",
    description:
      "Walk the path of greatness in the Air Jordan 11 Retro. These eter...",
    amount: "150.00 KR",
    productLabel: "PPC : 2KR",
    productLabelSellText: "SELL : 10%",
    isPriceAmountText: true,
    isProductLabel: true,
  },
  {
    image: productFour,
    logo: zaraImg,
    label: "Zara",
    title: "Loose-Fitting Darted Trousers",
    description:
      "High-waist trousers with belt loops. Featuring side pockets...",
    amount: "153.20 KR",
    productLabel: "PPC : 2KR",
    productLabelSellText: "SELL : 10%",
    isPriceAmountText: true,
    isProductLabel: true,
  },
  {
    image: productTwo,
    logo: adidasImg,
    label: "Adidas",
    title: "Air Jordan 11 Retro Low Black Varsity Royal",
    description:
      "You're only one workout away from a better mood. Slip on thi...",
    amount: "165.69 KR",
    productLabel: "PPC : 2KR",
    productLabelSellText: "SELL : 10%",
    isPriceAmountText: true,
    isProductLabel: true,
  },
  {
    image: productThree,
    logo: adidasImg,
    label: "Adidas",
    title: "Essential Single Jersey Embroidered Small Logo",
    description:
      "A key player for your wardrobe. This adidas t-shirt is classic..",
    amount: "299.26 KR",
    productLabel: "PPC : 2KR",
    productLabelSellText: "SELL : 10%",
    isPriceAmountText: true,
    isProductLabel: true,
  },
];

export const recentReviewsSwiperListOne = [
  {
    userImage: "https://i.pravatar.cc/48",
    ratingImage: fiveFullStarsRating,
    userName: "Annette Black",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel sed et arcu porttitor nibh morbi id. Duis vitae vitae vitae tempus ipsum nunc risus praesent dictumst. Vel accumsan elit quam leo. Eget amet nibh tincidunt risus morbi libero consequat nulla fermentum. Commodo interdum cras urna id. Porttitor elementum ornare praesent placerat venenatis. Neque aliquam lacus est scelerisque curabitur enim commodo.",
  },
  {
    userImage: "https://i.pravatar.cc/48",
    ratingImage: fiveFullStarsRating,
    userName: "Annette Black",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel sed et arcu porttitor nibh morbi id. Duis vitae vitae vitae tempus ipsum nunc risus praesent dictumst. Vel accumsan elit quam leo. Eget amet nibh tincidunt risus morbi libero consequat nulla fermentum. Commodo interdum cras urna id. Porttitor elementum ornare praesent placerat venenatis. Neque aliquam lacus est scelerisque curabitur enim commodo.",
  },
  {
    userImage: "https://i.pravatar.cc/48",
    ratingImage: fiveFullStarsRating,
    userName: "Annette Black",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel sed et arcu porttitor nibh morbi id. Duis vitae vitae vitae tempus ipsum nunc risus praesent dictumst. Vel accumsan elit quam leo. Eget amet nibh tincidunt risus morbi libero consequat nulla fermentum. Commodo interdum cras urna id. Porttitor elementum ornare praesent placerat venenatis. Neque aliquam lacus est scelerisque curabitur enim commodo.",
  },
  {
    userImage: "https://i.pravatar.cc/48",
    ratingImage: fiveFullStarsRating,
    userName: "Annette Black",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel sed et arcu porttitor nibh morbi id. Duis vitae vitae vitae tempus ipsum nunc risus praesent dictumst. Vel accumsan elit quam leo. Eget amet nibh tincidunt risus morbi libero consequat nulla fermentum. Commodo interdum cras urna id. Porttitor elementum ornare praesent placerat venenatis. Neque aliquam lacus est scelerisque curabitur enim commodo.",
  },
  {
    userImage: "https://i.pravatar.cc/48",
    ratingImage: fiveFullStarsRating,
    userName: "Annette Black",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel sed et arcu porttitor nibh morbi id. Duis vitae vitae vitae tempus ipsum nunc risus praesent dictumst. Vel accumsan elit quam leo. Eget amet nibh tincidunt risus morbi libero consequat nulla fermentum. Commodo interdum cras urna id. Porttitor elementum ornare praesent placerat venenatis. Neque aliquam lacus est scelerisque curabitur enim commodo.",
  },
];
export const recentReviewsSwiperListTwo = [
  {
    userImage: "https://i.pravatar.cc/48",
    ratingImage: fiveFullStarsRating,
    userName: "Annette Black",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel sed et arcu porttitor nibh morbi id. Duis vitae vitae vitae tempus ipsum nunc risus praesent dictumst. Vel accumsan elit quam leo. Eget amet nibh tincidunt risus morbi libero consequat nulla fermentum. Commodo interdum cras urna id. Porttitor elementum ornare praesent placerat venenatis. Neque aliquam lacus est scelerisque curabitur enim commodo.",
  },
  {
    userImage: "https://i.pravatar.cc/48",
    ratingImage: fiveFullStarsRating,
    userName: "Annette Black",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel sed et arcu porttitor nibh morbi id. Duis vitae vitae vitae tempus ipsum nunc risus praesent dictumst. Vel accumsan elit quam leo. Eget amet nibh tincidunt risus morbi libero consequat nulla fermentum. Commodo interdum cras urna id. Porttitor elementum ornare praesent placerat venenatis. Neque aliquam lacus est scelerisque curabitur enim commodo.",
  },
  {
    userImage: "https://i.pravatar.cc/48",
    ratingImage: fiveFullStarsRating,
    userName: "Annette Black",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel sed et arcu porttitor nibh morbi id. Duis vitae vitae vitae tempus ipsum nunc risus praesent dictumst. Vel accumsan elit quam leo. Eget amet nibh tincidunt risus morbi libero consequat nulla fermentum. Commodo interdum cras urna id. Porttitor elementum ornare praesent placerat venenatis. Neque aliquam lacus est scelerisque curabitur enim commodo.",
  },
  {
    userImage: "https://i.pravatar.cc/48",
    ratingImage: fiveFullStarsRating,
    userName: "Annette Black",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel sed et arcu porttitor nibh morbi id. Duis vitae vitae vitae tempus ipsum nunc risus praesent dictumst. Vel accumsan elit quam leo. Eget amet nibh tincidunt risus morbi libero consequat nulla fermentum. Commodo interdum cras urna id. Porttitor elementum ornare praesent placerat venenatis. Neque aliquam lacus est scelerisque curabitur enim commodo.",
  },
  {
    userImage: "https://i.pravatar.cc/48",
    ratingImage: fiveFullStarsRating,
    userName: "Annette Black",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel sed et arcu porttitor nibh morbi id. Duis vitae vitae vitae tempus ipsum nunc risus praesent dictumst. Vel accumsan elit quam leo. Eget amet nibh tincidunt risus morbi libero consequat nulla fermentum. Commodo interdum cras urna id. Porttitor elementum ornare praesent placerat venenatis. Neque aliquam lacus est scelerisque curabitur enim commodo.",
  },
  {
    userImage: "https://i.pravatar.cc/48",
    ratingImage: fiveFullStarsRating,
    userName: "Annette Black",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel sed et arcu porttitor nibh morbi id. Duis vitae vitae vitae tempus ipsum nunc risus praesent dictumst. Vel accumsan elit quam leo. Eget amet nibh tincidunt risus morbi libero consequat nulla fermentum. Commodo interdum cras urna id. Porttitor elementum ornare praesent placerat venenatis. Neque aliquam lacus est scelerisque curabitur enim commodo.",
  },
  {
    userImage: "https://i.pravatar.cc/48",
    ratingImage: fiveFullStarsRating,
    userName: "Annette Black",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel sed et arcu porttitor nibh morbi id. Duis vitae vitae vitae tempus ipsum nunc risus praesent dictumst. Vel accumsan elit quam leo. Eget amet nibh tincidunt risus morbi libero consequat nulla fermentum. Commodo interdum cras urna id. Porttitor elementum ornare praesent placerat venenatis. Neque aliquam lacus est scelerisque curabitur enim commodo.",
  },
];

export const influecerMarketingCardsList = [
  {
    icon: Icons.networkIcon,
    title: "Din prestation är viktigare än ditt följarantal.",
  },
  {
    icon: Icons.collaborateIcon,
    title: "Samarbeten sker direkt via plattformen.",
  },
  {
    icon: Icons.barChart,
    title: "Se och följ hur mycket du tjänar.",
  },
  {
    icon: Icons.audienceThrough,
    title: "Få din ersättning utbetald med Swish.",
  },
];

export const achievementDataList = [
  {
    icon: (
      <UserLove02Icon size={44} color={"var(--clr-white)"} variant={"stroke"} />
    ),
    title: "2000 +",
    description: "Tusentals har redan skapat en ILONSI-butik",
  },
  {
    icon: (
      <Award01Icon size={44} color={"var(--clr-white)"} variant={"stroke"} />
    ),
    title: "4,8",
    description: "Är vårt omdöme från våra användare om ILONSI.",
  },
  {
    icon: (
      <CoinsDollarIcon
        size={44}
        color={"var(--clr-white)"}
        variant={"stroke"}
      />
    ),
    title: "98%",
    description:
      "Av konsumenterna planerar att göra minst ett köp via sociala medier i år.",
  },
];

export const benefitCardList = [
  {
    image: "asian-girl-celebrating-victory-chanting-yes-with-hands-raised.png",
    title: "Maximera dina sociala medier",
    description: "Få ut mer av dina sociala medier med ILONSI",
  },
  {
    image: "man-is-holding-laptop-smiling.png",
    title: "Se vad företagen betalar.",
    description: "Hos oss ser du alltid vad företagen betalar influencers.",
  },
  {
    image: "lady-yellow-tshirt.png",
    title: "Alla är välkomna att joina ILONSI",
    description: (
      <>
        Det enda kravet för att bli en del av ILONSI är att du har sociala
        medier.
      </>
    ),
  },
];

export const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Age",
    dataIndex: "age",
    key: "age",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Tags",
    key: "tags",
    dataIndex: "tags",
    render: (_, { tags }) => (
      <>
        {tags.map((tag) => {
          let color = tag.length > 5 ? "geekblue" : "green";
          if (tag === "loser") {
            color = "volcano";
          }
          return <>{tag.toUpperCase()}</>;
        })}
      </>
    ),
  },
  {
    title: "Redigera",
    key: "action",
    render: (_, record) => (
      <>
        <a>Invite {record.name}</a>
        <a>Delete</a>
      </>
    ),
  },
];
export const data = [
  {
    key: "1",
    name: "John Brown",
    age: 32,
    address: "New York No. 1 Lake Park",
    tags: ["nice", "developer"],
  },
  {
    key: "2",
    name: "Jim Green",
    age: 42,
    address: "London No. 1 Lake Park",
    tags: ["loser"],
  },
  {
    key: "3",
    name: "Joe Black",
    age: 32,
    address: "Sydney No. 1 Lake Park",
    tags: ["cool", "teacher"],
  },
];

export const dataCardsList = [
  {
    data: "5,650.26 KR",
    title: "Totala intäkter",
    image: earningsIcon,
  },
  {
    data: "2,560+",
    title: "Totala klick",
    image: clickIcon,
  },
  {
    data: "2,568 KR",
    title: "Klicka på intäkter",
    image: clickEarningsIcon,
  },
  {
    data: "256+",
    title: "Totala produkter och tjänster",
    image: productServiceIcon,
  },
];

export const myProductsColumns = [
  {
    title: "Produkter",
    dataIndex: "products",
    key: "products",
    render: (item, _) => (
      <div className="d-flex align-items-center gap-4">
        <div className={clsx("ratio ratio-1x1 product-service-img")}>
          <img
            className={"object-fit-cover h-100 w-100"}
            src={item.image}
            alt={item.title}
          />
        </div>
        <div>
          <p className="fw-medium mb-2">{item.title}</p>
          <p className="sm mb-0 text-gray text-ellipsis line-clamp-1">
            {item.description}
          </p>
          <p className="fw-medium mb-0">{item.price}</p>
        </div>
      </div>
    ),
    width: "300px",
  },
  {
    title: "Företag",
    dataIndex: "brand",
    key: "brand",
    render: (item, _) => (
      <>
        <div className="d-flex gap-3">
          <div className="table-logo-img">
            <img
              width={"100%"}
              height={"100%"}
              className={"object-fit-cover"}
              src={item.image}
              alt={item.title}
            />
          </div>
          <p className="fw-medium mb-0">{item.title}</p>
        </div>
      </>
    ),
    width: "150px",
  },
  {
    title: "Klicks",
    dataIndex: "clicks",
    key: "clicks",
    width: "120px",
  },
  {
    title: "CPC",
    dataIndex: "ppc",
    key: "ppc",
    render: (title, _) => (
      <p className="fw-medium gradient-text max-w-max-content">{title}</p>
    ),
    width: "120px",
  },
  {
    title: "Försäljning",
    dataIndex: "sell",
    key: "sell",
    width: "120px",
  },
  {
    title: "Provision %",
    dataIndex: "sale",
    key: "sale",
    render: (title, _) => (
      <p className="fw-medium gradient-text max-w-max-content">{title}</p>
    ),
    width: "120px",
  },
  {
    title: "Redigera",
    dataIndex: "action",
    key: "action",
    render: (_) => (
      <>
        <div className="d-flex align-items-center gap-5 action-btns-wrap">
          <span className="w-50 text-center">
            <ILSocialShareMedia />
          </span>
          <span className="w-50 text-center">
            <ILDeleteCollectionModal />
          </span>
        </div>
      </>
    ),
    fixed: "right",
    width: "100px",
  },
];

export const myProductsData = [
  {
    key: 1,
    products: {
      title: (
        <Link to="product-details" className="text-dark-purple">
          Logo Training Tee
        </Link>
      ),
      image: productTshirt,
      description:
        "You're only one workout away from a better mood. Slip on thi...",
      price: "350.00 KR",
    },
    brand: { image: adidasImg, title: "Adidas" },
    ppc: "0.25 KR",
    clicks: "46",
    sell: "10",
    sale: "-",
  },
  {
    key: 2,
    products: {
      title: (
        <Link to="product-details" className="text-dark-purple">
          Nike Air Max 90 Men's Shoes
        </Link>
      ),
      image: productTwo,
      description: "Nothing as fly, nothing as comfortable, nothing as proven.",
      price: "750.00 KR",
    },
    brand: { image: nikeLogo, title: " Nike" },
    ppc: "0.52 KR",
    clicks: "43",
    sell: "15",
    sale: "5 %",
  },
  {
    key: 3,
    products: {
      title: (
        <Link to="product-details" className="text-dark-purple">
          Nike Air Max 90 Men's Shoes
        </Link>
      ),
      image: productTwo,
      description: "Nothing as fly, nothing as comfortable, nothing as proven.",
      price: "750.00 KR",
    },
    brand: { image: nikeLogo, title: " Nike" },
    ppc: "0.52 KR",
    clicks: "42",
    sell: "20",
    sale: "4 %",
  },
];

export const myServicesColumns = [
  {
    title: "Tjänster/Plats",
    dataIndex: "services_location",
    key: "services_location",
    render: (item, _) => (
      <div className="d-flex align-items-center gap-4">
        <div className={clsx("ratio ratio-1x1 product-service-img")}>
          <img
            className={"object-fit-cover h-100 w-100"}
            src={item.image}
            alt={item.title}
          />
        </div>
        <div>
          <p className="fw-medium mb-2">{item.title}</p>
          <p className="sm mb-0 text-gray text-ellipsis line-clamp-1">
            {item.description}
          </p>
          <p className="fw-medium mb-0">{item.price}</p>
        </div>
      </div>
    ),
    width: "300px",
  },
  {
    title: "Företag",
    dataIndex: "brand",
    key: "brand",
    render: (item, _) => (
      <>
        <div className="d-flex gap-3">
          <div className="table-logo-img">
            <img
              width={"100%"}
              height={"100%"}
              className={"object-fit-cover"}
              src={item.image}
              alt={item.title}
            />
          </div>
          <p className="fw-medium mb-0">{item.title}</p>
        </div>
      </>
    ),
    width: "150px",
  },
  {
    title: "Omdömen",
    dataIndex: "ratings",
    key: "ratings",
    render: (item, _) => (
      <>
        <div className="d-flex align-items-center gap-4 gap-xxl-3">
          {Icons.star}
          <p className="fw-medium mb-0">{item}</p>
        </div>
      </>
    ),
    width: "140px",
  },
  {
    title: "Klicks",
    dataIndex: "clicks",
    key: "clicks",
    width: "120px",
  },
  {
    title: "CPC",
    dataIndex: "ppc",
    key: "ppc",
    render: (title, _) => (
      <p className="fw-medium gradient-text max-w-max-content">{title}</p>
    ),
    width: "100px",
  },
  {
    title: "Försäljning",
    dataIndex: "sell",
    key: "sell",
    width: "120px",
  },
  {
    title: "Provision %",
    dataIndex: "sale",
    key: "sale",
    render: (title, _) => (
      <p className="fw-medium gradient-text max-w-max-content">{title}</p>
    ),
    width: "120px",
  },
  {
    title: "Redigera",
    dataIndex: "action",
    key: "action",
    render: (_) => (
      <>
        <div className="d-flex align-items-center gap-5 action-btns-wrap">
          <span className="w-50 text-center">
            <ILSocialShareMedia />
          </span>
          <span className="w-50 text-center">
            <ILDeleteCollectionModal />
          </span>
        </div>
      </>
    ),
    fixed: "right",
    width: "100px",
  },
];

export const myServicesData = [
  {
    key: 1,
    services_location: {
      title: (
        <Link
          to="/dashboard/my-shop/product-details"
          className="text-dark-purple"
        >
          Haircut & Beard Trimming
        </Link>
      ),
      image: productTshirt,
      description: "Office 1991, Al Ghutras Street...",
      price: "350.00 KR",
    },
    ratings: "4.9 (25)",
    brand: { image: adidasImg, title: "Adidas" },
    ppc: "0.25 KR",
    clicks: "43",
    sell: "12",
    sale: "5 %",
  },
  {
    key: 2,
    services_location: {
      title: (
        <Link
          to="/dashboard/my-shop/product-details"
          className="text-dark-purple"
        >
          Massage Service for women
        </Link>
      ),
      image: productTwo,
      description: "Office 1991, Al Ghutras Street...",
      price: "750.00 KR",
    },
    ratings: "4.9 (25)",
    brand: { image: nikeLogo, title: " Nike" },
    ppc: "0.52 KR",
    clicks: "40",
    sell: "08",
    sale: "8 %",
  },
  {
    key: 3,
    services_location: {
      title: (
        <Link
          to="/dashboard/my-shop/product-details"
          className="text-dark-purple"
        >
          Massage Service for women
        </Link>
      ),
      image: productTwo,
      description: "Office 1991, Al Ghutras Street...",
      price: "750.00 KR",
    },
    ratings: "4.9 (25)",
    brand: { image: nikeLogo, title: " Nike" },
    ppc: "0.52 KR",
    clicks: "40",
    sell: "06",
    sale: "15 %",
  },
  {
    key: 4,
    services_location: {
      title: (
        <Link
          to="/dashboard/my-shop/product-details"
          className="text-dark-purple"
        >
          Massage Service for women
        </Link>
      ),
      image: productTwo,
      description: "Office 1991, Al Ghutras Street...",
      price: "750.00 KR",
    },
    ratings: "4.9 (25)",
    brand: { image: nikeLogo, title: " Nike" },
    ppc: "0.52 KR",
    clicks: "40",
    sell: "06",
    sale: "15 %",
  },
  {
    key: 5,
    services_location: {
      title: (
        <Link
          to="/dashboard/my-shop/product-details"
          className="text-dark-purple"
        >
          Massage Service for women
        </Link>
      ),
      image: productTwo,
      description: "Office 1991, Al Ghutras Street...",
      price: "750.00 KR",
    },
    ratings: "4.9 (25)",
    brand: { image: nikeLogo, title: " Nike" },
    ppc: "0.52 KR",
    clicks: "40",
    sell: "06",
    sale: "15 %",
  },
];

export const topClickedProductsColumns = [
  {
    title: "Sr.no",
    dataIndex: "sr.no",
    key: "sr.no",
    render: (text, record, index) => <p className="text-gray">{++index}</p>,
  },
  {
    title: "Produkter",
    dataIndex: "products",
    key: "products",
    render: (item, _) => (
      <div className="d-flex align-items-center gap-4">
        <div className={clsx("ratio ratio-1x1 product-service-img")}>
          <img
            className={"object-fit-cover h-100 w-100"}
            src={item.image}
            alt={item.title}
          />
        </div>
        <div>
          <p className="fw-medium mb-2">{item.title}</p>
          <p className="sm mb-2 text-gray text-ellipsis line-clamp-1">
            {item.description}
          </p>
          <p className="fw-medium mb-0">{item.price}</p>
        </div>
      </div>
    ),
  },
  {
    title: "Företag",
    dataIndex: "brand",
    key: "brand",
    render: (item, _) => (
      <>
        <div className="d-flex gap-3">
          <div className="table-logo-img">
            <img
              width={"100%"}
              height={"100%"}
              className={"object-fit-cover"}
              src={item.image}
              alt={item.title}
            />
          </div>
          <p className="fw-medium mb-0">{item.title}</p>
        </div>
      </>
    ),
  },
  {
    title: "Klicks",
    dataIndex: "clicks",
    key: "clicks",
  },
  {
    title: "CPC",
    dataIndex: "ppc",
    key: "ppc",
    render: (title, _) => (
      <p className="fw-medium gradient-text max-w-max-content">{title}</p>
    ),
  },
  {
    title: "Försäljning",
    dataIndex: "sell",
    key: "sell",
  },
  {
    title: "Provision %",
    dataIndex: "sale",
    key: "sale",
    render: (title, _) => (
      <p className="fw-medium gradient-text max-w-max-content">{title}</p>
    ),
  },
];

export const topClickedProductsData = [
  {
    key: 1,
    products: {
      title: "Logo Training Tee",
      image: productTshirt,
      description:
        "You're only one workout away from a better mood. Slip on thi...",
      price: "910.89 KR",
    },
    brand: { image: adidasImg, title: "Adidas" },
    ppc: "0.25 KR",
    clicks: "45+",
    sell: "15",
    sale: "10%",
  },
  {
    key: 2,
    products: {
      title: "Nike Air Max 90 Men's Shoes",
      image: productTwo,
      description: "Nothing as fly, nothing as comfortable, nothing as proven.",
      price: "917.42 KR",
    },
    brand: { image: nikeLogo, title: " Nike" },
    ppc: "0.52 KR",
    clicks: "40",
    sell: "20",
    sale: "5%",
  },
  {
    key: 3,
    products: {
      title: "Nike Air Max 90 Men's Shoes",
      image: productTwo,
      description: "Nothing as fly, nothing as comfortable, nothing as proven.",
      price: "346.13 KR",
    },
    brand: { image: nikeLogo, title: " Nike" },
    ppc: "0.52 KR",
    clicks: "38",
    sell: "12",
    sale: "4%",
  },
];
export const notificationData = [
  {
    key: "1",
    label: "Alla",
    children: "Content of Tab Pane 1",
  },
  {
    key: "2",
    label: "Förfrågningar",
    children: "Content of Tab Pane 2",
  },
  {
    key: "3",
    label: "Åtkomstförfrågningar",
    children: "Content of Tab Pane 3",
  },
];

export const notificationTabData = [
  {
    key: "",
    label: "Alla",
  },
  {
    key: "CUSTOM",
    label: "Förfrågningar",
  },
  {
    key: "ACCESS",
    label: "Åtkomstförfrågningar",
  },
];

export const notificationTabInnerData = [
  {
    category: "brandRequest",
    logoImg: nikeWhite,
    logoAlt: "nike logo",
    title: "Du har ett meddelande från The Kybalion AB",
    time: "2h",
    description: (
      <>
        Grattis 🎊! Du har fått en förfrågan från{" "}
        <span className="fw-medium">The Kybalion AB</span>.
      </>
    ),
  },
  {
    category: "accessRequest",
    logoImg: tommyHilfigerLogoImg,
    logoAlt: "tommy hilfiger logo",
    title: "Access Request Approved!😍",
    time: "4h",
    description: (
      <>
        your access request has been approved from tommy highflier{" "}
        <span className="fw-medium">Air Jordan 11 Retro Low Blac..</span>
      </>
    ),
    productsImgSrc: [
      {
        imgSrc: productOne,
      },
      {
        imgSrc: productThree,
      },
    ],
  },
  {
    category: "accessRequest",
    logoImg: zaraImg,
    logoBg: "var(--clr-crystal-bell)",
    logoAlt: "zara logo",
    title: "Begäran om åtkomst avvisades!😢",
    time: "6h",
    description: (
      <>
        your access request has been Rejected from zara official{" "}
        <span className="fw-medium">Makeups collection</span>
      </>
    ),
    productsImgSrc: [
      {
        imgSrc: productOne,
      },
      {
        imgSrc: productTwo,
      },
      {
        imgSrc: productThree,
      },
      {
        imgSrc: productFive,
      },
    ],
  },
  {
    category: "brandRequest",
    logoImg: zaraImg,
    logoBg: "var(--clr-crystal-bell)",
    logoAlt: "zara logo",
    title: "Zara Official",
    time: "2h",
    description: (
      <>
        Congrats✨! you got custom request message from Nike. publish their
        products to your store.
      </>
    ),
  },
  {
    category: "brandRequest",
    logoImg: adidas,
    logoAlt: "adidas logo",
    logoBg: "var(--clr-crystal-bell)",
    title: "Adidas Official",
    time: "3h",
    description: (
      <>
        Congrats😇! you got custom request message from Nike. publish their
        products to your store.
      </>
    ),
  },
  {
    category: "accessRequest",
    logoImg: sinRamen,
    logoAlt: "sin ramen logo",
    logoBg: "var(--clr-black)",
    title: "Access Request Approved!😍",
    time: "5h",
    description: (
      <>
        your access request has been Approved from{" "}
        <span className="fw-medium">Sin Ramen Lounge</span>
      </>
    ),
    productsImgSrc: [
      {
        imgSrc: serviceTwo,
      },
    ],
  },
];

export const productAndBrandCollectionData = [
  {
    thumbsClassName: "thumb-gallery-slider-one",
    title: "T-shirt",
    subTitle: "189 Items",
    ILProductGallerySliderList: [
      {
        mainImage: productThree,
        thumbImage: productThree,
      },
      {
        mainImage: productEight,
        thumbImage: productEight,
      },
      {
        mainImage: productSeven,
        thumbImage: productSeven,
      },
    ],
    addToCollectionBtn: true,
  },
  {
    thumbsClassName: "thumb-gallery-slider-two",
    title: "Makeups",
    subTitle: "16 Products",
    ILProductGallerySliderList: [
      {
        mainImage: earphones,
        thumbImage: earphones,
      },
      {
        mainImage: nikeImg,
        thumbImage: nikeImg,
      },
      {
        mainImage: earphones,
        thumbImage: earphones,
      },
    ],
    addToCollectionBtn: true,
  },
  {
    thumbsClassName: "thumb-gallery-slider-three",
    title: "Shoes",
    subTitle: "81 Products",
    ILProductGallerySliderList: [
      {
        mainImage: productOne,
        thumbImage: productOne,
      },
      {
        mainImage: productTwo,
        thumbImage: productTwo,
      },
      {
        mainImage: productOne,
        thumbImage: productOne,
      },
    ],
    addToCollectionBtn: true,
  },
  {
    thumbsClassName: "thumb-gallery-slider-four",
    title: "Watches",
    subTitle: "664 Products",
    ILProductGallerySliderList: [
      {
        mainImage: earphones,
        thumbImage: earphones,
      },
      {
        mainImage: nikeImg,
        thumbImage: nikeImg,
      },
      {
        mainImage: earphones,
        thumbImage: earphones,
      },
    ],
    addToCollectionBtn: true,
  },
];

export const myProductCardData = [
  {
    productData: [
      {
        image: productTen,
        logo: nikeLogo,
        label: "Nike",
        description: "Nike Air Max Koko Women's Sandals ",
        amount: "165.69 KR",
        isBookNowBtn: true,
        isBg: true,
        isMyProductImg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productTwelve,
        logo: sugarCosmetics,
        label: "Sugar Cosmetics",
        description: "Transfer proof Lipstick",
        amount: "351.02 KR",
        isBookNowBtn: true,
        isBg: true,
        isMyProductImg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productTen,
        logo: pumaImg,
        label: "Puma",
        description: "Palermo Unisex Sneakers",
        amount: "693.17 KR",
        isBookNowBtn: true,
        isBg: true,
        isMyProductImg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productTwelve,
        logo: sugarCosmetics,
        label: "Sugar Cosmetics",
        description: "Mettle Liquid Lipstick",
        amount: "630.44 KR",
        isBookNowBtn: true,
        isBg: true,
        isMyProductImg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productEleven,
        logo: adidasImg,
        label: "Adidas",
        description: "Woman Yoga backpack ",
        amount: "695.00 KR",
        isBookNowBtn: true,
        isBg: true,
        isMyProductImg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productFour,
        logo: adidasImg,
        label: "Adidas",
        description: "Nike Air Max Koko Women's Sandals ",
        amount: "165.69 KR",
        isBookNowBtn: true,
        isBg: true,
        isMyProductImg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productTen,
        logo: nikeLogo,
        label: "Nike",
        description: "Nike Air Max 90 Men's Shoes",
        amount: "750.00 KR",
        isBookNowBtn: true,
        isBg: true,
        isMyProductImg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productThirteen,
        logo: nikeLogo,
        label: "Nike",
        description: "Cropped check blazerhoulder Bag",
        amount: "943.65 KR",
        isBookNowBtn: true,
        isBg: true,
        isMyProductImg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productNine,
        logo: nikeLogo,
        label: "Nike",
        description: "Rim cat-eye sunglasses",
        amount: "695.00 KR",
        isBookNowBtn: true,
        isBg: true,
        isMyProductImg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productTwo,
        logo: ralphLaurenImg,
        label: " Ralph Lauren",
        description: "Sports cap",
        amount: "917.42 KR",
        isBookNowBtn: true,
        isBg: true,
        isMyProductImg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productTen,
        logo: apple,
        label: "Apple",
        description: "Air buds Pro 2nd generation",
        amount: "943.65 KR",
        isBookNowBtn: true,
        isBg: true,
        isMyProductImg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productOne,
        logo: nikeLogo,
        label: "Nike",
        description: "Cropped check blazerhoulder Bag",
        amount: "943.65 KR",
        isBookNowBtn: true,
        isBg: true,
        isMyProductImg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productTwelve,
        logo: ralphLaurenImg,
        label: " Ralph Lauren",
        description: "Welington Calfskin Shoulder Bag",
        amount: "943.65 KR",
        isBookNowBtn: true,
        isBg: true,
        isMyProductImg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productThree,
        logo: nikeLogo,
        label: "Nike",
        description: "Cropped check blazerhoulder Bag",
        amount: "943.65 KR",
        isBookNowBtn: true,
        isBg: true,
        isMyProductImg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productNine,
        logo: nikeLogo,
        label: "Nike",
        description: "Cropped check blazerhoulder Bag",
        amount: "943.65 KR",
        isBookNowBtn: true,
        isBg: true,
        isMyProductImg: true,
      },
    ],
  },
];

export const liveProfileServicesData = [
  {
    productData: [
      {
        image: serviceOne,
        logo: freshaSalon,
        label: "Fresha Saloon",
        title: "Haircut & beard trimming",
        rating: 4.9,
        address: "Office 1991, Al Ghubrah Street, 9,Muscat, Muscat Governorate",
        amount: "165.72 KR",
        isBookNowBtn: true,
      },
    ],
  },
  {
    productData: [
      {
        image: serviceTwo,
        logo: sinRamen,
        label: "Sin Ramen Lounge",
        title: "Book a restaurant reservation",
        rating: 4.9,
        address: "Office 1991, Al Ghubrah Street, 9,Muscat, Muscat Governorate",
        amount: "165.72 KR",
        isBookNowBtn: true,
      },
    ],
  },
  {
    productData: [
      {
        image: serviceThree,
        logo: freshaSalon,
        label: "Fresha Saloon",
        title: "Massage services for women",
        rating: 4.9,
        address: "Office 1991, Al Ghubrah Street, 9,Muscat, Muscat Governorate",
        amount: "165.72 KR",
        isBookNowBtn: true,
      },
    ],
  },
  {
    productData: [
      {
        image: serviceFour,
        logo: royalBeautySalon,
        label: "Roya Beauty Salon",
        title: "Clean spa services for women",
        rating: 4.8,
        address: "Skytteholm, Solna",
        amount: "165.72 KR",
        isBookNowBtn: true,
      },
    ],
  },
  {
    productData: [
      {
        image: serviceOne,
        logo: freshaSalon,
        label: "Fresha Saloon",
        title: "Haircut & beard trimming",
        rating: 4.9,
        address: "Office 1991, Al Ghubrah Street, 9,Muscat, Muscat Governorate",
        amount: "165.72 KR",
        isBookNowBtn: true,
      },
    ],
  },
  {
    productData: [
      {
        image: serviceTwo,
        logo: sinRamen,
        label: "Sin Ramen Lounge",
        title: "Haircut & Beard Trimming",
        rating: 4.9,
        address: "Office 1991, Al Ghubrah Street, 9,Muscat, Muscat Governorate",
        amount: "165.72 KR",
        isBookNowBtn: true,
      },
    ],
  },
  {
    productData: [
      {
        image: serviceThree,
        logo: freshaSalon,
        label: "Fresha Saloon",
        title: "Massage services for women",
        rating: 4.9,
        address: "Office 1991, Al Ghubrah Street, 9,Muscat, Muscat Governorate",
        amount: "165.72 KR",
        isBookNowBtn: true,
      },
    ],
  },
  {
    productData: [
      {
        image: serviceFour,
        logo: royalBeautySalon,
        label: "Roya Beauty Salon",
        title: "Clean spa services for women",
        rating: 4.8,
        address: "Skytteholm, Solna",
        amount: "165.72 KR",
        isBookNowBtn: true,
      },
    ],
  },
];

export const viewCollectionCardData = [
  {
    productData: [
      {
        image: productFourteen,
        logo: sugarCosmetics,
        label: "Sugar Cosmetics",
        description: "Eyeshadow Palette",
        amount: "165.69 KR",
        isBookNowBtn: true,
        isBg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productTwelve,
        logo: sugarCosmetics,
        label: "Sugar Cosmetics",
        description: "Transfer proof Lipstick",
        amount: "351.02 KR",
        isBookNowBtn: true,
        isBg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productSixteen,
        logo: sugarCosmetics,
        label: "Sugar Cosmetics",
        description: "Grand Finale Matte Setting Mist",
        amount: "693.17 KR",
        isBookNowBtn: true,
        isBg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productFifteen,
        logo: sugarCosmetics,
        label: "Sugar Cosmetics",
        description: "Transfer proof Lipstick",
        amount: "630.44 KR",
        isBookNowBtn: true,
        isBg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productSeventeen,
        logo: sugarCosmetics,
        label: "Sugar Cosmetics",
        description: "Matte Loose Powder Makeup",
        amount: "695.00 KR",
        isBookNowBtn: true,
        isBg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productFifteen,
        logo: sugarCosmetics,
        label: "Sugar Cosmetics",
        description: "Transfer proof Lipstick",
        amount: "165.69 KR",
        isBookNowBtn: true,
        isBg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productFourteen,
        logo: sugarCosmetics,
        label: "Sugar Cosmetics",
        description: "Eyeshadow Palette",
        amount: "750.00 KR",
        isBookNowBtn: true,
        isBg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productSixteen,
        logo: sugarCosmetics,
        label: "Sugar Cosmetics",
        description: "Grand Finale Matte Setting Mist",
        amount: "943.65 KR",
        isBookNowBtn: true,
        isBg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productSeventeen,
        logo: sugarCosmetics,
        label: "Sugar Cosmetics",
        description: "Matte Loose Powder Makeup",
        amount: "917.42 KR",
        isBookNowBtn: true,
        isBg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productFifteen,
        logo: sugarCosmetics,
        label: "Sugar Cosmetics",
        description: "Transfer proof Lipstick",
        amount: "695.00 KR",
        isBookNowBtn: true,
        isBg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productSixteen,
        logo: sugarCosmetics,
        label: "Sugar Cosmetics",
        description: "Grand Finale Matte Setting Mist",
        amount: "943.65 KR",
        isBookNowBtn: true,
        isBg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productSeventeen,
        logo: sugarCosmetics,
        label: "Sugar Cosmetics",
        description: "Matte Loose Powder Makeup",
        amount: "943.65 KR",
        isBookNowBtn: true,
        isBg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productTwelve,
        logo: sugarCosmetics,
        label: "Sugar Cosmetics",
        description: "Welington Calfskin Shoulder Bag",
        amount: "943.65 KR",
        isBookNowBtn: true,
        isBg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productFourteen,
        logo: sugarCosmetics,
        label: "Sugar Cosmetics",
        description: "Eyeshadow Palette",
        amount: "943.65 KR",
        isBookNowBtn: true,
        isBg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productFifteen,
        logo: sugarCosmetics,
        label: "Sugar Cosmetics",
        description: "Transfer proof Lipstick",
        amount: "943.65 KR",
        isBookNowBtn: true,
        isBg: true,
      },
    ],
  },
  {
    productData: [
      {
        image: productFifteen,
        logo: sugarCosmetics,
        label: "Sugar Cosmetics",
        description: "Transfer proof Lipstick",
        amount: "943.65 KR",
        isBookNowBtn: true,
        isBg: true,
      },
    ],
  },
];

export const productSellClickList = [
  {
    data: "256 KR",
    title: "Totala intäkter",
    image: earningsIcon,
  },
  {
    data: "60",
    title: "Totala klick",
    image: clickIcon,
  },
  {
    data: "12",
    title: "Totala försäljningar",
    image: sellIcon,
  },
];

export const dsAllCollectionsSelectOption = [
  {
    value: "myCollection",
    label: "Mina Kollektioner",
  },
  {
    value: "brandCollection",
    label: "Företagsskollektioner",
  },
];
export const socialMediaAccountsData = [
  {
    imgSrc: instagramIcon,
    text: "Instagram",
  },
  {
    imgSrc: facebookIcon,
    text: "Facebooks",
  },
  {
    imgSrc: tiktokLogo,
    text: "TikTok",
  },
  {
    imgSrc: twitterIcon,
    text: "Twitter",
  },
  {
    imgSrc: youtubeIcon,
    text: "YouTube",
  },
];

export const personalInterestCardData = [
  {
    icon: (
      <>
        <Shirt01Icon size={16} color={"#db2f7f"} variant={"stroke"} />
      </>
    ),
    text: "Fashion & apparel",
  },
  {
    icon: (
      <>
        <Hotel01Icon size={16} color={"#db2f7f"} variant={"stroke"} />
      </>
    ),
    text: "Travel & Hotels",
  },
  {
    icon: (
      <>
        <BodyPartMuscleIcon size={16} color={"#db2f7f"} variant={"stroke"} />
      </>
    ),
    text: "Personal Training",
  },
  {
    icon: (
      <>
        <FootballIcon size={16} color={"#db2f7f"} variant={"stroke"} />
      </>
    ),
    text: "Sports & outdoor",
  },
  {
    icon: (
      <>
        <NewOfficeIcon size={16} color={"#db2f7f"} variant={"stroke"} />
      </>
    ),
    text: "Home improvement",
  },
];

export const viewServicesColumns = [
  {
    title: "Tjänster/Plats",
    dataIndex: "services_location",
    key: "services_location",
    render: (item, _) => (
      <div className="d-flex align-items-center gap-4">
        <div className={clsx("ratio ratio-1x1 product-service-img")}>
          <img
            className={"object-fit-cover h-100 w-100"}
            src={item.image}
            alt={item.title}
          />
        </div>
        <div>
          <p className="fw-medium mb-2">{item.title}</p>
          <p className="sm mb-0 text-gray text-ellipsis line-clamp-1">
            {item.description}
          </p>
          <p className="fw-medium mb-0">{item.price}</p>
        </div>
      </div>
    ),
    width: "400px",
  },
  {
    title: "Företag",
    dataIndex: "brand",
    key: "brand",
    width: "200px",
    render: (item, _) => (
      <>
        <div className="d-flex gap-3 align-items-center">
          <div className="table-logo-img">
            <img
              width={"100%"}
              height={"100%"}
              className={"object-fit-cover"}
              src={item.image}
              alt={item.title}
            />
          </div>
          <p className="fw-medium mb-0">{item.title}</p>
        </div>
      </>
    ),
  },
  {
    title: "Omdömen",
    dataIndex: "ratings",
    key: "ratings",
    render: (item, _) => (
      <>
        <div className="d-flex align-items-center gap-4 gap-xxl-3">
          {Icons.star}
          <p className="fw-medium mb-0">{item}</p>
        </div>
      </>
    ),
    width: "150px",
  },
  {
    title: "Klicks",
    dataIndex: "clicks",
    key: "clicks",
  },
  {
    title: "CPC",
    dataIndex: "ppc",
    key: "ppc",
    render: (title, _) => (
      <p className="fw-medium gradient-text max-w-max-content">{title}</p>
    ),
  },
  {
    title: "Försäljning",
    dataIndex: "sell",
    key: "sell",
  },
  {
    title: "Provision %",
    dataIndex: "sale",
    key: "sale",
    render: (title, _) => (
      <p className="fw-medium gradient-text max-w-max-content">{title}</p>
    ),
  },
  {
    title: "Redigera",
    dataIndex: "action",
    key: "action",
    render: (_) => (
      <>
        <div className="d-flex align-items-center gap-5 action-btns-wrap">
          <span className="w-50 text-center">
            <ILSocialShareMedia />
          </span>
          <span className="w-50 text-center">
            <ILDeleteCollectionModal />
          </span>
        </div>
      </>
    ),
    width: "100px",
    fixed: "right",
  },
];

export const viewServicesData = [
  {
    key: 1,
    services_location: {
      title: "Haircut & Beard Trimming",
      image: serviceOne,
      description: "2118 Thornridge Cir. Syracuse, Connecticut 35624",
      price: "778.35 KR",
    },
    ratings: "4.0 (43)",
    brand: { image: freshaSalon, title: "Fresha Saloon" },
    ppc: "0.31 KR",
    clicks: "43",
    sell: "20",
    sale: "15 %",
  },
  {
    key: 2,
    services_location: {
      title: "Classic Pedicure",
      image: serviceTwo,
      description: "2464 Royal Ln. Mesa, New Jersey 45463",
      price: "576.28 KR",
    },
    ratings: "4.0 (40)",
    brand: { image: ralphLaurenImg, title: "Ralph Lauren" },
    ppc: "0.23 KR",
    clicks: "40",
    sell: "15",
    sale: "16 %",
  },
  {
    key: 3,
    services_location: {
      title: "Full Body Massage",
      image: serviceThree,
      description: "8502 Preston Rd. Inglewood, Maine 98380",
      price: "219.78 KR",
    },
    ratings: "4.9 (40)",
    brand: { image: freshaSalon, title: "Fresha Saloon" },
    ppc: "0.36 KR",
    clicks: "40",
    sell: "11",
    sale: "06 %",
  },
  {
    key: 4,
    services_location: {
      title: "Clean Spa Services for women",
      image: serviceFour,
      description: "4140 Parker Rd. Allentown, New Mexico 31134",
      price: "275.43 KR",
    },
    ratings: "4.0 (37)",
    brand: { image: royalBeautySalon, title: "Roya Beauty Salon" },
    ppc: "0.37 KR",
    clicks: "37",
    sell: "37",
    sale: "08 %",
  },
  {
    key: 5,
    services_location: {
      title: "Amazon Keratine / Brazilian blow...",
      image: serviceFour,
      description: "2715 Ash Dr. San Jose, South Dakota 83475",
      price: "943.65 KR",
    },
    ratings: "3.5 (38)",
    brand: { image: nikeLogo, title: "Nike" },
    ppc: "0.21 KR",
    clicks: "38",
    sell: "16",
    sale: "12 %",
  },
  {
    key: 6,
    services_location: {
      title: "Waxing Package",
      image: serviceFour,
      description: "6381 Elgin St. Celina, Delaware 10299",
      price: "169.43 KR",
    },
    ratings: "3.5 (38)",
    brand: { image: freshaSalon, title: "Fresha Saloon" },
    ppc: "0.50 KR",
    clicks: "38",
    sell: "10",
    sale: "12 %",
  },
  {
    key: 7,
    services_location: {
      title: "Book a Restaurant Reservation",
      image: serviceFour,
      description: "2972 Westheimer Rd. Santa Ana, Illinois 85486",
      price: "782.01 KR",
    },
    ratings: "4.9 (35)",
    brand: { image: nikeLogo, title: "Nike" },
    ppc: "0.25 KR",
    clicks: "35",
    sell: "06",
    sale: "12 %",
  },
  {
    key: 8,
    services_location: {
      title: "Gellish Removal",
      image: serviceFour,
      description: "4517 Washington Ave. Manchester, Kentucky 39495",
      price: "779.58 KR",
    },
    ratings: "4.5 (30)",
    brand: { image: royalBeautySalon, title: "Roya Beauty Salon" },
    ppc: "0.24 KR",
    clicks: "30",
    sell: "08",
    sale: "12 %",
  },
  {
    key: 9,
    services_location: {
      title: "Gel Manicure & Pedicure Combo",
      image: serviceFour,
      description: "3891 Ranchview Dr. Richardson, California 62639",
      price: "948.55 KR",
    },
    ratings: "3.0 (29)",
    brand: { image: freshaSalon, title: "Fresha Saloon" },
    ppc: "0.15 KR",
    clicks: "29",
    sell: "12",
    sale: "12 %",
  },
  {
    key: 10,
    services_location: {
      title: "Roots Color And Blowdry",
      image: serviceFour,
      description: "2715 Ash Dr. San Jose, South Dakota 83475",
      price: "767.50 KR",
    },
    ratings: "4.0 (26)",
    brand: { image: royalBeautySalon, title: "Roya Beauty Salon" },
    ppc: "0.31 KR",
    clicks: "26",
    sell: "13",
    sale: "12 %",
  },
];

export const viewProductsColumns = [
  {
    title: "Produkter",
    dataIndex: "products",
    key: "products",
    render: (item, _) => (
      <div className="d-flex align-items-center gap-4">
        <div className={clsx("ratio ratio-1x1 product-service-img")}>
          <img
            className={"object-fit-cover h-100 w-100"}
            src={item.image}
            alt={item.title}
          />
        </div>
        <div>
          <p className="fw-medium mb-2">{item.title}</p>
          <p className="sm mb-0 text-gray text-ellipsis line-clamp-1">
            {item.description}
          </p>
          <p className="fw-medium mb-0">{item.price}</p>
        </div>
      </div>
    ),
    width: "400px",
  },
  {
    title: "Företag",
    dataIndex: "brand",
    key: "brand",
    width: "200px",
    render: (item, _) => (
      <>
        <div className="d-flex gap-3 align-items-center">
          <div className="table-logo-img">
            <img
              width={"100%"}
              height={"100%"}
              className={"object-fit-cover"}
              src={item.image}
              alt={item.title}
            />
          </div>
          <p className="fw-medium mb-0">{item.title}</p>
        </div>
      </>
    ),
  },
  {
    title: "Klicks",
    dataIndex: "clicks",
    key: "clicks",
  },
  {
    title: "CPC",
    dataIndex: "ppc",
    key: "ppc",
    render: (title, _) => (
      <p className="fw-medium gradient-text max-w-max-content">{title}</p>
    ),
  },
  {
    title: "Försäljning",
    dataIndex: "sell",
    key: "sell",
  },
  {
    title: "Provision %",
    dataIndex: "sale",
    key: "sale",
    render: (title, _) => (
      <p className="fw-medium gradient-text max-w-max-content">{title}</p>
    ),
  },
  {
    title: "Redigera",
    dataIndex: "action",
    key: "action",
    render: (_) => (
      <>
        <div className="d-flex align-items-center gap-5 action-btns-wrap">
          <span className="w-50 text-center">
            <ILSocialShareMedia />
          </span>
          <span className="w-50 text-center">
            <ILDeleteCollectionModal />
          </span>
        </div>
      </>
    ),
    fixed: "right",
  },
];

export const viewProductsData = [
  {
    key: 1,
    products: {
      title: "Logo Training Tee",
      image: productTshirt,
      description:
        "You're only one workout away from a better mood. Slip on this...",
      price: "350.00 KR",
    },
    brand: {
      image: adidasImg,
      title: "Adidas",
    },
    clicks: "46",
    ppc: "0.25 KR",
    sell: 20,
    sale: "10 %",
  },
  {
    key: 2,
    products: {
      title: "Nike Air Max 90 Men's Shoes",
      image: productTen,
      description:
        "Nothing as fly, nothing as comfortable, nothing as proven...",
      price: "750.00 KR",
    },
    brand: {
      image: nikeImg,
      title: "Nike",
    },
    clicks: "43",
    ppc: "0.52 KR",
    sell: 14,
    sale: "10 %",
  },
  {
    key: 3,
    products: {
      title: "Wellington Calfskin Shoulder Bag",
      image: productTshirt,
      description:
        "Part of the Ralph Lauren Wellington collection, this should...",
      price: "598.00 KR",
    },
    brand: {
      image: ralphLaurenImg,
      title: "Ralph Lauren",
    },
    clicks: "42",
    ppc: "0.65 KR",
    sell: 12,
    sale: "10 %",
  },
  {
    key: 4,
    products: {
      title: "Air Jordan 11 Retro Low Black Varsity",
      image: productTwo,
      description: "Nike Air Max Koko Women's Sandals",
      price: "165.69 KR",
    },
    brand: {
      image: nikeImg,
      title: "Nike",
    },
    clicks: "40",
    ppc: "0.65 KR",
    sell: "08",
    sale: "10 %",
  },
  {
    key: 5,
    products: {
      title: "Embroidered Flag Cotton Tee",
      image: productEight,
      description:
        "Treated for a vintage-inspired look, this crewneck tee is ar...",
      price: "153.20 KR",
    },
    brand: {
      image: ralphLaurenImg,
      title: "Ralph Lauren",
    },
    clicks: "35",
    ppc: "0.65 KR",
    sell: 12,
    sale: "10 %",
  },
];
export const myAccessRequestTabData = [
  {
    key: "",
    label: "Alla",
  },
  {
    key: "PENDING",
    label: "Väntande",
  },
  {
    key: "ACCEPTED",
    label: "Accepterade",
  },
  {
    key: "REJECTED",
    label: "Avvisade",
  },
];

export const myAccessRequestTabInnerData = [
  {
    category: "accepterade",
    logoImg: tommyHilfigerLogoImg,
    logoAlt: "tommy hilfiger logo",
    title: "Access Request Approved!😍",
    time: "4h",
    description: (
      <>
        your access request has been approved from tommy highflier{" "}
        <span className="fw-medium">Air Jordan 11 Retro Low Blac..</span>
      </>
    ),
    productsImgSrc: [
      {
        imgSrc: productOne,
      },
      {
        imgSrc: productThree,
      },
    ],
  },
  {
    category: "avvisade",
    logoImg: zaraImg,
    logoBg: "var(--clr-crystal-bell)",
    logoAlt: "zara logo",
    title: "Begäran om åtkomst avvisades!😢",
    time: "6h",
    description: (
      <>
        your access request has been Rejected from zara official{" "}
        <span className="fw-medium">Makeups collection</span>
      </>
    ),
    productsImgSrc: [
      {
        imgSrc: productOne,
      },
      {
        imgSrc: productTwo,
      },
      {
        imgSrc: productThree,
      },
      {
        imgSrc: productFive,
      },
    ],
  },
  {
    category: "väntande",
    logoImg: adidas,
    logoAlt: "sin ramen logo",
    logoBg: "var(--clr-crystal-bell)",
    title: "Pending Access Request!",
    time: "12:01 pm",
    description: (
      <>
        Your access request has been Rejected from Zara official Begs collection
      </>
    ),
    productsImgSrc: [
      {
        imgSrc: serviceTwo,
      },
    ],
  },
  {
    category: "avvisade",
    logoImg: sinRamen,
    logoAlt: "sin ramen logo",
    logoBg: "var(--clr-black)",
    title: "Begäran om åtkomst avvisades!😢",
    time: "5h",
    description: (
      <>
        your access request has been Approved from{" "}
        <span className="fw-medium">Sin Ramen Lounge</span>
      </>
    ),
    productsImgSrc: [
      {
        imgSrc: serviceTwo,
      },
    ],
  },
  {
    category: "accepterade",
    logoImg: sinRamen,
    logoAlt: "sin ramen logo",
    logoBg: "var(--clr-black)",
    title: "Access Request Approved!😍",
    time: "5h",
    description: (
      <>
        your access request has been Approved from{" "}
        <span className="fw-medium">Sin Ramen Lounge</span>
      </>
    ),
    productsImgSrc: [
      {
        imgSrc: serviceTwo,
      },
    ],
  },
  {
    category: "väntande",
    logoImg: tommyHilfigerLogoImg,
    logoAlt: "tommy hilfiger logo",
    title: "Pending Access Request!",
    time: "4h",
    description: (
      <>
        your access request has been pending from tommy highflier{" "}
        <span className="fw-medium">Air Jordan 11 Retro Low Blac..</span>
      </>
    ),
    productsImgSrc: [
      {
        imgSrc: productOne,
      },
      {
        imgSrc: productThree,
      },
    ],
  },
];

export const ILProfileCardsList = [
  {
    image: "https://i.pravatar.cc/68",
    name: "Miles, Esther",
    address: "Nepal",
    brandCategoryBadgeList: [
      {
        badgeIcon: <>{Icons.brush}</>,
        badgeTitle: "Food & Grocery",
      },
      {
        badgeIcon: <>{Icons.dumbell}</>,
        badgeTitle: "Health & Wellness",
      },
    ],
    earningContentList: [
      {
        image: clickIcon,
        number: "25K",
        label: "Clicks",
      },
      {
        image: clickIcon,
        number: "9.2M",
        label: "Sell",
      },
      {
        image: clickIcon,
        number: "2M+",
        label: "Shop visitors",
      },
    ],
  },
];

export const chatRowDataList = [
  {
    profileImg: "https://i.pravatar.cc/48",
    profileName: "Nike Sweden",
    message: "Hello, We are From Nike..",
    time: "7:30 am",
    unread: true,
    unreadMsgNumber: 2,
  },
  {
    profileImg: "https://i.pravatar.cc/48",
    profileName: "Nike Sweden",
    message: "Hello, We are From Nike..",
    time: "8:56 am",
  },
  {
    profileImg: "https://i.pravatar.cc/48",
    profileName: "Paypal",
    message: "Thank you 😊",
    time: "7:30 am",
    doubleTick: true,
  },
  {
    profileImg: "https://i.pravatar.cc/48",
    profileName: "Zara Official",
    message: "Thank you 😊",
    time: "Yesterday",
    doubleTick: true,
  },
  {
    profileImg: "https://i.pravatar.cc/48",
    profileName: "Boat Official",
    message: "Hello, We are From Boat official....",
    time: "7:30 am",
    unread: true,
    unreadMsgNumber: 2,
  },
];

export const supportAnHelpAccordionData = [
  {
    key: "1",
    label: "Vad är ILONSI?",
    children: `ILONSI är en affiliateplattform där influencers och sociala medieanvändare kan marknadsföra produkter och tjänster från både etablerade varumärken och nya entreprenörer. Genom att dela länkar och rekommendera produkter får du som influencer betalt.`,
  },
  {
    key: "2",
    label: "Hur fungerar ILONSI för influencers?",
    children: `Som influencer får du notiser när företag vill samarbeta med dig. När du godkänner samarbetet läggs företagets produkter eller tjänster upp i din ILONSI butik. Du tjänar pengar både på klick och provision på försäljning, förutsatt att företaget har angett en provision.`,
  },
  {
    key: "3",
    label: "Hur fungerar det med klick?",
    children: `Du delar företagets länkar i dina inlägg eller i din butik. För varje klick på din länk tjänar du pengar. Ju fler klick, desto mer kan du tjäna!`,
  },
  {
    key: "4",
    label: "Hur mycket kan jag tjäna på ILONSI som influencer?",
    children: `Din inkomst beror på hur många klick och försäljningar du genererar. Du får betalt för både klick och försäljningar som sker via din länk. Ju mer engagemang och trafik du får, desto mer kan du tjäna. ILONSI gör det möjligt att tjäna pengar även utan en stor följarskara.`,
  },
  {
    key: "5",
    label:
      "Kan man tjäna pengar utan att sälja eller generera trafik med ILONSI?",
    children: `Ja! Förutom att tjäna pengar på klick och försäljning kommer ILONSI att introducera möjligheten att växla besökare till pengar under kommande uppdateringar. Att lägga din butikslänk i din bio och dela den regelbundet i dina inlägg och händelser kommer att gynna dig. På ILONSI handlar det om att ha kul och skapa engagemang!`,
  },
  {
    key: "6",
    label: "Hur kan jag öka mina chanser att få samarbete som influencer?",
    children: `För att öka dina chanser att få samarbeten, fokusera på att engagera dina följare och skapa intressant och autentiskt innehåll. Företag söker ofta influencers med bra engagemang, inte bara stora följarantal och som passar deras varumärke och värderingar.`,
  },
  {
    key: "7",
    label:
      "Vilka typer av produkter och tjänster kan jag marknadsföra på ILONSI?",
    children: `På ILONSI kan du marknadsföra produkter och tjänster inom en mängd olika kategorier, inklusive mode, skönhet, hälsa, elektronik, och mycket mer. Alla produkter och tjänster är noggrant utvalda för att passa influencers inom olika nischer.`,
  },
  {
    key: "8",
    label: "Hur fungerar skatter på min inkomst som influencer?",
    children: `Som ILONSI-användare är din inkomst från samarbeten och försäljning skattepliktig. För att förenkla denna process samarbetar ILONSI med GigaPay.co, en svensk Fintech som hanterar skatterapportering och utbetalningar. Du kan få betalt direkt via Swish och GigaPay.co ser till att allt sköts korrekt och snabbt, utan administrativt krångel.`,
  },
  {
    key: "9",
    label: "Hittar du inte svaret på din fråga?",
    children: (
      <>
        Om du inte hittar svaret på din fråga, maila oss på&nbsp;
        <a href="mailto:info@ilonsi.com">info@ilonsi.com</a>&nbsp;DM:a oss på
        Instagram&nbsp;
        <a href="https://www.instagram.com/ilonsisverige" target="_blank">
          @ilonsisverige
        </a>
        , &nbsp;eller ring oss på&nbsp;
        <a href="tel:0700-649429">0700-649429</a>.
      </>
    ),
  },
];

export const myTransactionHistoryColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: "200px",
  },
  {
    title: "Email id",
    dataIndex: "emailId",
    key: "emailId",
    width: "100px",
  },
  {
    title: "Phone Number",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    width: "200px",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: "200px",
  },
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
    render: (item, _) => (
      <>
        <div className="d-flex gap-3 align-items-center">
          <div className="table-logo-img">
            <img
              className={"object-fit-cover rounded-circle w-100 h-100"}
              src={item.image}
              alt={item.title}
            />
          </div>
          <p className="fw-medium mb-0">{item.text}</p>
        </div>
      </>
    ),
    width: "150px",
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
    render: (title, _) => (
      <p className="fw-medium gradient-text max-w-max-content">{title}</p>
    ),
    width: "100px",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    width: "100px",
  },
];

export const myTransactionHistoryData = [
  {
    key: 1,
    name: "Cody Fisher",
    emailId: "cody.fisher@gmail.com",
    phoneNumber: "+34 334 443 333",
    description: "Text",
    country: {
      text: "Sweden",
      image: swedenFlag,
    },
    currency: "KR",
    amount: 598.0,
  },
  {
    key: 2,
    name: "Cheyenne Vaccaro",
    emailId: "cheyenne.vaccaro@gmail.com",
    phoneNumber: "+1 889 988 123",
    description: "Click earning",
    country: {
      text: "Sweden",
      image: swedenFlag,
    },
    currency: "KR",
    amount: 350.0,
  },
  {
    key: 3,
    name: "Jacob Jones",
    emailId: "jacob.jones@gmail.com",
    phoneNumber: "+39 678 890 000",
    description: "Text",
    country: {
      text: "Sweden",
      image: swedenFlag,
    },
    currency: "KR",
    amount: 750.0,
  },
  {
    key: 4,
    name: "Ronald Richards",
    emailId: "ronald.richards@gmail.com",
    phoneNumber: "+34 855 855 999",
    description: "Text",
    country: {
      text: "Sweden",
      image: swedenFlag,
    },
    currency: "KR",
    amount: 165.69,
  },
  {
    key: 5,
    name: "Jacob Jones",
    emailId: "jacob.jones@gmail.com",
    phoneNumber: "+39 678 890 000",
    description: "Text",
    country: {
      text: "Sweden",
      image: swedenFlag,
    },
    currency: "KR",
    amount: 750.0,
  },
  {
    key: 6,
    name: "Jacob Jones",
    emailId: "jacob.jones@gmail.com",
    phoneNumber: "+39 678 890 000",
    description: "Text",
    country: {
      text: "Sweden",
      image: swedenFlag,
    },
    currency: "KR",
    amount: 750.0,
  },
];
export const reportMessageRadioList = [
  {
    title: "Spam",
    value: "spam",
  },
  {
    title: "Vuxeninnehåll",
    value: "vuxeninnehåll",
  },
  {
    title: "Bedrägeri",
    value: "bedrägeri",
  },
  {
    title: "Skadligt eller olagligt",
    value: "skadligtEllerOlagligt",
  },
  // {
  //   title: "Imminent Physical harm",
  //   value: "Imminent Physical harm",
  // },
  {
    title: "Desinformation",
    value: "desinformation",
  },
  {
    title: "Annat",
    value: "annat",
  },
];
export const ILPagesListData = [
  {
    link: "/",
    title: "Hem",
  },
  {
    link: "/about-us",
    title: "Om oss",
  },
  {
    link: "/influencers",
    title: "Influencers",
  },
  {
    link: "/brands",
    title: "Företag",
  },
  {
    link: "/faq",
    title: "FAQ’s",
  },
];
export const selectProductBreakPoints = {
  0: {
    slidesPerView: 1.2,
    spaceBetween: 10,
  },
  576: {
    slidesPerView: 2,
    spaceBetween: 10,
  },
  768: {
    slidesPerView: 2.5,
    spaceBetween: 15,
  },
  992: {
    slidesPerView: 3.5,
    spaceBetween: 15,
  },
  1200: {
    slidesPerView: 4,
    spaceBetween: 20,
  },
};
export const recentReviewsSwiperBreakPoints = {
  0: {
    slidesPerView: 1.1,
    spaceBetween: 10,
  },
  375: {
    slidesPerView: 1.2,
    spaceBetween: 10,
  },
  576: {
    slidesPerView: 1.4,
    spaceBetween: 10,
  },
  768: {
    slidesPerView: 2,
    spaceBetween: 15,
  },
  992: {
    slidesPerView: 2.7,
    spaceBetween: 15,
  },
  1200: {
    slidesPerView: 3.2,
    spaceBetween: 15,
  },
  1600: {
    slidesPerView: 4.2,
    spaceBetween: 20,
  },
  1800: {
    slidesPerView: 4.5,
    spaceBetween: 40,
  },
};

export const viewProductsCollectionColumn = [
  {
    title: "Produkter",
    dataIndex: "products",
    key: "products",
    render: (item, _) => (
      <div className="d-flex align-items-center gap-4">
        <div className={clsx("ratio ratio-1x1 product-service-img")}>
          <img
            className={"object-fit-cover h-100 w-100"}
            src={item.image}
            alt={item.title}
          />
        </div>
        <div>
          <p className="fw-medium mb-2">{item.title}</p>
          <p className="sm mb-0 text-gray text-ellipsis line-clamp-1">
            {item.description}
          </p>
          <p className="fw-medium mb-0">{item.price}</p>
        </div>
      </div>
    ),
    width: "400px",
  },
  {
    title: "Företag",
    dataIndex: "brand",
    key: "brand",
    width: "200px",
    render: (item, _) => (
      <>
        <div className="d-flex gap-3 align-items-center">
          <div className="table-logo-img">
            <img
              width={"100%"}
              height={"100%"}
              className={"object-fit-cover"}
              src={item?.image}
              alt={item.title}
            />
          </div>
          <p className="fw-medium mb-0">{item.title}</p>
        </div>
      </>
    ),
  },
  {
    title: "Klicks",
    dataIndex: "clicks",
    key: "clicks",
  },
  {
    title: "CPC",
    dataIndex: "ppc",
    key: "ppc",
    render: (title, _) => (
      <p className="fw-medium gradient-text max-w-max-content">{title}</p>
    ),
  },
  {
    title: "Försäljning",
    dataIndex: "sell",
    key: "sell",
  },
  {
    title: "Provision %",
    dataIndex: "sale",
    key: "sale",
    render: (title, _) => (
      <p className="fw-medium gradient-text max-w-max-content">{title}</p>
    ),
  },
];

export const viewProductsCollectionData = [
  {
    key: 1,
    products: {
      title: (
        <Link
          to="/insights-contents/my-products/product-details"
          className="text-dark-purple"
        >
          Logo Training Tee
        </Link>
      ),
      image: productTshirt,
      description:
        "You're only one workout away from a better mood. Slip on this...",
      price: "350.00 KR",
    },
    brand: {
      image: adidasImg,
      title: "Adidas",
    },
    clicks: "46",
    ppc: "0.25 KR",
    sell: 20,
    sale: "10 %",
  },
  {
    key: 2,
    products: {
      title: (
        <Link
          to="/insights-contents/my-products/product-details"
          className="text-dark-purple"
        >
          Nike Air Max 90 Men's Shoes
        </Link>
      ),
      image: productTen,
      description:
        "Nothing as fly, nothing as comfortable, nothing as proven...",
      price: "750.00 KR",
    },
    brand: {
      image: nikeImg,
      title: "Nike",
    },
    clicks: "43",
    ppc: "0.52 KR",
    sell: 14,
    sale: "10 %",
  },
  {
    key: 3,
    products: {
      title: (
        <Link
          to="/insights-contents/my-products/product-details"
          className="text-dark-purple"
        >
          Wellington Calfskin Shoulder Bag
        </Link>
      ),
      image: productTshirt,
      description:
        "Part of the Ralph Lauren Wellington collection, this should...",
      price: "598.00 KR",
    },
    brand: {
      image: ralphLaurenImg,
      title: "Ralph Lauren",
    },
    clicks: "42",
    ppc: "0.65 KR",
    sell: 12,
    sale: "10 %",
  },
  {
    key: 4,
    products: {
      title: (
        <Link
          to="/insights-contents/my-products/product-details"
          className="text-dark-purple"
        >
          Air Jordan 11 Retro Low Black Varsity,
        </Link>
      ),
      image: productTwo,
      description: "Nike Air Max Koko Women's Sandals",
      price: "165.69 KR",
    },
    brand: {
      image: nikeImg,
      title: "Nike",
    },
    clicks: "40",
    ppc: "0.65 KR",
    sell: "08",
    sale: "10 %",
  },
  {
    key: 5,
    products: {
      title: (
        <Link
          to="/insights-contents/my-products/product-details"
          className="text-dark-purple"
        >
          Embroidered Flag Cotton Tee
        </Link>
      ),
      image: productEight,
      description:
        "Treated for a vintage-inspired look, this crewneck tee is ar...",
      price: "153.20 KR",
    },
    brand: {
      image: ralphLaurenImg,
      title: "Ralph Lauren",
    },
    clicks: "35",
    ppc: "0.65 KR",
    sell: 12,
    sale: "10 %",
  },
];

export const brandCategoriesTabItem = [
  {
    key: "1",
    label: "Produkter",
  },
  {
    key: "2",
    label: "Tjänster",
  },
];

export const radioList = [
  {
    text: "produkter",
  },
  {
    text: "Tjänster",
  },
  {
    text: "Företagsskollektioner",
  },
];

export const msgProductCardData = [
  {
    image: zaraImg,
  },
  {
    image: vsImg,
  },
  {
    image: productSix,
  },
  {
    image: earphones,
  },
  {
    image: nikeImg,
  },
  {
    image: fossil,
  },
];
export const msgPreviewProductBreakPoints = {
  0: {
    slidesPerView: 1.2,
    spaceBetween: 15,
  },
  361: {
    slidesPerView: 1.4,
    spaceBetween: 15,
  },
  400: {
    slidesPerView: 1.5,
    spaceBetween: 15,
  },
};
export const CSGenderFilterCheckbox = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const CSCountryFilterCheckbox = [
  {
    label: "Sweden",
  },
  {
    label: "Sri Lanka",
  },
  {
    label: "China",
  },
  {
    label: "Cameroon",
  },
  {
    label: "Philippines",
  },
  {
    label: "Cambodia",
  },
  {
    label: "Tonga",
  },
  {
    label: "Botswana",
  },
  {
    label: "Bahrain",
  },
  {
    label: "Guatemala",
  },
];

export const CSBrandCategoriesFilterCheckbox = [
  {
    label: "T-shirt",
  },
  {
    label: "Track",
  },
  {
    label: "Dress",
  },
];
export const CSClicksFilterCheckbox = [
  {
    label: "Clicks",
  },
];
export const CSSellFilterCheckbox = [
  {
    label: "Sell",
  },
];

export const CSShopClicksFilterCheckbox = [
  {
    label: "1 - 200",
    value: "1-200",
  },
  {
    label: "200 - 400",
    value: "200-400",
  },
  {
    label: "400 - 600",
    value: "400-600",
  },
  {
    label: "600 - 800",
    value: "600-800",
  },
  {
    label: "800 - 1000",
    value: "800-1000",
  },
  {
    label: "1000+",
    value: "1000above",
  },
];

export const CSProductPriceFilterCheckbox = [
  {
    label: "1 - 100",
    value: "1-100",
  },
  {
    label: "100 - 200",
    value: "100-200",
  },
  {
    label: "200 - 300",
    value: "200-300",
  },
  {
    label: "300 - 400",
    value: "300-400",
  },
  {
    label: "400 - 500",
    value: "400-500",
  },
  {
    label: "500+",
    value: "500above",
  },
];

export const CSPayPerClickFilterCheckbox = [
  {
    label: "1 - 20",
    value: "1-20",
  },
  {
    label: "20 - 40",
    value: "20-40",
  },
  {
    label: "40 - 60",
    value: "40-60",
  },
  {
    label: "60 - 80",
    value: "60-80",
  },
  {
    label: "80 - 100",
    value: "80-100",
  },
  {
    label: "100+",
    value: "100above",
  },
];

export const CSShopVisitorsFilterCheckbox = [
  {
    label: "1 - 50",
    value: "1-50",
  },
  {
    label: "50 - 100",
    value: "50-100",
  },
  {
    label: "100 - 150",
    value: "100-150",
  },
  {
    label: "150 - 200",
    value: "150-200",
  },
  {
    label: "200+",
    value: "200above",
  },
];
export const applyFilterCreatorSearchData = [
  {
    key: "gender",
    label: "Gender",
    children: <ILCSFilterCheckboxList CSGenderFilterCheckboxCon />,
  },
  {
    key: "country",
    label: "Country",
    children: <ILCSFilterCheckboxList CSCountryFilterCheckboxCon />,
  },
  {
    key: "clicks",
    label: "Clicks",
    children: <ILCSFilterCheckboxList CSClicksFilterCheckboxCon />,
  },
  {
    key: "sell",
    label: "Sell",
    children: <ILCSFilterCheckboxList CSSellFilterCheckboxCon />,
  },
  {
    key: "visitors",
    label: "Shop visitors",
    children: <ILCSFilterCheckboxList CSShopVisitorsFilterCheckboxCon />,
  },
];

export const productBrandCollectionBreakPoints = {
  0: {
    slidesPerView: 1.5,
    spaceBetween: 10,
  },
  375: {
    slidesPerView: 1.6,
    spaceBetween: 10,
  },
  400: {
    slidesPerView: 2,
    spaceBetween: 10,
  },
  576: {
    slidesPerView: 2.4,
    spaceBetween: 10,
  },
  768: {
    slidesPerView: 3.2,
    spaceBetween: 10,
  },
  992: {
    slidesPerView: 4.1,
    spaceBetween: 15,
  },
  1200: {
    slidesPerView: 4.8,
    spaceBetween: 15,
  },
  1366: {
    slidesPerView: 5,
    spaceBetween: 15,
  },
  1440: {
    slidesPerView: 5.5,
    spaceBetween: 15,
  },
  1600: {
    slidesPerView: 6,
    spaceBetween: 20,
  },
};
