import React from "react";
import clsx from "clsx";
import { formatTime } from "../../services";
import { Icons } from "../../constants/icons";
import styles from "./ILChatRow.module.css";
import { InView } from "react-intersection-observer";
import { ILLoader } from "../ILLoader";

export const ILChatRow = ({
  ref,
  selectedRoom,
  observerScroll,
  chatRowDataList,
  handleGetDetails,
}) => {
  return (
    <>
      <div className={clsx(styles.chatRowWrap, "h-inherit")}>
        {chatRowDataList &&
          chatRowDataList?.rows?.map((item, index) => {
            return (
              <>
                <div
                  key={index}
                  className={clsx(
                    styles.chatRow,
                    `cursor-pointer ${
                      selectedRoom?.room_fk === item?.room_fk && "active"
                    }`
                  )}
                  onClick={() => handleGetDetails(item)}
                >
                  <div className="d-flex align-items-center gap-4 gap-md-3 w-100">
                    <div
                      className={clsx(
                        styles.chatProfileImg,
                        "bg-clr-black-gray w-100 h-100 flex-0-auto d-flex align-items-center justify-content-center ratio ratio-1x1 ratio-w-100 rounded-circle"
                      )}
                    >
                      <img
                        src={item?.other_user?.room_member?.profile_image}
                        alt={item?.other_user?.room_member?.name}
                        className="radius-inherit object-fit-cover w-100 h-100 max-w-100"
                      />
                    </div>
                    <div>
                      <p className="fw-semibold mb-0 lh-normal text-ellipsis line-clamp-2 word-break-word">
                        {item?.other_user?.room_member?.name}
                      </p>
                      {item?.other_user?.last_massage?.length > 0 && (
                        <div className="d-flex align-items-center gap-3 gap-xxl-2 lh-normal">
                          {/* <span className="text-royal-peacock lh-noraml">
                            {item && Icons.doubleTickIcon}
                          </span> */}
                          <p
                            className={clsx(
                              "sm fw-normal mb-0 text-ellipsis line-clamp-1 word-break-word lh-normal",
                              !item.unread && "text-gray"
                            )}
                          >
                            {item?.other_user?.last_massage[0]?.message}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  {item?.other_user?.last_massage?.length > 0 && (
                    <div className="d-flex flex-column align-items-end flex-0-auto justify-content-between">
                      <p className="sm fw-medium text-gray mb-1">
                        {" "}
                        {formatTime(
                          item?.other_user?.last_massage[0]?.created_at
                        )}
                      </p>
                      {selectedRoom?.room_fk !== item?.room_fk &&
                        item.unread_messages_count > 0 && (
                          <span className={styles.unreadMsgBadge}>
                            {item.unread_messages_count}
                          </span>
                        )}
                    </div>
                  )}
                </div>
              </>
            );
          })}

        {chatRowDataList?.currentPage < chatRowDataList?.totalPages && (
          <InView
            as="div"
            onChange={(inView, entry) =>
              inView && observerScroll(entry, "users")
            }
            className={clsx(styles.chatRow)}
          >
            <div ref={ref} className="w-100 text-center">
              <ILLoader
                classname={"loader-wrap-absolute bg-transparent"}
                isLoaderBgOpacitySm
                isChatLoader
              />
              <h6>Loading...</h6>
            </div>
          </InView>
        )}
      </div>
    </>
  );
};
