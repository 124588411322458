import { Link } from "react-router-dom";
import { ArrowLeft02Icon } from "hugeicons-react";
import { clsx } from "clsx";
import {
  ILAddYourCollectionModal,
  ILButton,
  ILHead,
  ILModal,
  ILPagination,
  ILProductCard,
  ILSkeleton,
} from "../../components";
import { Col, Row } from "antd";
import styles from "./ILBrandCollections.module.css";
import { useBrandCollectionsHook } from "../../hooks/website/brandCollections.hook";
import { capitalize } from "lodash";
import { countHandler } from "../../services";
import {
  defaultUser,
  gradientCircleRound,
  nikeLogo,
} from "../../constants/imageData";

const ILBrandCollections = () => {
  const {
    isLoading,
    isLoggedIn,
    productList,
    paginationData,
    isCollectionLoading,
    isAccessReqModalOpen,
    isShopCollectionModalOpen,
    isSuccessfullyReqModalOpen,
    isCollectionAddedModalOpen,
    isCollectionAlreadyAddedModalOpen,
    showSCModal,
    showARModal,
    showSRModal,
    handleARModalOk,
    handleSRModalOk,
    handleCAModalOk,
    handleSCModalOk,
    myShopNavigation,
    handleTableChange,
    handleARModalCancel,
    handleProductDetail,
    navigateMyAccessReq,
    handleSRModalCancel,
    handleSCModalCancel,
    handleAddToShopCollection,
  } = useBrandCollectionsHook();

  return (
    <>
      <ILHead title="Företagsskollektioner" />
      <section className="default-inner-hero position-relative">
        <div className="container">
          <div className="d-flex align-items-center gap-3">
            <Link to="/brands">
              <ArrowLeft02Icon
                size={34}
                color={"#1D1128"}
                variant={"stroke"}
                strokeWidth={2}
              />
            </Link>
            <h4 className="fw-semibold lh-22 mb-0">Företagsskollektioner</h4>
          </div>
        </div>
      </section>
      <section className="section-md-pb">
        <div className="container">
          <Row>
            <Col xs={24}>
              <div
                className={clsx(
                  styles.productCollectionWRap,
                  "d-flex justify-content-between align-items-sm-center flex-sm-row flex-column align-items-start gap-5 gap-md-4"
                )}
              >
                <div
                  className={clsx(
                    styles.productCollectionCard,
                    "radius-md position-relative gradient-border-box"
                  )}
                >
                  <div
                    className={clsx(
                      styles.productCollectionContentCard,
                      "bg-white w-100 h-100 radius-sm d-flex flex-column"
                    )}
                  >
                    <div className="d-flex align-items-center">
                      {isLoading ? (
                        <ILSkeleton isNormalParagraph />
                      ) : (
                        <>
                          <div
                            className="brand-logo flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center overflow-hidden ratio ratio-1x1 ratio-w-100"
                            style={{ borderColor: "#BA2026" }}
                          >
                            <img
                              src={
                                isLoading
                                  ? defaultUser
                                  : productList?.collection?.brand
                                      ?.profile_image
                              }
                              alt="advertising agency | ilonsi"
                              className="object-fit-cover w-100 h-100 max-w-100"
                            />
                          </div>
                          <div className="w-100">
                            <h5 className="fw-semibold text-dark-purple">
                              {capitalize(productList?.collection?.name)}{" "}
                              Kollektion
                            </h5>
                            <p className="big fw-medium text-dark-purple mb-0">
                              {countHandler(productList?.totalItems)}
                              &nbsp;&nbsp;Items
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {isLoggedIn &&
                  ((productList?.collection?.has_access === "ACCESS_NEEDED" ||
                    productList?.collection?.has_access === "ACCESS_DENIED") &&
                  productList?.collection?.is_added_to_shop == 0 ? (
                    <ILButton
                      isSmallSizeBtn
                      isArrow
                      ILBtnClass={"mt-3 text-clr-pink"}
                      variant={"gradient-bordered"}
                      handleClick={showARModal}
                    >
                      Skicka förfrågan
                    </ILButton>
                  ) : productList?.collection?.has_access ===
                    "PENDING_REQUEST" ? (
                    <ILButton
                      disabled
                      isSmallSizeBtn
                      isArrow
                      ILBtnClass={"mt-5 mt-xxl-4 text-clr-pink"}
                      variant={"gradient-bordered"}
                    >
                      Förfrågan Skickad
                    </ILButton>
                  ) : productList?.collection?.is_added_to_shop == 0 &&
                    !isLoading ? (
                    <ILAddYourCollectionModal
                      className="w-auto"
                      collectionData={productList}
                      showSCModal={showSCModal}
                    />
                  ) : (
                    ""
                  ))}
              </div>

              <div className={clsx(styles.brandCollectionListGrid, "d-grid")}>
                {isLoading ? (
                  <div className="row">
                    <div className="skeleton-card border">
                      <ILSkeleton isImgTitleLabelCard2 />
                    </div>
                  </div>
                ) : (
                  productList?.rows?.map((item, index) => {
                    return (
                      <ILProductCard
                        key={index}
                        productDataShow={item}
                        noButtons
                        isProductLabel
                        handleProductDetail={handleProductDetail}
                      />
                    );
                  })
                )}
              </div>
            </Col>
            {(productList?.hasPrevious || productList?.hasNext) && (
              <Col xs={24}>
                <ILPagination
                  defaultCurrent={paginationData?.page}
                  defaultPageSize={paginationData?.pageSize}
                  total={productList?.totalItems}
                  onChange={handleTableChange}
                />
              </Col>
            )}
          </Row>
        </div>
      </section>

      <ILModal
        open={isCollectionAddedModalOpen}
        onOk={() => handleCAModalOk("new")}
        onCancel={() => handleCAModalOk("new")}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <span className="lh-0">
                <img
                  src={gradientCircleRound}
                  fluid
                  alt="advertising agency | ilonsi"
                />
              </span>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Din kollektion har lagts till!
              </h4>
              <ILButton
                ILBtnClass="w-100"
                variant={"primary-gradient"}
                handleClick={myShopNavigation}
              >
                Visa i min butik
              </ILButton>
            </div>
          </>
        }
        isFooterRemove
      />

      <ILModal
        open={isCollectionAlreadyAddedModalOpen}
        onOk={() => handleCAModalOk("already")}
        onCancel={() => handleCAModalOk("already")}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <span className="lh-0">
                <img
                  src={gradientCircleRound}
                  fluid
                  alt="advertising agency | ilonsi"
                />
              </span>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Din kollektion har lagts till redan!
              </h4>
              <ILButton
                ILBtnClass="w-100"
                variant={"primary-gradient"}
                handleClick={myShopNavigation}
              >
                Visa i min butik
              </ILButton>
            </div>
          </>
        }
        isFooterRemove
      />

      <ILModal
        open={isShopCollectionModalOpen}
        onOk={handleSCModalOk}
        onCancel={handleSCModalCancel}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <div
                className="brand-logo brand-logo-big flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center mx-auto"
                style={{ borderColor: "#000000" }}
              >
                <img
                  src={productList?.collection?.brand?.profile_image}
                  height={50}
                  width={50}
                  alt="advertising agency | ilonsi"
                  className="object-fit-contain img-fluid"
                />
              </div>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Vill du lägga till den här kollektionen i din butik?
              </h4>
              <p className="paragraph-sm-text paragraph-md-text text-center mb-0">
                Denna kollektion kommer att läggas till i din butik.
              </p>
              <div className="d-flex align-items-center gap-4 modal-more-btn">
                <ILButton
                  disabled={isCollectionLoading}
                  ILBtnClass="w-50"
                  variant={"primary-gradient"}
                  onClick={handleAddToShopCollection}
                >
                  Ja, Lägg till
                </ILButton>
                <ILButton
                  disabled={isCollectionLoading}
                  ILBtnClass="w-50"
                  variant={"gradient-bordered"}
                  onClick={handleSCModalCancel}
                >
                  Nej, Avbryt
                </ILButton>
              </div>
            </div>
          </>
        }
        isFooterRemove
      />

      <ILModal
        open={isAccessReqModalOpen}
        onOk={handleARModalOk}
        onCancel={handleARModalCancel}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <div
                className="brand-logo brand-logo-big flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center mx-auto"
                style={{ borderColor: "#000000" }}
              >
                <img
                  src={nikeLogo}
                  alt="advertising agency | ilonsi"
                  className="object-fit-contain img-fluid"
                />
              </div>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Bekräfta för att skicka Samarbetsförfrågan
              </h4>
              <p className="paragraph-sm-text paragraph-md-text text-center mb-0">
                Det valda företaget kommer att granska ditt konto för att ge
                bekräftelse om åtkomst.
              </p>
              <div className="d-flex align-items-center gap-4 modal-more-btn">
                <ILButton
                  disabled={isCollectionLoading}
                  ILBtnClass="w-50"
                  variant={"primary-gradient"}
                  onClick={showSRModal}
                >
                  Ja, Skicka
                </ILButton>
                <ILButton
                  disabled={isCollectionLoading}
                  ILBtnClass="w-50"
                  variant={"gradient-bordered"}
                  onClick={handleARModalCancel}
                >
                  Nej, Avbryt
                </ILButton>
              </div>
            </div>
          </>
        }
        isFooterRemove
      />
      {/* SUCCESSFULLY REQUET MODAL */}
      <ILModal
        open={isSuccessfullyReqModalOpen}
        onOk={handleSRModalOk}
        onCancel={handleSRModalCancel}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <span className="lh-0">
                <img
                  src={gradientCircleRound}
                  fluid
                  alt="advertising agency | ilonsi"
                />
              </span>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Förfrågan har skickats!
              </h4>
              <ILButton
                ILBtnClass="w-100"
                variant={"primary-gradient"}
                handleClick={navigateMyAccessReq}
              >
                Hantera förfrågningars
              </ILButton>
            </div>
          </>
        }
        isFooterRemove
      />
    </>
  );
};
export default ILBrandCollections;
