import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  doFetchBrandProducts,
  doFetchBrandServices,
  doFetchSendAccessRequest,
  doFetchSingleProductDetails,
  doFetchSingleServiceDetails,
} from "../../actions";
import { getDecryptionString } from "../../services";

export const useProductDetailsHook = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const [productData, setProductData] = useState({});
  const [isProduct, setIsProduct] = useState(
    location.pathname.includes("product-details")
  );
  const [productList, setProductList] = useState([]);
  const [isAddedToShop, setIsAddedToShop] = useState(true);
  const [relatedProductList, setRelatedProductList] = useState([]);
  const [productId, setProductId] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [apiStatus, setApiStatus] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("_token") ? true : false
  );
  const [isLoading, setIsLoading] = useState({
    detail: false,
    isRequestSent: false,
    productList: false,
    relatedProducts: false,
  });
  const [paginationData, setPaginationData] = useState({
    search: "",
    pageSize: 10,
    page: 1,
  });
  const [modalPaginationData, setModalPaginationData] = useState({
    search: "",
    pageSize: 10,
    page: 1,
  });
  const [isSuccessfullyReqModalOpen, setIsSuccessfullyReqModalOpen] =
    useState(false);
  const { userDetails } = useSelector(
    (state) => state.storeAuthenticateReducer
  );

  useEffect(() => {
    let queryParams = new URLSearchParams(location.search);
    userDetails &&
      queryParams.set("user-name", userDetails?.userData?.user_name);
    queryParams.set("res", apiStatus ? "true" : "false");

    navigate(`${location.pathname}?${queryParams.toString()}`, {
      replace: true,
    });
  }, [location?.pathname, userDetails, apiStatus]);

  useEffect(() => {
    if (isProduct && isAddedToShop) {
      fetchRelatedProducts(modalPaginationData);
    }
  }, [isProduct, productData, modalPaginationData?.page, isAddedToShop]);

  useEffect(() => {
    if (isLoggedIn && !userDetails) {
      setIsLoading((prev) => ({
        ...prev,
        detail: true,
      }));
      return;
    }

    if (params?.id && isProduct) {
      if (isAddedToShop) {
        if (isLoggedIn && userDetails) {
          fetchProductDetail(params?.id);
        } else {
          fetchProductDetail(params?.id);
        }
      }
    }
  }, [params, isProduct, userDetails, isLoggedIn, isAddedToShop]);

  useEffect(() => {
    if (params?.id && !isProduct) {
      if (isLoggedIn && userDetails?.userData?.id && isAddedToShop) {
        fetchServiceDetail(params?.id);
      } else {
        fetchServiceDetail(params?.id);
      }
    }
  }, [serviceId, params, isLoggedIn, isProduct, userDetails, isAddedToShop]);

  useEffect(() => {
    if (Object?.keys(productData)?.length > 0) {
      if (isLoggedIn) {
        if (userDetails?.userData?.id) {
          fetchProducts();
        }
      } else {
        fetchProducts();
      }
    }
  }, [productData, paginationData?.page, userDetails, isLoggedIn]);

  const loginHandler = () => {
    navigate("/login");
  };

  const handleTableChange = (event, type) => {
    try {
      if (type === "relatedProducts") {
        setPaginationData((prev) => ({
          ...prev,
          page: event,
        }));
      } else {
        setModalPaginationData((prev) => ({
          ...prev,
          page: event,
        }));
      }
    } catch (error) {
      console.error("Error occurred during pagination:", error);
    }
  };

  const handleSetProductId = (data) => {
    if (isProduct) {
      setProductId(data?.id);
    } else {
      setServiceId(data?.id);
    }
    navigate(
      `/brands/${isProduct ? "product" : "service"}-details/${data?.id}`
    );
  };

  const handleSetServiceDetail = (data) => {
    setServiceId(data?.id);
    navigate(`/brands/service-details/${data?.id}`);
  };

  const fetchProductDetail = async (id) => {
    try {
      setIsLoading((prevState) => ({ ...prevState, detail: true }));

      const productDetailResponse = await doFetchSingleProductDetails(
        id,
        isLoggedIn ? userDetails?.userData?.id : null
      );
      if (productDetailResponse?.status === 200) {
        setApiStatus(true);

        const decryptedProductDetail = await JSON.parse(
          getDecryptionString(productDetailResponse?.data)
        );

        setProductData(decryptedProductDetail);
      } else {
        setApiStatus(false);
        toast.error(productDetailResponse?.message);
      }
      setIsLoading((prevState) => ({ ...prevState, detail: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prevState) => ({ ...prevState, detail: false }));
    }
  };

  const fetchServiceDetail = async (id) => {
    try {
      setIsLoading((prevState) => ({ ...prevState, detail: true }));

      const serviceDetailResponse = await doFetchSingleServiceDetails(
        id,
        userDetails?.userData?.id ? userDetails?.userData?.id : null
      );
      if (serviceDetailResponse?.status === 200) {
        setApiStatus(true);

        const decryptedServiceDetail = await JSON.parse(
          getDecryptionString(serviceDetailResponse?.data)
        );
        setProductData(decryptedServiceDetail);
      } else {
        setApiStatus(false);
        toast.error(productDetailResponse?.message);
      }
      setIsLoading((prevState) => ({ ...prevState, detail: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prevState) => ({ ...prevState, detail: false }));
    }
  };

  const fetchProducts = async () => {
    try {
      setIsLoading((prevState) => ({ ...prevState, productList: true }));

      let brandId = [],
        brandProductsResponse;
      brandId.push(productData?.brand?.id);
      const requestBody = {
        brand: brandId,
        hasAccess: true,
        userId: userDetails ? userDetails?.userData?.id : null,
        pageSize: paginationData?.pageSize,
        currentPage: paginationData?.page,
      };

      if (isProduct) {
        brandProductsResponse = await doFetchBrandProducts(requestBody);
      } else {
        brandProductsResponse = await doFetchBrandServices(requestBody);
      }

      if (brandProductsResponse?.status === 200) {
        const decryptedBrandProducts = await JSON.parse(
          getDecryptionString(brandProductsResponse?.data)
        );

        const filteredProductData = decryptedBrandProducts?.rows?.filter(
          (data) => data?.id !== params?.id
        );

        setProductList({
          ...decryptedBrandProducts,
          rows: filteredProductData,
        });
      } else {
        toast.error(brandProductsResponse?.message);
      }
      setIsLoading((prevState) => ({ ...prevState, productList: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prevState) => ({ ...prevState, productList: false }));
    }
  };

  const fetchRelatedProducts = async (data) => {
    try {
      setIsLoading((prevState) => ({ ...prevState, relatedProducts: true }));

      let brandId = [],
        brandProductsResponse;
      brandId.push(productData?.brand?.id);
      const requestBody = {
        brand: brandId,
        hasAccess: true,
        userId: isLoggedIn ? userDetails?.userData?.id : null,
        pageSize: data?.pageSize,
        currentPage: data?.page,
      };

      if (isProduct) {
        brandProductsResponse = await doFetchBrandProducts(requestBody);
      } else {
        brandProductsResponse = await doFetchBrandServices(requestBody);
      }

      if (brandProductsResponse?.status === 200) {
        const decryptedBrandProducts = await JSON.parse(
          getDecryptionString(brandProductsResponse?.data)
        );

        const filteredProductData = decryptedBrandProducts?.rows?.filter(
          (data) => data?.id !== params?.id
        );

        setRelatedProductList({
          ...decryptedBrandProducts,
          rows: filteredProductData,
        });
      } else {
        toast.error(brandProductsResponse?.message);
      }
      setIsLoading((prevState) => ({ ...prevState, relatedProducts: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prevState) => ({ ...prevState, relatedProducts: false }));
    }
  };

  const sendAccessRequest = async () => {
    try {
      setIsLoading((prevState) => ({ ...prevState, isRequestSent: true }));
      const requestBody = {
        type: "ACCESS_REQUEST",
        toUser: productData?.brand?.id,
        ...(isProduct
          ? { productId: productData?.id }
          : { serviceId: productData?.id }),
      };

      const accessRequestResponse = await doFetchSendAccessRequest(requestBody);

      if (accessRequestResponse?.status === 200) {
        setIsSuccessfullyReqModalOpen(true);

        if (isProduct) {
          fetchProductDetail(params?.id);
        } else {
          fetchServiceDetail(params?.id);
        }
      } else {
        toast.error(accessRequestResponse?.message);
      }
      setIsLoading((prevState) => ({ ...prevState, isRequestSent: true }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prevState) => ({ ...prevState, isRequestSent: true }));
    }
  };

  const handleSRModalClose = () => {
    setIsSuccessfullyReqModalOpen(false);
  };

  return {
    isProduct,
    isLoading,
    isLoggedIn,
    productList,
    productData,
    paginationData,
    relatedProductList,
    modalPaginationData,
    isSuccessfullyReqModalOpen,
    navigate,
    loginHandler,
    setIsAddedToShop,
    handleTableChange,
    sendAccessRequest,
    handleSetProductId,
    handleSRModalClose,
    handleSetServiceDetail,
    setModalPaginationData,
  };
};
