import { clsx } from "clsx";
import { capitalize } from "lodash";
import { Link } from "react-router-dom";
import styles from "./ILBrandsSelectionCard.module.css";

export const ILBrandsSelectionCard = ({ brandCardMap, handleBrandSelect }) => {
  return (
    <>
      {brandCardMap?.rows?.map((brandItem, brandIndex) => {
        return (
          <Link
            onClick={() => handleBrandSelect(brandItem?.brandDetail?.id)}
            className={clsx(styles.brandCard, "position-relative")}
            key={brandIndex}
          >
            <div className="position-relative z-1">
              <div
                className={clsx(
                  styles.brandLogo,
                  "flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center ratio ratio-1x1 ratio-w-100"
                )}
                style={{ borderColor: brandItem?.brandBorderColor }}
              >
                <img
                  src={brandItem?.brandDetail?.profile_image}
                  alt="advertising agency | ilonsi"
                  className="object-fit-cover radius-inherit w-100 h-100 max-w-100"
                />
              </div>
              <div className="mt-auto d-flex flex-column gap-3">
                <h5 className="p fw-medium big text-dark-purple mb-0 lh-base">
                  {capitalize(brandItem.brandDetail?.name)}
                </h5>
                {brandItem.brandDetail?.bio !== "" && (
                  <p className="paragraph-sm-text paragraph-md-text fw-medium mb-0 text-ellipsis line-clamp-1">
                    {brandItem.brandDetail?.bio}
                  </p>
                )}
              </div>
            </div>
          </Link>
        );
      })}
    </>
  );
};
