import { Col, Row } from "antd";
import { ILHead, ILInnerHero } from "../../components";

export const ILTermsCondition = () => {
  return (
    <>
      <ILHead title="Användarvillkor" />
      <ILInnerHero
        className="pb-5 hero-gradient-border"
        heroTitle="Användarvillkor"
        heroParagraph="Användarvillkor för Influensers hos ILONSI AB"
      />
      <section className="mt-4 section-sm-py">
        <div className="container">
          <Row className="d-flex flex-column gap-5">
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold lh-base">1. Introduktion</h4>
              <p className="big text-gray mb-0">
                Välkommen till ILONSI AB! Vi är ett affiliatenätverk som
                erbjuder en plattform där influensers kan marknadsföra produkter
                och tjänster på ett effektivt sätt, antingen genom sina egna
                ILONSI-butiker eller direkt via sina sociala medier. Företag som
                använder vår plattform betalar enbart för resultat – det innebär
                att kostnader uppstår först när trafik eller försäljning
                genereras genom samarbeten med influensers. Detta gör det
                möjligt för företag att samarbeta med influensers på ett
                kostnadseffektivt sätt, utan att behöva betala för reklam i
                förväg.
              </p>
              <p className="big text-gray mb-0">
                Genom att registrera dig, få tillgång till eller använda våra
                tjänster godkänner du att följa dessa villkor. Om du inte
                accepterar dessa villkor, vänligen använd inte vår plattform. Vi
                förbehåller oss rätten att när som helst uppdatera eller ändra
                dessa villkor. Alla ändringar träder i kraft vid publicering på
                vår plattform, och genom att fortsätta använda våra tjänster
                anses du ha accepterat de uppdaterade villkoren.
              </p>
            </Col>
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold lh-base">
                2. Tjänster som Tillhandahålls av ILONSI AB
              </h4>
              <p className="big text-gray mb-0">
                ILONSI AB erbjuder en plattform där influensers och företag kan
                samarbeta genom ett affiliatenätverk. Våra tjänster inkluderar:
              </p>
              <ul className="ps-5 d-flex flex-column gap-4 list-style-disc">
                <li className="p big text-gray mb-0">
                  Skapande av onlinebutik: Influensers kan skapa sina egna
                  onlinebutiker utan kostnad och marknadsföra produkter från
                  företag i dessa butiker.{" "}
                </li>
                <li className="p big text-gray mb-0">
                  Marknadsföring på sociala medier: Influensers kan även
                  marknadsföra företagets produkter och tjänster direkt på sina
                  sociala medier.{" "}
                </li>
                <li className="p big text-gray mb-0">
                  Samarbeten och Marknadsföring: Företag kan samarbeta med
                  influensers för att marknadsföra sina produkter via
                  influensers butiker eller sociala medier.{" "}
                </li>
                <li className="p big text-gray mb-0">
                  Provision och Kommission: Företag kan sätta upp provisioner
                  och regler för trafikgenerering. Influensers får betalning
                  baserat på de resultat de genererar.{" "}
                </li>
              </ul>
            </Col>
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold">Provision för Influensers:</h4>
              <p className="big text-gray">
                För varje klick och försäljning som genereras av influensers för
                företag, behåller influensers 50% av den kommission som
                företaget har angett på ILONSI.com. Det innebär att influensers
                får hälften av den intäkten som genereras via deras
                marknadsföring och trafik.
              </p>
            </Col>
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold">3. Registrering och Användarkonto</h4>
              <p className="big text-gray">
                För att använda våra tjänster måste du registrera ett konto på
                vår plattform. Vid registrering förbinder du dig att:{" "}
              </p>
              <ul className="ps-5 d-flex flex-column gap-4 list-style-disc">
                <li className="p big text-gray mb-0">
                  Tillhandahålla korrekt och fullständig information om dig
                  själv.{" "}
                </li>
                <li className="p big text-gray mb-0">
                  Hålla ditt konto och all information uppdaterad.{" "}
                </li>
                <li className="p big text-gray mb-0">
                  Ansvara för all aktivitet som sker via ditt konto.{" "}
                </li>
              </ul>
              <p className="big text-gray">
                Du är ansvarig för att skydda ditt konto och lösenord och
                förhindra obehörig åtkomst.
              </p>
            </Col>
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold">4. Användarens Ansvar</h4>
              <p className="big text-gray">
                Du förbinder dig att använda vår plattform och tjänster endast
                för lagliga syften och i enlighet med dessa villkor. Du får
                inte:{" "}
              </p>
              <ul className="ps-5 d-flex flex-column gap-4 list-style-disc">
                <li className="p big text-gray mb-0">
                  Publicera eller distribuera innehåll som är olagligt,
                  skadligt, hotande, ärekränkande, obscent, kränkande eller på
                  annat sätt olämpligt.{" "}
                </li>
                <li className="p big text-gray mb-0">
                  Inkräkta på någon annans immateriella rättigheter.{" "}
                </li>
                <li className="p big text-gray mb-0">
                  Försöka manipulera eller störa plattformens funktioner eller
                  integritet.{" "}
                </li>
                <li className="p big text-gray mb-0">
                  Föra vidare virus eller annan skadlig kod.{" "}
                </li>
              </ul>
              <p className="big text-gray">
                Om vi misstänker att du bryter mot dessa villkor, kan vi vidta
                åtgärder som att stänga av eller säga upp ditt konto.
              </p>
            </Col>
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold">
                5. Betalningar, Provisioner och Avgifter
              </h4>
              <p className="big text-gray">
                Alla tjänster och funktioner på vår plattform är gratis för
                influensers. Det tillkommer inga kostnader för att skapa en
                onlinebutik, publicera produkter eller delta i samarbeten med
                företag. För varje klick och försäljning som genereras av
                influensers för företag, behåller influensers **50%** av den
                kommission som företaget har angett på ILONSI.com.
              </p>
            </Col>
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold">6. Skatter och Utbetalningar</h4>
              <p className="big text-gray">
                För att säkerställa att influensers följer gällande
                skattelagstiftning, hanteras skattefrågor och utbetalningar via
                GigaPay.co. GigaPay.co ansvarar för att samla in och hantera
                skatter på betalningar som görs till influensers, samt för att
                utföra de nödvändiga skatteavdragen och utbetalningarna i
                enlighet med gällande lagar och regler.
              </p>
            </Col>
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold">
                7. Återbetalning av Medlemskap och Krediter
              </h4>
              <p className="big text-gray">
                För influensers tillkommer inga avgifter för att använda
                ILONSI:s tjänster, så det finns inga frågor om återbetalning för
                influensers. För företag gäller dock att medlemskap och krediter
                inte är återbetalningsbara.
              </p>
            </Col>
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold">8. Immateriella Rättigheter</h4>
              <p className="big text-gray">
                Allt innehåll, programvara, grafik, design och funktioner på vår
                plattform är vår egendom eller tillhör våra licensgivare och
                skyddas av immateriella rättigheter. Du får inte använda,
                kopiera eller distribuera något av vårt innehåll utan vårt
                uttryckliga tillstånd.
              </p>
            </Col>
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold">9. Sekretess och Personuppgifter</h4>
              <p className="big text-gray">
                Vi värnar om din integritet och hanterar dina personuppgifter i
                enlighet med gällande dataskyddslagar, inklusive GDPR. Du
                samtycker till att vi samlar in, lagrar och använder dina
                personuppgifter enligt vår integritetspolicy.
              </p>
            </Col>
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold">10. Ansvarsbegränsning </h4>
              <p className="big text-gray">
                Vår plattform tillhandahålls "som den är" utan några garantier.
                Vi garanterar inte att vår tjänst alltid kommer att vara felfri
                eller tillgänglig, och vi ansvarar inte för eventuella indirekta
                eller följdskador som uppstår genom användning av plattformen.
                Vi ansvarar inte heller för skador eller förluster som orsakas
                av användning av externa tjänster eller innehåll som publiceras
                av användare eller företag på plattformen.
              </p>
            </Col>
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold">11. Uppsägning av Konto </h4>
              <p className="big text-gray">
                Vi förbehåller oss rätten att avsluta eller tillfälligt stänga
                av ditt konto vid misstanke om att du har brutit mot dessa
                villkor. Du kan själv avsluta ditt konto när som helst genom att
                kontakta oss. Vid avslutning av ditt konto upphör alla
                rättigheter som du har på plattformen, men eventuella
                betalningar eller skyldigheter som uppkommit före avslutningen
                förblir bindande.
              </p>
            </Col>
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold">12. Force Majeure</h4>
              <p className="big text-gray">
                Vi är inte ansvariga för förseningar eller bristande uppfyllelse
                av våra åtaganden om detta orsakas av omständigheter utanför vår
                kontroll, såsom naturkatastrofer, pandemier, tekniska problem,
                eller andra oförutsedda händelser.
              </p>
            </Col>
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold">13. Ändringar av Villkoren</h4>
              <p className="big text-gray">
                Vi kan komma att uppdatera eller ändra dessa villkor vid behov.
                Ändringar träder i kraft när de publiceras på plattformen. Vi
                rekommenderar att du regelbundet granskar dessa villkor för att
                hålla dig informerad om eventuella uppdateringar.
              </p>
            </Col>
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold">
                14. Tillämpbar Lag och Tvistlösning
              </h4>
              <p className="big text-gray">
                Dessa villkor regleras av svensk lag. Vid tvist som inte kan
                lösas genom förhandling ska tvisten avgöras av svensk domstol.
              </p>
            </Col>
            <Col xs={24} className="d-flex flex-column gap-4 list-style-disc">
              <h4 className="fw-semibold lh-base">15. Kontaktinformation</h4>
              <p className="big text-gray">
                Om du har några frågor om dessa användarvillkor eller om vår
                plattform, vänligen kontakta oss via:
              </p>
              <ul className="d-flex flex-column gap-4 list-style-disc">
                <a
                  href="mailto:info@ilonsi.com"
                  className="p big text-gray mb-0"
                >
                  <b className="text-black me-2">Email:</b> info@ilonsi.com
                </a>
                <a href="telto:0700-649429" className="p big text-gray mb-0">
                  <b className="text-black me-2">Telefon:</b> 0700-649429
                </a>
                <a
                  href="https://g.co/kgs/dQHJ5ek"
                  className="p big text-gray mb-0"
                  target="_blank"
                >
                  <b className="text-black me-2">Adress:</b> Aschebergsgatan 21,
                  411 27, Göteborg.
                </a>
              </ul>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};
