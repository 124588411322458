import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getDecryptionString } from "../../../services";
import {
  doFetchAllCollection,
  doFetchDeleteCollection,
} from "../../../actions";
import { useNavigate } from "react-router-dom";
import { appConfig } from "../../../config";
import copy from "copy-to-clipboard";

export const useCollectionViewAllListHook = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [collectionId, setCollectionId] = useState("");
  const [collectionListData, setCollectionListData] = useState({});
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isSuccessfullyDeleteModalOpen, setIsSuccessfullyDeleteModalOpen] =
    useState(false);
  const [paginationData, setPaginationData] = useState({
    search: "",
    pageSize: 10,
    page: 1,
    collectionType: null,
  });
  const [selectedData, setSelectedData] = useState({});
  const [selectedSocialMedia, setSelectedSocialMedia] = useState({});
  const [isShareSMModalOpen, setIsShareSMModalOpen] = useState(false);

  useEffect(() => {
    fetchAllCollectionList(paginationData);
  }, [
    paginationData?.page,
    paginationData?.pageSize,
    paginationData?.collectionType,
  ]);

  const showShareSMModal = () => {
    setIsShareSMModalOpen(!isShareSMModalOpen);
  };

  const generateSharableLink = () => {
    window.open(
      `${selectedSocialMedia?.link}${appConfig.APP_URL}${selectedData?.data?.short_code}`,
      "_blank"
    );
    setSelectedSocialMedia({});
    setIsShareSMModalOpen(false);
  };

  const shareSocialMediaHandler = () => {
    setIsShareSMModalOpen(false);
    generateSharableLink();
  };

  const showDeleteConfirmModal = (data) => {
    setSelectedData({ data: data });
    setIsDeleteModal(!isDeleteModal);
  };

  const handleDeleteConfirmModalCancel = () => {
    setIsDeleteModal(false);
  };

  const handleSuccessfullyDeleteModalOk = () => {
    setIsSuccessfullyDeleteModalOpen(false);
    setSelectedData({});
  };

  const handleSelectChange = (value, name) => {
    setPaginationData((prev) => ({
      ...prev,
      [name]: value,
      page: 1,
    }));
  };

  const handleTableChange = (event, filter, sort) => {
    try {
      setPaginationData((prev) => ({
        ...prev,
        page: event,
        pageSize: filter,
      }));
    } catch (error) {
      console.error("Error occurred during pagination:", error);
    }
  };

  const fetchAllCollectionList = async (data) => {
    try {
      setIsLoading(true);

      const collectionListResponse = await doFetchAllCollection(
        data?.pageSize,
        data?.page,
        data?.search,
        data?.collectionType
      );

      if (collectionListResponse?.status === 200) {
        const decryptedProductList = await JSON.parse(
          getDecryptionString(collectionListResponse?.data?.data)
        );
        setCollectionListData(decryptedProductList);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error occurred during fetching all collections:", error);
    }
  };

  const handleDeleteCollectionSubmit = async () => {
    try {
      setIsDeleteLoading(true);
      const collectionDeleteResponse = await doFetchDeleteCollection(
        selectedData?.data?.id
      );
      if (collectionDeleteResponse?.status === 200) {
        setIsDeleteModal(false);
        setIsSuccessfullyDeleteModalOpen(true);
        fetchAllCollectionList(paginationData);
      } else {
        toast.error(collectionDeleteResponse?.data?.message);
      }
      setIsDeleteLoading(false);
    } catch (error) {
      setIsDeleteLoading(false);
      console.error("Error occurred during deleting collection:", error);
    }
  };

  const handleCopyLink = () => {
    copy(`${appConfig.APP_URL}collection/${selectedData?.data?.short_code}`);
    toast.success("Länk kopierad");
  };

  const handleSelectSocialMedia = (data) => {
    setSelectedSocialMedia(data);
  };

  return {
    isLoading,
    collectionId,
    selectedData,
    isDeleteModal,
    paginationData,
    isDeleteLoading,
    isShareSMModalOpen,
    collectionListData,
    selectedSocialMedia,
    isSuccessfullyDeleteModalOpen,
    navigate,
    handleCopyLink,
    setSelectedData,
    setCollectionId,
    showShareSMModal,
    handleTableChange,
    handleSelectChange,
    generateSharableLink,
    showDeleteConfirmModal,
    shareSocialMediaHandler,
    handleSelectSocialMedia,
    handleDeleteCollectionSubmit,
    handleDeleteConfirmModalCancel,
    handleSuccessfullyDeleteModalOk,
  };
};
