import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  doFetchAddCollection,
  doFetchBrandCollections,
  doFetchBrandDetails,
  doFetchBrandProducts,
  doFetchBrandServices,
  doFetchSendAccessRequest,
  doFetchSingleBrandDetails,
} from "../../actions";
import { getDecryptionString } from "../../services";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export const useSearchProductsHook = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [brandList, setBrandList] = useState([]);
  const [brandId, setBrandId] = useState([]);
  const [isAddedToShop, setIsAddedToShop] = useState(true);
  const [isLoading, setIsLoading] = useState({
    collectionAdd: false,
    brandList: false,
    productList: false,
    serviceList: false,
    relatedProductService: false,
    collectionList: false,
  });
  const [productList, setProductList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("_token") ? true : false
  );
  const [brandData, setBrandData] = useState({});
  const [collectionList, setCollectionList] = useState([]);
  const [isShopCollectionModalOpen, setIsShopCollectionModalOpen] =
    useState(false);
  const [isCollectionAddedModalOpen, setIsCollectionAddedModalOpen] =
    useState(false);
  const [
    isCollectionAlreadyAddedModalOpen,
    setIsCollectionAlreadyAddedModalOpen,
  ] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState({});
  const [isSuccessfullyReqModalOpen, setIsSuccessfullyReqModalOpen] =
    useState(false);
  const [apiStatus, setApiStatus] = useState(false);
  const [isRequestSentLoading, setIsRequestSentLoading] = useState(false);
  const [relatedProductList, setRelatedProductList] = useState([]);
  const [paginationData, setPaginationData] = useState({
    search: "",
    pageSize: 10,
    page: 1,
  });
  const { userDetails } = useSelector(
    (state) => state.storeAuthenticateReducer
  );

  useEffect(() => {
    let queryParams = new URLSearchParams(location.search);
    userDetails &&
      queryParams.set("user-name", userDetails?.userData?.user_name);
    queryParams.set("res", apiStatus ? "true" : "false");

    navigate(`${location.pathname}?${queryParams.toString()}`, {
      replace: true,
    });
  }, [location?.pathname, userDetails, apiStatus]);

  useEffect(() => {
    fetchBrandList();
    setBrandId([params?.id]);
  }, []);

  useEffect(() => {
    if (params?.id) {
      fetchBrandDetails();
    }
  }, [params?.id]);

  useEffect(() => {
    if (isLoggedIn && (!userDetails || !brandData)) {
      setIsLoading((prev) => ({
        ...prev,
        productList: true,
        collectionList: true,
      }));
      return;
    }

    if (isAddedToShop) {
      if (isLoggedIn) {
        if (userDetails && Object?.keys(brandData)?.length > 0) {
          if (brandData) {
            if (brandData?.brandDetail?.selling_type === "PRODUCT") {
              fetchProducts();
              fetchCollections();
            } else {
              fetchServices();
            }
          }
        }
      } else {
        if (brandData) {
          if (brandData?.brandDetail?.selling_type === "PRODUCT") {
            fetchProducts();
            fetchCollections();
          } else {
            fetchServices();
          }
        }
      }
    }
  }, [isLoggedIn, userDetails, brandData, isAddedToShop]);

  useEffect(() => {
    if (isLoggedIn) {
      if (userDetails?.userData?.id) {
        fetchRelatedProductsServices(paginationData);
      }
    } else {
      fetchRelatedProductsServices(paginationData);
    }
  }, [brandData, paginationData?.page, userDetails, isLoggedIn]);

  const showSCModal = (data) => {
    setSelectedCollection(data);
    setIsShopCollectionModalOpen(true);
  };

  const handleSCModalOk = () => {
    setIsShopCollectionModalOpen(false);
  };
  const handleSCModalCancel = () => {
    setIsShopCollectionModalOpen(false);
  };

  const handleCAModalOk = (name) => {
    if (name === "new") {
      setIsCollectionAddedModalOpen(!isCollectionAddedModalOpen);
    } else {
      setIsCollectionAlreadyAddedModalOpen(!isCollectionAlreadyAddedModalOpen);
    }
  };

  const myShopNavigation = () => {
    navigate("/dashboard/my-shop");
  };

  const handleAddToShopCollection = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, collectionAdd: true }));

      const requestBody = {
        brandCollectionId: selectedCollection?.id,
        categoryId: selectedCollection?.brand?.category_id,
      };

      const collectionAddResponse = await doFetchAddCollection(requestBody);

      if (collectionAddResponse?.status === 200) {
        setIsCollectionAddedModalOpen(true);
      } else if (collectionAddResponse?.status === 409) {
        setIsCollectionAlreadyAddedModalOpen(true);
      } else {
        toast.error(collectionAddResponse?.data?.message);
      }

      setIsShopCollectionModalOpen(false);
      fetchCollections();
      setIsLoading((prev) => ({ ...prev, collectionAdd: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prev) => ({ ...prev, collectionAdd: false }));
    }
  };

  const loginHandler = () => {
    navigate("/login");
  };

  const handleProductDetail = (data) => {
    navigate(`/brands/product-details/${data?.id}`);
  };

  const handleServiceDetail = (data) => {
    navigate(`/brands/service-details/${data?.id}`);
  };

  const handleCollectionDetail = (data) => {
    navigate(`/brand-collections/${data?.id}`);
  };

  const handleTableChange = (event) => {
    try {
      setPaginationData((prev) => ({
        ...prev,
        page: event,
      }));
    } catch (error) {
      console.error("Error occurred during pagination:", error);
    }
  };

  const sendAccessRequest = async (data) => {
    try {
      setIsRequestSentLoading(true);

      const requestBody = {
        type: "ACCESS_REQUEST",
        toUser: data?.brand?.id,
        ...(brandData?.brandDetail?.selling_type === "PRODUCT"
          ? {
              productId: data?.id,
            }
          : {
              serviceId: data?.id,
            }),
      };

      const accessRequestResponse = await doFetchSendAccessRequest(requestBody);

      if (accessRequestResponse?.status === 200) {
        setIsSuccessfullyReqModalOpen(true);
        if (brandData?.brandDetail?.selling_type === "PRODUCT") {
          fetchProducts();
        } else {
          fetchServices();
        }
      } else {
        toast.error(accessRequestResponse?.message);
      }
      setIsRequestSentLoading(false);
    } catch (error) {
      console.log({ error });
      setIsRequestSentLoading(false);
    }
  };

  const fetchBrandList = async (data) => {
    try {
      setIsLoading((prev) => ({ ...prev, brandList: true }));

      const requestBody = {
        pageSize: data?.pageSize,
        currentPage: data?.page,
        isPaginate: true,
        brandId: params?.id,
      };

      const brandsListResponse = await doFetchBrandDetails(requestBody);
      if (brandsListResponse?.status === 200) {
        setApiStatus(true);

        const decryptedBrandDetails = await JSON.parse(
          getDecryptionString(brandsListResponse?.data?.data)
        );

        const brandDetailsData = decryptedBrandDetails?.rows
          ?.filter((data) => data?.brandDetail?.id !== params?.id)
          ?.map((data) => {
            return {
              value: data?.brandDetail?.id,
              label: data?.brandDetail?.name,
              image: data?.brandDetail?.profile_image,
              bio: data?.brandDetail?.bio,
            };
          });

        setBrandList({ ...decryptedBrandDetails, rows: brandDetailsData });
      } else {
        setApiStatus(false);
        toast.error(brandsListResponse?.message);
      }
      setIsLoading((prev) => ({ ...prev, brandList: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prev) => ({ ...prev, brandList: false }));
    }
  };

  const fetchProducts = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, productList: true }));

      const requestBody = {
        userId: userDetails?.userData?.id ? userDetails?.userData?.id : null,
        brand: brandId,
      };

      const productListResponse = await doFetchBrandProducts(requestBody);

      if (productListResponse?.status === 200) {
        const decryptedProducts = await JSON.parse(
          getDecryptionString(productListResponse?.data)
        );
        setProductList(decryptedProducts);
      } else {
        toast.error(productListResponse?.message);
      }
      setIsLoading((prev) => ({ ...prev, productList: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prev) => ({ ...prev, productList: false }));
    }
  };

  const fetchServices = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, serviceList: true }));

      const requestBody = {
        userId: userDetails?.userData?.id ? userDetails?.userData?.id : null,
        brand: brandId,
      };

      const serviceListResponse = await doFetchBrandServices(requestBody);

      if (serviceListResponse?.status === 200) {
        const decryptedServices = await JSON.parse(
          getDecryptionString(serviceListResponse?.data)
        );
        setServiceList(decryptedServices);
      } else {
        toast.error(serviceListResponse?.message);
      }
      setIsLoading((prev) => ({ ...prev, serviceList: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prev) => ({ ...prev, serviceList: false }));
    }
  };

  const fetchRelatedProductsServices = async (data) => {
    try {
      setIsLoading((prev) => ({ ...prev, relatedProductService: true }));

      let brandProductsResponse;

      const requestBody = {
        brand: brandId,
        hasAccess: true,
        userId: userDetails?.userData?.id ? userDetails?.userData?.id : null,
        pageSize: data?.pageSize,
        currentPage: data?.page,
      };

      if (brandData?.brandDetail?.selling_type === "PRODUCT") {
        brandProductsResponse = await doFetchBrandProducts(requestBody);
      } else {
        brandProductsResponse = await doFetchBrandServices(requestBody);
      }

      if (brandProductsResponse?.status === 200) {
        const decryptedBrandProducts = await JSON.parse(
          getDecryptionString(brandProductsResponse?.data)
        );

        setRelatedProductList(decryptedBrandProducts);
      } else {
        toast.error(brandProductsResponse?.message);
      }
      setIsLoading((prev) => ({ ...prev, relatedProductService: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prev) => ({ ...prev, relatedProductService: false }));
    }
  };

  const fetchCollections = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, collectionList: true }));

      let brand = [];
      brand?.push(params?.id);
      const requestBody = {
        userId: userDetails?.userData?.id ? userDetails?.userData?.id : null,
        brand: brand,
      };

      const collectionProductsResponse = await doFetchBrandCollections(
        requestBody
      );

      if (collectionProductsResponse?.status === 200) {
        const decryptedCollectionProducts = await JSON.parse(
          getDecryptionString(collectionProductsResponse?.data)
        );
        setCollectionList(decryptedCollectionProducts);
      } else {
        toast.error(collectionProductsResponse?.message);
      }
      setIsLoading((prev) => ({ ...prev, collectionList: false }));
    } catch (error) {
      console.log({ error });
      setIsLoading((prev) => ({ ...prev, collectionList: false }));
    }
  };

  const sendCollectionAccessRequest = async (data) => {
    try {
      setIsRequestSentLoading(true);
      const requestBody = {
        type: "ACCESS_REQUEST",
        toUser: data?.brand?.id,
        collectionId: data?.id,
      };

      const accessRequestResponse = await doFetchSendAccessRequest(requestBody);

      if (accessRequestResponse?.status === 200) {
        setIsSuccessfullyReqModalOpen(true);
        fetchCollections();
      } else {
        toast.error(accessRequestResponse?.message);
      }
      setIsRequestSentLoading(false);
    } catch (error) {
      console.log({ error });
      setIsRequestSentLoading(false);
    }
  };

  const fetchBrandDetails = async () => {
    try {
      const brandResponse = await doFetchSingleBrandDetails(params?.id);
      if (brandResponse?.status === 200) {
        const decryptedBrandDetails = await JSON.parse(
          getDecryptionString(brandResponse?.data)
        );

        setBrandData(decryptedBrandDetails);
      } else {
        toast.error(brandResponse?.message);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const handleSRModalClose = () => {
    setIsSuccessfullyReqModalOpen(false);
  };

  return {
    params,
    brandList,
    brandData,
    isLoading,
    isLoggedIn,
    productList,
    serviceList,
    collectionList,
    paginationData,
    selectedCollection,
    relatedProductList,
    isRequestSentLoading,
    isShopCollectionModalOpen,
    isSuccessfullyReqModalOpen,
    isCollectionAddedModalOpen,
    isCollectionAlreadyAddedModalOpen,
    navigate,
    showSCModal,
    loginHandler,
    handleCAModalOk,
    handleSCModalOk,
    myShopNavigation,
    setIsAddedToShop,
    setPaginationData,
    handleTableChange,
    sendAccessRequest,
    handleSRModalClose,
    handleSCModalCancel,
    handleProductDetail,
    handleServiceDetail,
    handleCollectionDetail,
    handleAddToShopCollection,
    sendCollectionAccessRequest,
  };
};
