import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  doFetchBrandDetails,
  doFetchProductSingleCategory,
  doFetchServiceSingleCategory,
} from "../../actions";
import { getDecryptionString } from "../../services";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { useSelector } from "react-redux";

export const useBrandListHook = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [brandList, setBrandList] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState({});
  const [categoryType, setCategoryType] = useState({
    category: localStorage.getItem("categoryType"),
  });
  const [apiStatus, setApiStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({
    pageSize: 12,
    search: "",
  });
  const { userDetails } = useSelector(
    (state) => state.storeAuthenticateReducer
  );

  useEffect(() => {
    let queryParams = new URLSearchParams(location.search);
    userDetails &&
      queryParams.set("user-name", userDetails?.userData?.user_name);
    queryParams.set("res", apiStatus ? "true" : "false");

    navigate(`${location.pathname}?${queryParams.toString()}`, {
      replace: true,
    });
  }, [location?.pathname, userDetails, apiStatus]);

  useEffect(() => {
    if (params?.id) {
      fetchCategory(params?.id);
    }
    localStorage.removeItem("categoryId");
  }, []);

  useEffect(() => {
    if (params?.id) {
      fetchBrandList(paginationData);
    }
  }, [categoryType, paginationData?.pageSize]);

  const handleInputChange = (event) => {
    const { value, name } = event?.target;

    setPaginationData((prevState) => ({ ...prevState, [name]: value }));
    debounceBrandsSearchHandler({
      ...paginationData,
      [name]: value,
      page: 1,
    });
  };

  const debounceBrandsSearchHandler = useCallback(
    debounce(async (data) => {
      await fetchBrandList(data);
    }, 1000),
    []
  );

  const handleViewMore = () => {
    setPaginationData((prevState) => ({
      ...prevState,
      pageSize: prevState?.pageSize + 12,
    }));
  };

  const handleBrandSelect = (id) => {
    localStorage.setItem("categoryId", params?.id);
    navigate(`/brand-info/${id}`);
  };

  const fetchBrandList = async (data) => {
    try {
      setIsLoading(true);

      const requestBody = {
        pageSize: data?.pageSize,
        currentPage: data?.page,
        type: categoryType?.category ? categoryType?.category : null,
        categoryId: [params?.id],
        search: data?.search,
        isPaginate: true,
      };

      const brandsListResponse = await doFetchBrandDetails(requestBody);

      if (brandsListResponse?.status === 200) {
        setApiStatus(true);

        const decryptedBrandDetails = await JSON.parse(
          getDecryptionString(brandsListResponse?.data?.data)
        );

        const brandDetailsData = decryptedBrandDetails?.rows?.map(
          (data, index) => {
            return {
              id: data?.user?.id,
              brandImg: data?.user?.profile_image,
              title: data?.user?.name,
              paragraphText: data?.user?.bio,
            };
          }
        );

        setBrandList({
          ...decryptedBrandDetails,
          brandDetailsData: brandDetailsData,
        });
      } else {
        setApiStatus(false);
        toast.error(brandsListResponse?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  const fetchCategory = async (id) => {
    try {
      let categoryData;

      if (categoryType?.category === "product") {
        categoryData = await doFetchProductSingleCategory(id);
      } else {
        categoryData = await doFetchServiceSingleCategory(id);
      }
      if (categoryData?.status === 200) {
        const decryptedCategoryData = await JSON.parse(
          getDecryptionString(categoryData?.data)
        );

        setCategoryDetails(decryptedCategoryData);
      }
    } catch (error) {
      console.error("Error occurred during fetching category details:", error);
    }
  };

  return {
    isLoading,
    brandList,
    paginationData,
    categoryDetails,
    handleViewMore,
    handleBrandSelect,
    handleInputChange,
  };
};
