import { Col, Form, Row } from "antd";
import { ILTextInput } from "../../../../components";
import {
  Location01Icon,
  PinCodeIcon,
  SecurityCheckIcon,
} from "hugeicons-react";

export const ILGeneralInfo = ({
  signUpData,
  validateMessages,
  handleInputChange,
}) => {
  return (
    <>
      <h1 className="secondary-title md-secondary-title">Allmän information</h1>
      <p className="paragraph-sm-text paragraph-md-text">
        Fyll i allmän information för att fortsätta
      </p>
      <Form>
        <Row gutter={20} className="align-items-start">
          <Col xs={24}>
            <ILTextInput
              prefix={
                <SecurityCheckIcon
                  size={24}
                  color={"#1d1128"}
                  variant={"stroke"}
                  strokeWidth={1.5}
                />
              }
              isRequiredInput
              id="signUpSecurityNo"
              datatestid="signUpSecurityNo"
              label="Personnummer"
              placeholder={"Ange ditt personnummer"}
              type="text"
              name="socialSecurityNumber"
              value={signUpData?.socialSecurityNumber}
              handleChange={handleInputChange}
              errorMsg={validateMessages?.socialSecurityNumber}
            />
          </Col>
          <Col xs={24}>
            <ILTextInput
              isRequiredInput
              prefix={
                <Location01Icon
                  size={24}
                  color={"#1d1128"}
                  variant={"stroke"}
                  strokeWidth={1.5}
                />
              }
              id="signUpAddress"
              datatestid="signUpAddress"
              type="text"
              label="Adress"
              placeholder={"Ange din fullständiga adress"}
              name="address"
              value={signUpData?.address}
              handleChange={handleInputChange}
              errorMsg={validateMessages?.address}
            />
          </Col>
          <Col xs={24}>
            <ILTextInput
              textAreaShow
              id="signUpDetails"
              datatestid="signUpDetails"
              autoSize={{
                maxRows: 5,
              }}
              label="Detaljer"
              placeholder={"Ange detaljer"}
              name="details"
              value={signUpData?.details}
              handleChange={handleInputChange}
              errorMsg={validateMessages?.details}
            />
          </Col>
          <Col xs={24}>
            <ILTextInput
              isRequiredInput
              prefix={
                <PinCodeIcon
                  size={24}
                  color={"#1d1128"}
                  variant={"stroke"}
                  strokeWidth={1.5}
                />
              }
              id="signUpPincode"
              datatestid="signUpPincode"
              type="number"
              label="Postnummer"
              placeholder={"Ange postnummer"}
              name="pincode"
              value={signUpData?.pincode}
              handleChange={handleInputChange}
              errorMsg={validateMessages?.pincode}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};
