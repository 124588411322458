import React from "react";
import { Col, Row } from "antd";
import { clsx } from "clsx";
import {
  ILDashboardCard,
  ILDataOverviewCard,
  ILDashboardHeader,
  ILHead,
  ILReadMoreLess,
  ILLoader,
  ILSelect,
  ILSkeleton,
} from "../../../components";
import { Link } from "react-router-dom";
import { useMyShopProductDetailsHook } from "../../../hooks/dashboard/myShop/myShopProductDetails.hook";
import { capitalize } from "lodash";
import styles from "./ILMyShop.module.css";
import ILLineNewChart from "../../../components/ILCharts/ILLineNewChart";
import { dmyOption } from "../../../constants/data";
import { defaultUser } from "../../../constants/imageData";
import { isEmpty } from "../../../services";

export const ILMyShopProductDetails = () => {
  const {
    isLoading,
    isService,
    chartData,
    productServiceData,
    productSellClickList,
    handleSelectChange,
  } = useMyShopProductDetailsHook();

  return (
    <>
      <ILHead title="Product details | Min butik" />
      <ILDashboardHeader
        extra={true}
        title="Min butik"
        breadCrumbItems={[
          { title: "Hem", href: "/dashboard" },
          { title: "Min butik", href: "/dashboard/my-shop" },
          { title: `${isService ? "Service" : "Product"} Details` },
        ]}
      />
      <Row
        gutter={[
          { xs: 8, sm: 16, md: 10, lg: 20 },
          { xs: 8, sm: 16, md: 10, lg: 20 },
        ]}
      >
        <Col
          xs={18}
          sm={14}
          md={9}
          lg={8}
          xl={6}
          className={clsx("top-0 h-100 mx-auto", styles.productImgCol)}
        >
          <div
            className={clsx(
              styles.productImg,
              "d-flex align-items-center justify-content-center w-100"
            )}
          >
            <img
              src={isLoading ? defaultUser : productServiceData?.image}
              alt="advertising agency | ilonsi"
              className="object-fit-contain object-position-center w-100 h-100 aspect-ratio-1-1 radius-inherit"
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={15} lg={16} xl={18}>
          <ILDashboardCard
            className={clsx(styles.productDetailCard, "h-auto")}
            title={
              isLoading ? (
                <ILSkeleton isNormalParagraph />
              ) : (
                capitalize(productServiceData?.name)
              )
            }
            subTitle={
              <>
                {isLoading ? (
                  <ILSkeleton isNormalParagraph widthOne="50px" />
                ) : (
                  <div className="d-flex align-items-center gap-3">
                    <h6 className="text-uppercase p fw-semibold mb-0 gradient-text d-flex align-items-center">
                      CPC&nbsp;:&nbsp;<b>{productServiceData?.ppc_price} kr</b>
                    </h6>
                    {!isEmpty(productServiceData?.sell_commission) && (
                      <h6 className="text-uppercase p fw-semibold mb-0 gradient-text d-flex align-items-center">
                        Försäljning&nbsp;:&nbsp;
                        <b>{productServiceData?.sell_commission + " %"}</b>
                      </h6>
                    )}
                  </div>
                )}
              </>
            }
          >
            {isLoading ? (
              <ILSkeleton isNormalParagraph widthOne="50px" />
            ) : (
              <>
                <ILReadMoreLess
                  text={
                    productServiceData?.description === ("" || null | "null")
                      ? ""
                      : capitalize(productServiceData?.description)
                  }
                  sliceLength={400}
                  sliceClassName="custom-text-class"
                  className="big text-gray custom-text-class word-break-word"
                  onClick={(e) => e.stopPropagation()}
                />

                <div className="mt-5 mt-xxl-5">
                  <h6 className="p big fw-normal text-gray">
                    {isService ? "Tjänstens pris" : "Pris"}
                  </h6>
                  <h6 className="p fw-semibold text-dark-purple text-uppercase mb-0">
                    {productServiceData?.price} KR
                  </h6>
                </div>
              </>
            )}
          </ILDashboardCard>
          <ILDashboardCard
            className={clsx(styles.productDetailCard, "h-auto")}
            title={`${
              isService ? "Tjänste" : "Produckt"
            } Klicka och sälj detaljer`}
          >
            <div className={clsx(styles.productDetailGrid, "d-grid")}>
              <ILDataOverviewCard
                isLoading={isLoading}
                dataCardsList={productSellClickList}
                stylingCard
              />
            </div>
          </ILDashboardCard>
          <ILDashboardCard
            className={clsx(styles.productDetailCard, "h-auto")}
            title={"Märkesinformation"}
          >
            <div
              className={clsx(
                styles.brandDetailsRow,
                "d-flex gap-5 flex-md-nowrap flex-wrap"
              )}
            >
              {isLoading ? (
                <ILSkeleton isNormalParagraph widthOne="50px" />
              ) : (
                <>
                  <div
                    className={clsx(
                      styles.brandImgCard,
                      "d-flex align-items-center justify-content-center radius-xs ratio ratio-1x1 ratio-w-100 flex-0-auto w-100 h-100"
                    )}
                    style={{ backgroundColor: "#d2c976" }}
                  >
                    <img
                      src={productServiceData?.brand?.profile_image}
                      className="max-w-100 w-100 h-100 radius-inherit"
                    />
                  </div>
                  <div>
                    <h4 className="text-clr-dark-purple fw-semibold p big">
                      {productServiceData?.brand?.name}
                    </h4>
                    <h5 className="text-clr-dark-purple fw-normal p">
                      {productServiceData?.brand?.address}
                    </h5>
                    <div className="d-flex align-items-center flex-wrap mb-3">
                      <Link
                        to={productServiceData?.brand?.url}
                        className="text-clr-in-the-twiligh p"
                        target="_blank"
                      >
                        {productServiceData?.brand?.url}
                      </Link>
                      <b className="mx-2">|</b>
                      <Link
                        to="mailto:ralphlauren@gmail.com"
                        className="text-clr-in-the-twiligh p"
                      >
                        {productServiceData?.brand?.email}
                      </Link>
                    </div>
                    <ILReadMoreLess
                      text={
                        productServiceData?.brand?.bio === ("" || null | "null")
                          ? ""
                          : productServiceData?.brand?.bio
                      }
                      sliceLength={400}
                      sliceClassName="custom-text-class"
                      className="text-gray mb-0 custom-text-class word-break-word"
                      onClick={(e) => e.stopPropagation()}
                    />
                  </div>
                </>
              )}
            </div>
          </ILDashboardCard>

          <ILDashboardCard
            className="h-auto"
            title={"Intäktsinsikter"}
            extra={
              <ILSelect
                ILSelectLabelClass={clsx(styles.tableSelect, "w-auto mb-0")}
                id="dmy_select"
                datatestid="dmy_select"
                defaultValue="weekly"
                options={dmyOption}
                name="dateRange"
                handleChange={(event) => handleSelectChange(event, "dateRange")}
              />
            }
            dashboardCardHeaderClass="d-flex algin-items-center gap-4"
          >
            <div className="mt-5 overflow-x-auto h-100">
              {isLoading ? (
                <ILSkeleton isNormalParagraph widthOne="50px" />
              ) : (
                <ILLineNewChart
                  chartData={chartData}
                  isLegendVisible={false}
                  dashedXAxis
                />
              )}
            </div>
          </ILDashboardCard>
        </Col>
      </Row>
    </>
  );
};
