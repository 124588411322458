import { Col, Row } from "antd";
import clsx from "clsx";
import {
  ILButton,
  ILDashboardCard,
  ILModal,
  ILSelect,
  ILTextInput,
  ILDashboardHeader,
  ILHead,
  ILUpload,
  ILLoader,
  ILNoDataCard,
  ILSkeleton,
} from "../../../components";
import {
  Delete02Icon,
  Mail01Icon,
  Unlink04Icon,
  UserIcon,
} from "hugeicons-react";
import { useInfluencerProfileHook } from "../../../hooks/dashboard/profile/influencerProfile.hook";
import styles from "./ILEditProfile.module.css";

const ILEditProfile = () => {
  const {
    isLoading,
    citiesList,
    isEditData,
    countryList,
    channelList,
    channelData,
    countryCodeList,
    isSubmitLoading,
    socialMediaList,
    socialMediaData,
    validateMessages,
    userProfileDetails,
    deleteChannelModalOpen,
    isAddChannelModalOpen,
    navigate,
    handleInputChange,
    handleSelectChange,
    showAddChannelModal,
    handleUnlinkChannel,
    handleFileInputChange,
    handleAddChannelSubmit,
    handleChannelDeleteModal,
    handleInfluencerProfileSubmit,
    handleChannelDeleteModalCancel,
  } = useInfluencerProfileHook();

  return (
    <>
      <ILHead title="Redigera Profil" />
      <ILDashboardHeader
        extra={true}
        title="Redigera Profil"
        breadCrumbItems={[
          { title: "Hem", href: "/dashboard" },
          { title: "Redigera Profil" },
        ]}
      />

      <section>
        <div>
          <Row
            gutter={[
              { xs: 15, sm: 20, md: 25, lg: 30, xl: 35, xxl: 40 },
              { xs: 15, sm: 20, md: 25, lg: 30, xl: 35, xxl: 40 },
            ]}
          >
            <Col xs={24} xl={13}>
              <ILDashboardCard
                title={"Personliga uppgifter"}
                className={clsx(styles.editProfilePersonalDetailsWrap)}
              >
                {isLoading ? (
                  <>
                    <ILSkeleton isImgTile2 isNotImgTileC width="100px" />
                    <div className="mb-4"></div>
                  </>
                ) : (
                  <div
                    className={clsx(
                      styles.personalDetailsCard,
                      "d-flex align-items-md-start align-items-center flex-md-row flex-column w-100"
                    )}
                  >
                    <div
                      className={clsx(
                        styles.profileAvatarCard,
                        "flex-shrink-0 position-relative"
                      )}
                    >
                      <ILUpload
                        htmlFor="imgUploadProfile"
                        id="imgUploadProfile"
                        multiple={false}
                        accept=""
                        title="Profile image"
                        stylingUploadWrap
                        viewImg
                        name="image"
                        value={userProfileDetails?.image}
                        handleChange={handleFileInputChange}
                        errorMsg={validateMessages?.image}
                        imgClass="object-fit-cover"
                      />
                    </div>
                    <div
                      className={clsx(
                        styles.personalInterestWrap,
                        "d-flex align-items-center flex-wrap gap-4 small-track overflow-auto"
                      )}
                    >
                      {userProfileDetails?.productCategoryListData?.map(
                        (item, index) => {
                          return (
                            <div
                              className={clsx(
                                styles.personalInterestCard,
                                "d-flex align-items-center gap-4 gap-xxl-3"
                              )}
                              key={index}
                            >
                              <img src={item.icon} height={16} width={16} />

                              <span className="p sm">{item.name}</span>
                            </div>
                          );
                        }
                      )}
                      {userProfileDetails?.serviceCategoryListData?.map(
                        (item, index) => {
                          return (
                            <div
                              className={clsx(
                                styles.personalInterestCard,
                                "d-flex align-items-center gap-4 gap-xxl-3"
                              )}
                              key={index}
                            >
                              <img src={item.icon} height={16} width={16} />
                              <span className="p sm">{item.name}</span>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
                {isLoading ? (
                  <>
                    <ILSkeleton isForm />
                    <ILSkeleton isForm />
                    <ILSkeleton isForm />
                    <ILSkeleton isForm isFullFormCol height={70} />
                  </>
                ) : (
                  <>
                    <div className="pt-3">
                      <Row gutter={24}>
                        <Col xs={24} md={12}>
                          <ILTextInput
                            isRequiredInput
                            id="fullNameInput"
                            datatestid="fullNameInput"
                            type="text"
                            label="Fullständigt namn"
                            prefix={
                              <UserIcon
                                size={24}
                                color={"#1d1128"}
                                variant={"stroke"}
                              />
                            }
                            placeholder={"Enter influencer name"}
                            name="name"
                            value={userProfileDetails?.name}
                            handleChange={handleInputChange}
                            errorMsg={validateMessages?.name}
                          />
                        </Col>
                        <Col xs={24} md={12}>
                          <ILTextInput
                            isRequiredInput
                            id="fullNameInput"
                            datatestid="fullNameInput"
                            type="text"
                            label="Användarnamn"
                            prefix={
                              <UserIcon
                                size={24}
                                color={"#1d1128"}
                                variant={"stroke"}
                              />
                            }
                            disabled
                            placeholder={"Enter user name"}
                            name="userName"
                            value={userProfileDetails?.userName}
                            handleChange={handleInputChange}
                            errorMsg={validateMessages?.userName}
                          />
                        </Col>
                        <Col xs={24} md={12}>
                          <ILTextInput
                            isRequiredInput
                            disabled
                            id="ePostAddressInput"
                            datatestid="ePostAddressInput"
                            type="text"
                            label="E-postadress"
                            value={userProfileDetails?.email}
                            prefix={
                              <Mail01Icon
                                size={24}
                                color={"#1d1128"}
                                variant={"stroke"}
                              />
                            }
                          />
                        </Col>
                        <Col xs={24} md={12}>
                          <div className="w-100">
                            <label className="fw-medium text-dark-purple d-block ILSelectLabelText">
                              Telefonnummer
                            </label>
                            <div className="d-flex align-items-start gap-2">
                              <ILSelect
                                id="countrySelect2"
                                // label="Telefonnummer"
                                datatestid="countrySelect2"
                                ILSelectLabelClass={clsx(
                                  styles.countryCodeSelect,
                                  "w-auto"
                                )}
                                name="countryCode"
                                value={userProfileDetails?.countryCode}
                                options={countryCodeList}
                                handleChange={(event) =>
                                  handleSelectChange(event, "countryCode")
                                }
                              />
                              <ILTextInput
                                id="basicInput"
                                datatestid="basicInput"
                                type="number"
                                label=""
                                name="phoneNumber"
                                value={userProfileDetails?.phoneNumber}
                                handleChange={handleInputChange}
                                placeholder={"Telefonnummer"}
                                errorMsg={validateMessages?.phoneNumber}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xs={24} md={12}>
                          <ILSelect
                            isRequired
                            label="Land"
                            id="countrySelect"
                            datatestid="countrySelect"
                            options={countryList}
                            name="countryId"
                            value={userProfileDetails?.countryId}
                            handleChange={(event) =>
                              handleSelectChange(event, "countryId")
                            }
                            errorMsg={validateMessages?.countryId}
                          />
                        </Col>
                        <Col xs={24} md={12}>
                          <ILSelect
                            isRequired
                            label="Stad"
                            id="citySelect"
                            datatestid="citySelect"
                            options={citiesList}
                            name="cityId"
                            placeholder={"Välj stad"}
                            value={userProfileDetails?.cityId || null}
                            handleChange={(event) =>
                              handleSelectChange(event, "cityId")
                            }
                            errorMsg={validateMessages?.cityId}
                          />
                        </Col>
                        <Col xs={24}>
                          <ILTextInput
                            textAreaShow
                            id="bioTextarea"
                            datatestid="bioTextarea"
                            ILInputLabelClass="mb-0"
                            autoSize={{
                              minRows: 3,
                            }}
                            label="Bio"
                            placeholder={"Jag älskar mode och resa!"}
                            name="bio"
                            value={userProfileDetails?.bio}
                            handleChange={handleInputChange}
                            errorMsg={validateMessages?.bio}
                          />
                        </Col>
                      </Row>
                    </div>
                  </>
                )}
              </ILDashboardCard>
            </Col>

            <Col xs={24} lg={12} xl={11}>
              <ILDashboardCard
                title={"Dina sociala mediekonton"}
                extra={
                  <>
                    <div className="cursor-pointer d-flex align-items-center gap-2">
                      <big className="gradient-text h5 mb-0 translateY-2">
                        +
                      </big>
                      <span
                        className="gradient-text p fw-semibold"
                        onClick={showAddChannelModal}
                      >
                        Lägg till kanal
                      </span>
                    </div>
                  </>
                }
              >
                {isLoading ? (
                  <>
                    <ILSkeleton isImgTile2 widthTwo="100px" />
                  </>
                ) : (
                  <div>
                    {socialMediaList?.length > 0 &&
                      socialMediaList?.map((item, index) => {
                        return (
                          <div
                            className={clsx(
                              styles.socialMediaAccCard,
                              "radius-md d-flex align-items-center flex-wrap justify-content-between gap-4"
                            )}
                            key={index}
                          >
                            <div className="d-flex align-items-center">
                              <div className={styles.socialMediaImg}>
                                <img
                                  src={item.image}
                                  alt="advertising agency | ilonsi"
                                  className="w-100 h-100"
                                />
                              </div>
                              <p className="big fw-medium mb-0">{item.label}</p>
                            </div>
                            <a
                              href=""
                              onClick={(e) => {
                                e.preventDefault();
                                handleChannelDeleteModal(item);
                              }}
                              className="d-flex align-items-center gap-4 gap-xxl-3"
                            >
                              <Unlink04Icon
                                size={24}
                                color={"#db2f7f"}
                                variant={"stroke"}
                              />
                              <span className="gradient-text p big fw-medium">
                                Ta bort länk
                              </span>
                            </a>
                          </div>
                        );
                      })}

                    {socialMediaList?.length === 0 && (
                      <ILNoDataCard
                        isIcon
                        className="position-relative z-3"
                        title={
                          "Tillagda kanaler kommer att visas här. Lägg till dina kanaler"
                        }
                      />
                    )}
                  </div>
                )}
              </ILDashboardCard>
            </Col>
            <Col xs={24} lg={12} xl={13}>
              <ILDashboardCard title={"Adress & Personnummer"}>
                {isLoading ? (
                  <>
                    <ILSkeleton isForm />
                    <ILSkeleton isForm />
                    <ILSkeleton isForm />
                    <ILSkeleton isForm isFullFormCol height={70} />
                  </>
                ) : (
                  <div>
                    <Row gutter={24}>
                      <Col xs={24} xl={12}>
                        <ILTextInput
                          isRequiredInput
                          id="addressInput"
                          datatestid="addressInput"
                          type="text"
                          label="Adress"
                          placeholder={"ex.Plot-No 21, High Rise"}
                          name="address"
                          value={userProfileDetails?.address}
                          handleChange={handleInputChange}
                          errorMsg={validateMessages?.address}
                        />
                      </Col>
                      <Col xs={24} xl={12}>
                        <ILTextInput
                          id="detailsInput"
                          datatestid="detailsInput"
                          type="text"
                          label="Detaljer"
                          placeholder={"ex.Beside street View"}
                          name="details"
                          value={userProfileDetails?.details}
                          handleChange={handleInputChange}
                          errorMsg={validateMessages?.details}
                        />
                      </Col>
                      <Col xs={24} xl={12}>
                        <ILTextInput
                          isRequiredInput
                          id="pinCodeInput"
                          datatestid="pinCodeInput"
                          type="number"
                          label="Postnummer"
                          placeholder={"ex.00125"}
                          name="pincode"
                          value={userProfileDetails?.pincode}
                          handleChange={handleInputChange}
                          errorMsg={validateMessages?.pincode}
                        />
                      </Col>
                      <Col xs={24} xl={12}>
                        <ILTextInput
                          id="securityNumberInput"
                          datatestid="securityNumberInput"
                          type="text"
                          label="Personnummer"
                          placeholder={"ex.8703564578"}
                          name="socialSecurityNumber"
                          value={userProfileDetails?.socialSecurityNumber}
                          handleChange={handleInputChange}
                          errorMsg={validateMessages?.socialSecurityNumber}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </ILDashboardCard>
            </Col>
          </Row>
        </div>
        <div className="section-xs-mt d-flex align-items-center justify-content-lg-start justify-content-center flex-wrap gap-4">
          <ILButton
            disabled={!isEditData || isSubmitLoading}
            ILBtnClass={"btn-small"}
            variant={"primary-gradient"}
            handleClick={handleInfluencerProfileSubmit}
          >
            {isSubmitLoading ? <ILLoader isBtn /> : "Uppdatera"}
          </ILButton>
          <ILButton
            disabled={isSubmitLoading}
            ILBtnClass={"btn-small"}
            variant={"gradient-bordered"}
            handleClick={() => navigate("/dashboard")}
          >
            Avbryt
          </ILButton>
        </div>
      </section>

      {/* ADD CHANNEL MODAL START */}
      <ILModal
        open={isAddChannelModalOpen}
        onOk={showAddChannelModal}
        onCancel={showAddChannelModal}
        title={
          <>
            <span className="d-flex align-items-center gap-3 mb-4 cursor-pointer hover-text">
              {/* <ArrowLeft02Icon size={28} color={"#000000"} variant={"stroke"} /> */}
              Lägg till dina sociala mediekonton
            </span>
          </>
        }
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <ILSelect
              isRequired
              label="Sociala mediekonto"
              id="signUpChannelName"
              datatestid="signUpChannelName"
              placeholder="Välj social medieplattform"
              options={channelList}
              name="channelId"
              value={socialMediaData?.channelId}
              handleChange={(event) => handleSelectChange(event, "channelId")}
              errorMsg={validateMessages?.channelId}
            />
            <ILTextInput
              isRequiredInput
              id="signUpChannelURL"
              datatestid="signUpChannelURL"
              type="text"
              label="Sociala mediers URL"
              placeholder={"Klistra in URL här"}
              name="mediaLink"
              value={socialMediaData?.mediaLink}
              handleChange={handleInputChange}
              errorMsg={validateMessages?.mediaLink}
            />
            <ILButton
              variant={"primary-gradient"}
              ILBtnClass="w-100 mt-5 mt-xl-4 mt-xxl-3"
              onClick={handleAddChannelSubmit}
            >
              Lägg till kanal
            </ILButton>
          </>
        }
        isFooterRemove
      />

      <ILModal
        open={deleteChannelModalOpen}
        onOk={handleChannelDeleteModal}
        onCancel={handleChannelDeleteModalCancel}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="518px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <div
                className="brand-logo brand-logo-big flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center mx-auto"
                style={{ borderColor: "#ED4F32", borderWidth: "3px" }}
              >
                <Delete02Icon
                  size={34}
                  color={"#ED4F32"}
                  variant={"stroke"}
                  strokeWidth={2}
                />
              </div>

              <p className="paragraph-sm-text paragraph-md-text text-center mb-0">
                Vill du ta bort&nbsp;
                <span className="text-indigo bg-clr-orchid-mist">
                  {channelData?.label}
                </span>
              </p>
              <div className="d-flex align-items-center gap-4 modal-more-btn">
                <ILButton
                  ILBtnClass="w-50"
                  variant={"primary-gradient"}
                  onClick={handleUnlinkChannel}
                >
                  Ja, Bekräfta
                </ILButton>
                <ILButton
                  ILBtnClass="w-50"
                  variant={"gradient-bordered"}
                  onClick={handleChannelDeleteModalCancel}
                >
                  Nej, Avbryt
                </ILButton>
              </div>
            </div>
          </>
        }
        isFooterRemove
      />

      {/* ADD CHANNEL MODAL END */}
    </>
  );
};

export default ILEditProfile;
