import { Icons } from "../../../constants/icons";
import { ILButton } from "../../../components";
import { useNavigate } from "react-router-dom";

export const ILSuccessfullyModal = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="text-center d-flex flex-column gap-5 gap-xxl-4">
        <span className="lh-0">{Icons.circleRoundIcon}</span>
        <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
          Lösenordet har ändrats!
        </h4>
        <p className="paragraph-sm-text paragraph-md-text text-center mb-0">
          Logga in för att komma igång.
        </p>
        <ILButton
          ILBtnClass="w-100"
          variant={"primary-gradient"}
          handleClick={() => {
            navigate("/login");
          }}
        >
          Logga in
        </ILButton>
      </div>
    </>
  );
};
