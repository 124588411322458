import React from "react";
import { clsx } from "clsx";
import styles from "./ILInfoCard.module.css";

export const ILInfoCard = ({
  ILInfoCardNumberInfoList,
  cardTitle,
  cardDescription,
  cardImg,
}) => {
  return (
    <>
      <div className={styles.ILInfoCard}>
        <div>
          <div className={clsx(styles.ILInfoCardImgWrap, "ratio ratio-16x9")}>
            <img className="object-fit-contain" src={cardImg} alt={cardTitle} />
          </div>
          <div>
            <h4 className="fw-semibold mb-3 d-block">{cardTitle}</h4>
            <p className="paragraph-sm-text text-ellipsis line-clamp-6">
              {cardDescription}
            </p>
          </div>
        </div>
        <div className="d-flex gap-5 flex-wrap mt-4">
          {ILInfoCardNumberInfoList &&
            ILInfoCardNumberInfoList.map((item, index) => {
              return (
                <div key={index}>
                  <h4
                    className={clsx(
                      "gradient-text text-uppercase",
                      styles.ILNumberInfo
                    )}
                  >
                    {item.gradientText}
                  </h4>
                  <p className="title-label text-start mb-0">
                    {item.labelText}
                  </p>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};
