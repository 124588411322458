import React from "react";
import {
  featuredCollectionsBreakPoints,
  featuredProductBreakPoints,
  featuredServicesBreakPoints,
  selectProductBreakPoints,
} from "../../constants/data";
import { SwiperSlide } from "swiper/react";
import {
  ILProductGalleryCard,
  ILProductCard,
  ILSwiper,
  ILInnerHero,
  ILCheckbox,
  ILHead,
  ILTab,
  ILLoader,
  ILNoDataCard,
  ILModalFlow,
  ILButton,
  ILSelect,
  ILTextInput,
  ILPagination,
  ILModal,
  ILSkeleton,
} from "../../components";
import { useBrandsHook } from "../../hooks/website/brands.hook";
import { Col, Row } from "antd";
import {
  ArrowLeft02Icon,
  ArrowRight01Icon,
  Delete02Icon,
} from "hugeicons-react";
import clsx from "clsx";
import ILProductCardCheckbox from "../../components/ILProductCardCheckbox";
import { gradientCircleRound, threeDotsIcon } from "../../constants/imageData";
import { Link } from "react-router-dom";
import { appConfig } from "../../config";
import copy from "copy-to-clipboard";
import styles from "./ILBrands.module.css";
import { isEmpty } from "../../services";

const ILBrands = () => {
  const {
    show,
    showAll,
    brandList,
    isService,
    currentId,
    isPrevBtn,
    isLoading,
    isLoggedIn,
    productList,
    productData,
    serviceList,
    categoryList,
    categoryType,
    selectedData,
    collectionList,
    paginationData,
    isModalLoading,
    productListData,
    collectionData,
    isNewCollection,
    validateMessages,
    isProductSelected,
    collectionListData,
    isAddToShopLoading,
    relatedProductList,
    selectedCollection,
    selectedSocialMedia,
    isRequestSentLoading,
    brandPaginationData,
    deleteProductModalOpen,
    isCollectionAddLoading,
    isShopCollectionModalOpen,
    isCollectionAddedModalOpen,
    isSuccessfullyReqModalOpen,
    isCollectionAlreadyAddedModalOpen,
    socialMediaSharingCardListResults,
    navigate,
    showModal,
    showSCModal,
    loginHandler,
    setCurrentId,
    handleShowAll,
    setProductData,
    handleCopyLink,
    addToShopHandle,
    handleCAModalOk,
    handleSCModalOk,
    myShopNavigation,
    handleModalClose,
    handleSetProduct,
    handleInputChange,
    handleTableChange,
    handleSRModalClose,
    handleSelectChange,
    handleProductDetail,
    handleSCModalCancel,
    handleServiceDetail,
    handleSearchChange,
    generateSharableLink,
    handleRemoveProduct,
    handleAddMoreProducts,
    handleProductAddToShop,
    setSelectedSocialMedia,
    handleCollectionDetail,
    handleSubmitCollection,
    handleSelectSocialMedia,
    sendProductAccessRequest,
    sendServiceAccessRequest,
    handleAddToShopCollection,
    handleSelectSearchChange,
    handleProductDeleteModal,
    sendCollectionAccessRequest,
    handleProductDeleteModalCancel,
  } = useBrandsHook();

  const brandCategoriesTabItem = [
    {
      key: "product",
      label: "Produkter",
    },
    {
      key: "service",
      label: "Tjänster",
    },
  ];

  const steps = [
    {
      id: "copyLink",
      title: "Kopiera länk",
      content: (
        <>
          <div className="d-flex align-items-center gap-4 mb-5 w-100">
            <div className={clsx("productImg flex-0-auto")}>
              <img
                width={"100%"}
                height={"100%"}
                src={selectedData?.image}
                alt="advertising agency | ilonsi"
                className="object-fit-contain object-position-center w-100 h-100 aspect-ratio-1-1"
              />
            </div>
            <div>
              <h5 className="big fw-semibold mb-1 line-height-normal">
                {selectedData?.name}
              </h5>
              <p className="paragraph-sm-text mb-0 text-ellipsis line-clamp-2 word-break-word">
                {selectedData?.description === "" || "null" || null
                  ? ""
                  : selectedData?.description}
                {}
              </p>
            </div>
          </div>
          <div className="d-flex flex-wrap gap-5 mb-5">
            <div>
              <span className="paragraph-sm-text fw-medium d-block mb-2">
                Pris
              </span>
              <p className="big fw-medium mb-0">{selectedData?.price} KR</p>
            </div>
            <div>
              <span className="paragraph-sm-text fw-medium d-block mb-2">
                Betalning per klick
              </span>
              <p className="big fw-medium mb-0">{selectedData?.ppc_price} KR</p>
            </div>
            {!isEmpty(selectedData?.sell_commission) && (
              <div>
                <span className="paragraph-sm-text fw-medium d-block mb-2">
                  Försäljningsprovision
                </span>
                <p className="big fw-medium mb-0">
                  {selectedData?.sell_commission + " %"}
                </p>
              </div>
            )}
          </div>
          <div>
            <p className="big fw-medium">Generated Affiliate Link</p>
            <ILTextInput
              ILInputLabelClass={"mb-0"}
              suffix={
                <>
                  <ILButton
                    handleClick={(e) => next(e)}
                    ILBtnClass={"min-w-auto fw-medium"}
                    variant={"primary-gradient"}
                    id={"copiedSuccessfully"}
                  >
                    Kopiera länk
                  </ILButton>
                </>
              }
              id="copyLinkInput"
              datatestid="copyLinkInput"
              type="text"
              name=""
              value={`${appConfig.APP_URL}${selectedData?.short_code}`}
              readOnly={true}
              handleChange={() => {
                return false;
              }}
              placeholder={"Kopiera länkingång"}
            />
          </div>

          {!selectedData?.service_code && (
            <div className="mt-3">
              {(selectedData?.is_added_to_shop === 1 ||
                selectedData?.is_added_to_collection === 1) && (
                <ILButton
                  disabled={isLoading?.addToShop}
                  isSmallSizeBtn
                  handleClick={() => addToShopHandle(selectedData, false)}
                  ILBtnClass={"w-100 min-w-auto"}
                  variant={"primary-gradient"}
                >
                  {isLoading?.addToShop ? (
                    <ILLoader isBtn />
                  ) : (
                    "Skapa en kollektion"
                  )}
                </ILButton>
              )}
            </div>
          )}
        </>
      ),
      width: "558px",
    },
    {
      id: "copiedSuccessfully",
      title: "",
      content: (
        <>
          <div className="text-center d-flex flex-column gap-5">
            <span className="lh-0">
              <img
                src={gradientCircleRound}
                fluid
                alt="advertising agency | ilonsi"
              />
            </span>
            <div>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Framgångsrikt kopierad!
              </h4>
              <p className="paragraph-sm-text paragraph-text-16 text-center">
                Länken har kopierats. Dela den på dina sociala medier och följ
                dina inkomster i realtid.
              </p>
            </div>
            <div>
              {/* <ILButton
                handleClick={() => addToShopHandle(selectedData, isService)}
                id={"addToShopAs"}
                ILBtnClass={"w-100 mb-4"}
                variant={"primary-gradient"}
              >
                Lägg till i min butik
              </ILButton> */}
              <ILButton
                handleClick={(e) => next(e)}
                id={"shareInSocialMedia"}
                ILBtnClass={"w-100"}
                variant={"gradient-bordered"}
              >
                Dela länken på dina sociala medier
              </ILButton>
            </div>
          </div>
        </>
      ),
      width: "518px",
    },
    {
      id: "addToShopAs",
      title: "Lägg till i butik som",
      prevBtn: !isPrevBtn && (
        <span
          id="copiedSuccessfully"
          role={"button"}
          className="cursor-pointer hover-text"
          onClick={(e) => prev(e)}
        >
          <ArrowLeft02Icon size={28} color={"#000000"} variant={"stroke"} />
        </span>
      ),
      content: (
        <div className="position-relative">
          {selectedData?.is_added_to_shop === 0 && (
            <div className="d-flex align-items-center justify-content-between gap-4 mb-5 mb-xxl-2">
              <p className="big-initial fw-medium py-3 mb-0 text-ellipsis line-clamp-3">
                Lägg till som enskild produkt
              </p>
              {isLoading?.addToShop ? (
                <ILLoader isChatLoader />
              ) : (
                <div
                  onClick={(e) => next(e)}
                  id="productAdded"
                  role={"button"}
                  className={"round-circle-btn"}
                >
                  <ArrowRight01Icon
                    size={20}
                    color={"currentcolor"}
                    variant={"stroke"}
                  />
                </div>
              )}
            </div>
          )}
          <div className="d-flex align-items-center justify-content-between gap-4">
            <p className="big-initial fw-medium py-3 mb-0 text-ellipsis line-clamp-3">
              Skapa en kollektion
            </p>
            <div
              onClick={(e) => next(e)}
              id="createCollection"
              role={"button"}
              className={"round-circle-btn"}
            >
              <ArrowRight01Icon
                size={20}
                color={"currentcolor"}
                variant={"stroke"}
              />
            </div>
          </div>
        </div>
      ),
      width: "444px",
    },
    {
      id: "productAdded",
      title: "",
      content: (
        <>
          <div className="text-center d-flex flex-column gap-5">
            <span className="lh-0">
              <img
                src={gradientCircleRound}
                fluid
                alt="advertising agency | ilonsi"
              />
            </span>
            <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
              Din {isService ? "tjänst" : "produkt"} har lagts till!
            </h4>
            <ILButton
              ILBtnClass={"w-100"}
              variant={"primary-gradient"}
              handleClick={() => navigate("/dashboard/my-shop")}
            >
              Visa i min butik
            </ILButton>
          </div>
        </>
      ),
      width: "518px",
    },
    {
      id: "createCollection",
      title: "Skapa kollektion",
      prevBtn: (
        <span
          id="addToShopAs"
          role={"button"}
          className="cursor-pointer hover-text"
          onClick={(e) => prev(e)}
        >
          {selectedData?.is_added_to_shop === 0 && (
            <ArrowLeft02Icon size={28} color={"#000000"} variant={"stroke"} />
          )}
        </span>
      ),
      content: (
        <>
          <div className="d-flex align-items-center justify-content-between gap-4 mb-5 mb-xxl-2">
            <p className="big-initial fw-medium py-3 mb-0 text-ellipsis line-clamp-3">
              Skapa en ny kollektion
            </p>
            <div
              onClick={(e) => next(e)}
              id="addNewCollection"
              role={"button"}
              className={"round-circle-btn"}
            >
              <ArrowRight01Icon
                size={20}
                color={"currentcolor"}
                variant={"stroke"}
              />
            </div>
          </div>
          {collectionListData?.length > 0 && (
            <div className="d-flex align-items-center justify-content-between gap-4">
              <p className="big-initial fw-medium py-3 mb-0 text-ellipsis line-clamp-3">
                Lägg till i befintlig kollektion{" "}
              </p>
              <div
                onClick={(e) => next(e)}
                id="addExistingCollection"
                role={"button"}
                className={"round-circle-btn"}
              >
                <ArrowRight01Icon
                  size={20}
                  color={"currentcolor"}
                  variant={"stroke"}
                />
              </div>
            </div>
          )}
        </>
      ),
      width: "444px",
    },
    {
      id: "addNewCollection",
      title: "Lägg till ny kollektion",
      prevBtn: (
        <span
          id="createCollection"
          role={"button"}
          className="cursor-pointer hover-text"
          onClick={(e) => prev(e)}
        >
          <ArrowLeft02Icon size={28} color={"#000000"} variant={"stroke"} />
        </span>
      ),
      content: (
        <>
          <div className="mb-5">
            <ILTextInput
              isRequiredInput
              ILInputLabelClass={"mb-0"}
              id="collectionName"
              datatestid="collectionName"
              type="text"
              name="name"
              value={collectionData?.name}
              label="Kollektionens namn"
              placeholder={"Ange kollektionens namn"}
              handleChange={handleInputChange}
              errorMsg={validateMessages?.name}
            />
          </div>
          <div className="d-flex align-items-center justify-content-between mb-5">
            <p className="big fw-medium mb-0">Produkter</p>
            <a
              id="selectProduct"
              onClick={(e) => {
                e.preventDefault();
                handleAddMoreProducts("newCollection");
              }}
              href="#"
              className="gradient-text p big fw-medium"
            >
              +Lägg till fler produkter
            </a>
          </div>

          <div className="mb-5">
            {productListData?.map((productData, index) => {
              return (
                <Row
                  gutter={24}
                  className="align-items-center pb-3 pb-xxl-0 mb-4"
                  key={index}
                >
                  <Col xs={24} sm={16}>
                    <ILTextInput
                      ILInputLabelClass={"mb-0"}
                      id="link1"
                      datatestid="link1"
                      type="text"
                      label={
                        <div className="d-flex align-items-center gap-3 gap-xxl-2">
                          <div className="order-2">{`Länk ${index + 1}`}</div>
                          {index + 1 > 1 && (
                            <Delete02Icon
                              size={20}
                              color={"#db2f7f"}
                              variant={"stroke"}
                              className={clsx(
                                styles.deleteSocialAcc,
                                "transition-smooth cursor-pointer order-1"
                              )}
                              onClick={() =>
                                handleProductDeleteModal(productData)
                              }
                            />
                          )}
                        </div>
                      }
                      name="link 1"
                      placeholder={"Länk 1"}
                      value={productData?.link}
                      disabled
                    />
                  </Col>
                  <Col
                    xs={24}
                    sm={8}
                    className={clsx(
                      styles.payPerClickSellWrap,
                      "align-self-end mt-4 mt-sm-0"
                    )}
                  >
                    <div className="d-flex gap-3">
                      <div>
                        <span className="fw-medium mb-4 d-block">CPC</span>
                        <p className="fw-semibold mb-0 sm">
                          {productData?.ppc_price} KR
                        </p>
                      </div>
                      {!isEmpty(productData?.sell_commission) && (
                        <div className="ms-auto">
                          <span className="fw-medium mb-4 d-block">
                            Provision
                          </span>
                          <p className="fw-semibold mb-0 sm">
                            {productData?.sell_commission + " %"}
                          </p>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              );
            })}
          </div>
          <ILButton
            disabled={isCollectionAddLoading}
            handleClick={(e) => handleSubmitCollection(e)}
            id={"collectionCreated"}
            ILBtnClass={"w-100"}
            variant={"primary-gradient"}
          >
            {isCollectionAddLoading ? (
              <ILLoader isBtn />
            ) : (
              "Lägg till ny kollektion"
            )}
          </ILButton>
        </>
      ),
      width: "636px",
    },
    {
      id: "selectProduct",
      title: "Välj produkt",
      prevBtn: (
        <span
          id="addNewCollection"
          role={"button"}
          className="cursor-pointer hover-text"
          onClick={(e) => prev(e)}
        >
          <ArrowLeft02Icon size={28} color={"#000000"} variant={"stroke"} />
        </span>
      ),
      subTitle:
        "Du kan välja en produkt åt gången för att lägga till i din kollektion",
      content: (
        <>
          <div className="position-relative">
            {isModalLoading ? (
              <ILSkeleton isImgTitleLabelCard2 />
            ) : (
              relatedProductList?.rows?.filter(
                (productData) =>
                  !productListData?.some(
                    (product) => product?.id === productData?.id
                  )
              )?.length > 0 && (
                <>
                  {" "}
                  <ILSwiper
                    className=""
                    spaceBetween={20}
                    slidesPerView={4}
                    autoplay={false}
                    loop={false}
                    leftArrowClassName={"select-product-left-nav"}
                    rightArrowClassName={"select-product-right-nav"}
                    breakpoints={selectProductBreakPoints}
                    grabCursor={true}
                  >
                    {relatedProductList?.rows
                      ?.filter(
                        (productData) =>
                          !productListData?.some(
                            (product) => product?.id === productData?.id
                          )
                      )
                      ?.map((item, index) => {
                        return (
                          <SwiperSlide>
                            <ILProductCardCheckbox
                              noButtons={true}
                              index={index}
                              productCardClassname={"modal-product-card"}
                              name={"select_product"}
                              item={item}
                              selectedData={productData}
                              handleSetProduct={handleSetProduct}
                              // is disabled card bellow
                              // isDisabledCard
                            />
                          </SwiperSlide>
                        );
                      })}
                  </ILSwiper>
                  {!isModalLoading && (
                    <div className="mt-5 pt-2 d-md-flex align-items-center flex-wrap">
                      <div className="d-flex justify-content-center justify-content-md-start flex-wrap gap-4 mb-5 mb-md-0">
                        <ILButton
                          id={"addNewCollection"}
                          disabled={!isProductSelected}
                          handleClick={(e) => {
                            e.preventDefault();
                            handleCopyLink("selectProduct");
                          }}
                          ILBtnClass={"mt-0 fw-normal"}
                          variant={"primary-gradient"}
                        >
                          Kopiera länk
                        </ILButton>
                      </div>

                      {(relatedProductList?.hasPrevious ||
                        relatedProductList?.hasNext) && (
                        <ILPagination
                          defaultCurrent={paginationData?.page}
                          total={relatedProductList?.totalItems}
                          onChange={handleTableChange}
                          defaultPageSize={paginationData?.pageSize}
                          className="flex-0-auto ms-md-auto mt-0"
                        />
                      )}
                    </div>
                  )}
                </>
              )
            )}

            {!isModalLoading &&
              relatedProductList?.rows?.filter(
                (productData) =>
                  !productListData?.some(
                    (product) => product?.id === productData?.id
                  )
              )?.length === 0 && (
                <h6 className="fw-semibold text-center py-5 mb-0">
                  Inga Produkter Hittades
                </h6>
              )}
          </div>
        </>
      ),
      width: "1280px",
    },
    {
      id: "addExistingCollection",
      title: "Lägg till i befintlig kollektion",
      prevBtn: (
        <span
          id="createCollection"
          role={"button"}
          className="cursor-pointer hover-text"
          onClick={(e) => prev(e)}
        >
          <ArrowLeft02Icon size={28} color={"#000000"} variant={"stroke"} />
        </span>
      ),
      content: (
        <>
          <div className="mb-5">
            <ILSelect
              isRequired
              ILSelectLabelClass={"mb-0"}
              label="Välj kollektion"
              id="selectCollection"
              datatestid="selectCollection"
              options={collectionListData}
              placeholder={"Välj kollektion"}
              name="id"
              value={collectionData?.id}
              handleChange={(event) => handleSelectChange(event, "id")}
              errorMsg={validateMessages?.id}
            />
          </div>
          <div className="d-flex align-items-center justify-content-between mb-5">
            <p className="big fw-medium mb-0">Produkter</p>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleAddMoreProducts("existingCollection");
              }}
              className="gradient-text p big fw-medium"
            >
              +Lägg till fler produkter
            </a>
          </div>
          <div className="mb-5">
            {productListData?.map((productData, index) => {
              return (
                <Row
                  gutter={24}
                  className="align-items-center pb-3 pb-xxl-0 mb-4"
                  key={index}
                >
                  <Col xs={24} sm={16}>
                    <ILTextInput
                      ILInputLabelClass={"mb-0"}
                      id="link1"
                      datatestid="link1"
                      type="text"
                      name="link 1"
                      placeholder={"Länk 1"}
                      value={productData?.link}
                      disabled
                      label={
                        <div className="d-flex align-items-center gap-3 gap-xxl-2">
                          <div className="order-2">{`Länk ${index + 1}`}</div>
                          {index > 0 && (
                            <Delete02Icon
                              size={20}
                              color={"#db2f7f"}
                              variant={"stroke"}
                              className={clsx(
                                styles.deleteSocialAcc,
                                "transition-smooth cursor-pointer order-1"
                              )}
                              onClick={() =>
                                handleProductDeleteModal(productData)
                              }
                            />
                          )}
                        </div>
                      }
                    />
                  </Col>
                  <Col
                    xs={24}
                    sm={8}
                    className={clsx(
                      styles.payPerClickSellWrap,
                      "align-self-end mt-4 mt-sm-0"
                    )}
                  >
                    <div className="d-flex gap-3">
                      <div>
                        <span className="fw-medium mb-4 d-block">CPC</span>
                        <p className="fw-semibold mb-0 sm">
                          {productData?.ppc_price} KR
                        </p>
                      </div>
                      {!isEmpty(productData?.sell_commission) && (
                        <div className="ms-auto">
                          <span className="fw-medium mb-4 d-block">
                            Provision
                          </span>
                          <p className="fw-semibold mb-0 sm">
                            {productData?.sell_commission + " %"}
                          </p>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              );
            })}
          </div>
          <ILButton
            disabled={isCollectionAddLoading}
            id={"yourProductAdded"}
            ILBtnClass={"w-100"}
            variant={"primary-gradient"}
            handleClick={(e) => handleSubmitCollection(e)}
          >
            {isCollectionAddLoading ? (
              <ILLoader isBtn />
            ) : (
              "Lägg till i befintlig kollektion"
            )}
          </ILButton>
        </>
      ),
      width: "636px",
    },
    {
      id: "collectionCreated",
      title: "",
      content: (
        <>
          <div className="text-center d-flex flex-column gap-5">
            <span className="lh-0">
              <img
                src={gradientCircleRound}
                fluid
                alt="advertising agency | ilonsi"
              />
            </span>
            <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
              Din kollektion har skapats och produkterna har lagts till!
            </h4>
            <ILButton
              ILBtnClass={"w-100"}
              variant={"primary-gradient"}
              handleClick={() => navigate("/dashboard/my-shop")}
            >
              Visa i min butik
            </ILButton>
          </div>
        </>
      ),
      width: "518px",
    },
    {
      id: "collectionUpdated",
      title: "",
      content: (
        <>
          <div className="text-center d-flex flex-column gap-5">
            <span className="lh-0">
              <img
                src={gradientCircleRound}
                fluid
                alt="advertising agency | ilonsi"
              />
            </span>
            <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
              Products added to your collection !
            </h4>
            <ILButton
              ILBtnClass={"w-100"}
              variant={"primary-gradient"}
              handleClick={() => navigate("/dashboard/my-shop")}
            >
              Visa i min butik
            </ILButton>
          </div>
        </>
      ),
      width: "518px",
    },
    {
      id: "yourProductAdded",
      title: "",
      content: (
        <>
          <div className="text-center d-flex flex-column gap-5">
            <span className="lh-0">
              <img
                src={gradientCircleRound}
                fluid
                alt="advertising agency | ilonsi"
              />
            </span>
            <div>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Din produkt har lagts till!
              </h4>
              <p className="paragraph-sm-text paragraph-text-16 text-center">
                Din produkt har lagts till!&nbsp;
                <span className="text-dark-purple fw-medium">
                  My Skin care collection.
                </span>
              </p>
            </div>
            <div>
              <ILButton
                ILBtnClass={"w-100"}
                variant={"primary-gradient"}
                handleClick={() => navigate("/dashboard/my-shop")}
              >
                Visa i min butik
              </ILButton>
            </div>
          </div>
        </>
      ),
    },
    {
      id: "alreadyAdded",
      title: "",
      content: (
        <>
          <div className="text-center d-flex flex-column gap-5">
            <span className="lh-0">
              <img
                src={gradientCircleRound}
                fluid
                alt="advertising agency | ilonsi"
              />
            </span>
            <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
              Din {isService ? "tjänste" : "produkt"} är redan tillagd!
            </h4>
            <div>
              <ILButton
                ILBtnClass={"w-100"}
                variant={"primary-gradient"}
                handleClick={() => navigate("/dashboard/my-shop")}
              >
                Visa i min butik
              </ILButton>
            </div>
          </div>
        </>
      ),
    },
    {
      id: "shareInSocialMedia",
      prevBtn: (
        <span
          id="copiedSuccessfully"
          role={"button"}
          className="cursor-pointer hover-text lh-0"
          onClick={(e) => prev(e)}
        >
          <ArrowLeft02Icon size={28} color={"#000000"} variant={"stroke"} />
        </span>
      ),
      title: "Dela på sociala medier",
      content: (
        <>
          <p className="big fw-medium mb-4">
            Dela {selectedData?.product_code ? "produkten" : "tjänsten"} direkt
            via dina sociala medier
          </p>
          <div className="social-media-sharing-cards-wrap">
            {socialMediaSharingCardListResults &&
              socialMediaSharingCardListResults.map((item, index) => {
                return (
                  <label
                    key={index}
                    className={clsx(
                      styles.socialMediaSharingCard,
                      selectedSocialMedia?.title === item?.title &&
                        styles.socialIconActive,
                      "cursor-pointer"
                    )}
                    onClick={() => handleSelectSocialMedia(item)}
                  >
                    <div className="social-media-icon">
                      <img
                        src={item.image}
                        alt={item.title}
                        width={"100%"}
                        height={"100%"}
                      />
                    </div>
                    <p className="sm text-gray mb-0 text-center">
                      {item.title}
                    </p>
                  </label>
                );
              })}
            {socialMediaSharingCardListResults?.length > 8 && (
              <div role={"button"} onClick={handleShowAll}>
                <div className="social-media-icon">
                  <img
                    src={threeDotsIcon}
                    alt={showAll ? "Less" : "More"}
                    width={"100%"}
                    height={"100%"}
                  />
                </div>
                <p className="sm mb-0 text-center fw-medium">
                  {showAll ? "Less" : "More"}
                </p>
              </div>
            )}
          </div>
          {/* <div className="mb-5">
            <p className="big fw-medium mb-4">Eller kopiera länk</p>
            <ILTextInput
              ILInputLabelClass={"mb-0"}
              suffix={
                <>
                  <ILButton
                    ILBtnClass={"min-w-auto"}
                    variant={"primary-gradient"}
                  >
                    Kopiera 
                  </ILButton>
                </>
              }
              id="copyLinkInput"
              datatestid="copyLinkInput"
              type="text"
              name=""
              value="Influencer.Ilonsi.com/136sdf"
              readOnly={true}
              handleChange={() => {
                return false;
              }}
              placeholder={"Kopiera länkingång"}
            />
          </div> */}
          <div>
            <ILButton
              disabled={
                Object?.keys(selectedSocialMedia)?.length > 0 ? false : true
              }
              variant={"gradient-bordered"}
              ILBtnClass={"w-100"}
              handleClick={generateSharableLink}
            >
              Dela
            </ILButton>
          </div>
        </>
      ),
      width: "518px",
    },
  ];

  const currentStep = steps.find((step) => step.id === currentId);

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const next = (e) => {
    if (e?.currentTarget?.id === "productAdded") {
      handleProductAddToShop();
    } else if (e === "addNewCollection") {
      setCurrentId("collectionCreated");
    } else if (e === "addExistingCollection") {
      setCurrentId("collectionUpdated");
    } else if (currentId === "copyLink") {
      copy(`${appConfig.APP_URL}${selectedData?.short_code}`);
      setCurrentId("copiedSuccessfully");
    } else {
      const currentIndex = steps.findIndex(
        (step) => step?.id === e?.currentTarget?.id
      );
      setCurrentId(steps[currentIndex]?.id);
    }
  };

  const prev = (e) => {
    if (currentId === "selectProduct") {
      if (isNewCollection) {
        setCurrentId("addNewCollection");
      } else {
        setCurrentId("addExistingCollection");
      }
    } else if (currentId === ("addNewCollection" || "addExistingCollection")) {
      setCurrentId("createCollection");
      productListData.splice(1);
      setProductData({});
    } else {
      if (e.currentTarget.id === "copiedSuccessfully") {
        setSelectedSocialMedia({});
      }
      const currentIndex = steps.findIndex(
        (step) => step.id === e.currentTarget.id
      );
      setCurrentId(steps[currentIndex].id);
    }
  };

  return (
    <>
      <ILHead title="Brands" />
      {/* HERO SECTION START */}
      <ILInnerHero
        heroTitle={"Utforska företag och kategorier"}
        heroSearchBarShow
        isShowSearchDropDown
        name="search"
        isLoading={isLoading}
        value={brandPaginationData?.search}
        placeholder={"Sök företag"}
        handleSearchChange={handleSearchChange}
        options={brandList}
        handleSelectSearchChange={handleSelectSearchChange}
        // heroLearnMoreBtnShow
      />

      {/* HERO SECTION END */}
      {/* EXPLORE BRAND CATEGORIES SECTION START */}
      <section className="position-relative hero-gradient-border bg-cover-layer bg-cover-layer-size-mid z-0">
        <div className="container position-relative z-1">
          <div className="d-flex flex-column">
            <div className="tab-wrapper align-self-center section-sm-mb">
              <ILTab
                defaultActiveKey="1"
                tabItem={brandCategoriesTabItem}
                onChange={(event) => handleSelectChange(event, "category")}
                tabClassName="brand-tab-wrap"
              />
            </div>
            <div className={styles.exploreCategoriesListWrap}>
              <ILCheckbox
                checkboxList={categoryList}
                iconImg
                name="categoryId"
                value={categoryType?.categoryId}
                handleChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </section>
      {/* EXPLORE BRAND CATEGORIES SECTION END */}

      {/* FEATURED PRODUCTS SECTION START */}
      <section className="section-md-pt">
        <div className="container">
          <h2 className="secondary-title text-center d-block mb-3">
            Populära Produkter
          </h2>

          {isLoading?.productList ? (
            <div className="row">
              <div className="col-md-3 skeleton-card border">
                <ILSkeleton isImgTitleLabelCard2 />
              </div>
            </div>
          ) : (
            productList?.totalItems > 0 && (
              <>
                <Link
                  to="/product-search-filter"
                  className="p big-initial fw-medium gradient-text mb-0 ms-md-auto mx-auto me-md-0 text-center text-md-end d-table mt-5 mt-xxl-4"
                  onClick={() => {
                    localStorage.setItem("categoryType", "product");
                  }}
                >
                  Visa alla produkter
                </Link>

                <ILSwiper
                  leftArrowClassName="left-featured-products-arrow"
                  rightArrowClassName="right-featured-products-arrow"
                  swiperButtonClassName="d-none d-md-block"
                  className="mt-5 mt-xxl-4"
                  spaceBetween={20}
                  slidesPerView={4}
                  relatedProductList
                  autoplay={false}
                  loop={false}
                  breakpoints={featuredProductBreakPoints}
                  grabCursor={true}
                >
                  {productList?.rows?.map((FPItem, FPIndex) => {
                    return (
                      <SwiperSlide key={FPIndex}>
                        <ILProductCard
                          isProductLabel
                          isBrandProduct
                          isLoggedIn={isLoggedIn}
                          productDataShow={FPItem}
                          showModal={showModal}
                          loginHandler={loginHandler}
                          addToShopHandle={addToShopHandle}
                          sendAccessRequest={sendProductAccessRequest}
                          handleProductDetail={handleProductDetail}
                          productImgClass={clsx(styles.imgCard, "mx-auto")}
                          isRequestSentLoading={isRequestSentLoading}
                        />
                      </SwiperSlide>
                    );
                  })}
                </ILSwiper>
              </>
            )
          )}

          {!isLoading?.productList && productList?.totalItems === 0 && (
            <ILNoDataCard
              isIcon
              className="position-relative z-3 mt-5"
              title={"Inga Populära Produkter Hittades"}
            />
          )}
        </div>
      </section>
      {/* FEATURED PRODUCTS SECTION END */}

      {/* FEATURED SERVICES SECTION START */}
      <section className="section-md-pt w-100">
        <div className="container">
          <span className="secondary-title text-center d-block mb-3">
            Populära tjänster
          </span>
          {isLoading?.serviceList ? (
            <div className="row">
              <div className="col-md-3 skeleton-card border">
                <ILSkeleton isImgTitleLabelCard2 />
              </div>
            </div>
          ) : (
            serviceList?.totalItems > 0 && (
              <>
                <Link
                  to="/product-search-filter"
                  className="p big-initial fw-medium gradient-text mb-0 ms-md-auto mx-auto me-md-0 text-center text-md-end d-table mt-5 mt-xxl-4"
                  onClick={() => {
                    localStorage.setItem("categoryType", "service");
                  }}
                >
                  Visa alla tjänster
                </Link>

                <ILSwiper
                  leftArrowClassName="left-featured-service-arrow"
                  rightArrowClassName="right-featured-service-arrow"
                  swiperButtonClassName="d-none d-md-block"
                  className="mt-5 mt-xxl-4"
                  spaceBetween={20}
                  slidesPerView={4}
                  autoplay={false}
                  loop={false}
                  breakpoints={featuredServicesBreakPoints}
                  grabCursor={true}
                >
                  {serviceList?.rows?.map((FSItem, FSIndex) => {
                    return (
                      <SwiperSlide key={FSIndex}>
                        <ILProductCard
                          isService
                          isBrandProduct
                          isProductLabel
                          productDataShow={FSItem}
                          isLoggedIn={isLoggedIn}
                          loginHandler={loginHandler}
                          showModal={showModal}
                          selectedData={selectedData}
                          addToShopLoader={isLoading?.addToShop}
                          addToShopHandle={addToShopHandle}
                          sendAccessRequest={sendServiceAccessRequest}
                          handleProductDetail={handleServiceDetail}
                          productImgClass={clsx(styles.imgCard, "mx-auto")}
                          isRequestSentLoading={isRequestSentLoading}
                        />
                      </SwiperSlide>
                    );
                  })}
                </ILSwiper>
              </>
            )
          )}

          {!isLoading?.serviceList && serviceList?.totalItems === 0 && (
            <ILNoDataCard
              isIcon
              className="position-relative z-3 mt-5"
              title={"Inga Populära Tjänster Hittades"}
            />
          )}
        </div>
      </section>
      {/* FEATURED SERVICES SECTION END */}

      {/* FEATURED SERVICES SECTION START */}
      <section className="section-md-py w-100">
        <div className="container">
          <span className="secondary-title text-center d-block mb-3">
            Populära kollektioner
          </span>

          {isLoading?.collectionList ? (
            <div className="row">
              <div className="col-md-3 skeleton-card border">
                <ILSkeleton isImgTitleLabelCard2 />
              </div>
            </div>
          ) : (
            collectionList?.totalItems > 0 && (
              <>
                <Link
                  to="/product-search-filter"
                  className="p big-initial fw-medium gradient-text mb-0 ms-md-auto mx-auto me-md-0 text-center text-md-end d-table mt-5 mt-xxl-4"
                  onClick={() => {
                    localStorage.setItem("categoryType", "collection");
                  }}
                >
                  Visa alla kollektioner
                </Link>

                <ILSwiper
                  leftArrowClassName="left-featured-collection-arrow"
                  rightArrowClassName="right-featured-collection-arrow"
                  swiperButtonClassName="d-none d-md-block"
                  className="mt-5 mt-xxl-4"
                  spaceBetween={20}
                  slidesPerView={3}
                  autoplay={false}
                  loop={false}
                  breakpoints={featuredCollectionsBreakPoints}
                  grabCursor={true}
                >
                  {collectionList?.rows?.map((FCItem, FCIndex) => {
                    return (
                      <SwiperSlide key={FCIndex}>
                        <ILProductGalleryCard
                          isLoggedIn={isLoggedIn}
                          loginHandler={loginHandler}
                          ILProductGalleryCardDataList={FCItem}
                          sendAccessRequest={sendCollectionAccessRequest}
                          handleCollectionDetail={handleCollectionDetail}
                          productContainerClass={clsx(
                            styles.imgCard,
                            "mx-auto"
                          )}
                          isLoading={isCollectionAddLoading}
                          collectionData={selectedCollection}
                          showSCModal={showSCModal}
                          isShopCollectionModalOpen={isShopCollectionModalOpen}
                          handleSCModalOk={handleSCModalOk}
                          handleSCModalCancel={handleSCModalCancel}
                          handleAddToShopCollection={handleAddToShopCollection}
                          pagination={{ dynamicBullets: true }}
                          grabCursor={true}
                          isRequestSentLoading={isRequestSentLoading}
                        />
                      </SwiperSlide>
                    );
                  })}
                </ILSwiper>
              </>
            )
          )}

          {!isLoading?.collectionList && collectionList?.totalItems === 0 && (
            <ILNoDataCard
              isIcon
              className="position-relative z-3 mt-5"
              title={"Inga Kollektion Hittades"}
            />
          )}
        </div>
      </section>
      {/* FEATURED SERVICES SECTION END */}

      <ILModalFlow
        currentStep={currentStep}
        steps={steps}
        open={show}
        items={items}
        currentId={currentId}
        onCancel={handleModalClose}
      />

      <ILModal
        open={deleteProductModalOpen}
        onOk={handleProductDeleteModal}
        onCancel={handleProductDeleteModalCancel}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="518px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <div
                className="brand-logo brand-logo-big flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center mx-auto"
                style={{ borderColor: "#ED4F32", borderWidth: "3px" }}
              >
                <Delete02Icon
                  size={34}
                  color={"#ED4F32"}
                  variant={"stroke"}
                  strokeWidth={2}
                />
              </div>

              <p className="paragraph-sm-text paragraph-md-text text-center mb-0">
                Vill du ta bort den här produkt?
              </p>
              <div className="d-flex align-items-center gap-4 modal-more-btn">
                <ILButton
                  ILBtnClass="w-50"
                  variant={"primary-gradient"}
                  onClick={handleRemoveProduct}
                >
                  Ja, Bekräfta
                </ILButton>
                <ILButton
                  ILBtnClass="w-50"
                  variant={"gradient-bordered"}
                  onClick={handleProductDeleteModalCancel}
                >
                  Nej, Avbryt
                </ILButton>
              </div>
            </div>
          </>
        }
        isFooterRemove
      />

      <ILModal
        open={isCollectionAddedModalOpen}
        onOk={() => handleCAModalOk("new")}
        onCancel={() => handleCAModalOk("new")}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <span className="lh-0">
                <img
                  src={gradientCircleRound}
                  fluid
                  alt="advertising agency | ilonsi"
                />
              </span>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Din kollektion har lagts till!
              </h4>
              <ILButton
                ILBtnClass="w-100"
                variant={"primary-gradient"}
                handleClick={myShopNavigation}
              >
                Visa i min butik
              </ILButton>
            </div>
          </>
        }
        isFooterRemove
      />

      <ILModal
        open={isCollectionAlreadyAddedModalOpen}
        onOk={() => handleCAModalOk("already")}
        onCancel={() => handleCAModalOk("already")}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <span className="lh-0">
                <img
                  src={gradientCircleRound}
                  fluid
                  alt="advertising agency | ilonsi"
                />
              </span>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Din kollektion har lagts till redan!
              </h4>
              <ILButton
                ILBtnClass="w-100"
                variant={"primary-gradient"}
                handleClick={myShopNavigation}
              >
                Visa i min butik
              </ILButton>
            </div>
          </>
        }
        isFooterRemove
      />

      {/* ADD TO YOUR COLLECTION MODAL */}
      <ILModal
        open={isShopCollectionModalOpen}
        onOk={handleSCModalOk}
        onCancel={handleSCModalCancel}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <div
                className="brand-logo brand-logo-big flex-shrink-0 rounded-circle d-flex align-items-center justify-content-center mx-auto"
                style={{ borderColor: "#000000" }}
              >
                <img
                  src={selectedCollection?.brand?.profile_image}
                  height={50}
                  width={50}
                  alt="advertising agency | ilonsi"
                  className="object-fit-contain img-fluid"
                />
              </div>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Vill du lägga till den här kollektionen i din butik?
              </h4>
              <p className="paragraph-sm-text paragraph-md-text text-center mb-0">
                Denna kollektion kommer att läggas till i din butik.
              </p>
              <div className="d-flex align-items-center gap-4 modal-more-btn">
                <ILButton
                  disabled={isAddToShopLoading}
                  ILBtnClass="w-50"
                  variant={"primary-gradient"}
                  onClick={handleAddToShopCollection}
                >
                  {isAddToShopLoading ? <ILLoader isBtn /> : "Ja, Lägg till"}
                </ILButton>
                <ILButton
                  ILBtnClass="w-50"
                  variant={"gradient-bordered"}
                  onClick={handleSCModalCancel}
                >
                  Nej, Avbryt
                </ILButton>
              </div>
            </div>
          </>
        }
        isFooterRemove
      />

      <ILModal
        open={isSuccessfullyReqModalOpen}
        onOk={handleSRModalClose}
        onCancel={handleSRModalClose}
        title=""
        className=""
        wrapClassName=""
        centered={true}
        closable=""
        width="534px"
        children={
          <>
            <div className="text-center d-flex flex-column gap-5">
              <span className="lh-0">
                <img
                  src={gradientCircleRound}
                  fluid
                  alt="advertising agency | ilonsi"
                />
              </span>
              <h4 className="text-dark-purple fw-semibold text-center mb-0 px-4">
                Förfrågan har skickats!
              </h4>
              <ILButton
                ILBtnClass="w-100"
                variant={"primary-gradient"}
                handleClick={() =>
                  navigate("/dashboard/my-profile/my-access-request")
                }
              >
                Hantera förfrågningars
              </ILButton>
            </div>
          </>
        }
        isFooterRemove
      />
    </>
  );
};

export default ILBrands;
