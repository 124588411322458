import React from "react";
import { Steps } from "antd";
import { ILModal } from "../ILModal";

export const ILModalFlow = ({
  onCancel,
  open,
  currentStep,
  steps,
  currentId,
  items,
}) => {
  return (
    <>
      <ILModal
        open={open}
        onCancel={onCancel}
        title={
          <div className="d-flex align-items-center gap-3">
            {currentStep?.prevBtn && currentStep?.prevBtn}
            <div>
              {currentStep?.title}
              {currentStep?.subTitle && (
                <span className="p sm mt-1 d-block text-gray">
                  {currentStep?.subTitle}
                </span>
              )}
            </div>
          </div>
        }
        centered={true}
        width={currentStep?.width}
        children={currentStep?.content}
        isFooterRemove
        className={"isHeightAuto"}
      />
      <Steps
        current={steps.findIndex((step) => step.id === currentId)}
        items={items}
      />
    </>
  );
};
