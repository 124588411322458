import clsx from "clsx";
import { ILAccordion, ILHead, ILInnerHero } from "../../components";
import { useFaqHook } from "../../hooks/website/faq.hook";

const ILFaqDetails = () => {
  const { faqList } = useFaqHook();
  return (
    <>
      <ILHead title="Frågor" />
      <ILInnerHero
        className="pb-5"
        heroTitle="Frågor och Svar"
        heroParagraph="Vanliga Frågor och Svar om ILONSI"
      />

      <section
        className={clsx(
          "section-md-pb position-relative hero-gradient-border bg-cover-layer"
        )}
      >
        <div className="container position-relative z-1">
          <ILAccordion
            items={faqList}
            defaultActiveKey="1"
            className=""
            expandIconPosition="end"
          />
        </div>
      </section>
    </>
  );
};
export default ILFaqDetails;
